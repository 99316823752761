/* eslint-disable react/prop-types */

import { Carousel } from 'antd'
import { useState, useRef, useEffect } from 'react'
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import CarouselLine from '../../../../components/CarouselLine'
import SecondaryModal from '../../../../components/Modal/SecondaryModal'
import { isTablet } from 'src/utils/mediaQueries'

const CreditVariableModal = ({
    showModal,
    setShowModal,
    selectedVariable,
    save = () => {},
    creditVariableData,
    updateVariable,
    updateVariableLoading,
    provider,
    clusterInfo,
    type,
    closeVariableDetailsModal,
    creditVariableType,
}) => {
    const initialDataState = {
        cost_of_capital: '',
        debt_cost: '',
        debt_months: '',
        debt_to_equity: '',
        lowest_tenure: '',
        lowest_tenure_annual_interest: '',
        monthly_interest_jump: '',
        payment_plans_count: '',
        reinvestment_rate: '',
        upfront_deposit: '',
        tenure_increment: '',
    }
    const [data, setData] = useState(initialDataState)
    const stepsRef = useRef(null)
    const stepsHandler = action => {
        if (action === 'next') {
            stepsRef.current.next()
        }
        if (action === 'prev') {
            stepsRef.current.prev()
        }
    }
    useEffect(() => {
        setData(creditVariableData)
    }, [creditVariableData])
    return (
        <SecondaryModal
            modalWidth={isTablet ? '580px' : '624px'}
            closable={false}
            modalHeight="fit-content"
            app="adminsolution"
            showModal={showModal}
            content={
                <Carousel
                    swipeToSlide={false}
                    swipe={false}
                    accessibility={false}
                    ref={stepsRef}
                    dots={false}
                >
                    <>
                        <StepOne
                            nextHandler={() => stepsHandler('next')}
                            closeModal={() => {
                                closeVariableDetailsModal()
                                setData(creditVariableData)
                            }}
                            setData={setData}
                            data={data}
                            type={type}
                        />
                        <CarouselLine
                            total={2}
                            stage={1}
                            customMargin="10px 41%"
                        />
                    </>
                    <>
                        <StepTwo
                            closeModal={() => {
                                setShowModal(false)
                                save(data)
                            }}
                            cancelModal={() => {
                                closeVariableDetailsModal()
                                if (
                                    selectedVariable?.toLowerCase() ===
                                    'subscription'
                                ) {
                                    const initialState = {
                                        ...initialDataState,
                                        amortization_month: '',
                                        markup_value: '',
                                    }
                                    setData(initialState)
                                } else {
                                    setData(initialDataState)
                                }

                                stepsHandler('prev')
                            }}
                            data={data}
                            setData={setData}
                            showAddVariableModal={() => setShowModal(true)}
                            prevHandler={() => stepsHandler('prev')}
                            selectedVariable={selectedVariable}
                            updateVariable={updateVariable}
                            updateVariableLoading={updateVariableLoading}
                            provider={provider}
                            clusterInfo={clusterInfo}
                            type={type}
                            creditVariableType={creditVariableType}
                        />
                        <CarouselLine
                            total={2}
                            stage={2}
                            customMargin="10px 41%"
                        />
                    </>
                </Carousel>
            }
        />
    )
}

export default CreditVariableModal
