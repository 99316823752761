import PropTypes from 'prop-types'
import s from '../profilesgroupslistings.module.scss'
import { tableHeaders } from '../data'
import ButtonComponent from 'src/components/Button'
import Card from 'src/components/Card'
import { toTitleCase } from 'src/utils/formatting'
import { format, parseISO } from 'date-fns'
import { getPermissions } from '../formatter'

const ProfilesListingsTable = ({ profiles, setShowViewModal }) => {
    const { canViewProfileGroupDetails } = getPermissions()
    return (
        <>
            <table className={s.table}>
                <tr className={s.tableHeads}>
                    {tableHeaders?.map((header, i) => (
                        <th key={i}>{header} </th>
                    ))}
                </tr>
                {profiles?.map((row, i) => (
                    <tr key={i} className={s.tableRow}>
                        <td>{row?.name}</td>
                        <td>{row?.profiles_added}</td>
                        <td>{toTitleCase(row?.customer_type)}</td>
                        <td>
                            {row?.updated_at !== undefined &&
                                format(
                                    parseISO(row?.updated_at),
                                    'dd MMM yyyy, HH:mm',
                                )}
                        </td>
                        <td>
                            <ButtonComponent
                                btnWidth={'65px'}
                                btnHeight={'37px'}
                                type={'secondary'}
                                handleClick={() => {
                                    setShowViewModal({
                                        open: true,
                                        id: row?.id,
                                    })
                                }}
                                disabled={!canViewProfileGroupDetails}
                            >
                                View
                            </ButtonComponent>
                        </td>
                    </tr>
                ))}
            </table>

            {/* mobile */}
            <div className={s.cards}>
                {profiles?.map((row, i) => (
                    <Card
                        key={i}
                        topLeftHeading={'GROUP NAME'}
                        topLeftContent={row?.name}
                        bottomLeftHeading={'PROFILES ADDED'}
                        bottomLeftContent={row?.profiles_added}
                        bottomRightHeading={'PROFILE TYPE'}
                        bottomRightContent={toTitleCase(row?.customer_type)}
                        handleClick={() => {
                            setShowViewModal({ open: true, id: row?.id })
                        }}
                    />
                ))}
            </div>
        </>
    )
}

ProfilesListingsTable.propTypes = {
    profiles: PropTypes.array,
    setShowViewModal: PropTypes.func,
}

export default ProfilesListingsTable
