import { permissionsControl } from 'src/utils/permissionsControl'

export const getPermissions = () => {
    const canViewProfileGroups = permissionsControl([
        'can_list_energy_profile_group',
    ])

    const canViewEnergyProfileDetails = permissionsControl([
        'can_retrieve_energy_profile',
    ])

    const canCreateEnergyProfile = permissionsControl([
        'can_create_energy_profile',
    ])

    return {
        canCreateEnergyProfile,
        canViewEnergyProfileDetails,
        canViewProfileGroups,
    }
}
