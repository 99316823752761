import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import styles from './adminsubmitcreditdecisionmodal.module.scss'
import { ReactComponent as ConsumerKycNotificationIcon } from 'src/assets/images/consumer-kyc-notification-icon.svg'
import { ReactComponent as SubmitCreditDecisionIcon } from 'src/assets/images/submit-credit-decision-icon.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import actionStyles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import Toast from 'src/components/Toast'
import { SelectField } from 'src/components/InputFields'
import { simulationData } from 'src/pages/Admin/PaymentPlans/Details/components/creditReviewTab/data'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'

const AdminSubmitCreditDecisionModal = ({
    showModal,
    onCancel,
    consumerName,
    modalType = 'submit-kyc',
    kycAction,
    setKycAction,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    showToast,
    toastMessageType,
    toastMessage,
    primaryBtnLoading,
    simulationType,
    handleSimulationTypeChange,
}) => {
    const { isMobile } = useMediaQueries()

    const modalData = {
        'submit-kyc': {
            title: 'Submit KYC',
            leftContent: 'button',
            leftButtonText: 'More changes',
            rightButtonText: 'Confirm',
            primaryButtonDisabled: !kycAction,
        },
        'simulate-decision': {
            title: 'Submit info',
            leftContent: null,
            rightButtonText: 'Confirm',
            primaryButtonDisabled: simulationType === '',
        },
        'submitted-kyc': {
            title: 'KYC submitted successful',
            heading: 'KYC submitted successful',
            subHeading: `Great work! KYC has been submitted successfully, we’ve notified the ${consumerName} about this update.`,
            leftContent: null,
            rightButtonText: 'See credit review',
        },
        'notified-consumer': {
            title: 'Notified Consumer',
            heading: 'Consumer has been notified',
            subHeading: `Great work! we’ve notified ${consumerName} about the updates that have been made.`,
            leftContent: null,
            rightButtonText: 'Great, Thank you',
        },
    }
    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'475px'}
            title={modalData[modalType].title}
            v2
            kycSecondaryButtonStopLoading
            content={
                modalType === 'submit-kyc' ? (
                    <div className={styles.SubmitKycContainer}>
                        <div
                            className={`${styles.Option} ${
                                kycAction === 'kyc-notification'
                                    ? styles.Selected
                                    : ''
                            }`}
                            onClick={() => setKycAction?.('kyc-notification')}
                        >
                            <div className={styles.LeftBox}>
                                <ConsumerKycNotificationIcon />
                            </div>
                            <div className={styles.RightBox}>
                                <h4>Submit for consumer notification</h4>
                                <p>Submit to notify consumer only</p>
                            </div>
                        </div>
                        <div
                            className={`${styles.Option} ${
                                kycAction === 'kyc-submission'
                                    ? styles.Selected
                                    : ''
                            }`}
                            onClick={() => setKycAction?.('kyc-submission')}
                        >
                            <div className={styles.LeftBox}>
                                <SubmitCreditDecisionIcon />
                            </div>
                            <div className={styles.RightBox}>
                                <h4>Submit for credit decision</h4>
                                <p>
                                    Submit for credit decision and also notify
                                    the consumer
                                </p>
                            </div>
                        </div>
                    </div>
                ) : modalType === 'simulate-decision' ? (
                    <div className={styles.simulateWrapper}>
                        <WarningIcon />
                        <h2 className={actionStyles.ActionHeader}>
                            Submit customer&apos;s info
                        </h2>
                        <p className={actionStyles.ActionText}>
                            The next step is to verify if the customer is
                            eligible to get their solar system financed by
                            SunFi.
                        </p>
                        <SelectField
                            initialOption="Select Credit Decision Result"
                            floatingLabel="Select Credit Decision Result"
                            floatLabelOnSelectedValue
                            values={simulationData.map(option => ({
                                value: option,
                            }))}
                            selectWidth={'100%'}
                            name="simulationType"
                            handleChange={handleSimulationTypeChange}
                            currentSelected={simulationType}
                            selectedValue={simulationType}
                            marginTop="24px"
                            withCheckBox
                        />
                    </div>
                ) : (
                    <div style={{ position: 'relative' }}>
                        {showToast && (
                            <Toast
                                position="absolute"
                                top="30px"
                                messageType={toastMessageType}
                                message={toastMessage}
                            />
                        )}
                        <div className={actionStyles.ActionContainer}>
                            <SuccessIcon />
                            <h2 className={actionStyles.ActionHeader}>
                                {modalData[modalType].heading}
                            </h2>
                            <p className={actionStyles.ActionText}>
                                {modalData[modalType].subHeading}
                            </p>
                        </div>
                    </div>
                )
            }
            showFooter
            footerLeftContent={modalData[modalType].leftContent}
            primaryBtnText={modalData[modalType].rightButtonText}
            secondaryBtnText={modalData[modalType].leftButtonText}
            handlePrimaryBtnClick={handlePrimaryButtonClick}
            handleSecondaryBtnClick={handleSecondaryButtonClick}
            primaryBtnDisabled={modalData[modalType].primaryButtonDisabled}
            primaryBtnWidth={'160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            primaryBtnLoading={primaryBtnLoading}
        />
    )
}

AdminSubmitCreditDecisionModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    modalType: PropTypes.string,
    consumerName: PropTypes.string,
    kycAction: PropTypes.string,
    setKycAction: PropTypes.func,
    handlePrimaryButtonClick: PropTypes.func,
    handleSecondaryButtonClick: PropTypes.func,
    showToast: PropTypes.bool,
    toastMessageType: PropTypes.string,
    toastMessage: PropTypes.string,
    primaryBtnLoading: PropTypes.bool,
    simulationType: PropTypes.string,
    handleSimulationTypeChange: PropTypes.func,
}

export default AdminSubmitCreditDecisionModal
