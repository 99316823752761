import { useEffect, useState } from 'react'
import { validateDynamicKycInfoV2 } from 'src/utils/validationSchema'

const useDynamicKycCompleted = ({
    persistedValues,
    requiredKycSections,
    kycData,
    useType,
}) => {
    const [dynamicKycCompleted, setDynamicKycCompleted] = useState(false)

    useEffect(() => {
        const persistedValuesValidationSchemaContext = {
            sameAddress: persistedValues?.sameAddress?.value,
            requiredKycSections: requiredKycSections,
            isRegisteredBusiness: persistedValues?.registeredBusiness?.value,
            identityType: persistedValues?.identityType,
            bvnVerified: persistedValues?.bvnVerified,
            hasSubmittedBankStatement:
                kycData?.financial_verification?.bank_statements?.length > 0,
            useType: useType,
            moaRequired:
                useType === 'BUSINESS' &&
                requiredKycSections?.includes('business') &&
                persistedValues?.businessType !==
                    'Non-governmental Organisation',
            moaEmpty:
                persistedValues?.moaSuccessUpload?.name === '' &&
                persistedValues?.moaUrl === '',
            checkAnalysisStatus: true,
        }

        validateDynamicKycInfoV2
            .validate(persistedValues, {
                context: persistedValuesValidationSchemaContext,
                abortEarly: false,
            })
            .then(() => {
                setDynamicKycCompleted(true)
            })
            .catch(() => {
                setDynamicKycCompleted(false)
            })
    }, [persistedValues, kycData, requiredKycSections, useType])

    return {
        dynamicKycCompleted,
    }
}

export default useDynamicKycCompleted
