import { format } from 'date-fns'
import { formatBusinessType } from 'src/utils/business'
import {
    fullEmploymentOptions,
    retiredOptions,
} from 'src/utils/employmentStatus'
import { formatPhoneNumber, includeHttps } from 'src/utils/formatting'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import { removeEmptyValues } from 'src/utils/removeEmptyValues'

export const moduleDescriptionMapping = {
    basic_information:
        'This information helps us know you more. Please provide accurate information below.',
    personal_information:
        'Your personal information is required to create your account.',
    employment_information:
        'Your employment information is required to create your account.',
    identity_information:
        'This will help us to verify your identity and protect your account against unauthorised access.',
    financial_information: `Kindly upload a minimum of 6-months bank statement or retrieve your bank statement faster by clicking on "connect to Mono".`,
    business_financial_information: `Kindly upload a minimum of 12-months bank statement or retrieve your bank statement faster by clicking on "connect to Mono".`,
    business_information:
        'This information helps us know more about your business. Please provide accurate information below.',
    business_contact_information:
        'Provide any other contact information different from the one previously provided.',
    director_information:
        'This requires that you provide the contact information of the director of the business.',
}

export const moduleTitleMapping = {
    basic_information: 'Basic Information',
    personal_information: 'Personal Information',
    employment_information: 'Employment Information',
    identity_information: 'Identity Information',
    financial_information: 'Financial Information',
    business_financial_information: 'Financial Information',
    business_information: 'Business Information',
    business_contact_information: 'Contact Information',
    director_information: "Director's Information",
}

export const pageTitle =
    'Customer KYC | SunFi | Simplifying and Scaling Clean Energy'
export const pageUrlName = window.location.pathname
export const pageTracker = 'KYC_SSE'

export const getRequiredModules = modules =>
    modules
        .filter(module => module.section.some(section => section.required))
        .map(module => module.key)

export const actionModalValues = (action, inputs) => {
    switch (action) {
        case 'progress-saved':
            return {
                actionModalTitle: 'Redirecting to home page',
                actionModalHeaderText: 'Progress Saved, check your mail',
                actionModalSubtitle:
                    "Progress saved! We've emailed you instructions to resume when you have the required information.",
                actionModalActionType: 'countdown-timer',
                actionModalPrimaryBtnText: 'Proceed to Log Out',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Continue Application',
            }
        case 'progress-saved-by-admin':
            return {
                actionModalTitle: 'Changes saved successfully',
                actionModalHeaderText: 'KYC updates successfully saved',
                actionModalSubtitle:
                    'You can continue making additional changes, or notify the consumer that updates are complete.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'More changes',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Notify Consumer',
            }
        case 'consumer-notified-of-save-by-admin':
            return {
                actionModalTitle: 'Notified Consumer',
                actionModalHeaderText: 'Consumer has been notified',
                actionModalSubtitle: `Great work! we’ve notified ${inputs.firstName} ${inputs.lastName} about the updates that have been made`,
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'save-and-continue-later':
            return {
                actionModalTitle: `Save your progress`,
                actionModalHeaderText: `Save your progress? Please confirm`,
                actionModalSubtitle:
                    'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }

        case 'bank-statement-in-review':
            return {
                actionModalTitle: 'Save to continue later',
                actionModalHeaderText:
                    'Your bank statement is currently being analyzed',
                actionModalSubtitle:
                    'Thank you for completing your KYC, please note that we will get back to you once your bank statement has been analysed. Please Proceed to save.',
                actionModalActionType: 'document',
                actionModalPrimaryBtnText: 'Save and continue',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'bank-statement-in-review-and-kyc-incomplete':
            return {
                actionModalTitle: 'Your bank statement is in review',
                actionModalHeaderText: (
                    <>Your bank statement is being reviewed</>
                ),
                actionModalSubtitle:
                    'We’re checking your bank statement to complete your KYC. This will only take a moment. Please don’t refresh or leave the KYC page.',
                actionModalActionType: 'document-analysis',
                actionModalPrimaryBtnText: 'Continue KYC',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'bank-statement-in-review-and-kyc-complete':
            return {
                actionModalTitle: 'Your bank statement is in review',
                actionModalHeaderText: 'Your bank statement is being reviewed',
                actionModalSubtitle:
                    'We’re reviewing your bank statement. This will take a moment, but you don’t have to wait. You can submit your KYC and we will let you know If your application is successful.',
                actionModalActionType: 'document-analysis',
                actionModalPrimaryBtnText: 'Submit KYC',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const databaseKeysToInputKeysMap = {
    first_name: 'firstName',
    last_name: 'lastName',
    phone_number: 'phone',
    email: 'email',
    street_address: 'contactAddress',
    city: 'contactCity',
    state_of_residence: 'contactState',
    country: 'contactCountry',
    installation_details__street_address: 'installationAddress',
    installation_details__city: 'installationCity',
    installation_details__state: 'installationState',
    installation_details__country: 'installationCountry',
    employment_type: 'employmentType',
    pension_details__pension_manager: 'pensionManager',
    pension_details__retirement_date: 'retirementDate',
    business_type: 'businessType',
    business__business_moa: 'moaUrl',
    business__business_name: 'businessName',
    business__business_industry: 'businessIndustry',
    business__business_description: 'businessDescription',
    business__business_registration_number: 'cacNumber',
    business__business_name_verified: 'cacNumberVerified',
    business__years_in_operation: 'numberOfYearsInOperation',
    work_info__company_name: 'companyName',
    work_info__job_title: 'jobTitle',
    employee_data__net_monthly_salary: 'netMonthlySalary',
    employee_data__employee_id: 'employeeId',
    employment_details__job_title: 'jobTitle',
    employment_details__employment_start_date: 'jobStartDate',
    employment_details__linkedin_url: 'linkedinPage',
    employment_details__employment_contract_url: 'employmentContractUrl',
    director_first_name: 'directorFirstName',
    director_last_name: 'directorLastName',
    director_phone_number: 'directorPhone',
    director_email: 'directorEmail',
    avatar_url: 'selfieImageUrl',
    bvn: 'bvn',
    bvn_verified: 'bvnVerified',
    bank_statement: 'bankStatementId',
    bank_statement_analysis: 'bank_statement_analysis',
    audited_financial_report: 'auditedFinancialReportUrl',
}

export const getRequiredFieldsForDynamicKycModule = (data, inputs) => {
    const fields = []

    for (const section of data.section) {
        if (section.required) {
            for (const component of section.components) {
                if (component) {
                    const field = databaseKeysToInputKeysMap[component.key]

                    if (
                        ([
                            'installationAddress',
                            'installationCountry',
                            'installationState',
                            'installationCity',
                        ].includes(field) &&
                            inputs?.sameAddress?.value) ||
                        [
                            'bvnVerified',
                            'bank_statement_analysis',
                            'cacNumberVerified',
                        ].includes(field) ||
                        (field === 'moaUrl' &&
                            inputs.businessType ===
                                'Non-governmental Organisation')
                    ) {
                        continue
                    }

                    fields.push(field)
                }
            }
        }
    }

    if (fields.includes('businessName')) {
        if (!fields.includes('businessIndustry')) {
            fields.push('businessIndustry')
        }
    }

    return fields
}

export const getRequiredSectionDataForDynamicKycModule = (
    kycData = [],
    inputs,
) => {
    return kycData?.map(data => {
        const result = {
            title: moduleTitleMapping[data.key],
            fields: getRequiredFieldsForDynamicKycModule(data, inputs),
        }

        return result
    })
}

export const formatPayload = (inputs, offer, requiredKycSections, submit) => {
    let allInputs = {}

    if (offer?.use_type === 'RESIDENTIAL') {
        allInputs.basic_information = {
            personal_information: {},
            employment_information: {},
        }

        if (requiredKycSections?.includes('full_name')) {
            allInputs.basic_information.personal_information =
                removeEmptyValues({
                    ...allInputs.basic_information.personal_information,
                    first_name: inputs?.firstName,
                    last_name: inputs?.lastName,
                })
        }

        if (requiredKycSections?.includes('phone_number_email')) {
            allInputs.basic_information.personal_information =
                removeEmptyValues({
                    ...allInputs.basic_information.personal_information,
                    email: inputs?.email,
                })
        }

        if (requiredKycSections?.includes('phone_number_email')) {
            allInputs.basic_information.personal_information =
                removeEmptyValues({
                    ...allInputs.basic_information.personal_information,
                    phone_number: formatPhoneNumber(inputs?.phone),
                })
        }

        if (requiredKycSections?.includes('address')) {
            const contactAddressObject = removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state_of_residence: inputs?.contactState,
                country: inputs?.contactCountry,
            })

            let installationAddressObject
            if (inputs?.sameAddress?.value) {
                installationAddressObject = removeEmptyValues({
                    street_address: inputs?.contactAddress,
                    city: inputs?.contactCity,
                    state: inputs?.contactState,
                    country: inputs?.contactCountry,
                })
            } else {
                installationAddressObject = removeEmptyValues({
                    street_address: inputs?.installationAddress,
                    city: inputs?.installationCity,
                    state: inputs?.installationState,
                    country: inputs?.installationCountry,
                })
            }

            allInputs.basic_information.personal_information =
                removeEmptyValues({
                    ...allInputs.basic_information.personal_information,
                    contact_address: contactAddressObject,
                    installation_address: installationAddressObject,
                })
        }

        if (requiredKycSections?.includes('employment_type')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    employment_type: inputs?.employmentType,
                })
        }

        if (requiredKycSections?.includes('work_info')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    company_name: inputs?.companyName,
                    job_title: inputs?.jobTitle,
                })
        }

        if (requiredKycSections?.includes('employee_data')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    net_monthly_salary: removeCommaFromNum(
                        inputs?.netMonthlySalary,
                    ),
                    employee_id: inputs?.employeeId,
                })
        }

        if (requiredKycSections?.includes('employment_details')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    employment_start_date:
                        inputs?.jobStartDate?.length > 0 &&
                        fullEmploymentOptions.includes(inputs?.employmentType)
                            ? format(
                                  new Date(inputs?.jobStartDate),
                                  'yyyy-MM-dd',
                              )
                            : '',
                    employment_contract_url: inputs?.employmentContractUrl,
                    linkedin_url: includeHttps(inputs?.linkedinPage),
                })
        }

        if (requiredKycSections?.includes('business')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    is_business_registered: inputs?.registeredBusiness?.value,
                })

            allInputs.basic_information.employment_information.business_details =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information
                        .business_details,
                    business_name: inputs?.businessName,
                    business_industry: inputs?.businessIndustry,
                    business_description: inputs?.businessDescription,
                })
            removeEmptyValues(
                allInputs?.basic_information?.employment_information,
            )
            removeEmptyValues(allInputs?.basic_information)
        }

        if (requiredKycSections?.includes('pension_details')) {
            allInputs.basic_information.employment_information =
                removeEmptyValues({
                    ...allInputs.basic_information.employment_information,
                    pension_manager: inputs?.pensionManager,
                    retirement_date:
                        inputs?.retirementDate?.length > 0 &&
                        retiredOptions.includes(inputs?.employmentType)
                            ? format(
                                  new Date(inputs?.retirementDate),
                                  'yyyy-MM-dd',
                              )
                            : '',
                })
            removeEmptyValues(allInputs?.basic_information)
        }

        if (requiredKycSections?.includes('bank_statement')) {
            allInputs.financial_information = removeEmptyValues({
                ...allInputs.financial_information,
                estimation_id: offer?.id,
            })

            allInputs.financial_information.select_primary = removeEmptyValues({
                ...allInputs.financial_information.select_primary,
                bank_statement_id: inputs?.bankStatementId,
            })
            removeEmptyValues(allInputs?.financial_information)
        }

        if (requiredKycSections?.includes('avatar')) {
            allInputs.identity_information = removeEmptyValues({
                ...allInputs.identity_information,
                selfie_url: inputs?.selfieImageUrl,
            })
        }
    } else if (offer?.use_type === 'BUSINESS') {
        allInputs.business_information = {}

        if (requiredKycSections?.includes('address')) {
            const contactAddressObject = removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state_of_residence: inputs?.contactState,
                country: inputs?.contactCountry,
            })

            let installationAddressObject
            if (inputs?.sameAddress?.value) {
                installationAddressObject = removeEmptyValues({
                    street_address: inputs?.contactAddress,
                    city: inputs?.contactCity,
                    state: inputs?.contactState,
                    country: inputs?.contactCountry,
                })
            } else {
                installationAddressObject = removeEmptyValues({
                    street_address: inputs?.installationAddress,
                    city: inputs?.installationCity,
                    state: inputs?.installationState,
                    country: inputs?.installationCountry,
                })
            }

            allInputs.business_information = removeEmptyValues({
                ...allInputs.business_information,
                contact_address: contactAddressObject,
                installation_address: installationAddressObject,
            })
        }

        if (requiredKycSections?.includes('business_type')) {
            allInputs.business_information = removeEmptyValues({
                ...allInputs.business_information,
                business_type: formatBusinessType(inputs?.businessType),
            })
        }

        if (requiredKycSections?.includes('business')) {
            allInputs.business_information = removeEmptyValues({
                ...allInputs.business_information,
                business_name: inputs?.businessName,
                business_industry: inputs?.businessIndustry,
                business_description: inputs?.businessDescription,
                memorandum_of_association_url: inputs?.moaUrl,
            })
        }

        if (requiredKycSections?.includes('director_full_name')) {
            allInputs.director_information = removeEmptyValues({
                ...allInputs.director_information,
                first_name: inputs?.directorFirstName,
                last_name: inputs?.directorLastName,
            })
        }

        if (requiredKycSections?.includes('director_phone_number_email')) {
            allInputs.director_information = removeEmptyValues({
                ...allInputs.director_information,
                phone_number: formatPhoneNumber(inputs?.directorPhone),
                email: inputs?.directorEmail,
            })
        }

        if (requiredKycSections?.includes('full_name')) {
            allInputs.contact_information = removeEmptyValues({
                ...allInputs.contact_information,
                first_name: inputs?.firstName,
                last_name: inputs?.lastName,
            })
        }

        if (requiredKycSections?.includes('phone_number_email')) {
            allInputs.contact_information = removeEmptyValues({
                ...allInputs.contact_information,
                email: inputs?.email,
                phone_number: formatPhoneNumber(inputs?.phone),
            })
        }

        if (
            requiredKycSections?.includes('financial_report') ||
            requiredKycSections?.includes('bank_statement')
        ) {
            allInputs.financial_information = removeEmptyValues({
                ...allInputs.financial_information,
                estimation_id: offer?.id,
            })
        }

        if (requiredKycSections?.includes('financial_report')) {
            allInputs.financial_information = removeEmptyValues({
                ...allInputs.financial_information,
                audited_financial_report: inputs?.auditedFinancialReportUrl,
            })
        }
    }

    allInputs = removeEmptyValues({
        ...allInputs,
        submit: submit,
        dynamic_kyc: true,
    })

    const payload = removeEmptyValues(allInputs)

    return payload
}
