import ButtonComponent from 'src/components/Button'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
const PackageCard = ({ packageData, handleViewPackageInfo, paymentPlans }) => {
    return (
        <div>
            <div className={styles.Container}>
                <div className={styles.CardHeader}>
                    <h3>{packageData.name}</h3>
                    <ButtonComponent
                        btnTextColor="var(--blue)"
                        btnWidth="99px"
                        btnHeight="36px"
                        fontSize="12px"
                        btnTextColorOutline="#004AAD"
                        handleClick={handleViewPackageInfo}
                    >
                        View
                    </ButtonComponent>
                </div>
                <div className={styles.CardBody}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: packageData.description,
                        }}
                    ></p>
                </div>
                <div className={styles.CardFooter}>
                    <div className={styles.CardFooterCol}>
                        <h5>PACKAGE AMOUNT</h5>
                        <p>{packageData.total}</p>
                    </div>
                    <div className={styles.CardFooterCol}>
                        <h5>PAYMENT PLANS</h5>
                        <p>{paymentPlans.length}</p>
                    </div>
                    <div className={styles.CardFooterCol}>
                        <h5>COMPONENTS</h5>
                        <p>{packageData?.components?.length}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

PackageCard.propTypes = {
    packageData: PropTypes.object,
    handleViewPackageInfo: PropTypes.func,
    paymentPlans: PropTypes.array,
}

export default PackageCard
