/* eslint-disable react/prop-types */
import parse from 'html-react-parser'
import styles from './energysolution.module.scss'
import { Tabs } from 'antd'
import { useState } from 'react'
import ButtonComponent from 'src/components/Button'

const EnergySolution = ({
    onClose,
    energySolutionData,
    setShowClosePlanModal,
}) => {
    const {
        TopRectangle,
        Title,
        Subtitle,
        BottomRectangleTitle,
        BtnWrapper,
        DescriptionWrapper,
        DescriptionText,
        PlanWrapper,
        PlanDetailsTabs,
        EnergyTab,
        EnergyHeading,
        EnergyLightText,
        ApplianceRating,
        RatingContainer,
        textBold,
    } = styles

    const [currentTab, setCurrentTab] = useState('1')

    const ApplianceRatings = ({
        name,
        quantity,
        duration,
        energyRating,
        unitOfMeasurement,
    }) => {
        return (
            <div className={ApplianceRating}>
                <p className={textBold}>{name}</p>
                <p>{quantity} Units</p>
                <p>{duration} Hours</p>
                <p className={textBold}>
                    {energyRating} {unitOfMeasurement}
                </p>
            </div>
        )
    }

    return (
        <>
            <Tabs
                activeKey={currentTab}
                className={PlanDetailsTabs}
                onChange={tab => setCurrentTab(tab)}
                centered
            >
                <Tabs.TabPane tab="Your Plan Details" key="1">
                    <div className={PlanWrapper}>
                        <div>
                            <div className={TopRectangle}>
                                <p className={Title}>Package Name</p>
                                <p className={Subtitle}>
                                    {
                                        energySolutionData?.recommended_solution
                                            ?.package_name
                                    }
                                </p>
                            </div>
                            <div className={DescriptionWrapper}>
                                <p className={BottomRectangleTitle}>
                                    DESCRIPTION
                                </p>
                                <div className={DescriptionText}>
                                    {parse(
                                        `${energySolutionData?.recommended_solution?.package_description}`,
                                    )}
                                </div>
                            </div>
                            <div className={BtnWrapper}>
                                {energySolutionData?.recommended_solution
                                    ?.payment_model === 'Subscription' && (
                                    <ButtonComponent
                                        v2
                                        type="secondary"
                                        handleClick={() => {
                                            setShowClosePlanModal(true)
                                            onClose()
                                        }}
                                    >
                                        End Plan
                                    </ButtonComponent>
                                )}
                                <ButtonComponent handleClick={onClose} v2>
                                    Okay, Got it
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Energy Information" key="2">
                    <div className={EnergyTab}>
                        <div>
                            <p className={EnergyHeading}>Energy Needs</p>
                            <p className={EnergyLightText}>
                                Your appliance information
                            </p>
                        </div>
                        <div className={RatingContainer}>
                            {energySolutionData?.consumer_form_questions?.appliances?.map(
                                (appliance, idx) => (
                                    <ApplianceRatings
                                        key={idx}
                                        name={appliance.name}
                                        quantity={appliance.quantity}
                                        duration={appliance.backup_hours}
                                        energyRating={appliance.power_rating}
                                        unitOfMeasurement={
                                            appliance.unit_of_measurement
                                        }
                                    />
                                ),
                            )}
                        </div>
                        <div className={BtnWrapper}>
                            <ButtonComponent handleClick={onClose} v2>
                                Okay, Got it
                            </ButtonComponent>
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default EnergySolution
