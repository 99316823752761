import { permissionsControl } from 'src/utils/permissionsControl'

export const getPermissions = () => {
    const canCreateProfileGroup = permissionsControl([
        'can_create_energy_profile_group',
    ])

    const canViewProfileGroupDetails = permissionsControl([
        'can_view_energy_profile_group',
    ])

    const canUpdateProfileGroup = permissionsControl([
        'can_update_energy_profile_group',
    ])

    const canDeleteProfileGroup = permissionsControl([
        'can_delete_energy_profile_group',
    ])

    return {
        canCreateProfileGroup,
        canViewProfileGroupDetails,
        canUpdateProfileGroup,
        canDeleteProfileGroup,
    }
}
