import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { verifyPaystackDirectDebitApi } from 'src/api/consumer/directDebit'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { useSendAgreementReminderEmail } from '../SmartShoppingExperience/CreditDecision/utils/queries'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'
import {
    useRetriveRepaymentMethodSetupType,
    useSSERetrieveConsumerEstimation,
    useSSERetrieveConsumerEstimationSummary,
} from 'src/api/shoppingExperience/consumer-estimation/queries'
import { getSSERecentUpdatesApi } from 'src/api/consumer/requestUpdate'
import { getProfileInformationApi } from 'src/api/profile'
import { formattedActivePlans } from './data'
import repaymentSetupMapping from 'src/utils/Data/repaymentSetupMapping'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { getKeyByValue } from 'src/utils/formatting'
import { getSearchParamsFromUrl } from 'src/utils/urlTracker'
import { retrieveEstimationApi } from 'src/api/shoppingExperience/account-overview'
import { formatAccountOverviewData } from '../SmartShoppingExperience/AccountSetupOverview/data'

const useConsumerWorkspace = () => {
    const pageTitle =
        'Consumer Workspace | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CONSUMER_WORKSPACE_SSE'
    const consumerWorkspaceTabs = {
        1: 'recent-updates',
        2: 'active-plans',
    }

    const [showSubTenure, setShowSubTenure] = useState(false)
    const [showSubscriptionPlan, setShowSubscriptionPlan] = useState({
        primary: false,
        secondary: false,
    })
    const [showisLeaseToOwnPlan, setShowisLeaseToOwnPlan] = useState({
        primary: false,
        secondary: false,
    })

    const [action, setAction] = useState('')
    const [showDisputePackageModal, setShowDisputePackageModal] =
        useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [showPackageDetailsDrawer, setShowPackageDetailsDrawer] =
        useState(false)

    const history = useHistory()
    const filterQuery = getSearchParamsFromUrl()
    const hasMounted = useRef(false)

    const [showPaystackModal, setShowPaystackModal] = useState(false)
    const [showRequestNewDealModal, setShowRequestNewDealModal] =
        useState(false)
    const [activePlan, setActivePlan] = useState({}) // active plan for post approval new package
    const [
        showAgreementSigningConfirmationModal,
        setShowAgreementSigningConfirmationModal,
    ] = useState(false)
    const [paystackLoading, setPaystackLoading] = useState(false)
    const [showTenureChangeModal, setShowTenureChangeModal] = useState(false)
    const [tenureChangeAction, setTenureChangeAction] = useState('') // confirm-change-request | enter-request-reason | request-sent-successfully
    const [selectedPlanId, setSelectedPlanId] = useState('')
    const [initialDeposit, setInitialDeposit] = useState('')

    const handleLeaseRepaymentSetup = () => {
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: true,
        })
    }
    const handleShowisLeaseToOwnPlan = () => {
        setShowisLeaseToOwnPlan({ primary: false, secondary: true })
    }
    const handlePaystackModal = () => {
        setShowPaystackModal(true)
    }
    const queryParams = new URLSearchParams(window.location.search)
    const pageTab = queryParams.get('tab')
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(consumerWorkspaceTabs, filterQuery.tab) || '1',
    )
    const [selectedEstimation, setSelectedEstimation] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [allUpdates, setAllUpdates] = useState([])
    const [pageSize] = useState(10)
    const isSubscriptionPlan = history?.location?.state?.isSubscription ?? false
    const isLeaseToOwnPlan = history?.location?.state?.isLeaseToOwn ?? false
    const isWithSuggestion = history?.location?.state?.isWithSuggestion ?? false
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        consumerEmail: '',
        phone: '',
        isPhoneNumberVerified: false,
        isEmailVerified: false,
    })
    const [repaymentMethodType, setRepaymentMethodType] = useState('')
    const [accountDetails, setAccountDetails] = useState({})
    const { errorAlert } = useCustomToast()
    const [verifyPaystackError, setVerifyPaystackError] = useState(false)
    const [returningCustomerStatus, setReturningCustomerStatus] = useState('')
    const [showNewPackageModal, setShowNewPackageModal] = useState(false)
    const [planWithUpdatedTenure, setPlanWithUpdatedTenure] = useState({})
    const [showIncreaseDepositModal, setShowIncreaseDepositModal] =
        useState(false)
    const [increaseDepositAction, setIncreaseDepositAction] = useState('')

    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'

    const allowPostApprovalChanges =
        process.env.REACT_APP_FLAG_ENABLE_POST_APPROVAL_CHANGES === 'true'

    const { getReturningCustomerStatus } = useGetReturningCustomerStatus({
        email: userInfo?.email,
        phone: userInfo?.phone,
        onSuccess: data => {
            setReturningCustomerStatus(data?.status)
        },
    })

    useEffect(() => {
        if (
            allowReturningCustomers &&
            userInfo?.email !== '' &&
            userInfo?.phone !== ''
        ) {
            getReturningCustomerStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowReturningCustomers, userInfo?.email, userInfo?.phone])

    useEffect(() => {
        if (pageTab === '2') {
            setCurrentTab('2')
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `VIEW_${pageTab === '1' ? 'RECENT_UPDATES' : 'ACTIVE_PLANS'}`,
            'KYC_CONSUMER_WORKSPACE_TABS',
            'KYC_CONSUMER_WORKSPACE',
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageTab])

    const { refetch: fetchEstimationDetails } = useQuery(
        ['getEstimationDetails'],
        ({ queryKey }) => retrieveEstimationApi(queryKey[1]),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setActivePlan(formatAccountOverviewData(data))
            },
        },
    )

    const { sseConsumerEstimationData, sseConsumerEstimationRefetch } =
        useSSERetrieveConsumerEstimation({
            onSuccess: data => {
                appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                    people_set: {
                        'Repayment setup type':
                            repaymentSetupMapping[
                                data[0]?.repayment_method_setup?.type
                            ],
                    },
                })

                const changed_post_approval_packages = data?.filter(
                    item => item.consumer_status === 'PACKAGE_CHANGED',
                )
                const hasSeenModal = JSON.parse(
                    sessionStorage.getItem('shownNewPackageModal') || 'false',
                )
                const adminUpdatedPlan = data?.find(
                    item => item?.consumer_status === 'TENURE_CHANGED',
                )

                const upfrontDepositIncreased = data?.find(
                    item =>
                        item?.post_approval?.upfront_deposit?.deposit_increased,
                )

                if (upfrontDepositIncreased) {
                    openDepositChangeModal(
                        'confirm-change-request',
                        upfrontDepositIncreased?.id,
                    )
                    setInitialDeposit(
                        upfrontDepositIncreased?.post_approval?.upfront_deposit?.original_paid.toString(),
                    )
                }

                if (adminUpdatedPlan) {
                    setPlanWithUpdatedTenure(adminUpdatedPlan)
                    openTenureChangeModal(
                        'admin-updated-tenure-notification',
                        adminUpdatedPlan?.id,
                    )
                }

                if (
                    changed_post_approval_packages.length > 0 &&
                    allowPostApprovalChanges &&
                    !hasSeenModal
                ) {
                    sessionStorage.setItem(
                        'shownNewPackageModal',
                        JSON.stringify(true),
                    )
                    setShowNewPackageModal(true)
                    fetchEstimationDetails({
                        queryKey: [
                            'getEstimationDetails',
                            changed_post_approval_packages[0]?.id,
                        ],
                    })
                }
            },
        })
    const {
        sseConsumerEstimationSummaryLoading,
        sseConsumerEstimationSummaryData,
        sseConsumerEstimationSummaryRefetch,
    } = useSSERetrieveConsumerEstimationSummary({})

    const {
        isLoading: loadingUpdates,
        refetch: fetchAllUpdates,
        data: sseRecentUpdatesData,
    } = useQuery(
        ['getSSERecentUpdatesApi'],
        () => getSSERecentUpdatesApi(pageSize),
        {
            enabled: true,
            retry: false,
            refetchIntervalInBackground: true,
            onSuccess: data => {
                const filterRequiredUpdates = data?.data?.data?.filter(data => {
                    return data?.updated !== 'FULL'
                })
                setAllUpdates(filterRequiredUpdates)
                if (isSubscriptionPlan) {
                    setShowSubscriptionPlan({ primary: true, secondary: false })
                }
                if (isLeaseToOwnPlan) {
                    setShowisLeaseToOwnPlan({ primary: true, secondary: false })
                }
                if (isWithSuggestion) {
                    setShowisLeaseToOwnPlan({ primary: false, secondary: true })
                }
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(
                    'Failed to get recent updates. Try refreshing page',
                )
            },
        },
    )

    const { refetch: refetchUserProfile } = useQuery(
        'getUserProfile',
        () => getProfileInformationApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setUserInfo(prev => ({
                    ...prev,
                    firstName: data?.data?.data?.first_name,
                    email: data?.data?.data?.email,
                    phone: data?.data?.data?.phone_number,
                    isEmailVerified: data?.data?.data?.email_verified,
                    isPhoneNumberVerified: data?.data?.data?.phone_verified,
                }))
            },
        },
    )

    const toggleRequestNewDealModal = () => {
        setShowRequestNewDealModal(prev => !prev)
    }

    useEffect(() => {
        if (history.location.state?.currentTab) {
            setCurrentTab(history.location.state.currentTab)

            // Clear the state
            history.replace({ ...history.location, state: undefined })
        }

        if (hasMounted.current) return
        hasMounted.current = true

        return () => {
            sessionStorage.removeItem('shownNewPackageModal')
        }
    }, [history])

    useEffect(() => {
        fetchAllUpdates()
        sseConsumerEstimationRefetch()
        sseConsumerEstimationSummaryRefetch()
        refetchUserProfile()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activePlans = formattedActivePlans(
        sseConsumerEstimationData?.data?.data,
    )

    // Use the estimation_id returned from the recent update to make necessary updates e.g. setting up a repayment method
    const estimationId =
        selectedEstimation ?? sseRecentUpdatesData?.data?.data[0]?.estimation_id

    const isPendingAgreementSigning =
        !!sseConsumerEstimationData?.data?.data?.find(
            data =>
                data.application_status === 'AGREEMENT_SIGNING' &&
                !data.agreement_signed_at,
        )

    const { refetchRepaymentMethodType } = useRetriveRepaymentMethodSetupType({
        estimationId: estimationId,
        enabled: !!estimationId,
        onSuccess: data => {
            setRepaymentMethodType(data?.repayment_method_type)
            setAccountDetails(data?.account_details)
        },
        onError: error => {
            errorAlert(error)
        },
    })

    const { mutateSendAgreementEmail } = useSendAgreementReminderEmail({})

    const handleSendAgreementReminder = () => {
        mutateSendAgreementEmail({
            estimationId,
        })
        setShowAgreementSigningConfirmationModal(true)
    }

    useEffect(() => {
        if (estimationId) {
            refetchRepaymentMethodType()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimationId])

    // verify paystack direct debit
    const { refetch: verifyPaystackDirectDebitRefetch } = useQuery(
        'verify-paystack-direct-debit',
        () => verifyPaystackDirectDebitApi(estimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setVerifyPaystackError(false)
                setAction('verify-paystack-direct-debit')
                setShowActionModal(true)
            },
            onError: () => {
                setVerifyPaystackError(true)
                setAction('verify-paystack-direct-debit')
                setShowActionModal(true)
            },
        },
    )

    // check if paystack direct debit has been initialized
    const paystackQueryParams = new URLSearchParams(history.location.search)
    const paystackInitialized = paystackQueryParams.get('paystack_initialized')

    useEffect(() => {
        if (paystackInitialized && estimationId?.length > 0)
            verifyPaystackDirectDebitRefetch()
        // eslint-disable-next-line
    }, [paystackInitialized, estimationId])

    const handleShowSubscriptionPlan = () => {
        setShowSubscriptionPlan({ primary: false, secondary: true })
        setShowSubTenure(true)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseTenureSetup'].action,
            eventTrackers['sseTenureSetup'].label,
            eventTrackers['sseTenureSetup'].category,
        )
    }

    const handleDisputeBtnClick = () => {
        setShowDisputePackageModal(false)
        setAction('post-approval-new-package-disputed-successfully')
        setShowActionModal(true)
    }

    const handlePrimaryBtnClick = () => {
        switch (action) {
            case 'verify-paystack-direct-debit':
            case 'post-approval-new-package-disputed-successfully':
            case 'accept-post-approval-package-change':
                setAction('')
                setShowPackageDetailsDrawer(false)
                setShowActionModal(false)
                break
            case 'confirm-post-approval-package-rejection':
                setAction('')
                setShowActionModal(false)
                setShowDisputePackageModal(true)
                break
            default:
                break
        }
    }

    const handleDisputePackage = () => {
        setAction('confirm-post-approval-package-rejection')
        setShowActionModal(true)
    }

    const openTenureChangeModal = (action, id) => {
        setShowTenureChangeModal(true)
        setTenureChangeAction(action)
        setSelectedPlanId(id)
    }

    const closeTenureChangeModal = () => {
        setShowTenureChangeModal(false)
        setTenureChangeAction('')
    }

    const openDepositChangeModal = (action, id, deposit) => {
        setShowIncreaseDepositModal(true)
        setIncreaseDepositAction(action)
        setSelectedEstimation(id)
        setInitialDeposit(deposit)
    }

    const closeDepositChangeModal = () => {
        setShowIncreaseDepositModal(false)
        setIncreaseDepositAction('')
    }

    const openNewPackageModal = () => {
        setShowPackageDetailsDrawer(true)
    }

    const openCardDetailsModal = () => {
        closeDepositChangeModal()
        setPaystackLoading(true)
        handlePaystackModal()
    }

    return {
        consumerWorkspaceTabs,
        showSubTenure,
        setShowSubTenure,
        showSubscriptionPlan,
        setShowSubscriptionPlan,
        showisLeaseToOwnPlan,
        setShowisLeaseToOwnPlan,
        handleShowSubscriptionPlan,
        handleDisputeBtnClick,
        action,
        setAction,
        showActionModal,
        setShowActionModal,
        showDisputePackageModal,
        setShowDisputePackageModal,
        showPackageDetailsDrawer,
        setShowPackageDetailsDrawer,
        handlePrimaryBtnClick,
        handleDisputePackage,
        activePlans,
        selectedEstimation,
        setSelectedEstimation,
        paystackLoading,
        handleSendAgreementReminder,
        handleLeaseRepaymentSetup,
        handlePaystackModal,
        handleShowisLeaseToOwnPlan,
        allUpdates,
        loadingUpdates,
        errorMessage,
        toastError,
        currentTab,
        setCurrentTab,
        toggleRequestNewDealModal,
        returningCustomerStatus,
        allowReturningCustomers,
        userInfo,
        sseConsumerEstimationRefetch,
        setShowNewPackageModal,
        showNewPackageModal,
        setPaystackLoading,
        setShowAgreementSigningConfirmationModal,
        isPendingAgreementSigning,
        showAgreementSigningConfirmationModal,
        repaymentMethodType,
        accountDetails,
        verifyPaystackError,
        sseConsumerEstimationData,
        sseConsumerEstimationSummaryRefetch,
        fetchAllUpdates,
        isSubscriptionPlan,
        estimationId,
        isWithSuggestion,
        showPaystackModal,
        setShowPaystackModal,
        showRequestNewDealModal,
        sseConsumerEstimationSummaryData,
        sseConsumerEstimationSummaryLoading,
        activePlan,
        allowPostApprovalChanges,
        showTenureChangeModal,
        tenureChangeAction,
        openTenureChangeModal,
        closeTenureChangeModal,
        setTenureChangeAction,
        selectedPlanId,
        planWithUpdatedTenure,
        openDepositChangeModal,
        closeDepositChangeModal,
        showIncreaseDepositModal,
        increaseDepositAction,
        setIncreaseDepositAction,
        openNewPackageModal,
        initialDeposit,
        openCardDetailsModal,
    }
}

export default useConsumerWorkspace
