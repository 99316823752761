import { useState } from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import Button from '../../../../../../components/Button'
import styles from './SolutionModalContent.module.scss'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import Pill from 'src/components/Pill'

const EnergyRecommendationContent = ({ modalData, closeModal }) => (
    <div className={styles.energyRecommendation}>
        <div className={styles.energyRecommendation__header}>
            {modalData?.best_fit && <small>BEST MATCH</small>}
            <h2>{modalData?.package_name || modalData?.name}</h2>
        </div>
        <div className={styles.energyRecommendation__body}>
            <h4>DESCRIPTION</h4>
            {parse(
                modalData?.package_description ||
                    modalData?.recommendation_context?.note ||
                    '',
            )}
        </div>
        <h4 className={styles.energyRecommendation__compHead}>
            Components Information
        </h4>
        <ul className={styles.energyRecommendation__table}>
            {modalData?.components?.map(component => (
                <li key={component.id}>
                    <p>{component.name ?? component?.component_name}</p>
                    <small className={styles.type}>
                        {component.type ?? component?.component_type}
                    </small>
                    <small className={styles.currency}>
                        ₦ {currencyFormatter(component.total_cost)}
                    </small>
                    <small className={styles.qty}>{component.quantity}</small>
                </li>
            ))}
            <li>
                <p>Subtotal</p>
                <small className={styles.currency}>
                    ₦ {currencyFormatter(modalData?.cost)}
                </small>
            </li>
            <li>
                <p>Margin</p>
                <div className={styles.marginWrapper}>
                    <span className={styles.marginPercent}>
                        % {modalData?.margin}
                    </span>
                    <small className={styles.margin}>
                        +₦ {currencyFormatter(modalData?.margin_amount)}
                    </small>
                </div>
            </li>
            <li>
                <p>Total</p>
                <small className={styles.currency}>
                    ₦ {currencyFormatter(modalData?.total_cost_of_ownership)}
                </small>
            </li>
        </ul>

        <div className={styles.energyRecommendation__footer}>
            <Button
                btnBgColor="#004AAD"
                btnTextColor="#fff"
                btnWidth="160px"
                btnHeight="56px"
                handleClick={() => closeModal()}
            >
                Okay, Got it
            </Button>
        </div>
    </div>
)

const PaymentPlansContent = ({ modalData, closeModal }) => (
    <div className={styles.paymentPlans}>
        <div className={styles.paymentPlans__header}>
            <small>INITIAL DEPOSIT</small>
            <h2>
                {modalData?.payment_plans[0]?.currency.symbol}{' '}
                {currencyFormatter(
                    modalData?.payment_plans[0]?.initial_deposit,
                )}
            </h2>
        </div>

        <ul className={styles.paymentPlans__body}>
            <h4>Available Payment Plans</h4>
            <div className={styles.paymentPlans__body__wrapper}>
                {modalData?.payment_plans?.map((plan, index) => (
                    <li key={plan.id}>
                        <div className={styles.listTop}>
                            <div>
                                <p>Plan {index + 1} </p>
                                {plan?.selected && <Pill text="Provider" />}
                                {plan?.admin_approved && (
                                    <Pill text="Customer" />
                                )}
                            </div>
                            <small>MONTHS OF REPAYMENT</small>
                        </div>
                        <div className={styles.listBottom}>
                            <p>
                                {plan?.currency.symbol}{' '}
                                {currencyFormatter(plan.monthly_payment)}
                                <span>/ Month</span>
                            </p>
                            <small>{plan.tenure} Months</small>
                        </div>
                    </li>
                ))}
            </div>
        </ul>

        <div className={styles.paymentPlans__footer}>
            <Button
                btnBgColor="#004AAD"
                btnTextColor="#fff"
                btnWidth="160px"
                btnHeight="56px"
                handleClick={() => closeModal()}
            >
                Okay, Got it
            </Button>
        </div>
    </div>
)

const SolutionModalContent = ({ closeModal, modalData }) => {
    const [activeTab, setActiveTab] = useState('energyRecommendation')
    const handleTabClick = tab => setActiveTab(tab)

    const tabContentMap = {
        energyRecommendation: (
            <EnergyRecommendationContent
                modalData={modalData}
                closeModal={closeModal}
            />
        ),
        paymentPlans: (
            <PaymentPlansContent
                modalData={modalData}
                closeModal={closeModal}
            />
        ),
    }

    return (
        <div className={styles.solutionModalContent}>
            <div className={styles.solutionModalContent__header}>
                {Object.keys(tabContentMap).map(tab => (
                    <h2
                        key={tab}
                        className={activeTab === tab ? styles.active : ''}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab === 'energyRecommendation'
                            ? 'Energy Recommendation'
                            : 'Payment Plans'}
                    </h2>
                ))}
            </div>
            <div className={styles.solutionModalContent__body}>
                {tabContentMap[activeTab]}
            </div>
        </div>
    )
}

export default SolutionModalContent

EnergyRecommendationContent.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
}

PaymentPlansContent.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
}

SolutionModalContent.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
}
