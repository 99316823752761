import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import axios from 'src/config/axios'

export const retrieveEstimationApi = (estimationId = '') => {
    const request = axios.get(
        `/consumers/estimations/offer?estimation_id=${estimationId}`,
    )
    return request
}

export const retrieveEstimationForAdminApi = estimation_id => {
    const request = axios.get(
        `/admin/sse-consumers/estimations/${estimation_id}/offer`,
    )
    return request
}

export const changeUseTypeApi = (id, payload) => {
    const request = axios.post(
        `consumers/estimations/${id}/change-customer-type`,
        payload,
    )
    return request
}

export const resendOTP = payload => {
    const request = axios.post(`/sse-auth/initiate-otp`, payload)
    return request
}

export const getKYCInfo = (estimationId = '') => {
    const request = axios.get(
        `/consumers/kyc/info?estimation_id=${estimationId}`,
    )
    return request
}

export const getConsumerKYCInfoForAdmin = estimation_id => {
    const request = axios.get(
        `/admin/sse-consumers/estimations/${estimation_id}/info`,
    )
    return request
}

export const getKYCCompletionStatus = estimation_id => {
    const request = axios.get(
        `/consumers/kyc/${estimation_id}/onboarding-progress`,
    )
    return request
}

export const getKYCCompletionStatusForAdmin = estimation_id => {
    const request = axios.get(
        `/admin/sse-consumers/estimations/${estimation_id}/onboarding-progress`,
    )
    return request
}

export const validateOTP = payload => {
    const request = axios.post(`/sse-auth/auth-validate-otp`, payload)
    return request
}

export const useGetKycInfo = (isAdminUser, estimation_id, options = {}) => {
    return useQuery(
        'get-kyc-info',
        () =>
            isAdminUser
                ? getConsumerKYCInfoForAdmin(estimation_id)
                : getKYCInfo(estimation_id || ''),
        {
            ...options,
        },
    )
}
export const useGetEstimation = (
    isAdminUser = false,
    estimation_id = '',
    options = {},
) => {
    const { errorAlert } = useCustomToast()
    return useQuery(
        'fetch-estimation',
        () => {
            let estimationId = ''

            if (isAdminUser) {
                return retrieveEstimationForAdminApi(estimation_id)
            }

            if (
                process.env
                    .REACT_APP_FLAG_SHOW_KYC_DETAILS_FOR_ASSOCIATED_ACTIVE_PLAN ===
                    'true' &&
                estimation_id
            ) {
                estimationId = estimation_id
            }

            return retrieveEstimationApi(estimationId)
        },
        {
            ...options,
            retry: false,
            onError: error => {
                errorAlert(error?.response?.data?.message)
            },
        },
    )
}

export const disputePackageApi = (estimation_id, data) => {
    const request = axios.post(
        `consumers/estimations/${estimation_id}/dispute-package`,
        data,
    )
    return request
}

export const nudgeProviderApi = estimation_id => {
    const request = axios.post(
        `consumers/estimations/${estimation_id}/dispute-package/notify-provider`,
    )
    return request
}

export const acceptPackageApi = estimation_id => {
    const request = axios.post(
        `consumers/estimations/${estimation_id}/accept-package`,
    )
    return request
}

export const getRequestUpdatFieldsApi = estimation_id => {
    const request = axios.get(
        `/admin/sse-consumers/estimations/${estimation_id}/request-update-fields`,
    )
    return request
}

export const requestUpdateApi = (estimation_id, data) => {
    const request = axios.post(
        `/admin/sse-consumers/estimations/${estimation_id}/request-update`,
        data,
    )
    return request
}

export const solutionChangeApi = (estimation_id, data) => {
    const request = axios.post(
        `/admin/sse-consumers/estimations/${estimation_id}/solution-change`,
        data,
    )
    return request
}

export const determineCreditDecisionRunStatus = estimationId => {
    const request = axios.get(
        `/credit/decision/estimations/${estimationId}/check-decision`,
    )
    return request
}

export const fetchAllKycAppsApi = () => {
    const request = axios.get('/cluster-origination/kyc/kyc-app')
    return request
}

export const fetchKycAppApi = appId => {
    const request = axios.get(`/cluster-origination/kyc/kyc-app/${appId}`)
    return request
}

export const getKycModulesApi = estimationId => {
    const request = axios.get(
        `/cluster-origination/kyc/${estimationId}/modules`,
    )
    return request
}

export const getAdminKycModulesApi = estimationId => {
    const request = axios.get(
        `/admin-cluster-origination/kyc/${estimationId}/modules`,
    )
    return request
}

export const closeSubscriptionPlan = async (estimation_id, payload) => {
    const request = axios.post(
        `/consumers/estimations/${estimation_id}/close-subscription`,
        payload,
    )
    return request
}
