import PropTypes from 'prop-types'
import PaymentSummary from './PaymentSummary'
import { format } from 'date-fns'
import { DateField, InputFields, SelectField } from 'src/components/InputFields'
import { CostField } from 'src/components/InputFields/CostField'
import { floatWithCommas, getFileNameFromUrl } from 'src/utils/formatting'
import { Dropdown } from 'antd'
import SuccessUpload from 'src/assets/images/successUpload.svg'

const CollapsibleExistingPaymentRecord = ({
    action,
    idx,
    item,
    setIsMultiplePartialPaymentMode,
}) => {
    const evidenceFormat = file => {
        return JSON.stringify(file).split('.')[
            JSON.stringify(file).split('.').length - 1
        ]
    }
    return (
        <PaymentSummary
            action={action}
            idx={idx}
            setIsMultiplePartialPaymentMode={setIsMultiplePartialPaymentMode}
        >
            <div>
                <>
                    <div className="APAModalDateTimeWrapper">
                        <div
                            style={{
                                width: '204px',
                            }}
                            className="APAModalDateTimeInput"
                        >
                            <DateField
                                prefilled={action.statusMsg === 'COMPLETED'}
                                disabledCursorIcon={
                                    action.statusMsg === 'COMPLETED'
                                }
                                name="dateOfAction"
                                placeholder={format(
                                    new Date(item.transaction_date),
                                    'dd MMM yyyy',
                                )}
                                openCalendarHandler={() => ''}
                                openCalendar={false}
                                closeCalendarHandler={() => ''}
                                caretStyle="AdminPaymentPlanCaretStyle"
                                disabled={action.statusMsg === 'COMPLETED'}
                            />
                        </div>
                        <div
                            style={{
                                width: '204px',
                            }}
                            className="APAModalDateTimeInput"
                        >
                            {action.keyID !== 'REPAYMENT_SCHEDULE' ? (
                                <div>
                                    <Dropdown
                                        trigger={['click']}
                                        overlayClassName="AdminApprovedTimePicker"
                                        placement="bottomCenter"
                                        overlayStyle={{
                                            marginTop: '18px',
                                            marginLeft: '42px',
                                        }}
                                    >
                                        <div className="APAModalTimeInputWrapper">
                                            <InputFields
                                                title={'Time Paid'}
                                                value={format(
                                                    new Date(
                                                        item.transaction_date,
                                                    ),
                                                    'HH:mm',
                                                )}
                                                iconType={
                                                    action.statusMsg ===
                                                    'COMPLETED'
                                                        ? ''
                                                        : 'DropdownArrow'
                                                }
                                                disabled={
                                                    action.statusMsg ===
                                                    'COMPLETED'
                                                        ? true
                                                        : false
                                                }
                                                prefilled={
                                                    action.statusMsg ===
                                                    'COMPLETED'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </Dropdown>
                                </div>
                            ) : (
                                <DateField
                                    prefilled={action.statusMsg === 'COMPLETED'}
                                    disabledCursorIcon={
                                        action.statusMsg === 'COMPLETED'
                                    }
                                    name="firstRepaymentDate"
                                    handleDateChange={() => ''}
                                    setHandleDateChange={() => ''}
                                    placeholder={
                                        JSON.stringify(action.updatedTime) !==
                                        'null' ? (
                                            <div className="APAMInputAutoFilled">
                                                <p>First Repayment Date</p>
                                                <span>
                                                    {format(
                                                        new Date(
                                                            action.firstRepayment,
                                                        ),
                                                        'd MMM yyyy',
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            'First Repayment Date'
                                        )
                                    }
                                    openCalendarHandler={() => null}
                                    openCalendar={false}
                                    closeCalendarHandler={() => null}
                                    caretStyle="AdminPaymentPlanCaretStyle"
                                    maxDateType="future dates included"
                                />
                            )}
                        </div>
                    </div>
                </>
                <div className="RecordRepayment__PaymentMethodWrapper">
                    <SelectField
                        selectWidth="204px"
                        name="repaymentMethod"
                        type="approvedModal"
                        dropdownPositionRelative
                        withCheckBox={true}
                        optionStyle={{
                            width: '100%',
                            marginTop: '6px',
                            marginBottom: '6px',
                        }}
                        value={item.pssp}
                        prefilled={
                            action.statusMsg === 'COMPLETED' ? true : false
                        }
                        disabled={
                            action.statusMsg === 'COMPLETED' ? true : false
                        }
                        marginRight="10px"
                    />

                    <CostField
                        name="recordRepaymentTotalAmount"
                        currencyCode={'₦'}
                        value={floatWithCommas(item.amount)}
                        inputWidth="204px"
                        fullWidth={false}
                        noFloat
                        marginBottom="10px"
                        selectWidth="90px"
                        prefixDisabled={true}
                        cssStyle={{
                            position: 'relative',
                            top: '6px',
                            marginLeft: '-35px',
                        }}
                        floatTitlePositionLeft={'40px'}
                        title="Amount Paid"
                        zIndex={true}
                        prefilled={action.statusMsg === 'COMPLETED'}
                        disabled={action.statusMsg === 'COMPLETED'}
                    />
                </div>
                <div>
                    <p className="APAActionText">Uploaded evidence</p>
                    {action.statusMsg === 'COMPLETED' && (
                        <div className="APAViewUploadedFile existingUploadedFile">
                            <img
                                src={SuccessUpload}
                                className="UploadLogo"
                                alt="success logo"
                                style={{
                                    marginBottom: '14px',
                                }}
                            />
                            {item.payment_evidence ? (
                                <p>
                                    evidence.
                                    {evidenceFormat(
                                        item.payment_evidence,
                                    ).includes('jpg')
                                        ? 'jpg'
                                        : evidenceFormat(
                                              item.payment_evidence,
                                          ).includes('jpeg')
                                        ? 'jpeg'
                                        : evidenceFormat(
                                              item.payment_evidence,
                                          ).includes('png')
                                        ? 'png'
                                        : evidenceFormat(
                                              item.payment_evidence,
                                          ).includes('pdf')
                                        ? 'pdf'
                                        : 'file'}
                                </p>
                            ) : (
                                'Evidence of this action was not uploaded.'
                            )}
                            {item.payment_evidence && (
                                <a
                                    href={item.payment_evidence}
                                    download={getFileNameFromUrl(
                                        item.payment_evidence,
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View uploaded file
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </PaymentSummary>
    )
}

CollapsibleExistingPaymentRecord.propTypes = {
    action: PropTypes.object,
    idx: PropTypes.string,
    item: PropTypes.object,
    setIsMultiplePartialPaymentMode: PropTypes.func,
}

export default CollapsibleExistingPaymentRecord
