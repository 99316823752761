import { ReactComponent as KycUpdateReviewImage } from 'src/assets/images/kyc-update-review-image.svg'
import { ReactComponent as WarningSecondaryIcon } from 'src/assets/images/warning-icon-secondary.svg'

export const getModalIconType = type => {
    switch (type) {
        case 'kyc-update-review':
            return <KycUpdateReviewImage data-testid="kyc-update-review-icon" />

        case 'warning-secondary':
            return (
                <WarningSecondaryIcon
                    style={{ marginBottom: 20 }}
                    data-testid="warning-secondary-icon"
                />
            )
        default:
            return null
    }
}

export const getModalContentType = type => {
    switch (type) {
        case 'updates-to-review':
            return {
                title: 'Updates to review',
                headerText: 'Your provider has updated your information.',
                subtitle:
                    'Please review the changes in the drawer that opens on the side. Additionally, there are updates to check on the KYC page. These changes are highlighted in each relevant field with a yellow dot, as shown in the image above.',
                iconType: 'kyc-update-review',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
                secondaryBtnText: '',
            }

        case 'updates-to-review-no-drawer':
            return {
                title: 'Updates to review',
                headerText: 'Some updates have been made to your KYC',
                subtitle:
                    'Your provider has updated your information. Please review the changes highlighted on each necessary field with a yellow dot as shown in the image above, and make any necessary edits.',
                iconType: 'kyc-update-review',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
                secondaryBtnText: '',
            }

        case 'review-kyc-updates-before-submit':
            return {
                title: 'Submit your KYC',
                headerText: 'Submit your KYC & Check your eligibility',
                subtitle:
                    'Great work! The next step is to click on submit in order to verify your KYC details and check your eligibility. Kindly note that after submitting, your KYC can no longer be edited.',
                iconType: 'warning-secondary',
                primaryBtnText: 'Proceed to submit',
                footerLeftContent: 'button',
                secondaryBtnText: 'Review KYC again',
            }

        default: {
            return null
        }
    }
}
