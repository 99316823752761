import { useEffect } from 'react'
import styles from './solarsystemslistingsv2.module.scss'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerFooter from 'src/components/ConsumerFooter'
import CookieNotification from 'src/components/CookieNotificiation'
import Header from './components/Header'
import ProfileCard from './components/ProfileCard'
import Systems from './components/Systems'
import SharedForm from './components/SharedForm'
import { SolarSystemsContext, useSolarSystems } from './hooks/useSolarSystems'
import { Skeleton } from 'antd'
import SeoComponent from 'src/components/Seo'
import FrequentlyAskedQuestions from 'src/components/Faq'
import ActionModal from 'src/components/ActionModalV2'
import { actionModalValues } from './data'
import LoginModal from 'src/components/ConsumerLandingPageBody/LoginModal'

const SolarSystemsListingsV2 = () => {
    const {
        ParentWrapper,
        ParentWrapper_Shared,
        ChildWrapper,
        MainWrapper,
        NoMargin,
    } = styles
    const isAllSystems = location.pathname.includes('/all-systems')
    const isPromo = location.pathname.includes('/promo')

    const {
        getEnergyProfileSolutionsData,
        getEnergyProfileSolutionsFetching,
        currentPage,
        setCurrentPage,
        sortBy,
        setSortBy,
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
        filterInputs,
        setFilterInputs,
        clearFilters,
        showBuildCustomModal,
        setShowBuildCustomModal,
        getEnergyProfileData,
        getEnergyProfileFetching,
        isClosedCluster,
        setIsClosedCluster,
        showClosedClusterModal,
        action,
        actionModalOpen,
        openActionModal,
        closeActionModal,
        showLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        setLoginInputs,
        otpMethod,
        setOtpMethod,
        actionModalHandler,
        actionModalToast,
        actionModalPrimaryBtnLoading,
        actionModalSecondaryHandler,
        setReturningCustomerId,
        openLoginNoDetailsProvided,
        closeLoginNoDetailsProvided,
        initialDetailsNotProvided,
        showSharedProfile,
        setShowSharedProfile,
        onLoginSuccess,
        goBack,
        isPromotional,
        showLoginModalCloseButton,
        setHasDiscardedDeal,
    } = useSolarSystems()

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(action)

    const canCloseActionModal =
        action !== 'customer-exists' && action !== 'confirm-continue-to-package'
    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )

    const closeSharedModal = () => {
        setShowSharedProfile(false)
    }

    useEffect(() => {
        const isClosedCluster =
            getEnergyProfileData?.data?.data?.promotional_type === 'PRIVATE'
        setIsClosedCluster(isClosedCluster)

        if (getEnergyProfileData) {
            setShowSharedProfile(location.pathname.includes('/shared'))
        }
        // eslint-disable-next-line
    }, [getEnergyProfileData])

    return (
        <>
            <SeoComponent
                title="Solar systems | SunFi | Simplifying and Scaling Clean Energy"
                tracker="SOLAR_SYSTEM_LISTING"
                keywords="SunFi, Inverters, Solar Panels, 10VA solar system, 10kw solar battery"
            />

            <SolarSystemsContext.Provider
                value={{
                    getEnergyProfileSolutionsData,
                    getEnergyProfileSolutionsFetching,
                    currentPage,
                    setCurrentPage,
                    sortBy,
                    setSortBy,
                    getEnergyProfileSolutionsFilterData,
                    getEnergyProfileSolutionsFilterFetching,
                    filterInputs,
                    setFilterInputs,
                    clearFilters,
                    showBuildCustomModal,
                    setShowBuildCustomModal,
                    getEnergyProfileData,
                    getEnergyProfileFetching,
                    isClosedCluster,
                    setIsClosedCluster,
                    showClosedClusterModal,
                    isPromo,
                    action,
                    actionModalOpen,
                    openActionModal,
                    closeActionModal,
                    setLoginInputs,
                    setReturningCustomerId,
                    openLoginNoDetailsProvided,
                    closeLoginNoDetailsProvided,
                    initialDetailsNotProvided,
                    showSharedProfile,
                    setShowSharedProfile,
                    onLoginSuccess,
                    goBack,
                    isPromotional,
                    setHasDiscardedDeal,
                }}
            >
                {/* modals */}
                <ActionModal
                    actionModalOpen={actionModalOpen}
                    title={actionModalTitle}
                    headerText={actionModalHeaderText}
                    subTitle={actionModalSubtitle}
                    actionType={actionModalActionType}
                    primaryBtnText={actionModalPrimaryBtnText}
                    handlePrimaryBtnClick={() => actionModalHandler()}
                    closable={canCloseActionModal}
                    onCancel={() => {
                        if (canCloseActionModal) {
                            closeActionModal()
                        } else () => {}
                    }}
                    noBackLink
                    footerLeftContent={actionModalFooterLeftContent}
                    secondaryBtnText={actionModalSecondaryBtnText}
                    handleSecondaryBtnClick={actionModalSecondaryHandler}
                    primaryBtnLoading={actionModalPrimaryBtnLoading}
                    toastError={actionModalToast?.showToast}
                    errorMessage={actionModalToast?.toastMessage}
                    errorMessageType={actionModalToast?.messageType}
                    kycSecondaryButtonStopLoading
                />

                <LoginModal
                    showModal={showLoginModal}
                    setShowModal={() => {}}
                    onCancel={closeLoginNoDetailsProvided}
                    loginModalContent={loginModalContent}
                    setLoginModalContent={setLoginModalContent}
                    inputs={loginInputs}
                    otpMethod={otpMethod}
                    setOtpMethod={setOtpMethod}
                    preventConsumerRedirect
                    handlePostSuccess={data => onLoginSuccess(data)}
                    showCloseIcon={showLoginModalCloseButton}
                    initialDetailsNotProvided={initialDetailsNotProvided}
                />

                {/* page */}
                <ConsumerNavigation
                    showUserBtn
                    page={isClosedCluster ? 'closedCluster' : 'getStarted'}
                    showProfileDropdown={
                        returningActiveOrClosedCustomer?.status
                    }
                />
                <div
                    className={`${ParentWrapper} ${
                        showSharedProfile && ParentWrapper_Shared
                    }`}
                >
                    {showSharedProfile && (
                        <SharedForm
                            closeSharedModal={closeSharedModal}
                            openLoginNoDetailsProvided={
                                openLoginNoDetailsProvided
                            }
                            closeLoginNoDetailsProvided={
                                closeLoginNoDetailsProvided
                            }
                            setHasDiscardedDeal={setHasDiscardedDeal}
                        />
                    )}
                    <div className={ChildWrapper}>
                        <div
                            className={`${MainWrapper} ${
                                isAllSystems && NoMargin
                            }`}
                        >
                            {!isClosedCluster && <Header />}
                            {!isAllSystems && !getEnergyProfileFetching ? (
                                <ProfileCard />
                            ) : !isAllSystems && getEnergyProfileFetching ? (
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 6, width: '100%' }}
                                />
                            ) : null}
                            <Systems />
                            {isClosedCluster && (
                                <FrequentlyAskedQuestions
                                    useV2
                                    page="closedCluster"
                                />
                            )}
                        </div>
                    </div>

                    <CookieNotification />
                    <ConsumerFooter />
                </div>
            </SolarSystemsContext.Provider>
        </>
    )
}

export default SolarSystemsListingsV2
