import receiptImg from 'src/assets/images/receipt-item.svg'
import s from '.././MembersList/styles.module.scss'
import PropTypes from 'prop-types'
import receipt from 'src/assets/images/receipt.svg'

const EmptyState = ({ heading, description, v2 }) => {
    return (
        <div className={s.emptyState}>
            <img src={v2 ? receipt : receiptImg} alt="receipt" />
            <h4>{heading}</h4>
            <p>{description}</p>
        </div>
    )
}

EmptyState.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    v2: PropTypes.bool,
}

export default EmptyState
