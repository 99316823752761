import { decodeUserInfo } from './auth'

export const getUserType = () => {
    const showPartnerKycAssist =
        process.env.REACT_APP_FLAG_PARTNER_KYC_ASSIST === 'true'

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const isProvider = !!userInfo?.providerId

    const isProviderAndPartnerKycAssistAllowed =
        isProvider && showPartnerKycAssist

    return {
        isAdminUser,
        isProvider,
        isProviderAndPartnerKycAssistAllowed,
    }
}
