import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import actionModalStyles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import s from './styles.module.scss'
import { depositChangeModalValues } from './utils'
import { InputFields } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import useIncreaseUpfrontDeposit from './hooks/useIncreaseUpfrontDeposit'
import { ReactComponent as InfoIcon } from 'src/assets/images/info-warning-blue2.svg'
import { formatDepositInfo } from './utils'
import Toast from 'src/components/Toast'

const IncreaseUpfrontDepositModal = ({
    showModal,
    closeDepositChangeModal,
    depositChangeAction,
    setDepositChangeAction,
    openCardDetailsModal,
    estimationId,
    initialDeposit,
}) => {
    const {
        handlePrimaryBtnClick,
        onCancel,
        inputs,
        errors,
        handleOnBlur,
        handleChange,
        updatedUpfrontDepositDetails,
        primaryBtnLoading,
        modalToast,
    } = useIncreaseUpfrontDeposit({
        depositChangeAction,
        setDepositChangeAction,
        closeDepositChangeModal,
        openCardDetailsModal,
        estimationId,
        initialDeposit,
    })
    const {
        modalTitle,
        modalContent,
        actionIcon,
        actionHeading,
        actionDescription,
        primaryBtnText,
        footerLeftContent,
    } = depositChangeModalValues(depositChangeAction)
    const { ActionHeader, ActionContainer, ActionText } = actionModalStyles

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={onCancel}
            title={modalTitle}
            primaryBtnText={primaryBtnText}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            footerLeftContent={footerLeftContent}
            primaryBtnLoading={primaryBtnLoading}
        >
            <>
                {modalToast?.showToast && (
                    <Toast
                        messageType={modalToast?.messageType}
                        message={modalToast?.toastMessage}
                        position={'absolute'}
                        top={30}
                        closable={true}
                    />
                )}
                {modalContent === 'action-modal' ? (
                    <div className={ActionContainer}>
                        {actionIcon}
                        <h2 className={ActionHeader}>{actionHeading}</h2>
                        <p className={ActionText} style={{ maxWidth: 345 }}>
                            {actionDescription}
                        </p>
                    </div>
                ) : modalContent === 'upfront-deposit-form' ? (
                    <div className={s.formWrapper}>
                        <div className={s.formSection}>
                            <h3>
                                Enter an amount that is not less than
                                <strong> ₦{initialDeposit} </strong>
                            </h3>
                            <div className={s.originalDeposit}>
                                <p>Original deposit</p>
                                <span>
                                    <strong>₦ {initialDeposit} </strong>
                                    <InfoIcon />
                                </span>
                            </div>

                            <InputFields
                                onBlur={handleOnBlur}
                                handleChange={handleChange}
                                name="amount"
                                value={inputs?.amount}
                                errorMessage={errors?.amount}
                                inputPlaceholder="Enter new upfront deposit"
                                paddingBottom="28px"
                                title={
                                    inputs?.amount === ''
                                        ? ''
                                        : 'Enter new upfront deposit'
                                }
                                type="number"
                            />
                        </div>
                        <VerticalSpacer top={32} />
                    </div>
                ) : (
                    modalContent === 'payment-details' && (
                        <div className={s.paymentDetails}>
                            <div className={s.header}>
                                <h6>Complete your upfront deposit payment.</h6>
                                <p>
                                    Your upfront deposit has been increased. You
                                    are required to pay the total amount due to
                                    finalize the update. Your monthly repayment
                                    has been adjusted in line with this update.
                                </p>
                            </div>

                            <div className={s.totalAmount}>
                                <p>Total Amount due</p>
                                <h5>
                                    ₦
                                    {
                                        updatedUpfrontDepositDetails?.new_upfront_deposit_amount
                                    }
                                </h5>
                            </div>

                            <div className={s.depositInfo}>
                                {formatDepositInfo(
                                    updatedUpfrontDepositDetails,
                                )?.map(item => (
                                    <div key={item.label} className={s.item}>
                                        <p>{item.label}</p>
                                        <h6>{item.value}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </>
        </ConsumerModalBase>
    )
}

IncreaseUpfrontDepositModal.propTypes = {
    showModal: PropTypes.bool,
    closeDepositChangeModal: PropTypes.func,
    depositChangeAction: PropTypes.string,
    setDepositChangeAction: PropTypes.func,
    openCardDetailsModal: PropTypes.func,
    estimationId: PropTypes.string,
    initialDeposit: PropTypes.string,
}

export default IncreaseUpfrontDepositModal
