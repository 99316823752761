import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import {
    consumerHomeValidationSchema,
    NewLandingPageFormSchema,
} from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import {
    // paymentPlansData,
    paymentPlansMap,
    // reasonForSolarData,
    reasonForSolarMap,
    CONTACT_METHODS,
    PAYMENT_TYPES,
    electricityPerDayDataMap,
    paymentPlansMapReverse,
    reasonForSolarMapReverse,
} from '../data'
import useValidateExistingCustomer from './useValidateExistingCustomer'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useGetRecommendations from './useGetRecommendations'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { v4 as uuidv4 } from 'uuid'
import { getFormResponse } from 'src/api/shoppingExperience/landingPage'

import { floatWithCommas } from 'src/utils/formatting'
import { decrypt, encrypt } from 'src/utils/cryptography'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useRegisterDropOff from './useRegisterDropOff'
import { removeCountryCode } from 'src/utils/NumberFormatter'
import { urgencyOptionsMap } from 'src/pages/Consumer/SmartShoppingExperience/Home/SelectUseType/data'
import { clearVisitCount } from 'src/utils/visitCount'
import useGetReturningCustomerStatus from './useGetReturningCustomerStatus'
import useDiscardDeal from './useDiscardDeal'
import { decodeUserInfo } from 'src/utils/auth'
import useInitiateLoginMutation from './useInitiateLoginMutation'
import { errorHandler } from 'src/utils/errorHandler'
import { formatEntryFormDataForMixpanel } from '../utils'
import { setOnboardingInfo } from 'src/utils/userOnboardingInfoManager'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'
const shouldPersistUserInfo =
    process.env.REACT_APP_FLAG_PERSIST_USER_GET_STARTED_INFO === 'true'

export const useConsumerLandingPage = () => {
    const { validateRequestInfoMutation, loading, setLoading, setErrorState } =
        useGetRecommendations()
    const history = useHistory()
    const isCustomBuild = history.location.state?.buildCustomSystem
    const isHomepage =
        history.location?.pathname === '/' ||
        history.location?.pathname === '/home'
    const customAppliances = history.location.state?.appliances

    const [errors, setErrors] = useState({})

    const { validateUserIdMutation, isExisting, validateUserIdLoading } =
        useValidateExistingCustomer({
            setErrors,
        })
    const [inputs, setInputs] = useState({
        firstName: '',
        email: '',
        phone: '',
        location: '',
        paymentPlan: '',
        monthlyPayment: '',
        generatorSpend: '',
        electricityPerDay: '',
        reasonForSolar: '',
        generatorSize: '',
        generatorMaintenance: '',
    })
    const [energyNeeds, setEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            ...(isCustomBuild && customAppliances
                ? { power_rating: '' }
                : { powerRating: '' }),
            backup_hours_night: '',
            powerRating: '',
            unitOfMeasurement: '',
        },
    ])

    const [checked, setChecked] = useState(true)
    const [countryCode] = useState('+234')
    const [contactMethod, setContactMethod] = useState(CONTACT_METHODS.EMAIL)
    const [emailIsExistingChecked, setEmailIsExistingChecked] = useState(false)
    const [phoneIsExistingChecked, setPhoneIsExistingChecked] = useState(false)
    const [knowsGenMonthlyCost, setKnowsGenMonthlyCost] = useState('')
    const [showNext, setShowNext] = useState(false)
    const [generatorUse, setGeneratorUse] = useState('yes')
    const [paymentModel, setPaymentModel] = useState(PAYMENT_TYPES.NULL)
    const [canMountSolar, setCanMountSolar] = useState(null)
    const [useType, setUseType] = useState('')
    const [useTypeFormAction, setUseTypeFormAction] = useState('') // get-started || view-all-solutions
    const [useTypeFormInputs, setUseTypeFormInputs] = useState({
        urgency: '',
        referral_source: [],
    })

    const [showUseTypeModal, setShowUseTypeModal] = useState(false)
    const [modalAction, setModalAction] = useState('') // notify-user-who-has-not-started-kyc | discard-deal
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [returningCustomerId, setReturningCustomerId] = useState('')
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [actionModalToast, setActionModalToast] = useState(toastData)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginModalContent, setLoginModalContent] = useState('') // select-otp-method | enter-otp
    const [otpMethod, setOtpMethod] = useState('') // email | phone
    const [hasStartedForm, setHasStartedForm] = useState(false)

    const { isMobile } = useMediaQueries()
    const { errorAlert } = useCustomToast()
    const userInfo = decodeUserInfo()

    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )
    const loginInputs = {
        email: inputs?.email,
        phone: formatPhoneNumber(inputs?.phone),
    }

    const resetToast = () => {
        setTimeout(() => {
            setActionModalToast(toastData)
        }, 2000)
    }

    const { mutate: initiateLogin, isLoading: initiateLoginLoading } =
        useInitiateLoginMutation(
            loginInputs,
            () => {
                setLoginModalContent('enter-otp')
                setActionModalOpen(false)
                setShowLoginModal(true)
            },
            err => {
                setActionModalToast({
                    showToast: true,
                    toastMessage: errorHandler(err),
                    messageType: 'error-secondary',
                })
                resetToast()
            },
            'consumer',
            pageUrlName,
            pageTracker,
            pageTitle,
        )

    const getReturningCustomerStatusOnSuccess = data => {
        const customerStatus = data?.status
        if (data?.id) setReturningCustomerId(data?.id)

        switch (customerStatus) {
            case 'KYC_NOT_STARTED':
                setModalAction('notify-user-who-has-not-started-kyc')
                setActionModalOpen(true)
                break
            case 'KYC_STARTED':
                setModalAction('notify-user-who-has-started-kyc')
                setActionModalOpen(true)
                break
            case 'NOT_SELECTED_PLAN':
                setModalAction('notify-user-with-an-approved-deal')
                setActionModalOpen(true)
                break
            case 'ACTIVE_OR_CLOSED_PLAN':
                setModalAction('notify-user-with-an-active-or-closed-plan')
                setActionModalOpen(true)
                break
            case 'NEW_CUSTOMER':
                if (isHomepage) {
                    handleGetStarted()
                } else {
                    handleShowNext()
                }
                break
        }
    }

    const { getReturningCustomerStatus, getReturningCustomerStatusLoading } =
        useGetReturningCustomerStatus({
            email: inputs?.email,
            phone: formatPhoneNumber(inputs?.phone),
            onSuccess: data => {
                getReturningCustomerStatusOnSuccess(data)
            },
        })

    const {
        getReturningCustomerStatus: getNewStatusAfterDiscardingDeal,
        getReturningCustomerStatusLoading:
            getNewStatusAfterDiscardingDealLoading,
    } = useGetReturningCustomerStatus({
        email: inputs?.email,
        phone: formatPhoneNumber(inputs?.phone),
        onSuccess: data => {
            const customerStatus = data?.status
            if (data?.id) setReturningCustomerId(data?.id)
            if (customerStatus === 'ACTIVE_OR_CLOSED_PLAN') {
                setModalAction('notify-user-with-an-active-or-closed-plan')
            } else {
                setModalAction('discard-deal-success')
            }
        },
        onError: err => {
            setActionModalToast({
                showToast: true,
                toastMessage: errorHandler(err),
                messageType: 'error-secondary',
            })
            resetToast()
        },
        page: 'landing-page-form-post-discard',
    })

    const discardDealOnSuccess = () => {
        getNewStatusAfterDiscardingDeal()
    }

    const discardDealOnError = errorMsg => {
        setActionModalToast({
            showToast: true,
            toastMessage: errorMsg,
            messageType: 'error-secondary',
        })
        resetToast()
    }

    const { discardDealMutate, discardDealLoading } = useDiscardDeal({
        id: returningCustomerId,
        onSuccess: discardDealOnSuccess,
        onError: discardDealOnError,
    })

    const actionLoading =
        discardDealLoading ||
        validateUserIdLoading ||
        initiateLoginLoading ||
        getNewStatusAfterDiscardingDealLoading

    const toggleShowUseTypeModal = () => setShowUseTypeModal(prev => !prev)
    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'

    useEffect(() => {
        let decryptedPhoneNumber = ''
        let decryptedEmailAddress = ''

        if (isCustomBuild) {
            customAppliances && setEnergyNeeds(customAppliances)
            const decryptedData =
                localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
                JSON.parse(
                    decrypt(
                        localStorage?.getItem('sunfiUserSSEGettingStartedInfo'),
                    ),
                )
            if (!decryptedData) return
            else {
                decryptedPhoneNumber = removeCountryCode(decryptedData.phone)
                decryptedEmailAddress = decryptedData.email
                const typeOfContact = decryptedData.contactType
                const methodOfContact =
                    typeOfContact === 'email'
                        ? CONTACT_METHODS.EMAIL
                        : CONTACT_METHODS.PHONE
                setContactMethod(methodOfContact)

                if (!allowReturningCustomers) {
                    handleInputSubmit(
                        typeOfContact,
                        typeOfContact === 'email'
                            ? decryptedEmailAddress
                            : decryptedPhoneNumber,
                        methodOfContact,
                    )
                }
            }

            setInputs(prev => ({
                ...prev,
                email: returningActiveOrClosedCustomer?.status
                    ? userInfo?.email
                    : decryptedEmailAddress ?? '',
                phone: returningActiveOrClosedCustomer?.status
                    ? removeCountryCode(userInfo?.phoneNumber)
                    : decryptedPhoneNumber ?? '',
            }))
        }
        clearVisitCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shouldPersistUserInfo) {
            const sunfiUserSSEGettingStartedPersistJSON =
                sessionStorage.getItem('sunfiUserSSEGettingStartedPersist') &&
                decrypt(
                    sessionStorage.getItem('sunfiUserSSEGettingStartedPersist'),
                )

            if (sunfiUserSSEGettingStartedPersistJSON) {
                const sunfiUserSSEGettingStartedPersist = JSON.parse(
                    sunfiUserSSEGettingStartedPersistJSON,
                )

                if (sunfiUserSSEGettingStartedPersist) {
                    setInputs(prev => ({
                        ...prev,
                        ...sunfiUserSSEGettingStartedPersist.inputs,
                    }))

                    setUseTypeFormInputs(prev => ({
                        ...prev,
                        ...sunfiUserSSEGettingStartedPersist.useTypeFormInputs,
                    }))

                    setUseType(sunfiUserSSEGettingStartedPersist.useType)
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        registerDropOffMutate,
        getStartedBtnLoading,
        viewAllSolutionsBtnLoading,
    } = useRegisterDropOff(
        {
            email: inputs?.email,
            phone_number: formatPhoneNumber(inputs?.phone),
            first_name: inputs.firstName,
            ...(useType === '' ? {} : { customer_type: useType }),
            urgency: urgencyOptionsMap[useTypeFormInputs.urgency],
            ...(useTypeFormInputs.referral_source === ''
                ? {}
                : { referral_source: useTypeFormInputs.referral_source }),
        },
        useType,
        useTypeFormAction,
    )

    // handle track entry form activity

    const handleTrackEntryFormActivity = (
        activity = 'sseStartLandingPageEntryForm',
        name,
        value,
    ) => {
        const data = {
            ...inputs,
            useType,
            ...useTypeFormInputs,
        }

        if (
            sessionStorage.getItem('sunfiUserSSEGettingStartedPersist') ||
            (activity === 'sseStartLandingPageEntryForm' && hasStartedForm)
        ) {
            return
        }

        if (value?.length) {
            data[name] = value
            setHasStartedForm(true)
        }

        if (data.phone) {
            data.phone = formatPhoneNumber(data.phone)
        }

        const payload = formatEntryFormDataForMixpanel(data)

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers[activity].action,
            eventTrackers[activity].label,
            eventTrackers[activity].category,
            ['MP', 'GA'],
            'event',
            {
                event: payload,
            },
        )
    }

    //get returning consumer email
    const queryParams = new URLSearchParams(history?.location?.search)
    const consumerEmail = queryParams.get('email')
    // get returning consumer phone
    const consumerPhone = queryParams.get('phone')?.replace('234', '')

    const handleInputChange = event => {
        const { name, value } = event.target
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        if (name === 'monthlyPayment') {
            setInputs(prev => ({
                ...prev,
                monthlyPayment:
                    value === '0'
                        ? ''
                        : numberRegex.test(value)
                        ? floatWithCommas(value)
                        : inputs.monthlyPayment,
            }))
        } else if (name === 'generatorSpend') {
            setInputs(prev => ({
                ...prev,
                generatorSpend: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.generatorSpend,
            }))
        } else if (name === 'email') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else if (name === 'phone' || name === 'firstName') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }

        if (errors[name]) {
            delete errors[name]
        }

        if (name === 'email') {
            setEmailIsExistingChecked(false)
        }
        if (name === 'phone') {
            setPhoneIsExistingChecked(false)
        }
    }

    const handleChecked = () => {
        setChecked(!checked)
    }
    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const handleInputBlur = e => {
        const { name, value } = e.target

        handleTrackEntryFormActivity(
            'sseStartLandingPageEntryForm',
            name,
            value,
        )

        if (allowReturningCustomers) {
            return
        }

        NewLandingPageFormSchema({})
            .validate(
                { [name]: value },
                { context: { fields: [name] }, abortEarly: false },
            )
            .then(() => {
                let errList = { ...errors }
                delete errList[name]

                setErrors(errList)

                let fieldsToSubmit = ['email', 'phone']

                if (fieldsToSubmit.includes(name)) {
                    setContactMethod(CONTACT_METHODS[name.toUpperCase()])
                    handleInputSubmit(
                        name,
                        value,
                        CONTACT_METHODS[name.toUpperCase()],
                    )
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })

                setErrors(prev => ({ ...prev, ...errList }))
            })
    }

    const handleInputSubmit = async (
        name,
        value,
        methodOfContact = contactMethod,
    ) => {
        const existingConsumerPayload =
            methodOfContact === CONTACT_METHODS.EMAIL
                ? { email: value }
                : { phone_number: formatPhoneNumber(value, countryCode) }

        if (
            !existingConsumerPayload['email'] &&
            methodOfContact === CONTACT_METHODS.EMAIL
        ) {
            return
        }
        if (
            existingConsumerPayload['phone_number']?.length < 5 &&
            methodOfContact === CONTACT_METHODS.PHONE
        ) {
            return
        }

        const field =
            methodOfContact === CONTACT_METHODS.EMAIL ? 'email' : 'phone number'

        const result = await validateUserIdMutation.mutateAsync(
            existingConsumerPayload,
        )

        const exists = result?.data?.data
        if (exists) {
            setErrors({
                ...errors,
                [name]: `This ${field} already exist, you can try Sign In`,
            })
        } else {
            delete errors[name]
        }
        setErrors(prevErrors => {
            delete prevErrors['checkExistingStatus']
            return { ...prevErrors }
        })

        if (field === 'email') {
            setEmailIsExistingChecked(true)
        } else {
            setPhoneIsExistingChecked(true)
        }
    }

    const validateNewFormInputs = (requiredFields = [], isComplete = false) => {
        const errorMessages = {
            email: 'Please verify your email',
            phone: 'Please verify phone number',
        }

        NewLandingPageFormSchema(errorMessages)
            .validate(
                {
                    ...inputs,
                    urgency: useTypeFormInputs.urgency,
                    useType,
                    referral_source: useTypeFormInputs.referral_source,
                },
                {
                    context: { fields: requiredFields },
                    abortEarly: false,
                },
            )
            .then(() => {
                setErrors({})

                if (isComplete) {
                    handleTrackEntryFormActivity(
                        'sseCompleteLandingPageEntryForm',
                        'useType',
                        requiredFields.includes('useType')
                            ? useType.toLocaleLowerCase()
                            : 'view all available systems',
                    )

                    localStorage.setItem(
                        'sunfiUserSSEGettingStartedInfo',
                        encrypt(
                            JSON.stringify({
                                ...(inputs?.firstName !== '' && {
                                    firstName: inputs.firstName,
                                }),
                                ...(inputs?.email !== '' && {
                                    email: inputs.email,
                                }),
                                ...(inputs?.phone !== '' && {
                                    phone: formatPhoneNumber(inputs.phone),
                                }),
                                useType,
                                contactType: 'email',
                            }),
                        ),
                    )

                    setOnboardingInfo({
                        type: 'DTC',
                        context: {},
                    })

                    if (shouldPersistUserInfo) {
                        sessionStorage.setItem(
                            'sunfiUserSSEGettingStartedPersist',
                            encrypt(
                                JSON.stringify({
                                    inputs,
                                    useTypeFormInputs,
                                    useType,
                                }),
                            ),
                        )
                    }

                    registerDropOffMutate()
                } else {
                    setShowUseTypeModal(true)
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleGetStarted = () => {
        validateNewFormInputs([
            'firstName',
            'email',
            'phone',
            'urgency',
            'referral_source',
        ])
    }

    const handleGetStartedContinue = () => {
        setUseTypeFormAction('get-started')
        validateNewFormInputs(['useType'], true)
    }

    const handleViewAllSystems = () => {
        setUseTypeFormAction('view-all-solutions')
        validateNewFormInputs([], true)
    }

    const handleShowNext = () => {
        localStorage.setItem(
            'sunfiUserSSEGettingStartedInfo',
            encrypt(
                JSON.stringify({
                    email: inputs.email,
                    ...(inputs?.phone !== '' && {
                        phone: formatPhoneNumber(inputs.phone),
                    }),
                    contactType:
                        contactMethod === CONTACT_METHODS.EMAIL
                            ? 'email'
                            : 'phone',
                    energyNeeds,
                    knowsGenMonthlyCost: knowsGenMonthlyCost,
                }),
            ),
        )

        setOnboardingInfo({
            type: 'DTC',
            context: {},
        })

        if (!allowReturningCustomers) {
            if (!emailIsExistingChecked && contactMethod === '1') {
                setErrors({
                    ...errors,
                    email: 'Please verify your email',
                })
                return
            } else if (!phoneIsExistingChecked && contactMethod === '2') {
                setErrors({
                    ...errors,
                    phone: 'Please verify phone number',
                })
                return
            }
        }

        //  eslint-disable-next-line
        const emptyItems =
            energyNeeds.filter(
                item =>
                    item.name === '' ||
                    item.quantity === '' ||
                    item.backup_hours === '' ||
                    item.backup_hours_night === '' ||
                    (item.powerRating ?? item.power_rating) === '' ||
                    item.unitOfMeasurement === '',
            ) ?? []

        consumerHomeValidationSchema(
            contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
            showNext,
            knowsGenMonthlyCost,
        )
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                if (emptyItems?.length > 0) {
                    errorAlert(
                        'Please fill in at least one appliance',
                        isMobile ? 320 : 511,
                        110,
                    )

                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })
                    return
                }
                setShowNext(true)
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }

    // make api call to return available systems
    function handleSubmit() {
        setErrorState('')

        consumerHomeValidationSchema(
            contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
            showNext,
            knowsGenMonthlyCost,
            generatorUse === 'yes' ? true : false,
            paymentModel,
            canMountSolar,
        )
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                getRecommendations()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
                errorAlert('You omitted a required field.')
            })
    }

    function getRecommendations() {
        setLoading(true)
        const reshapedEnergyNeeds = energyNeeds.map(item => {
            return {
                name: item.name,
                quantity: item.quantity,
                backup_hours: item.backup_hours,
                backup_hours_night: item.backup_hours_night,
                power_rating: item.powerRating ?? item.power_rating,
                unit_of_measurement: item.unitOfMeasurement,
            }
        })
        const generatorSpend =
            inputs.generatorSpend === ''
                ? 0
                : typeof inputs.generatorSpend === 'string'
                ? inputs.generatorSpend.replaceAll(',', '')
                : inputs.generatorSpend
        const payload = {
            can_mount_solar: canMountSolar,
            gets_required_grid_hours: parseInt(
                inputs.electricityPerDay.replace(/hours?/i, ''),
            ),
            feasible_repayment_tenure: paymentPlansMap[inputs.paymentPlan],
            feasible_monthly_payment:
                typeof inputs.monthlyPayment === 'string'
                    ? inputs.monthlyPayment.replaceAll(',', '')
                    : inputs.monthlyPayment,
            generator_maintenance_expense: generatorSpend,
            state_of_installation: inputs.location,
            user_id:
                contactMethod === CONTACT_METHODS.EMAIL
                    ? inputs.email
                    : formatPhoneNumber(inputs.phone),
            payment_model: paymentModel,
            solution_reason: reasonForSolarMap[inputs.reasonForSolar],
            appliances: reshapedEnergyNeeds,
        }

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseGetSolarRecommendations'].action,
            eventTrackers['sseGetSolarRecommendations'].label,
            eventTrackers['sseGetSolarRecommendations'].category,
            ['MP', 'GA'],
            'event',
            {
                event: payload,
            },
        )

        validateRequestInfoMutation.mutate(payload)
    }
    //implementation for returning consumers that have previously filled the conversational form but don't have any recomendation

    const getFormResponseMutation = useMutation({
        mutationFn: payload => getFormResponse(payload),
        onSuccess: data => {
            const inputData = data?.data?.data

            // if nothing is returned, this means the consumer has not filled the form before
            if (Object.keys(inputData).length === 0) {
                history.push('/consumer/get-started')
            } else {
                const selectedAppliances = data?.data?.data.appliances?.map(
                    appliance => ({
                        id: uuidv4(),
                        backup_hours: appliance.backup_hours,
                        backup_hours_night: appliance.backup_hours_night,
                        name: appliance.name,
                        quantity: appliance.quantity,
                        powerRating: appliance.power_rating,
                        unitOfMeasurement: appliance.unit_of_measurement,
                    }),
                )
                setEnergyNeeds(selectedAppliances)

                const gridHours = inputData?.gets_required_grid_hours

                const initialInputs = {
                    electricityPerDay: electricityPerDayDataMap[gridHours],
                    generatorSpend: inputData?.generator_maintenance_expense,
                    location: inputData?.state_of_installation,
                    monthlyPayment: inputData?.feasible_monthly_payment,
                    paymentPlan:
                        paymentPlansMapReverse[
                            inputData?.feasible_repayment_tenure
                        ]?.[0],
                    reasonForSolar:
                        reasonForSolarMapReverse[
                            inputData?.solution_reason
                        ]?.[0],
                    email: inputData?.user_id,
                    phone: consumerPhone?.trim(),
                }
                setInputs(initialInputs)
                setCanMountSolar(inputData?.can_mount_solar ? 'true' : 'false')
                setPaymentModel(
                    inputData?.payment_model === 'Subscription'
                        ? 'Subscription'
                        : 'Lease to Own',
                )
                if (consumerPhone) {
                    setContactMethod('2')
                }
                checkExisting()
            }
        },
        onError: error => {
            errorAlert(error?.response?.data, isMobile ? 320 : 511, 110)
        },
    })

    const checkExisting = async () => {
        const name = consumerEmail ? 'email' : 'phone'
        const payload = {
            ...(consumerEmail
                ? {
                      email: consumerEmail,
                  }
                : { phone_number: formatPhoneNumber(consumerPhone) }),
        }
        const result = await validateUserIdMutation.mutateAsync(payload)
        const exists = result?.data?.data
        if (exists) {
            setErrors({
                ...errors,
                [name]: `This ${name} already exist, you can try Sign In`,
            })
        } else {
            delete errors[name]
            consumerEmail
                ? setEmailIsExistingChecked(true)
                : setPhoneIsExistingChecked(true)
        }
    }

    const handleCheckReturningCustomerStatus = () => {
        if (isHomepage) {
            NewLandingPageFormSchema()
                .validate(
                    {
                        ...inputs,
                        urgency: useTypeFormInputs.urgency,
                        useType,
                        referral_source: useTypeFormInputs.referral_source,
                    },
                    {
                        context: {
                            fields: [
                                'firstName',
                                'email',
                                'phone',
                                'urgency',
                                'referral_source',
                            ],
                        },
                        abortEarly: false,
                    },
                )
                .then(() => {
                    setErrors({})
                    getReturningCustomerStatus()
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        } else {
            // custom build page
            if (returningActiveOrClosedCustomer?.status) {
                handleShowNext()
                return
            }
            //  eslint-disable-next-line
            const emptyItems =
                energyNeeds.filter(
                    item =>
                        item.name === '' ||
                        item.quantity === '' ||
                        item.backup_hours === '' ||
                        item.backup_hours_night === '' ||
                        (item.powerRating ?? item.power_rating) === '' ||
                        item.unitOfMeasurement === '',
                ) ?? []

            consumerHomeValidationSchema(
                contactMethod === CONTACT_METHODS.EMAIL ? 'email' : 'phone',
                showNext,
                knowsGenMonthlyCost,
            )
                .validate(inputs, {
                    abortEarly: false,
                })
                .then(() => {
                    setErrors({})
                    if (emptyItems?.length > 0) {
                        errorAlert(
                            'Please fill in at least one appliance',
                            isMobile ? 320 : 511,
                            110,
                        )

                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                        return
                    }
                    getReturningCustomerStatus()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })
                    setErrors(errList)
                    errorAlert('You omitted a required field.')
                })
        }
    }

    const closeActionModal = () => {
        setActionModalOpen(false)
        setModalAction('')
    }

    const closeLoginModal = () => {
        setShowLoginModal(false)
        setLoginModalContent('')
    }

    const validateReturningUserContact = async () => {
        const [emailResult, phoneResult] = await Promise.all([
            validateUserIdMutation.mutateAsync({ email: inputs.email }),
            validateUserIdMutation.mutateAsync({
                phone_number: formatPhoneNumber(inputs.phone, countryCode),
            }),
        ])

        const emailExists = emailResult?.data?.data
        const phoneExists = phoneResult?.data?.data

        const otpMethod = emailExists ? 'email' : 'phone'
        setOtpMethod(otpMethod)

        if (emailExists && phoneExists) {
            setLoginModalContent('select-otp-method')
            setActionModalOpen(false)
            setShowLoginModal(true)
        } else {
            initiateLogin(otpMethod)
        }
    }

    const actionModalHandler = () => {
        switch (modalAction) {
            case 'notify-user-who-has-not-started-kyc':
            case 'notify-user-who-has-started-kyc':
            case 'notify-user-with-an-approved-deal':
            case 'notify-user-with-an-active-or-closed-plan':
                validateReturningUserContact()
                break
            case 'confirm-discard-deal-for-user-who-has-not-started-kyc':
            case 'confirm-discard-deal-for-user-who-has-started-kyc':
            case 'confirm-discard-deal-for-user-with-an-approved-deal':
                discardDealMutate()
                break
            case 'discard-deal-success':
                closeActionModal()
                if (isHomepage) {
                    handleGetStarted()
                } else {
                    handleShowNext()
                }
                break
        }
    }

    const secondaryActionModalHandler = () => {
        switch (modalAction) {
            case 'notify-user-who-has-not-started-kyc':
                setModalAction(
                    'confirm-discard-deal-for-user-who-has-not-started-kyc',
                )
                break
            case 'notify-user-who-has-started-kyc':
                setModalAction(
                    'confirm-discard-deal-for-user-who-has-started-kyc',
                )
                break
            case 'notify-user-with-an-approved-deal':
                setModalAction(
                    'confirm-discard-deal-for-user-with-an-approved-deal',
                )
                break
            case 'confirm-discard-deal-for-user-who-has-not-started-kyc':
                setModalAction('notify-user-who-has-not-started-kyc')
                break
            case 'confirm-discard-deal-for-user-who-has-started-kyc':
                setModalAction('notify-user-who-has-started-kyc')
                break
            case 'confirm-discard-deal-for-user-with-an-approved-deal':
                setModalAction('notify-user-with-an-approved-deal')
                break
        }
    }

    return {
        loading,
        errors,
        setErrors,
        isExisting,
        inputs,
        setInputs,
        energyNeeds,
        setEnergyNeeds,
        checked,
        contactMethod,
        emailIsExistingChecked,
        phoneIsExistingChecked,
        knowsGenMonthlyCost,
        setKnowsGenMonthlyCost,
        showNext,
        setShowNext,
        generatorUse,
        setGeneratorUse,
        paymentModel,
        setPaymentModel,
        canMountSolar,
        setCanMountSolar,
        handleInputChange,
        handleChecked,
        handleBlur,
        handleInputBlur,
        handleShowNext,
        handleSubmit,
        validateUserIdMutation,
        getFormResponseMutation,
        handleGetStarted,
        handleViewAllSystems,
        useType,
        setUseType,
        getStartedBtnLoading,
        viewAllSolutionsBtnLoading,
        isCustomBuild,
        customAppliances,
        useTypeFormInputs,
        setUseTypeFormInputs,
        showUseTypeModal,
        toggleShowUseTypeModal,
        handleGetStartedContinue,
        modalAction,
        actionModalOpen,
        handleCheckReturningCustomerStatus,
        allowReturningCustomers,
        closeActionModal,
        actionModalHandler,
        secondaryActionModalHandler,
        getReturningCustomerStatusLoading,
        actionLoading,
        actionModalToast,
        showLoginModal,
        setShowLoginModal,
        closeLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        otpMethod,
        setOtpMethod,
        handleTrackEntryFormActivity,
    }
}
