import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'

import SelectedSolutionCard from 'src/pages/Consumer/SmartShoppingExperience/AccountSetupOverview/components/SelectedSolutionCard'
import styles from './styles.module.scss'
import ToggleDropdown from '../ToggleDropdown'
import { useRef, useState } from 'react'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-no-radius-large.svg'
import { formatPackageInformation } from '../../utils'
import EmptyState from '../EmptyState'
import LoadingState from './_components/LoadingState'

const ProfileDrawer = ({
    visible,
    onDrawerClose,
    energyProfile,
    handleEnergyProfilePackagesSearch,
    packageList,
    goBackToSystem,
    isFetchingClusterProfilePackages,
}) => {
    const [searchQuery, setSearchQuery] = useState('')
    const searchTimeout = useRef(null)

    const handleApplyFilter = value => {
        handleEnergyProfilePackagesSearch(value)
    }

    const handleSearch = value => {
        clearTimeout(searchTimeout.current)
        searchTimeout.current = setTimeout(() => {
            handleApplyFilter(value)
        }, 300)
    }

    return (
        <Drawer
            visible={visible}
            closable={false}
            width={525}
            onClose={onDrawerClose}
            bodyStyle={{ backgroundColor: '#F9FCFF' }}
        >
            <div className={styles.drawer}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.header}>Selected Package</h1>

                    <CloseIcon onClick={onDrawerClose} role="button" />
                </div>
                <div className={styles.search}>
                    <SearchIcon role="button" />
                    <input
                        placeholder="Search for package"
                        value={searchQuery}
                        onChange={e => {
                            setSearchQuery(e.target.value)
                            handleSearch(e.target.value)
                        }}
                        onBlur={e => {
                            handleApplyFilter(e.target.value)
                        }}
                    />
                </div>

                {isFetchingClusterProfilePackages ? (
                    <LoadingState />
                ) : (
                    <>
                        {packageList?.length === 0 ? (
                            <div className={styles.EmptyState}>
                                <EmptyState
                                    v2
                                    heading="No Package found"
                                    description={
                                        <>
                                            No action point available for this
                                            cluster.
                                            <br />
                                            Please review and try again.
                                        </>
                                    }
                                />
                            </div>
                        ) : (
                            <ToggleDropdown
                                title={energyProfile?.name}
                                className={styles.ProfileCard}
                                defaultOpen
                            >
                                <div className={styles.AboutProfile}>
                                    <h6>About this profile</h6>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: energyProfile?.description,
                                        }}
                                    ></p>
                                </div>

                                {packageList?.map((estimation, index) => (
                                    <ToggleDropdown
                                        key={estimation?.id}
                                        title={estimation?.name}
                                        id={estimation?.id}
                                        openTitle="System Overview"
                                        className={styles.toggleStyle}
                                        defaultOpen={index === 0}
                                    >
                                        <SelectedSolutionCard
                                            page="cluster-workspace"
                                            estimation={formatPackageInformation(
                                                estimation,
                                            )}
                                            goBackToSystem={() =>
                                                goBackToSystem(estimation)
                                            }
                                            fetchUpfrontDeposit={false}
                                            upfrontDepositPaid={true} // set to true so button is hidden
                                        />
                                    </ToggleDropdown>
                                ))}
                            </ToggleDropdown>
                        )}
                    </>
                )}
            </div>
        </Drawer>
    )
}

ProfileDrawer.propTypes = {
    visible: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    energyProfile: PropTypes.object,
    handleEnergyProfilePackagesSearch: PropTypes.func,
    packageList: PropTypes.array,
    goBackToSystem: PropTypes.func,
    isFetchingClusterProfilePackages: PropTypes.bool,
}

export default ProfileDrawer
