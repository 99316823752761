import axios from '../../../config/axios'

export const getAllClusters = async ({
    profileType = '',
    maxClusterMembers = '',
    recentlyUpdated = '',
    recentlyCreated = '',
    name = '',
    currentPage = '',
}) => {
    const request = await axios.get(
        `/cluster-origination/reps/clusters?profile_type=${profileType}&max_cluster_members=${maxClusterMembers}&recently_updated=${recentlyUpdated}&recently_created=${recentlyCreated}&name=${name}&currentPage=${currentPage}`,
    )
    return request
}

export const getClusterSummary = async () => {
    const request = await axios.get(
        '/cluster-origination/reps/clusters/summary',
    )
    return request
}

export const getClusterInfo = async clusterId => {
    const request = await axios.get(
        `/cluster-origination/reps/clusters/${clusterId}`,
    )
    return request
}
