import { ReactComponent as MoneyIcon } from 'src/assets/images/money-outline.svg'
import { ReactComponent as Users } from 'src/assets/images/users-thick-no-stroke.svg'
import { ReactComponent as Monies } from 'src/assets/images/moneys-blue.svg'
import { ReactComponent as Stack } from 'src/assets/images/stack-2-no-stroke.svg'

export const overviewMetricsData = (data, noOfClusters) => [
    {
        icon: <MoneyIcon style={{ stroke: 'none' }} />,
        title: 'total outstanding',
        value: data?.total_outstanding ?? 0.0,
        symbol: 'naira',
    },
    {
        icon: <Stack />,
        title: 'number of cluster',
        value: noOfClusters ?? 0,
    },
    {
        icon: <Users />,
        title: 'total cluster members',
        value: data?.active_members ?? 0,
    },
    {
        icon: <Monies style={{ stroke: 'none' }} />,
        title: 'total monthly repayment',
        symbol: 'naira',
        value: data?.monthly_repayment ?? 0.0,
    },
]

export const overviewTableHeaders = [
    'Cluster name',
    'Profile Type',
    'No ofCluster members',
    'Last Updated Time',
    'Action',
]
