import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { numberWithCommas } from 'src/utils/formatting'
import HalfMoonIcon from 'src/assets/images/half-moon-icon.svg'
import SunIcon from 'src/assets/images/sunlight.svg'
import Button, { AppButton } from '../../../../../../components/Button'
import { v4 as uuidv4 } from 'uuid'

import styles from './TechnicalReviewTab.module.scss'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import UpdateNeeds from 'src/components/UpdateNeeds'
import SolutionModalContent from '../solutionModalContent/SolutionModalContent'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { Pagination } from 'antd'
import { useParams } from 'react-router-dom'
import EmptyState from 'src/components/EmptyState'
import useGetSolutions from '../../hooks/useGetSolutions'
import { RowSkeletalLoader } from 'src/components/SkeletalCards'
import { numberFloat } from '../../utils/utils'
import CustomToolTip from 'src/components/CustomToolTip'
import infoIcon from 'src/assets/images/warning-info-thick.svg'
import { parseHTML } from 'src/utils/parseHTML'
import { permissionsControl } from 'src/utils/permissionsControl'

const TechnicalReviewTab = ({
    appliances,
    DEPSolution,
    paymentPlans,
    recommendationType,
    handleShowAlternativePackages,
    alternativeRecommendations,
    status,
    energyProfile = '',
}) => {
    const params = useParams()
    const [showModal, setShowModal] = useState(false)
    const [solutionModal, setSolutionModal] = useState(false)
    const [modalData, setModalData] = useState()
    const {
        page,
        setPage,
        totalPages,
        solutions,
        isRecommendedSolutionsLoading,
    } = useGetSolutions(params?.id, recommendationType)

    const [energyNeeds, setEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            backup_hours_night: '',
            power_rating: '',
            unit_of_measurement: '',
        },
    ])

    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
    }

    //summary calculation
    const wattsPerAppliance = energyNeeds?.map(
        item => item.power_rating * item.quantity,
    )
    const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b, 0) ?? 0

    const dayUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours * item.quantity,
    )
    const totalDayUsage = dayUsage?.reduce((a, b) => a + b, 0) ?? 0
    const nightUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours_night * item.quantity,
    )
    const totalNightUsage = nightUsage?.reduce((a, b) => a + b, 0) ?? 0
    const averageDailyUsage = totalDayUsage + totalNightUsage

    const canProvideAlternativeRecommendation = permissionsControl([
        'can_provide_recommended_solution',
    ])

    useEffect(() => {
        setEnergyNeeds(appliances)
    }, [appliances])

    const handleSolutionModal = item => {
        setModalData(item)
        setSolutionModal(true)
    }

    const handlePage = pageNumber => {
        setPage(pageNumber)
    }

    let solutionContent
    if (DEPSolution) {
        solutionContent =
            DEPSolution?.total_cost > 0 ? (
                <ul className={styles.technicalReview__bottom__body}>
                    <li key={DEPSolution.id}>
                        <div>
                            <p
                                className={`${
                                    DEPSolution?.selected ? styles.selected : ''
                                }`}
                            >
                                <span>
                                    {DEPSolution.package_name ??
                                        DEPSolution.name}{' '}
                                </span>
                                {DEPSolution?.selected && (
                                    <small className={styles.select}>
                                        Selected
                                    </small>
                                )}
                            </p>
                            <small>
                                ₦{' '}
                                {currencyFormatter(
                                    DEPSolution.total_cost_of_ownership ??
                                        DEPSolution.cost,
                                )}
                            </small>
                        </div>
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            type="outline"
                            btnWidth="70px"
                            btnHeight="37px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={() =>
                                handleSolutionModal({
                                    components: DEPSolution.components,
                                    payment_plans: paymentPlans,
                                    cost: DEPSolution.cost,
                                    margin: DEPSolution.margin,
                                    margin_amount: DEPSolution.margin_amount,
                                    total_cost: DEPSolution.total_cost,
                                    total_cost_of_ownership:
                                        DEPSolution.total_cost_of_ownership,
                                    package_description:
                                        DEPSolution.package_description,
                                })
                            }
                            disabled={false}
                        >
                            More
                        </AppButton>
                    </li>
                </ul>
            ) : (
                <EmptyState title="No recommended solution" />
            )
    } else {
        solutionContent = (
            <ul className={styles.technicalReview__bottom__body}>
                {isRecommendedSolutionsLoading ? (
                    <RowSkeletalLoader total={6} />
                ) : solutions?.length ? (
                    solutions.map(item => {
                        return (
                            <li key={item.id}>
                                <div>
                                    <p
                                        className={`${
                                            item?.selected
                                                ? styles.selected
                                                : ''
                                        }`}
                                    >
                                        <span>
                                            {item.package_name ?? item.name}{' '}
                                        </span>
                                        {item?.selected && (
                                            <small className={styles.select}>
                                                Selected
                                            </small>
                                        )}
                                    </p>
                                    <small>
                                        ₦{' '}
                                        {currencyFormatter(
                                            item.total_cost_of_ownership ??
                                                item.cost,
                                        )}
                                    </small>
                                </div>
                                <AppButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="70px"
                                    btnHeight="37px"
                                    fontSize="12px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    handleClick={() =>
                                        handleSolutionModal(item)
                                    }
                                    disabled={false}
                                >
                                    More
                                </AppButton>
                            </li>
                        )
                    })
                ) : (
                    <EmptyState title="No recommended solution" />
                )}
            </ul>
        )
    }
    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'
    return (
        <>
            <SecondaryModal
                type="recommendedEnergy"
                modalWidth="624px"
                modalHeight={recommendationType === 'alternative' ? 590 : 690}
                showModal={showModal}
                closable={false}
                content={
                    <UpdateNeeds
                        closeModal={() => setShowModal(false)}
                        updateEnergyNeeds={updateEnergyNeeds}
                        selectedAppliances={energyNeeds}
                        noPadding
                        page="estimation"
                        actionType="preview"
                        includeActionButton={false}
                        parentModalHeight={
                            recommendationType === 'alternative' ? 590 : 690
                        }
                    />
                }
                onCancel={() => setShowModal(false)}
            />
            <SecondaryModal
                modalWidth="624px"
                modalHeight="fit-content"
                modalClass="RecommendedEnergy"
                noPadding="0"
                showModal={solutionModal}
                onCancel={() => setSolutionModal(false)}
                content={
                    <SolutionModalContent
                        closeModal={() => setSolutionModal(false)}
                        modalData={modalData}
                    />
                }
            />
            {showUpdatedSSEFlow && energyProfile !== '' && (
                <div
                    className={'ViewSystemTabsPayment_Insurance'}
                    style={{ margin: '30px 0px' }}
                >
                    <p style={{ fontSize: '14px' }}>Selected profile</p>
                    <CustomToolTip
                        text={parseHTML(energyProfile?.description)}
                        toolTipPopupWidth={174}
                        toolTipMarginLeft={-9}
                        toolTipPadding={'9px 10px 25px 10px'}
                    >
                        <div className={'ViewSystemTabsPayment_Insurance_Num'}>
                            <span>{energyProfile?.name}</span>
                            <img src={infoIcon} alt={'info icon'} />
                        </div>
                    </CustomToolTip>
                </div>
            )}

            <section className={styles.technicalReview}>
                <div className={styles.technicalReview__top}>
                    <div className={styles.technicalReview__top__first}>
                        <h4>CONSUMER ENERGY NEEDS</h4>
                        <p>
                            <span>{energyNeeds?.length}</span>
                            Appliance(s) added
                        </p>
                    </div>
                    <div className={styles.technicalReview__top__second}>
                        <div
                            className={styles.technicalReview__top__second_left}
                        >
                            <h4>Total watts added</h4>
                            <p>
                                {numberWithCommas(numberFloat(totalWattsAdded))}
                                <sup>watts</sup>
                            </p>
                        </div>
                        <div
                            className={
                                styles.technicalReview__top__second_right
                            }
                        >
                            <div>
                                <small>
                                    <img src={SunIcon} alt="a sunlight icon" />
                                    <p>Total day usage</p>
                                </small>
                                <p>
                                    {numberWithCommas(
                                        numberFloat(totalDayUsage),
                                    )}{' '}
                                    <span>wh</span>
                                </p>
                            </div>
                            <div>
                                <small>
                                    <img
                                        src={HalfMoonIcon}
                                        alt="a half moon icon"
                                    />
                                    <p>Total night usage</p>
                                </small>
                                <p>
                                    {numberWithCommas(
                                        numberFloat(totalNightUsage),
                                    )}{' '}
                                    <span>wh</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.technicalReview__top__third}>
                        <Button
                            btnBgColor="#E2EEFF"
                            btnTextColor="var(--blue)"
                            btnWidth="170px"
                            btnHeight="44px"
                            handleClick={() => setShowModal(true)}
                        >
                            {energyNeeds?.length &&
                            energyNeeds[0]?.name?.length < 1
                                ? 'Add'
                                : 'See added'}{' '}
                            Appliances
                        </Button>
                        <div
                            className={styles.technicalReview__top__third_right}
                        >
                            <p>Daily Energy Consumption</p>
                            <span>
                                {numberWithCommas(
                                    numberFloat(averageDailyUsage),
                                )}{' '}
                                <span>wh</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.technicalReview__bottom}>
                    <h3>Recommended solution</h3>
                    {recommendationType === 'auto' && solutionContent}
                    {recommendationType === 'alternative' &&
                        (status === 'SOLUTION_PROVIDED' ? (
                            <div>
                                <ul
                                    className={
                                        styles.technicalReview__bottom__body
                                    }
                                >
                                    {isRecommendedSolutionsLoading ? (
                                        <RowSkeletalLoader total={6} />
                                    ) : alternativeRecommendations?.length ? (
                                        alternativeRecommendations?.map(
                                            item => {
                                                return (
                                                    <li key={item.id}>
                                                        <div>
                                                            <p
                                                                className={`${
                                                                    item?.selected
                                                                        ? styles.selected
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span>
                                                                    {item.package_name ??
                                                                        item.name}{' '}
                                                                </span>
                                                                {item?.selected && (
                                                                    <small
                                                                        className={
                                                                            styles.select
                                                                        }
                                                                    >
                                                                        Selected
                                                                    </small>
                                                                )}
                                                            </p>
                                                            <small>
                                                                ₦{' '}
                                                                {currencyFormatter(
                                                                    item.total_cost ??
                                                                        item.cost,
                                                                )}
                                                            </small>
                                                        </div>
                                                        <AppButton
                                                            btnBgColor="#E2EEFF"
                                                            btnTextColor="#004AAD"
                                                            btnTextColorOutline="var(--blue)"
                                                            btnOutlineColor="var(--purple-light)"
                                                            btnBgColorOutline="#E2EEFF"
                                                            type="outline"
                                                            btnWidth="70px"
                                                            btnHeight="37px"
                                                            fontSize="12px"
                                                            fontFamily="SF-Pro-Display-Medium"
                                                            handleClick={() =>
                                                                handleSolutionModal(
                                                                    item,
                                                                )
                                                            }
                                                            disabled={false}
                                                        >
                                                            More
                                                        </AppButton>
                                                    </li>
                                                )
                                            },
                                        )
                                    ) : (
                                        <EmptyState title="No recommended solution" />
                                    )}
                                </ul>
                            </div>
                        ) : (
                            <EmptyState
                                title={'Nothing’s here yet'}
                                subTitle={
                                    <>
                                        This consumer got zero recommendation or
                                        was not satisfied
                                        <br /> with the recommendations from
                                        power curve.
                                    </>
                                }
                                buttonVisible
                                buttonText={
                                    'Provide an alternative recommendation'
                                }
                                buttonWidth={'257px'}
                                buttonHeight={'44px'}
                                buttonClick={() =>
                                    handleShowAlternativePackages()
                                }
                                buttonDisabled={
                                    !canProvideAlternativeRecommendation
                                }
                            />
                        ))}
                    <div className={styles.technicalReview__bottom__pagination}>
                        {DEPSolution === null && solutions?.length ? (
                            <Pagination
                                defaultCurrent={1}
                                defaultPageSize={1}
                                current={page}
                                total={totalPages}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={handlePage}
                            />
                        ) : null}
                    </div>
                </div>
            </section>
        </>
    )
}

export default TechnicalReviewTab

TechnicalReviewTab.propTypes = {
    appliances: PropTypes.object,
    DEPSolution: PropTypes.object,
    paymentPlans: PropTypes.array,
    recommendationType: PropTypes.string,
    handleShowAlternativePackages: PropTypes.func,
    alternativeRecommendations: PropTypes.array,
    status: PropTypes.string,
    energyProfile: PropTypes.obj,
}
