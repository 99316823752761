import { useState, useEffect, useMemo} from 'react'
import { errorProcessor, errorHandler } from '../errorHandler'

const useErrorHandler = (errorObj = { errors: {} }) => {
    const [error, setError] = useState({})
    const initialErrorValue = useMemo(() => {
        return errorProcessor(errorHandler(errorObj, 'new'))
    }, [errorObj])

    const checkToRender = initialErrorValue => {
        if (initialErrorValue !== null || initialErrorValue !== undefined) {
            if (Object.keys(initialErrorValue).length > 0) {
                if (error?.[Object.keys(initialErrorValue)?.[0]]) {
                    if (
                        Object.values(
                            error?.[Object.keys(initialErrorValue)?.[0]],
                        )?.[0] ===
                        Object.values(
                            initialErrorValue?.[
                                Object.keys(initialErrorValue)?.[0]
                            ],
                        )?.[0]
                    ) {
                        return false
                    }
                } else if (
                    Object.keys(
                        initialErrorValue?.[
                            Object.keys(initialErrorValue)?.[0]
                        ],
                    )?.length < 1
                ) {
                    return false
                } else if (initialErrorValue === error?.msg) {
                    return false
                } else if (
                    initialErrorValue[0]?.[
                        Object.keys(
                            initialErrorValue?.[
                                Object.keys(initialErrorValue)?.[0]
                            ],
                        )?.[0]
                    ] === error?.msg?.[0]
                ) {
                    return false
                } else if (
                    initialErrorValue[0]?.[
                        Object.keys(
                            initialErrorValue?.[
                                Object.keys(initialErrorValue)?.[0]
                            ],
                        )?.[0]
                    ] ===
                    error?.[
                        Object.keys(
                            initialErrorValue?.[Object.keys(initialErrorValue)],
                        )?.[0]
                    ]?.[0]
                ) {
                    return false
                } else if (
                    initialErrorValue?.[0]?.msg &&
                    initialErrorValue?.[0]?.msg ===
                        error?.[
                            Object.keys(
                                initialErrorValue?.[
                                    Object.keys(initialErrorValue)?.[0]
                                ],
                            )?.[0]
                        ]
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }

    useEffect(() => {
        if (initialErrorValue !== undefined) {
            if (
                (initialErrorValue?.length > 0 ||
                    Object.keys(initialErrorValue).length > 0) &&
                checkToRender(initialErrorValue)
            ) {
                if (Object.keys(initialErrorValue).length > 0) {
                    setError({
                        [Object.keys(
                            initialErrorValue?.[Object.keys(initialErrorValue)],
                        )]: Object.values(
                            initialErrorValue?.[
                                Object.keys(initialErrorValue)?.[0]
                            ],
                        ),
                    })
                } else {
                    setError({
                        msg: initialErrorValue ? initialErrorValue : '',
                    })
                }
            }
        }

        // eslint-disable-next-line
    }, [initialErrorValue])

    return error
}

export default useErrorHandler
