import OverviewIcon from '../../assets/images/overview-icon.svg'
import ProductsIcon from '../../assets/images/products-icon.svg'
import ProductsSelectIcon from '../../assets/images/products-select.svg'
import EstimationsIcon from '../../assets/images/estimations.svg'
import EstimationsSelectIcon from '../../assets/images/estimations-select.svg'
import MobileOverviewIcon from '../../assets/images/OverviewMobileIcon.svg'
import OverviewActiveIcon from '../../assets/images/overViewActiveIcon.svg'
import RequestsIcon from '../../assets/images/requests-gray-icon.svg'
import RequestsActiveIcon from '../../assets/images/requests-icon.svg'
import AdminRequestsIcon from '../../assets/images/AdminRequestsIcon.svg'
import AdminRequestsActiveIcon from '../../assets/images/AdminRequestsActiveIcon.svg'
import AdminCreditIcon from '../../assets/images/AdminCreditIcon.svg'
import AdminCreditActiveIcon from '../../assets/images/AdminCreditActiveIcon.svg'
import UsersIcon from '../../assets/images/users.svg'
import UsersActiveIcon from '../../assets/images/users-active.svg'
import ProvidersIcon from '../../assets/images/ProvidersIcon.svg'
import ProvidersActiveIcon from '../../assets/images/ProvidersActiveIcon.svg'
import AdminSettingsActiveIcon from '../../assets/images/AdminSettingsActiveIcon.svg'
import AdminSettingsIcon from '../../assets/images/AdminSettingsIcon.svg'
import AdminPaymentPlansActiveIcon from 'src/assets/images/payment-plans-active.svg'
import AdminPaymentPlansInactiveIcon from 'src/assets/images/payment-plans-inactive.svg'
import AdminProfilesActiveIcon from 'src/assets/images/profiles-active.svg'
import AdminProfilesInactiveIcon from 'src/assets/images/profiles-inactive.svg'
import { applyDynamicPermissionsToAdminUser } from 'src/utils/permissionsControl'
import { decodeUserInfo } from 'src/utils/auth'

const productRouteList = [
    '/admin/products',
    '/app/products',
    '/app/products/add-component',
    '/app/products/edit-component',
    '/app/products/add-package',
    '/app/products/view-package/:id',
    '/app/products/view-component/:id',
    '/app/products/edit-package/:id',
    '/app/products/edit-component/:id',
]

export const navRoutes = (
    canViewProductLibrary = true,
    canViewPaymentPlansList = true,
    canViewCustomersList = true,
    canViewUsersList = true,
    canViewCreditVariableList = true,
    canViewProviderList = true,
    canViewEstimationList = true,
    canViewAlternativeRecommendation = true,
    canViewConsumerProfileList = true,
    canViewAdminUsersList,
) => {
    const userInfo = decodeUserInfo()

    return [
        {
            key: 1,
            title: 'Overview',
            url: '/app/overview',
            icon: OverviewIcon,
            mobileIcon: MobileOverviewIcon,
            onSelectIcon: OverviewActiveIcon,
            routeList: ['/app/overview'],
            permissionRole: ['provider_workspace'],
        },
        {
            key: 2,
            title: 'Products',
            url: '/admin/products',
            icon: ProductsIcon,
            onSelectIcon: ProductsSelectIcon,
            routeList: productRouteList,
            permissionRole: ['admin_workspace'],
        },
        {
            key: 2,
            title: 'Products',
            url: '/app/products',
            icon: ProductsIcon,
            onSelectIcon: ProductsSelectIcon,
            routeList: [
                '/app/products',
                '/app/products/add-component',
                '/app/products/edit-component',
                '/app/products/add-package',
                '/app/products/view-package/:id',
                '/app/products/view-component/:id',
                '/app/products/edit-package/:id',
                '/app/products/edit-component/:id',
            ],
            permissionRole: [canViewProductLibrary && 'provider_workspace'],
        },
        {
            key: 4,
            title: 'Consumer Profiles',
            url: '/admin/consumer-profiles',
            icon: AdminProfilesInactiveIcon,
            onSelectIcon: AdminProfilesActiveIcon,
            routeList: [
                '/admin/consumer-profiles',
                '/admin/consumer-profiles/:id',
                '/admin/consumer-profiles-groups',
                '/admin/consumer-profiles/add-consumer-profile',
                '/admin/consumer-profiles/edit-consumer-profile/:id',
                '/admin/consumer-profiles/add-package',
                '/admin/consumer-profiles/add-kyc-information',
                '/admin/consumer-profiles/edit-kyc-information',
                '/admin/consumer-profiles/add-cluster-information',
                '/admin/consumer-profiles/add-cluster-banner',
                '/admin/consumer-profiles/edit-cluster-information/:profile_id',
                '/admin/consumer-profiles/edit-cluster-banner/:profile_id',
                '/admin/consumer-profiles/review/:id',
            ],
            permissionRole: [
                process.env.REACT_APP_FLAG_ENFORCE_ADMIN_PERMISSION === 'false'
                    ? 'admin_workspace'
                    : applyDynamicPermissionsToAdminUser &&
                      canViewProductLibrary &&
                      canViewConsumerProfileList
                    ? 'admin_workspace'
                    : '',
            ],
        },
        {
            key: 5,
            title: 'Outright Sale',
            url: '/admin/outright-sale',
            icon: AdminRequestsIcon,
            onSelectIcon: AdminRequestsActiveIcon,
            routeList: ['/admin/outright-sale', '/admin/outright-sale/:id'],
            permissionRole: ['admin_workspace'],
        },
        {
            key: 6,
            title: 'Payment Plans',
            url: '/app/payment-plans',
            icon: EstimationsIcon,
            onSelectIcon: EstimationsSelectIcon,
            routeList: [
                '/app/payment-plans',
                '/app/payment-plans/create',
                '/app/payment-plans/create/one',
                '/app/payment-plans/create/two',
                '/app/payment-plans/create/three',
                '/app/payment-plans/edit/one',
                '/app/payment-plans/edit/two',
                '/app/payment-plans/edit/three',
                '/app/payment-plans/cluster-package',
            ],
            permissionRole: [canViewPaymentPlansList && 'provider_workspace'],
        },

        {
            key: 7,
            title: 'Payment Plans',
            url: '/admin/plans',
            icon: AdminPaymentPlansInactiveIcon,
            onSelectIcon: AdminPaymentPlansActiveIcon,
            routeList: ['/admin/plans', '/admin/plans/:id'],
            permissionRole: [canViewEstimationList && 'admin_workspace'],
        },

        {
            key: 8,
            title: 'Customers',
            url: '/app/customers',
            icon: RequestsIcon,
            onSelectIcon: RequestsActiveIcon,
            routeList: [
                '/app/customers',
                '/app/customers/active/:id',
                '/app/customers/awaiting-approval/:id',
                '/app/customers/approved/:id',
                '/app/customers/awaiting-update/:id',
                '/app/customers/re-estimate/:id',
                '/app/customers/declined/:id',
                '/app/customers/closed/:id',
            ],
            permissionRole: [canViewCustomersList && 'provider_workspace'],
        },
        {
            key: 9,
            title: 'DTC Review',
            url: '/admin/alternative-recommendations',
            icon: AdminRequestsIcon,
            onSelectIcon: AdminRequestsActiveIcon,
            routeList: [
                '/admin/alternative-recommendations',
                '/admin/alternative-recommendations/:id',
                '/admin/alternative-recommendations/selected/:id',
            ],
            permissionRole: [
                canViewAlternativeRecommendation && 'admin_workspace',
            ],
        },
        {
            key: 10,
            title: 'Credit System',
            url: '/admin/credit',
            icon: AdminCreditIcon,
            onSelectIcon: AdminCreditActiveIcon,
            routeList: [
                '/admin/credit',
                '/admin/credit/view/:id',
                '/app/payment-plans/simulate/one',
                '/app/payment-plans/simulate/two',
            ],
            permissionRole: [canViewCreditVariableList && 'admin_workspace'],
        },
        {
            key: 11,
            title: 'Providers',
            url: '/admin/providers',
            icon: ProvidersIcon,
            onSelectIcon: ProvidersActiveIcon,
            routeList: [
                '/admin/providers',
                '/admin/providers/active/:id',
                '/admin/providers/deactivated/:id',
                '/admin/providers/pending/:id',
            ],
            permissionRole: [canViewProviderList && 'admin_workspace'],
        },
        {
            key: 12,
            title: 'Users',
            url:
                userInfo?.isAdminWorkspaceUser === 'true'
                    ? '/admin/users/listing'
                    : '/app/users/listing',
            icon: UsersIcon,
            onSelectIcon: UsersActiveIcon,
            routeList:
                userInfo?.isAdminWorkspaceUser === 'true'
                    ? [
                          '/admin/users/listing',
                          '/admin/users/add-group',
                          '/admin/user/:id',
                          '/admin/group/:id',
                          '/admin/group/update-group-permissions/:id',
                      ]
                    : [
                          '/app/users/listing',
                          '/app/users/add-group',
                          '/app/user/:id',
                          '/app/group/:id',
                          '/app/group/update-group-permissions/:id',
                      ],
            permissionRole:
                process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' &&
                canViewAdminUsersList
                    ? ['admin_workspace']
                    : canViewUsersList &&
                      process.env.REACT_APP_FLAG_SHOW_PROVIDER_USERS === 'true'
                    ? ['provider_workspace']
                    : [],
        },
        {
            key: 13,
            title: 'Settings',
            url: '/admin/settings',
            icon: AdminSettingsIcon,
            onSelectIcon: AdminSettingsActiveIcon,
            routeList: [
                '/admin/settings',
                '/admin/settings/provider-groups/:id',
                '/admin/settings/update-group-permissions/:id',
                '/admin/settings/provider-resources/:id',
                '/admin/settings/component-types/:id',
            ],
            permissionRole: [
                process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS === 'true' &&
                    'admin_workspace',
            ],
        },
    ]
}

export const adminNavRoutes = (
    canViewCreditVariableList = true,
    canViewProviderList = true,
    canViewEstimationList = true,
    canViewAlternativeRecommendation = true,
    canViewConsumerProfileList = true,
) => {
    return [
        {
            key: 1,
            title: 'Overview',
            url: '/admin/overview',
            icon: OverviewIcon,
            mobileIcon: MobileOverviewIcon,
            onSelectIcon: OverviewActiveIcon,
            routeList: ['/admin/overview'],
            permissionRole: ['provider_workspace'],
        },
        {
            key: 2,
            title: 'Products',
            url: '/admin/products',
            icon: ProductsIcon,
            onSelectIcon: ProductsSelectIcon,
            routeList: productRouteList,
            permissionRole: ['admin_workspace'],
        },
        {
            key: 3,
            title: 'Consumer Profiles',
            url: '/admin/consumer-profiles',
            icon: AdminProfilesInactiveIcon,
            onSelectIcon: AdminProfilesActiveIcon,
            routeList: [
                '/admin/consumer-profiles',
                '/admin/consumer-profiles/:id',
                '/admin/consumer-profiles-groups',
                '/admin/consumer-profiles/add-consumer-profile',
                '/admin/consumer-profiles/edit-consumer-profile/:id',
                '/admin/consumer-profiles/add-package',
                '/admin/consumer-profiles/add-kyc-information',
                '/admin/consumer-profiles/edit-kyc-information',
                '/admin/consumer-profiles/add-cluster-information',
                '/admin/consumer-profiles/add-cluster-banner',
                '/admin/consumer-profiles/edit-cluster-information/:profile_id',
                '/admin/consumer-profiles/edit-cluster-banner/:profile_id',
                '/admin/consumer-profiles/review/:id',
            ],
            permissionRole: [canViewConsumerProfileList && 'admin_workspace'],
        },
        {
            key: 4,
            title: 'Outright Sale',
            url: '/admin/outright-sale',
            icon: AdminRequestsIcon,
            onSelectIcon: AdminRequestsActiveIcon,
            routeList: ['/admin/outright-sale', '/admin/outright-sale/:id'],
            permissionRole: ['admin_workspace'],
        },
        {
            key: 5,
            title: 'Payment Plans',
            url: '/admin/plans',
            icon: AdminPaymentPlansInactiveIcon,
            onSelectIcon: AdminPaymentPlansActiveIcon,
            routeList: ['/admin/plans', '/admin/plans/:id'],
            permissionRole: [canViewEstimationList && 'admin_workspace'],
        },
        {
            key: 6,
            title: 'DTC Review',
            url: '/admin/alternative-recommendations',
            icon: AdminRequestsIcon,
            onSelectIcon: AdminRequestsActiveIcon,
            routeList: [
                '/admin/alternative-recommendations',
                '/admin/alternative-recommendations/:id',
                '/admin/alternative-recommendations/selected/:id',
            ],
            permissionRole: [
                canViewAlternativeRecommendation && 'admin_workspace',
            ],
        },
        {
            key: 7,
            title: 'Credit System',
            url: '/admin/credit',
            icon: AdminCreditIcon,
            onSelectIcon: AdminCreditActiveIcon,
            routeList: [
                '/admin/credit',
                '/admin/credit/view/:id',
                '/app/payment-plans/simulate/one',
                '/app/payment-plans/simulate/two',
            ],
            permissionRole: [canViewCreditVariableList && 'admin_workspace'],
        },
        {
            key: 8,
            title: 'Providers',
            url: '/admin/providers',
            icon: ProvidersIcon,
            onSelectIcon: ProvidersActiveIcon,
            routeList: [
                '/admin/providers',
                '/admin/providers/active/:id',
                '/admin/providers/deactivated/:id',
                '/admin/providers/pending/:id',
                '/admin/providers/user/:id',
            ],
            permissionRole: [canViewProviderList && 'admin_workspace'],
        },
        {
            key: 9,
            title: 'Users',
            url: '/admin/users/listing',
            icon: UsersIcon,
            onSelectIcon: UsersActiveIcon,
            routeList: [
                '/admin/users/listing',
                '/admin/users/add-group',
                '/admin/user/:id',
                '/admin/group/:id',
                '/admin/group/update-group-permissions/:id',
            ],
            permissionRole: [
                process.env.REACT_APP_FLAG_SHOW_ADMIN_USERS === 'true' &&
                    'admin_workspace',
            ],
        },
        {
            key: 10,
            title: 'Settings',
            url: '/admin/settings',
            icon: AdminSettingsIcon,
            onSelectIcon: AdminSettingsActiveIcon,
            routeList: [
                '/admin/settings',
                '/admin/settings/provider-groups/:id',
                '/admin/settings/update-group-permissions/:id',
                '/admin/settings/provider-resources/:id',
                '/admin/settings/component-types/:id',
            ],
            permissionRole: ['admin_workspace'],
        },
    ]
}
