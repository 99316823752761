import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Upload, Dropdown, Menu, message } from 'antd'
import { format, parse } from 'date-fns'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import UploadLogo from 'src/assets/images/upload.svg'
import { DateField, InputFields } from 'src/components/InputFields'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import { errorHandler } from 'src/utils/errorHandler'
import { usePlanContext } from '../../hooks/planProvider'
import { uploadSignedSunFiAgreementApi } from 'src/api/admin/payment-plans/ssePlan'
import './approvedmodal.scss'
import CustomToolTip from 'src/components/CustomToolTip'
import { ReactComponent as WarningIcon } from 'src/assets/images/info-warning-blue2.svg'
import Button from 'src/components/Button'
import useUploadEvidenceToS3 from '../../hooks/useUploadEvidenceToS3'

const UploadSignedAgreementModal = ({ estimationId, onClose }) => {
    const [uploading, setUploading] = useState(false)
    const [fileValid, setFileValid] = useState(true)
    const [width, setWidth] = useState(40)
    const [errorToast, setErrorToast] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showCalendar, setShowCalendar] = useState(null)
    const [showTimeDropdown, setShowTimeDropdown] = useState(false)
    const [hour, setHour] = useState('')
    const [minute, setMinute] = useState('')
    const [time, setTime] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)

    const queryClient = useQueryClient()

    const {
        setOpenModal,
        setSuccessModal,
        setSuccessHeaderText,
        inputs,
        setInputs,
        currentDatePaid,
        setCurrentDatePaid,
        errors,
        setErrors,
        successUpload,
        setSuccessUpload,
    } = usePlanContext()

    const handleDateChange = (name, date) => {
        if (name === 'dateOfAction') {
            setCurrentDatePaid(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(prevState => ({
            ...prevState,
            [name]: currentDatePaid,
        }))
    }

    const handleHourChange = e => {
        if (e.target.value < 24) setHour(e.target.value)
    }

    const handleMinuteChange = e => {
        if (e.target.value < 60) setMinute(e.target.value)
    }

    const handleSetTime = () => {
        if (inputs.dateOfAction === '' || inputs.dateOfAction === null) {
            setErrors(prev => ({
                ...prev,
                dateOfAction: 'Select the date of payment',
            }))
            setShowTimeDropdown(false)
        } else {
            setErrors(prev => ({
                ...prev,
                dateOfAction: '',
            }))
            const time = hour + ':' + minute
            let concatDate = new Date(currentDatePaid + ' ' + time)
            const formatTime = time.toString()
            if (hour && time) {
                setTime(time)

                setInputs(prevInputs => ({
                    ...prevInputs,
                    timeOfAction: format(
                        parse(formatTime, 'HH:mm', new Date()),
                        'hh:mm',
                    ),
                    dateTime: format(concatDate, "yyyy-MM-dd'T'HH:mm:ssxxx"),
                }))
                setShowTimeDropdown(false)
            }
        }
    }

    const { Dragger } = Upload
    const draggerProps = {
        name: 'file',
        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
        },
        onDrop() {
            setUploading(false)
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1000 / 1000 < 3
            if (!isLt2M) {
                message.error('Image should be less than 3mb')
                setFileValid(false)
                return false
            }
            setFileValid(true)
            return true
        },
        showUploadList: true,
        fileList: successUpload,
        maxCount: 1,
        accept: '.pdf,.jpg,.jpeg,.png',
        customRequest: () => {},
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) setWidth(prev => prev + 20)
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    const menu = (
        <Menu>
            <Menu.Item>
                <div style={{ display: 'flex' }}>
                    <div className="TimePickerLeftWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={hour}
                                name="hour"
                                onChange={handleHourChange}
                                autoFocus
                            />
                            <CustomToolTip
                                text="Enter hours as digits from 0 to 23 only. Avoid numbers above 23 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Hour</p>
                        <Button
                            btnBgColor=" #E2EEFF"
                            btnWidth="110px"
                            btnTextColor="var(--blue)"
                            btnHeight="45px"
                            handleClick={() => setShowTimeDropdown(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <div
                            className="ApprovedModalTimePickerSeparator"
                            style={{ margin: '16px 0' }}
                        ></div>
                        <div className="ApprovedModalTimePickerSeparator"></div>
                    </div>
                    <div className="TimePickerRightWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={minute}
                                name="minute"
                                onChange={handleMinuteChange}
                            />
                            <CustomToolTip
                                text="Enter minutes as digits from 0 to 59 only. Avoid numbers above 59 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Minute</p>
                        <div style={{ marginLeft: 5 }}>
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="110px"
                                btnHeight="45px"
                                handleClick={handleSetTime}
                            >
                                Set Time
                            </Button>
                        </div>
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    )

    const { uploadEvidenceMutate } = useUploadEvidenceToS3(
        successUpload?.[0],
        async s3Url => {
            try {
                const fileData = {
                    action: 'SUNFI_AGREEMENT',
                    agreement_signed_at: inputs.dateTime,
                    signed_agreement_file_url: s3Url,
                }
                await uploadSignedSunFiAgreementApi(estimationId, fileData)
                setOpenModal(false)
                setSuccessHeaderText(
                    'New Signed Agreement Uploaded Successfully',
                )
                setSuccessModal(true)
                queryClient.invalidateQueries('single-payment-plan-estimation')
                onClose()
            } catch (error) {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            } finally {
                setIsSubmitting(false)
            }
        },
        error => {
            setErrorToast(true)
            setErrorMessage(errorHandler(error?.response?.data))
            setIsSubmitting(false)
        },
    )

    const handleUpload = () => {
        if (!inputs.dateTime || successUpload.length === 0) {
            if (!inputs.dateTime) {
                setErrors(prev => ({
                    ...prev,
                    dateOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setErrors(prev => ({
                    ...prev,
                    uploadError: 'Please upload a signed agreement',
                }))
            }
        } else {
            setIsSubmitting(true)
            uploadEvidenceMutate('SUNFI_AGREEMENT')
        }
    }
    const handleClose = () => {
        setSuccessUpload([])
        setFileValid(true)
        setUploading(false)
        setErrors({})
        onClose()
    }
    return (
        <div>
            {errorToast && (
                <Toast
                    message={
                        errorMessage ||
                        "Couldn't submit your request. Please try again"
                    }
                    messageType="error"
                    showIcon
                />
            )}
            <h2 className="ApprovedModalText">
                Upload Updated Signed Agreement
            </h2>
            <p className="ApprovedModalSubHeader">
                Enter the details of the signed agreement
            </p>

            <div className="APAModalDateTimeWrapper">
                <div
                    className="APAModalDateTimeInput"
                    style={{ width: '224px' }}
                >
                    <DateField
                        dateValue={
                            inputs.dateOfAction
                                ? new Date(inputs.dateOfAction)
                                : null
                        }
                        name="dateOfAction"
                        handleDateChange={handleDateChange}
                        setHandleDateChange={setHandleDateChange}
                        placeholder="Date Signed"
                        openCalendarHandler={() => setShowCalendar(1)}
                        openCalendar={showCalendar === 1}
                        closeCalendarHandler={() => setShowCalendar(null)}
                        caretStyle="AdminPaymentPlanCaretStyle"
                        errorMessage={errors?.dateOfAction}
                    />
                </div>
                <div
                    className="APAModalDateTimeInput"
                    style={{ width: '224px' }}
                >
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        visible={showTimeDropdown}
                        overlayClassName="AdminApprovedTimePicker"
                        placement="bottomCenter"
                        overlayStyle={{ marginTop: '18px', marginLeft: '42px' }}
                    >
                        <div
                            onClick={() => setShowTimeDropdown(true)}
                            className="APAModalTimeInputWrapper"
                        >
                            <InputFields
                                title="Time Signed"
                                value={time}
                                inputPlaceholder=""
                                iconType="DropdownArrow"
                                errorMessage={errors?.timeOfAction}
                            />
                        </div>
                    </Dropdown>
                </div>
            </div>
            <p className="AgreementTitle">Signed Agreement</p>

            <div className="APADraggerArea">
                <Dragger className="DraggerArea" {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                        {uploading ? (
                            <div className="SlideContainer">
                                <p
                                    className="Slider"
                                    style={{ width: `${width}%` }}
                                ></p>
                            </div>
                        ) : successUpload.length > 0 ? (
                            <img
                                src={SuccessUpload}
                                className="UploadLogo"
                                alt="success logo"
                            />
                        ) : (
                            <img
                                src={UploadLogo}
                                className="UploadLogo"
                                alt="upload logo"
                            />
                        )}
                    </p>
                    <p style={{ marginTop: '16px' }}>
                        {uploading ? (
                            'Uploading document...'
                        ) : successUpload.length > 0 ? (
                            <u className="Browse">Replace document</u>
                        ) : (
                            <>
                                Upload file. Drag and drop or{' '}
                                <u className="Browse">browse</u>
                            </>
                        )}
                    </p>
                </Dragger>
                {errors.uploadError && (
                    <p className="ApprovedModalUploadError">
                        Please upload evidence of this action.
                    </p>
                )}
            </div>

            <div style={{ textAlign: 'center' }}>
                <Button
                    btnWidth="160px"
                    btnHeight="56px"
                    type={'secondary'}
                    handleClick={handleClose}
                    disabled={isSubmitting}
                >
                    Cancel
                </Button>
                <Button
                    btnWidth="160px"
                    btnHeight="56px"
                    btnBgColor="#004AAD"
                    marginLeft={'20px'}
                    btnTextColor="var(--white)"
                    handleClick={handleUpload}
                    disabled={!fileValid || isSubmitting}
                >
                    {isSubmitting ? <InlineLoader /> : 'Save'}
                </Button>
            </div>
        </div>
    )
}

UploadSignedAgreementModal.propTypes = {
    estimationId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default UploadSignedAgreementModal
