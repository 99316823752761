import styles from '../../styles.module.scss'
import { overviewMetricsData } from '../../utils'
import { numberWithCommas } from 'src/utils/formatting'
import PropTypes from 'prop-types'
import Card from 'src/components/Card'
import Slider from 'react-slick/lib/slider'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { Skeleton } from 'antd'

const Metrics = (clustersSummary, noOfClusters, fetchingClusterSummary) => {
    return overviewMetricsData(clustersSummary, noOfClusters)?.map(
        (summary, index) =>
            fetchingClusterSummary ? (
                <Skeleton key={index} />
            ) : (
                <Card
                    key={index}
                    topLeftHeading={
                        <div className={styles.iconDiv}>{summary?.icon}</div>
                    }
                    bottomLeftContent={
                        summary?.symbol === 'naira'
                            ? `₦${numberWithCommas(summary?.value)}.00`
                            : numberWithCommas(summary?.value)
                    }
                    bottomLeftHeading={summary?.title}
                    showButton={false}
                />
            ),
    )
}
const OverviewMetrics = ({
    clustersSummary,
    noOfClusters,
    fetchingClusterSummary,
}) => {
    const settings = {
        arrows: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        dots: true,
        infinite: false,
    }
    const { isMobile } = useMediaQueries()

    return (
        <>
            {isMobile ? (
                <Slider
                    className={`${styles?.MobileSummary} RepOverviewSummary`}
                    {...settings}
                >
                    {Metrics(
                        clustersSummary,
                        noOfClusters,
                        fetchingClusterSummary,
                    )}
                </Slider>
            ) : (
                <div className={styles.overviewInfo}>
                    {Metrics(
                        clustersSummary,
                        noOfClusters,
                        fetchingClusterSummary,
                    )}
                </div>
            )}
        </>
    )
}

OverviewMetrics.propTypes = {
    clustersSummary: PropTypes.object,
    noOfClusters: PropTypes.number,
    fetchingClusterSummary: PropTypes.bool,
}

export default OverviewMetrics
