/* eslint-disable react/prop-types */
import { Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import logo from '../../assets/images/logo2.svg'
import { logoutApi } from '../../api/logout'
import { getToken, logsOutUser } from '../../utils/auth.js'
import { ReactComponent as Avatar } from 'src/assets/images/avatar-placeholder.svg'
import { ReactComponent as DropdownIcon } from 'src/assets/images/dropdown.svg'
import { ReactComponent as ShutDownIcon } from 'src/assets/images/shut-down.svg'
// import { ReactComponent as ProfileIcon } from 'src/assets/images/profile.svg'
import { decodeUserInfo } from '../../utils/auth.js'

import styles from './navbar.module.scss'
import './authenticatednavbar.scss'

const NavBar = ({ margin, padding }) => {
    const {
        NavWrapper,
        NavMenu,
        SunfiLogo,
        ListItem,
        MobileNav,
        AvatarImg,
        NavWrapperForWorkspace,
        options,
        DropDownLI,
        ProfileImg,
    } = styles
    const userInfo = decodeUserInfo()

    const logOut = () => {
        setTimeout(() => {
            refetch()
        }, 1000)
    }

    const { refetch } = useQuery(
        'logoutConsumer',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                logsOutUser()
            },
        },
    )
    // const isSSEConsumer = JSON.parse(localStorage.getItem('isSSEConsumer'))

    const dropdownMenu = (
        <Menu className="NavBar_Menu">
            {/* TODO: uncomment when implementing profile page */}
            {/* {isSSEConsumer && (
                <Menu.Item>
                    <span className={DropDownLI}>
                        <ProfileIcon />
                        <span>Profile</span>
                    </span>
                </Menu.Item>
            )} */}
            <Menu.Item onClick={logOut}>
                <span className={DropDownLI}>
                    <ShutDownIcon />
                    <span>Log Out</span>
                </span>
            </Menu.Item>
        </Menu>
    )

    return (
        <header
            className={`${NavWrapper} ${NavWrapperForWorkspace}`}
            style={{ margin: margin, padding: padding }}
        >
            <Link to="/">
                <img src={logo} alt="logo" className={SunfiLogo} />
            </Link>
            <Menu mode="horizontal" className={NavMenu}>
                <li className={MobileNav}>
                    <Link
                        to="/privacy-policy"
                        className={ListItem}
                        target="_blank"
                    >
                        Privacy Policy
                    </Link>
                </li>
                <li className={MobileNav}>
                    <Link
                        to="/terms-of-service"
                        className={ListItem}
                        target="_blank"
                    >
                        Terms of Service
                    </Link>
                </li>
            </Menu>
            {location.pathname !== '/consumer/get-your-offer' && (
                <div className={options}>
                    {userInfo?.profilePic ? (
                        <img
                            src={userInfo?.profilePic}
                            alt="profile picture"
                            className={ProfileImg}
                        />
                    ) : (
                        <div className={AvatarImg}>
                            <Avatar />
                        </div>
                    )}
                    <Dropdown
                        trigger={['click']}
                        overlay={dropdownMenu}
                        placement="bottomRight"
                    >
                        <DropdownIcon style={{ cursor: 'pointer' }} />
                    </Dropdown>
                </div>
            )}
        </header>
    )
}

export default NavBar
