import s from './tenurechangemodal.module.scss'
import { ReactComponent as CoinsIcon } from 'src/assets/images/financial.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import MaintenanceIcon from 'src/assets/images/maintenance-image.png'
import SuccessIconSecondary from 'src/assets/images/successImage-secondary.svg'

export const tenureChangeModalValues = action => {
    switch (action) {
        case 'confirm-change-request':
            return {
                modalTitle: 'Request Tenure Change',
                modalContent: 'action-modal',
                actionIcon: <CoinsIcon />,
                actionHeading: 'How Your Tenure Change Works',
                actionDescription:
                    "Requesting a tenure change will adjust your monthly repayments based on the new period. If approved, you'll receive an updated payment breakdown and once your plan is active, tenure changes won't be allowed. You'll be notified when your request is processed.",
                primaryBtnText: 'Send request',
                footerLeftContent: 'chat',
                closable: true,
                secondaryBtnText: '',
            }
        case 'enter-request-reason':
            return {
                modalTitle: 'Request Tenure Change',
                modalContent: 'reason-form',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: 'Confirm',
                footerLeftContent: null,
                closable: true,
                secondaryBtnText: '',
            }
        case 'request-sent-successfully':
            return {
                modalTitle: 'Request Tenure Change',
                modalContent: 'action-modal',
                actionIcon: <SuccessIcon />,
                actionHeading: 'Request submitted successfully!',
                actionDescription:
                    'Your request to change your tenure has been submitted. Our team will review your request and notify you via email once a new tenure option is available.',
                primaryBtnText: 'Okay, got it',
                footerLeftContent: 'chat',
                closable: true,
                secondaryBtnText: '',
            }
        case 'admin-updated-tenure-notification':
            return {
                modalTitle: 'You have a new tenure',
                modalContent: 'action-modal',
                actionIcon: (
                    <img
                        src={MaintenanceIcon}
                        alt="new tenure"
                        role="presentation"
                        className={s.maintenanceIcon}
                    />
                ),
                actionHeading: 'A new tenure has been suggested to you',
                actionDescription:
                    'Please note that a new tenure has been suggested to you by our team. Kindly look through it carefully.',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
                closable: false,
                secondaryBtnText: '',
            }

        case 'compare-tenures':
            return {
                modalTitle: 'Updated Tenure & Payment Plan',
                modalContent: 'compare-tenures-cards',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: 'Accept',
                footerLeftContent: 'button',
                closable: false,
                secondaryBtnText: 'Reject',
            }
        case 'tenure-accepted-successfully':
            return {
                modalTitle: 'Request Tenure Change',
                modalContent: 'action-modal',
                actionIcon: (
                    <img
                        src={SuccessIconSecondary}
                        alt="success"
                        role="presentation"
                        className={s.successIcon}
                    />
                ),
                actionHeading: 'You have successfully accepted the new tenure!',
                actionDescription:
                    'Your updated repayment schedule is now active, and your monthly payments will be adjusted accordingly. If you have any questions, feel free to reach out to our support team.',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
                closable: true,
                secondaryBtnText: '',
            }
        case 'reject-new-tenure':
            return {
                modalTitle: 'Reject Tenure Change',
                modalContent: 'reason-form',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: 'Continue',
                footerLeftContent: null,
                closable: false,
                secondaryBtnText: '',
            }
        case 'tenure-rejected-successfully':
            return {
                modalTitle: 'Request Tenure Change',
                modalContent: 'action-modal',
                actionIcon: (
                    <img
                        src={SuccessIconSecondary}
                        alt="success"
                        role="presentation"
                        className={s.successIcon}
                    />
                ),
                actionHeading: 'Feedback Submitted Successfully',
                actionDescription:
                    'Thank you for your feedback! We’ll review your input and recommend a new tenure option that better fits your needs. You’ll receive an update via email once a new tenure is available.',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
                closable: true,
                secondaryBtnText: '',
            }
        default:
            return {
                modalTitle: '',
                modalContent: '',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: '',
                footerLeftContent: null,
                closable: true,
                secondaryBtnText: '',
            }
    }
}

export const tenureChangeReasons = [
    'I want to complete my tenure sooner',
    'I want to reduce my monthly payment',
    'I need a tenure that aligns with my budget',
    'Others',
]

export const rejectNewTenureReasons = [
    'The new monthly payments are too high',
    'I prefer a shorter payment tenure',
    'The new tenure does not align with my budget',
    'Others',
]

export const compareTenures = data => {
    return [
        {
            title: 'current plan',
            upfrontDeposit:
                data?.post_approval?.tenure_change_request
                    ?.old_tenure_information?.initial_deposit,
            monthsOfRepayment:
                data?.post_approval?.tenure_change_request
                    ?.old_tenure_information?.tenure,
            monthlyPayment:
                data?.post_approval?.tenure_change_request
                    ?.old_tenure_information?.monthly_payment,
        },
        {
            title: 'new plan',
            upfrontDeposit:
                data?.admin_approved_repayment_plan?.initial_deposit,
            monthsOfRepayment: data?.admin_approved_repayment_plan?.tenure,
            monthlyPayment:
                data?.admin_approved_repayment_plan?.monthly_repayment,
        },
    ]
}
