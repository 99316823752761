import arrowDownIcon from 'src/assets/images/arrowDown.svg'
import arrowUpIcon from 'src/assets/images/ArrowUp.svg'
import trashIcon from 'src/assets/images/trash_icon.svg'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { usePlanContext } from '../../hooks/planProvider'

const PaymentSummary = ({
    children,
    isNewPayment,
    action,
    idx,
    openPanel,
    setShowNewPayment,
}) => {
    const { openModal } = usePlanContext()
    const [isOpen, setIsOpen] = useState(openPanel || false)

    useEffect(() => {
        if (!openModal) {
            setIsOpen(false)
        }
    }, [openModal, openPanel])
    return (
        <div className="payment_rec_wrapper subsequent_record_container">
            <div className="recordSummary" onClick={() => setIsOpen(!isOpen)}>
                <p>
                    Payment{' '}
                    <span>
                        {isNewPayment
                            ? action?.transactions?.length + 1
                            : idx + 1}
                    </span>
                </p>
                <div className="action_icons">
                    {isNewPayment && (
                        <button onClick={() => setShowNewPayment(false)}>
                            <img
                                src={trashIcon}
                                alt="delete"
                                className="delete"
                            />
                        </button>
                    )}
                    <span>
                        <img
                            src={isOpen ? arrowUpIcon : arrowDownIcon}
                            alt="caret"
                        />
                    </span>
                </div>
            </div>
            {isOpen && <div className="recordDetails">{children}</div>}
        </div>
    )
}

PaymentSummary.propTypes = {
    children: PropTypes.node,
    isNewPayment: PropTypes.bool,
    action: PropTypes.object,
    idx: PropTypes.string,
    openPanel: PropTypes.bool,
    setShowNewPayment: PropTypes.func,
}

export default PaymentSummary
