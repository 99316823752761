import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    fetchUpfrontDepositDetails,
    increaseUpfrontDeposit,
} from 'src/api/consumer/postApproval'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import { errorHandler } from 'src/utils/errorHandler'

const useIncreaseUpfrontDeposit = ({
    depositChangeAction,
    setDepositChangeAction,
    closeDepositChangeModal,
    openCardDetailsModal,
    estimationId,
    initialDeposit,
}) => {
    const [inputs, setInputs] = useState({
        amount: '',
    })
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [modalToast, setModalToast] = useState(toastData)
    const [errors, setErrors] = useState({})
    const [updatedUpfrontDepositDetails, setUpdatedUpfrontDepositDetails] =
        useState({})

    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }

    const { refetch: fetchNewUpfrontDepositDetails } = useQuery(
        'get-upfront-deposit-details',
        () => fetchUpfrontDepositDetails(estimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setUpdatedUpfrontDepositDetails(data)
            },
            onError: err => {
                setModalToast({
                    showToast: true,
                    toastMessage:
                        errorHandler(err?.response?.data) ||
                        'An error occurred, please try again later.',
                    messageType: 'error-secondary',
                })
                resetToast()
            },
        },
    )

    const {
        mutate: increaseUpfrontDepositMutate,
        isLoading: increaseUpfrontDepositLoading,
    } = useMutation({
        mutationKey: ['post-approval-upfront-deposit-increase'],
        mutationFn: ({ payload }) =>
            increaseUpfrontDeposit(estimationId, payload),
        onSuccess: () => {
            setDepositChangeAction('request-sent-successfully')
            setInputs({
                amount: '',
            })
            fetchNewUpfrontDepositDetails()
        },
        onError: err => {
            setModalToast({
                showToast: true,
                toastMessage:
                    errorHandler(err?.response?.data) ||
                    'An error occurred, please try again later.',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    const handleSubmit = () => {
        let initial_deposit = removeCommaFromNum(initialDeposit)

        if (inputs?.amount < initial_deposit) {
            setErrors({
                amount: `Please enter an amount of at least ₦${initialDeposit}.`,
            })
        } else {
            const increment = inputs?.amount - initial_deposit // api receives increment instead of new amount
            let payload = {
                amount: increment ?? 0,
            }
            setErrors({})
            increaseUpfrontDepositMutate({ payload })
        }
    }

    const handlePrimaryBtnClick = () => {
        switch (depositChangeAction) {
            case 'confirm-change-request':
                setDepositChangeAction('enter-new-upfront-deposit')
                break
            case 'enter-new-upfront-deposit':
                handleSubmit()
                break
            case 'request-sent-successfully':
                setDepositChangeAction('confirm-upfront-deposit')
                break
            case 'confirm-upfront-deposit':
                openCardDetailsModal()
                break
            case 'payment-made-successfully':
                closeDepositChangeModal()
                break
        }
    }

    const onCancel = () => {
        closeDepositChangeModal()
        setInputs({
            amount: '',
        })
        setErrors({})
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    return {
        handlePrimaryBtnClick,
        onCancel,
        inputs,
        errors,
        modalToast,
        handleChange,
        handleSelectChange,
        updatedUpfrontDepositDetails,
        primaryBtnLoading: increaseUpfrontDepositLoading,
    }
}

export default useIncreaseUpfrontDeposit
