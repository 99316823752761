import { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './depkycupdatesreviewmodal.module.scss'
import Toast from 'src/components/Toast'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import Checkbox from 'src/components/Checkbox'
import { getModalIconType, getModalContentType } from './utils'

const { ModalHeader, ModalContainer, ModalText, CheckboxWrapper } = styles

const DEPKycUpdatesReviewModal = ({
    modalOpen = false,
    contentType = '',
    closable,
    onCancel,
    errorMessage,
    toastError,
    errorMessageType = 'error',
    handlePrimaryBtnClick,
    primaryBtnLoading,
    v2 = true,
    subTitleMaxWidth,
    handleSecondaryBtnClick,
    kycSecondaryButtonStopLoading,
    secondaryBtnLoading,
}) => {
    const [checked, setChecked] = useState(false)

    const modalContent = contentType && getModalContentType(contentType)

    if (!modalContent) {
        return null
    }

    const iconType = getModalIconType(modalContent.iconType)
    const isSubmitReview = contentType === 'review-kyc-updates-before-submit'

    const renderExtraContent = () => {
        if (isSubmitReview) {
            return (
                <div className={CheckboxWrapper}>
                    <Checkbox
                        isChecked={checked}
                        handleChecked={() => setChecked(!checked)}
                    />
                    <p role="button" onClick={() => setChecked(!checked)}>
                        I have reviewed the information filled by my provider
                        and I consent to its accuracy.
                    </p>
                </div>
            )
        }

        return <></>
    }

    return (
        <SecondaryModal
            showModal={modalOpen}
            closable={closable}
            onCancel={onCancel ? onCancel : () => {}}
            title={modalContent.title}
            primaryBtnText={modalContent.primaryBtnText}
            primaryBtnLoading={primaryBtnLoading}
            primaryBtnWidth={isMobile ? '148px' : ''}
            secondaryBtnWidth={isMobile ? '148px' : ''}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            secondaryBtnText={modalContent.secondaryBtnText}
            handleSecondaryBtnClick={handleSecondaryBtnClick}
            modalWidth={'475px'}
            showFooter
            v2={v2}
            footerLeftContent={modalContent.footerLeftContent}
            kycSecondaryButtonStopLoading={kycSecondaryButtonStopLoading}
            dataTestId={'dep-kyc-updates-review-modal-v2'}
            primaryBtnDataTestId={'dep-kyc-updates-review-modal-v2-primary-btn'}
            secondaryBtnLoading={secondaryBtnLoading}
            footerClassName="ActionModalV2_Footer"
            primaryBtnDisabled={isSubmitReview && !checked}
            content={
                <div style={{ position: 'relative' }}>
                    {toastError && (
                        <Toast
                            position="absolute"
                            top="30px"
                            messageType={errorMessageType}
                            message={errorMessage?.[0]}
                        />
                    )}
                    <div className={ModalContainer}>
                        {iconType}
                        <h2 className={ModalHeader}>
                            {modalContent.headerText}
                        </h2>
                        <p
                            className={ModalText}
                            style={{ maxWidth: subTitleMaxWidth }}
                        >
                            {modalContent.subtitle}
                        </p>
                        {renderExtraContent()}
                    </div>
                </div>
            }
        />
    )
}

DEPKycUpdatesReviewModal.propTypes = {
    modalOpen: PropTypes.bool,
    contentType: PropTypes.string,
    closable: PropTypes.any,
    onCancel: PropTypes.func,
    errorMessage: PropTypes.any,
    toastError: PropTypes.bool,
    errorMessageType: PropTypes.string,
    handlePrimaryBtnClick: PropTypes.func,
    primaryBtnLoading: PropTypes.bool,
    v2: PropTypes.bool,
    subTitleMaxWidth: PropTypes.string,
    handleSecondaryBtnClick: PropTypes.func,
    kycSecondaryButtonStopLoading: PropTypes.bool,
    secondaryBtnLoading: PropTypes.bool,
}

export default DEPKycUpdatesReviewModal
