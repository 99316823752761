/* eslint-disable react/prop-types */
import { AppButton } from 'src/components/Button'
import formatToDoData from './data'
import { format, parseISO } from 'date-fns'
import { formatOutrightSaleToDoData } from './data'
import BellIcon from 'src/assets/images/bell-with-circle.svg'

import './approvedtodotable.scss'
import { handlePermission } from '../../utils/data'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'
import { useHistory } from 'react-router-dom'
import ToggleDropdown from 'src/pages/Representative/Workspace/components/ToggleDropdown'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const ApprovedToDoTable = ({
    data,
    handleModal,
    customCallBack,
    sseUse,
    disabled,
    isOutrightSale,
    kycRedirectLink,
    requestedPackageChange,
    notification,
    offer,
    openUpdateTenureModal,
}) => {
    const todoData = isOutrightSale
        ? formatOutrightSaleToDoData
        : formatToDoData

    const allowPostApprovalChanges =
        process.env.REACT_APP_FLAG_ENABLE_POST_APPROVAL_CHANGES === 'true'
    const history = useHistory()
    const { isMobile } = useMediaQueries()

    return (
        <>
            {allowPostApprovalChanges && requestedPackageChange && (
                <ToggleDropdown
                    id="post-approval"
                    defaultOpen
                    className="PostApprovalChangesNotification"
                    isClosedStyle="isClosed"
                    title={
                        <>
                            <div className="title">
                                <img src={BellIcon} alt="bell" />
                                <h6>Notification</h6>
                            </div>
                        </>
                    }
                    roundedArrow
                >
                    <div className="Content">
                        <hr />
                        <div className="Container">
                            <p className="Notification">
                                This customer has made a request to update their
                                package because{' '}
                                <strong>
                                    <q>{notification}</q>
                                </strong>
                                . Please send a new recommendation as soon as
                                possible.
                            </p>
                            <button
                                onClick={() =>
                                    history.push({
                                        pathname: kycRedirectLink(),
                                        state: {
                                            action: 'post-approval-package-change',
                                        },
                                    })
                                }
                            >
                                <LightBulb className="ChangePackageSvg" />
                                Change Package
                            </button>
                        </div>
                    </div>
                </ToggleDropdown>
            )}
            {allowPostApprovalChanges &&
                (offer?.consumer_status === 'REQUEST_TENURE_CHANGE' ||
                    offer?.consumer_status === 'REJECT_TENURE_CHANGE') && (
                    <ToggleDropdown
                        id="post-approval-tenure-change"
                        defaultOpen
                        className="PostApprovalChangesNotification TenureChange"
                        isClosedStyle="isClosed"
                        title={
                            <>
                                <div className="title">
                                    <img src={BellIcon} alt="bell" />
                                    <h6>Notification</h6>
                                </div>
                            </>
                        }
                        roundedArrow
                    >
                        <div className="Content">
                            <hr />
                            <div className="Container">
                                <p className="Notification">
                                    This customer has made a request to update
                                    their tenure because{' '}
                                    <strong>
                                        <q>{notification}</q>
                                    </strong>
                                    . Please update their tenure as soon as
                                    possible.
                                </p>
                                <button onClick={() => openUpdateTenureModal()}>
                                    Update{!isMobile ? ' tenure' : ''}
                                </button>
                            </div>
                        </div>
                    </ToggleDropdown>
                )}
            <div
                style={{ fontFamily: 'SF-Pro-Display' }}
                className="ATDTLargeTable"
            >
                <div className={`ATDTHead ${sseUse && 'seeUseHead'}`}>
                    <p>Task</p>
                    <p>Updated Time</p>
                    <p>Action</p>
                </div>

                <div className="ATDTGrid">
                    {todoData(data).map((info, i) => (
                        <div
                            className={`ATDTGridItem ${
                                sseUse && 'sseGridItem'
                            }`}
                            key={i}
                        >
                            <div className="ATDTGridItemStatus">
                                {/* action to be/that has been taken */}
                                <p className="ATDTGridItemStatusText">
                                    {info.label}
                                </p>
                                {/* status - pending or completed */}
                                <div
                                    className="ATDTStatusTag"
                                    style={{
                                        backgroundColor: `${info.statusBox}`,
                                    }}
                                >
                                    <p
                                        style={{
                                            color: `${info.statusText}`,
                                        }}
                                    >
                                        {info.statusMsg}
                                    </p>
                                </div>
                                {info.keyID === 'REPAYMENT_METHOD_SETUP' &&
                                    info.statusMsg == 'COMPLETED' && (
                                        <div
                                            className="ATDTStatusTag"
                                            style={{
                                                backgroundColor: `${info.activeStatusBox}`,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: `${info.activeStateText}`,
                                                }}
                                            >
                                                {info.activeStateMsg}
                                            </p>
                                        </div>
                                    )}
                            </div>
                            {/* time last updated */}
                            <p className="ATDTGridItemStatusDate">
                                {info?.updatedTime === null
                                    ? 'N/A'
                                    : info?.updatedTime &&
                                      format(
                                          parseISO(info?.updatedTime),
                                          'dd MMM yyyy, HH:mm',
                                      )}
                            </p>

                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="70px"
                                btnHeight="37px"
                                fontSize="12px"
                                fontFamily="SF-Pro-Display-Medium"
                                handleClick={() => {
                                    if (
                                        info.keyID === 'REPAYMENT_SCHEDULE' &&
                                        info.btnText === 'View'
                                    ) {
                                        customCallBack()
                                    } else {
                                        handleModal(info)
                                    }
                                }}
                                disabled={
                                    handlePermission(info?.permission) ||
                                    (info.keyID === 'UPFRONT_DEPOSIT' &&
                                        data?.upfront_deposit_percentage ===
                                            0) ||
                                    disabled
                                }
                                toolTipText={
                                    handlePermission(info?.permission) ||
                                    (info.keyID === 'UPFRONT_DEPOSIT' &&
                                        data?.upfront_deposit_percentage ===
                                            0) ||
                                    disabled
                                        ? 'You are not authorised to perform this action'
                                        : null
                                }
                                toolTipPopupWidth={167}
                                arrowPositionCenter
                            >
                                {info.btnText}
                            </AppButton>
                        </div>
                    ))}
                </div>
            </div>
            <div
                style={{ fontFamily: 'SF-Pro-Display' }}
                className="ATDTableMobile"
            >
                <div className="ATDTGrid">
                    {todoData(data).map((info, i) => (
                        <div className="ATDTGridItem" key={i}>
                            <div className="ATDTGridItemStatus">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {/* action to be/that has been taken */}
                                    <p className="ATDTGridItemStatusText">
                                        {info.label}
                                    </p>
                                    {/* status - pending or completed */}
                                    <div
                                        className="ATDTStatusTag"
                                        style={{
                                            backgroundColor: `${info.statusBox}`,
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: `${info.statusText}`,
                                            }}
                                        >
                                            {info.statusMsg}
                                        </p>
                                    </div>
                                    {info.keyID === 'REPAYMENT_METHOD_SETUP' &&
                                        info.statusMsg == 'COMPLETED' && (
                                            <div
                                                className="ATDTStatusTag"
                                                style={{
                                                    backgroundColor: `${info.activeStatusBox}`,
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        color: `${info.activeStateText}`,
                                                    }}
                                                >
                                                    {info.activeStateMsg}
                                                </p>
                                            </div>
                                        )}
                                </div>
                                <AppButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="70px"
                                    btnHeight="37px"
                                    fontSize="12px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    handleClick={() => {
                                        if (
                                            info.keyID ===
                                                'REPAYMENT_SCHEDULE' &&
                                            info.btnText === 'View'
                                        ) {
                                            customCallBack()
                                        } else {
                                            handleModal(info)
                                        }
                                    }}
                                    disabled={
                                        handlePermission(info?.permission) ||
                                        disabled
                                    }
                                    toolTipText={
                                        handlePermission(info?.permission) ||
                                        disabled
                                            ? 'You are not authorised to perform this action'
                                            : null
                                    }
                                    // toolTipText={denyPermission('admin', 'approved_requests', info.permission) ? denyPermissionToolTipText : null}
                                >
                                    {info.btnText}
                                </AppButton>
                            </div>

                            {/* time last updated */}
                            <div style={{ marginTop: 35 }}>
                                <p className="MobileTimeUpdatedText">
                                    Updated time
                                </p>
                                <p className="ATDTGridItemStatusDate">
                                    {info?.updatedTime === null
                                        ? 'N/A'
                                        : info?.updatedTime &&
                                          format(
                                              parseISO(info?.updatedTime),
                                              'dd MMM yyyy, HH:mm',
                                          )}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ApprovedToDoTable
