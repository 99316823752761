import { createContext, useContext, useEffect, useState, useRef } from 'react'
import {
    useGetEnergyProfile,
    useGetEnergyProfileSolutions,
    useGetEnergyProfileSolutionsFilter,
} from 'src/api/shoppingExperience/energy-profile/queries'
import { formatProfileManagementDataForMixpanel, sortByParam } from '../utils'
import { useParams, useHistory } from 'react-router-dom'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import { errorHandler } from 'src/utils/errorHandler'
import { encrypt } from 'src/utils/cryptography'
import { useMutation } from 'react-query'
import {
    getReturningCustomerStatusApi,
    registerDropOffApi,
} from 'src/api/shoppingExperience/landingPage'
import { formatPhoneNumber } from 'src/utils/formatting'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useInitiateLoginMutation from 'src/components/ConsumerLandingPageBody/hooks/useInitiateLoginMutation'
import useValidateExistingCustomer from 'src/components/ConsumerLandingPageBody/hooks/useValidateExistingCustomer'
import useDiscardDeal from 'src/components/ConsumerLandingPageBody/hooks/useDiscardDeal'
import { getSearchParamsFromUrl } from 'src/utils/urlTracker'
import { decodeUserInfo } from 'src/utils/auth'
import { visitCount } from 'src/utils/visitCount'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'

export const useSolarSystems = () => {
    const { id } = useParams()
    const filterQuery = getSearchParamsFromUrl()
    const pageTitle =
        'Solar systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `SOLAR_SYSTEM_LISTING`
    const sharedProfile = pageUrlName.includes('/shared')
    // states
    const [showBuildCustomModal, setShowBuildCustomModal] = useState({
        open: false,
        type: 'cancel',
    })
    const [sortBy, setSortBy] = useState(
        filterQuery.sort || 'Price: low to high',
    )
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.page) || 1,
    )
    const [filterInputs, setFilterInputs] = useState({
        system_size: [
            filterQuery.system_size_min || 0,
            filterQuery.system_size_max || 0,
        ],
        system_type: filterQuery.system_type || '',
        payment_plan: filterQuery.payment_plan || '',
        battery_type: filterQuery.battery_type || '',
        backup_hours: [
            filterQuery.backup_hours_min || 0,
            filterQuery.backup_hours_max || 0,
        ],
        cost: [filterQuery.cost_min || 0, filterQuery.cost_max || 0],
        backup_system: filterQuery.backup_system || '',
    })
    const [isClosedCluster, setIsClosedCluster] = useState(false)
    const [action, setAction] = useState('') // customer-exists | confirm-continue-to-package
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showLoginModalCloseButton, setShowLoginModalCloseButton] =
        useState(false)
    const [loginModalContent, setLoginModalContent] = useState('') // select-otp-method | enter-otp
    const [otpMethod, setOtpMethod] = useState('') // email | phone
    const [loginInputs, setLoginInputs] = useState({})
    const viewPackageListPayload = useRef(null)
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [actionModalToast, setActionModalToast] = useState(toastData)
    const [returningCustomerId, setReturningCustomerId] = useState('')
    const [initialDetailsNotProvided, setInitialDetailsNotProvided] =
        useState(false)
    const [showSharedProfile, setShowSharedProfile] = useState(false)
    const [previousModal, setPreviousModal] = useState('')
    const [hasDiscardedDeal, setHasDiscardedDeal] = useState(false)

    const { errorAlert } = useCustomToast()
    const history = useHistory()
    const { validateUserIdMutation, validateUserIdLoading } =
        useValidateExistingCustomer({})
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const showReturningCustomerV2 =
        process.env.REACT_APP_FLAG_RETURNING_CUSTOMERS_V2 === 'true'
    const resetToast = () => {
        setTimeout(() => {
            setActionModalToast(toastData)
        }, 2000)
    }

    const continueToProfile = () => {
        history.push(
            location.pathname.replace(
                '/shared',
                '' + `${window.location.search}`,
            ),
            {
                shared: true,
            },
        )
        location.reload()
    }

    const {
        getReturningCustomerStatus: getNewStatusAfterDiscardingDeal,
        getReturningCustomerStatusLoading:
            getNewStatusAfterDiscardingDealLoading,
    } = useGetReturningCustomerStatus({
        email: loginInputs?.email,
        phone: loginInputs?.phone,
        onSuccess: data => {
            const customerStatus = data?.status
            if (data?.id) setReturningCustomerId(data?.id)
            if (customerStatus === 'ACTIVE_OR_CLOSED_PLAN') {
                sessionStorage.setItem(
                    'returningActiveOrClosedCustomer',
                    JSON.stringify({
                        status: true,
                    }),
                )
            }
            continueToProfile()
        },
        onError: err => {
            setActionModalToast({
                showToast: true,
                toastMessage: errorHandler(err),
                messageType: 'error-secondary',
            })
            resetToast()
        },
        page: 'package-listings-post-discard',
    })

    const discardDealOnSuccess = () => {
        if (showReturningCustomerV2) {
            getNewStatusAfterDiscardingDeal()
        } else {
            continueToProfile()
        }
    }

    const discardDealOnError = errorMsg => {
        setActionModalToast({
            showToast: true,
            toastMessage: errorMsg,
            messageType: 'error-secondary',
        })
        resetToast()
    }

    const { discardDealMutate, discardDealLoading } = useDiscardDeal({
        id: returningCustomerId,
        onSuccess: discardDealOnSuccess,
        onError: discardDealOnError,
    })

    const closeLoginModal = () => {
        setShowLoginModal(false)
        setLoginModalContent('')
    }

    const {
        mutateAsync: getReturningCustomerStatusAsync,
        isLoading: getReturningCustomerStatusLoading,
    } = useMutation(
        ({ email, phone }) => getReturningCustomerStatusApi(email, phone),
        {
            onSuccess: response => {
                const data = response?.data?.data
                if (data?.id) {
                    setReturningCustomerId(data.id)
                }
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    const onLoginSuccess = async data => {
        const userFirstName = data?.user?.first_name
        const userEmail = data?.user?.email
        const userPhone = data?.user?.phone_number

        const email = initialDetailsNotProvided ? userEmail : loginInputs?.email
        const phone = initialDetailsNotProvided
            ? userPhone
            : formatPhoneNumber(loginInputs?.phone)

        if (initialDetailsNotProvided) {
            setLoginInputs({
                email: userEmail,
                phone: userPhone,
                firstName: userFirstName,
            })
        }
        await registerDropOffMutateAsync({
            email: email,
            phone: phone,
            firstName: initialDetailsNotProvided
                ? userFirstName
                : loginInputs?.firstName,
        })

        const statusResponse = await getReturningCustomerStatusAsync({
            email,
            phone,
        })
        const status = statusResponse?.data?.data?.status

        if (showReturningCustomerV2 && status === 'ACTIVE_OR_CLOSED_PLAN') {
            sessionStorage.setItem(
                'returningActiveOrClosedCustomer',
                JSON.stringify({
                    status: true,
                }),
            )
        }

        closeLoginModal()

        if (data?.customer_type === null) {
            history.push(
                location.pathname.replace(
                    '/shared',
                    '' + `${window.location.search}`,
                ),
                {
                    shared: true,
                },
            )
            location.reload()
        } else if (showReturningCustomerV2) {
            if (!hasDiscardedDeal && status !== 'ACTIVE_OR_CLOSED_PLAN') {
                openActionModal('confirm-continue-to-package')
            } else {
                history.push(
                    location.pathname.replace(
                        '/shared',
                        '' + `${window.location.search}`,
                    ),
                    {
                        shared: true,
                    },
                )
                location.reload()
            }
        } else {
            openActionModal('confirm-continue-to-package')
        }
    }

    const { getEnergyProfileSolutionsData, getEnergyProfileSolutionsFetching } =
        useGetEnergyProfileSolutions({
            enabled: true,
            params: {
                profile_id: id ?? '',
                page: currentPage,
                price_sort: sortByParam[sortBy],
                battery_types: filterInputs?.battery_type
                    ?.toLowerCase()
                    ?.replaceAll(' ', '_'),
                system_types: filterInputs?.system_type
                    ?.toLowerCase()
                    ?.replaceAll(' ', '_'),
                payment_plan: filterInputs?.payment_plan
                    ?.toUpperCase()
                    ?.replaceAll(' ', '_'),
                min_system_size:
                    filterQuery.system_size_min === 0
                        ? ''
                        : filterQuery.system_size_min,
                max_system_size:
                    filterQuery.system_size_max === 0
                        ? ''
                        : filterQuery?.system_size_max,
                min_cost:
                    filterInputs?.cost[0] === 0 ? '' : filterInputs?.cost[0],
                max_cost:
                    filterInputs?.cost[1] === 0 ? '' : filterInputs?.cost[1],
                min_backup_hour:
                    filterInputs?.backup_hours[0] === 0
                        ? ''
                        : filterInputs?.backup_hours[0],
                max_backup_hour:
                    filterInputs?.backup_hours[1] === 0
                        ? ''
                        : filterInputs?.backup_hours[1],
            },
        })
    const getFilterDataOnSuccess = data => {
        setFilterInputs(prev => ({
            ...prev,
            system_size: [data?.min_system_size, data?.max_system_size],
        }))
    }
    const {
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
    } = useGetEnergyProfileSolutionsFilter({
        enabled: true,
        params: {
            profile_id: id ?? '',
        },
        onSuccess: getFilterDataOnSuccess,
    })

    const { getEnergyProfileData, getEnergyProfileFetching } =
        useGetEnergyProfile({
            enabled: id?.length ? true : false,
            params: {
                id: id ?? '',
            },
            onSuccess: data => {
                const isResidential = data?.customer_type === 'RESIDENTIAL'
                setIsClosedCluster(data?.promotional_type === 'PRIVATE')
                if (sharedProfile) {
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')[
                            'viewedSharedProfile'
                        ].action,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .label,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .category,
                        ['MP', 'GA'],
                        'event',
                    )
                } else {
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')[
                            'viewListing'
                        ].action,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .label,
                        consumerProfileEvents(!isResidential ? 'BP' : 'RP')
                            .category,
                        ['MP', 'GA'],
                        'event',
                    )
                }
            },
        })

    const { mutateAsync: registerDropOffMutateAsync } = useMutation({
        mutationFn: ({ email, phone, firstName }) =>
            registerDropOffApi({
                email,
                phone_number: formatPhoneNumber(phone),
                first_name: firstName,
            }),
        onSuccess: () => {
            localStorage.setItem(
                'sunfiUserSSEGettingStartedInfo',
                encrypt(
                    JSON.stringify({
                        firstName: loginInputs.firstName,
                        email: loginInputs.email,
                        ...(loginInputs?.phone !== '' && {
                            phone: formatPhoneNumber(loginInputs?.phone),
                        }),
                        ...(isClosedCluster && {
                            useType: closedClusterData?.customer_type,
                        }),
                    }),
                ),
            )
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    const closedClusterData = getEnergyProfileData?.data?.data

    const goBack = () => {
        if (isAdminUser) {
            history.goBack()
        } else {
            history.push(userInfo?.consumerKYCHome)
        }
    }

    const clearFilters = data => {
        setFilterInputs({
            system_size: [data?.min_system_size, data?.max_system_size],
            system_type: '',
            payment_plan: '',
            battery_type: '',
            backup_hours: [0, 0],
            cost: [0, 0],
            backup_system: '',
        })
    }

    const openActionModal = action => {
        setAction(action)
        setActionModalOpen(true)
    }

    const closeActionModal = () => {
        setAction('')
        setActionModalOpen(false)
    }

    const openLoginNoDetailsProvided = (previous = '') => {
        if (previous === 'shared-form') {
            setShowLoginModalCloseButton(true)
        }

        setInitialDetailsNotProvided(true)
        setLoginModalContent('select-otp-method')
        setShowLoginModal(true)
        setShowSharedProfile(false)
        setPreviousModal(previous)
    }

    const closeLoginNoDetailsProvided = () => {
        const isCloseable = showLoginModalCloseButton

        if (previousModal === 'shared-form') {
            setShowSharedProfile(true)
            setShowLoginModalCloseButton(false)
        }
        if (isCloseable) {
            setShowLoginModal(false)
            setPreviousModal('')
        }
    }

    const { mutate: initiateLogin, isLoading: initiateLoginLoading } =
        useInitiateLoginMutation(
            loginInputs,
            () => {
                setLoginModalContent('enter-otp')
                setShowLoginModal(true)
                closeActionModal()
            },
            err => {
                setActionModalToast({
                    showToast: true,
                    toastMessage: errorHandler(err),
                    messageType: 'error-secondary',
                })
                resetToast()
            },
            'consumer',
            pageUrlName,
            pageTracker,
            pageTitle,
        )

    const validateReturningUserContact = async () => {
        const [emailResult, phoneResult] = await Promise.all([
            validateUserIdMutation.mutateAsync({ email: loginInputs?.email }),
            validateUserIdMutation.mutateAsync({
                phone_number: formatPhoneNumber(loginInputs?.phone),
            }),
        ])

        const emailExists = emailResult?.data?.data
        const phoneExists = phoneResult?.data?.data

        const otpMethod = emailExists ? 'email' : 'phone'
        setOtpMethod(otpMethod)

        if (emailExists && phoneExists) {
            setLoginModalContent('select-otp-method')
            setActionModalOpen(false)
            setShowLoginModal(true)
        } else {
            initiateLogin(otpMethod)
        }
    }

    const actionModalHandler = () => {
        switch (action) {
            case 'customer-exists':
                validateReturningUserContact()
                break
            case 'confirm-continue-to-package':
                discardDealMutate()
                break
        }
    }

    const actionModalSecondaryHandler = () => {
        switch (action) {
            case 'confirm-continue-to-package':
                history.push(userInfo?.consumerKYCHome)
                break
        }
    }

    useEffect(() => {
        const profileData = getEnergyProfileData?.data?.data
        const supportedPaymentPlans =
            getEnergyProfileSolutionsData?.data?.data?.[0]?.supported_payment_plans?.map(
                data => data.replace(/_/g, ' '),
            ) || []

        if (profileData) {
            const viewPackageListEvent =
                consumerProfileEvents()['viewPackageList']

            const payload = formatProfileManagementDataForMixpanel(
                {
                    profileName: profileData.name,
                    planTypes: supportedPaymentPlans.join(', '),
                    customerType: profileData.customer_type,
                    sortBy,
                    filterCost: filterInputs?.cost
                        ? `${filterInputs.cost[0]} - ${filterInputs.cost[1]} Naira`
                        : '',
                    filterPaymentPlanType: filterInputs?.payment_plan,
                    filterSystemSize: filterInputs?.system_size
                        ? `${filterInputs.system_size[0]} - ${filterInputs.system_size[1]} KvA`
                        : '',
                    filterBatteryType: filterInputs?.battery_type,
                    filterSystemType: filterInputs?.system_type,
                    filterSystemBackupHours: filterInputs?.backup_hours
                        ? `${filterInputs.backup_hours[0]} - ${filterInputs.backup_hours[1]} hours`
                        : '',
                    visitCount: visitCount(
                        profileData.id,
                        'profile_listing_visits',
                        true,
                    ),
                },
                'view-package-list',
            )

            if (
                !viewPackageListPayload.current ||
                JSON.stringify(viewPackageListPayload.current) !==
                    JSON.stringify(payload)
            )
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    viewPackageListEvent.action,
                    viewPackageListEvent.label,
                    viewPackageListEvent.category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: payload,
                    },
                )

            viewPackageListPayload.current = payload
        }
    }, [
        filterInputs,
        sortBy,
        getEnergyProfileData,
        pageUrlName,
        pageTracker,
        getEnergyProfileSolutionsData,
    ])

    const actionModalPrimaryBtnLoading =
        initiateLoginLoading ||
        validateUserIdLoading ||
        discardDealLoading ||
        getReturningCustomerStatusLoading ||
        getNewStatusAfterDiscardingDealLoading

    return {
        id,
        getEnergyProfileSolutionsData,
        getEnergyProfileSolutionsFetching,
        currentPage,
        setCurrentPage,
        sortBy,
        setSortBy,
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
        filterInputs,
        setFilterInputs,
        clearFilters,
        showBuildCustomModal,
        setShowBuildCustomModal,
        getEnergyProfileData,
        getEnergyProfileFetching,
        isClosedCluster,
        setIsClosedCluster,
        action,
        actionModalOpen,
        openActionModal,
        closeActionModal,
        showLoginModal,
        setShowLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        setLoginInputs,
        otpMethod,
        setOtpMethod,
        actionModalHandler,
        actionModalToast,
        actionModalPrimaryBtnLoading,
        actionModalSecondaryHandler,
        setReturningCustomerId,
        openLoginNoDetailsProvided,
        closeLoginNoDetailsProvided,
        initialDetailsNotProvided,
        showSharedProfile,
        setShowSharedProfile,
        onLoginSuccess,
        goBack,
        isPromotional: getEnergyProfileData?.data?.data?.is_promotional, // promotional public or private cluster
        setShowLoginModalCloseButton,
        showLoginModalCloseButton,
        setHasDiscardedDeal,
    }
}

export const SolarSystemsContext = createContext({
    id: null,
    getEnergyProfileSolutionsData: {},
    getEnergyProfileSolutionsFetching: false,
    currentPage: 1,
    setCurrentPage: () => {},
    getEnergyProfileSolutionsFilterData: {},
    getEnergyProfileSolutionsFilterFetching: false,
    filterInputs: {
        system_size: [0, 0],
        system_type: '',
        payment_plan: '',
        battery_type: '',
        backup_hours: [0, 0],
        cost: [0, 0],
        backup_system: '',
    },
    setFilterInputs: () => {},
    sortBy: 'All',
    setSortBy: () => {},
    clearFilters: () => {},
    showBuildCustomModal: false,
    setShowBuildCustomModal: () => {},
    getEnergyProfileData: {},
    getEnergyProfileFetching: false,
    isClosedCluster: false,
    setIsClosedCluster: () => {},
    isPromo: false,
    action: '',
    actionModalOpen: false,
    openActionModal: () => {},
    closeActionModal: () => {},
    showLoginModal: false,
    setShowLoginModal: () => {},
    loginModalContent: '',
    setLoginModalContent: () => {},
    loginInputs: {},
    setLoginInputs: () => {},
    otpMethod: '',
    setOtpMethod: () => {},
    actionModalHandler: () => {},
    actionModalToast: {},
    actionModalPrimaryBtnLoading: false,
    actionModalSecondaryHandler: () => {},
    setReturningCustomerId: () => {},
    openLoginNoDetailsProvided: () => {},
    closeLoginNoDetailsProvided: () => {},
    setShowLoginModalCloseButton: () => {},
    showLoginModalCloseButton: false,
    initialDetailsNotProvided: false,
    showSharedProfile: false,
    setShowSharedProfile: () => {},
    onLoginSuccess: () => {},
    goBack: () => {},
    isPromotional: false,
    setHasDiscardedDeal: () => {},
})

export const useSolarSystemsContext = () => useContext(SolarSystemsContext)
