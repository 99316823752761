import { ReactComponent as CoinsIcon } from 'src/assets/images/financial.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import { floatWithCommas, numberWithCommas } from 'src/utils/formatting'
import SuccessIconSecondary from 'src/assets/images/successImage-secondary.svg'
import s from './styles.module.scss'

export const depositChangeModalValues = action => {
    switch (action) {
        case 'confirm-change-request':
            return {
                modalTitle: 'Upfront Deposit Increase',
                modalContent: 'action-modal',
                actionIcon: <CoinsIcon />,
                actionHeading: 'How Your Upfront Deposit Increase Works',
                actionDescription:
                    'When you increase  your upfront deposit, your monthly repayment will be adjusted accordingly. If you have already made your initial upfront deposit, you will be required to pay the additional amount requested before your new payment plan takes effect.',
                primaryBtnText: 'Continue',
                footerLeftContent: 'chat',
            }
        case 'enter-new-upfront-deposit':
            return {
                modalTitle: 'Upfront Deposit Increase',
                modalContent: 'upfront-deposit-form',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: 'Continue',
                footerLeftContent: null,
            }
        case 'request-sent-successfully':
            return {
                modalTitle: 'Upfront Deposit Increased Successfully!',
                modalContent: 'action-modal',
                actionIcon: <SuccessIcon />,
                actionHeading:
                    'Your upfront deposit has been increased successfully!',
                actionDescription:
                    'Your upfront deposit has been increased successfully. You can now proceed with the payment to adjust your monthly repayment amount.',
                primaryBtnText: 'Proceed to payment',
                footerLeftContent: 'chat',
            }
        case 'confirm-upfront-deposit':
            return {
                modalTitle: 'Complete payment',
                modalContent: 'payment-details',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: 'Make payment',
                footerLeftContent: 'chat',
            }
        case 'payment-made-successfully':
            return {
                modalTitle: 'Payment successful!',
                modalContent: 'action-modal',
                actionIcon: (
                    <img
                        src={SuccessIconSecondary}
                        alt="success"
                        role="presentation"
                        className={s.successIcon}
                    />
                ),
                actionHeading: 'Your payment was successful!',
                actionDescription:
                    'We are pleased to inform you that your payment has been received successfully and changes to monthly payments have been effected.',
                primaryBtnText: 'Okay, got it!',
                footerLeftContent: 'chat',
            }
        default:
            return {
                modalTitle: '',
                modalContent: '',
                actionIcon: '',
                actionHeading: '',
                actionDescription: '',
                primaryBtnText: '',
                footerLeftContent: null,
            }
    }
}

export const formatDepositInfo = data => {
    const values = [
        {
            label: 'Original upfront deposit',
            value: `₦${numberWithCommas(data?.old_upfront_deposit_amount)}`,
        },
        {
            label: 'New upfront deposit',
            value: `₦${numberWithCommas(data?.new_upfront_deposit_amount)}`,
        },
        {
            label: 'Months of repayment',
            value: `${data?.months_of_repayment} months`,
        },
        {
            label: 'New monthly payment',
            value: `₦${floatWithCommas(data?.monthly_payment?.toString())}`,
        },
    ]
    return values
}
