import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useConsumerKYCContext } from '../../context'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { convertArrayOfStringsToObjectOfBooleans } from '../../utils'

const PersonalInfo = () => {
    const { isMobile } = useMediaQueries()
    const {
        inputs,
        handleInputChange,
        handleBlur,
        isAdminUser,
        isCustomerApproved,
        onInputFocus,
        providerUpdatedFieldsData,
        isDEPConsumer,
    } = useConsumerKYCContext()

    const basicInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.basicInformation || [],
        )
    const contactInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.contactInformation || [],
        )

    return (
        <div className="PersonalInfo">
            <div className="GroupItems">
                <InputFields
                    title="First Name"
                    value={inputs?.firstName}
                    name="firstName"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                    onBlur={handleBlur}
                    disabled={isCustomerApproved}
                    onFocus={onInputFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.firstName ||
                            contactInformationUpdatedFields.firstName)
                    }
                />
                <InputFields
                    title="Last Name"
                    value={inputs?.lastName}
                    name="lastName"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                    onBlur={handleBlur}
                    disabled={isCustomerApproved}
                    onFocus={onInputFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.lastName ||
                            contactInformationUpdatedFields.lastName)
                    }
                />
            </div>

            <div className="GroupItems">
                <InputFields
                    title="Email Address"
                    value={inputs?.email}
                    name="email"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                    onBlur={handleBlur}
                    verified={inputs?.emailVerified}
                    disabled={
                        inputs?.emailVerified ||
                        isAdminUser ||
                        isCustomerApproved
                    }
                    onFocus={onInputFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.email ||
                            contactInformationUpdatedFields.email)
                    }
                />
                <SelectCountryCode
                    title="Phone number"
                    inputValue={inputs?.phone}
                    marginBottom={isMobile ? '16px' : ''}
                    name="phone"
                    onBlur={handleBlur}
                    handleChange={handleInputChange}
                    verified={inputs?.phoneVerified}
                    disabled={
                        inputs?.phoneVerified ||
                        isAdminUser ||
                        isCustomerApproved
                    }
                    onFocus={onInputFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.phone ||
                            contactInformationUpdatedFields.phone)
                    }
                />
            </div>
        </div>
    )
}

export default PersonalInfo
