import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import arrow from 'src/assets/images/arrowDown.svg'
import arrowRounded from 'src/assets/images/arrow-rounded.svg'
import styles from './styles.module.scss'

const ToggleDropdown = ({
    title,
    id,
    children,
    openTitle,
    className,
    defaultOpen,
    isClosedStyle,
    roundedArrow,
}) => {
    const [openDropdowns, setOpenDropdowns] = useState({})

    useEffect(() => {
        if (defaultOpen) {
            setOpenDropdowns(prev => ({
                ...prev,
                [id]: true,
            }))
        }
    }, [defaultOpen, id])

    const toggleBox = id => {
        setOpenDropdowns(prev => ({
            ...prev,
            [id]: !prev[id],
        }))
    }

    return (
        <div
            className={`${styles.DropdownContainer} ${className}`}
            style={{
                padding: openDropdowns[id] ? '16px' : 0,
            }}
        >
            <div
                className={`${styles.DetailsDropdown} ${
                    openDropdowns[id]
                        ? styles.isOpen
                        : isClosedStyle ?? styles.isClosed
                }`}
                onClick={() => toggleBox(id)}
            >
                <p>{openDropdowns[id] ? openTitle || title : title}</p>

                <div>
                    <img
                        src={roundedArrow ? arrowRounded : arrow}
                        alt="arrow"
                        className={`${styles.arrowIcon} ${
                            openDropdowns[id]
                                ? 'TopArrowOpen'
                                : 'TopArrowClosed'
                        }`}
                    />
                </div>
            </div>
            {openDropdowns[id] && <>{children}</>}
        </div>
    )
}

ToggleDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.node,
    openTitle: PropTypes.string,
    className: PropTypes.string,
    defaultOpen: PropTypes.bool,
    isClosedStyle: PropTypes.string,
    roundedArrow: PropTypes.bool,
}

export default ToggleDropdown
