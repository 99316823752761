//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Upload } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import './estimations-stepone.scss'
import LeftArrow from 'src/assets/images/left-blue-arrow.svg'
import { InputFields, SelectField } from 'src/components/InputFields'
import RadioSelected from 'src/assets/images/radio-selected-icon.svg'
import RadioUnselected from 'src/assets/images/radio-unselected-icon.svg'
import { ReactComponent as InfoIcon } from 'src/assets/images/info.svg'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import {
    CustomerAcquisitionData,
    paymentMethodData,
    tenurePaymentData,
    systemsRecoveryData,
    remoteMonitoringData,
    generatorSizeData,
    installationDetailsData,
    installationDetailsDataMapping,
    selectPackageFromOptions,
} from './data'
import {
    createEstimationsStepOneApi,
    createEstimationsStepOne2Api,
    reEstimationsStepOneApi,
    getEstimationById,
    getCreditVariables,
    getProviderSubscriptionModelEnabled,
    getCreditVariableCount,
} from 'src/api/estimations/estimations-step-one'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'
import { useLocation } from 'react-router-dom'
import {
    EstimationStepOneValidationSchema,
    EstimationStepTwoValidationSchema,
} from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import SeoComponent from 'src/components/Seo'
import parsePhoneNumber from 'libphonenumber-js'
import ActionModal from 'src/components/ActionModal'
import { eventTrackers } from 'src/utils/eventTrackers.js'
import { appTracking } from 'src/utils/appTracker.js'
import { stateData } from 'src/utils/stateData'
import { AppButton } from 'src/components/Button'
import Button from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import { titleCase } from 'src/utils/formatting'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import UpdateNeeds from 'src/components/UpdateNeeds'
import HalfMoonIcon from 'src/assets/images/half-moon-icon.svg'
import SunIcon from 'src/assets/images/sunlight.svg'
import { numberWithCommas } from 'src/utils/formatting'
import SelectLibrary from './SelectLibrary'
import { ReactComponent as HelpIcon } from 'src/assets/images/grey-help-circle.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import Editor from 'src/components/Editor'
import { replaceHtmlTags, toTitleCase } from 'src/utils/formatting'

const EstimationsStepOne = () => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    //persist estimations ID from step one part one
    const [estimationsId, setEstimationsId] = useState('')
    const estimationId = params.id || location.state?.params || estimationsId
    const [estimationData, setEstimationData] = useState({})
    const [isChecked, setIsChecked] = useState('')
    const [isCheckedTwo, setIsCheckedTwo] = useState('')
    const [uploading, setUploading] = useState(false)
    const [successUpload, setSuccessUpload] = useState([])
    const [creditCheckProof, setCreditCheckProof] = useState('')
    const [width, setWidth] = useState(40)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    //api interface
    const [ran_credit_check, setRanCreditCheck] = useState('')
    const [errors, setErrors] = useState({})
    const [countryCode, setCountryCode] = useState('+234')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [inputs, setInputs] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        customer_acquisition: '',
        upfront_deposit: '',
        repayment_method: '',
        financing_term: '',
        system_ease_of_recovery: '',
        remote_monitoring_capabilities: '',
        businessName: '',
        businessAddress: '',
        businessCity: '',
        businessState: '',
        genSize: '',
        installationSiteReady: '',
        roofAssessment: '',
        installationAwareness: '',
        projectComments: '',
        selectPackageFrom: '',
    })
    const [payment_plan, setPaymentPlanType] = useState('')
    //persist re-estimations status from step one part one
    const [reEstimationStatus, setReEstimationStatus] = useState('')
    const [upfrontDeposit, setUpfrontDeposit] = useState({})
    const [
        restrictMultipleApprovedPaymentPlansModal,
        setRestrictMultipleApprovedPaymentPlansModal,
    ] = useState(false)
    const [creditVariables, setCreditVariables] = useState([])
    const [subscriptionModelEnabled, setSubscriptionModelEnabled] =
        useState(false)
    const [showModal, setShowModal] = useState(false)
    const [energyWarningModal, setEnergyWarningModal] = useState(false)
    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'
    const [showSelectLibraryModal, setShowSelectLibraryModal] = useState(false)
    const [library, setLibrary] = useState('')

    const [energyNeeds, setEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            backup_hours_night: '',
            power_rating: '',
            unit_of_measurement: '',
        },
    ])
    const [creditVariableCheck, setCreditVariableCheck] = useState({
        hasSubscription: false,
        hasLeaseToOwn: false,
    })

    useEffect(() => {
        let fetchedAppliances = energyNeeds
        if (fetchedAppliances?.length !== 0 && estimationId) {
            setEnergyNeeds(
                fetchedAppliances?.map(appliance => {
                    return {
                        ...appliance,
                        backup_hours: appliance?.backup_hours,
                        backup_hours_night: appliance.backup_hours_night,
                        quantity: appliance?.quantity,
                        name: appliance?.name,
                        power_rating: appliance?.power_rating,
                        unit_of_measurement: appliance?.unit_of_measurement,
                    }
                }),
            )
        }
    }, [])

    //summary calculation

    const wattsPerAppliance = energyNeeds?.map(
        item => item.power_rating * item.quantity,
    )
    const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b, 0)

    const dayUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours * item.quantity,
    )
    const totalDayUsage = dayUsage?.reduce((a, b) => a + b, 0)
    const nightUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours_night * item.quantity,
    )
    const totalNightUsage = nightUsage?.reduce((a, b) => a + b, 0)
    const averageDailyUsage = totalDayUsage + totalNightUsage
    useEffect(() => {
        localStorage.setItem('estimationsId', estimationsId)
    }, [estimationsId])

    useEffect(() => {
        localStorage.setItem('reEstimationStatus', reEstimationStatus)
    }, [reEstimationStatus])

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    //getcreditvariables

    const { refetch: creditVariablesRefetch } = useQuery(
        'get-credit-variables',
        () => getCreditVariables(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setCreditVariables(data?.data?.data)
            },
        },
    )

    // getcreditvariablecount

    const { refetch: creditVariableCheckRefetch } = useQuery(
        'get-credit-variable-count',
        () => getCreditVariableCount(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const creditVariableData = data?.data?.data

                creditVariableData.forEach(data => {
                    if (
                        data?.payment_model === 'SUBSCRIPTION' &&
                        data.count > 0
                    ) {
                        setCreditVariableCheck(prev => ({
                            ...prev,
                            hasSubscription: true,
                        }))
                    }

                    if (
                        data?.payment_model === 'LEASE_TO_OWN' &&
                        data.count > 0
                    ) {
                        setCreditVariableCheck(prev => ({
                            ...prev,
                            hasLeaseToOwn: true,
                        }))
                    }
                })
            },
        },
    )

    // get subscription model enabled
    const { refetch: subscriptionModelSupportRefetch } = useQuery(
        'get-provider-subscription-model-enabled',
        () => getProviderSubscriptionModelEnabled(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setSubscriptionModelEnabled(data?.data?.data)
            },
        },
    )

    useEffect(() => {
        creditVariablesRefetch()
        creditVariableCheckRefetch()
    }, [])
    useEffect(() => subscriptionModelSupportRefetch(), [])

    const handleInputChange = event => {
        const { name, value } = event.target
        setErrorMessage(prev => ({ ...prev, 0: { [name]: '' } }))

        if (value === '' || 0) {
            if (name !== 'email')
                setErrors(prev => ({
                    ...prev,
                    [name]: 'This field is required',
                }))
        } else if (name === 'upfront_deposit' && Number(value) > 100) {
            setErrors(prev => ({
                ...prev,
                [name]: 'Upfront deposit should not be greater than 100%',
            }))
        } else if (
            name === 'upfront_deposit' &&
            value < parseInt(upfrontDeposit?.upfront_deposit)
        ) {
            setErrors(prev => ({
                ...prev,
                [name]: `The lowest upfront deposit is ${parseInt(
                    upfrontDeposit?.upfront_deposit,
                )}%, enter ${parseInt(
                    upfrontDeposit?.upfront_deposit,
                )} or more`,
            }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        if (name === 'phone_number') {
            setInputs(preState => ({
                ...preState,
                [name]: formatPhoneNumber(value, countryCode),
            }))
            setPhoneNumber(value)
        } else if (['first_name', 'last_name', 'email'].includes(name)) {
            setInputs(preState => ({
                ...preState,
                [name]: formatInputValue(name, value).trim(),
            }))
        } else {
            setInputs(preState => ({
                ...preState,
                [name]: value.trim(),
            }))
        }
    }

    const handleOnBlur = e => {
        const { name, value } = e.target

        if (name === 'phone_number') {
            setInputs(preState => ({
                ...preState,
                [name]: formatPhoneNumber(value, countryCode).trim(),
            }))
            setPhoneNumber(value.trim())
        } else if (['first_name', 'last_name', 'email'].includes(name)) {
            setInputs(preState => ({
                ...preState,
                [name]: value.trim(),
            }))
        } else {
            setInputs(preState => ({
                ...preState,
                [name]: value,
            }))
        }
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleEditorChange = value => {
        setInputs(prev => ({ ...prev, projectComments: value }))
        if (value === '') {
            setErrors(prev => ({
                ...prev,
                projectComments: 'This field is required',
            }))
        } else {
            setErrors(prev => ({ ...prev, projectComments: '' }))
        }
    }
    const { Dragger } = Upload

    const props = {
        name: 'file',

        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
        },
        onDrop() {
            setUploading(false)
        },
    }
    const newCustomerType = location?.state?.newCustomerType?.toLowerCase()
    const customerData = location?.state?.customerData
    const customerInfo = location?.state?.customerInfo
    const isExistingUser = location?.state?.isExistingUser

    const [showForm, setShowForm] = useState(false)

    useEffect(() => {
        setPhoneNumber(customerInfo?.phoneNumber || phoneNumber)
        setInputs(prev => ({
            ...prev,
            first_name: customerInfo?.firstName || prev.first_name,
            last_name: customerInfo?.lastName || prev.last_name,
            email: customerInfo?.email || prev.email,
            phone_number: customerInfo?.phoneNumber
                ? formatPhoneNumber(customerInfo.phoneNumber, countryCode)
                : prev.phone_number,
            businessName: customerInfo?.businessName || prev.businessName,
            businessAddress:
                customerInfo?.businessAddress || prev.businessAddress,
            businessCity: customerInfo?.businessCity || prev.businessCity,
            businessState: customerInfo?.businessState || prev.businessState,
        }))
    }, [customerInfo])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [showForm])

    const businessPayload = {
        name: inputs.businessName,
        address: inputs.businessAddress,
        city: inputs.businessCity,
        state: inputs.businessState,
    }

    //step one payload
    const consumerInformation = {
        first_name:
            customerData === undefined
                ? inputs.first_name
                : customerData.firstName,
        last_name:
            customerData === undefined
                ? inputs.last_name
                : customerData?.lastName,
        phone_number:
            customerData === undefined
                ? inputs.phone_number
                : customerData?.phoneNumber,
        email: customerData === undefined ? inputs.email : customerData?.email,
        [customerData === undefined &&
        newCustomerType === 'business' &&
        'business']: JSON.stringify(businessPayload),
        customer_acquisition: inputs.customer_acquisition,
        ran_credit_check,
        repayment_method: inputs.repayment_method,
        credit_check_proof: successUpload[0]?.originFileObj,
    }

    ran_credit_check === (false || undefined) &&
        delete consumerInformation.credit_check_proof
    //Post req. for step one part one
    const {
        isFetching: createEstimationsStepOneLoading,
        refetch: createEstimationsStepOneRefetch,
    } = useQuery(
        ['createEstimationsStepOne'],
        () => createEstimationsStepOneApi(consumerInformation),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setEstimationsId(data.data.data.id)
                setShowForm(true)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['createPaymentPlanStepOnePartOne'].action,
                    eventTrackers['createPaymentPlanStepOnePartOne'].label,
                    eventTrackers['createPaymentPlanStepOnePartOne'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            payment_plan_step_one_part_one_info:
                                consumerInformation,
                        },
                    },
                )
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data, 'new'))
                } else if (data?.message.includes('409')) {
                    setRestrictMultipleApprovedPaymentPlansModal(true)
                } else {
                    setErrorMessage([
                        'Failed to create payment plan. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const handlePaymentPlanType = model => {
        // Clear any upfront deposit errors
        setErrors(prev => ({
            ...prev,
            upfront_deposit: '',
        }))

        const model_mapping = {
            sub: 'SUBSCRIPTION',
            les: 'LEASE_TO_OWN',
        }
        const model_variable = creditVariables.find(
            variable => variable?.payment_model === model_mapping[model],
        )
        setUpfrontDeposit(model_variable)
        setInputs(preState => ({
            ...preState,
            ['upfront_deposit']: '',
        }))
    }
    //Post req. for step one part one
    const handleSavePackage = () => {
        setIsChecked(
            isChecked !== 'yes' && isChecked !== 'no' ? 'none' : isChecked,
        )
        setToastError(false)
        setErrors({})
        if (customerData !== undefined) {
            if (inputs.customer_acquisition === '') {
                setErrors(prev => ({
                    ...prev,
                    customer_acquisition: 'This field is required',
                }))
            } else if (inputs.repayment_method === '') {
                setErrors(prev => ({
                    ...prev,
                    repayment_method: 'This field is required',
                }))
            } else {
                setErrors('')
                createEstimationsStepOneRefetch()
            }
        } else {
            EstimationStepOneValidationSchema.validate(inputs, {
                abortEarly: false,
            })
                .then(() => {
                    setErrors('')
                    createEstimationsStepOneRefetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }
    //Patch req. for restimations step one part one
    const {
        isFetching: restimationsStepOneLoading,
        refetch: restimationsStepOneRefetch,
    } = useQuery(
        ['ReEstimationsStepOne'],
        () => reEstimationsStepOneApi(estimationId, consumerInformation),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setEstimationsId(data.data.data.id)
                setReEstimationStatus('reEstimation')
                setShowForm(true)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['restimatedPaymentPlanStepOnePartOne'].action,
                    eventTrackers['restimatedPaymentPlanStepOnePartOne'].label,
                    eventTrackers['restimatedPaymentPlanStepOnePartOne']
                        .category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            restimated_payment_plan_step_one_part_one_info:
                                consumerInformation,
                        },
                    },
                )
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else if (data?.message.includes('409')) {
                    setRestrictMultipleApprovedPaymentPlansModal(true)
                } else {
                    setErrorMessage([
                        'Failed to create payment plan. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    //Patch req. for restimations step one part one
    const handleRestimation = () => {
        setToastError(false)
        restimationsStepOneRefetch()
    }
    //Patch req. for step one part two
    const {
        isFetching: createEstimationsStepOne2Loading,
        refetch: createEstimationsStepOne2Refetch,
    } = useQuery(
        ['createEstimationsStepOne2'],
        () =>
            createEstimationsStepOne2Api(estimationsId, {
                financing_term: inputs.financing_term.match(/\d/g).join(''),
                system_ease_of_recovery: inputs.system_ease_of_recovery,
                package_selected_from: inputs.selectPackageFrom,
                upfront_deposit: inputs.upfront_deposit,
                remote_monitoring_capabilities:
                    inputs.remote_monitoring_capabilities,
                payment_plan,
                appliances: energyNeeds.map(item => ({
                    backup_hours: item.backup_hours,
                    backup_hours_night: item.backup_hours_night,
                    quantity: item.quantity,
                    name: item.name,
                    power_rating: item.power_rating,
                    unit_of_measurement: item.unit_of_measurement,
                })),
                ...(process.env.REACT_APP_FLAG_SHOW_INSTALLATION_DETAILS ===
                    'true' && {
                    customer_generator_size: inputs.genSize,
                    is_site_ready: inputs.installationSiteReady,
                    roof_assessment: inputs.roofAssessment,
                    customer_installation_awareness:
                        inputs.installationAwareness,
                    additional_comment: replaceHtmlTags(inputs.projectComments),
                }),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowSelectLibraryModal(false)
                let currentUrl = location.pathname
                if (library === 'cluster') {
                    history.push({
                        pathname: '/app/payment-plans/cluster-package',
                        state: { paymentPlanModel: payment_plan },
                    })
                } else {
                    switch (currentUrl) {
                        case '/app/payment-plans/create/one':
                            history.push('/app/payment-plans/create/two', {
                                isEstimationTwo: true,
                            })
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers[
                                    'restimatedPaymentPlanStepOnePartTwo'
                                ].action,
                                eventTrackers[
                                    'restimatedPaymentPlanStepOnePartTwo'
                                ].label,
                                eventTrackers[
                                    'restimatedPaymentPlanStepOnePartTwo'
                                ].category,
                                ['MP', 'GA'],
                                'event',
                                {
                                    event: {
                                        payment_plan_step_one_part_two_info: {
                                            financing_term:
                                                inputs.financing_term
                                                    .match(/\d/g)
                                                    .join(''),
                                            system_ease_of_recovery:
                                                inputs.system_ease_of_recovery,
                                            upfront_deposit:
                                                inputs.upfront_deposit,
                                            remote_monitoring_capabilities:
                                                inputs.remote_monitoring_capabilities,
                                            payment_plan,
                                        },
                                    },
                                },
                            )
                            break
                        default:
                            history.push('/app/payment-plans/edit/two', {
                                isEstimationTwo: true,
                            })
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers['createPaymentPlanStepOnePartTwo']
                                    .action,
                                eventTrackers['createPaymentPlanStepOnePartTwo']
                                    .label,
                                eventTrackers['createPaymentPlanStepOnePartTwo']
                                    .category,
                                ['MP', 'GA'],
                                'event',
                                {
                                    event: {
                                        payment_plan_step_one_part_two_info: {
                                            financing_term:
                                                inputs.financing_term
                                                    .match(/\d/g)
                                                    .join(''),
                                            system_ease_of_recovery:
                                                inputs.system_ease_of_recovery,
                                            upfront_deposit:
                                                inputs.upfront_deposit,
                                            remote_monitoring_capabilities:
                                                inputs.remote_monitoring_capabilities,
                                            payment_plan,
                                        },
                                    },
                                },
                            )
                    }
                }
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                    setErrors(data?.response?.data?.errors)
                } else {
                    setErrorMessage([
                        'Failed to create payment plan. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )
    //Patch req. for step one part two
    const handleSavePackage2 = () => {
        setToastError(false)
        setErrors({})
        setIsCheckedTwo(
            isCheckedTwo !== 'les' && isCheckedTwo !== 'sub'
                ? 'none'
                : isCheckedTwo,
        )

        EstimationStepTwoValidationSchema.validate(
            { ...inputs, appliances: energyNeeds[0]?.name },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                if (
                    inputs.selectPackageFrom === selectPackageFromOptions[0] &&
                    inputs?.upfront_deposit <
                        parseInt(upfrontDeposit?.upfront_deposit)
                ) {
                    setErrors(prev => ({
                        ...prev,
                        upfront_deposit: `The lowest upfront deposit is ${parseInt(
                            upfrontDeposit?.upfront_deposit,
                        )}%, enter ${parseInt(
                            upfrontDeposit?.upfront_deposit,
                        )} or more`,
                    }))
                    return
                } else if (
                    inputs.selectPackageFrom === selectPackageFromOptions[0] &&
                    inputs?.upfront_deposit > 100
                ) {
                    setErrors(prev => ({
                        ...prev,
                        upfront_deposit:
                            'Upfront deposit should not be greater than 100%',
                    }))
                    return
                }

                setErrors('')

                let hasSelectedSolution =
                    estimationData?.recommended_solution?.id
                let hasClusterCode = estimationData?.cluster_code

                // if selected solution and there's no cluster code it directs to the product library, if there's a clusterCode we call the packages associated with cluster code with the cluster code, if there's no selected solution display modal

                if (hasSelectedSolution) {
                    if (hasClusterCode) {
                        createEstimationsStepOne2Refetch()
                        history.push({
                            pathname: '/app/payment-plans/create/two',
                            state: {
                                clusterCode: estimationData?.cluster_code,
                            },
                        })
                    } else {
                        createEstimationsStepOne2Refetch()
                    }
                } else {
                    if (
                        inputs.selectPackageFrom === selectPackageFromOptions[0]
                    ) {
                        setLibrary('product')
                    } else if (
                        inputs.selectPackageFrom === selectPackageFromOptions[1]
                    ) {
                        setLibrary('cluster')
                    }
                    createEstimationsStepOne2Refetch()
                }
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    // get product library
    const handleProductPackages = () => {
        createEstimationsStepOne2Refetch()
    }

    //function to get value of dragger file name on re-estimating credit check prooft
    function getValueOfUrlAfterLastSlash(name) {
        let splitUrl = name.split('/', -1)
        let fileName = splitUrl[splitUrl.length - 1]
        return fileName
    }
    //fetch one estimation by Id to restimate an existing estimation
    const { refetch: fetchEstimation } = useQuery(
        ['fetchEstimation', estimationId],
        () => getEstimationById(estimationId),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                const estimationData = data?.data?.data
                setEstimationData(estimationData)
                setInputs(preState => ({
                    ...preState,
                    customer_acquisition: estimationData.customer_acquisition,
                    repayment_method: toTitleCase(
                        estimationData.repayment_method?.replace(/_/g, ' '),
                    ),
                    upfront_deposit: estimationData.upfront_deposit_percentage,
                    financing_term: estimationData.financing_term
                        ? `${estimationData.financing_term} months`
                        : '',
                    system_ease_of_recovery:
                        estimationData.system_ease_of_recovery || '',
                    remote_monitoring_capabilities:
                        estimationData.remote_monitoring_capabilities || '',
                    genSize:
                        estimationData?.installation_details
                            ?.customer_generator_size || '',
                    installationSiteReady:
                        installationDetailsDataMapping[
                            estimationData?.installation_details?.is_site_ready
                        ] || '',
                    installationAwareness:
                        installationDetailsDataMapping[
                            estimationData?.installation_details
                                ?.customer_installation_awareness
                        ] || '',
                    roofAssessment:
                        installationDetailsDataMapping[
                            estimationData?.installation_details
                                ?.roof_assessment
                        ] || '',
                    projectComments:
                        estimationData?.installation_details
                            ?.additional_comment || '',
                    selectPackageFrom:
                        estimationData?.meta_data?.package_selected_from || '',
                }))
                setRanCreditCheck(estimationData.ran_credit_check)
                estimationData?.appliances?.length > 0 &&
                    setEnergyNeeds(estimationData?.appliances)
                let ran_credit_check1 = ''
                if (estimationData.ran_credit_check === true) {
                    ran_credit_check1 = 'yes'
                } else if (estimationData.ran_credit_check === false) {
                    ran_credit_check1 = 'no'
                }
                setIsChecked(ran_credit_check1)
                setPaymentPlanType(estimationData.payment_plan)
                let payment_plan1 = ''
                if (estimationData.payment_plan === 'Subscription') {
                    payment_plan1 = 'sub'
                } else if (estimationData.payment_plan === 'Lease to Own') {
                    payment_plan1 = 'les'
                }
                setIsCheckedTwo(payment_plan1)
                let upfront_deposit = ''
                if (estimationData.payment_plan === 'Subscription') {
                    upfront_deposit = creditVariables.find(
                        item => item?.payment_model === 'SUBSCRIPTION',
                    )
                } else if (estimationData.payment_plan === 'Lease to Own') {
                    upfront_deposit = creditVariables.find(
                        item => item?.payment_model === 'LEASE_TO_OWN',
                    )
                }
                setUpfrontDeposit(upfront_deposit)
                setCreditCheckProof(
                    estimationData.credit_check_proof !== null
                        ? getValueOfUrlAfterLastSlash(
                              estimationData.credit_check_proof,
                          )
                        : '',
                )
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch payment plan. Refresh page')
            },
        },
    )

    useEffect(() => {
        estimationId && fetchEstimation()
    }, [estimationId, fetchEstimation])

    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
    }

    const prefilledFirstName = estimationData.consumer?.user.first_name
    const prefilledLastName = estimationData.consumer?.user.last_name
    const prefilledEmail = estimationData.consumer?.user.email
    const prefilledPhoneNumber = parsePhoneNumber(
        estimationData.consumer?.user?.phone_number || '',
    )
    const prefilledBusinessName = estimationData?.consumer?.business?.name
    const prefilledBusinessAddress = estimationData?.consumer?.business?.address
    const prefilledBusinessCity = estimationData?.consumer?.business?.city
    const prefilledBusinessState = estimationData?.consumer?.business?.state

    const yes =
        isChecked === 'none'
            ? 'red'
            : isChecked === 'yes'
            ? '#004aad'
            : '#e5e5e5'

    const no =
        isChecked === 'none'
            ? 'red'
            : isChecked === 'no'
            ? '#004aad'
            : '#e5e5e5'

    const sub =
        isCheckedTwo === 'none'
            ? 'red'
            : isChecked === 'sub'
            ? '#004aad'
            : '#e5e5e5'

    const les =
        isCheckedTwo === 'none'
            ? 'red'
            : isChecked === 'les'
            ? '#004aad'
            : '#e5e5e5'

    const truncateSystemEaseOfRecovery =
        inputs.system_ease_of_recovery &&
        inputs.system_ease_of_recovery.length > 40
            ? inputs.system_ease_of_recovery.substring(0, 40) + '...'
            : inputs.system_ease_of_recovery

    const truncateRemoteMonitoringCapabilities =
        inputs.remote_monitoring_capabilities &&
        inputs.remote_monitoring_capabilities.length > 40
            ? inputs.remote_monitoring_capabilities.substring(0, 40) + '...'
            : inputs.remote_monitoring_capabilities
    return (
        <>
            <SeoComponent
                title={`SunFi - Create Payment Plan | Step One - ${
                    !showForm ? 'Part One' : 'Part Two'
                }`}
                tracker="CreatePaymentplanStepOne"
            />
            <ActionModal
                actionModalOpen={restrictMultipleApprovedPaymentPlansModal}
                headerText="Existing Payment Plan"
                subTitle="You can't create this payment plan because, the consumer has an existing payment plan under awaiting approval. Click go back to display the payment plan"
                actionType="delete"
                actionText="Go Back"
                noCancelBtn
                actionHandler={() => history.push('/app/payment-plans')}
                closeModal={() =>
                    setRestrictMultipleApprovedPaymentPlansModal(false)
                }
            />
            <SecondaryModal
                type="recommendedEnergy"
                modalWidth="624px"
                modalHeight="690px"
                showModal={showModal}
                closable={false}
                content={
                    <UpdateNeeds
                        closeModal={() => setShowModal(false)}
                        updateEnergyNeeds={updateEnergyNeeds}
                        selectedAppliances={energyNeeds}
                        noPadding
                        page="estimation"
                    />
                }
                onCancel={() => setShowModal(false)}
            />

            <SecondaryModal
                showModal={showSelectLibraryModal}
                modalHeight="487px"
                modalWidth="459px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => setShowSelectLibraryModal(false)}
                content={
                    <SelectLibrary
                        library={library}
                        setLibrary={setLibrary}
                        handleProductPackages={handleProductPackages}
                        loading={createEstimationsStepOne2Loading}
                    />
                }
            />
            <div className="Estimation_stepone-container">
                <button
                    className="PrevButton"
                    onClick={() => {
                        newCustomerType !== undefined
                            ? history.push({
                                  pathname:
                                      customerData !== undefined
                                          ? '/app/payment-plans/create'
                                          : '/app/payment-plans',
                                  state: {
                                      customerType:
                                          customerData !== undefined
                                              ? 'existing'
                                              : undefined,
                                  },
                              })
                            : history.push('/app/payment-plans')
                    }}
                >
                    <img src={LeftArrow} alt="left-arrow" />
                    {location?.state?.params === undefined
                        ? newCustomerType !== undefined
                            ? customerData === undefined
                                ? 'Back to Payment Plans'
                                : 'Back to Estimation'
                            : 'Back to Payment Plans'
                        : 'Back to Payment Plans'}
                </button>
                <div style={{ display: 'flex' }}>
                    <h1 className="EstimationStepOneH1">
                        Create a Single Payment Plan
                    </h1>
                </div>
                {toastError && (
                    <Toast
                        messageType="error"
                        message={
                            errorMessage?.[0]?.['message']
                                ? errorMessage?.[0]?.['message']
                                : ''
                        }
                    />
                )}
                <div className="EstimationStepOneContainer">
                    <div className="StepsContainer">
                        <div className="StepsChildContainer">
                            <button className="StepsButton ActiveStepsButton">
                                1
                            </button>
                            <p className="StepsText">Customer Information</p>
                        </div>
                        <div className="StepsLine"></div>
                        <div className="StepsChildContainer">
                            <button className="StepsButton PointerStyle">
                                2
                            </button>
                            <p className="StepsText">Energy Solution</p>
                        </div>
                        <div className="StepsLine"></div>
                        <div className="StepsChildContainer">
                            <button className="StepsButton PointerStyle">
                                3
                            </button>
                            <p className="StepsText">Payment Plans</p>
                        </div>
                    </div>
                    <div className="EstimationContainer">
                        <div className="EstimationFormLayout">
                            {showForm && (
                                <div style={{ marginBottom: 47 }}>
                                    <h2 className="EstimationStepOneH2">
                                        Energy Needs
                                    </h2>
                                    <p className="EstimationStepOneSubTitle">
                                        Consumer’s appliance information
                                    </p>

                                    <div className="SummaryWrapper">
                                        <section className="SectionOneWrapper">
                                            <p
                                                className="
                                                        ConsumerEnergyNeedsStyle
                                                    "
                                            >
                                                CONSUMER ENERGY NEEDS
                                            </p>
                                            <p
                                                className="
                                                        ApplianceAddedStyle
                                                    "
                                            >
                                                <span
                                                    className="
                                                            ApplianceSpanStyle
                                                        "
                                                >
                                                    {energyNeeds?.length}
                                                </span>
                                                Appliance(s) added
                                            </p>
                                        </section>
                                        <section className="SectionTwoWrapper">
                                            <div className="TotalAddedStyle">
                                                <p>
                                                    Total watts added <br />{' '}
                                                    <span
                                                        className="
                                                                SectionTwoSpanStyle
                                                            "
                                                    >
                                                        {numberWithCommas(
                                                            parseFloat(
                                                                totalWattsAdded,
                                                                10,
                                                            ).toFixed(2),
                                                        )}
                                                    </span>
                                                    <sup>watts</sup>
                                                </p>
                                            </div>
                                            <div>
                                                <p
                                                    className="
                                                            TotalUsageStyle
                                                        "
                                                >
                                                    <img
                                                        src={SunIcon}
                                                        alt="a sunlight icon"
                                                    />
                                                    <span
                                                        className="
                                                                TotalUsageSpanOne
                                                            "
                                                    >
                                                        Total day usage
                                                    </span>
                                                    <span
                                                        className="
                                                                TotalUsageSpan
                                                            "
                                                    >
                                                        {numberWithCommas(
                                                            parseFloat(
                                                                totalDayUsage,
                                                                10,
                                                            ).toFixed(2),
                                                        )}{' '}
                                                        wh
                                                    </span>
                                                </p>
                                                <p
                                                    className="
                                                            TotalUsageStyle
                                                        "
                                                >
                                                    <img
                                                        src={HalfMoonIcon}
                                                        alt="a half moon icon"
                                                    />
                                                    <span
                                                        className="
                                                                TotalUsageSpanOne
                                                            "
                                                    >
                                                        Total night usage
                                                    </span>
                                                    <span
                                                        className="
                                                                TotalUsageSpan
                                                            "
                                                    >
                                                        {numberWithCommas(
                                                            parseFloat(
                                                                totalNightUsage,
                                                                10,
                                                            ).toFixed(2),
                                                        )}{' '}
                                                        wh
                                                    </span>
                                                </p>
                                            </div>
                                        </section>
                                        <hr className="HRStyle" />
                                        <section className="SectionThreeWrapper">
                                            <Button
                                                btnBgColor="#E2EEFF"
                                                btnTextColor="var(--blue)"
                                                btnWidth="170px"
                                                btnHeight="44px"
                                                handleClick={() =>
                                                    setShowModal(true)
                                                }
                                            >
                                                {energyNeeds?.length &&
                                                energyNeeds[0]?.name?.length < 1
                                                    ? 'Add'
                                                    : 'See'}{' '}
                                                Appliances
                                            </Button>
                                            <div
                                                className="
                                                        AverageUsageStyle
                                                    "
                                            >
                                                <p
                                                    className="
                                                            AverageUsageSpanOne
                                                        "
                                                >
                                                    Daily Energy Consumption
                                                </p>
                                                <span
                                                    className="
                                                            AverageUsageSpanTwo
                                                        "
                                                >
                                                    {numberWithCommas(
                                                        parseFloat(
                                                            averageDailyUsage,
                                                            10,
                                                        ).toFixed(2),
                                                    )}{' '}
                                                    wh
                                                </span>
                                            </div>
                                        </section>
                                    </div>
                                    {errors?.appliances?.length &&
                                        averageDailyUsage < 1 && (
                                            <p className="ApplianceErrorText">
                                                {errors?.appliances}
                                            </p>
                                        )}
                                </div>
                            )}
                            {customerData === undefined && (
                                <>
                                    <h2 className="EstimationStepOneH2">
                                        {!showForm
                                            ? newCustomerType === 'business'
                                                ? 'Business Information'
                                                : 'Consumer’s Information'
                                            : showForm
                                            ? 'Estimation Info'
                                            : 'Payment Plan Info'}
                                    </h2>
                                    <p className="EstimationStepOneSubTitle">
                                        {!showForm
                                            ? newCustomerType === 'business'
                                                ? 'Enter business name as it appears on CAC document'
                                                : 'Enter the customer’s name as it appears on their ID'
                                            : 'Select customer’s energy estimation'}
                                    </p>
                                </>
                            )}
                            <div style={{ marginTop: '24px' }}>
                                {!showForm && (
                                    <div>
                                        {customerData === undefined ? (
                                            <>
                                                {newCustomerType ===
                                                    'business' && (
                                                    <>
                                                        <div className="InputsContainer">
                                                            <InputFields
                                                                type="text"
                                                                inputWidth="317px"
                                                                name="businessName"
                                                                title="Business Name"
                                                                value={
                                                                    prefilledBusinessName ||
                                                                    inputs?.businessName
                                                                }
                                                                prefilled={
                                                                    prefilledBusinessName
                                                                }
                                                                handleChange={
                                                                    handleInputChange
                                                                }
                                                                onBlur={
                                                                    handleOnBlur
                                                                }
                                                                errorMessage={
                                                                    errors?.businessName ||
                                                                    errorMessage?.[0]?.[
                                                                        'business_name'
                                                                    ]
                                                                }
                                                            />

                                                            <InputFields
                                                                type="text"
                                                                inputWidth="317px"
                                                                name="businessAddress"
                                                                title="Business Address"
                                                                value={
                                                                    prefilledBusinessAddress ||
                                                                    inputs.businessAddress
                                                                }
                                                                prefilled={
                                                                    prefilledBusinessAddress
                                                                }
                                                                handleChange={
                                                                    handleInputChange
                                                                }
                                                                onBlur={
                                                                    handleOnBlur
                                                                }
                                                                errorMessage={
                                                                    errors?.businessAddress ||
                                                                    errorMessage?.[0]?.[
                                                                        'business_address'
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                        <div className="InputsContainer">
                                                            <InputFields
                                                                type="text"
                                                                inputWidth="317px"
                                                                name="businessCity"
                                                                title="City"
                                                                value={
                                                                    prefilledBusinessCity ||
                                                                    inputs?.businessCity
                                                                }
                                                                prefilled={
                                                                    prefilledBusinessCity
                                                                }
                                                                handleChange={
                                                                    handleInputChange
                                                                }
                                                                onBlur={
                                                                    handleOnBlur
                                                                }
                                                                errorMessage={
                                                                    errors?.businessCity ||
                                                                    errorMessage?.[0]?.[
                                                                        'city'
                                                                    ]
                                                                }
                                                            />
                                                            <SelectField
                                                                selectWidth="317px"
                                                                initialOption={
                                                                    prefilledBusinessState ||
                                                                    inputs.businessState ||
                                                                    'State'
                                                                }
                                                                name="businessState"
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                currentSelected={
                                                                    inputs.businessState
                                                                }
                                                                handleChange={
                                                                    handleSelectChange
                                                                }
                                                                errorMessage={
                                                                    errors?.businessState ||
                                                                    errorMessage?.[0]?.[
                                                                        'state'
                                                                    ]
                                                                }
                                                                values={stateData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                dropdownPositionRelative
                                                                showSearch
                                                                prefilled={
                                                                    prefilledBusinessState
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {newCustomerType ===
                                                    'business' && (
                                                    <>
                                                        <h2 className="EstimationStepOneH2">
                                                            Business Contact
                                                        </h2>
                                                        <p className="EstimationStepOneSubTitle">
                                                            Enter business
                                                            contact details
                                                        </p>
                                                    </>
                                                )}
                                                <div className="InputsContainer">
                                                    <InputFields
                                                        type="text"
                                                        inputWidth="317px"
                                                        name="first_name"
                                                        title={
                                                            newCustomerType ===
                                                            'business'
                                                                ? 'Contact’s First Name'
                                                                : 'First Name'
                                                        }
                                                        value={
                                                            prefilledFirstName ||
                                                            inputs?.first_name
                                                        }
                                                        prefilled={
                                                            prefilledFirstName
                                                        }
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        onBlur={handleOnBlur}
                                                        errorMessage={
                                                            errors?.first_name ||
                                                            errorMessage?.[0]?.[
                                                                'first_name'
                                                            ]
                                                        }
                                                        verified={
                                                            isExistingUser &&
                                                            customerInfo?.firstName ===
                                                                inputs?.first_name
                                                        }
                                                    />
                                                    <InputFields
                                                        type="text"
                                                        inputWidth="317px"
                                                        name="last_name"
                                                        title={
                                                            newCustomerType ===
                                                            'business'
                                                                ? 'Contact’s Surname/Last Name'
                                                                : 'Surname/Last Name'
                                                        }
                                                        value={
                                                            prefilledLastName ||
                                                            inputs.last_name
                                                        }
                                                        prefilled={
                                                            prefilledLastName
                                                        }
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        onBlur={handleOnBlur}
                                                        errorMessage={
                                                            errors?.last_name ||
                                                            errorMessage?.[0]?.[
                                                                'last_name'
                                                            ]
                                                        }
                                                        verified={
                                                            isExistingUser &&
                                                            customerInfo?.lastName ===
                                                                inputs?.last_name
                                                        }
                                                    />
                                                </div>
                                                <div className="InputsContainer">
                                                    <SelectCountryCode
                                                        title="Phone Number"
                                                        name="phone_number"
                                                        inputValue={
                                                            prefilledPhoneNumber?.nationalNumber ||
                                                            phoneNumber
                                                        }
                                                        defaultValue={
                                                            prefilledPhoneNumber?.country
                                                        }
                                                        prefilled={
                                                            prefilledPhoneNumber?.nationalNumber
                                                        }
                                                        disabled={
                                                            prefilledPhoneNumber?.country ||
                                                            (isExistingUser &&
                                                                !!customerInfo?.phoneNumber)
                                                        }
                                                        verified={
                                                            isExistingUser &&
                                                            !!customerInfo?.phoneNumber
                                                        }
                                                        inputWidth="316px"
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        onBlur={handleOnBlur}
                                                        setCountryCode={
                                                            setCountryCode
                                                        }
                                                        errorMessage={
                                                            errors?.phone_number ||
                                                            errorMessage?.[0]?.[
                                                                'phone_number'
                                                            ]
                                                        }
                                                        countryCodeDropdownClassName="countryCodeDropdown"
                                                        inputStyle={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                    <InputFields
                                                        type="text"
                                                        inputWidth="317px"
                                                        name="email"
                                                        title="Email Address"
                                                        value={
                                                            prefilledEmail ||
                                                            inputs.email
                                                        }
                                                        prefilled={
                                                            prefilledEmail
                                                        }
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        onBlur={handleOnBlur}
                                                        errorMessage={
                                                            errors?.email ||
                                                            errorMessage?.[0]?.[
                                                                'email'
                                                            ]
                                                        }
                                                        disabled={
                                                            isExistingUser &&
                                                            !!customerInfo?.email
                                                        }
                                                        verified={
                                                            isExistingUser &&
                                                            !!customerInfo?.email
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : isMobile ? (
                                            <div className="MobileSectionWrapper">
                                                <div className="MobileTopSection">
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                '45px',
                                                        }}
                                                    >
                                                        <h4 className="MobileExistingTableHeader">
                                                            First/Last Name
                                                        </h4>
                                                        <p
                                                            className="MobileExistingText"
                                                            style={{
                                                                fontFamily:
                                                                    'SF-Pro-Display-Semibold',
                                                            }}
                                                        >
                                                            {
                                                                customerData.customerName
                                                            }
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                '45px',
                                                        }}
                                                    >
                                                        <h4 className="MobileExistingTableHeader Right">
                                                            Customer type
                                                        </h4>
                                                        <p className="MobileExistingText Right">
                                                            {titleCase(
                                                                newCustomerType ===
                                                                    'individual'
                                                                    ? 'Residential'
                                                                    : newCustomerType,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="MobileTopSection">
                                                    <div>
                                                        <h4 className="MobileExistingTableHeader">
                                                            Email address
                                                        </h4>
                                                        <p className="MobileExistingText">
                                                            {customerData.email}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h4 className="MobileExistingTableHeader Right">
                                                            Phone Number
                                                        </h4>
                                                        <p className="MobileExistingText Right">
                                                            {
                                                                customerData.phoneNumber
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <section className="ExistingCustomersWrapper">
                                                <div>
                                                    <p className="ExistingCustomersHeader">
                                                        Customer Name
                                                    </p>
                                                    <p className="ExistingCustomersSubheader">
                                                        {
                                                            customerData.customerName
                                                        }
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className="ExistingCustomersHeader">
                                                        Phone Number
                                                    </p>
                                                    <p className="ExistingCustomersSubheader">
                                                        {
                                                            customerData.phoneNumber
                                                        }
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className="ExistingCustomersHeader">
                                                        Email Address
                                                    </p>
                                                    <p className="ExistingCustomersSubheader">
                                                        {customerData.email}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="ExistingCustomersHeader">
                                                        Customer Type
                                                    </p>
                                                    <p className="ExistingCustomersSubheader">
                                                        {titleCase(
                                                            newCustomerType ===
                                                                'individual'
                                                                ? 'Residential'
                                                                : newCustomerType,
                                                        )}
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                        <div
                                            style={{
                                                marginTop: '15px',
                                            }}
                                        >
                                            <h2 className="EstimationStepOneH2">
                                                Customer's Credit Information
                                            </h2>
                                            <p className="EstimationStepOneSubTitle">
                                                Select the customer's billing
                                                details
                                            </p>
                                        </div>

                                        <div className="InputsContainer">
                                            <div>
                                                <SelectField
                                                    selectWidth="317px"
                                                    initialOption={
                                                        inputs.customer_acquisition ||
                                                        'Customer Aquisition'
                                                    }
                                                    floatingLabel="Customer Aquisition"
                                                    floatLabelOnSelectedValue
                                                    withCheckBox={true}
                                                    name="customer_acquisition"
                                                    currentSelected={
                                                        inputs.customer_acquisition
                                                    }
                                                    value={
                                                        inputs.customer_acquisition
                                                    }
                                                    handleChange={
                                                        handleSelectChange
                                                    }
                                                    errorMessage={
                                                        errors.customer_acquisition ||
                                                        errorMessage?.[0]?.[
                                                            'customer_acquisition'
                                                        ]
                                                    }
                                                    values={CustomerAcquisitionData.map(
                                                        option => ({
                                                            value: option,
                                                        }),
                                                    )}
                                                    dropdownPositionRelative
                                                />
                                            </div>
                                            <div>
                                                <SelectField
                                                    selectWidth="317px"
                                                    initialOption={
                                                        inputs.repayment_method ||
                                                        'Payment Method of Repayment'
                                                    }
                                                    floatingLabel="Payment Method of Repayment"
                                                    floatLabelOnSelectedValue
                                                    name="repayment_method"
                                                    withCheckBox={true}
                                                    currentSelected={
                                                        inputs.repayment_method
                                                    }
                                                    value={
                                                        inputs.repayment_method
                                                    }
                                                    handleChange={
                                                        handleSelectChange
                                                    }
                                                    errorMessage={
                                                        errors?.repayment_method ||
                                                        errorMessage?.[0]?.[
                                                            'upfront_deposit'
                                                        ]
                                                    }
                                                    values={paymentMethodData.map(
                                                        option => ({
                                                            value: option,
                                                        }),
                                                    )}
                                                    dropdownPositionRelative
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: '0px',
                                            }}
                                        >
                                            <h2 className="EstimationStepOneH2">
                                                Have You Done a Credit Check on
                                                Customer?
                                            </h2>
                                        </div>
                                        <div
                                            className={`InputsContainer ${
                                                ran_credit_check &&
                                                'DragNDropContainer_Responsiveness'
                                            }`}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <button
                                                    className="RadioContainer"
                                                    style={{
                                                        border: `0.7px solid ${yes}`,
                                                        fontWeight:
                                                            isChecked === 'yes'
                                                                ? 600
                                                                : 400,
                                                        marginRight: '3.5px',
                                                    }}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setIsChecked('yes')
                                                        setRanCreditCheck(true)
                                                    }}
                                                >
                                                    <input
                                                        value={true}
                                                        onClick
                                                        name="credit_check_proof"
                                                        defaultChecked={
                                                            isChecked === 'yes'
                                                        }
                                                        type="radio"
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                    <img
                                                        src={
                                                            isChecked === 'yes'
                                                                ? RadioSelected
                                                                : RadioUnselected
                                                        }
                                                        alt="radio"
                                                    />
                                                    Yes, I have
                                                </button>
                                                <button
                                                    className="RadioContainer"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setIsChecked('no')
                                                        setRanCreditCheck(false)
                                                        successUpload.length = 0
                                                    }}
                                                    style={{
                                                        border: `0.7px solid ${no}`,
                                                        fontWeight:
                                                            isChecked === 'no'
                                                                ? 600
                                                                : 400,
                                                        marginLeft: '3.5px',
                                                    }}
                                                >
                                                    <input
                                                        value={false}
                                                        name="credit_check_proof"
                                                        defaultChecked={
                                                            isChecked === 'no'
                                                        }
                                                        type="radio"
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                    <img
                                                        src={
                                                            isChecked === 'no'
                                                                ? RadioSelected
                                                                : RadioUnselected
                                                        }
                                                        alt="radio"
                                                    />
                                                    No, I have not
                                                </button>
                                            </div>
                                            {isChecked === 'yes' && (
                                                <>
                                                    <div className="DragDropContainer DragDrop_mobile-responsiveness">
                                                        <Dragger
                                                            className={
                                                                errorMessage.length >
                                                                    0 &&
                                                                successUpload.length <
                                                                    1
                                                                    ? 'DraggerError'
                                                                    : 'DraggerArea'
                                                            }
                                                            fileList={
                                                                successUpload.length >
                                                                0
                                                                    ? successUpload
                                                                    : [
                                                                          {
                                                                              name: creditCheckProof,
                                                                          },
                                                                      ]
                                                            }
                                                            maxCount={1}
                                                            beforeUpload={() =>
                                                                false
                                                            }
                                                            showUploadList={
                                                                true
                                                            }
                                                            accept=".pdf,.docx,.doc"
                                                            customRequest={() => {}}
                                                            {...props}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                {uploading ? (
                                                                    <div className="SlideContainer">
                                                                        <p
                                                                            className="Slider"
                                                                            style={{
                                                                                width: `${width}%`,
                                                                            }}
                                                                        ></p>
                                                                    </div>
                                                                ) : successUpload.length >
                                                                      0 ||
                                                                  creditCheckProof?.length >
                                                                      0 ? (
                                                                    <img
                                                                        src={
                                                                            SuccessUpload
                                                                        }
                                                                        className="UploadLogo"
                                                                        alt="success logo"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            UploadLogo
                                                                        }
                                                                        className="UploadLogo"
                                                                        alt="upload logo"
                                                                    />
                                                                )}
                                                            </p>
                                                            <p className="UploadText">
                                                                {uploading ? (
                                                                    <div>
                                                                        Uploading
                                                                        document...
                                                                    </div>
                                                                ) : successUpload.length >
                                                                      0 ||
                                                                  creditCheckProof?.length >
                                                                      0 ? (
                                                                    <>
                                                                        <p className="Upload">
                                                                            <u
                                                                                className="Browse"
                                                                                style={{
                                                                                    marginTop:
                                                                                        '20px',
                                                                                }}
                                                                            >
                                                                                Replace
                                                                                document
                                                                            </u>
                                                                        </p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>
                                                                            Upload
                                                                            file.
                                                                            Drag
                                                                            and
                                                                            drop
                                                                            or
                                                                            &nbsp;
                                                                            <u
                                                                                className="Browse"
                                                                                style={{
                                                                                    lineHeight:
                                                                                        '26px',
                                                                                }}
                                                                            >
                                                                                browse
                                                                            </u>
                                                                        </p>
                                                                        <p
                                                                            style={{
                                                                                fontSize:
                                                                                    '13px',
                                                                            }}
                                                                        >
                                                                            Supported
                                                                            format
                                                                            :
                                                                            pdf
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </Dragger>
                                                        {errorMessage.length >
                                                            0 &&
                                                            successUpload.length <
                                                                1 && (
                                                                <p className="UploadError">
                                                                    Please
                                                                    upload a
                                                                    File
                                                                </p>
                                                            )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {isChecked === 'none' ? (
                                            <p className="CreditCheckErrorText">
                                                Please select an option
                                            </p>
                                        ) : (
                                            ''
                                        )}

                                        <div className="Estimation_stepone-SaveBtn">
                                            {customerData !== undefined && (
                                                <>
                                                    <AppButton
                                                        btnBgColor="#E2EEFF"
                                                        btnTextColor="#004AAD"
                                                        btnWidth={132}
                                                        btnHeight={50}
                                                        fontFamily="SF-Pro-Display-Semibold"
                                                        fontSize="12px"
                                                        handleClick={() =>
                                                            history.goBack()
                                                        }
                                                    >
                                                        Back
                                                    </AppButton>
                                                    &nbsp; &nbsp; &nbsp;
                                                </>
                                            )}

                                            <button
                                                className="EstimationStepOneBtnPrimary"
                                                disabled={
                                                    createEstimationsStepOneLoading
                                                }
                                                onClick={e => {
                                                    e.preventDefault()
                                                    prefilledFirstName?.length !==
                                                    undefined
                                                        ? handleRestimation()
                                                        : handleSavePackage()
                                                }}
                                            >
                                                {createEstimationsStepOneLoading ||
                                                restimationsStepOneLoading ? (
                                                    <InlineLoader />
                                                ) : (
                                                    'Continue'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {showForm && (
                                    <div>
                                        <>
                                            <div className="InputsContainer">
                                                <div>
                                                    <SelectField
                                                        selectWidth="317px"
                                                        initialOption={
                                                            inputs.financing_term ||
                                                            'Tenure of Payment'
                                                        }
                                                        floatingLabel={
                                                            'Tenure of Payment'
                                                        }
                                                        floatLabelOnSelectedValue
                                                        withCheckBox={true}
                                                        name="financing_term"
                                                        currentSelected={
                                                            inputs.financing_term
                                                        }
                                                        value={
                                                            inputs.financing_term
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        errorMessage={
                                                            errors?.financing_term
                                                        }
                                                        values={tenurePaymentData.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        showSearch
                                                    />
                                                </div>
                                                <div>
                                                    <SelectField
                                                        selectWidth="317px"
                                                        initialOption={
                                                            inputs.system_ease_of_recovery ||
                                                            'Select System’s Ease of Recovery'
                                                        }
                                                        floatingLabel={
                                                            'Select System’s Ease of Recovery'
                                                        }
                                                        floatLabelOnSelectedValue
                                                        name="system_ease_of_recovery"
                                                        withCheckBox={true}
                                                        currentSelected={
                                                            inputs.system_ease_of_recovery
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        errorMessage={
                                                            errors?.system_ease_of_recovery
                                                        }
                                                        value={
                                                            truncateSystemEaseOfRecovery
                                                        }
                                                        values={systemsRecoveryData.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        showSearch
                                                    />
                                                </div>
                                            </div>
                                            <div className="InputsContainer">
                                                <div>
                                                    <SelectField
                                                        selectWidth="317px"
                                                        initialOption={
                                                            inputs.remote_monitoring_capabilities ||
                                                            'Select Remote Monitoring Capabilities'
                                                        }
                                                        floatingLabel={
                                                            'Select Remote Monitoring Capabilities'
                                                        }
                                                        floatLabelOnSelectedValue
                                                        name="remote_monitoring_capabilities"
                                                        withCheckBox={true}
                                                        currentSelected={
                                                            inputs.remote_monitoring_capabilities
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        errorMessage={
                                                            errors?.remote_monitoring_capabilities
                                                        }
                                                        value={
                                                            truncateRemoteMonitoringCapabilities
                                                        }
                                                        values={remoteMonitoringData.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        showSearch
                                                    />
                                                </div>
                                            </div>
                                            {process.env
                                                .REACT_APP_FLAG_SHOW_INSTALLATION_DETAILS ===
                                                'true' && (
                                                <>
                                                    <h2
                                                        className="EstimationStepOneH2"
                                                        style={{
                                                            margin: '34px 0 10px',
                                                        }}
                                                    >
                                                        Installation details
                                                    </h2>
                                                    <p
                                                        className="EstimationStepOneSubTitle"
                                                        style={{
                                                            marginBottom:
                                                                '24px',
                                                        }}
                                                    >
                                                        Select customer's
                                                        installation details
                                                    </p>

                                                    <div className="InputsContainer">
                                                        <div className="InstallationIconWrapper">
                                                            <SelectField
                                                                selectWidth="317px"
                                                                initialOption={
                                                                    inputs.genSize ||
                                                                    'Customer Generator Size'
                                                                }
                                                                floatingLabel={
                                                                    'Customer Generator Size'
                                                                }
                                                                floatLabelOnSelectedValue
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                name="genSize"
                                                                currentSelected={
                                                                    inputs.genSize
                                                                }
                                                                value={
                                                                    inputs.genSize
                                                                }
                                                                handleChange={
                                                                    handleSelectChange
                                                                }
                                                                errorMessage={
                                                                    errors?.genSize
                                                                }
                                                                values={generatorSizeData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                dropdownPositionRelative
                                                            />
                                                            <CustomToolTip
                                                                text="Whats the customers generator size, this is not a compulsory field"
                                                                toolTipFontSize="12px"
                                                                toolTipMarginLeft="-149px"
                                                                toolTipPopupWidth="174px"
                                                                top="50%"
                                                            >
                                                                <HelpIcon className="InstallationHelpIconStyle" />
                                                            </CustomToolTip>
                                                        </div>
                                                        <div className="InstallationIconWrapper">
                                                            <SelectField
                                                                selectWidth="317px"
                                                                initialOption={
                                                                    inputs.installationSiteReady ||
                                                                    'Is the site ready for installation'
                                                                }
                                                                floatingLabel={
                                                                    'Is the site ready for installation'
                                                                }
                                                                floatLabelOnSelectedValue
                                                                name="installationSiteReady"
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                currentSelected={
                                                                    inputs.installationSiteReady
                                                                }
                                                                value={
                                                                    inputs.installationSiteReady
                                                                }
                                                                handleChange={
                                                                    handleSelectChange
                                                                }
                                                                errorMessage={
                                                                    errors?.installationSiteReady
                                                                }
                                                                values={installationDetailsData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                dropdownPositionRelative
                                                            />
                                                            <CustomToolTip
                                                                text="Is the site ready for installation; Permission to mount on the roof obtained, building not under renovation, etc"
                                                                toolTipFontSize="12px"
                                                                toolTipMarginLeft="-149px"
                                                                toolTipPopupWidth="178px"
                                                                top="50%"
                                                            >
                                                                <HelpIcon className="InstallationHelpIconStyle" />
                                                            </CustomToolTip>
                                                        </div>
                                                    </div>
                                                    <div className="InputsContainer">
                                                        <div className="InstallationIconWrapper">
                                                            <SelectField
                                                                selectWidth="317px"
                                                                initialOption={
                                                                    inputs.roofAssessment ||
                                                                    'Will roof assessment be performed'
                                                                }
                                                                floatingLabel={
                                                                    'Will roof assessment be performed'
                                                                }
                                                                floatLabelOnSelectedValue
                                                                name="roofAssessment"
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                currentSelected={
                                                                    inputs.roofAssessment
                                                                }
                                                                value={
                                                                    inputs.roofAssessment
                                                                }
                                                                handleChange={
                                                                    handleSelectChange
                                                                }
                                                                errorMessage={
                                                                    errors?.roofAssessment
                                                                }
                                                                values={installationDetailsData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                dropdownPositionRelative
                                                            />
                                                            <CustomToolTip
                                                                text="Before the installation commences, confirm the roof will be assessed for solar panel mounting to ensure no issues?"
                                                                toolTipFontSize="12px"
                                                                toolTipMarginLeft="-149px"
                                                                toolTipPopupWidth="178px"
                                                                top="50%"
                                                            >
                                                                <HelpIcon className="InstallationHelpIconStyle" />
                                                            </CustomToolTip>
                                                        </div>
                                                        <div className="InstallationIconWrapper">
                                                            <SelectField
                                                                selectWidth="317px"
                                                                initialOption={
                                                                    inputs.installationAwareness ||
                                                                    'Customer installation awareness'
                                                                }
                                                                floatingLabel={
                                                                    'Customer installation awareness'
                                                                }
                                                                floatLabelOnSelectedValue
                                                                name="installationAwareness"
                                                                withCheckBox={
                                                                    true
                                                                }
                                                                currentSelected={
                                                                    inputs.installationAwareness
                                                                }
                                                                value={
                                                                    inputs.installationAwareness
                                                                }
                                                                handleChange={
                                                                    handleSelectChange
                                                                }
                                                                errorMessage={
                                                                    errors?.installationAwareness
                                                                }
                                                                values={installationDetailsData.map(
                                                                    option => ({
                                                                        value: option,
                                                                    }),
                                                                )}
                                                                dropdownPositionRelative
                                                            />
                                                            <CustomToolTip
                                                                text="Is the customer aware you are installing an energy solution and not rectifying existing electrical faults in the vicinity?"
                                                                toolTipFontSize="12px"
                                                                toolTipMarginLeft="-149px"
                                                                toolTipPopupWidth="178px"
                                                                top="50%"
                                                            >
                                                                <HelpIcon className="InstallationHelpIconStyle" />
                                                            </CustomToolTip>
                                                        </div>
                                                    </div>
                                                    <Editor
                                                        value={
                                                            inputs?.projectComments
                                                        }
                                                        name="projectComments"
                                                        placeholder="Add additional project comments"
                                                        onChangeHandler={
                                                            handleEditorChange
                                                        }
                                                        editorWidth="317px"
                                                        editorHeight="140px"
                                                        errorMessage={
                                                            errors?.projectComments
                                                        }
                                                    />
                                                </>
                                            )}
                                            <h2
                                                className="EstimationStepOneH2"
                                                style={{
                                                    margin: '34px 0 10px',
                                                }}
                                            >
                                                Payment Plan Type
                                            </h2>
                                            <p
                                                className="EstimationStepOneSubTitle"
                                                style={{ marginBottom: '24px' }}
                                            >
                                                Select customer's payment plan
                                            </p>
                                            <div className="InputsContainer no_height_input">
                                                <div
                                                    className="InputsContainer no_height_input"
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <CustomToolTip
                                                        text="A credit variable has not been created for you"
                                                        toolTipFontSize="12px"
                                                        toolTipMarginLeft="10px"
                                                        toolTipPopupWidth="174px"
                                                        showTooltip={
                                                            !creditVariableCheck?.hasLeaseToOwn
                                                        }
                                                        arrowPositionLeft
                                                    >
                                                        <button
                                                            className="RadioContainer"
                                                            style={{
                                                                border: `0.7px solid ${les}`,
                                                                fontWeight:
                                                                    isCheckedTwo ===
                                                                    'les'
                                                                        ? 600
                                                                        : 400,
                                                                marginRight:
                                                                    '3.5px',
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                setIsCheckedTwo(
                                                                    'les',
                                                                )
                                                                setPaymentPlanType(
                                                                    'Lease to Own',
                                                                )
                                                                handlePaymentPlanType(
                                                                    'les',
                                                                )
                                                            }}
                                                            disabled={
                                                                !creditVariableCheck?.hasLeaseToOwn
                                                            }
                                                        >
                                                            <input
                                                                value="lease_to_own"
                                                                name="payment_plan_type"
                                                                defaultChecked={
                                                                    isCheckedTwo ===
                                                                    'les'
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="radio"
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                            />
                                                            <img
                                                                src={
                                                                    isCheckedTwo ===
                                                                    'les'
                                                                        ? RadioSelected
                                                                        : RadioUnselected
                                                                }
                                                                alt="radio"
                                                            />
                                                            Lease to Own
                                                        </button>
                                                    </CustomToolTip>
                                                    <CustomToolTip
                                                        text="A credit variable has not been created for you"
                                                        toolTipFontSize="12px"
                                                        toolTipMarginLeft="10px"
                                                        toolTipPopupWidth="174px"
                                                        showTooltip={
                                                            !creditVariableCheck?.hasSubscription
                                                        }
                                                        arrowPositionLeft
                                                    >
                                                        <button
                                                            className={
                                                                subscriptionModelEnabled
                                                                    ? 'RadioContainer'
                                                                    : 'RadioContainer RadioContainerDisabled'
                                                            }
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                if (
                                                                    subscriptionModelEnabled
                                                                ) {
                                                                    setIsCheckedTwo(
                                                                        'sub',
                                                                    )
                                                                    setPaymentPlanType(
                                                                        'Subscription',
                                                                    )
                                                                    handlePaymentPlanType(
                                                                        'sub',
                                                                    )
                                                                }
                                                            }}
                                                            disabled={
                                                                !subscriptionModelEnabled ||
                                                                !creditVariableCheck?.hasSubscription
                                                            }
                                                            style={{
                                                                border: subscriptionModelEnabled
                                                                    ? ''
                                                                    : `0.7px solid ${sub}`,
                                                                fontWeight:
                                                                    isCheckedTwo ===
                                                                    'sub'
                                                                        ? 600
                                                                        : 400,
                                                                marginLeft:
                                                                    '3.5px',
                                                            }}
                                                        >
                                                            <input
                                                                value="Subscription"
                                                                name="payment_plan_type"
                                                                checked={
                                                                    isCheckedTwo ===
                                                                    'sub'
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="radio"
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                            />
                                                            <img
                                                                src={
                                                                    isCheckedTwo ===
                                                                    'sub'
                                                                        ? RadioSelected
                                                                        : RadioUnselected
                                                                }
                                                                alt="radio"
                                                            />
                                                            Subscription
                                                        </button>
                                                    </CustomToolTip>
                                                </div>
                                            </div>
                                            <div className="InputsContainer">
                                                <div className="InputsContainer__package">
                                                    <SelectField
                                                        values={selectPackageFromOptions.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        selectWidth="317px"
                                                        name="selectPackageFrom"
                                                        initialOption={
                                                            inputs.selectPackageFrom ||
                                                            'Select Package From'
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        floatingLabel={
                                                            inputs.selectPackageFrom !==
                                                                '' &&
                                                            'Select Package From'
                                                        }
                                                        value={
                                                            inputs.selectPackageFrom
                                                        }
                                                        currentSelected={
                                                            inputs.selectPackageFrom
                                                        }
                                                        selectedValue={
                                                            inputs.selectPackageFrom
                                                        }
                                                        dropdownPositionRelative
                                                        errorMessage={
                                                            errors[
                                                                'selectPackageFrom'
                                                            ]
                                                        }
                                                        withCheckBox
                                                    />
                                                    {inputs.selectPackageFrom ===
                                                        selectPackageFromOptions[0] && (
                                                        <InputFields
                                                            name="upfront_deposit"
                                                            inputWidth="317px"
                                                            title="Upfront Deposit"
                                                            type="number"
                                                            marginBottom="0px"
                                                            inputValue={
                                                                inputs.upfront_deposit
                                                            }
                                                            value={
                                                                inputs.upfront_deposit
                                                            }
                                                            floatingLabel="%"
                                                            handleChange={
                                                                handleInputChange
                                                            }
                                                            errorMessage={
                                                                errors?.upfront_deposit ||
                                                                errorMessage?.[0]?.[
                                                                    'upfront_deposit'
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                    {inputs.selectPackageFrom ===
                                                        selectPackageFromOptions[1] && (
                                                        <div className="cluster__info">
                                                            <InfoIcon />
                                                            <p>
                                                                The minimum
                                                                upfront deposit
                                                                that will be
                                                                used will be the
                                                                upfront deposit
                                                                of the select
                                                                shared package
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>

                                        {isCheckedTwo === 'none' ? (
                                            <p className="CreditCheckErrorText">
                                                Please select an option
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                        <div
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            <button
                                                className="EstimationStepOneBtnSecondary"
                                                onClick={() => {
                                                    setShowForm(
                                                        !showForm
                                                            ? true
                                                            : false,
                                                    )
                                                }}
                                                style={{
                                                    position: 'relative',
                                                    top: createEstimationsStepOne2Loading
                                                        ? '-4px'
                                                        : '',
                                                }}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                className="EstimationStepOneBtnPrimary"
                                                disabled={
                                                    createEstimationsStepOne2Loading
                                                }
                                                onClick={e => {
                                                    e.preventDefault()
                                                    handleSavePackage2()
                                                }}
                                            >
                                                {createEstimationsStepOne2Loading ? (
                                                    <InlineLoader />
                                                ) : (
                                                    'Continue'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EstimationsStepOne
