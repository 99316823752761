import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import actionModalStyles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import s from './tenurechangemodal.module.scss'
import {
    rejectNewTenureReasons,
    tenureChangeModalValues,
    tenureChangeReasons,
} from './utils'
import useTenureChangeModal from './hooks/useTenureChangeModal'
import { SelectField, TextArea } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Toast from 'src/components/Toast'
import BackNav from 'src/components/BackNav'
import NairaSymbol from 'src/components/NairaSymbol'

const TenureChangeModal = ({
    showModal,
    closeTenureChangeModal,
    tenureChangeAction,
    setTenureChangeAction,
    estimationId,
    planWithUpdatedTenure,
}) => {
    const {
        handlePrimaryBtnClick,
        onCancel,
        inputs,
        errors,
        handleOnBlur,
        handleSelectOnBlur,
        handleChange,
        handleSelectChange,
        modalToast,
        primaryBtnLoading,
        handleSecondaryBtnClick,
        comparedTenures,
    } = useTenureChangeModal({
        tenureChangeAction,
        setTenureChangeAction,
        closeTenureChangeModal,
        estimationId,
        planWithUpdatedTenure,
    })
    const {
        modalTitle,
        modalContent,
        actionIcon,
        actionHeading,
        actionDescription,
        primaryBtnText,
        footerLeftContent,
        secondaryBtnText,
        closable,
    } = tenureChangeModalValues(tenureChangeAction)
    const { ActionHeader, ActionContainer, ActionText } = actionModalStyles

    const reasonList =
        tenureChangeAction === 'enter-request-reason'
            ? tenureChangeReasons
            : rejectNewTenureReasons
    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={onCancel}
            title={modalTitle}
            primaryBtnText={primaryBtnText}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            primaryBtnLoading={primaryBtnLoading}
            footerLeftContent={footerLeftContent}
            secondaryBtnText={secondaryBtnText}
            handleSecondaryBtnClick={handleSecondaryBtnClick}
            closable={closable}
        >
            <>
                {modalToast?.showToast && (
                    <Toast
                        messageType={modalToast?.messageType}
                        message={modalToast?.toastMessage}
                        position={'absolute'}
                        top={30}
                        closable={true}
                    />
                )}
                {modalContent === 'action-modal' ? (
                    <div className={ActionContainer}>
                        {actionIcon}
                        <h2 className={ActionHeader}>{actionHeading}</h2>
                        <p className={ActionText} style={{ maxWidth: 345 }}>
                            {actionDescription}
                        </p>
                    </div>
                ) : modalContent === 'compare-tenures-cards' ? (
                    <div className={s.compareTenures}>
                        <p>
                            We&apos;ve adjusted your tenure based on your
                            request. Review the updated breakdown below and
                            confirm your decision.
                        </p>
                        {comparedTenures?.map((tenure, i) => (
                            <div
                                key={i}
                                className={`${s.tenureCard} ${
                                    tenure?.title === 'current plan'
                                        ? s.tenureCard_Current
                                        : s.tenureCard_New
                                }`}
                            >
                                <span className={s.tenureCard_Title}>
                                    {tenure?.title}
                                </span>
                                <VerticalSpacer top={16} />
                                <div className={s.tenureCard_Row}>
                                    <div>
                                        <span>upfront deposit</span>
                                        <p>
                                            <NairaSymbol />
                                            {tenure?.upfrontDeposit}
                                        </p>
                                    </div>
                                    <div>
                                        <span>months of repayment</span>
                                        <p style={{ textAlign: 'right' }}>
                                            {tenure?.monthsOfRepayment} months
                                        </p>
                                    </div>
                                </div>
                                <VerticalSpacer top={28} />
                                <div className={s.tenureCard_Row}>
                                    <div>
                                        <span>monthly payment</span>
                                        <p>
                                            <NairaSymbol />
                                            {tenure?.monthlyPayment}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={s.formWrapper}>
                        {tenureChangeAction === 'reject-new-tenure' && (
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() => {
                                    setTenureChangeAction('compare-tenures')
                                }}
                                marginBottom="16px"
                            />
                        )}
                        <div className={s.formSection}>
                            <h3>
                                {tenureChangeAction === 'enter-request-reason'
                                    ? 'Select reason for tenure change'
                                    : 'You are about to reject the new tenure recommended for you'}
                            </h3>
                            <p>
                                {tenureChangeAction === 'enter-request-reason'
                                    ? 'Please provide a reason below so we can better assist you.'
                                    : 'Please provide a reason below so we can better assist you.'}
                            </p>

                            <SelectField
                                values={reasonList?.map(option => ({
                                    value: option,
                                    label: option,
                                }))}
                                selectWidth="100%"
                                name="reason"
                                initialOption="Select a reason"
                                onBlur={() => handleSelectOnBlur('reason')}
                                handleChange={handleSelectChange}
                                marginBottom="0px"
                                dropdownPositionRelative
                                errorMessage={errors?.reason}
                                floatingLabel={
                                    inputs?.reason === ''
                                        ? ''
                                        : 'Select a reason'
                                }
                                withCheckBox
                                selectedValue={inputs?.reason}
                                currentSelected={inputs?.reason}
                            />
                        </div>
                        <VerticalSpacer top={32} />
                        <div className={s.formSection}>
                            <h3>
                                Your reason was not included in the options?
                            </h3>
                            <p>
                                Please go ahead and type your reason for
                                requesting a tenure change.
                            </p>
                            <TextArea
                                title={'Type in your reason'}
                                height="140px"
                                resize
                                name="note"
                                inputValue={inputs?.note}
                                value={inputs?.note}
                                onBlur={handleOnBlur}
                                handleChange={handleChange}
                                rows={10}
                                errorMessage={errors?.note}
                            />
                        </div>
                    </div>
                )}
            </>
        </ConsumerModalBase>
    )
}

TenureChangeModal.propTypes = {
    showModal: PropTypes.bool,
    closeTenureChangeModal: PropTypes.func,
    tenureChangeAction: PropTypes.string,
    setTenureChangeAction: PropTypes.func,
    estimationId: PropTypes.string,
    planWithUpdatedTenure: PropTypes.object,
}

export default TenureChangeModal
