import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { DotsLineLoader } from 'src/components/Loader'
import '../accountsetupoverview.scss'
import { ReactComponent as CheckIcon } from 'src/assets/images/circle_check_blue.svg'

const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}
const style = { width: '100%', marginBottom: 16, height: 80 }
export const LeftSkeletons = () => {
    return (
        <div style={wrapperStyle} data-testid="aso-left-skeleton">
            <Skeleton.Image active style={{ width: '100%', height: 300 }} />
            {[...Array(3)].map((_, i) => (
                <Skeleton.Input key={i} active style={style} />
            ))}
        </div>
    )
}

export const RightSkeletons = () => {
    return (
        <div style={wrapperStyle} data-testid="aso-right-skeleton">
            {[...Array(7)].map((_, i) => (
                <Skeleton.Input key={i} active style={style} />
            ))}
        </div>
    )
}
const autoSaveIndicatorValues = autoSaveStatus => {
    switch (autoSaveStatus) {
        case 'auto-saving':
            return {
                icon: <DotsLineLoader />,
                text: 'Saving progress',
            }
        case 'auto-saved':
            return {
                icon: <CheckIcon />,
                text: 'Progress saved',
            }
        default:
            return {
                icon: '',
                text: '',
            }
    }
}
export const AutoSaveIndicator = ({ autoSaveStatus }) => {
    const { icon, text } = autoSaveIndicatorValues(autoSaveStatus)
    return (
        <div className="Nav_AutoSaveIndicator">
            {icon}
            <span>{text}</span>
        </div>
    )
}

AutoSaveIndicator.propTypes = {
    autoSaveStatus: PropTypes.string,
}
