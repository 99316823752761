//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
    markAPaymentApi,
    regenerateAndAssignPaymentScheduleApi,
    uploadSolutionInstalledEvidenceApi,
    updatePaymentPlanTenure,
} from 'src/api/admin/payment-plans/ssePlan'
import './approvedmodal.scss'
import { format, parse } from 'date-fns'

import FormTitleBar from 'src/components/FormTitleBar'
import { Dropdown, Upload, Menu, message } from 'antd'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import UploadLogo from 'src/assets/images/upload.svg'
import Lock from 'src/assets/images/lock.svg'
import Button from 'src/components/Button'
import {
    DateField,
    InputFields,
    SelectField,
    TextArea,
} from 'src/components/InputFields'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from 'src/components/Seo'
import {
    paystackSubscriptionCodeRegex,
    googleDriveLinkRegex,
} from 'src/utils/validationSchema'
import { floatWithCommas, getFileNameFromUrl } from 'src/utils/formatting'
import { getDate } from 'date-fns'
import { usePlanContext } from '../../hooks/planProvider'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import ActionModal from 'src/components/ActionModal'
import { CostField } from 'src/components/InputFields/CostField'
import { ReactComponent as WarningIcon } from 'src/assets/images/info-warning-blue2.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { isNotInconsistentDate } from 'src/utils/dateFilter'
import useUploadEvidenceToS3 from '../../hooks/useUploadEvidenceToS3'
import useSubmitToDo from '../../hooks/useSubmitToDo'
import repaymentSetupMapping from 'src/utils/Data/repaymentSetupMapping'
import plusBlueIcon from 'src/assets/images/Plus-Blue.svg'
import CollapsibleExistingPaymentRecord from './CollapsibleExistingPaymentRecord'
import CollapsibleNewPaymentRecord from './CollapsibleNewPaymentRecord'
import UploadSignedAgreementModal from './UploadSignedAgreementModal'
const ApprovedModal = ({
    estimationId,
    estimationData,
    showCalendar,
    setShowCalendar,
    openClosePlanActionModal,
    isMultiplePartialPaymentMode,
    setIsMultiplePartialPaymentMode,
}) => {
    // file uploading - to-do table
    const [uploading, setUploading] = useState(false)
    const [fileValid, setFileValid] = useState(true)
    const [repaymentPlans, setRepaymentPlans] = useState([])
    const [showUploadNewAgreementModal, setShowUploadNewAgreementModal] =
        useState(false)
    const queryClient = useQueryClient()

    const {
        toDoContent,
        moreActionsContent,
        recordDepositContent,
        repaymentDepositContent,
        repaymentScheduleContent,
        setOpenModal,
        setSuccessModal,
        setSuccessHeaderText,
        inputs,
        setInputs,
        currentDatePaid,
        setCurrentDatePaid,
        firstRepaymentDate,
        setRepaymentDate,
        hour,
        setHour,
        minute,
        setMinute,
        time,
        setTime,
        showTimeDropdown,
        setShowTimeDropdown,
        modalDisplayValues,
        errors,
        setErrors,
        uploadError,
        setUploadError,
        successUpload,
        setSuccessUpload,
        showUploadStandingOrder,
        setShowUploadStandingOrder,
        showPaystackSubscriptionCode,
        setShowPaystackSubscriptionCode,
        paymentMethods,
        recordPaymentContent,
        setSuccessModalSubtitle,
    } = usePlanContext()

    const { submitToDoMutate, submitToDoLoading } = useSubmitToDo(
        estimationId,
        successMsg => {
            setOpenModal(false)
            setSuccessHeaderText(successMsg)
            setSuccessModalSubtitle(
                modalDisplayValues === 'More Actions' &&
                    moreActionsContent[0].moreActionsLabel ===
                        'Payment Evidence' &&
                    'You have successfully submitted the payment evidence for this consumer',
            )
            setSuccessModal(true)
            queryClient.invalidateQueries('single-payment-plan-estimation')
        },
        error => {
            setErrorToast(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
        estimationData.payment_plan,
    )
    const { uploadEvidenceMutate, uploadEvidenceLoading } =
        useUploadEvidenceToS3(
            successUpload?.[0],
            (s3Url, actionKeyID) => {
                submitToDoMutate(s3Url, inputs, actionKeyID)
            },
            error => {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        )
    const [errorToast, setErrorToast] = useState(false)
    const [width, setWidth] = useState(40)
    const [errorMessage, setErrorMessage] = useState('')
    // data to be passed into modal
    const formattedValues =
        modalDisplayValues === 'To Do Table'
            ? toDoContent
            : modalDisplayValues === 'More Actions'
            ? moreActionsContent
            : modalDisplayValues === 'Record Deposit'
            ? recordDepositContent
            : modalDisplayValues === 'Record Payment'
            ? recordPaymentContent
            : modalDisplayValues === 'Record Repayment'
            ? repaymentDepositContent
            : repaymentScheduleContent
    // handle change on datepickers
    const handleDateChange = (name, date) => {
        if (name === 'dateOfAction') {
            setCurrentDatePaid(format(date, 'MM/dd/yyyy'))
        }

        if (name === 'firstRepaymentDate') {
            setRepaymentDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]:
                name === 'dateOfAction' ? currentDatePaid : firstRepaymentDate,
        }))
    }

    const isValidRange = date => {
        const day = getDate(date)
        return day < 31
    }
    // handle time picker
    const handleShowTimeDropDown = action => {
        if (action.keyID !== 'REPAYMENT_SCHEDULE') {
            setShowTimeDropdown(true)
        }

        if (action.statusMsg === 'COMPLETED') {
            setShowTimeDropdown(false)
        }
    }

    const handleHourChange = e => {
        if (e.target.value < 24) {
            setHour(e.target.value)
        }
    }

    const handleMinuteChange = e => {
        if (e.target.value < 60) {
            setMinute(e.target.value)
        }
    }

    const handleSetTime = () => {
        // we need the date of action to be inputed before the time of payment, we're formatting the date and time together and it'll be invalid if the date is not set
        if (inputs.dateOfAction === '' || inputs.dateOfAction === null) {
            setErrors(prev => ({
                ...prev,
                dateOfAction: 'Select the date of payment',
            }))
            setShowTimeDropdown(false)
        } else {
            setErrors(prev => ({
                ...prev,
                dateOfAction: '',
            }))
            const time = hour + ':' + minute
            let concatDate = new Date(currentDatePaid + ' ' + time)
            const formatTime = time.toString()
            if (hour && time) {
                setTime(time)

                setInputs(prevInputs => ({
                    ...prevInputs,
                    timeOfAction: format(
                        parse(formatTime, 'HH:mm', new Date()),
                        'hh:mm',
                    ),
                    dateTime: format(concatDate, "yyyy-MM-dd'T'HH:mm:ssxxx"),
                }))
                setShowTimeDropdown(false)
            }
        }
    }

    const { Dragger } = Upload
    const draggerprops = {
        name: 'file',

        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
        },
        onDrop() {
            setUploading(false)
        },
    }
    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    //set values for updating payment plan tenure
    useEffect(() => {
        const availablePlans = estimationData?.repayment_plans?.filter(
            plan => plan?.admin_approved === false,
        )

        for (let i = 0; i < availablePlans?.length; i++) {
            setRepaymentPlans(prev => [
                ...prev,
                {
                    id: availablePlans[i].id,
                    label:
                        estimationData?.payment_plan === 'Subscription'
                            ? ` Plan ${i + 1} (${
                                  availablePlans[i].currency.symbol
                              } ${availablePlans[i].monthly_payment}/mo)`
                            : ` Plan ${i + 1} (${
                                  availablePlans[i].tenure
                              } Months, ${availablePlans[i].currency.symbol} ${
                                  availablePlans[i].monthly_payment
                              }/mo)`,
                },
            ])
        }
    }, [])

    useEffect(() => {
        let repaymentDepositpaid = repaymentDepositContent?.map(
            repayment => repayment.amountPaid,
        )[0]
        setInputs(prev => ({
            ...prev,
            recordRepaymentTotalAmount:
                repaymentDepositpaid === '0.00'
                    ? ''
                    : currencyFormatter(repaymentDepositpaid),
            upfrontDepositTotalAmount:
                estimationData?.upfront_deposit_total_amount_paid === null
                    ? ''
                    : estimationData?.upfront_deposit_total_amount_paid,
            buyoutTotalAmount:
                estimationData?.meta_data?.buyout_evidence
                    ?.total_amount_paid === null ||
                estimationData?.meta_data?.buyout_evidence
                    ?.total_amount_paid === undefined
                    ? ''
                    : estimationData?.meta_data?.buyout_evidence
                          ?.total_amount_paid,
            providerPaymentTotalAmount:
                estimationData?.provider_payment_total_amount_paid === null
                    ? ''
                    : estimationData?.provider_payment_total_amount_paid,
            outrightSaleTotalAmount:
                estimationData?.outright_payment_evidence === null
                    ? ''
                    : estimationData?.outright_payment_evidence?.amount,
        }))
    }, [toDoContent, repaymentDepositContent, recordDepositContent])
    // time picket menu
    const menu = (
        <Menu>
            <Menu.Item>
                <div style={{ display: 'flex' }}>
                    <div className="TimePickerLeftWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={hour}
                                name="hour"
                                onChange={handleHourChange}
                                autoFocus
                            />
                            <CustomToolTip
                                text="Enter hours as digits from 0 to 23 only. Avoid numbers above 23 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Hour</p>
                        <Button
                            btnBgColor=" #E2EEFF"
                            btnWidth="110px"
                            btnTextColor="var(--blue)"
                            btnHeight="45px"
                            handleClick={() => setShowTimeDropdown(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <div
                            className="ApprovedModalTimePickerSeparator"
                            style={{ margin: '16px 0' }}
                        ></div>
                        <div className="ApprovedModalTimePickerSeparator"></div>
                    </div>
                    <div className="TimePickerRightWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={minute}
                                name="minute"
                                onChange={handleMinuteChange}
                            />
                            <CustomToolTip
                                text="Enter minutes as digits from 0 to 59 only. Avoid numbers above 59 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Minute</p>
                        <div style={{ marginLeft: 5 }}>
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="110px"
                                btnHeight="45px"
                                handleClick={handleSetTime}
                            >
                                Set Time
                            </Button>
                        </div>
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    )

    // get evidence format
    const evidenceFormat = file => {
        return JSON.stringify(file).split('.')[
            JSON.stringify(file).split('.').length - 1
        ]
    }

    // repayment
    const paymentMethodData = [
        'Auto Payment Link',
        'Bank Transfer',
        'Direct Deposit',
    ]

    // repayment methods
    const repaymentMethodSetupData = [
        'Bank - Standing Order',
        'Paystack - Plan/Subscription',
        'Bank Transfer',
    ]

    const handleSelectChange = (name, value) => {
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleRepaymentMethodSetupType = (name, value) => {
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
        setShowUploadStandingOrder(value === 'Bank - Standing Order')
        setShowPaystackSubscriptionCode(
            value === 'Paystack - Plan/Subscription',
        )
    }

    const handleReselectTenure = (name, value) => {
        const availablePlans = estimationData?.repayment_plans?.filter(
            plan => plan?.admin_approved === false,
        )
        let newPlanId
        for (let i = 0; i < availablePlans.length; i++) {
            if (repaymentPlans[i].label.includes(value)) {
                newPlanId = repaymentPlans[i].id
            }
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
            updatePlanTenurePlanId: newPlanId,
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        if (name === 'recordRepaymentTotalAmount') {
            setInputs(prev => ({
                ...prev,
                recordRepaymentTotalAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.recordRepaymentTotalAmount,
            }))
        } else if (name === 'upfrontDepositTotalAmount') {
            setInputs(prev => ({
                ...prev,
                upfrontDepositTotalAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.upfrontDepositTotalAmount,
            }))
        } else if (name === 'buyoutTotalAmount') {
            setInputs(prev => ({
                ...prev,
                buyoutTotalAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.buyoutTotalAmount,
            }))
        } else if (name === 'providerPaymentTotalAmount') {
            setInputs(prev => ({
                ...prev,
                providerPaymentTotalAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.providerPaymentTotalAmount,
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleClosureInputChange = event => {
        setInputs(prev => ({
            ...prev,
            closureReason: event.target.value,
        }))
    }

    const handleBlur = e => {
        const { name, value } = e.target

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    // assign action to the right handler
    const handleUpload = action => {
        if (
            action.statusMsg === 'COMPLETED' ||
            action.statusMsg === undefined
        ) {
            setOpenModal(false)
        } else {
            // TODO: Change the use of action.modalTitle to action.keyID because these strings can change and it is unreliable
            action.modalTitle === 'Record Deposit'
                ? handleRecordDeposit(action.keyID)
                : action.keyID === 'MARK_A_PAYMENT'
                ? handleRecordPayment(action.keyID)
                : action.keyID === 'BUYOUT'
                ? handleRecordBuyout(action.keyID)
                : action.keyID === 'PROVIDER_PAYMENT'
                ? handlePaymentEvidence(action.keyID)
                : action.keyID === 'SUNFI_AGREEMENT'
                ? handleSignedAgreement(action.keyID)
                : action.keyID === 'SOLUTION_INSTALLED'
                ? handleInstallationEvidence()
                : action.keyID === 'REPAYMENT_SCHEDULE'
                ? handleRepaymentSchedule()
                : action.keyID === 'REPAYMENT_METHOD_SETUP'
                ? handleRepaymentMethodSetup(action.keyID)
                : action.keyID === 'UPDATE_PLAN_TENURE'
                ? handleReselectPaymentPlanTenure()
                : action.keyID === 'CLOSE_PLAN'
                ? handleClosure()
                : handleRecordRepayment()
        }
    }

    const handleMultipleUpload = () => {
        setShowUploadNewAgreementModal(true)
    }

    const handleRecordDeposit = actionKeyID => {
        let totalAmount = estimationData?.repayment_plans.find(
            plan => plan.admin_approved === true,
        ).initial_deposit

        let inputAmount = inputs.upfrontDepositTotalAmount.toString()

        let formatInputAmount = parseFloat(inputAmount.replace(/,/g, ''))
        let formatTotalAmount = parseFloat(totalAmount.replace(/,/g, ''))

        if (
            inputs.dateTime === '' ||
            successUpload.length === 0 ||
            formatInputAmount < formatTotalAmount
        ) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setUploadError(true)
            }
            if (inputs.upfrontDepositTotalAmount === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    upfrontDepositTotalAmount: 'This field is required',
                }))
            }
            if (formatInputAmount < formatTotalAmount) {
                setErrors(prev => ({
                    ...prev,
                    upfrontDepositTotalAmount: `Total amount should not be less than ${totalAmount}`,
                }))
            }
        } else {
            if (formatInputAmount > formatTotalAmount) {
                setShowIncorrectAmountModal(true)
                setIncorrectModalAction('Upfront Deposit')
            } else {
                uploadEvidenceMutate(actionKeyID)
            }
        }
    }

    const handleRecordBuyout = actionKeyID => {
        let inputAmount = inputs.buyoutTotalAmount.toString()
        let formatInputAmount = parseFloat(inputAmount.replace(/,/g, ''))

        if (inputs.dateTime === '' || successUpload.length === 0) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setUploadError(true)
            }
            if (inputs.buyoutTotalAmount === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    buyoutTotalAmount: 'This field is required',
                }))
            }
        } else {
            uploadEvidenceMutate(actionKeyID)
        }
    }

    const handleRecordPayment = actionKeyID => {
        let totalAmount =
            estimationData?.recommended_solution?.total_cost?.toFixed(2)
        let inputAmount = inputs.outrightSaleTotalAmount.toString()
        let formatInputAmount = parseFloat(inputAmount.replace(/,/g, ''))

        if (
            inputs.dateTime === '' ||
            successUpload.length === 0 ||
            formatInputAmount < totalAmount
        ) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setUploadError(true)
            }
            if (inputs.outrightSaleTotalAmount === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    outrightSaleTotalAmount: 'This field is required',
                }))
            }
            if (formatInputAmount < totalAmount) {
                setErrors(prev => ({
                    ...prev,
                    outrightSaleTotalAmount: `Total amount should not be less than ${currencyFormatter(
                        totalAmount,
                    )}`,
                }))
            }
            if (inputs.repayentMethod === '') {
                setErrors(prev => ({
                    ...prev,
                    repaymentMethod: 'This feild is required',
                }))
            }
        } else {
            if (formatInputAmount > totalAmount) {
                setShowIncorrectAmountModal(true)
                setIncorrectModalAction('Payment Evidence')
            } else {
                uploadEvidenceMutate(actionKeyID)
            }
        }
    }

    const handlePaymentEvidence = actionKeyID => {
        let totalAmount =
            estimationData?.recommended_solution?.total_cost?.toFixed(2)
        let inputAmount = inputs.providerPaymentTotalAmount.toString()

        let formatInputAmount = parseFloat(inputAmount.replace(/,/g, ''))

        if (
            inputs.dateTime === '' ||
            successUpload.length === 0 ||
            formatInputAmount < totalAmount
        ) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setUploadError(true)
            }
            if (inputs.providerPaymentTotalAmount === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    providerPaymentTotalAmount: 'This field is required',
                }))
            }
            if (formatInputAmount < totalAmount) {
                setErrors(prev => ({
                    ...prev,
                    providerPaymentTotalAmount: `Total amount should not be less than ${currencyFormatter(
                        totalAmount,
                    )}`,
                }))
            }
        } else {
            if (formatInputAmount > totalAmount) {
                setShowIncorrectAmountModal(true)
                setIncorrectModalAction('Provider Payment')
            } else {
                uploadEvidenceMutate(actionKeyID)
            }
        }
    }
    const handleSignedAgreement = actionKeyID => {
        if (inputs.dateTime === '' || successUpload.length === 0) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (successUpload.length === 0) {
                setUploadError(true)
            }
        } else {
            uploadEvidenceMutate(actionKeyID)
        }
    }

    const handleInstallationEvidence = () => {
        let errorSet = false
        if (inputs.dateTime === '') {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
                errorSet = true
            }
            if (inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
                errorSet = true
            }
        }
        if (inputs.installedSolutionEvidenceDriveLink === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                installedSolutionEvidenceDriveLink: 'This field is required',
            }))
            errorSet = true
        }

        if (
            inputs.installedSolutionEvidenceDriveLink !== '' &&
            !googleDriveLinkRegex.test(
                inputs.installedSolutionEvidenceDriveLink,
            )
        ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                installedSolutionEvidenceDriveLink:
                    'The google drive link is invalid',
            }))
            errorSet = true
        }

        if (!errorSet) uploadInstallationEvidenceRefetch()
    }

    const [showIncorrectAmountModal, setShowIncorrectAmountModal] =
        useState(false)
    const [incorrectModalAction, setIncorrectModalAction] = useState('')

    const handleRepaymentSchedule = () => {
        if (
            inputs.dateOfAction === null ||
            inputs.firstRepaymentDate === null
        ) {
            if (currentDatePaid === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }

            if (inputs.firstRepaymentDate === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dateOfAction: 'This field is required',
                }))
            }
        } else {
            regeneratePaymentScheduleRefetch()
        }
    }

    const handleRepaymentMethodSetup = actionKeyID => {
        let errorSet = false
        if (inputs.repaymentMethodSetupType == null) {
            setErrors(prevErrors => ({
                ...prevErrors,
                repaymentMethodSetupType: 'This field is required',
            }))
            errorSet = true
        }
        if (inputs.repaymentMethodSetupType === 'Bank - Standing Order') {
            if (successUpload.length === 0) {
                setUploadError(true)
                errorSet = true
            }
        }
        if (
            inputs.repaymentMethodSetupType === 'Paystack - Plan/Subscription'
        ) {
            if (inputs.repaymentMethodSetupSubscriptionCode === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    repaymentMethodSetupSubscriptionCode:
                        'This field is required',
                }))
                errorSet = true
            }

            if (
                !paystackSubscriptionCodeRegex.test(
                    inputs.repaymentMethodSetupSubscriptionCode,
                )
            ) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    repaymentMethodSetupSubscriptionCode:
                        'The subscription code is invalid',
                }))
                errorSet = true
            }
        }

        if (inputs.dateOfAction === null) {
            setErrors(prevErrors => ({
                ...prevErrors,
                dateOfAction: 'This field is required',
            }))
            errorSet = true
        }

        if (inputs.timeOfAction === null) {
            setErrors(prevErrors => ({
                ...prevErrors,
                timeOfAction: 'This field is required',
            }))
            errorSet = true
        }

        if (!errorSet) {
            if (inputs.repaymentMethodSetupType === 'Bank - Standing Order') {
                uploadEvidenceMutate(actionKeyID)
            } else {
                submitToDoMutate('', inputs, actionKeyID)
            }
        }
    }

    const handleReselectPaymentPlanTenure = () => {
        if (inputs.updatePlanTenure === null) {
            setErrors(prevErrors => ({
                ...prevErrors,
                updatePlanTenure: 'This field is required',
            }))
        } else {
            updatePlanTenureRefetch()
        }
    }

    const handleRecordRepayment = () => {
        let totalAmount = repaymentDepositContent?.map(
            repayment => repayment.amountDue,
        )[0]
        let inputAmount = inputs.recordRepaymentTotalAmount.toString()

        let formatInputAmount = parseFloat(inputAmount.replace(/,/g, ''))
        let formatTotalAmount = parseFloat(totalAmount.replace(/,/g, ''))
        let repaymentMethodSetupType =
            repaymentDepositContent.map(
                repayment => repayment.repaymentMethodType,
            )[0] !== 'BANK_TRANSFER:ADMIN_MANUAL_ENTRY'

        if (
            (repaymentMethodSetupType && inputs.dateTime === null) ||
            (repaymentMethodSetupType && inputs.dateTime === '') ||
            currentDatePaid === null ||
            inputs.repaymentMethod === '' ||
            (repaymentMethodSetupType && successUpload.length === 0)
        ) {
            if (currentDatePaid === null || currentDatePaid === '') {
                setErrors({
                    dateOfAction: 'This field is required',
                })
            }
            if (repaymentMethodSetupType && inputs.timeOfAction === null) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    timeOfAction: 'This field is required',
                }))
            }
            if (inputs.repaymentMethod === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    repaymentMethod: 'This field is required',
                }))
            }

            if (repaymentMethodSetupType && successUpload.length === 0) {
                setUploadError(true)
            }
            if (inputs.recordRepaymentTotalAmount === '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    recordRepaymentTotalAmount: 'This field is required',
                }))
            }
        } else {
            if (formatInputAmount > formatTotalAmount) {
                setShowIncorrectAmountModal(true)
                setIncorrectModalAction('Record Repayment')
            } else {
                recordRepaymentRefetch()
            }
        }
    }

    const handleClosure = () => {
        if (inputs.closureReason === null) {
            setErrors(prevErrors => ({
                ...prevErrors,
                closureReason: 'This field is required',
            }))
        } else {
            openClosePlanActionModal()
            setOpenModal(false)
            setErrors(prevErrors => ({
                ...prevErrors,
                closureReason: '',
            }))
        }
    }

    // API calls
    const agreementHistory = estimationData?.signed_agreement || []

    const {
        refetch: uploadInstallationEvidenceRefetch,
        isLoading: uploadInstallationEvidenceLoading,
    } = useQuery(
        'upload-solution-installation-evidence',
        () =>
            uploadSolutionInstalledEvidenceApi(estimationId, {
                action: 'SOLUTION_INSTALLED',
                solution_installed_at: inputs.dateTime,
                evidence_of_solution_installed_file:
                    inputs.installedSolutionEvidenceDriveLink,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOpenModal(false)
                setSuccessHeaderText('Installation Evidence Updated')
                setSuccessModal(true)
                queryClient.invalidateQueries('single-payment-plan-estimation')
            },
            onError: error => {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const {
        refetch: regeneratePaymentScheduleRefetch,
        isLoading: regeneratePaymentScheduleLoading,
    } = useQuery(
        'regenerate-payment-schedule-and-assign',
        () =>
            regenerateAndAssignPaymentScheduleApi(estimationId, {
                action: 'REGENERATE_SCHEDULE_AND_ASSIGN',

                new_financing_start_date: format(
                    new Date(inputs.dateOfAction),
                    "yyyy-MM-dd'T'hh:mm",
                ),

                first_repayment_date: format(
                    new Date(inputs.firstRepaymentDate),
                    "yyyy-MM-dd'T'hh:mm",
                ),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOpenModal(false)
                setSuccessHeaderText('Repayment Started Successfully')
                setSuccessModal(true)
                queryClient.invalidateQueries('single-payment-plan-estimation')
            },
            onError: error => {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: updatePlanTenureRefetch,
        isLoading: updatePlanTenureLoading,
    } = useQuery(
        'update-plan-tenure',
        () =>
            updatePaymentPlanTenure(estimationId, {
                action: 'RE_SELECT_PAYMENT_PLAN',
                action_payment_plan: inputs.updatePlanTenurePlanId,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOpenModal(false)
                setSuccessHeaderText('Plan Updated Successfully')
                setSuccessModal(true)
                queryClient.invalidateQueries('single-payment-plan-estimation')
            },
            onError: error => {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: recordRepaymentRefetch,
        isLoading: recordRepaymentLoading,
    } = useQuery(
        'mark-a-repayment',
        () =>
            markAPaymentApi(estimationId, {
                action: 'MARK_A_PAYMENT',
                repayment_month: inputs.repaymentMonth,
                date_repayment_was_made:
                    inputs.dateTime === ''
                        ? format(
                              new Date(currentDatePaid),
                              "yyyy-MM-dd'T'HH:mm:ssxxx",
                          )
                        : inputs.dateTime,
                repayment_method: inputs.repaymentMethod,
                repayment_evidence:
                    successUpload.length === 0
                        ? null
                        : successUpload[0]?.originFileObj,
                total_amount_paid: inputs.recordRepaymentTotalAmount
                    .toString()
                    .replace(/,/g, ''),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOpenModal(false)
                setSuccessHeaderText('Repayment Recorded Successfully')
                setSuccessModal(true)
                queryClient.invalidateQueries('single-payment-plan-estimation')
            },
            onError: error => {
                setErrorToast(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // loading states
    const loading =
        uploadInstallationEvidenceLoading ||
        regeneratePaymentScheduleLoading ||
        updatePlanTenureLoading ||
        recordRepaymentLoading ||
        uploadEvidenceLoading ||
        submitToDoLoading

    const beforeUpload = file => {
        const isLt2M = file.size / 1000 / 1000 < 3

        if (!isLt2M) {
            message.error('Image should be less than 3mb')
            setFileValid(false)
            return false
        } else {
            setFileValid(true)
            return true
        }
    }

    const requestsModal = window.location.pathname.includes('requests')

    const handleTotalAmountAction = () => {
        setShowIncorrectAmountModal(false)

        incorrectModalAction === 'Record Repayment'
            ? recordRepaymentRefetch()
            : incorrectModalAction === 'Upfront Deposit'
            ? uploadEvidenceMutate('UPFRONT_DEPOSIT')
            : incorrectModalAction === 'Provider Payment' &&
              uploadEvidenceMutate('PROVIDER_PAYMENT')
    }

    let bankTransferMethodSetup =
        estimationData?.repayment_method_setup?.type ===
        'BANK_TRANSFER:ADMIN_MANUAL_ENTRY'

    useEffect(() => {
        if (formattedValues?.[0]?.transactions?.length > 1) {
            setIsMultiplePartialPaymentMode(true)
        }
    }, [formattedValues])

    return (
        <div>
            {showUploadNewAgreementModal ? (
                <UploadSignedAgreementModal
                    estimationId={estimationId}
                    onClose={() => setShowUploadNewAgreementModal(false)}
                />
            ) : (
                <>
                    <SeoComponent
                        title={
                            requestsModal
                                ? 'SunFi - Admin Requests | Approved Requests - Record Deposit | Approved Requests - Repayment Schedule | Approved Requests - Upload Signed Agreement'
                                : 'SunFi - Admin Payment Plans | Approved Plans - Record Deposit | Approved Plans - Repayment Schedule | Approved Plans - Upload Signed Agreement'
                        }
                        tracker={
                            requestsModal
                                ? 'AdminRequestsTracker'
                                : 'AdminPaymentPlansTracker'
                        }
                    />
                    <ActionModal
                        actionModalOpen={showIncorrectAmountModal}
                        headerText="Incorrect Total Amount"
                        subTitle="Total amount entered doesnt match the records, do you want to proceed?"
                        closeModal={() => setShowIncorrectAmountModal(false)}
                        actionHandler={handleTotalAmountAction}
                        actionType="success"
                        actionText="Continue"
                        cancelText="Cancel"
                    />
                    {errorToast && (
                        <Toast
                            message={
                                errorMessage !== ''
                                    ? errorMessage
                                    : "Couldn't submit your request. Please try again"
                            }
                            messageType="error"
                            showIcon
                        />
                    )}

                    <div>
                        {formattedValues?.map((action, i) => {
                            return (
                                <div key={i}>
                                    <FormTitleBar
                                        title={action.modalTitle}
                                        subtitle={action.modalSubTitle}
                                    />
                                    {isMultiplePartialPaymentMode ? (
                                        <div>
                                            {action.transactions.map(
                                                (item, idx) => {
                                                    return (
                                                        <CollapsibleExistingPaymentRecord
                                                            key={idx}
                                                            action={action}
                                                            idx={idx}
                                                            item={item}
                                                            setIsMultiplePartialPaymentMode={
                                                                setIsMultiplePartialPaymentMode
                                                            }
                                                        />
                                                    )
                                                },
                                            )}
                                            {action.status === 'SHORT_PAID' ? (
                                                <div className="new_payment_records_container">
                                                    <CollapsibleNewPaymentRecord
                                                        action={action}
                                                        setIsMultiplePartialPaymentMode={
                                                            setIsMultiplePartialPaymentMode
                                                        }
                                                        estimationId={
                                                            estimationId
                                                        }
                                                        width={width}
                                                        isMultiplePartialPaymentMode={
                                                            isMultiplePartialPaymentMode
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        marginTop: '60px',
                                                    }}
                                                >
                                                    <Button
                                                        btnWidth="160px"
                                                        btnHeight="56px"
                                                        btnBgColor="#004AAD"
                                                        btnTextColor="var(--white)"
                                                        handleClick={() => {
                                                            setOpenModal(false)
                                                            setIsMultiplePartialPaymentMode(
                                                                false,
                                                            )
                                                        }}
                                                    >
                                                        Okay, Got it
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {action.keyID ===
                                                'REPAYMENT_METHOD_SETUP' && (
                                                <div
                                                    style={{
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    <SelectField
                                                        selectWidth="98%"
                                                        name="repaymentMethodSetupType"
                                                        type="approvedModal"
                                                        initialOption={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? `${
                                                                      repaymentSetupMapping[
                                                                          action
                                                                              .methodType
                                                                      ]
                                                                  }`
                                                                : 'Select Repayment Method Type'
                                                        }
                                                        values={repaymentMethodSetupData.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        withCheckBox={true}
                                                        optionStyle={{
                                                            width: '100%',
                                                            marginTop: '6px',
                                                            marginBottom: '6px',
                                                        }}
                                                        value={
                                                            inputs.repaymentMethodSetupType !==
                                                            null
                                                                ? inputs.repaymentMethodSetupType
                                                                : action.methodType ===
                                                                  null
                                                                ? 'Select Repayment Method Type'
                                                                : repaymentSetupMapping[
                                                                      action
                                                                          .methodType
                                                                  ]
                                                        }
                                                        handleChange={
                                                            handleRepaymentMethodSetupType
                                                        }
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        errorMessage={
                                                            errors.repaymentMethodSetupType
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {/*update plan tenure from repayment plans options*/}
                                            {action.keyID ===
                                                'UPDATE_PLAN_TENURE' && (
                                                <div
                                                    style={{
                                                        marginBottom: 170,
                                                    }}
                                                >
                                                    <SelectField
                                                        selectWidth="98%"
                                                        name="updatePlanTenure"
                                                        type="DropdownClassName"
                                                        withCheckBox
                                                        initialOption="Select Plan Duration"
                                                        values={repaymentPlans.map(
                                                            option => ({
                                                                value: option.label,
                                                            }),
                                                        )}
                                                        value={
                                                            inputs.updatePlanTenure ===
                                                            null
                                                                ? 'Select Plan Duration'
                                                                : inputs.updatePlanTenure
                                                        }
                                                        handleChange={
                                                            handleReselectTenure
                                                        }
                                                        optionStyle={{
                                                            width: '100%',
                                                            paddingTop: '10px',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                        errorMessage={
                                                            errors.updatePlanTenure
                                                        }
                                                        currentSelected={
                                                            inputs.updatePlanTenure
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {action.uploadText !== 'None' && (
                                                <>
                                                    <div className="APAModalDateTimeWrapper">
                                                        <div
                                                            style={{
                                                                width: '224px',
                                                            }}
                                                            className="APAModalDateTimeInput"
                                                        >
                                                            <DateField
                                                                prefilled={
                                                                    action.statusMsg ===
                                                                    'COMPLETED'
                                                                }
                                                                disabledCursorIcon={
                                                                    action.statusMsg ===
                                                                    'COMPLETED'
                                                                }
                                                                dateValue={
                                                                    inputs.dateOfAction ===
                                                                    null
                                                                        ? null
                                                                        : new Date(
                                                                              inputs.dateOfAction,
                                                                          )
                                                                }
                                                                name="dateOfAction"
                                                                handleDateChange={(
                                                                    name,
                                                                    date,
                                                                ) =>
                                                                    handleDateChange(
                                                                        name,
                                                                        date,
                                                                    )
                                                                }
                                                                setHandleDateChange={name =>
                                                                    setHandleDateChange(
                                                                        name,
                                                                    )
                                                                }
                                                                // if datetime is not empty, return a formatted version, else use placeholders provided
                                                                placeholder={
                                                                    JSON.stringify(
                                                                        action.updatedTime,
                                                                    ) !==
                                                                    'null' ? (
                                                                        <div className="APAMInputAutoFilled">
                                                                            <p>
                                                                                {action.keyID ===
                                                                                'REPAYMENT_METHOD_SETUP'
                                                                                    ? bankTransferMethodSetup
                                                                                        ? 'Date Agreed'
                                                                                        : 'Date Setup'
                                                                                    : [
                                                                                          'MARK_A_PAYMENT',
                                                                                          'RECORD_PAYMENT',
                                                                                          'BUYOUT',
                                                                                      ].includes(
                                                                                          action.keyID,
                                                                                      )
                                                                                    ? 'Date Paid'
                                                                                    : 'Financing Start Date'}
                                                                            </p>
                                                                            <span>
                                                                                {action
                                                                                    .updatedTime
                                                                                    .length ===
                                                                                0
                                                                                    ? ''
                                                                                    : format(
                                                                                          new Date(
                                                                                              action.updatedTime,
                                                                                          ),
                                                                                          'dd MMM yyyy',
                                                                                      )}
                                                                            </span>
                                                                        </div>
                                                                    ) : action.keyID ===
                                                                      'REPAYMENT_SCHEDULE' ? (
                                                                        'Finance Date'
                                                                    ) : action.keyID ===
                                                                          'SOLUTION_INSTALLED' ||
                                                                      action.keyID ===
                                                                          'SUNFI_AGREEMENT' ? (
                                                                        'Date'
                                                                    ) : action.keyID ===
                                                                      'REPAYMENT_METHOD_SETUP' ? (
                                                                        bankTransferMethodSetup ? (
                                                                            'Date Agreed'
                                                                        ) : (
                                                                            'Date Setup'
                                                                        )
                                                                    ) : [
                                                                          'MARK_A_PAYMENT',
                                                                          'RECORD_PAYMENT',
                                                                          'BUYOUT',
                                                                      ].includes(
                                                                          action.keyID,
                                                                      ) ? (
                                                                        'Date Paid'
                                                                    ) : (
                                                                        'Financing Start Date'
                                                                    )
                                                                }
                                                                openCalendarHandler={() =>
                                                                    action.statusMsg ===
                                                                    'COMPLETED'
                                                                        ? null
                                                                        : setShowCalendar(
                                                                              1,
                                                                          )
                                                                }
                                                                openCalendar={
                                                                    showCalendar ===
                                                                    1
                                                                        ? true
                                                                        : false
                                                                }
                                                                closeCalendarHandler={() =>
                                                                    setShowCalendar(
                                                                        null,
                                                                    )
                                                                }
                                                                caretStyle="AdminPaymentPlanCaretStyle"
                                                                errorMessage={
                                                                    errors?.dateOfAction
                                                                }
                                                            />
                                                        </div>
                                                        {/* contains date input for repayment schedule & time input for all else */}
                                                        <div
                                                            style={{
                                                                width: '224px',
                                                            }}
                                                            className="APAModalDateTimeInput"
                                                        >
                                                            {action.keyID !==
                                                            'REPAYMENT_SCHEDULE' ? (
                                                                <div>
                                                                    <Dropdown
                                                                        overlay={
                                                                            menu
                                                                        }
                                                                        trigger={[
                                                                            'click',
                                                                        ]}
                                                                        visible={
                                                                            showTimeDropdown
                                                                        }
                                                                        overlayClassName="AdminApprovedTimePicker"
                                                                        placement="bottomCenter"
                                                                        overlayStyle={{
                                                                            marginTop:
                                                                                '18px',
                                                                            marginLeft:
                                                                                '42px',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            onClick={() =>
                                                                                handleShowTimeDropDown(
                                                                                    action,
                                                                                )
                                                                            }
                                                                            className="APAModalTimeInputWrapper"
                                                                        >
                                                                            <InputFields
                                                                                title={
                                                                                    action.keyID ===
                                                                                        'SOLUTION_INSTALLED' ||
                                                                                    action.keyID ===
                                                                                        'SUNFI_AGREEMENT'
                                                                                        ? 'Time'
                                                                                        : action.keyID ===
                                                                                          'REPAYMENT_METHOD_SETUP'
                                                                                        ? bankTransferMethodSetup
                                                                                            ? 'Time Agreed'
                                                                                            : 'Time Setup'
                                                                                        : 'Time Paid'
                                                                                }
                                                                                value={
                                                                                    time
                                                                                        ? time
                                                                                        : [
                                                                                              null,
                                                                                          ].includes(
                                                                                              action.updatedTime,
                                                                                          )
                                                                                        ? 'N/A'
                                                                                        : [
                                                                                              '',
                                                                                          ].includes(
                                                                                              action.updatedTime,
                                                                                          )
                                                                                        ? ''
                                                                                        : format(
                                                                                              new Date(
                                                                                                  action.updatedTime,
                                                                                              ),
                                                                                              'h:mm',
                                                                                          )
                                                                                }
                                                                                inputPlaceholder={
                                                                                    [
                                                                                        null,
                                                                                        '',
                                                                                    ].includes(
                                                                                        action.updatedTime,
                                                                                    )
                                                                                        ? ''
                                                                                        : format(
                                                                                              new Date(
                                                                                                  action.updatedTime,
                                                                                              ),
                                                                                              'h:mm',
                                                                                          )
                                                                                }
                                                                                iconType={
                                                                                    action.statusMsg ===
                                                                                    'COMPLETED'
                                                                                        ? ''
                                                                                        : 'DropdownArrow'
                                                                                }
                                                                                disabled={
                                                                                    action.statusMsg ===
                                                                                    'COMPLETED'
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                prefilled={
                                                                                    action.statusMsg ===
                                                                                    'COMPLETED'
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                errorMessage={
                                                                                    errors?.timeOfAction
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Dropdown>
                                                                </div>
                                                            ) : (
                                                                <DateField
                                                                    prefilled={
                                                                        action.statusMsg ===
                                                                        'COMPLETED'
                                                                    }
                                                                    filterRangeFn={
                                                                        action.keyID ===
                                                                        'REPAYMENT_SCHEDULE'
                                                                            ? isNotInconsistentDate
                                                                            : isValidRange
                                                                    }
                                                                    disabledCursorIcon={
                                                                        action.statusMsg ===
                                                                        'COMPLETED'
                                                                    }
                                                                    dateValue={
                                                                        inputs.firstRepaymentDate ===
                                                                        null
                                                                            ? null
                                                                            : new Date(
                                                                                  inputs.firstRepaymentDate,
                                                                              )
                                                                    }
                                                                    name="firstRepaymentDate"
                                                                    handleDateChange={(
                                                                        name,
                                                                        date,
                                                                    ) =>
                                                                        handleDateChange(
                                                                            name,
                                                                            date,
                                                                        )
                                                                    }
                                                                    setHandleDateChange={name =>
                                                                        setHandleDateChange(
                                                                            name,
                                                                        )
                                                                    }
                                                                    placeholder={
                                                                        JSON.stringify(
                                                                            action.updatedTime,
                                                                        ) !==
                                                                        'null' ? (
                                                                            <div className="APAMInputAutoFilled">
                                                                                <p>
                                                                                    First
                                                                                    Repayment
                                                                                    Date
                                                                                </p>
                                                                                <span>
                                                                                    {format(
                                                                                        new Date(
                                                                                            action.firstRepayment,
                                                                                        ),
                                                                                        'd MMM yyyy',
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            'First Repayment Date'
                                                                        )
                                                                    }
                                                                    openCalendarHandler={() =>
                                                                        action.statusMsg ===
                                                                        'COMPLETED'
                                                                            ? null
                                                                            : setShowCalendar(
                                                                                  2,
                                                                              )
                                                                    }
                                                                    openCalendar={
                                                                        showCalendar ===
                                                                        2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    closeCalendarHandler={() =>
                                                                        setShowCalendar(
                                                                            null,
                                                                        )
                                                                    }
                                                                    caretStyle="AdminPaymentPlanCaretStyle"
                                                                    maxDateType="future dates included"
                                                                    errorMessage={
                                                                        errors.firstRepaymentDate
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {(action.keyID ===
                                                'UPFRONT_DEPOSIT' ||
                                                action.keyID === 'BUYOUT' ||
                                                action.keyID ===
                                                    'PROVIDER_PAYMENT') && (
                                                <div
                                                    style={{
                                                        marginTop: '16px',
                                                    }}
                                                >
                                                    <CostField
                                                        name={
                                                            action.keyID ===
                                                            'UPFRONT_DEPOSIT'
                                                                ? 'upfrontDepositTotalAmount'
                                                                : action.keyID ===
                                                                  'BUYOUT'
                                                                ? 'buyoutTotalAmount'
                                                                : 'providerPaymentTotalAmount'
                                                        }
                                                        currencyCode={'₦'}
                                                        value={
                                                            action.keyID ===
                                                            'UPFRONT_DEPOSIT'
                                                                ? inputs?.upfrontDepositTotalAmount
                                                                : action.keyID ===
                                                                  'BUYOUT'
                                                                ? inputs?.buyoutTotalAmount
                                                                : inputs?.providerPaymentTotalAmount
                                                        }
                                                        inputWidth="100%"
                                                        fullWidth={false}
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        errorMessage={
                                                            action.keyID ===
                                                            'UPFRONT_DEPOSIT'
                                                                ? errors?.upfrontDepositTotalAmount
                                                                : action.keyID ===
                                                                  'BUYOUT'
                                                                ? errors?.buyoutTotalAmount
                                                                : errors?.providerPaymentTotalAmount
                                                        }
                                                        noFloat
                                                        marginBottom="10px"
                                                        selectWidth="90px"
                                                        prefixDisabled={true}
                                                        cssStyle={{
                                                            position:
                                                                'relative',
                                                            top: '6px',
                                                            marginLeft: '-50px',
                                                        }}
                                                        floatTitlePositionLeft={
                                                            '40px'
                                                        }
                                                        title="Total Amount"
                                                        zIndex={true}
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {/* handle recording payment for outright sale */}
                                            {action.keyID ===
                                                'MARK_A_PAYMENT' && (
                                                <div className="RecordRepayment__PaymentMethodWrapper">
                                                    <SelectField
                                                        selectWidth="224px"
                                                        name="repaymentMethod"
                                                        type="approvedModal"
                                                        initialOption={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? `${action.methodOfPayment}`
                                                                : 'Payment Method'
                                                        }
                                                        values={paymentMethods.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        withCheckBox={true}
                                                        optionStyle={{
                                                            width: '100%',
                                                            marginTop: '6px',
                                                            marginBottom: '6px',
                                                        }}
                                                        value={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? action?.methodOfPayment
                                                                : inputs?.repaymentMethod
                                                        }
                                                        currentSelected={
                                                            inputs.repaymentMethod
                                                        }
                                                        selectedValue={
                                                            inputs.repaymentMethod
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        errorMessage={
                                                            errors.repaymentMethod
                                                        }
                                                        marginRight="10px"
                                                    />

                                                    <CostField
                                                        name="outrightSaleTotalAmount"
                                                        currencyCode={'₦'}
                                                        value={
                                                            inputs?.outrightSaleTotalAmount
                                                        }
                                                        inputWidth="224px"
                                                        fullWidth={false}
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        errorMessage={
                                                            errors?.outrightSaleTotalAmount
                                                        }
                                                        noFloat
                                                        marginBottom="10px"
                                                        selectWidth="90px"
                                                        prefixDisabled={true}
                                                        cssStyle={{
                                                            position:
                                                                'relative',
                                                            top: '6px',
                                                            marginLeft: '-35px',
                                                            marginTop: '0',
                                                        }}
                                                        floatTitlePositionLeft={
                                                            '40px'
                                                        }
                                                        title="Total Amount"
                                                        zIndex={true}
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {/* select payment method- for recording a repayment */}
                                            {action.keyID ===
                                                'RECORD_PAYMENT' && (
                                                <div className="RecordRepayment__PaymentMethodWrapper">
                                                    <SelectField
                                                        selectWidth="224px"
                                                        name="repaymentMethod"
                                                        type="approvedModal"
                                                        initialOption={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? `${action.methodOfPayment}`
                                                                : 'Select Repayment Method'
                                                        }
                                                        values={paymentMethodData.map(
                                                            option => ({
                                                                value: option,
                                                            }),
                                                        )}
                                                        dropdownPositionRelative
                                                        withCheckBox={true}
                                                        optionStyle={{
                                                            width: '100%',
                                                            marginTop: '6px',
                                                            marginBottom: '6px',
                                                        }}
                                                        value={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? action?.methodOfPayment
                                                                : inputs?.repaymentMethod
                                                        }
                                                        currentSelected={
                                                            inputs.repaymentMethod
                                                        }
                                                        selectedValue={
                                                            inputs.repaymentMethod
                                                        }
                                                        handleChange={
                                                            handleSelectChange
                                                        }
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        errorMessage={
                                                            errors.repaymentMethod
                                                        }
                                                        marginRight="10px"
                                                    />

                                                    <CostField
                                                        name="recordRepaymentTotalAmount"
                                                        currencyCode={'₦'}
                                                        value={
                                                            inputs?.recordRepaymentTotalAmount
                                                        }
                                                        inputWidth="224px"
                                                        fullWidth={false}
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        errorMessage={
                                                            errors?.recordRepaymentTotalAmount
                                                        }
                                                        noFloat
                                                        marginBottom="10px"
                                                        selectWidth="90px"
                                                        prefixDisabled={true}
                                                        cssStyle={{
                                                            position:
                                                                'relative',
                                                            top: '6px',
                                                            marginLeft: '-35px',
                                                        }}
                                                        floatTitlePositionLeft={
                                                            '40px'
                                                        }
                                                        title="Total Amount"
                                                        zIndex={true}
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {action.keyID ===
                                                'SOLUTION_INSTALLED' && (
                                                <div>
                                                    <p className="APAActionText">
                                                        {action.uploadText !==
                                                            'None' &&
                                                            action.uploadText}
                                                    </p>
                                                    <InputFields
                                                        title="Google Drive Link"
                                                        inputWidth="100%"
                                                        name="installedSolutionEvidenceDriveLink"
                                                        type="text"
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        errorMessage={
                                                            errors?.installedSolutionEvidenceDriveLink
                                                        }
                                                        value={
                                                            inputs.installedSolutionEvidenceDriveLink !==
                                                            ''
                                                                ? inputs.installedSolutionEvidenceDriveLink
                                                                : action.actionEvidence !==
                                                                  null
                                                                ? action.actionEvidence
                                                                : ''
                                                        }
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            )}
                                            {/* upload file- for all actions except starting/viewing a repayment schedule */}
                                            {(![
                                                'REPAYMENT_SCHEDULE',
                                                'REPAYMENT_METHOD_SETUP',
                                                'SOLUTION_INSTALLED',
                                            ].includes(action.keyID) ||
                                                showUploadStandingOrder ||
                                                (action.actionEvidence !=
                                                    null &&
                                                    action.keyID ===
                                                        'REPAYMENT_METHOD_SETUP')) && (
                                                <div>
                                                    <p className="APAActionText">
                                                        {action.uploadText !==
                                                            'None' &&
                                                            action.uploadText}
                                                    </p>
                                                    {/* allow user to see action evidence if it exists */}
                                                    {action.statusMsg ===
                                                        'COMPLETED' && (
                                                        <div className="APAViewUploadedFile">
                                                            <img
                                                                src={
                                                                    SuccessUpload
                                                                }
                                                                className="UploadLogo"
                                                                alt="success logo"
                                                                style={{
                                                                    marginBottom:
                                                                        '14px',
                                                                }}
                                                            />
                                                            {/* recommended workaround regarding backend not returning the name of the file. Using evidence.(format) until they do */}
                                                            {action.actionEvidence ? (
                                                                <p>
                                                                    evidence.
                                                                    {evidenceFormat(
                                                                        action.actionEvidence,
                                                                    ).includes(
                                                                        'jpg',
                                                                    )
                                                                        ? 'jpg'
                                                                        : evidenceFormat(
                                                                              action.actionEvidence,
                                                                          ).includes(
                                                                              'jpeg',
                                                                          )
                                                                        ? 'jpeg'
                                                                        : evidenceFormat(
                                                                              action.actionEvidence,
                                                                          ).includes(
                                                                              'png',
                                                                          )
                                                                        ? 'png'
                                                                        : evidenceFormat(
                                                                              action.actionEvidence,
                                                                          ).includes(
                                                                              'pdf',
                                                                          )
                                                                        ? 'pdf'
                                                                        : 'file'}
                                                                </p>
                                                            ) : (
                                                                'Evidence of this action was uploaded.'
                                                            )}
                                                            {action.actionEvidence && (
                                                                <a
                                                                    href={
                                                                        action?.actionEvidence
                                                                    }
                                                                    download={getFileNameFromUrl(
                                                                        action?.actionEvidence,
                                                                    )}
                                                                    target="_blank"
                                                                >
                                                                    View
                                                                    uploaded
                                                                    file
                                                                </a>
                                                            )}
                                                        </div>
                                                    )}
                                                    {action.status ===
                                                        'SHORT_PAID' && (
                                                        <div className="newPaymentWrapper">
                                                            <button
                                                                width="141px"
                                                                onClick={() =>
                                                                    setIsMultiplePartialPaymentMode(
                                                                        true,
                                                                    )
                                                                }
                                                                className="recordNewPayment"
                                                            >
                                                                <img
                                                                    src={
                                                                        plusBlueIcon
                                                                    }
                                                                    alt="plus-icon"
                                                                    style={{
                                                                        marginRight:
                                                                            '12.75px',
                                                                    }}
                                                                />
                                                                <span>
                                                                    Record
                                                                    another
                                                                    payment
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {/* if action evidence does not exist, user can act */}
                                                    {action.statusMsg ===
                                                        'PENDING' && (
                                                        <div className="APADraggerArea">
                                                            <Dragger
                                                                className="DraggerArea"
                                                                {...draggerprops}
                                                                beforeUpload={
                                                                    beforeUpload
                                                                }
                                                                showUploadList={
                                                                    true
                                                                }
                                                                fileList={
                                                                    successUpload
                                                                }
                                                                maxCount={1}
                                                                accept=".pdf,.jpg,.jpeg,.png"
                                                                customRequest={() => {}}
                                                            >
                                                                <p className="ant-upload-drag-icon">
                                                                    {uploading ? (
                                                                        <div className="SlideContainer">
                                                                            <p
                                                                                className="Slider"
                                                                                style={{
                                                                                    width: `${width}%`,
                                                                                }}
                                                                            ></p>
                                                                        </div>
                                                                    ) : successUpload >
                                                                      0 ? (
                                                                        <img
                                                                            src={
                                                                                SuccessUpload
                                                                            }
                                                                            className="UploadLogo"
                                                                            alt="success logo"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                UploadLogo
                                                                            }
                                                                            className="UploadLogo"
                                                                            alt="upload logo"
                                                                        />
                                                                    )}
                                                                </p>
                                                                <p className="UploadText">
                                                                    {uploading ? (
                                                                        <div>
                                                                            Uploading
                                                                            document...
                                                                        </div>
                                                                    ) : successUpload.length >
                                                                      0 ? (
                                                                        <>
                                                                            <p className="Upload">
                                                                                <u
                                                                                    className="Browse"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '20px',
                                                                                    }}
                                                                                >
                                                                                    Replace
                                                                                    document
                                                                                </u>
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            Upload
                                                                            file.
                                                                            Drag
                                                                            and
                                                                            drop
                                                                            or
                                                                            <u className="Browse">
                                                                                browse
                                                                            </u>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </Dragger>
                                                            {uploadError && (
                                                                <p className="ApprovedModalUploadError">
                                                                    Please
                                                                    upload
                                                                    evidence of
                                                                    this action.
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {/* to view/start a repayment schedule */}
                                            {action.keyID ===
                                                'REPAYMENT_SCHEDULE' && (
                                                <div className="APARSArea">
                                                    <div>
                                                        <h4>Plan Duration</h4>
                                                        {action.planDuration ? (
                                                            <span>
                                                                {
                                                                    action.planDuration
                                                                }{' '}
                                                                months
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Plan duration is
                                                                not yet
                                                                available
                                                            </span>
                                                        )}
                                                    </div>
                                                    <img
                                                        src={Lock}
                                                        alt="locked icon"
                                                    />
                                                </div>
                                            )}

                                            {/* to view the Plan ID of the Repayment Method Setup */}
                                            {(showPaystackSubscriptionCode ||
                                                action.methodSubscriptionCode) && (
                                                <div>
                                                    <p className="APAActionText">
                                                        Enter Proof
                                                    </p>
                                                    <InputFields
                                                        title="Paystack Subscription Code"
                                                        inputWidth="100%"
                                                        name="repaymentMethodSetupSubscriptionCode"
                                                        type="text"
                                                        handleChange={
                                                            handleInputChange
                                                        }
                                                        disabled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        prefilled={
                                                            action.statusMsg ===
                                                            'COMPLETED'
                                                                ? true
                                                                : false
                                                        }
                                                        errorMessage={
                                                            errors?.repaymentMethodSetupSubscriptionCode
                                                        }
                                                        value={
                                                            inputs.repaymentMethodSetupSubscriptionCode !==
                                                            ''
                                                                ? inputs.repaymentMethodSetupSubscriptionCode
                                                                : action.methodSubscriptionCode !==
                                                                  null
                                                                ? action.methodSubscriptionCode
                                                                : ''
                                                        }
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            )}

                                            {/*close payment plan*/}
                                            {action.keyID === 'CLOSE_PLAN' && (
                                                <div
                                                    style={{ marginBottom: 60 }}
                                                >
                                                    <TextArea
                                                        width="464px"
                                                        height="146px"
                                                        name="closureReason"
                                                        placeholder="Enter Reason"
                                                        rows={10}
                                                        maxHeight={193}
                                                        value={
                                                            inputs.closureReason
                                                        }
                                                        handleChange={
                                                            handleClosureInputChange
                                                        }
                                                        errorMessage={
                                                            errors.closureReason
                                                        }
                                                    />
                                                </div>
                                            )}

                                            {/* action button */}
                                            <div
                                                style={{ textAlign: 'center' }}
                                            >
                                                {action.statusMsg ===
                                                    'COMPLETED' &&
                                                    action.keyID ===
                                                        'SUNFI_AGREEMENT' && (
                                                        <>
                                                            <div className="AgreementHistoryWrapper">
                                                                <p>
                                                                    Agreement
                                                                    history
                                                                </p>
                                                                {agreementHistory.length >
                                                                0 ? (
                                                                    <ul className="AgreementHistoryList">
                                                                        {agreementHistory.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="AgreementHistoryItem"
                                                                                >
                                                                                    <span>
                                                                                        {getFileNameFromUrl(
                                                                                            item.secure_url,
                                                                                        )}{' '}
                                                                                        -{' '}
                                                                                        {new Date(
                                                                                            item.date_created,
                                                                                        ).toLocaleDateString()}
                                                                                    </span>
                                                                                    <a
                                                                                        href={
                                                                                            item.secure_url
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        View
                                                                                    </a>
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                    </ul>
                                                                ) : (
                                                                    <p>
                                                                        No
                                                                        previous
                                                                        agreements
                                                                        found.
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <Button
                                                                btnWidth="176px"
                                                                btnHeight="56px"
                                                                type={
                                                                    'secondary'
                                                                }
                                                                handleClick={
                                                                    handleMultipleUpload
                                                                }
                                                                disabled={
                                                                    !fileValid
                                                                }
                                                            >
                                                                {loading ? (
                                                                    <InlineLoader />
                                                                ) : (
                                                                    'Upload New Agreement'
                                                                )}
                                                            </Button>
                                                        </>
                                                    )}

                                                <Button
                                                    btnWidth="160px"
                                                    btnHeight="56px"
                                                    btnBgColor="#004AAD"
                                                    btnTextColor="var(--white)"
                                                    marginLeft={'20px'}
                                                    handleClick={() =>
                                                        handleUpload(action)
                                                    }
                                                    disabled={!fileValid}
                                                    marginTop={
                                                        action.status ===
                                                            'SHORT_PAID' &&
                                                        '100px'
                                                    }
                                                >
                                                    {loading ? (
                                                        <InlineLoader />
                                                    ) : action.statusMsg ===
                                                      'PENDING' ? (
                                                        'Save'
                                                    ) : action.statusMsg ===
                                                      'Not Applicable' ? (
                                                        action.buttonText
                                                    ) : (
                                                        'Okay, Got it'
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

ApprovedModal.propTypes = {
    estimationId: PropTypes.string,
    estimationData: PropTypes.any,
    showCalendar: PropTypes.any,
    setShowCalendar: PropTypes.any,
    openClosePlanActionModal: PropTypes.func,
}

export default ApprovedModal
