import 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/consumerkyc.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useDynamicInformationV2Context } from '../../context'
import SelectedSolutionSection from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/components/SelectedSolution'
import SectionContainer from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/components/SectionContainer'
import {
    SectionLoader,
    SingleInputLoader,
} from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/components/Loaders'
import {
    getRequiredFieldsForDynamicKycModule,
    moduleDescriptionMapping,
    moduleTitleMapping,
} from '../../utils'
import FullName from '../../../DynamicInformation/components/Form/components/FullName'
import ContactInfo from '../../../DynamicInformation/components/Form/components/ContactInfo'
import Address from '../../../DynamicInformation/components/Form/components/Address'
import WhereYouWork from '../../../DynamicInformation/components/Form/components/WhereYouWork'
import EmploymentDetails from '../../../DynamicInformation/components/Form/components/EmploymentDetails'
import EmployeeData from '../../../DynamicInformation/components/Form/components/EmployeeData'
import PensionDetails from '../../../DynamicInformation/components/Form/components/PensionDetails'
import BusinessDetails from '../../../DynamicInformation/components/Form/components/BusinessDetails'
import IdCard from '../../../DynamicInformation/components/Form/components/IdCard'
import Bvn from '../../../DynamicInformation/components/Form/components/Bvn'
import BankStatementSection from '../../../DynamicInformation/components/Form/components/BankStatementSection'
import EmploymentType from '../FormFields/EmploymentType'
import Avatar from '../../../_components/Avatar'
import VerifyBVNModal from '../../../Verification/components/VerifyBVNModal'
import ActionModal from 'src/components/ActionModalV2'
import LoginModal from 'src/components/ConsumerLandingPageBody/LoginModal'
import { sseAuthValidateOtp } from 'src/api/shoppingExperience/auth/api'
import VerifyCacModal from '../../../DynamicInformation/components/VerifyCacModal'
import BusinessType from '../FormFields/BusinessType'
import DirectorFullName from '../FormFields/DirectorFullName'
import DirectorContactInfo from '../FormFields/DirectorContactInfo'
import { actionModalValues } from '../../utils'
import { getSectionCompletionData } from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/utils'
import { useAppContext } from 'src/appContext/AppContext'
import DisputePackageModal from 'src/pages/Consumer/SmartShoppingExperience/AccountSetupOverview/components/DisputePackageModal'

const MainContent = () => {
    const {
        kycData,
        goBackToSystem,
        inputs,
        offer,
        offerFetching,
        offerLoadingOrRefetchError,
        disablePageButtons,
        upfrontDepositPaid,
        kycModules,
        isOpen,
        handleToggle,
        modulesSectionFetching,
        modulesLoadingOrRefetchError,
        handleChangePackage,
        errors,
        setErrors,
        handleOnBlur,
        handleSelectOnBlur,
        handleChange,
        handleSelectChange,
        removeErrors,
        handleSameAddressOptionChange,
        isAdminUser,
        showRemoveFileModal,
        fileToBeRemoved,
        closeRemoveFileModal,
        closeRemoveFileModalAfterSuccess,
        showCalendar,
        setShowCalendar,
        setShowUploadModal,
        handleRemoveFile,
        employmentContract,
        setEmploymentContract,
        showUploadModal,
        handleCloseUploadModal,
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
        kycRefetch,
        isCustomerApproved,
        showBVNModal,
        openBVNModal,
        closeBVNModal,
        bvnVerifiedSuccessfully,
        handleBvnVerifyLater,
        bvnVerificationInProgress,
        setBvnVerificationInProgress,
        startMonoLoading,
        stopMonoLoading,
        connectToMonoLoading,
        kycStatus,
        useType,
        action,
        actionModalOpen,
        closeActionModal,
        handleActionPrimaryBtnClick,
        handleActionSecondaryBtnClick,
        actionModalPrimaryBtnLoading,
        actionModalSecondaryBtnLoading,
        showLoginModal,
        setShowLoginModal,
        closeLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        otpMethod,
        setOtpMethod,
        initiateOTPVerificationMutation,
        initiateOTPVerificationLoading,
        onVerifySuccess,
        modalToast,
        handleBusinessRegisteredChange,
        showCacVerifyModal,
        toggleShowCacVerifyModal,
        cacVerificationInProgress,
        setCacVerificationInProgress,
        cacHelpPayload,
        requiredKycModules,
        moa,
        setMoa,
        successMOAUpload,
        setSuccessMOAUpload,
        requiredKycSections,
        page,
        onAdminKycSaveSuccessCallback,
        postApprovalModalValues,
        showDisputePackageModal,
        setShowDisputePackageModal,
        handleDisputeBtnClick,
        onInputFocus,
        onSelectFocus,
        handleAutoSave,
        handlePersistEmploymentContract,
        uploadModalBtnLoading,
        handlePersistMoa,
    } = useDynamicInformationV2Context()

    const { finanicalInfoFormik } = useAppContext()

    const {
        actionModalActionType,
        actionModalFooterLeftContent,
        actionModalHeaderText,
        actionModalPrimaryBtnText,
        actionModalSecondaryBtnText,
        actionModalSubtitle,
        actionModalTitle,
    } = {
        ...actionModalValues(action, inputs),
        ...postApprovalModalValues(action),
    }

    const { isMobile } = useMediaQueries()

    const componentMap = {
        full_name: FullName,
        phone_number_email: ContactInfo,
        address: Address,
        employment_type: EmploymentType,
        work_info: WhereYouWork,
        employee_data: EmployeeData,
        employment_details: EmploymentDetails,
        pension_details: PensionDetails,
        business: BusinessDetails,
        avatar: Avatar,
        id_card: IdCard,
        bvn: Bvn,
        bank_statement: BankStatementSection,
        business_type: BusinessType,
        director_full_name: DirectorFullName,
        director_phone_number_email: DirectorContactInfo,
        director_bvn: Bvn,
    }

    return (
        <>
            {/* modals */}
            <VerifyBVNModal
                showModal={showBVNModal}
                closeBVNModal={closeBVNModal}
                storedBvn={inputs?.bvn}
                bvnVerifiedSuccessfully={bvnVerifiedSuccessfully}
                userType={useType}
                storedFirstName={
                    useType === 'RESIDENTIAL'
                        ? inputs?.firstName
                        : inputs?.directorFirstName
                }
                storedLastName={
                    useType === 'RESIDENTIAL'
                        ? inputs?.lastName
                        : inputs?.directorLastName
                }
                storedEmail={
                    useType === 'RESIDENTIAL'
                        ? inputs?.email
                        : inputs?.directorEmail
                }
                storedPhoneNumber={
                    useType === 'RESIDENTIAL'
                        ? inputs?.phone
                        : inputs?.directorPhone
                }
                handleBvnVerifyLater={handleBvnVerifyLater}
                setVerificationInProgress={setBvnVerificationInProgress}
            />

            <ActionModal
                actionModalOpen={actionModalOpen}
                headerText={actionModalHeaderText}
                actionType={actionModalActionType}
                footerLeftContent={actionModalFooterLeftContent}
                primaryBtnText={actionModalPrimaryBtnText}
                subTitle={actionModalSubtitle}
                title={actionModalTitle}
                secondaryBtnText={actionModalSecondaryBtnText}
                handlePrimaryBtnClick={handleActionPrimaryBtnClick}
                handleSecondaryBtnClick={handleActionSecondaryBtnClick}
                onCancel={closeActionModal}
                countdownTimerSeconds={10}
                onTimeEnd={handleActionPrimaryBtnClick}
                closable
                primaryBtnLoading={actionModalPrimaryBtnLoading}
                kycSecondaryButtonStopLoading
                secondaryBtnLoading={actionModalSecondaryBtnLoading}
                toastError={modalToast?.showToast}
                errorMessage={modalToast?.toastMessage}
                errorMessageType={modalToast?.messageType}
            />

            <LoginModal
                showModal={showLoginModal}
                setShowModal={setShowLoginModal}
                onCancel={closeLoginModal}
                loginModalContent={loginModalContent}
                setLoginModalContent={setLoginModalContent}
                inputs={loginInputs}
                otpMethod={otpMethod}
                setOtpMethod={setOtpMethod}
                initiateOTP={initiateOTPVerificationMutation.mutate}
                initiateOTPVerificationLoading={initiateOTPVerificationLoading}
                verifyApi={sseAuthValidateOtp}
                onVerifySuccess={onVerifySuccess}
                modalTitle={'Getting started on SunFi'}
                modalHeading={
                    'Where would you like to receive your verification code?'
                }
                modalSubHeading={
                    'Providing the code to your email or phone number helps us verify your account and get started with your KYC'
                }
                showCloseIcon={false}
            />

            <VerifyCacModal
                showModal={showCacVerifyModal}
                onCancel={toggleShowCacVerifyModal}
                cacHelpPayload={cacHelpPayload}
                setVerificationInProgress={setCacVerificationInProgress}
                inputs={inputs}
                isCustomerApproved={isCustomerApproved}
            />

            <VerifyCacModal
                showModal={showCacVerifyModal}
                onCancel={toggleShowCacVerifyModal}
                cacHelpPayload={cacHelpPayload}
                setVerificationInProgress={setCacVerificationInProgress}
                inputs={inputs}
                isCustomerApproved={isCustomerApproved}
            />

            <div className="SectionsContainer">
                {kycModules && kycModules?.length > 0
                    ? kycModules?.map((each, index) => (
                          <SectionContainer
                              key={each?.id}
                              title={moduleTitleMapping[each?.key]}
                              isOpen={
                                  isOpen[requiredKycModules?.indexOf(each?.key)]
                              }
                              onToggle={() =>
                                  handleToggle(
                                      requiredKycModules?.indexOf(each?.key),
                                  )
                              }
                              info={moduleDescriptionMapping[each?.key]}
                              displaySection={requiredKycModules?.includes(
                                  each?.key,
                              )}
                              indicatorData={getSectionCompletionData(
                                  useType,
                                  moduleTitleMapping[each?.key],
                                  inputs,
                                  {
                                      ...errors,
                                      ...finanicalInfoFormik.errors,
                                  },
                                  getRequiredFieldsForDynamicKycModule(
                                      each,
                                      inputs,
                                  ),
                              )}
                          >
                              {modulesSectionFetching ||
                              modulesLoadingOrRefetchError ? (
                                  <SectionLoader index={index} />
                              ) : (
                                  each?.section?.map((section, i) => {
                                      if (section?.required) {
                                          const Component =
                                              componentMap[section.key]
                                          return Component ? (
                                              <Component
                                                  key={i}
                                                  section={section}
                                                  inputs={inputs}
                                                  errors={errors}
                                                  setErrors={setErrors}
                                                  handleChange={handleChange}
                                                  handleSelectChange={
                                                      handleSelectChange
                                                  }
                                                  handleOnBlur={handleOnBlur}
                                                  handleSelectOnBlur={
                                                      handleSelectOnBlur
                                                  }
                                                  handleSameAddressOptionChange={
                                                      handleSameAddressOptionChange
                                                  }
                                                  isAdminUser={isAdminUser}
                                                  showRemoveFileModal={
                                                      showRemoveFileModal
                                                  }
                                                  fileToBeRemoved={
                                                      fileToBeRemoved
                                                  }
                                                  closeRemoveFileModal={
                                                      closeRemoveFileModal
                                                  }
                                                  closeRemoveFileModalAfterSuccess={
                                                      closeRemoveFileModalAfterSuccess
                                                  }
                                                  showCalendar={showCalendar}
                                                  setShowCalendar={
                                                      setShowCalendar
                                                  }
                                                  setShowUploadModal={
                                                      setShowUploadModal
                                                  }
                                                  handleRemoveFile={
                                                      handleRemoveFile
                                                  }
                                                  employmentContract={
                                                      employmentContract
                                                  }
                                                  setEmploymentContract={
                                                      setEmploymentContract
                                                  }
                                                  showUploadModal={
                                                      showUploadModal
                                                  }
                                                  handleCloseUploadModal={
                                                      handleCloseUploadModal
                                                  }
                                                  successEmploymentContractUpload={
                                                      successEmploymentContractUpload
                                                  }
                                                  setSuccessEmploymentContractUpload={
                                                      setSuccessEmploymentContractUpload
                                                  }
                                                  isCustomerApproved={
                                                      isCustomerApproved
                                                  }
                                                  kycStatus={kycStatus}
                                                  openBVNModal={openBVNModal}
                                                  bvnVerificationInProgress={
                                                      bvnVerificationInProgress
                                                  }
                                                  kycData={kycData}
                                                  removeErrors={removeErrors}
                                                  offer={offer}
                                                  page={page}
                                                  kycRefetch={kycRefetch}
                                                  showOnlyMono
                                                  startMonoLoading={
                                                      startMonoLoading
                                                  }
                                                  stopMonoLoading={
                                                      stopMonoLoading
                                                  }
                                                  connectToMonoLoading={
                                                      connectToMonoLoading
                                                  }
                                                  handleBusinessRegisteredChange={
                                                      handleBusinessRegisteredChange
                                                  }
                                                  toggleShowCacVerifyModal={
                                                      toggleShowCacVerifyModal
                                                  }
                                                  cacVerificationInProgress={
                                                      cacVerificationInProgress
                                                  }
                                                  useType={useType}
                                                  moa={moa}
                                                  setMoa={setMoa}
                                                  successMOAUpload={
                                                      successMOAUpload
                                                  }
                                                  setSuccessMOAUpload={
                                                      setSuccessMOAUpload
                                                  }
                                                  requiredKycSections={
                                                      requiredKycSections
                                                  }
                                                  onInputFocus={onInputFocus}
                                                  onSelectFocus={onSelectFocus}
                                                  handleAutoSave={
                                                      handleAutoSave
                                                  }
                                                  handlePersistEmploymentContract={
                                                      handlePersistEmploymentContract
                                                  }
                                                  uploadModalBtnLoading={
                                                      uploadModalBtnLoading
                                                  }
                                                  handlePersistMoa={
                                                      handlePersistMoa
                                                  }
                                              />
                                          ) : null
                                      }
                                  })
                              )}
                          </SectionContainer>
                      ))
                    : Array.from({ length: 3 }).map((_, index) => (
                          <SectionContainer
                              key={index}
                              title={<SingleInputLoader />}
                              isOpen={true}
                              onToggle={() => {}}
                              info=""
                              displayProgress={false}
                          >
                              <SectionLoader index={index} />
                          </SectionContainer>
                      ))}

                {isMobile && (
                    <SelectedSolutionSection
                        estimation={offer}
                        goBackToSystem={goBackToSystem}
                        useType={offer?.use_type}
                        handlePackageChange={handleChangePackage}
                        showPageHeading={false}
                        inputs={inputs}
                        estimationFetching={offerFetching}
                        estimationLoadingOrRefetchError={
                            offerLoadingOrRefetchError
                        }
                        disablePageButtons={disablePageButtons}
                        isCustomerApproved={offer?.isCustomerApproved}
                        upfrontDepositPaid={upfrontDepositPaid}
                        onAdminKycSaveSuccessCallback={
                            onAdminKycSaveSuccessCallback
                        }
                        requiredKycSections={requiredKycSections}
                        page={page}
                    />
                )}
            </div>

            <DisputePackageModal
                showModal={showDisputePackageModal}
                onCancel={() => {
                    setShowDisputePackageModal(false)
                }}
                onClick={handleDisputeBtnClick}
                title={'Change your package'}
                estimation_id={offer?.id}
                type="request-new-package-post-approval"
                postApprovalRequest={
                    isCustomerApproved &&
                    process.env.REACT_APP_FLAG_ENABLE_POST_APPROVAL_CHANGES ===
                        'true'
                }
            />
        </>
    )
}

export default MainContent
