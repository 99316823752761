import s from './energymatchmodal.module.scss'
import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import warningIcon from 'src/assets/images/warning-icon-secondary.svg'
import FormTitleBar from 'src/components/FormTitleBar'
import { TextArea } from 'src/components/InputFields'
import { useState } from 'react'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import useNotifyEnergyNeedsOverride from '../hooks/useNotifyEnergyNeedsOverride'
import Toast from 'src/components/Toast'

const EnergyMatchModal = ({
    energyMatchModalState,
    closeModal,
    openEnergyMatchModal,
    packageMatchError,
    estimationId = '',
    proceedToStepThree,
}) => {
    const [reason, setReason] = useState('')
    const [reasonErr, setReasonErr] = useState('')
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }
    const [modalToast, setModalToast] = useState(toastData)

    const onNotifySuccess = () => {
        proceedToStepThree(true)
        closeModal()
    }

    const {
        notifyEnergyNeedsOverrideMutate,
        notifyEnergyNeedsOverrideLoading,
    } = useNotifyEnergyNeedsOverride({
        setModalToast,
        resetToast,
        onSuccess: onNotifySuccess,
    })

    const onCancel = () => {
        setReason('')
        setReasonErr('')
        closeModal()
    }

    const handleChange = e => {
        const { value } = e.target
        setReason(value)
    }

    const handleOverride = () => {
        if (reason === '') {
            setReasonErr('Please enter your reason')
        } else {
            setReasonErr('')
            notifyEnergyNeedsOverrideMutate({
                estimationId,
                data: {
                    reason,
                },
            })
        }
    }

    return (
        <SecondaryModal
            showModal={energyMatchModalState?.show}
            onCancel={onCancel}
            modalWidth={
                energyMatchModalState?.action === 'warning' ? '459px' : '624px'
            }
            content={
                <>
                    {modalToast?.showToast && (
                        <Toast
                            message={modalToast?.toastMessage}
                            messageType={modalToast?.messageType}
                        />
                    )}
                    {energyMatchModalState?.action === 'warning' ? (
                        <div className={s.warningWrapper}>
                            <div className={s.warningIcon}>
                                <img
                                    src={warningIcon}
                                    alt="warning"
                                    role="presentation"
                                />
                            </div>
                            <h2>
                                Entered energy needs exceeds selected system
                                capacity
                            </h2>
                            <p>{packageMatchError}</p>
                            <div className={s.buttons}>
                                <ButtonComponent
                                    v2
                                    type="secondary"
                                    size="medium"
                                    handleClick={() => {
                                        openEnergyMatchModal('override')
                                    }}
                                >
                                    Continue with system
                                </ButtonComponent>
                                <ButtonComponent
                                    v2
                                    size="medium"
                                    handleClick={onCancel}
                                >
                                    Try another system
                                </ButtonComponent>
                            </div>
                        </div>
                    ) : (
                        <div className={s.overrideWrapper}>
                            <FormTitleBar
                                title={'Override system capacity limit'}
                                subtitle={
                                    'Enter a reason for overriding system capacity limit'
                                }
                            />
                            <TextArea
                                title={'Enter reason'}
                                height="140px"
                                resize
                                name="note"
                                inputValue={reason}
                                value={reason}
                                handleChange={handleChange}
                                rows={10}
                                errorMessage={reasonErr}
                            />
                            <VerticalSpacer top={61} />
                            <div className={s.buttons}>
                                <ButtonComponent
                                    v2
                                    handleClick={handleOverride}
                                    loading={notifyEnergyNeedsOverrideLoading}
                                >
                                    Confirm
                                </ButtonComponent>
                            </div>
                        </div>
                    )}
                </>
            }
        />
    )
}

EnergyMatchModal.propTypes = {
    energyMatchModalState: PropTypes.object,
    closeModal: PropTypes.func,
    openEnergyMatchModal: PropTypes.func,
    packageMatchError: PropTypes.string,
    estimationId: PropTypes.string,
    proceedToStepThree: PropTypes.func,
}

export default EnergyMatchModal
