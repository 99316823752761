/* eslint-disable react/prop-types */
import FormTitleBar from '../../../../../components/FormTitleBar'
import { InputFields } from '../../../../../components/InputFields'
import { ActionSection } from '../../../../Onboarding'
import CloseIcon from '../../../../../assets/images/black-close-icon.svg'
import '../addvariable.scss'
import { isMobile } from 'src/utils/mediaQueries'

const StepOne = ({ nextHandler, closeModal, setData, data, type }) => {
    const isSmallMobile = window.innerWidth < 375

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    return (
        <div className="ACVAddWrapper">
            <div className="ACVAddTopWrapper">
                <span className="AWCloseIconWrapper">
                    <img
                        src={CloseIcon}
                        alt="close icon"
                        onClick={closeModal}
                        style={{
                            width: '27px',
                            marginTop: isMobile ? '24px' : 0,
                        }}
                    />
                </span>
                <FormTitleBar
                    title={
                        type === 'addVariable'
                            ? 'Set Variable Details'
                            : 'Update Variable Details'
                    }
                    subtitle="Please enter the details of the variables"
                    titleFontSize="20px"
                    alignLeft={isMobile}
                    marginBottom={isMobile && '0'}
                />
            </div>
            <div style={{ marginTop: 32 }}>
                <div className="ACVAddInputMainWrapper">
                    <div className="ACVInputWrapper">
                        <InputFields
                            inputWidth={isSmallMobile ? '180px' : '224px'}
                            title="Monthly Rate Jump"
                            type="text"
                            value={data?.monthly_interest_jump}
                            name="monthly_interest_jump"
                            handleChange={handleChange}
                        />
                        <div style={{ marginLeft: 15 }}>
                            <InputFields
                                inputWidth={isSmallMobile ? '180px' : '224px'}
                                title="Lowest Tenure (Months)"
                                type="text"
                                value={data?.lowest_tenure}
                                name="lowest_tenure"
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="ACVAddInputMainWrapper">
                    <div className="ACVInputWrapper">
                        <InputFields
                            inputWidth={isSmallMobile ? '180px' : '224px'}
                            title="No. of payment plans"
                            type="text"
                            value={data?.payment_plans_count}
                            name="payment_plans_count"
                            handleChange={handleChange}
                        />
                        <div style={{ marginLeft: 15 }}>
                            <InputFields
                                inputWidth={isSmallMobile ? '180px' : '224px'}
                                title="Tenure Increment"
                                type="text"
                                value={data?.tenure_increment}
                                name="tenure_increment"
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="ACVAddInputMainWrapper">
                    <div className="ACVInputWrapper">
                        <InputFields
                            inputWidth={isSmallMobile ? '180px' : '224px'}
                            title={`Start Decl. ${
                                isMobile ? 'Bal.' : 'Balance'
                            } Rate (%)`}
                            type="text"
                            value={data?.lowest_tenure_annual_interest}
                            name="lowest_tenure_annual_interest"
                            handleChange={handleChange}
                        />
                        <div style={{ marginLeft: 15 }}>
                            <InputFields
                                inputWidth={isSmallMobile ? '180px' : '224px'}
                                title="Cost of Capital (%)"
                                type="text"
                                value={data?.cost_of_capital}
                                name="cost_of_capital"
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                {process.env
                    .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                'true' ? (
                    <div className="ACVAddInputMainWrapper">
                        <div className="ACVInputWrapper">
                            <InputFields
                                inputWidth={isSmallMobile ? '180px' : '224px'}
                                title={`Insurance Fee (%)`}
                                type="number"
                                value={data?.insurance}
                                name="insurance"
                                disabled
                            />
                            <div style={{ marginLeft: 15 }}>
                                <InputFields
                                    inputWidth={
                                        isSmallMobile ? '180px' : '224px'
                                    }
                                    title="SunFi Insurance Fee (%)"
                                    type="number"
                                    value={data?.sunfi_insurance}
                                    name="sunfi_insurance"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            <ActionSection
                stage={1}
                nextHandler={nextHandler}
                margin="30px 0px 0px"
            />
        </div>
    )
}

export default StepOne
