import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'
import afterCareBadge from 'src/assets/images/AftercareRatingBadge.png'
import fallbackImage from 'src/assets/images/fallback.svg'
import { ReactComponent as ShareIcon } from 'src/assets/images/share-icon.svg'
import filterIcon from 'src/assets/images/filter-blue.svg'
import { ConsumerButton } from 'src/components/Button'
import SystemNav from './components/SystemNav'

import {
    overviewData,
    tabLabels,
    recommendedComboData,
    customerTypeRemapping,
    paymentPlansFormatter,
    actionModalValues,
} from './data'
import { scrollToById } from 'src/utils/scrollToById'
import RepaymentOptions from './components/RepaymentOptions'
import PackageDescription from './components/PackageDescription'
import PackageSteps from './components/PackageSteps'
import SavingsChart from './components/SavingsChart'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerFooter from 'src/components/ConsumerFooter'
import FrequentlyAskedQuestions from 'src/components/Faq'
import FilterRecommendations from '../SolarSystemsListings/FilterRecommendations'
import GettingStarted from 'src/components/GettingStarted'
import {
    getRecommendedSystemAuthUser,
    getSSERecommendedSystem,
    getSystemAfterCareRating,
    savePotentialCustomerApi,
    selectNewSystemAuthUser,
} from 'src/api/shoppingExperience/view-system'
import { useAppContext } from 'src/appContext/AppContext'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { decode, decrypt, encode, encrypt } from 'src/utils/cryptography'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { formatPhoneNumber } from 'src/utils/formatting'

import './viewsystem.scss'
import SeoComponent from 'src/components/Seo'
import ViewSystemSkeleton from './components/ViewSystemSkeleton'
import {
    getPromoPackageDetailsApi,
    selectNewSystemPromoAuthUser,
} from 'src/api/shoppingExperience/promo'
import {
    decodeUserInfo,
    encodeUserInfo,
    setToken,
    updateUserInfo,
    isAuthenticated,
} from 'src/utils/auth'
import { parseHTML } from 'src/utils/parseHTML'
import { useOnScreenV2 } from 'src/utils/Hooks/useOnScreen'
import NairaSymbol from 'src/components/NairaSymbol'
import ChatCTA from 'src/components/ChatCTA'
import CookieNotification from 'src/components/CookieNotificiation'
import NPSRatingModal from 'src/components/NPSRatingModal'
import { useUpdateNPSValues } from 'src/utils/handleNPSValues'
import { submitEnergyAssessmentRatingApi } from 'src/api/shoppingExperience/npsRating'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { promoBanners } from '../Home/PromoCarousel/data'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import AppliancesPreview from 'src/components/AppliancesPreview'
import UpdateNeeds from 'src/components/UpdateNeeds'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import {
    matchEnergySolutionAppliances,
    retrieveEnergySolutionDetails,
} from 'src/api/shoppingExperience/energy-profile/api'
import BuildCustomsModalContent from '../SolarSystemsListingsV2/components/BuildCustomsModalContent'
import CustomToolTip from 'src/components/CustomToolTip'
import { getSystemPageEvents } from 'src/utils/mp-event-tracker/get-system-page'
import omit from 'lodash/omit'
import map from 'lodash/map'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import {
    pageTitle,
    pageTracker,
    handleCustomBuildViewCountTracking,
    getStartedTrackingForCustomBuild,
    secondaryModalActions,
} from './utils'
import SharedForm from '../SolarSystemsListingsV2/components/SharedForm'
import useShortenLink from 'src/utils/Hooks/useShortenLink'
import SharePackageModal from './components/SharePackageModal'
import ExploreOtherOptionsModal from './components/ExploreOtherOptionsModal'
import { useGetEnergyProfileSolutions } from 'src/api/shoppingExperience/energy-profile/queries'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { ReactComponent as Star } from 'src/assets/images/star.svg'
import { ReactComponent as StarFilled } from 'src/assets/images/star-yellow.svg'
import useGetNewSystemForReturningUser from '../SolarSystemsListingsV2/hooks/useGetNewSystemForReturningUser'
import BackNav from 'src/components/BackNav'
import SystemDetailsCard from './components/SystemDetailsCard'
import TextDropdown from 'src/components/TextDropdown'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import PackageImagesCarousel from './components/PackageImagesCarousel'
import useViewSystem from './hooks/useViewSystem'
import ActionModal from 'src/components/ActionModalV2'
import LoginModal from 'src/components/ConsumerLandingPageBody/LoginModal'
import useOnePageKYCSignup from './hooks/useOnePageKYCSignup'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'
import getConsumerKYCHome from 'src/utils/getConsumerKYCHome'
import AfterCareRatingModalContent from './components/AfterCareRatingModalContent'
import { initiateReturningUserSignup } from 'src/api/shoppingExperience/get-system'

const ViewSystem = () => {
    const queryParams = new URLSearchParams(window.location?.search)
    const isUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'
    const shouldPersistUserInfo =
        process.env.REACT_APP_FLAG_PERSIST_USER_GET_STARTED_INFO === 'true'
    const pageUrlName = window.location.pathname
    const isSharedPackage = pageUrlName.includes('/shared')
    const [activeTab, setActiveTab] = useState('VS-Overview')
    const [targetRef, inViewPort] = useOnScreenV2(0)
    const history = useHistory()
    const location = useLocation()
    const [, setShowPackages] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showBuildCustomModal, setShowBuildCustomModal] = useState({
        open: false,
        type: 'cancel',
    })
    const [checked, setChecked] = useState(false)
    const [selectedDifferentPaymentPlan, setSelectedDifferentPaymentPlan] =
        useState(false)
    const payment_model =
        history?.location.state?.payment_model ||
        (queryParams.get('payment_model')?.length > 0
            ? decode(queryParams.get('payment_model'))
            : null)
    const { formResponse } = useAppContext()
    const [payload, setPayload] = useState({})
    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const user_id =
        decryptedData?.contactType === 'email'
            ? decryptedData?.email
            : decryptedData?.phone
    const useType = decryptedData?.useType
    const [userId, setUserId] = useState(user_id)
    const [systemDetails, setSystemsDetails] = useState({})
    const [showToastError, setShowToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [repaymentPlans, setRepaymentPlans] = useState([])
    const [paymentModel, setPaymentModel] = useState('')
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [showNPSRatingModal, setShowNPSRatingModal] = useState(false)
    const [showAppliancePreview, setShowAppliancePreview] = useState(false)
    const [showApplianceEditModal, setShowApplianceEditModal] = useState(false)
    const [showSystemConifrmation, setShowSystemConfirmation] = useState(false)
    const [isSystemOverloaded, setIsSystemOverloaded] = useState(false)
    const [energyNeeds, setEnergyNeeds] = useState([])
    const [systemAppliances, setSystemAppliances] = useState([])
    const [changedAppliances, setChangedAppliances] = useState(false)
    const [undidChanges, setUndidChange] = useState(false)
    const [showSharePackageModal, setShowSharePackageModal] = useState(false)
    const [sharePackageLink, setSharePackageLink] = useState('')
    const [showExploreModal, setShowExploreModal] = useState(false)
    const [appliancesBreakdownAction, setAppliancesBreakdownAction] =
        useState('selecting-system') // selecting-system | preview-what-it-powers
    const [paymentPlans, setPaymentPlans] = useState([])
    const [secondaryModalAction, setSecondaryModalAction] = useState('')
    const [showSecondaryModal, setShowSecondaryModal] = useState(false)
    const [afterCareRatingDetails, setAfterCareRatingDetails] = useState()
    const [activePaymentModel, setActivePaymentModel] = useState(payment_model)
    const { isMobile } = useMediaQueries()

    const { id } = useParams()

    const {
        modalAction,
        actionModalOpen,
        openActionModal,
        closeActionModal,
        showLoginModal,
        loginModalContent,
        setLoginModalContent,
        loginInputs,
        setLoginInputs,
        otpMethod,
        setOtpMethod,
        actionModalHandler,
        actionModalToast,
        actionModalPrimaryBtnLoading,
        actionModalSecondaryHandler,
        setReturningCustomerId,
        canCloseActionModal,
        onLoginSuccess,
        initialDetailsNotProvided,
        showSharedPackageModal,
        setShowSharedPackageModal,
        openLoginNoDetailsProvided,
        closeLoginNoDetailsProvided,
        signupPayload,
        signUpInputs,
        setSignUpInputs,
        customerType,
        isPromotional,
        showLoginModalCloseButton,
        hasDiscardedDeal,
        setHasDiscardedDeal,
    } = useViewSystem({ energyNeeds, activePaymentModel })

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(modalAction)

    const isAuthenticatedUser = location?.state?.isAuthenticatedUser ?? false
    const estimation_id = location?.state?.estimation_id ?? null
    const action = location?.state?.action ?? null
    const auth_user_id = location?.state?.auth_user_id ?? null
    const page = location?.state?.page ?? null
    const returnToKyc = location?.state?.returnToKyc ?? false
    const viewingFromWorkspace = location?.state?.returnToWorkspace ?? false
    const isPromoPage = page === 'promo'
    const clusterCode = location?.state?.clusterCode
    const recommendedCombo = location?.state?.recommendedCombo
    const isAllSystemsString =
        queryParams.get('isAllSystems')?.length > 0
            ? decode(queryParams.get('isAllSystems'))
            : null
    const isAllSystems =
        location.state?.isAllSystems || isAllSystemsString === 'true'
    const isResidential = location.state?.customer_type === 'RESIDENTIAL'
    const isBusiness = location.state?.customer_type === 'BUSINESS'
    const energyProfileId =
        location.state?.profile_id ||
        (queryParams.get('profile_id')?.length > 0
            ? decode(queryParams.get('profile_id'))
            : '')
    const isProfile =
        location.state?.profile_id?.length > 0 ||
        decode(queryParams.get('profile_id'))?.length > 0
    const userInfo = decodeUserInfo()

    const sharedPackageName =
        queryParams.get('name')?.length > 0
            ? decode(queryParams.get('name'))
            : null
    const onboardingType =
        history?.location?.state?.onboardingType ||
        (queryParams.get('onboardingType')?.length > 0
            ? decode(queryParams.get('onboardingType'))
            : null)
    const userIdValue = history?.location?.state?.userId

    const appliances =
        history?.location?.state?.appliances ||
        (queryParams.get('appliances')?.length > 0
            ? JSON.parse(decode(queryParams.get('appliances')))
            : null)
    const { npsState, updateNPSAndResetValues } = useUpdateNPSValues(
        'energyAssessmentNPSDisplayed',
    )
    const isDEPConsumer = userInfo?.isDEPConsumer === 'true'
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const promoDetails =
        localStorage.getItem('promoDetails') &&
        JSON.parse(decrypt(localStorage.getItem('promoDetails')))
    const promoType = promoDetails?.promoType
    const systemsViewedCount = useSelector(
        state => state.consumerBuildCustomSystem.systemsViewedCount,
    )

    const previousPaymentModel =
        isAuthenticatedUser && history.location?.state?.previous_payment_model

    const isClosedCluster = location?.state?.isClosedCluster
    const isPromoCluster = location?.state?.isPromoCluster
    const isSharedCluster = location?.state?.isSharedCluster
    const isNotSharedPromoCluster = isPromoCluster && !isSharedCluster
    const abTestInBoundRequest =
        history?.location?.state?.abTestInBoundRequest ?? false

    const { shortenLinkMutate, shortenLinkLoading } = useShortenLink(link =>
        setSharePackageLink(link),
    )

    const showAfterCareRating =
        process.env.REACT_APP_FLAG_SHOW_AFTER_CARE_RATING === 'true' &&
        afterCareRatingDetails

    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )
    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'
    const showPackageImagesCarousel =
        process.env.REACT_APP_FLAG_SHOW_PACKAGE_IMAGE_UPLOAD === 'true'
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [modalToast, setModalToast] = useState(toastData)
    const showPackageDetailsPageUpdates =
        process.env.REACT_APP_FLAG_SHOW_PACKAGE_DETAILS_PAGE_UPDATES === 'true'
    const isOnePageKYC = process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC === 'true'
    const solution_id =
        isAuthenticatedUser && history?.location?.state?.solution_id
    const usedOnePageKYC = !userInfo?.consumerKYCHome?.includes('overview')
    const usesDynamicKYC = isPromotional
    const consumerKycHome = getConsumerKYCHome(
        usedOnePageKYC,
        location?.state?.customer_type,
        usesDynamicKYC,
    )
    const showReturningCustomerV2 =
        process.env.REACT_APP_FLAG_RETURNING_CUSTOMERS_V2 === 'true'

    const {
        onePageKYCSignupMutate,
        onePageKYCSignupLoading,
        signUpModalToast,
        consumerKYCHome,
    } = useOnePageKYCSignup({
        onSuccess: data => {
            if (data?.estimation_id) {
                history.replace({
                    pathname: history.location?.pathname,
                    state: {
                        ...history.location?.state,
                        estimation_id: data.estimation_id,
                    },
                })
            }

            setShowAppliancePreview(false)
            setShowModal(true)
        },
        isPromotional: isPromotional,
    })

    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }

    const getNewSystemOnSuccess = () => {
        if (showReturningCustomerV2) {
            const newUserInfo = {
                usedOnePageKYC: true,
                customerType: customerType || useType,
                usesDynamicKYC: isPromotional,
            }
            updateUserInfo(newUserInfo)
            const updatedUserInfo = decodeUserInfo()
            const updatedKycHome = updatedUserInfo.consumerKYCHome
            history.push(updatedKycHome)
        } else {
            history.push(consumerKycHome)
        }
    }

    const getNewSystemOnError = errorMsg => {
        setModalToast({
            showToast: true,
            toastMessage: errorMsg,
            messageType: 'error-secondary',
        })
        resetToast()
    }

    const { getNewSystemMutate, getNewSystemLoading } =
        useGetNewSystemForReturningUser({
            onSuccess: getNewSystemOnSuccess,
            onError: getNewSystemOnError,
        })

    const handleChecked = () => {
        setChecked(!checked)
    }

    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
    }

    const closeSharedModal = () => {
        setShowSharedPackageModal(false)
    }

    const secondaryModalValues = secondaryModalActions(secondaryModalAction)

    useEffect(() => {
        if (isAllSystems) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('AASP')['viewPackageDetails'].action,
                consumerProfileEvents('AASP').label,
                consumerProfileEvents('AASP').category,
                ['MP', 'GA'],
                'event',
            )
        } else if (isResidential) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('RPP')['viewPackageDetails'].action,
                consumerProfileEvents('RPP').label,
                consumerProfileEvents('RPP').category,
                ['MP', 'GA'],
                'event',
            )
        } else if (isBusiness) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('BPP')['viewPackageDetails'].action,
                consumerProfileEvents('BPP').label,
                consumerProfileEvents('BPP').category,
                ['MP', 'GA'],
                'event',
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isPromoPage && recommendedCombo?.length > 0) {
            setActiveTab('VS-RecommendedCombo')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const viewingFromAccountSetup =
        isAuthenticatedUser && action === 'view_from_account_setup'
    const viewingFromOutrightSaleKYCForm =
        isAuthenticatedUser && action === 'view_from_outright_sale_kyc_form'
    const viewingFromOnePageKYC =
        isAuthenticatedUser && action === 'view_from_one_page_kyc_form'

    const handleAppliancePreview = () => {
        if (isAllSystems) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('AASP')['getSystem'].action,
                consumerProfileEvents('AASP').label,
                consumerProfileEvents('AASP').category,
                ['MP', 'GA'],
                'event',
            )
        } else if (isResidential) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('RPP')['getSystem'].action,
                consumerProfileEvents('RPP').label,
                consumerProfileEvents('RPP').category,
                ['MP', 'GA'],
                'event',
            )
        } else if (isBusiness) {
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('BPP')['getSystem'].action,
                consumerProfileEvents('BPP').label,
                consumerProfileEvents('BPP').category,
                ['MP', 'GA'],
                'event',
            )
        }

        if (shouldPersistUserInfo) {
            sessionStorage.removeItem('sunfiUserSSEGettingStartedPersist')
        }
        setShowAppliancePreview(true)
    }

    const undoPaymentOptionSelection = () => {
        setActivePaymentModel(location.state?.payment_model)
        setShowModal(false)
    }

    //get selected recommended system
    const getSSERecommendedSystemMutation = useMutation({
        mutationKey: ['selectedSSESystem'],
        mutationFn: payload => getSSERecommendedSystem(payload, id),
        onSuccess: data => {
            setSystemsDetails(data?.data?.data)
            setPaymentPlans(
                paymentPlansFormatter(data?.data?.data?.payment_plans),
            )
            setPaymentModel(data?.data?.data?.payment_model)
            setActivePaymentModel(data?.data?.data?.payment_model)
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // for profiles / all systems users
    const {
        refetch: fetchProfilesOrAllSystemsUserSystem,
        isFetching: ProfilesOrAllSystemsUserSystemFetching,
    } = useQuery(
        'fetch-system-for-profiles-or-auth-user',
        () => {
            return retrieveEnergySolutionDetails({
                id: id,
                payload: {
                    payment_model: activePaymentModel,
                    appliances: appliances ?? undefined,
                    onboardingType: onboardingType,
                    profile_id:
                        energyProfileId?.length > 0
                            ? energyProfileId
                            : undefined,
                },
            })
        },
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setSystemsDetails(data?.data?.data)
                setSystemAppliances(data.data.data.fit_appliances)
                setEnergyNeeds(data.data.data.fit_appliances)
                setPaymentPlans(
                    paymentPlansFormatter(data?.data?.data?.payment_plans),
                )
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useQuery(
        'get-system-aftercare-rating',
        () => getSystemAfterCareRating(id),
        {
            enabled: true,
            retry: false,
            onSuccess: data => {
                setAfterCareRatingDetails(data?.data?.data)
            },
        },
    )

    // set selected recommended system for auth user
    const { refetch: fetchAuthUserSystem, isFetching: authUserSystemFetching } =
        useQuery(
            'fetch-system-for-auth-user',
            () => getRecommendedSystemAuthUser(estimation_id, id),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setSystemsDetails(data?.data?.data)
                    setSystemAppliances(data.data.data.fit_appliances)
                    setEnergyNeeds(data.data.data.fit_appliances)
                    setPaymentPlans(
                        paymentPlansFormatter(data?.data?.data?.payment_plans),
                    )
                },
                onError: error => {
                    setShowToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //select a new system as an authenticated user
    const selectNewSystem = useMutation({
        mutationKey: ['select_new_system'],
        mutationFn: () =>
            selectNewSystemAuthUser(
                estimation_id,
                id,
                energyProfileId ?? '',
                energyNeeds?.length > 0 ? energyNeeds : undefined,
                isPromoPage
                    ? 'PROMO'
                    : isAllSystems
                    ? 'ALL_SOLUTIONS'
                    : isProfile
                    ? 'ENERGY_PROFILE'
                    : 'ENERGY_ASSESSMENT',
                activePaymentModel ?? '',
            ),
        onSuccess: () => {
            history.push({
                pathname: userInfo?.consumerKYCHome,
                state: {
                    payment_model: activePaymentModel,
                    onboardingType,
                },
            })
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    //select a new system as an authenticated promo user
    const selectNewPromoSystem = useMutation({
        mutationKey: ['select_new_promo_system'],
        mutationFn: () =>
            selectNewSystemPromoAuthUser(estimation_id, clusterCode, id),
        onSuccess: () => {
            history.push({
                pathname: userInfo?.consumerKYCHome,
                state: {
                    payment_model: activePaymentModel,
                    onboardingType,
                },
            })
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // persist consumer details and solution id in case of dropoff
    const savePotentialCustomer = useMutation({
        mutationKey: ['save-potential-customer'],
        mutationFn: phone =>
            savePotentialCustomerApi({
                selected_solution_id: id,
                ...(decryptedData?.contactType === 'email'
                    ? {
                          email: decryptedData?.email,
                      }
                    : {
                          phone_number:
                              isPromoPage || isPromoCluster
                                  ? formatPhoneNumber(phone)
                                  : decryptedData?.phone,
                      }),
            }),
        onSuccess: res => {
            const data = res?.data?.data

            const userInfo = { email: '', phone: '' }

            if (data.email) {
                userInfo.email = data.email

                if (decryptedData) {
                    decryptedData.email = userInfo.email
                }
            }

            if (data.phone_number) {
                userInfo.phone = data.phone_number

                if (decryptedData) {
                    decryptedData.phone = userInfo.phone
                }
            }

            setSignUpInputs(prev => ({
                ...prev,
                email: userInfo.email || prev.email,
                phone: userInfo.phone?.replace('+234', 0) || prev.phone,
            }))

            localStorage.setItem(
                'sunfiUserSSEGettingStartedInfo',
                encrypt(JSON.stringify({ ...decryptedData, ...userInfo })),
            )
        },
    })

    const saveUserAppliances = useMutation({
        mutationKey: ['match-appliances-to-energy-solution'],
        mutationFn: () =>
            matchEnergySolutionAppliances(id, {
                payment_model: activePaymentModel,
                appliances: energyNeeds,
            }),
        onSuccess: ({ data }) => {
            if (data) {
                if (data.data.package_match === true) {
                    setIsSystemOverloaded(false)
                } else {
                    setIsSystemOverloaded(true)
                }
                setShowApplianceEditModal(false)
                setShowSystemConfirmation(true)
            }
            return
        },
    })

    // get energy solution payment models
    const {
        getEnergyProfileSolutionsData,
        //    getEnergyProfileSolutionsFetching,
    } = useGetEnergyProfileSolutions({
        enabled: !!id,
        params: {
            solution_id: id,
        },
        onSuccess: data => {
            if (onboardingType === 'custom-build') {
                setSystemAppliances(data[0].fit_appliances)
                setEnergyNeeds(data[0].fit_appliances)
            }
        },
    })

    // post nps response
    const submitEnergyAssessmentRating = useMutation({
        mutationFn: () => {
            submitEnergyAssessmentRatingApi({
                user_id: userId,
                ...additionalPayload,
            })
        },
        onSuccess: () => {
            setShowNPSRatingModal(false)
            getStartedForNewSignups()
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const handleSubmitEnergyAssessmentRating = () =>
        submitEnergyAssessmentRating.mutate()

    const { isLoading: viewSystemFetching } = getSSERecommendedSystemMutation

    //get promo package details
    const { isFetching: promoPackageFetching, refetch: promoPackageRefetch } =
        useQuery(
            'get-promo-package-details',
            () => getPromoPackageDetailsApi(id, clusterCode),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setSystemsDetails(data?.data?.data?.package_info)
                    setRepaymentPlans(data?.data?.data?.repayment_plans)
                    setPaymentModel(data?.data?.data?.payment_model)
                    setPaymentPlans(
                        paymentPlansFormatter(
                            data?.data?.data?.repayment_plans,
                        ),
                    )
                },
                onError: error => {
                    setShowToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    useEffect(() => {
        if (isPromoPage) {
            promoPackageRefetch()
        } else if (isAuthenticatedUser) {
            if (
                onboardingType === 'profiles-or-all-systems' ||
                history?.location?.state?.returnToClusterWorkspace
            ) {
                if (activePaymentModel) {
                    fetchProfilesOrAllSystemsUserSystem()
                }
            } else {
                fetchAuthUserSystem()
            }
        } else if (onboardingType === 'profiles-or-all-systems') {
            if (activePaymentModel) {
                fetchProfilesOrAllSystemsUserSystem()
            }
        } else {
            setUserId(formResponse?.data?.user_id)
            const user_id = userId || userIdValue
            // return users to homepage if there's no userid
            if (user_id) {
                getSSERecommendedSystemMutation.mutate({
                    user_id,
                })
            } else {
                history.push('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePaymentModel])

    useEffect(() => {
        const fields = {
            appliances: energyNeeds,
            selected_solution_id: systemDetails.id,
            ...(!!energyProfileId && {
                energy_profile_id: energyProfileId,
            }),
            payment_model: activePaymentModel,
            customer_type: isPromoCluster
                ? location?.state?.customer_type
                : useType || customerType,
            flow: isAllSystems ? 'ALL_SOLUTIONS' : 'ENERGY_PROFILE',
        }
        setPayload(fields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energyNeeds, systemDetails, useType])

    const getStartedForNewSignups = () => {
        setShowNPSRatingModal(false)
        setShowModal(true)
        !isPromoPage && savePotentialCustomer.mutate()
    }

    const handlePromoDropoff = phone => {
        savePotentialCustomer.mutate(phone)
    }

    const handleEditApplianceModal = () => {
        if (!showApplianceEditModal) {
            setEnergyNeeds(systemAppliances)
        }
        setShowAppliancePreview(!showAppliancePreview)
        setShowApplianceEditModal(!showApplianceEditModal)
    }

    const closeBuildCustomModal = () => {
        showBuildCustomModal.type === 'go-back'
            ? history.goBack()
            : setShowBuildCustomModal({
                  open: false,
                  type: 'cancel',
              })
    }

    const handlePrimaryBtnClick = () => {
        if (secondaryModalAction === 'view-aftercare-rating') {
            setSecondaryModalAction('')
            setShowSecondaryModal(false)
        }
    }

    const handleGetStarted = () => {
        if (
            isAuthenticatedUser &&
            location.state.payment_model !== activePaymentModel
        ) {
            setSelectedDifferentPaymentPlan(true)
            setShowModal(true)
        } else if (abTestInBoundRequest) {
            history.goBack()
        } else if (isAuthenticatedUser && action === 'select_new_system') {
            if (isPromoPage) {
                selectNewPromoSystem.mutate()
            } else {
                selectNewSystem.mutate()
            }
        } else if (viewingFromAccountSetup) {
            history.push({
                pathname: userInfo?.consumerKYCHome,
                state: {
                    payment_model: activePaymentModel,
                    onboardingType,
                    returnToWorkspace:
                        history.location?.state?.returnToWorkspace,
                },
            })
        } else if (viewingFromOutrightSaleKYCForm) {
            history.push('/consumer/account-setup/outright-sale-information')
        } else if (viewingFromOnePageKYC) {
            history.push(
                `/consumer/account-setup/${
                    customerTypeRemapping[location?.state?.customer_type] ===
                    'Business use'
                        ? 'business'
                        : 'residential'
                }`,
            )
        } else {
            // handling new signups
            if (
                !npsState?.energyAssessmentNPSDisplayed &&
                decryptedData !== null
            ) {
                setShowNPSRatingModal(true)
                updateNPSAndResetValues()
            } else {
                getStartedForNewSignups()
            }
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseGetSolarSystem'].action,
            eventTrackers['sseGetSolarSystem'].label,
            eventTrackers['sseGetSolarSystem'].category,
            ['MP', 'GA'],
        )
        getStartedTrackingForCustomBuild(onboardingType === 'custom-build')
    }

    const formatPaymentModel = {
        LEASE_TO_OWN: 'Lease to Own',
        SUBSCRIPTION: 'Subscription',
        OUTRIGHT_SALE: 'Outright Sale',
    }

    const consumerCurrentSolution =
        solution_id === id && previousPaymentModel === payment_model

    const pageLoading =
        viewSystemFetching ||
        authUserSystemFetching ||
        promoPackageFetching ||
        ProfilesOrAllSystemsUserSystemFetching

    // TODO: We need to revise this when we are now supporting Outright Purchase.
    const isLeaseToOwnPage = isPromoPage
        ? formatPaymentModel[paymentModel] === 'Lease to Own'
        : activePaymentModel?.toLowerCase() === 'lease to own'
    const isOutrightSalePage = isPromoPage
        ? formatPaymentModel[paymentModel] === 'Outright Sale'
        : activePaymentModel?.toLowerCase() === 'outright sale'
    const isSubscriptionPage = isPromoPage
        ? formatPaymentModel[paymentModel] === 'Subscription'
        : activePaymentModel?.toLowerCase() === 'subscription'
    const solarSavingsChartSubTitle =
        'Compare your generator cost to your solar cost over a period of 60 months'

    const solarSavingsChartSubTitleMonthToMonth =
        'Compare your generator cost to your solar cost monthly in year 1'

    useEffect(() => {
        if (isUpdatedSSEFlow) {
            const omitEnergyNeedsIds = map(energyNeeds, needs =>
                omit(needs, 'id'),
            )
            const madeChanges = !(
                JSON.stringify(systemAppliances) ===
                JSON.stringify(omitEnergyNeedsIds)
            )
            setChangedAppliances(madeChanges)
        }
    }, [energyNeeds, systemAppliances, isUpdatedSSEFlow])

    useEffect(() => {
        if (onboardingType === 'custom-build') {
            handleCustomBuildViewCountTracking(systemsViewedCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCopy = () => {
        const state = location.state
        let appliances = encode(JSON.stringify(state?.appliances))
        let isAllSystems = encode(state?.isAllSystems)
        let onboardingType = encode(state?.onboardingType)
        let payment_model = encode(
            state?.payment_model || systemDetails?.payment_model,
        )

        if (activePaymentModel && state.payment_model !== activePaymentModel) {
            appliances = encode(JSON.stringify(systemDetails?.fit_appliances))
            payment_model = encode(
                activePaymentModel || systemDetails?.payment_model,
            )
        }

        const profile_id = encode(state?.profile_id ?? '')
        const name = encode(systemDetails?.name)
        const isAllSystemsPackage = state?.isAllSystems
        const isProfilePackage =
            state?.onboardingType === 'profiles-or-all-systems' &&
            !isAllSystemsPackage
        const profileCustomerType = encode(state?.customer_type)

        if (sharedPackageName) {
            shortenLinkMutate(
                `${window.location.origin + window.location.pathname}/shared${
                    window.location.search
                }`,
            )
        } else if (state?.onboardingType === 'custom-build') {
            shortenLinkMutate(
                `${
                    window.location.origin + window.location.pathname
                }/shared?name=${name}&onboardingType=${encode(
                    'profiles-or-all-systems',
                )}&isAllSystems=${encode(true)}&payment_model=${payment_model}`,
            )
        } else if (isAllSystemsPackage) {
            shortenLinkMutate(
                `${
                    window.location.origin + window.location.pathname
                }/shared?name=${name}&appliances=${appliances}&isAllSystems=${isAllSystems}&onboardingType=${onboardingType}&payment_model=${payment_model}`,
            )
        } else if (isProfilePackage) {
            shortenLinkMutate(
                `${
                    window.location.origin + window.location.pathname
                }/shared?name=${name}&appliances=${appliances}&isAllSystems=${isAllSystems}&onboardingType=${onboardingType}&payment_model=${payment_model}&profile_id=${profile_id}&customerType=${profileCustomerType}`,
            )
        }
    }

    const showTabContent = id => {
        if (showPackageDetailsPageUpdates) {
            return activeTab === id
        } else {
            return true
        }
    }

    const { getReturningCustomerStatus, getReturningCustomerStatusLoading } =
        useGetReturningCustomerStatus({
            email: signUpInputs?.email,
            phone: formatPhoneNumber(signUpInputs?.phone),
            onSuccess: data => {
                const hasDiscardedDeal = data?.has_discarded_deal
                setHasDiscardedDeal(hasDiscardedDeal)
            },
        })

    const returningCustomerSignupMutation = useMutation({
        mutationKey: ['initiateSSESignup'],
        mutationFn: ({ payload }) =>
            initiateReturningUserSignup(payload, showReturningCustomerV2),
        onSuccess: res => {
            setToken(res?.data?.data?.token?.access)
            res.data.data.used_one_page_kyc = true
            res.data.data.uses_dynamic_kyc = isPromotional
            encodeUserInfo(res)
            setShowAppliancePreview(false)
            setShowModal(true)
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const handleApproveAppliances = () => {
        const isAuthenticatedUserWithEstimation =
            isAuthenticated() && estimation_id

        if (
            (isOnePageKYC &&
                isAllSystems &&
                !isAuthenticatedUserWithEstimation) ||
            (isOnePageKYC && hasDiscardedDeal && !showReturningCustomerV2) ||
            (isOnePageKYC && isNotSharedPromoCluster) ||
            !isOnePageKYC ||
            onboardingType === 'custom-build'
        ) {
            setShowAppliancePreview(false)
            setShowModal(true)
        } else if (showReturningCustomerV2 && hasDiscardedDeal) {
            let payload = { ...signupPayload }
            if (payload?.phone_number) {
                delete payload['email']
            } else delete payload['phone_number']
            returningCustomerSignupMutation.mutate({ payload })
        } else if (isAuthenticatedUserWithEstimation) {
            selectNewSystem.mutate()
        } else {
            onePageKYCSignupMutate(signupPayload)
        }
    }

    useEffect(() => {
        setActivePaymentModel(payment_model)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            (!isSharedPackage && signUpInputs?.email !== '') ||
            (location?.state?.shared && signUpInputs?.email !== '')
        ) {
            getReturningCustomerStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpInputs])

    return (
        <>
            <SeoComponent title={pageTitle} tracker={pageTracker} />
            {addFilter && (
                <FilterRecommendations
                    addFilter={addFilter}
                    setAddFilter={setAddFilter}
                    setShowPackages={setShowPackages}
                    page="view-system"
                />
            )}
            <GettingStarted
                showModal={showModal}
                setShowModal={setShowModal}
                selectedSolutionId={id}
                isPromo={decryptedData === null}
                handlePromoDropoff={handlePromoDropoff}
                initiatePayload={payload}
                showUseTypeField={
                    isAllSystems || onboardingType === 'custom-build'
                }
                showUseTypeFieldWithoutContact={
                    (isOnePageKYC && isAllSystems) ||
                    onboardingType === 'custom-build'
                }
                useV2SaveUseTypeFn={onboardingType !== 'custom-build'}
                selectedDifferentPaymentPlan={selectedDifferentPaymentPlan}
                activePaymentModel={activePaymentModel}
                handleChangePaymentModel={() => selectNewSystem.mutate()}
                undoPaymentOptionSelection={undoPaymentOptionSelection}
                useType={
                    customerTypeRemapping[customerType] || signUpInputs?.useType
                }
                isClosedCluster={isClosedCluster}
                isPromoCluster={isPromoCluster}
                isSharedCluster={isSharedCluster}
                isAllSystems={isAllSystems}
                signUpPayload={signupPayload}
                consumerKYCHome={consumerKYCHome}
                hasDiscardedDeal={hasDiscardedDeal}
                isPromotional={isPromotional}
                profileName={location.state?.profile_name || ''}
                systemDetails={systemDetails}
            />

            <SharePackageModal
                showModal={showSharePackageModal}
                onCancel={() => {
                    setShowSharePackageModal(false)
                    setSharePackageLink('')
                }}
                sharePackageLink={sharePackageLink}
                shortenLinkLoading={shortenLinkLoading}
                isPromoPage={isPromoPage}
                promoType={promoType}
                systemDetails={systemDetails}
                formatPaymentModel={formatPaymentModel}
                paymentModel={paymentModel}
                repaymentPlans={repaymentPlans}
            />

            <NPSRatingModal
                showModal={showNPSRatingModal}
                closeModal={() => {
                    getStartedForNewSignups()
                }}
                feature={'energy-assessment'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={handleSubmitEnergyAssessmentRating}
                btnLoading={submitEnergyAssessmentRating.isLoading}
            />

            <ActionModal
                actionModalOpen={actionModalOpen}
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                handlePrimaryBtnClick={() => actionModalHandler()}
                closable={canCloseActionModal}
                onCancel={() => {
                    if (canCloseActionModal) {
                        closeActionModal()
                    } else () => {}
                }}
                noBackLink
                footerLeftContent={actionModalFooterLeftContent}
                secondaryBtnText={actionModalSecondaryBtnText}
                handleSecondaryBtnClick={actionModalSecondaryHandler}
                primaryBtnLoading={actionModalPrimaryBtnLoading}
                toastError={actionModalToast?.showToast}
                errorMessage={actionModalToast?.toastMessage}
                errorMessageType={actionModalToast?.messageType}
                kycSecondaryButtonStopLoading
            />

            <LoginModal
                showModal={showLoginModal}
                setShowModal={() => {}}
                onCancel={closeLoginNoDetailsProvided}
                loginModalContent={loginModalContent}
                setLoginModalContent={setLoginModalContent}
                inputs={loginInputs}
                otpMethod={otpMethod}
                setOtpMethod={setOtpMethod}
                preventConsumerRedirect
                handlePostSuccess={data => onLoginSuccess(data)}
                showCloseIcon={showLoginModalCloseButton}
                initialDetailsNotProvided={initialDetailsNotProvided}
            />
            {!inViewPort && !isSharedPackage ? (
                <SystemNav
                    handleGetStarted={() => {
                        if (
                            onboardingType === 'custom-build' ||
                            action === 'select_new_system' ||
                            ((viewingFromOutrightSaleKYCForm ||
                                viewingFromAccountSetup ||
                                viewingFromOnePageKYC) &&
                                consumerCurrentSolution)
                        ) {
                            handleGetStarted()
                        } else {
                            handleAppliancePreview()
                        }
                    }}
                    viewingFromAccountSetup={viewingFromAccountSetup}
                    viewingFromOutrightSaleKYCForm={
                        viewingFromOutrightSaleKYCForm
                    }
                    viewingFromOnePageKYC={viewingFromOnePageKYC}
                    systemDetails={systemDetails}
                    isLoading={
                        isProfile || isAllSystems
                            ? false
                            : selectNewSystem.isLoading
                    }
                    pageLoading={pageLoading}
                    isPromoPage={isPromoPage}
                    repaymentPlans={repaymentPlans}
                    promoType={promoType}
                    planType={activePaymentModel?.toLowerCase()}
                    consumerCurrentSolution={consumerCurrentSolution}
                    viewingFromWorkspace={viewingFromWorkspace}
                />
            ) : (
                <ConsumerNavigation
                    showUserBtn
                    page={isClosedCluster ? 'closedCluster' : 'sseViewSystem'}
                    showProfileDropdown={
                        returningActiveOrClosedCustomer?.status
                    }
                />
            )}

            <SecondaryModal
                modalWidth={secondaryModalValues?.modalWidth}
                modalHeight={secondaryModalValues?.modalHeight}
                primaryBtnText={secondaryModalValues?.primaryBtnText}
                secondaryBtnText={secondaryModalValues?.secondaryBtnText}
                footerLeftContent={secondaryModalValues?.footerLeftContent}
                showModal={showSecondaryModal}
                title={secondaryModalValues?.modalTitle}
                content={
                    secondaryModalAction === 'view-aftercare-rating' ? (
                        <AfterCareRatingModalContent
                            afterCareRatingDetails={afterCareRatingDetails}
                        />
                    ) : null
                }
                v2
                showFooter
                handlePrimaryBtnClick={handlePrimaryBtnClick}
                onCancel={() => setShowSecondaryModal(false)}
            />

            <SecondaryModal
                showModal={showSystemConifrmation}
                onCancel={() => setShowSystemConfirmation(false)}
                modalWidth={'475px'}
                modalHeight={'525px'}
                title={
                    isSystemOverloaded
                        ? 'Reduce your added appliances'
                        : 'Powering essentials updated'
                }
                showFooter
                content={
                    <div className="SSEEditModalWrapper">
                        {isSystemOverloaded ? <WarningIcon /> : <SuccessIcon />}
                        <h3>
                            {isSystemOverloaded
                                ? 'This system has reached its capacity'
                                : 'Your appliances have been updated'}
                        </h3>
                        <p>
                            {isSystemOverloaded
                                ? 'The appliances you added exceeds the capacity of this system. To continue, please go back and reduce the appliances you selected.'
                                : `Your system’s appliances has now been updated, 
                                you can now go ahead to submit your KYC`}
                        </p>
                        {isSystemOverloaded && (
                            <div className="GetStarted">
                                <h6>OR build a custom system</h6>
                                <div className="BuildSystem">
                                    <p>
                                        Submit your appliances to build your
                                        system
                                    </p>
                                    <ConsumerButton
                                        btnFontSize="12px"
                                        btnTextColor="#004AAD"
                                        borderOutlineColor="#004AAD"
                                        handleClick={() => {
                                            setShowSystemConfirmation(false)
                                            setShowBuildCustomModal({
                                                open: true,
                                                type: 'cancel',
                                            })
                                        }}
                                    >
                                        Get started
                                    </ConsumerButton>
                                </div>
                            </div>
                        )}
                    </div>
                }
                v2
                primaryBtnText={isSystemOverloaded ? 'Go Back' : 'Get started'}
                secondaryBtnText={isSystemOverloaded && 'Undo Changes'}
                footerLeftContent={isSystemOverloaded ? 'button' : 'chat'}
                handlePrimaryBtnClick={() => {
                    setShowSystemConfirmation(false)
                    !isSystemOverloaded && setSystemAppliances(energyNeeds)
                    isSystemOverloaded
                        ? setShowApplianceEditModal(true)
                        : setShowAppliancePreview(true)
                }}
                handleSecondaryBtnClick={() => {
                    setShowSystemConfirmation(false)
                    setShowAppliancePreview(true)
                    setUndidChange(true)
                }}
            />

            <SecondaryModal
                v2
                modalWidth="624px"
                modalHeight="687px"
                showModal={showApplianceEditModal}
                onCancel={() => setShowApplianceEditModal(false)}
                title="Tell us your energy needs"
                content={
                    <UpdateNeeds
                        page="consumer-SSE"
                        includeActionButton={false}
                        energyNeeds={energyNeeds}
                        selectedAppliances={energyNeeds}
                        closeModal={() => {
                            saveUserAppliances.mutate()
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                getSystemPageEvents['editAppliance'].action,
                                getSystemPageEvents['editAppliance'].label,
                                getSystemPageEvents['editAppliance'].category,
                            )
                        }}
                        updateEnergyNeeds={updateEnergyNeeds}
                        isLoading={saveUserAppliances.isLoading}
                        seeUse
                        isUpdatedSSEFlow={isUpdatedSSEFlow}
                        underscoredKeys
                    />
                }
            />

            <ExploreOtherOptionsModal
                showModal={showExploreModal}
                onCancel={() => setShowExploreModal(false)}
                activePaymentModel={activePaymentModel}
                setActivePaymentModel={setActivePaymentModel}
                solutions={getEnergyProfileSolutionsData?.data?.data || []}
            />

            <SecondaryModal
                showModal={showAppliancePreview}
                mobileModalTop="unset"
                title={
                    appliancesBreakdownAction === 'selecting-system'
                        ? 'Confirm to continue'
                        : 'Appliances breakdown'
                }
                onCancel={() => {
                    setShowAppliancePreview(false)
                    setAppliancesBreakdownAction('selecting-system')
                }}
                content={
                    <>
                        {signUpModalToast?.showToast && (
                            <Toast
                                message={signUpModalToast?.toastMessage}
                                messageType={signUpModalToast?.messageType}
                            />
                        )}
                        <AppliancesPreview
                            systemDetails={systemDetails}
                            appliances={systemAppliances}
                            handleChecked={handleChecked}
                            checked={checked}
                            showToast={modalToast?.showToast}
                            toastMessage={modalToast?.toastMessage}
                            messageType={modalToast?.messageType}
                            showNotice={
                                appliancesBreakdownAction === 'selecting-system'
                            }
                            showCheckbox={
                                appliancesBreakdownAction === 'selecting-system'
                            }
                            additionalClassName={
                                appliancesBreakdownAction ===
                                    'preview-what-it-powers' &&
                                'AppliancesPreviewContainer_WhatItPowers'
                            }
                        />
                    </>
                }
                modalWidth="475px"
                v2
                showFooter
                primaryBtnText={
                    appliancesBreakdownAction === 'selecting-system'
                        ? 'Confirm'
                        : 'Got it'
                }
                secondaryBtnText="Edit Appliances"
                footerLeftContent={isClosedCluster ? 'chat' : 'button'}
                footerClassName={
                    appliancesBreakdownAction === 'selecting-system'
                        ? 'AppliancePreviewFooter'
                        : 'PreviewFooterClass'
                }
                handleSecondaryBtnClick={handleEditApplianceModal}
                primaryBtnDisabled={
                    (appliancesBreakdownAction === 'selecting-system' &&
                        !checked) ||
                    selectNewSystem.isLoading ||
                    returningCustomerSignupMutation.isLoading
                }
                primaryBtnLoading={
                    selectNewSystem.isLoading ||
                    getNewSystemLoading ||
                    onePageKYCSignupLoading ||
                    getReturningCustomerStatusLoading ||
                    returningCustomerSignupMutation.isLoading
                }
                kycSecondaryButtonStopLoading
                handlePrimaryBtnClick={() => {
                    if (
                        appliancesBreakdownAction === 'preview-what-it-powers'
                    ) {
                        setShowAppliancePreview(false)
                        setAppliancesBreakdownAction('selecting-system')
                    } else {
                        if (
                            allowReturningCustomers &&
                            returningActiveOrClosedCustomer?.status
                        ) {
                            getNewSystemMutate(payload)
                        } else if (isAuthenticatedUser) {
                            selectNewSystem.mutate()
                        } else {
                            handleApproveAppliances()
                            undidChanges
                                ? appTracking(
                                      pageUrlName,
                                      pageTracker,
                                      pageTitle,
                                      getSystemPageEvents['undoChanges'].action,
                                      getSystemPageEvents['undoChanges'].label,
                                      getSystemPageEvents['undoChanges']
                                          .category,
                                      ['MP', 'GA'],
                                      'event',
                                  )
                                : !changedAppliances &&
                                  appTracking(
                                      pageUrlName,
                                      pageTracker,
                                      pageTitle,
                                      getSystemPageEvents['proceedToKYC']
                                          .action,
                                      getSystemPageEvents['proceedToKYC'].label,
                                      getSystemPageEvents['proceedToKYC']
                                          .category,
                                  )
                        }
                    }
                }}
            />
            <div className={`${isSharedPackage ? 'ParentWrapper_Shared' : ''}`}>
                {isSharedPackage && showSharedPackageModal && (
                    <SharedForm
                        view={{
                            isPackage: true,
                            name: sharedPackageName,
                            packageCost: systemDetails?.total_cost
                                ? `N${currencyFormatter(
                                      systemDetails?.total_cost,
                                  )}`
                                : '',
                            packageCapacity: systemDetails?.energy_capacity
                                ? `${systemDetails.energy_capacity.value}${systemDetails.energy_capacity.unit_of_measurement}`
                                : '',
                            applianceCount:
                                systemDetails?.fit_appliances?.length || '',
                        }}
                        openPageActionModal={openActionModal}
                        closeSharedModal={closeSharedModal}
                        setPageLoginInputs={setLoginInputs}
                        setPageReturningCustomerId={setReturningCustomerId}
                        openLoginNoDetailsProvided={openLoginNoDetailsProvided}
                        closeLoginNoDetailsProvided={
                            closeLoginNoDetailsProvided
                        }
                        setHasDiscardedDeal={setHasDiscardedDeal}
                    />
                )}
                <div className="ViewSystemContainer noBodyScrollbar">
                    {pageLoading ? (
                        <ViewSystemSkeleton />
                    ) : (
                        <>
                            <div
                                className={`ViewSystemTopButtons ${
                                    showPackageDetailsPageUpdates
                                        ? 'ViewSystemTopButtons_Updated'
                                        : ''
                                }`}
                                style={{
                                    justifyContent: sharedPackageName
                                        ? 'flex-end'
                                        : '',
                                }}
                            >
                                {!sharedPackageName && (
                                    <BackNav
                                        v2
                                        title="Go back"
                                        onClick={() => {
                                            if (
                                                !isAuthenticatedUser ||
                                                isPromoPage ||
                                                isDEPConsumer ||
                                                returnToKyc ||
                                                isAdminUser ||
                                                abTestInBoundRequest
                                            ) {
                                                history.goBack()
                                            } else if (isAuthenticatedUser) {
                                                if (
                                                    history?.location?.state
                                                        ?.returnToClusterWorkspace
                                                ) {
                                                    history.push(
                                                        history?.location?.state
                                                            ?.from,
                                                        {
                                                            ...history?.location
                                                                ?.state,
                                                            from: '',
                                                        },
                                                    )
                                                } else if (
                                                    onboardingType ===
                                                    'profiles-or-all-systems'
                                                ) {
                                                    history.push({
                                                        pathname:
                                                            userInfo?.consumerKYCHome,
                                                        state: {
                                                            isAuthenticatedUser:
                                                                isAuthenticatedUser,
                                                            estimation_id:
                                                                estimation_id,
                                                            auth_user_id:
                                                                auth_user_id,
                                                            returnToWorkspace:
                                                                history.location
                                                                    ?.state
                                                                    ?.returnToWorkspace,
                                                        },
                                                    })
                                                } else {
                                                    history.push({
                                                        pathname:
                                                            '/consumer/systems',
                                                        state: {
                                                            isAuthenticatedUser:
                                                                isAuthenticatedUser,
                                                            estimation_id:
                                                                estimation_id,
                                                            auth_user_id:
                                                                auth_user_id,
                                                        },
                                                    })
                                                }
                                            } else {
                                                history.push({
                                                    pathname:
                                                        '/consumer/systems',
                                                    state: {
                                                        filterPayload: {},
                                                        showPackages: false,
                                                    },
                                                })
                                            }
                                        }}
                                    />
                                )}

                                <div
                                    className={`VS_TopRow_Right ${
                                        showPackageDetailsPageUpdates
                                            ? 'UpdatedVS_TopRow_Right'
                                            : ''
                                    }`}
                                >
                                    {showPackageDetailsPageUpdates && (
                                        <ChatCTA text="Got questions?" />
                                    )}
                                    {history.location.state?.onboardingType !==
                                    'custom-build'
                                        ? !isClosedCluster && (
                                              <div className="VS_TopRow_Right_ShareBtn">
                                                  <button
                                                      className={
                                                          'VSShareWrapper'
                                                      }
                                                      onClick={() => {
                                                          handleCopy()
                                                          setShowSharePackageModal(
                                                              true,
                                                          )
                                                      }}
                                                  >
                                                      <ShareIcon /> Share this
                                                      package
                                                  </button>
                                              </div>
                                          )
                                        : null}
                                    {!isMobile &&
                                        !isPromoPage &&
                                        !isDEPConsumer &&
                                        !isUpdatedSSEFlow && (
                                            <div
                                                onClick={() =>
                                                    setAddFilter(true)
                                                }
                                            >
                                                <img
                                                    src={filterIcon}
                                                    alt="filter icon"
                                                />
                                                <span>
                                                    Filter recommendations
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                            {showToastError && (
                                <Toast
                                    messageType="error-secondary"
                                    message={errorMessage}
                                    closable={true}
                                />
                            )}
                            <div
                                className={`ViewSystemTop ${
                                    showPackageDetailsPageUpdates
                                        ? 'ViewSystemTop_Updated'
                                        : ''
                                }`}
                                ref={targetRef}
                            >
                                {showPackageImagesCarousel ? (
                                    <PackageImagesCarousel
                                        systemDetails={systemDetails}
                                        margin={isMobile ? '0 0 30px 0' : ''}
                                    />
                                ) : (
                                    <LazyLoadImage
                                        src={
                                            isPromoPage &&
                                            promoType !== undefined
                                                ? promoBanners[promoType]
                                                : systemDetails?.has_solar_panel
                                                ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+Banner.png'
                                                : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+Banner.png'
                                        }
                                        alt="image of the system"
                                        placeholderSrc={fallbackImage}
                                    />
                                )}
                                <div className="ViewSystemTopText">
                                    {!showPackageImagesCarousel && (
                                        <h2>{systemDetails?.name}</h2>
                                    )}
                                    <h1>
                                        {systemDetails?.energy_capacity?.value +
                                            systemDetails?.energy_capacity
                                                ?.unit_of_measurement}{' '}
                                        system
                                    </h1>
                                    {!showPackageDetailsPageUpdates && (
                                        <div className="ViewSystemTopTextFlags">
                                            <div>
                                                <p>
                                                    {isPromoPage
                                                        ? formatPaymentModel[
                                                              paymentModel
                                                          ]?.toUpperCase()
                                                        : activePaymentModel?.toUpperCase()}
                                                </p>
                                            </div>
                                            {isLeaseToOwnPage && (
                                                <div>
                                                    <p>{`${
                                                        isPromoPage
                                                            ? repaymentPlans?.length
                                                            : systemDetails
                                                                  ?.payment_plans
                                                                  ?.length
                                                    } PLANS`}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {showPackageDetailsPageUpdates ? (
                                        <div className="VS_RightSection">
                                            <SystemDetailsCard
                                                onboardingType={onboardingType}
                                                action={action}
                                                viewingFromOutrightSaleKYCForm={
                                                    viewingFromOutrightSaleKYCForm
                                                }
                                                viewingFromAccountSetup={
                                                    viewingFromAccountSetup
                                                }
                                                viewingFromOnePageKYC={
                                                    viewingFromOnePageKYC
                                                }
                                                viewingFromWorkspace={
                                                    viewingFromWorkspace
                                                }
                                                handleGetStarted={
                                                    handleGetStarted
                                                }
                                                handleAppliancePreview={
                                                    handleAppliancePreview
                                                }
                                                isProfile={isProfile}
                                                isAllSystems={isAllSystems}
                                                selectNewSystem={
                                                    selectNewSystem
                                                }
                                                openAppliancesPreview={() => {
                                                    handleAppliancePreview()
                                                    setAppliancesBreakdownAction(
                                                        'preview-what-it-powers',
                                                    )
                                                }}
                                                isPromoPage={isPromoPage}
                                                repaymentPlans={repaymentPlans}
                                                systemDetails={systemDetails}
                                                paymentPlans={paymentPlans}
                                                consumerCurrentSolution={
                                                    consumerCurrentSolution
                                                }
                                            />
                                            <VerticalSpacer top={16} />
                                            <TextDropdown
                                                title="Installation details"
                                                content="The total cost of ownership covers the installation cost, but you may need to pay an extra fee for logistics if you are located outside of Lagos."
                                            />
                                            <VerticalSpacer top={16} />
                                            <TextDropdown
                                                title="Package description"
                                                content={
                                                    isPromoPage
                                                        ? parseHTML(
                                                              systemDetails?.description,
                                                          )
                                                        : isDEPConsumer
                                                        ? parseHTML(
                                                              systemDetails
                                                                  ?.recommendation_context
                                                                  ?.note,
                                                          )
                                                        : systemDetails
                                                              ?.recommendation_context
                                                              ?.note
                                                }
                                            />
                                            <VerticalSpacer top={12} />
                                        </div>
                                    ) : (
                                        <div className="ViewSystemTopTextCard">
                                            <div className="ViewSystemTopTextCardText Main">
                                                {isLeaseToOwnPage ? (
                                                    <span>
                                                        Min. amount payable per
                                                        month
                                                    </span>
                                                ) : isOutrightSalePage ? (
                                                    <span>
                                                        One-time payment of
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Amount payable per month
                                                    </span>
                                                )}
                                                <p>
                                                    <NairaSymbol />
                                                    {isOutrightSalePage
                                                        ? currencyFormatter(
                                                              systemDetails.total_cost,
                                                          )
                                                        : currencyFormatter(
                                                              isPromoPage
                                                                  ? repaymentPlans?.[
                                                                        repaymentPlans?.length -
                                                                            1
                                                                    ]
                                                                        ?.monthly_payment
                                                                  : systemDetails
                                                                        ?.payment_plans?.[
                                                                        systemDetails
                                                                            ?.payment_plans
                                                                            ?.length -
                                                                            1
                                                                    ]
                                                                        ?.monthly_payment,
                                                          )}
                                                </p>
                                            </div>
                                            <div className="ViewSystemTopTextCardTextWrapper">
                                                {!isOutrightSalePage && (
                                                    <div className="ViewSystemTopTextCardText Left">
                                                        <span>
                                                            Upfront Deposit
                                                        </span>
                                                        <p>
                                                            <NairaSymbol />
                                                            {currencyFormatter(
                                                                isPromoPage
                                                                    ? repaymentPlans?.[
                                                                          repaymentPlans?.length -
                                                                              1
                                                                      ]
                                                                          ?.initial_deposit
                                                                    : systemDetails
                                                                          ?.payment_plans?.[
                                                                          systemDetails
                                                                              ?.payment_plans
                                                                              ?.length -
                                                                              1
                                                                      ]
                                                                          ?.initial_deposit,
                                                            )}
                                                        </p>
                                                    </div>
                                                )}

                                                {isLeaseToOwnPage && (
                                                    <div className="ViewSystemTopTextCardText Right">
                                                        <span>
                                                            Highest possible
                                                            plan{' '}
                                                        </span>
                                                        <p>{`${
                                                            isPromoPage
                                                                ? repaymentPlans?.[
                                                                      repaymentPlans?.length -
                                                                          1
                                                                  ]?.tenure
                                                                : systemDetails
                                                                      ?.payment_plans?.[
                                                                      systemDetails
                                                                          ?.payment_plans
                                                                          ?.length -
                                                                          1
                                                                  ]?.tenure
                                                        } months`}</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="ViewSystemTopTextCardTextWrapper">
                                                {systemDetails?.average_backup_hours && (
                                                    <div className="ViewSystemTopTextCardText Left">
                                                        <span>
                                                            Backup duration
                                                        </span>
                                                        <p>
                                                            {Math.round(
                                                                systemDetails?.average_backup_hours,
                                                            )}{' '}
                                                            Hour
                                                            {systemDetails?.average_backup_hours >
                                                            1
                                                                ? 's'
                                                                : ''}
                                                        </p>
                                                    </div>
                                                )}

                                                {systemDetails?.consumer_avg_demand && (
                                                    <div className="ViewSystemTopTextCardText Right">
                                                        <span>
                                                            Avg. load size
                                                        </span>
                                                        <div className="ViewSystemTopTextCardText_LoadInfo">
                                                            <p>
                                                                {
                                                                    systemDetails?.consumer_avg_demand
                                                                }
                                                            </p>
                                                            <CustomToolTip
                                                                text={`${
                                                                    systemDetails?.consumer_avg_demand?.includes(
                                                                        'kVA',
                                                                    )
                                                                        ? ''
                                                                        : `${systemDetails?.consumer_avg_demand} also means ${systemDetails?.consumer_avg_demand_kva}. `
                                                                }Keep total power use at the recommended level to get the listed backup hours.`}
                                                                toolTipFontSize="12px"
                                                                toolTipPadding="9px 12px 9px 8px"
                                                                toolTipPopupWidth={
                                                                    174
                                                                }
                                                                toolTipMarginLeft={
                                                                    -150
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        infoIcon
                                                                    }
                                                                    alt={
                                                                        'info icon'
                                                                    }
                                                                    role={
                                                                        'presentation'
                                                                    }
                                                                />
                                                            </CustomToolTip>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {process.env
                                                .REACT_APP_FLAG_SHOW_AFTER_CARE_RATING ===
                                            'false' ? (
                                                <div className="ViewSystemTopTextCardTextWrapper">
                                                    <div className="ViewSystemTopTextCardText Left">
                                                        <span>
                                                            Aftercare service
                                                        </span>
                                                        <div className="ViewSystemTopTextCard_AftercareInfo">
                                                            <span>4/5</span>
                                                            <div className="ViewSystemTopTextCard_AftercareInfo_Stars">
                                                                {[
                                                                    ...Array(4),
                                                                ]?.map(i => (
                                                                    <StarFilled
                                                                        key={i}
                                                                    />
                                                                ))}
                                                                <Star />
                                                            </div>

                                                            <CustomToolTip
                                                                text={
                                                                    'This indicates the quality of aftercare service you will experience with this package post purchase'
                                                                }
                                                                toolTipFontSize="12px"
                                                                toolTipPadding="9px 12px 9px 8px"
                                                                toolTipPopupWidth={
                                                                    174
                                                                }
                                                                arrowPositionLeft
                                                                toolTipMarginLeft={
                                                                    -19
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        infoIcon
                                                                    }
                                                                    alt={
                                                                        'info icon'
                                                                    }
                                                                    role={
                                                                        'presentation'
                                                                    }
                                                                    className="ViewSystem_TooltipIcon"
                                                                />
                                                            </CustomToolTip>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {systemDetails?.best_fit ===
                                                true && (
                                                <div className="ViewSystemTopTextCardInfo">
                                                    <img
                                                        src={infoIcon}
                                                        alt={'info icon'}
                                                        role={'presentation'}
                                                    />
                                                    <p>
                                                        {`Based on the Information you’ve
                                        provided, this is the best option for
                                        you as it saves you ${Math.round(
                                            ((systemDetails?.solar_savings
                                                ?.gen_est_monthly_cost -
                                                systemDetails?.solar_savings
                                                    ?.solar_est_monthly_cost) /
                                                systemDetails?.solar_savings
                                                    ?.gen_est_monthly_cost) *
                                                100,
                                        )}% of the amount
                                        you spend on an alternative per month`}
                                                    </p>
                                                </div>
                                            )}
                                            <div className="ViewSystemTopTextButtons">
                                                {systemDetails
                                                    .supported_payment_plans
                                                    ?.length > 1 && (
                                                    <ConsumerButton
                                                        btnTextColor={'#004AAD'}
                                                        btnBgColor={'#FFFFFF'}
                                                        borderOutlineColor={
                                                            '#004AAD'
                                                        }
                                                        hover={'#004AAD'}
                                                        btnWidth={
                                                            isMobile
                                                                ? '100%'
                                                                : '160px'
                                                        }
                                                        btnHeight={'53px'}
                                                        btnFontSize="13px"
                                                        handleClick={() =>
                                                            setShowExploreModal(
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        Other payment options
                                                    </ConsumerButton>
                                                )}
                                                {!history?.location?.state
                                                    ?.returnToClusterWorkspace && (
                                                    <ConsumerButton
                                                        btnBgColor={'#004AAD'}
                                                        btnTextColor={'#FFFFFF'}
                                                        btnWidth={
                                                            isMobile ||
                                                            !(
                                                                systemDetails
                                                                    .supported_payment_plans
                                                                    ?.length > 1
                                                            )
                                                                ? '100%'
                                                                : '160px'
                                                        }
                                                        btnHeight={'53px'}
                                                        handleClick={() =>
                                                            onboardingType ===
                                                                'custom-build' ||
                                                            action ===
                                                                'select_new_system' ||
                                                            ((viewingFromOutrightSaleKYCForm ||
                                                                viewingFromAccountSetup ||
                                                                viewingFromOnePageKYC ||
                                                                viewingFromWorkspace) &&
                                                                consumerCurrentSolution)
                                                                ? handleGetStarted()
                                                                : handleAppliancePreview()
                                                        }
                                                        loading={
                                                            isProfile ||
                                                            isAllSystems
                                                                ? false
                                                                : selectNewSystem.isLoading
                                                        }
                                                    >
                                                        {(viewingFromAccountSetup ||
                                                            viewingFromOutrightSaleKYCForm ||
                                                            viewingFromOnePageKYC) &&
                                                        consumerCurrentSolution
                                                            ? viewingFromWorkspace
                                                                ? 'Back to Profile'
                                                                : 'Continue with KYC'
                                                            : 'Get this system'}
                                                    </ConsumerButton>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!showPackageDetailsPageUpdates && (
                                        <div className="ViewSystemContactCTA PageTop">
                                            <ChatCTA
                                                text="Have questions about this package?"
                                                width="fit-content"
                                                textOneFontSize="14px"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="ViewSystemBottom">
                                <div
                                    className={`ViewSystemTabsWrapper ${
                                        showAfterCareRating ? '' : 'AddMargin'
                                    }`}
                                >
                                    {showAfterCareRating ? (
                                        <div className="ViewSystemAfterCareRating">
                                            <div className="ViewSystemAfterCareRatingScore">
                                                <img
                                                    src={afterCareBadge}
                                                    className="AfterCareRatingBadge"
                                                />

                                                <p>
                                                    {
                                                        afterCareRatingDetails?.aftercare_score
                                                    }
                                                </p>
                                            </div>

                                            <div className="SummaryAndTrigger">
                                                <p className="ViewSystemRatingSummary">
                                                    {afterCareRatingDetails?.summary ??
                                                        'No summary Available'}
                                                </p>

                                                <span
                                                    className="ViewSystemAfterCareRatingTrigger"
                                                    role="button"
                                                    onClick={() => {
                                                        setSecondaryModalAction(
                                                            'view-aftercare-rating',
                                                        )
                                                        setShowSecondaryModal(
                                                            true,
                                                        )
                                                    }}
                                                >
                                                    What does this mean?
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="ViewSystemTabTitle">
                                        {tabLabels(
                                            page,
                                            isDEPConsumer,
                                            recommendedCombo,
                                        ).map((each, i) =>
                                            isOutrightSalePage &&
                                            each?.label ===
                                                'Savings Summary' ? (
                                                <></>
                                            ) : (
                                                <p
                                                    key={i}
                                                    onClick={() => {
                                                        scrollToById(
                                                            each?.id,
                                                            150,
                                                        )
                                                        setActiveTab(each?.id)
                                                    }}
                                                    style={{
                                                        borderBottom:
                                                            activeTab ===
                                                            each?.id
                                                                ? '1px solid #004AAD'
                                                                : 'none',
                                                        color:
                                                            activeTab ===
                                                            each?.id
                                                                ? '#004AAD'
                                                                : '#3D5075',
                                                    }}
                                                >
                                                    {each?.label}
                                                </p>
                                            ),
                                        )}
                                    </div>

                                    <div className={'ViewSystemTabsContent'}>
                                        {isPromoPage &&
                                            recommendedCombo?.length > 0 && (
                                                <>
                                                    <div className="PromoComboWrapper">
                                                        <p>
                                                            PERFECT COMBINATION
                                                            FOR THIS SYSTEM
                                                        </p>
                                                        <a
                                                            onClick={() =>
                                                                (window.location.href =
                                                                    '/consumer/get-started')
                                                            }
                                                        >
                                                            Is this different
                                                            from what you intend
                                                            to power?
                                                        </a>
                                                    </div>
                                                    <div
                                                        className={
                                                            'ViewSystemTabsContentOverview'
                                                        }
                                                        id={
                                                            'VS-RecommendedCombo'
                                                        }
                                                        style={{
                                                            columnGap: '12px',
                                                            justifyContent:
                                                                'flex-start',
                                                        }}
                                                    >
                                                        {recommendedComboData(
                                                            recommendedCombo,
                                                        )?.map((each, i) => (
                                                            <div
                                                                key={i}
                                                                className={
                                                                    'ViewSystemTabsOverviewItem'
                                                                }
                                                            >
                                                                {each?.icon}
                                                                <div>
                                                                    <p>
                                                                        {
                                                                            each?.label
                                                                        }
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            each?.value
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        {showTabContent('VS-Overview') && (
                                            <div
                                                className={
                                                    'ViewSystemTabsContentOverview'
                                                }
                                                id={'VS-Overview'}
                                            >
                                                {overviewData(
                                                    systemDetails,
                                                    isPromoPage,
                                                )?.map((each, i) => (
                                                    <div
                                                        key={i}
                                                        className={
                                                            'ViewSystemTabsOverviewItem'
                                                        }
                                                    >
                                                        {each?.icon}
                                                        <div>
                                                            <span>
                                                                {each?.label}
                                                            </span>
                                                            <p>{each?.value}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {!showPackageDetailsPageUpdates &&
                                            isMobile && (
                                                <PackageDescription
                                                    description={
                                                        isPromoPage
                                                            ? parseHTML(
                                                                  systemDetails?.description,
                                                              )
                                                            : isDEPConsumer
                                                            ? parseHTML(
                                                                  systemDetails
                                                                      ?.recommendation_context
                                                                      ?.note,
                                                              )
                                                            : systemDetails
                                                                  ?.recommendation_context
                                                                  ?.note
                                                    }
                                                />
                                            )}
                                        {showTabContent(
                                            'VS-PaymentBreakdown',
                                        ) && (
                                            <div>
                                                <RepaymentOptions
                                                    paymentPlans={
                                                        isPromoPage
                                                            ? repaymentPlans
                                                            : systemDetails?.payment_plans
                                                    }
                                                    paymentModel={
                                                        isPromoPage
                                                            ? formatPaymentModel[
                                                                  paymentModel
                                                              ]
                                                            : activePaymentModel
                                                    }
                                                    monthPayment={
                                                        isPromoPage
                                                            ? repaymentPlans?.[
                                                                  repaymentPlans?.length -
                                                                      1
                                                              ]?.monthly_payment
                                                            : systemDetails
                                                                  ?.payment_plans?.[
                                                                  systemDetails
                                                                      ?.payment_plans
                                                                      ?.length -
                                                                      1
                                                              ]?.monthly_payment
                                                    }
                                                    preferred_payment_tenure={
                                                        systemDetails?.preferred_payment_tenure
                                                    }
                                                    totalCost={
                                                        systemDetails.total_cost
                                                    }
                                                    setShowExploreModal={
                                                        setShowExploreModal
                                                    }
                                                    supportedPaymentPlans={
                                                        systemDetails.supported_payment_plans
                                                    }
                                                />
                                            </div>
                                        )}
                                        {showTabContent('VS-SavingsSummary') &&
                                            !isPromoPage &&
                                            !isDEPConsumer &&
                                            !isOutrightSalePage && (
                                                <>
                                                    <div
                                                        className={
                                                            'ViewSystemTabsDivider'
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            'ViewSystemTabsContentSavings'
                                                        }
                                                        id={'VS-SavingsSummary'}
                                                    >
                                                        <h3>
                                                            Est. Cumulative
                                                            Savings Chart
                                                        </h3>
                                                        <p>
                                                            {
                                                                solarSavingsChartSubTitle
                                                            }
                                                        </p>
                                                        <SavingsChart
                                                            genCost={
                                                                isLeaseToOwnPage
                                                                    ? systemDetails
                                                                          ?.solar_savings
                                                                          ?.gen_est_monthly_cost
                                                                    : systemDetails
                                                                          ?.solar_savings
                                                                          ?.gen_est_total_months_cost
                                                            }
                                                            solarCost={
                                                                isLeaseToOwnPage
                                                                    ? systemDetails
                                                                          ?.solar_savings
                                                                          ?.solar_est_monthly_cost
                                                                    : systemDetails
                                                                          ?.solar_savings
                                                                          ?.solar_est_total_months_cost
                                                            }
                                                            isMonthlyCostChart={
                                                                false
                                                            }
                                                        />
                                                    </div>
                                                    {showTabContent(
                                                        'VS-SavingsSummary',
                                                    ) &&
                                                        isSubscriptionPage && (
                                                            <div
                                                                className={
                                                                    'ViewSystemTabsContentSavings'
                                                                }
                                                                id={
                                                                    'VS-SavingsSummary'
                                                                }
                                                            >
                                                                <h3>
                                                                    Est. Monthly
                                                                    Savings
                                                                    Chart
                                                                </h3>
                                                                <p>
                                                                    {
                                                                        solarSavingsChartSubTitleMonthToMonth
                                                                    }
                                                                </p>
                                                                <SavingsChart
                                                                    genCost={
                                                                        systemDetails
                                                                            ?.solar_savings
                                                                            ?.gen_est_month_one_cost
                                                                    }
                                                                    solarCost={
                                                                        systemDetails
                                                                            ?.solar_savings
                                                                            ?.solar_est_month_one_cost
                                                                    }
                                                                    isMonthlyCostChart={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                    </div>
                                </div>
                                <div className={'ViewSystemBottomRight'}>
                                    {!showPackageDetailsPageUpdates &&
                                        !isMobile && (
                                            <PackageDescription
                                                description={
                                                    isPromoPage
                                                        ? parseHTML(
                                                              systemDetails?.description,
                                                          )
                                                        : isDEPConsumer
                                                        ? parseHTML(
                                                              systemDetails
                                                                  ?.recommendation_context
                                                                  ?.note,
                                                          )
                                                        : systemDetails
                                                              ?.recommendation_context
                                                              ?.note
                                                }
                                            />
                                        )}
                                    <PackageSteps
                                        collapsible={
                                            showPackageDetailsPageUpdates
                                        }
                                        v2Style={showPackageDetailsPageUpdates}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <FrequentlyAskedQuestions
                        useV2
                        page={isClosedCluster && 'closedCluster'}
                    />
                </div>
                <CookieNotification />
                <ConsumerFooter />
            </div>
            <SecondaryModal
                v2
                showModal={showBuildCustomModal.open}
                title="Build a customised system instead"
                modalWidth={476}
                showFooter
                footerLeftContent="button"
                secondaryBtnText={
                    showBuildCustomModal.type === 'cancel'
                        ? 'Cancel'
                        : 'Go back'
                }
                primaryBtnText="Build your system"
                content={<BuildCustomsModalContent />}
                onCancel={() => {
                    setShowBuildCustomModal({
                        open: false,
                        type: 'cancel',
                    })
                }}
                handleSecondaryBtnClick={() => {
                    closeBuildCustomModal()
                }}
                handlePrimaryBtnClick={() => {
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        getSystemPageEvents['buildCustomSystem'].action,
                        getSystemPageEvents['buildCustomSystem'].label,
                        getSystemPageEvents['buildCustomSystem'].category,
                        ['MP', 'GA'],
                        'event',
                    )
                    history.push({
                        pathname: '/consumer/get-started',
                        state: {
                            buildCustomSystem: true,
                            appliances: energyNeeds,
                        },
                    })
                }}
            />
        </>
    )
}
export default ViewSystem
