import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import {
    sendRecommendationsToConsumerApi,
    useGetAlternateRecommendations,
} from 'src/api/admin/payment-plans/selected-alternative-recom'
import { ReactComponent as Layers } from 'src/assets/images/layers.svg'
import BackNav from 'src/components/BackNav'
import { ConsumerButton } from 'src/components/Button'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { useEffect, useState } from 'react'
import styles from './selectedalternativerecommendations.module.scss'
import { RecommendedSkeletalCards } from 'src/components/SkeletalCards'
import ActionModal from 'src/components/ActionModal'
import { remarkMapping } from 'src/utils/recommendations'
import AlternativePackages from '../../PaymentPlans/Details/components/AlternativePackages'
import NairaSymbol from 'src/components/NairaSymbol'
import { truncateText } from 'src/utils/formatting'
import GetModal from 'src/components/Modal'
import ViewRecommendation from 'src/pages/Recommendations/ViewRecommendation'
import {
    alternativeRecomActionModalValues,
    determinePaymentModelString,
} from './data'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from 'src/components/Seo'
import { permissionsControl } from 'src/utils/permissionsControl'

const SelectedAlternativeRecommendations = () => {
    const params = useParams()
    const {
        data,
        isLoading: isLoadingRecommendations,
        refetch: recommendationsRefetch,
    } = useGetAlternateRecommendations(params?.id)
    const recommendations = data?.data
    const [viewSelectedSolution, setViewSelectedSolution] = useState()
    const [showActionModal, setShowActionModal] = useState(false)
    const [action, setAction] = useState('')
    const [showRecommendationModal, setShowRecommendationModal] =
        useState(false)
    const [showCustomPackages, setShowCustomPackages] = useState(false)
    const [extensivePackageData, setExtensivePackageData] = useState({})
    const [paymentModel, setPaymentModel] = useState('')
    const [toastError, setToastError] = useState(false)
    const [toastErrorMsg, setToastErrorMsg] = useState('')
    const [triggerSetSolution, setTriggerSetSolution] = useState(false)

    const history = useHistory()

    const canProvideAlternativeRecommendation = permissionsControl([
        'can_provide_recommended_solution',
    ])

    const { headerText, subTitle, actionText, actionType } =
        alternativeRecomActionModalValues(action)

    const handleShowModal = data => {
        setViewSelectedSolution(data)
        setShowRecommendationModal(true)
    }

    const handleCloseShowModal = () => {
        setTriggerSetSolution(!triggerSetSolution)
        setShowRecommendationModal(false)
    }

    const sendRecommendationsToConsumerMutation = useMutation({
        mutationFn: () => sendRecommendationsToConsumerApi(params?.id),
        onSuccess: () => setAction('recommendations-sent'),
        onError: err => {
            setToastErrorMsg(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            )
            setToastError(true)
        },
    })

    useEffect(() => {
        setPaymentModel(determinePaymentModelString(recommendations))
    }, [recommendations])
    return (
        <>
            <SeoComponent
                title={'SunFi - Admin Selected Alternative Recommendations'}
                tracker="AdminPaymentPlansTracker"
            />
            {/* modals */}
            <GetModal
                app="energyrecommendations"
                modalWidth="550px"
                modalHeight="100%"
                showModal={showRecommendationModal}
                onCancel={() => handleCloseShowModal()}
                content={
                    <ViewRecommendation
                        closeModal={handleCloseShowModal}
                        viewSelectedSolution={viewSelectedSolution}
                        selectedSolutions={data?.data}
                        dropOffId={params?.id}
                        estimationId={data?.data?.id}
                        module={'alt-recommendations'}
                        handleUpdateAltPackageSuccess={() => {
                            recommendationsRefetch()
                        }}
                        extensivePackageData={extensivePackageData}
                        setExtensivePackageData={setExtensivePackageData}
                        triggerSetSolution={triggerSetSolution}
                    />
                }
            />

            <ActionModal
                actionModalOpen={showActionModal}
                headerText={headerText}
                subTitle={subTitle}
                actionText={actionText}
                actionType={actionType}
                onCancel={() => {
                    if (action === 'recommendations-sent') {
                        history.push(
                            `/admin/alternative-recommendations/${params?.id}`,
                        )
                    } else {
                        setShowActionModal(false)
                    }
                }}
                closable
                actionHandler={() => {
                    if (action === 'initiate-sending-recommendations') {
                        sendRecommendationsToConsumerMutation.mutate()
                    }
                    if (action === 'recommendations-sent') {
                        history.push(
                            `/admin/alternative-recommendations/${params?.id}`,
                        )
                    }
                }}
                loading={sendRecommendationsToConsumerMutation.isLoading}
                noCancelBtn={action === 'recommendations-sent'}
                noBackLink
                toastError={toastError}
                errorMessage={toastErrorMsg}
                actionHeaderClassName={styles.sar_actionheader}
            />

            {/* drawer */}
            <AlternativePackages
                showAlternativePackages={showCustomPackages}
                setShowAlternativePackages={setShowCustomPackages}
                initialPackages={recommendations}
                module={'selected-alt-recommendations'}
                handlePackagesReselectionSuccess={() => {
                    recommendationsRefetch()
                    setShowCustomPackages(false)
                }}
                subscriptionSupported={paymentModel === 'Subscription'}
                leaseToOwnSupported={paymentModel === 'Lease to Own'}
                canEnableFetch={data?.data?.length > 0 && paymentModel !== ''}
            />

            <div className={styles.wrapper}>
                <BackNav
                    v2
                    title="Back to alternative recommendations"
                    onClick={() =>
                        history.push(`/admin/alternative-recommendations`)
                    }
                />

                <div className={styles.top_title_wrapper}>
                    <h3>Provide alternative recommendations</h3>
                    <ConsumerButton
                        btnBgColor="#004AAD"
                        btnTextColor="#fff"
                        btnHeight="50px"
                        btnWidth="185px"
                        handleClick={() => {
                            setAction('initiate-sending-recommendations')
                            setShowActionModal(true)
                        }}
                        disabled={!canProvideAlternativeRecommendation}
                        toolTipText={
                            canProvideAlternativeRecommendation
                                ? null
                                : 'You are not authorised to perform this action'
                        }
                    >
                        Send to consumer
                    </ConsumerButton>
                </div>

                <div className={styles.bottom_title_wrapper}>
                    <h4>
                        {paymentModel} <span>Recommendations</span>
                    </h4>
                    <button
                        onClick={() => {
                            setShowCustomPackages(true)
                        }}
                    >
                        <Layers />
                        Add or remove a package
                    </button>
                </div>
                {isLoadingRecommendations ? (
                    <RecommendedSkeletalCards total={6} />
                ) : (
                    <div className={styles.recommendations_wrapper}>
                        {recommendations?.map((data, index) => (
                            <div className={styles.CardWrapper} key={index}>
                                <div className={styles.FirstWrapper}>
                                    <div className={styles.FirstWrapper_txt}>
                                        <p
                                            className={
                                                styles.RecommendationSolution
                                            }
                                        >
                                            {data?.best_fit
                                                ? `BEST MATCH`
                                                : remarkMapping[
                                                      data
                                                          ?.recommendation_context
                                                          ?.remark
                                                  ]}
                                        </p>
                                        <h2>{data?.name}</h2>
                                    </div>

                                    <ConsumerButton
                                        btnBgColor="#F9FCFF"
                                        btnTextColor="#004aad"
                                        btnHeight="36px"
                                        btnWidth="99px"
                                        handleClick={() =>
                                            handleShowModal(data)
                                        }
                                    >
                                        View
                                    </ConsumerButton>
                                </div>
                                <p className={styles.RecommenadationParagraph}>
                                    {truncateText(
                                        data?.recommendation_context
                                            ?.recommendation_note ||
                                            data?.recommendation_context
                                                ?.note ||
                                            '',
                                        20,
                                    )}
                                </p>
                                <div className={styles.SecondWrapper}>
                                    <div>
                                        <div
                                            className={
                                                styles.RecommendationTitle
                                            }
                                        >
                                            PACKAGE AMOUNT
                                        </div>
                                        <p
                                            className={
                                                styles.RecommendationFigure
                                            }
                                        >
                                            <NairaSymbol />{' '}
                                            {currencyFormatter(
                                                data?.total_cost,
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                styles.RecommendationTitle
                                            }
                                        >
                                            PAYMENT PLANS
                                        </div>
                                        <p
                                            className={
                                                styles.RecommendationFigure
                                            }
                                        >
                                            {data?.number_of_plans}
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                styles.RecommendationTitle
                                            }
                                        >
                                            COMPONENTS
                                        </div>
                                        <p
                                            className={
                                                styles.RecommendationFigure
                                            }
                                        >
                                            {data?.number_of_components}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default SelectedAlternativeRecommendations
