import arrow from 'src/assets/images/arrowDown.svg'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Skeleton } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { useState } from 'react'

const SwitchCluster = ({
    clusterDetails,
    clusterList,
    handleClusterListSearch,
    fetchingClusterList,
}) => {
    const [searchQuery, setSearchQuery] = useState('')
    const history = useHistory()

    const handleApplyFilter = () => {
        handleClusterListSearch(searchQuery)
    }

    const dropdownMenu = (
        <div className={styles.menu}>
            <Menu>
                <div className={styles.search}>
                    <SearchIcon role="button" />
                    <input
                        placeholder="Search for cluster group"
                        onChange={e => setSearchQuery(e.target.value)}
                        onBlur={handleApplyFilter}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                handleApplyFilter()
                                e.target.blur()
                            }
                        }}
                        onKeyUp={handleApplyFilter}
                    />
                </div>

                {fetchingClusterList ? (
                    Array.from({ length: 4 }).map((_, index) => (
                        <div key={index} className={styles.loader}>
                            <Skeleton.Avatar />
                            <Skeleton.Button
                                style={{
                                    width: '200px',
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <div className={styles.clusterList}>
                        {clusterList?.map(cluster => (
                            <MenuItem key={cluster?.id}>
                                <span
                                    onClick={() =>
                                        history.push(
                                            `/rep/workspace/${cluster?.id}`,
                                        )
                                    }
                                    className={styles.nameAndLogo}
                                >
                                    <img
                                        className={styles.logo}
                                        src={cluster?.logo}
                                    />
                                    <p>{cluster?.name}</p>
                                </span>
                            </MenuItem>
                        ))}
                        {clusterList?.length === 0 && (
                            <MenuItem>
                                <p className={styles.noClusterMsg}>
                                    No Cluster Available
                                </p>
                            </MenuItem>
                        )}
                    </div>
                )}
            </Menu>
        </div>
    )

    return (
        <div>
            <div className={styles.nameContainer}>
                <div className={styles.nameAndLogo}>
                    <img className={styles.logo} src={clusterDetails?.logo} />
                    <h4>{clusterDetails?.name}</h4>
                </div>
                <Dropdown trigger={['click']} overlay={dropdownMenu}>
                    <img className={styles.arrow} src={arrow} alt="arrow" />
                </Dropdown>
            </div>
        </div>
    )
}

SwitchCluster.propTypes = {
    clusterDetails: PropTypes.object,
    clusterList: PropTypes.array,
    handleClusterListSearch: PropTypes.func,
    fetchingClusterList: PropTypes.bool,
}

export default SwitchCluster
