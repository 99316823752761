import PropTypes from 'prop-types'
import s from './styles.module.scss'
import { creditReportFileName, membersListValues } from './utils'
import NairaSymbol from 'src/components/NairaSymbol'
import { ReactComponent as Dots } from 'src/assets/images/dots.svg'
import { Dropdown, Pagination } from 'antd'
import DropdownMenu from './components/DropdownMenu'
import { ReactComponent as WarningIcon } from 'src/assets/images/Circle_Warning.svg'
import { ReactComponent as AttachIcon } from 'src/assets/images/clip.svg'
import { truncateSentence } from 'src/utils/formatting'
import CustomToolTip from 'src/components/CustomToolTip'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { isMobile } from 'src/utils/mediaQueries'
import { Fragment } from 'react'
import EmptyState from '../EmptyState'

const MembersList = ({
    pageAction,
    members,
    membersWithDuePayment,
    openMemberDetailsModal,
    storeActionAndOpenModal,
    totalPages,
    currentPage,
    updatePage,
    membersListFiltered,
    creditReportFiles,
    membersWithNoCreditReport,
    storeActionAndOpenUploadModal,
    newMembersList,
    membersLoading,
}) => {
    const {
        list,
        tableHeaders,
        isEmpty,
        emptyHeading,
        emptyDescription,
        rowClass,
    } = membersListValues(
        pageAction,
        members,
        membersWithDuePayment,
        membersListFiltered,
        membersWithNoCreditReport,
        newMembersList,
    )
    const Menu = row => (
        <Dropdown
            trigger={['click']}
            overlay={
                <DropdownMenu
                    openMemberDetailsModal={openMemberDetailsModal}
                    storeActionAndOpenModal={storeActionAndOpenModal}
                    status={row.member_status}
                    member={row}
                    pageAction={pageAction}
                    storeActionAndOpenUploadModal={
                        storeActionAndOpenUploadModal
                    }
                />
            }
            placement="bottomRight"
        >
            <a onClick={e => e.preventDefault()}>
                <div className={s.dotsWrapper}>
                    <Dots
                        role="button"
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </div>
            </a>
        </Dropdown>
    )

    const creditReportFile = row => (
        <>
            {creditReportFileName(creditReportFiles, row) ? (
                <div className={s.docName}>
                    <AttachIcon />
                    <span>
                        {row?.email_address}
                        .pdf
                    </span>
                </div>
            ) : (
                <CustomToolTip text="Document not found, ensure that the file name is the member's email address">
                    <p className={s.noDocumentFound}>
                        No Document Found
                        <span>
                            <WarningIcon />
                        </span>
                    </p>
                </CustomToolTip>
            )}
        </>
    )

    const memberFileStatus = (isValid, errors) => {
        const errs = Object.keys(errors)
        return (
            <>
                {!isValid ? (
                    <span className={s.uploadVerificationFailed}>
                        {errs.length === 1 ? (
                            <>Inavlid {errs[0]}</>
                        ) : (
                            <>{errs.length} Failed Inputs</>
                        )}

                        <CustomToolTip
                            text={
                                errs.length > 1 ? (
                                    <>
                                        {errs.map((error, index) => (
                                            <Fragment key={index}>
                                                {error}
                                                <br />
                                            </Fragment>
                                        ))}
                                    </>
                                ) : (
                                    errors[errs[0]]
                                )
                            }
                            toolTipFontSize="10px"
                            toolTipPadding="9px 12px 9px 8px"
                            toolTipPopupWidth="auto"
                            arrowPositionCenter
                            toolTipMarginLeft={isMobile ? -75 : -180}
                        >
                            <span>
                                <WarningIcon
                                    style={{
                                        height: '10px',
                                        width: '10px',
                                    }}
                                />
                            </span>
                        </CustomToolTip>
                    </span>
                ) : (
                    <span className={s.completedTag}>Completed</span>
                )}
            </>
        )
    }

    return (
        <div>
            {membersLoading ? (
                <ListingsSkeletalCards total={0} columnCount={5} />
            ) : !membersLoading && isEmpty ? (
                <EmptyState
                    heading={emptyHeading}
                    description={emptyDescription}
                />
            ) : (
                <>
                    <div>
                        <div className={s.mobileTable}>
                            {pageAction !==
                            'upload-multiple-cluster-members' ? (
                                <>
                                    {list.map(row => (
                                        <div
                                            key={row.id}
                                            className={s.mobileCard}
                                        >
                                            <div className={s.nameAndOptions}>
                                                <p>{row?.name}</p>
                                                {Menu(row)}
                                            </div>
                                            <div className={s.emailAndStatus}>
                                                <div>
                                                    <p className={s.title}>
                                                        Email Address
                                                    </p>
                                                    <p
                                                        className={
                                                            s.emailAddress
                                                        }
                                                    >
                                                        {row?.email_address
                                                            ? truncateSentence(
                                                                  row?.email_address,
                                                                  12,
                                                              )
                                                            : 'N/A'}
                                                    </p>
                                                </div>
                                                {pageAction ===
                                                'upload-multiple-credit-report' ? (
                                                    <div>
                                                        <p
                                                            className={s.title}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            Credit Report
                                                        </p>

                                                        {creditReportFile(row)}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p className={s.title}>
                                                            Status
                                                        </p>

                                                        {row?.system_size
                                                            ?.value &&
                                                        !row?.has_credit_report ? (
                                                            <span
                                                                className={
                                                                    s.noCreditReportTag
                                                                }
                                                            >
                                                                NO CREDIT REPORT
                                                            </span>
                                                        ) : row?.due_payment ? (
                                                            <span
                                                                className={
                                                                    s.dueTag
                                                                }
                                                            >
                                                                due
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className={
                                                                    row?.member_status ===
                                                                    'Active'
                                                                        ? s.statusActive
                                                                        : s.statusInactive
                                                                }
                                                            >
                                                                {
                                                                    row?.member_status
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {list?.map(({ row, errors, valid }, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={s.mobileCard}
                                            >
                                                <div className={s.groupMembers}>
                                                    <div>
                                                        {Object.keys(row)
                                                            .slice(0, 2)
                                                            ?.map(m => (
                                                                <div key={m}>
                                                                    <p
                                                                        className={
                                                                            s.title
                                                                        }
                                                                    >
                                                                        {m}
                                                                    </p>
                                                                    {row[m]}
                                                                </div>
                                                            ))}
                                                    </div>
                                                    <div>
                                                        <div>{Menu(row)}</div>

                                                        <div>
                                                            {memberFileStatus(
                                                                valid,
                                                                errors,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                        <div
                            className={`${
                                pageAction === 'upload-multiple-cluster-members'
                                    ? s.overflowTable
                                    : ''
                            }`}
                        >
                            <table className={s.table}>
                                <tr className={s.tableHead}>
                                    {tableHeaders?.map((header, i) => (
                                        <th key={i}>{header}</th>
                                    ))}
                                </tr>
                                <>
                                    {pageAction !==
                                    'upload-multiple-cluster-members' ? (
                                        <>
                                            {list?.map((row, i) => (
                                                <tr
                                                    key={i}
                                                    className={s[rowClass]}
                                                >
                                                    <td>{row?.name}</td>
                                                    <td>
                                                        {row?.location
                                                            ? truncateSentence(
                                                                  row?.location,
                                                                  12,
                                                              )
                                                            : 'N/A'}
                                                    </td>
                                                    {pageAction ===
                                                        'view-dashboard' && (
                                                        <>
                                                            <td>
                                                                {row?.system_size
                                                                    ? `${row?.system_size?.value} ${row?.system_size?.unit_of_measurement}`
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>
                                                                {row?.monthly_payment ? (
                                                                    <>
                                                                        <NairaSymbol />
                                                                        {
                                                                            row?.monthly_payment
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    'N/A'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {row
                                                                    ?.system_size
                                                                    ?.value &&
                                                                !row?.has_credit_report ? (
                                                                    <span
                                                                        className={
                                                                            s.noCreditReportTag
                                                                        }
                                                                    >
                                                                        NO
                                                                        CREDIT
                                                                        REPORT
                                                                    </span>
                                                                ) : row?.due_payment ? (
                                                                    <span
                                                                        className={
                                                                            s.dueTag
                                                                        }
                                                                    >
                                                                        due
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className={
                                                                            row?.member_status ===
                                                                            'Active'
                                                                                ? s.statusActive
                                                                                : s.statusInactive
                                                                        }
                                                                    >
                                                                        {
                                                                            row?.member_status
                                                                        }
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>{Menu(row)}</td>
                                                        </>
                                                    )}
                                                    {pageAction ===
                                                        'make-payment' && (
                                                        <>
                                                            <td>
                                                                {
                                                                    row?.email_address
                                                                }
                                                            </td>
                                                            <td>
                                                                <NairaSymbol />{' '}
                                                                {
                                                                    row?.monthly_payment
                                                                }
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className={
                                                                        s.dueTag
                                                                    }
                                                                >
                                                                    due
                                                                </span>
                                                            </td>
                                                        </>
                                                    )}
                                                    {pageAction ===
                                                        'upload-multiple-credit-report' && (
                                                        <>
                                                            <td>
                                                                {
                                                                    row?.email_address
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    row
                                                                        ?.identity_verification
                                                                        ?.type
                                                                }
                                                            </td>
                                                            <td>
                                                                {creditReportFile(
                                                                    row,
                                                                )}
                                                            </td>
                                                            <td>{Menu(row)}</td>
                                                        </>
                                                    )}
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {list?.map(
                                                ({ row, errors, valid }, i) => (
                                                    <tr
                                                        key={i}
                                                        className={s[rowClass]}
                                                    >
                                                        {Object.keys(row)?.map(
                                                            key => (
                                                                <td key={key}>
                                                                    {truncateSentence(
                                                                        row[
                                                                            key
                                                                        ],
                                                                        30,
                                                                    )}
                                                                </td>
                                                            ),
                                                        )}
                                                        <td>
                                                            {memberFileStatus(
                                                                valid,
                                                                errors,
                                                            )}
                                                        </td>
                                                        <td>{Menu(row)}</td>
                                                    </tr>
                                                ),
                                            )}
                                        </>
                                    )}
                                </>
                            </table>
                        </div>
                    </div>
                    {totalPages > 1 &&
                        pageAction !== 'upload-multiple-credit-report' && (
                            <div className={s.paginationContainer}>
                                <Pagination
                                    defaultCurrent={1}
                                    current={currentPage}
                                    total={parseInt(totalPages, 10)}
                                    defaultPageSize={1}
                                    showSizeChanger={false}
                                    showTitle={false}
                                    showLessItems
                                    onChange={updatePage}
                                />
                            </div>
                        )}
                </>
            )}
        </div>
    )
}

MembersList.propTypes = {
    pageAction: PropTypes.string,
    members: PropTypes.array,
    membersWithDuePayment: PropTypes.array,
    openMemberDetailsModal: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    totalPages: PropTypes.number,
    currentPage: PropTypes.string,
    updatePage: PropTypes.func,
    membersListFiltered: PropTypes.bool,
    creditReportFiles: PropTypes.array,
    membersWithNoCreditReport: PropTypes.array,
    storeActionAndOpenUploadModal: PropTypes.func,
    newMembersList: PropTypes.array,
    membersLoading: PropTypes.bool,
}

export default MembersList
