import React from 'react'
import { isMobile } from 'src/utils/mediaQueries'
import ActivePlansTable from './components/ActivePlansTable'
import './consumerworkspace.scss'
import { formatCardsData, actionModalValues } from './data'
import { Carousel, Skeleton, Tabs } from 'antd'
import RecentUpdates from './components/RecentUpdates'
import SeoComponent from 'src/components/Seo'
import DecisionIsYes from '../SmartShoppingExperience/CreditDecision/components/DecisionIsYes'
import Toast from 'src/components/Toast'
import NairaSymbol from 'src/components/NairaSymbol'
import ActionModal from 'src/components/ActionModalV2'
import { getGreetingTime } from 'src/utils/formatting'
import { ConsumerButton } from 'src/components/Button'
import RequestNewDealModal from './components/RequestNewDealModal'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as NewPackageIcon } from 'src/assets/images/new-package.svg'
import PackageDrawer from '../SmartShoppingExperience/AccountSetupOverview/components/PackageDrawer'
import DisputePackageModal from '../SmartShoppingExperience/AccountSetupOverview/components/DisputePackageModal'
import useConsumerWorkspace from './hook'
import { addQueryParamsToUrl } from 'src/utils/urlTracker'
import TenureChangeModal from './components/TenureChangeModal'
import IncreaseUpfrontDepositModal from './components/IncreaseDepositModal'

const ConsumerWorkspace = () => {
    const {
        consumerWorkspaceTabs,
        showSubTenure,
        setShowSubTenure,
        showSubscriptionPlan,
        setShowSubscriptionPlan,
        showisLeaseToOwnPlan,
        setShowisLeaseToOwnPlan,
        handleShowSubscriptionPlan,
        handleDisputeBtnClick,
        handlePrimaryBtnClick,
        handleDisputePackage,
        setShowPackageDetailsDrawer,
        activePlans,
        selectedEstimation,
        setSelectedEstimation,
        action,
        setAction,
        showActionModal,
        setShowActionModal,
        showPackageDetailsDrawer,
        paystackLoading,
        handleSendAgreementReminder,
        handleLeaseRepaymentSetup,
        handlePaystackModal,
        handleShowisLeaseToOwnPlan,
        allUpdates,
        loadingUpdates,
        errorMessage,
        toastError,
        currentTab,
        setCurrentTab,
        toggleRequestNewDealModal,
        returningCustomerStatus,
        allowReturningCustomers,
        userInfo,
        showDisputePackageModal,
        setShowDisputePackageModal,
        sseConsumerEstimationRefetch,
        setShowNewPackageModal,
        showNewPackageModal,
        setPaystackLoading,
        setShowAgreementSigningConfirmationModal,
        isPendingAgreementSigning,
        showAgreementSigningConfirmationModal,
        repaymentMethodType,
        accountDetails,
        verifyPaystackError,
        sseConsumerEstimationData,
        sseConsumerEstimationSummaryRefetch,
        fetchAllUpdates,
        isSubscriptionPlan,
        estimationId,
        isWithSuggestion,
        showPaystackModal,
        setShowPaystackModal,
        showRequestNewDealModal,
        sseConsumerEstimationSummaryData,
        sseConsumerEstimationSummaryLoading,
        activePlan,
        allowPostApprovalChanges,
        showTenureChangeModal,
        tenureChangeAction,
        openTenureChangeModal,
        closeTenureChangeModal,
        setTenureChangeAction,
        selectedPlanId,
        planWithUpdatedTenure,
        closeDepositChangeModal,
        showIncreaseDepositModal,
        increaseDepositAction,
        setIncreaseDepositAction,
        openDepositChangeModal,
        openNewPackageModal,
        initialDeposit,
        openCardDetailsModal,
    } = useConsumerWorkspace()

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(action, verifyPaystackError)

    const CreditSummary = formatCardsData(
        sseConsumerEstimationSummaryData?.data?.data,
    )?.map((item, i) => (
        <div key={i} className={'ConsumerWorkspaceSummary_Card'}>
            <div>
                <div className={'ConsumerWorkspaceSummary_Card_Icon'}>
                    {item?.icon}
                </div>
                <h4>{item?.title}</h4>
                <p>
                    {sseConsumerEstimationSummaryLoading ? (
                        <Skeleton.Input active={true} style={{ height: 24 }} />
                    ) : (
                        <>
                            <NairaSymbol /> {item?.value}
                        </>
                    )}
                </p>
            </div>
        </div>
    ))

    const AppliancesFromProvider = () => (
        <p className="ASO_AppliancesText">
            {activePlan?.appliances_from_provider?.map(
                (appliance, index, array) => (
                    <React.Fragment key={index}>
                        <span>{appliance?.quantity}</span> {appliance?.name}
                        {index < array.length - 1 && ', '}
                    </React.Fragment>
                ),
            )}
        </p>
    )

    return (
        <>
            <ActionModal
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                secondaryBtnText={actionModalSecondaryBtnText}
                footerLeftContent={actionModalFooterLeftContent}
                actionModalOpen={showActionModal}
                onCancel={() => {
                    setAction('')
                    setShowActionModal(false)
                }}
                handlePrimaryBtnClick={handlePrimaryBtnClick}
                noCancelBtn={true}
                v2
                showFooter
            />

            <RequestNewDealModal
                showModal={showRequestNewDealModal}
                onCancel={toggleRequestNewDealModal}
            />

            <SeoComponent title="Consumer Workspace | SunFi | Simplifying and Scaling Clean Energy" />
            <DecisionIsYes
                showPaymentPlan={showisLeaseToOwnPlan}
                setShowPaymentPlan={setShowisLeaseToOwnPlan}
                showSubscriptionPlan={showSubscriptionPlan}
                setShowSubscriptionPlan={setShowSubscriptionPlan}
                updates={allUpdates}
                showPaystackModal={showPaystackModal}
                setShowPaystackModal={setShowPaystackModal}
                consumerFirstName={userInfo.firstName}
                isWithSuggestion={isWithSuggestion}
                estimationId={estimationId}
                refetchUpdates={() => {
                    if (isSubscriptionPlan) {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: {
                                isSubscription: false,
                            },
                        })
                    }
                    fetchAllUpdates()
                    sseConsumerEstimationRefetch()
                    sseConsumerEstimationSummaryRefetch()
                }}
                consumerEmail={userInfo?.email}
                consumerPhoneNumber={userInfo?.phone}
                consumerEstimationData={sseConsumerEstimationData?.data?.data}
                accountDetails={accountDetails}
                repaymentMethodType={repaymentMethodType}
                showSubTenure={showSubTenure}
                setShowSubTenure={setShowSubTenure}
                isPendingAgreementSigning={isPendingAgreementSigning}
                showAgreementSigningConfirmationModal={
                    showAgreementSigningConfirmationModal
                }
                setShowAgreementSigningConfirmationModal={
                    setShowAgreementSigningConfirmationModal
                }
                setPaystackLoading={setPaystackLoading}
                isEmailVerified={userInfo.isEmailVerified}
                isPhoneNumberVerified={userInfo.isPhoneNumberVerified}
                postApprovalOnSuccess={() => {
                    openDepositChangeModal('payment-made-successfully')
                    fetchAllUpdates()
                    sseConsumerEstimationRefetch()
                    sseConsumerEstimationSummaryRefetch()
                }}
            />

            <SecondaryModal
                showModal={showNewPackageModal}
                modalWidth={'475px'}
                closable
                onCancel={() => {
                    setShowNewPackageModal(false)
                }}
                title="You have a new package"
                primaryBtnText="Proceed"
                v2
                showFooter
                footerLeftContent="chat"
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                content={
                    <div className="NewPackageModal">
                        <NewPackageIcon />
                        <h3>New Package</h3>
                        <p>
                            A new package has been suggested to you by your
                            provider. Kindly look through it.
                        </p>
                    </div>
                }
                handlePrimaryBtnClick={() => {
                    setShowNewPackageModal(false)
                    setShowPackageDetailsDrawer(true)
                }}
            />

            <PackageDrawer
                open={showPackageDetailsDrawer}
                estimation={activePlan}
                AppliancesFromProvider={AppliancesFromProvider}
                handleAcceptPackage={() => {
                    setShowPackageDetailsDrawer(false)
                    setAction('accept-post-approval-package-change')
                    setShowActionModal(true)
                    sseConsumerEstimationRefetch()
                }}
                handleDisputePackage={handleDisputePackage}
                fetchUpfrontDeposit={false}
                isPostApproval={allowPostApprovalChanges}
            />

            <DisputePackageModal
                showModal={showDisputePackageModal}
                onCancel={() => {
                    setShowDisputePackageModal(false)
                }}
                onClick={handleDisputeBtnClick}
                title={'Change your package'}
                estimation_id={activePlan?.id}
                showDEPConsumerChangePackageOption={true}
                type="reject-new-post-approval-package"
                postApprovalRequest={allowPostApprovalChanges}
            />

            <TenureChangeModal
                showModal={showTenureChangeModal}
                closeTenureChangeModal={closeTenureChangeModal}
                tenureChangeAction={tenureChangeAction}
                setTenureChangeAction={setTenureChangeAction}
                estimationId={selectedPlanId}
                planWithUpdatedTenure={planWithUpdatedTenure}
            />

            <IncreaseUpfrontDepositModal
                showModal={showIncreaseDepositModal}
                closeDepositChangeModal={closeDepositChangeModal}
                depositChangeAction={increaseDepositAction}
                setDepositChangeAction={setIncreaseDepositAction}
                openCardDetailsModal={openCardDetailsModal}
                estimationId={selectedEstimation}
                initialDeposit={initialDeposit}
            />

            <div className={'ConsumerWorkspaceWrapper'}>
                <div className="ConsumerWorkspaceHeadingWrapper">
                    <h1 className={'ConsumerWorkspaceHeading'}>
                        Good {getGreetingTime()}
                        <span>, {userInfo.firstName}</span>
                    </h1>
                    {allowReturningCustomers &&
                        returningCustomerStatus === 'ACTIVE_OR_CLOSED_PLAN' && (
                            <ConsumerButton
                                btnTextColor={'#FFFFFF'}
                                btnBgColor={'#004AAD'}
                                btnWidth={'142px'}
                                btnHeight={'44px'}
                                handleClick={toggleRequestNewDealModal}
                            >
                                Request New Deal
                            </ConsumerButton>
                        )}
                </div>
                {isMobile ? (
                    <Carousel
                        className="ConsumerWorkspaceSummary_Mobile"
                        autoplay
                    >
                        {CreditSummary}
                    </Carousel>
                ) : (
                    <div className={'ConsumerWorkspaceSummary'}>
                        {CreditSummary}
                    </div>
                )}
                <div className={'ConsumerWorkspace_Tabs'}>
                    <Tabs
                        defaultActiveKey="1"
                        activeKey={currentTab}
                        onChange={values => {
                            setCurrentTab(values)
                            addQueryParamsToUrl({
                                tab: consumerWorkspaceTabs[values],
                            })
                        }}
                    >
                        <Tabs.TabPane tab="Recent Update" key="1">
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            <RecentUpdates
                                loading={loadingUpdates}
                                data={allUpdates}
                                activePlans={activePlans}
                                handleShowPaymentPlan={
                                    handleShowisLeaseToOwnPlan
                                }
                                handleSubscriptionPlan={
                                    handleShowSubscriptionPlan
                                }
                                handlePaystackModal={handlePaystackModal}
                                handleLeaseRepaymentSetup={
                                    handleLeaseRepaymentSetup
                                }
                                handleSendAgreementReminder={
                                    handleSendAgreementReminder
                                }
                                btnLoading={paystackLoading}
                                handleActiveEstimation={setSelectedEstimation}
                                activeEstimation={selectedEstimation}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Active Plans" key="2">
                            <ActivePlansTable
                                activePlans={activePlans}
                                consumerType="DTC"
                                viewNewPackage={openNewPackageModal}
                                openTenureChangeModal={openTenureChangeModal}
                                openDepositChangeModal={openDepositChangeModal}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default ConsumerWorkspace
