import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from './resourcetable.module.scss'
import { ReactComponent as Close } from '../../../../../assets/images/close-icon.svg'
import ActionModal from '../../../../../components/ActionModal'
import EmptyState from '../../../../../components/EmptyState'
import { useQuery } from 'react-query'
import { removeProviderResourceApi } from '../../../../../api/admin/provider'
import { errorHandler } from '../../../../../utils/errorHandler'
import { getPermissions } from '../data'
import CustomToolTip from 'src/components/CustomToolTip'

const ProviderResourcesTable = ({
    resources,
    providerId,
    setTriggerRefetch,
    setTriggerResourceRefetch,
    pending,
}) => {
    const { canRemoveResourcePermissions } = getPermissions()

    const [selectedResource, setSelectedResource] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const { TableItem, RightSection, CloseButton, CloseBtnDisabled } = styles

    const { refetch: removeResourceRefetch, isLoading: removeResourceLoading } =
        useQuery(
            ['remove-provider-resource'],
            () => removeProviderResourceApi(providerId, selectedResource),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setShowModal(false)
                    setSuccessModal(true)
                    setTriggerRefetch(true)
                    setTriggerResourceRefetch(true)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    const handleRemoveClick = item => {
        if (!canRemoveResourcePermissions[item?.name]) return
        setSelectedResource({
            provider_resource: item?.name,
            resource_id: item?.id,
        })
        setShowModal(true)
    }

    const handleRemoveAction = () => {
        removeResourceRefetch()
    }

    return (
        <>
            <ActionModal
                actionType="warning"
                actionModalOpen={showModal}
                headerText="Remove Resource"
                subTitle="Do you want to remove the provider’s resource?"
                actionHandler={handleRemoveAction}
                closeModal={() => setShowModal(false)}
                cancelText="Cancel"
                actionText="Remove"
                toastError={toastError}
                errorMessage={errorMessage}
                loading={removeResourceLoading}
                noBackLink
            />
            <ActionModal
                actionType="success"
                actionModalOpen={successModal}
                headerText="Resource Removed Successfully"
                subTitle="The provider resource has successfully been removed"
                actionText="Okay, Got it"
                onCancel={() => {
                    setSuccessModal(false)
                }}
                actionHandler={() => {
                    setSuccessModal(false)
                }}
                closable
                noBackLink
                noCancelBtn
            />
            {resources?.length < 1 ? (
                <EmptyState
                    title="Nothing’s here"
                    subTitle={
                        pending
                            ? 'You need to approve this account first'
                            : 'You’ve not added a resource to the workspace yet'
                    }
                />
            ) : (
                <div>
                    {resources?.map((item, i) => (
                        <div key={i} className={TableItem}>
                            <h4>Resource {i + 1}</h4>
                            <div className={RightSection}>
                                <p>{item?.name}</p>
                                {canRemoveResourcePermissions[item?.name] ? (
                                    <div
                                        className={CloseButton}
                                        onClick={() => handleRemoveClick(item)}
                                    >
                                        <Close />
                                    </div>
                                ) : (
                                    <CustomToolTip
                                        text="You are not authorised to perform this action"
                                        toolTipFontSize="12px"
                                        toolTipMarginLeft="-73px"
                                        toolTipPopupWidth="148px"
                                        top="60%"
                                        arrowPositionCenter
                                    >
                                        <div
                                            className={CloseBtnDisabled}
                                            onClick={() =>
                                                handleRemoveClick(item)
                                            }
                                            aria-disabled
                                        >
                                            <Close />
                                        </div>
                                    </CustomToolTip>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

ProviderResourcesTable.propTypes = {
    resources: PropTypes.array,
    providerId: PropTypes.string,
    setTriggerRefetch: PropTypes.func,
    setTriggerResourceRefetch: PropTypes.func,
    pending: PropTypes.any,
}

export default ProviderResourcesTable
