import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getAdminBankStatement } from 'src/api/admin/bank-statement'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

export const useGetBankStatement = (consumerId, isAdminUser, onSuccess) => {
    const { errorAlert } = useCustomToast()
    const [consumerBankStatements, setConsumerBankStatements] = useState([])
    const [errorCode, setErrorCode] = useState(0)

    const { refetch, isLoading: ConsumerBankStatementIsLoading } = useQuery({
        queryKey: ['getConsumerBankStatement'],
        queryFn: () => getAdminBankStatement(consumerId),
        enabled: consumerId && isAdminUser ? true : false,
        onSuccess: data => {
            setConsumerBankStatements(data.data?.data?.bank_statements)
            onSuccess?.(data)
        },
        onError: error => {
            setErrorCode(error?.response?.status)
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't get bank statement. please try again`,
            )
        },
    })

    useEffect(() => {
        if (isAdminUser && consumerId) {
            refetch()
        }
    }, [refetch, consumerId, isAdminUser])

    return {
        consumerBankStatements,
        ConsumerBankStatementIsLoading,
        notAuthorized: errorCode === 403,
    }
}

export default useGetBankStatement
