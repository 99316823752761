import { ReactComponent as MoneyIcon } from 'src/assets/images/money.svg'
import { ReactComponent as GiftIcon } from 'src/assets/images/gift.svg'

export const formatCardsData = data => {
    return [
        {
            icon: <MoneyIcon />,
            title: 'TOTAL OUTSTANDING',
            value: data?.total_outstanding,
        },
        {
            icon: <GiftIcon />,
            title: 'MONTHLY REPAYMENT',
            value: data?.monthly_repayment,
        },
        {
            icon: <GiftIcon />,
            title: 'AMOUNT FINANCED',
            value: data?.amount_financed,
        },
    ]
}

export const formattedActivePlans = data => {
    const formattedData = data?.map(item => {
        return {
            plan: item?.plan_name,
            monthlyPayment: [null, undefined].includes(
                item?.admin_approved_repayment_plan?.monthly_repayment,
            )
                ? '-'
                : `${item?.admin_approved_repayment_plan?.monthly_repayment}`,
            tenureDuration: [null, undefined].includes(
                item?.admin_approved_repayment_plan?.tenure,
            )
                ? '-'
                : `${item?.admin_approved_repayment_plan?.tenure} Months`,
            packageType: item?.payment_plan ?? '-',
            id: item?.id,
            applicationStatus: item?.application_status,
            approvalStatus: item?.approval_status,
            consumerStatus: item?.consumer_status,
            initialDeposit:
                item?.admin_approved_repayment_plan?.initial_deposit,
        }
    })

    return formattedData
}

export const categories = {
    personalInformation: 'personal_information',
    creditInformation: 'credit_information',
    energyInformation: 'energy_information',
    recommendedSolutions: 'recommended_solutions',
    upfrontDeposit: 'upfront_deposit',
    repaymentSetup: 'repayment_setup',
    subscriptionPayment: 'subscription_payment',
    selectPaymentPlan: 'select_payment_plan',
    agreementSigning: 'signed_agreement',
}

export const actionModalValues = (action, verifyPaystackError) => {
    switch (action) {
        case 'verify-paystack-direct-debit':
            return {
                actionModalTitle: `Repayment Setup ${
                    verifyPaystackError ? 'Failed' : 'Successful'
                }`,
                actionModalHeaderText: verifyPaystackError
                    ? 'We couldn’t setup your repayment'
                    : "You've successfully setup your repayment",
                actionModalSubtitle: verifyPaystackError
                    ? 'There was an error setting up your repayment. Please try again'
                    : "We're really excited to have you onboard. Your repayment schedule will be available within 24 hours",
                actionModalActionType: verifyPaystackError
                    ? 'delete'
                    : 'success',
                actionModalPrimaryBtnText: verifyPaystackError
                    ? 'Okay, got it'
                    : 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'accept-post-approval-package-change':
            return {
                actionModalTitle: 'You have a new package',
                actionModalHeaderText: 'Package confirmed sucessfully',
                actionModalSubtitle:
                    'Congratulations! Your package change request has been successfully processed. A notification has been sent to our team, and your new package is now in place.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Okay! Got it',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-post-approval-package-rejection':
            return {
                actionModalTitle: 'Reject Package',
                actionModalHeaderText:
                    'Are you sure you want to reject the new package?',
                actionModalSubtitle:
                    'Please be informed that this action indicates your decision to disontinue the option to chnage to a new package. An email will be sent to our team to notify thme of this update.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'post-approval-new-package-disputed-successfully':
            return {
                actionModalTitle: 'Package dispute',
                actionModalHeaderText: 'We are looking into your concerns',
                actionModalSubtitle:
                    "We have informed our team about this issu. While they're looking into it, you will not be able to change this information. You can also follow up with them to speed up the process",
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: '',
                actionModalSecondaryBtnText: '',
            }
    }
}
