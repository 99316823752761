import { InputFields } from 'src/components/InputFields'
import { sharedFormComponentPropTypes } from '../../../DynamicInformation/components/Form/utils'
import s from './formfields.module.scss'

const DirectorFullName = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
    onFocus,
}) => {
    return (
        <>
            <div className={s.directorFullName}>
                <InputFields
                    name="directorFirstName"
                    title="Director's First Name"
                    value={inputs?.directorFirstName}
                    handleChange={handleChange}
                    errorMessage={errors?.directorFirstName}
                    onBlur={handleOnBlur}
                    disabled={isAdminUser || isCustomerApproved}
                    marginBottom={'16px'}
                    onFocus={onFocus}
                />

                <InputFields
                    name="directorLastName"
                    title="Director's Last Name"
                    value={inputs?.directorLastName}
                    handleChange={handleChange}
                    errorMessage={errors?.directorLastName}
                    onBlur={handleOnBlur}
                    disabled={isAdminUser || isCustomerApproved}
                    marginBottom={'16px'}
                    onFocus={onFocus}
                />
            </div>
        </>
    )
}

DirectorFullName.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default DirectorFullName
