import { useQuery } from 'react-query'
import { useState } from 'react'
import {
    getKYCCompletionStatus,
    getKYCCompletionStatusForAdmin,
} from 'src/api/shoppingExperience/account-overview'
import { formatKYCCompletionStatus } from 'src/utils/formatKYCCompletionStatus'
import { onePageGetKycOnboardingProgressProviderApi } from 'src/api/shoppingExperience/kyc/api'
import { getUserType } from '../getUserType'

export const useKYCCompletionStatus = (
    estimation_id,
    customer_type,
    isDynamicKycUser = false,
) => {
    const { isProviderAndPartnerKycAssistAllowed, isAdminUser } = getUserType()
    const [kycStatus, setKycStatus] = useState([])

    const { refetch: statusRefetch } = useQuery(
        'kycStatus',
        () => {
            if (isAdminUser) {
                return getKYCCompletionStatusForAdmin(estimation_id)
            }

            if (isProviderAndPartnerKycAssistAllowed) {
                return onePageGetKycOnboardingProgressProviderApi(estimation_id)
            }

            return getKYCCompletionStatus(estimation_id)
        },
        {
            enabled: estimation_id ? true : false,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                const formattedData = formatKYCCompletionStatus(
                    data,
                    customer_type,
                    isDynamicKycUser,
                )
                setKycStatus(formattedData)
            },
        },
    )
    return { kycStatus, statusRefetch }
}
