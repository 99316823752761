import PropTypes from 'prop-types'
import PaymentSummary from './PaymentSummary'
import { useState, useEffect } from 'react'
import { format, parse } from 'date-fns'
import { DateField, InputFields, SelectField } from 'src/components/InputFields'
import { CostField } from 'src/components/InputFields/CostField'
import { floatWithCommas } from 'src/utils/formatting'
import { Dropdown, Upload, Menu } from 'antd'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import Button from 'src/components/Button'
import { InlineLoader } from 'src/components/Loader'
import repaymentSetupMapping from 'src/utils/Data/repaymentSetupMapping'
import { markAPaymentApi } from 'src/api/admin/payment-plans/ssePlan'
import CustomToolTip from 'src/components/CustomToolTip'
import { ReactComponent as WarningIcon } from 'src/assets/images/info-warning-blue2.svg'
import { usePlanContext } from '../../hooks/planProvider'
import { useQueryClient } from 'react-query'
import UploadLogo from 'src/assets/images/upload.svg'
import plusBlueIcon from 'src/assets/images/Plus-Blue.svg'

const CollapsibleNewPaymentRecord = ({
    action,
    setIsMultiplePartialPaymentMode,
    estimationId,
    width,
}) => {
    const {
        setOpenModal,
        openModal,
        setSuccessModal,
        setSuccessHeaderText,
        errors,
        setErrors,
        uploadError,
        setUploadError,
    } = usePlanContext()
    const queryClient = useQueryClient()
    const paymentMethodData = [
        'Auto Payment Link',
        'Bank Transfer',
        'Direct Deposit',
    ]
    const [showCalendar, setShowCalendar] = useState(null)
    const [showTimeDropdown, setShowTimeDropdown] = useState(false)
    const [newPaymentAmount, setNewPaymentAmount] = useState('')
    const [newPaymentDate, setNewPaymentDate] = useState(null)
    const [newPaymentTime, setNewPaymentTime] = useState({
        hour: '',
        minute: '',
        time: '',
        timeOfAction: '',
        dateTime: '',
    })
    const [newPaymentMethod, setNewPaymentMethod] = useState('')
    const [isRecordingAnotherRepayment, setIsRecordingAnotherRepayment] =
        useState(false)
    const [uploadedFile, setUploadedFile] = useState([])
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [showNewPayment, setShowNewPayment] = useState(false)
    const { Dragger } = Upload

    const handleSetTimeAction = () => {
        if (!newPaymentDate) {
            setErrors(prev => ({
                ...prev,
                dateOfAction: 'Select the date of payment',
            }))
            setShowTimeDropdown(false)
        } else {
            const time = newPaymentTime.hour + ':' + newPaymentTime.minute
            let concatDate = new Date(newPaymentDate + ' ' + time)
            const formatTime = time.toString()
            if (newPaymentTime.hour && time) {
                setNewPaymentTime(prev => ({
                    ...prev,
                    time: time,
                    timeOfAction: format(
                        parse(formatTime, 'HH:mm', new Date()),
                        'hh:mm',
                    ),
                    dateTime: format(concatDate, "yyyy-MM-dd'T'HH:mm:ssxxx"),
                }))
                setShowTimeDropdown(false)
            }
        }
    }

    const timePaidDropdown = (
        <Menu>
            <Menu.Item>
                <div style={{ display: 'flex' }}>
                    <div className="TimePickerLeftWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={newPaymentTime.hour}
                                name="hour"
                                onChange={e =>
                                    setNewPaymentTime({
                                        ...newPaymentTime,
                                        hour: e.target.value,
                                    })
                                }
                                autoFocus
                            />
                            <CustomToolTip
                                text="Enter hours as digits from 0 to 23 only. Avoid numbers above 23 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Hour</p>
                        <Button
                            btnBgColor=" #E2EEFF"
                            btnWidth="110px"
                            btnTextColor="var(--blue)"
                            btnHeight="45px"
                            handleClick={() => setShowTimeDropdown(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <div
                            className="ApprovedModalTimePickerSeparator"
                            style={{ margin: '16px 0' }}
                        ></div>
                        <div className="ApprovedModalTimePickerSeparator"></div>
                    </div>
                    <div className="TimePickerRightWrapper">
                        <div className="TPInputWrapper">
                            <input
                                className="ApprovedModalTimeWrapper"
                                value={newPaymentTime.minute}
                                name="minute"
                                onChange={e =>
                                    setNewPaymentTime({
                                        ...newPaymentTime,
                                        minute: e.target.value,
                                    })
                                }
                            />
                            <CustomToolTip
                                text="Enter minutes as digits from 0 to 59 only. Avoid numbers above 59 for accurate time input."
                                toolTipFontSize="12px"
                                toolTipMarginLeft="-73px"
                                toolTipPopupWidth="148px"
                                top="60%"
                                arrowPositionCenter
                            >
                                <span className="TPSvgWrapper">
                                    <WarningIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                        <p className="ApprovedModalTimePickerText">Minute</p>
                        <div style={{ marginLeft: 5 }}>
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="110px"
                                btnHeight="45px"
                                handleClick={handleSetTimeAction}
                            >
                                Set Time
                            </Button>
                        </div>
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    )

    const handleInputErrors = () => {
        setErrors(prev => ({
            ...prev,
            dateOfAction: !newPaymentDate ? 'Select the date of payment' : '',
            timeOfAction: !newPaymentTime.time
                ? 'Select the time of payment'
                : '',
            repaymentMethod: !newPaymentMethod
                ? 'Select the repayment method'
                : '',
            amountPaid: !newPaymentAmount ? 'Enter the amount paid' : '',
            uploadedFile:
                uploadedFile.length < 1
                    ? setUploadError(true)
                    : setUploadError(false),
        }))
    }

    const recordNewPartPayment = () => {
        handleInputErrors()
        setIsRecordingAnotherRepayment(true)
        const result = markAPaymentApi(estimationId, {
            action: 'MARK_A_PAYMENT',
            repayment_month: action.month,
            date_repayment_was_made: newPaymentTime.dateTime,
            repayment_method: newPaymentMethod,
            repayment_evidence:
                uploadedFile.length === 0
                    ? null
                    : uploadedFile[0]?.originFileObj,
            total_amount_paid: newPaymentAmount.toString().replace(/,/g, ''),
        })
        result
            .then(() => {
                setIsRecordingAnotherRepayment(false)
                setOpenModal(false)
                setSuccessHeaderText('Repayment Recorded Successfully')
                setSuccessModal(true)
                setNewPaymentDate(null)
                setNewPaymentTime({
                    hour: '',
                    minute: '',
                    time: '',
                    timeOfAction: '',
                    dateTime: '',
                })
                setNewPaymentAmount('')
                setNewPaymentMethod('')
                setUploadedFile([])
                setShowNewPayment(false)
                queryClient.invalidateQueries('single-payment-plan-estimation')
            })
            .catch(() => {
                setIsRecordingAnotherRepayment(false)
            })
    }

    useEffect(() => {
        if (!openModal) {
            setShowNewPayment(false)
        }
    }, [openModal])

    useEffect(() => {
        if (action?.transactions.length === 1) {
            setShowNewPayment(true)
        }
    }, [action])

    return showNewPayment ? (
        <div className="subsequent_record_container">
            <PaymentSummary
                action={action}
                isNewPayment
                openPanel={true}
                setIsMultiplePartialPaymentMode={
                    setIsMultiplePartialPaymentMode
                }
                setShowNewPayment={setShowNewPayment}
            >
                <div className="APAModalDateTimeWrapper">
                    <div
                        style={{
                            width: '204px',
                        }}
                        className="APAModalDateTimeInput"
                    >
                        <DateField
                            prefilled={false}
                            disabledCursorIcon={false}
                            dateValue={
                                newPaymentDate === null
                                    ? null
                                    : new Date(newPaymentDate)
                            }
                            name="dateOfAction"
                            handleDateChange={(name, date) =>
                                setNewPaymentDate(format(date, 'MM/dd/yyyy'))
                            }
                            setHandleDateChange={() => setShowCalendar(false)}
                            placeholder={'Date Paid'}
                            openCalendarHandler={() => setShowCalendar(true)}
                            openCalendar={showCalendar}
                            closeCalendarHandler={() => setShowCalendar(false)}
                            caretStyle="AdminPaymentPlanCaretStyle"
                            errorMessage={errors?.dateOfAction}
                        />
                    </div>
                    <div
                        style={{
                            width: '204px',
                        }}
                        className="APAModalDateTimeInput"
                    >
                        <div>
                            <Dropdown
                                overlay={timePaidDropdown}
                                trigger={['click']}
                                visible={showTimeDropdown}
                                overlayClassName="AdminApprovedTimePicker"
                                placement="bottomCenter"
                                overlayStyle={{
                                    marginTop: '18px',
                                    marginLeft: '42px',
                                }}
                            >
                                <div
                                    onClick={() => setShowTimeDropdown(true)}
                                    className="APAModalTimeInputWrapper"
                                >
                                    <InputFields
                                        title={'Time Paid'}
                                        value={
                                            !newPaymentTime.time
                                                ? 'N/A'
                                                : newPaymentTime.time
                                        }
                                        inputPlaceholder={''}
                                        iconType={'DropdownArrow'}
                                        disabled={false}
                                        prefilled={false}
                                        errorMessage={errors?.timeOfAction}
                                        handleChange={() => ''}
                                    />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="RecordRepayment__PaymentMethodWrapper">
                    <SelectField
                        selectWidth="204px"
                        name="repaymentMethod"
                        type="approvedModal"
                        initialOption={'Select Payment Method'}
                        values={paymentMethodData.map(option => ({
                            value: option,
                        }))}
                        dropdownPositionRelative
                        withCheckBox={true}
                        optionStyle={{
                            width: '100%',
                            marginTop: '6px',
                            marginBottom: '6px',
                        }}
                        value={repaymentSetupMapping[newPaymentMethod]}
                        currentSelected={newPaymentMethod}
                        selectedValue={newPaymentMethod}
                        handleChange={(name, value) =>
                            setNewPaymentMethod(value)
                        }
                        prefilled={false}
                        disabled={false}
                        errorMessage={errors.repaymentMethod}
                        marginRight="10px"
                    />

                    <CostField
                        name="amountPaid"
                        currencyCode={'₦'}
                        value={newPaymentAmount}
                        inputWidth="204px"
                        fullWidth={false}
                        handleChange={e =>
                            setNewPaymentAmount(floatWithCommas(e.target.value))
                        }
                        errorMessage={errors?.amountPaid}
                        noFloat
                        marginBottom="10px"
                        selectWidth="90px"
                        prefixDisabled={true}
                        cssStyle={{
                            position: 'relative',
                            top: '6px',
                            marginLeft: '-35px',
                        }}
                        floatTitlePositionLeft={'40px'}
                        title="Amount Paid"
                        zIndex={true}
                        prefilled={false}
                        disabled={false}
                    />
                </div>
                <div>
                    <p className="upload_evidence">Upload evidence</p>
                    <div className="APADraggerArea rmMgBottom">
                        <Dragger
                            className="DraggerArea"
                            name="file"
                            onChange={({ fileList }) => {
                                setIsUploadingFile(true)
                                setUploadedFile(fileList)
                                setIsUploadingFile(false)
                            }}
                            showUploadList={true}
                            fileList={uploadedFile}
                            maxCount={1}
                            accept=".pdf,.jpg,.jpeg,.png"
                            customRequest={() => {}}
                        >
                            <p className="ant-upload-drag-icon">
                                {isUploadingFile ? (
                                    <div className="SlideContainer">
                                        <p
                                            className="Slider"
                                            style={{
                                                width: `${width}%`,
                                            }}
                                        ></p>
                                    </div>
                                ) : uploadedFile > 0 ? (
                                    <img
                                        src={SuccessUpload}
                                        className="UploadLogo"
                                        alt="success logo"
                                    />
                                ) : (
                                    <img
                                        src={UploadLogo}
                                        className="UploadLogo"
                                        alt="upload logo"
                                    />
                                )}
                            </p>
                            <p className="UploadText">
                                {isUploadingFile ? (
                                    <div>Uploading document...</div>
                                ) : uploadedFile.length > 0 ? (
                                    <>
                                        <p className="Upload">
                                            <u
                                                className="Browse"
                                                style={{
                                                    marginTop: '20px',
                                                }}
                                            >
                                                Replace document
                                            </u>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        Upload file. Drag and drop or
                                        <u className="Browse">browse</u>
                                    </>
                                )}
                            </p>
                        </Dragger>
                        {uploadError && (
                            <p className="ApprovedModalUploadError">
                                Please upload evidence of this action.
                            </p>
                        )}
                    </div>
                </div>
            </PaymentSummary>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '60px',
                }}
            >
                <Button
                    btnWidth="160px"
                    btnHeight="56px"
                    btnBgColor="#004AAD"
                    btnTextColor="var(--white)"
                    handleClick={recordNewPartPayment}
                >
                    {isRecordingAnotherRepayment ? <InlineLoader /> : 'Save'}
                </Button>
            </div>
        </div>
    ) : (
        <div className="newPaymentWrapper">
            <button
                width="141px"
                onClick={() => setShowNewPayment(true)}
                className="recordNewPayment"
            >
                <img
                    src={plusBlueIcon}
                    alt="plus-icon"
                    style={{
                        marginRight: '12.75px',
                    }}
                />
                <span>Record another payment</span>
            </button>
        </div>
    )
}

CollapsibleNewPaymentRecord.propTypes = {
    action: PropTypes.object,
    estimationId: PropTypes.string,
    setIsMultiplePartialPaymentMode: PropTypes.func,
    isMultiplePartialPaymentMode: PropTypes.bool,
    width: PropTypes.number,
}

export default CollapsibleNewPaymentRecord
