export const adminActionSaveModalInitialData = {
    modalType: '',
    title: '',
    actionType: 'warning',
    heading: '',
    subHeading: '',
    leftContent: 'chat',
    leftButtonText: '',
    rightButtonText: '',
}

export const nameMismatchConsumerActionModalData = {
    modalType: 'name-mismatch',
    title: 'Getting started on SunFi',
    actionType: 'warning',
    heading: 'Verification error',
    subHeading:
        "The name you provided doesn't match the name on your uploaded bank statement. Please ensure both names are identical to proceed with your submission.",
    leftContent: 'chat',
    leftButtonText: '',
    rightButtonText: 'Okay, got it!',
}

export const providerKycActionModalData = {
    'save-success': {
        title: 'Progress saved',
        actionType: 'success-secondary',
        heading: 'Progress saved',
        subHeading:
            'Your updates have been saved. You can return later to complete and submit the KYC for review.',
        leftContent: 'chat',
        leftButtonText: '',
        rightButtonText: 'Okay, got it!',
    },
    'send-for-review-success': {
        title: 'KYC Sent for Review',
        actionType: 'success-secondary',
        heading: 'KYC Sent for Review',
        subHeading:
            'The consumer has been notified to review and complete their KYC. You will be updated once they take action.',
        leftContent: 'chat',
        leftButtonText: '',
        rightButtonText: 'Okay, got it!',
    },
    'send-for-review': {
        title: 'Send KYC to customer for review',
        actionType: 'warning-secondary',
        heading: 'Send KYC to customer?',
        subHeading:
            'You’re about to send the KYC for the customer’s review. Once sent, the customer will be notified to review and complete the remaining sections. You can only make edits if the customer hasn’t submitted their KYC.',
        leftContent: 'button',
        leftButtonText: 'Cancel',
        rightButtonText: 'Send',
    },
    'customer-has-submitted-kyc': {
        title: 'Customer has submitted KYC',
        actionType: 'warning-secondary',
        heading: 'Customer has submitted KYC',
        subHeading:
            'The customer has already submitted their KYC, and no further edits can be made. If any changes are needed, please ask the customer to update their details directly.',
        leftContent: 'chat',
        leftButtonText: '',
        rightButtonText: 'Okay, got it!',
    },
}
