import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useConsumerKYCContext } from '../context'
import PageHeading from '../../CreateAccount/_components/PageHeading'
import SelectedSolutionSection from './SelectedSolution'

const SideContent = () => {
    const {
        estimation,
        goBackToSystem,
        handleChangePackage,
        handleShowVideo,
        handleUseTypeChange,
        inputs,
        estimationFetching,
        estimationLoadingOrRefetchError,
        disablePageButtons,
        isCustomerApproved,
        upfrontDepositPaid,
        toggleAddPackageDrawer,
        selectedPackage,
        setSelectedPackage,
        showAddPackageDrawer,
    } = useConsumerKYCContext()

    const { isMobile } = useMediaQueries()

    return (
        <>
            <div className="LeftContainer">
                {isMobile ? (
                    <PageHeading
                        handleShowVideo={handleShowVideo}
                        goBackToSystem={goBackToSystem}
                    />
                ) : (
                    <SelectedSolutionSection
                        estimation={estimation}
                        goBackToSystem={goBackToSystem}
                        useType={estimation?.use_type}
                        handleShowVideo={handleShowVideo}
                        handlePackageChange={handleChangePackage}
                        handleUseTypeChange={handleUseTypeChange}
                        showPageHeading={true}
                        inputs={inputs}
                        estimationFetching={estimationFetching}
                        estimationLoadingOrRefetchError={
                            estimationLoadingOrRefetchError
                        }
                        disablePageButtons={disablePageButtons}
                        isCustomerApproved={isCustomerApproved}
                        upfrontDepositPaid={upfrontDepositPaid}
                        toggleAddPackageDrawer={toggleAddPackageDrawer}
                        selectedPackage={selectedPackage}
                        setSelectedPackage={setSelectedPackage}
                        showAddPackageDrawer={showAddPackageDrawer}
                    />
                )}
            </div>
        </>
    )
}

export default SideContent
