import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import {
    onePageSubmitAdminInfo,
    onePageSubmitConsumerInfo,
    onePageSubmitKycInfoProviderApi,
} from 'src/api/shoppingExperience/kyc/api'
import { formatBusinessType } from 'src/utils/business'
import { errorHandler } from 'src/utils/errorHandler'
import { formatPhoneNumber, includeHttps } from 'src/utils/formatting'
import { removeEmptyValues } from 'src/utils/removeEmptyValues'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import { getUserType } from 'src/utils/getUserType'

const serverFieldMapping = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    phone: 'phone_number',
    contactAddress: 'contact_address',
    contactCity: 'contact_city',
    contactState: 'contact_state',
    contactCountry: 'contact_country',
    installationAddress: 'installation_address',
    installationCity: 'installation_city',
    installationState: 'installation_state',
    installationCountry: 'installation_country',
    employmentType: 'employment_type',
    companyName: 'company_name',
    jobTitle: 'job_title',
    isBusinessRegistered: 'is_business_registered',
    pensionManager: 'pension_manager',
    retirementDate: 'retirement_date',
    businessType: 'business_type',
    businessIndustry: 'business_industry',
    businessName: 'business_name',
    businessDescription: 'business_description',
    directorEmail: 'director_email',
    directorFirstName: 'director_first_name',
    directorLastName: 'director_last_name',
    directorPhone: 'director_phone',
    netMonthlySalary: 'net_monthly_salary',
    employeeId: 'employee_id',
    jobStartDate: 'employment_start_date',
    linkedinPage: 'linkedin_url',
}

export const useAutoSave = ({ onSuccess } = {}) => {
    const [autoSaveStatus, setAutoSaveStatus] = useState(null)
    const { errorAlert } = useCustomToast()
    const { isProviderAndPartnerKycAssistAllowed, isAdminUser } = getUserType()

    const submitKYCMutation = useMutation({
        mutationFn: ({ inputs, userType, estimationId }) => {
            if (isAdminUser) {
                return onePageSubmitAdminInfo({
                    type: userType,
                    data: inputs,
                    estimationID: estimationId,
                })
            }
            if (isProviderAndPartnerKycAssistAllowed) {
                return onePageSubmitKycInfoProviderApi({
                    data: inputs,
                    type: userType,
                    estimationID: estimationId,
                })
            }

            return onePageSubmitConsumerInfo({
                data: inputs,
                type: userType,
            })
        },
        onSuccess: () => {
            setAutoSaveStatus('auto-saved')
            setTimeout(() => {
                setAutoSaveStatus(null)
            }, 3000)

            if (onSuccess) {
                onSuccess()
            }
        },
        onError: error => {
            const msg =
                errorHandler(error?.response?.data) ||
                `Unable to save, please try again`
            errorAlert(msg)
            setAutoSaveStatus(null)
        },
    })
    const autoSave = useCallback(
        ({
            userType,
            usesDynamicKYC,
            inputs,
            newData,
            estimationId,
            isSameAddress,
        }) => {
            if (!newData || Object.keys(newData).length === 0) return
            const frontendFieldKey = Object.keys(newData)[0]
            const fieldValue = newData[frontendFieldKey]
            const serverFieldName = serverFieldMapping[frontendFieldKey]
            if (!serverFieldName) return
            let payload = {
                submit: false,
                dynamic_kyc: usesDynamicKYC,
            }
            if (userType === 'RESIDENTIAL') {
                payload.basic_information = {
                    personal_information: {},
                    employment_information: {},
                }
                if (
                    ['first_name', 'last_name', 'email'].includes(
                        serverFieldName,
                    )
                ) {
                    payload.basic_information.personal_information[
                        serverFieldName
                    ] = fieldValue
                } else if (serverFieldName === 'phone_number') {
                    payload.basic_information.personal_information.phone_number =
                        formatPhoneNumber(fieldValue)
                } else if (
                    [
                        'contact_address',
                        'contact_city',
                        'contact_state',
                        'contact_country',
                    ].includes(serverFieldName)
                ) {
                    if (
                        !payload.basic_information.personal_information
                            .contact_address
                    ) {
                        payload.basic_information.personal_information.contact_address =
                            {}
                    }
                    if (serverFieldName === 'contact_address') {
                        payload.basic_information.personal_information.contact_address.street_address =
                            fieldValue
                    } else if (serverFieldName === 'contact_city') {
                        payload.basic_information.personal_information.contact_address.state_of_residence =
                            inputs.contactState
                        payload.basic_information.personal_information.contact_address.city =
                            fieldValue
                    } else if (serverFieldName === 'contact_state') {
                        payload.basic_information.personal_information.contact_address.state_of_residence =
                            fieldValue
                    } else if (serverFieldName === 'contact_country') {
                        payload.basic_information.personal_information.contact_address.country =
                            fieldValue
                    }

                    if (isSameAddress) {
                        if (
                            !payload.basic_information.personal_information
                                .installation_address
                        ) {
                            payload.basic_information.personal_information.installation_address =
                                {}
                        }
                        if (serverFieldName === 'contact_address') {
                            payload.basic_information.personal_information.installation_address.street_address =
                                fieldValue
                        } else if (serverFieldName === 'contact_city') {
                            payload.basic_information.personal_information.installation_address.state =
                                inputs.contactState
                            payload.basic_information.personal_information.installation_address.city =
                                fieldValue
                        } else if (serverFieldName === 'contact_state') {
                            payload.basic_information.personal_information.installation_address.state =
                                fieldValue
                        } else if (serverFieldName === 'contact_country') {
                            payload.basic_information.personal_information.installation_address.country =
                                fieldValue
                        }
                    }
                } else if (
                    [
                        'installation_address',
                        'installation_city',
                        'installation_state',
                        'installation_country',
                    ].includes(serverFieldName)
                ) {
                    if (
                        !payload.basic_information.personal_information
                            .installation_address
                    ) {
                        payload.basic_information.personal_information.installation_address =
                            {}
                    }
                    if (serverFieldName === 'installation_address') {
                        payload.basic_information.personal_information.installation_address.street_address =
                            fieldValue
                    } else if (serverFieldName === 'installation_city') {
                        payload.basic_information.personal_information.installation_address.state =
                            inputs.installationState
                        payload.basic_information.personal_information.installation_address.city =
                            fieldValue
                    } else if (serverFieldName === 'installation_state') {
                        payload.basic_information.personal_information.installation_address.state =
                            fieldValue
                    } else if (serverFieldName === 'installation_country') {
                        payload.basic_information.personal_information.installation_address.country =
                            fieldValue
                    }
                } else if (
                    [
                        'employment_type',
                        'company_name',
                        'job_title',
                        'pension_manager',
                        'retirement_date',
                        'is_business_registered',
                        'net_monthly_salary',
                        'employee_id',
                        'employment_start_date',
                        'linkedin_url',
                    ].includes(serverFieldName)
                ) {
                    if (serverFieldName === 'retirement_date') {
                        payload.basic_information.employment_information.retirement_date =
                            format(new Date(fieldValue), 'yyyy-MM-dd')
                    } else if (serverFieldName === 'employment_start_date') {
                        payload.basic_information.employment_information.employment_start_date =
                            format(new Date(fieldValue), 'yyyy-MM-dd')
                    } else if (serverFieldName === 'linkedin_url') {
                        payload.basic_information.employment_information.linkedin_url =
                            includeHttps(fieldValue)
                    } else if (serverFieldName === 'net_monthly_salary') {
                        payload.basic_information.employment_information.net_monthly_salary =
                            removeCommaFromNum(fieldValue)
                    } else {
                        payload.basic_information.employment_information[
                            serverFieldName
                        ] = fieldValue
                    }
                } else if (
                    [
                        'business_type',
                        'business_industry',
                        'business_description',
                        'business_name',
                    ].includes(serverFieldName)
                ) {
                    if (
                        !payload.basic_information.employment_information
                            .business_details
                    ) {
                        payload.basic_information.employment_information.business_details =
                            {}
                    }
                    if (serverFieldName === 'business_type') {
                        payload.basic_information.employment_information.business_details.business_type =
                            formatBusinessType(fieldValue)
                    } else {
                        payload.basic_information.employment_information.business_details[
                            serverFieldName
                        ] = fieldValue
                    }
                }
                removeEmptyValues(payload.basic_information)
            } else if (userType === 'BUSINESS') {
                payload.business_information = {}
                payload.director_information = {}
                payload.contact_information = {}
                payload.financial_information = {}
                if (
                    [
                        'business_type',
                        'business_industry',
                        'business_description',
                        'business_name',
                    ].includes(serverFieldName)
                ) {
                    if (serverFieldName === 'business_type') {
                        payload.business_information.business_type =
                            formatBusinessType(fieldValue)
                    } else {
                        payload.business_information[serverFieldName] =
                            fieldValue
                    }
                } else if (
                    [
                        'contact_address',
                        'contact_city',
                        'contact_state',
                        'contact_country',
                    ].includes(serverFieldName)
                ) {
                    if (!payload.business_information.contact_address) {
                        payload.business_information.contact_address = {}
                    }
                    if (serverFieldName === 'contact_address') {
                        payload.business_information.contact_address.street_address =
                            fieldValue
                    } else if (serverFieldName === 'contact_city') {
                        payload.business_information.contact_address.state_of_residence =
                            inputs.contactState
                        payload.business_information.contact_address.city =
                            fieldValue
                    } else if (serverFieldName === 'contact_state') {
                        payload.business_information.contact_address.state_of_residence =
                            fieldValue
                    } else if (serverFieldName === 'contact_country') {
                        payload.business_information.contact_address.country =
                            fieldValue
                    }

                    if (isSameAddress) {
                        if (
                            !payload.business_information.installation_address
                        ) {
                            payload.business_information.installation_address =
                                {}
                        }
                        if (serverFieldName === 'contact_address') {
                            payload.business_information.installation_address.street_address =
                                fieldValue
                        } else if (serverFieldName === 'contact_city') {
                            payload.business_information.installation_address.state =
                                inputs.contactState
                            payload.business_information.installation_address.city =
                                fieldValue
                        } else if (serverFieldName === 'contact_state') {
                            payload.business_information.installation_address.state =
                                fieldValue
                        } else if (serverFieldName === 'contact_country') {
                            payload.business_information.installation_address.country =
                                fieldValue
                        }
                    }
                } else if (
                    [
                        'installation_address',
                        'installation_city',
                        'installation_state',
                        'installation_country',
                    ].includes(serverFieldName)
                ) {
                    if (!payload.business_information.installation_address) {
                        payload.business_information.installation_address = {}
                    }
                    if (serverFieldName === 'installation_address') {
                        payload.business_information.installation_address.street_address =
                            fieldValue
                    } else if (serverFieldName === 'installation_city') {
                        payload.business_information.installation_address.state =
                            inputs.installationState
                        payload.business_information.installation_address.city =
                            fieldValue
                    } else if (serverFieldName === 'installation_state') {
                        payload.business_information.installation_address.state =
                            fieldValue
                    } else if (serverFieldName === 'installation_country') {
                        payload.business_information.installation_address.country =
                            fieldValue
                    }
                } else if (
                    [
                        'director_email',
                        'director_first_name',
                        'director_last_name',
                        'director_phone',
                    ].includes(serverFieldName)
                ) {
                    let key =
                        serverFieldName === 'director_email'
                            ? 'email'
                            : serverFieldName === 'director_first_name'
                            ? 'first_name'
                            : serverFieldName === 'director_last_name'
                            ? 'last_name'
                            : 'phone_number'
                    let value =
                        key === 'phone_number'
                            ? formatPhoneNumber(fieldValue)
                            : fieldValue
                    payload.director_information[key] = value
                } else if (
                    [
                        'first_name',
                        'last_name',
                        'email',
                        'phone_number',
                    ].includes(serverFieldName)
                ) {
                    let value =
                        serverFieldName === 'phone_number'
                            ? formatPhoneNumber(fieldValue)
                            : fieldValue
                    payload.contact_information[serverFieldName] = value
                } else if (
                    ['bank_statement_id', 'audited_financial_report'].includes(
                        serverFieldName,
                    )
                ) {
                    payload.financial_information[serverFieldName] = fieldValue
                } else if (serverFieldName === 'moa_url') {
                    payload.business_information.memorandum_of_association_url =
                        fieldValue
                }
                removeEmptyValues(payload)
            }
            setAutoSaveStatus('auto-saving')
            submitKYCMutation.mutate({
                inputs: payload,
                userType,
                estimationId,
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )
    const autoSaveInstallationAddress = useCallback(
        ({ userType, usesDynamicKYC, contactAddressData, estimationId }) => {
            if (
                !contactAddressData ||
                Object.keys(contactAddressData).length === 0
            )
                return
            let payload = {
                submit: false,
                dynamic_kyc: usesDynamicKYC,
            }
            if (userType === 'RESIDENTIAL') {
                payload.basic_information = {
                    personal_information: {
                        installation_address: {
                            street_address: contactAddressData.contactAddress,
                            city: contactAddressData.contactCity,
                            state: contactAddressData.contactState,
                            country: contactAddressData.contactCountry,
                        },
                    },
                }
                removeEmptyValues(
                    payload?.basic_information?.personal_information
                        ?.installation_address,
                )
                removeEmptyValues(
                    payload?.basic_information?.personal_information,
                )
            } else if (userType === 'BUSINESS') {
                payload.business_information = {
                    installation_address: {
                        street_address: contactAddressData.contactAddress,
                        city: contactAddressData.contactCity,
                        state: contactAddressData.contactState,
                        country: contactAddressData.contactCountry,
                    },
                }
                removeEmptyValues(
                    payload?.business_information?.installation_address,
                )
                removeEmptyValues(payload?.business_information)
            }
            setAutoSaveStatus('auto-saving')
            submitKYCMutation.mutate({
                inputs: payload,
                userType,
                estimationId,
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )
    return {
        autoSave,
        autoSaveInstallationAddress,
        autoSaveStatus,
    }
}
