import ButtonComponent from 'src/components/Button'
import styles from '../../styles.module.scss'
import moment from 'moment'
import { overviewTableHeaders } from '../../utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import PropTypes from 'prop-types'
import { Pagination } from 'antd'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
const ClusterListing = ({
    clusterInfo,
    currentPage,
    totalPages,
    updatePage,
}) => {
    const history = useHistory()
    const { isMobileOrTablet } = useMediaQueries()

    const MobileCard = ({ cluster }) => {
        return (
            <div className={styles.mobileCard}>
                <div>
                    <div className={styles.clusterNameAndLogo}>
                        <img src={cluster?.logo} />
                        <p>{cluster?.name}</p>
                    </div>
                    <p>{cluster?.no_of_cluster_members} members</p>
                </div>
                <div>
                    <div className={styles.profileTypeContainer}>
                        <p>Profile Type</p>
                        <p className={styles.profileType}>
                            {cluster?.profile_type}
                        </p>
                    </div>
                    <ButtonComponent
                        btnTextColor="#004aad"
                        btnTextColorOutline="#004aad"
                        handleClick={() =>
                            history.push({
                                pathname: `/rep/workspace/${cluster?.id}`,
                                state: { cluster },
                            })
                        }
                    >
                        Manage Cluster
                    </ButtonComponent>
                </div>
            </div>
        )
    }

    MobileCard.propTypes = {
        cluster: PropTypes.shape({
            id: PropTypes.number,
            logo: PropTypes.string,
            name: PropTypes.string,
            profile_type: PropTypes.string,
            no_of_cluster_members: PropTypes.number,
            updated_at: PropTypes.string,
        }),
    }

    return (
        <>
            {isMobileOrTablet ? (
                <div>
                    {clusterInfo?.map(cluster => (
                        <MobileCard key={cluster} cluster={cluster} />
                    ))}
                </div>
            ) : (
                <div className={styles.overviewTable}>
                    <div className={styles.overviewTableHeaders}>
                        {overviewTableHeaders.map(header => (
                            <span key={header}>{header}</span>
                        ))}
                    </div>
                    <div className={styles.overviewTableBody}>
                        {clusterInfo?.map(cluster => (
                            <div
                                className={styles.overviewTableRow}
                                key={cluster?.id}
                            >
                                <div className={styles.clusterNameAndLogo}>
                                    <img src={cluster?.logo} />
                                    <p>{cluster?.name}</p>
                                </div>
                                <span className={styles.profileType}>
                                    {cluster?.profile_type}
                                </span>
                                <span>
                                    {cluster?.no_of_cluster_members} Members
                                </span>
                                <span>
                                    {moment(cluster?.updated_at).format('lll')}
                                </span>
                                <span>
                                    <ButtonComponent
                                        btnTextColor="#004aad"
                                        btnTextColorOutline="#004aad"
                                        handleClick={() =>
                                            history.push({
                                                pathname: `/rep/workspace/${cluster?.id}`,
                                                state: {
                                                    comingFromOverview: true,
                                                },
                                            })
                                        }
                                    >
                                        Manage Cluster
                                    </ButtonComponent>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {totalPages > 1 && (
                <div className={styles.paginationContainer}>
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        total={parseInt(totalPages, 10)}
                        defaultPageSize={1}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={updatePage}
                    />
                </div>
            )}
        </>
    )
}

ClusterListing.propTypes = {
    clusterInfo: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    updatePage: PropTypes.func.isRequired,
}

export default ClusterListing
