import '../adminprovidersactive.scss'
import { Menu } from 'antd'
import PropTypes from 'prop-types'
import { denyPermission } from 'src/utils/permissionFramework'
import { moreActions, getPermissions } from '../data'
import { applyDynamicPermissionsToAdminUser } from 'src/utils/permissionsControl'

const MoreActionsMenu = ({ providerIsAnAsi, handleMoreActions }) => {
    const { canDeactivateExistingProvider, hasAnyCreateLibraryUserPermission } =
        getPermissions()

    return (
        <Menu>
            {moreActions
                .filter(action => (providerIsAnAsi ? action.id > 1 : action))
                .map((action, i) => (
                    <Menu.Item
                        key={i}
                        onClick={() => handleMoreActions(action.label)}
                        disabled={
                            (applyDynamicPermissionsToAdminUser
                                ? !hasAnyCreateLibraryUserPermission &&
                                  action?.label === 'assign to resource'
                                : denyPermission(
                                      'admin',
                                      'approved_providers',
                                      'providers:can_assign_to_resource',
                                  )) ||
                            (action?.label === 'deactivate provider' &&
                                !canDeactivateExistingProvider)
                        }
                    >
                        <span className="AdminProvidersActiveDropdownLinks">
                            {action.action}
                        </span>
                    </Menu.Item>
                ))}
        </Menu>
    )
}

MoreActionsMenu.propTypes = {
    providerIsAnAsi: PropTypes.bool.isRequired,
    handleMoreActions: PropTypes.func.isRequired,
}

export default MoreActionsMenu
