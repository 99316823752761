import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import EnergyNeeds from 'src/components/EnergyNeeds'
import Button from 'src/components/Button'
import arrow from 'src/assets/images/blue-arrow.svg'

import styles from './recommendedpackage.module.scss'
import styles_pageform from 'src/pages/Admin/Profiles/NewProfile/components/PageForm/pageform.module.scss'
import { SelectField, MultipleSelectField } from 'src/components/InputFields'
import { useGetClusterVariables } from 'src/pages/Admin/Profiles/NewProfile/hooks/useGetClusterVariable'
import AllComponents from 'src/pages/Admin/Profiles/components/all-components'
import { packagePaymentTypes } from './utils'
import {
    formatPaymentTypesOptionsForApi,
    formatPaymentTypesOnChange,
    formatPaymentTypesOptionsForInput,
} from '../../data'

const RecommendedPackage = ({
    index,
    data,
    setSelectedSolutions,
    selectedSolutions,
    toggleBox,
    isOpen,
    profileName,
    isOnePackage = false,
    isPostApprovalPackageChange,
}) => {
    const [appliances, setAppliances] = useState([])
    const [cluster, setCluster] = useState({
        subscription_cluster_code: '',
        lease_to_own_cluster_code: '',
    })

    const [selectedSolution, setSelectedSolution] = useState(null)
    const [currentSolution, setCurrentSolution] = useState(null)
    const [paymentType, setPaymentType] = useState('')
    const [paymentTypes, setPaymentTypes] = useState([])
    const [paymentTypesError, setPaymentTypesError] = useState('')

    const {
        l2OCreditVariablesFetching,
        l2OCreditVariableData,
        subCreditVariablesFetching,
        subCreditVariableData,
        formatClusterData,
    } = useGetClusterVariables()

    const removeSelectedSolution = () => {
        const otherSelectedSolutions = selectedSolutions.filter(
            sol => sol?.id !== data.id,
        )
        setSelectedSolutions(otherSelectedSolutions)
    }

    const handlePaymentTypesChange = values => {
        setPaymentTypes(formatPaymentTypesOnChange(values, data))
        setPaymentTypesError('')
    }

    const handleSelectedSolution = data => {
        if (!isOnePackage) {
            let payment_types = [...paymentTypes]
            if (!payment_types.length) {
                setPaymentTypesError('Payment type is required')
            }

            data.payment_plan_types = formatPaymentTypesOptionsForApi(
                payment_types,
                data,
            )
        }

        const currentSolution = selectedSolutions.find(
            sol => sol?.id === data.id,
        )
        if (!currentSolution) {
            setSelectedSolutions(prev =>
                isOnePackage ? [data] : [...prev, data],
            )
        } else {
            removeSelectedSolution()
        }
    }

    const handleSolutionToggle = data => {
        setCurrentSolution(data)
        toggleBox(index)
    }

    const handleSetAppliances = appliances => {
        const selSol = selectedSolutions?.find(sol => sol?.id === data.id)

        if (selSol) {
            removeSelectedSolution()
            if (appliances.length) {
                setSelectedSolutions(prev => [...prev, { ...data, appliances }])
            }
        }

        setAppliances(appliances)
    }

    useEffect(() => {
        const checkSelected = data => {
            return selectedSolutions?.find(sol => sol?.id === data.id)
        }

        const selSol = checkSelected(data)
        if (selSol) {
            setSelectedSolution(selSol)
            setAppliances(selSol.appliances || [])
            if (!isOnePackage) {
                setPaymentTypes(
                    formatPaymentTypesOptionsForInput(
                        selSol.payment_plan_types || [],
                        data,
                    ),
                )
            }
            setCluster({
                lease_to_own_cluster_code:
                    selSol?.cluster_info?.LEASE_TO_OWN?.length > 1
                        ? selSol?.cluster_info?.LEASE_TO_OWN
                        : cluster.lease_to_own_cluster_code.length > 0
                        ? cluster.lease_to_own_cluster_code
                        : '',
                subscription_cluster_code:
                    selSol?.cluster_info?.SUBSCRIPTION?.length > 1
                        ? selSol?.cluster_info?.SUBSCRIPTION
                        : cluster.subscription_cluster_code.length > 0
                        ? cluster.subscription_cluster_code
                        : '',
            })
        } else {
            setSelectedSolution(null)
            setAppliances([])
            setCluster({
                lease_to_own_cluster_code: '',
                subscription_cluster_code: '',
            })
            if (isOnePackage) {
                setPaymentType('')
            } else {
                setPaymentTypes([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedSolutions])

    return (
        <div
            key={data.id}
            className={styles.CustomRecommendationEnergySolutionWrapper}
        >
            <div className={styles.CustomRecommendationEnergySolutionTop}>
                <h4>{data?.name}</h4>
                <div className={styles.AmountAndFlag}>
                    {selectedSolution ? (
                        <div className={styles.TopSelectedFlag}>
                            <p>SELECTED</p>
                        </div>
                    ) : null}
                </div>

                <div
                    onClick={() => {
                        handleSolutionToggle(data)
                    }}
                    className={styles.TopArrowWrapper}
                >
                    <img
                        src={arrow}
                        alt="arrow"
                        className={
                            isOpen[index]
                                ? styles.TopArrowOpen
                                : styles.TopArrowClosed
                        }
                    />
                </div>
            </div>
            <div
                className={
                    isOpen[index]
                        ? styles.CustomRecommendationEnergySolutionBottomOpen
                        : styles.CustomRecommendationEnergySolutionBottomClosed
                }
            >
                <div
                    className={`${styles.BottomTextWrapper} ALTRecommendations`}
                >
                    <div>
                        <h5>CAPACITY</h5>
                        <p>
                            {data?.energy_capacity?.value}
                            {data?.energy_capacity?.unit_of_measurement}
                        </p>
                    </div>
                    <div>
                        <h5>AMOUNT</h5>
                        <p>{data?.amount}</p>
                    </div>
                </div>

                <AllComponents
                    solutionId={currentSolution?.id}
                    isOpen={isOpen[index]}
                />

                {!isPostApprovalPackageChange && (
                    <EnergyNeeds
                        section="Profile"
                        actionText="Add"
                        setEnergyNeeds={handleSetAppliances}
                        energyNeeds={appliances}
                        canPullEnergyNeeds={
                            process.env
                                .REACT_APP_FLAG_SHOW_PULL_ENERGY_NEEDS ===
                            'true'
                        }
                        profileName={profileName}
                    />
                )}

                {isOnePackage ? (
                    <section
                        className={styles_pageform.FormSection}
                        style={{ marginTop: 24, width: '100%' }}
                    >
                        <h4>Select payment type for this package</h4>
                        <div className={styles_pageform.FormSectionContentRow}>
                            <div>
                                <SelectField
                                    selectWidth="100%"
                                    initialOption="Select payment type"
                                    floatingLabel={
                                        paymentType && 'Select payment type'
                                    }
                                    optionStyleNoCheckbox={{
                                        color: '#233862',
                                    }}
                                    name="payment_type"
                                    contentField="name"
                                    dropdownPositionRelative
                                    withCheckBox
                                    currentSelected={paymentType}
                                    value={paymentType}
                                    values={packagePaymentTypes(
                                        data?.lease_to_own_supported,
                                        data?.subscription_supported,
                                        data?.outright_sale_supported,
                                    )}
                                    handleChange={(_, value) => {
                                        setPaymentType(value)
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                ) : null}

                {isOnePackage ? (
                    !isPostApprovalPackageChange && (
                        <section
                            className={styles_pageform.FormSection}
                            style={{
                                marginTop: 24,
                                width: '100%',
                                display: [
                                    'Lease to Own',
                                    'Subscription',
                                ].includes(paymentType)
                                    ? 'block'
                                    : 'none',
                            }}
                        >
                            <h4>Apply cluster variable to this package</h4>
                            <div
                                className={
                                    styles_pageform.FormSectionContentRow
                                }
                            >
                                {paymentType === 'Lease to Own' &&
                                    data?.lease_to_own_supported && (
                                        <div>
                                            <SelectField
                                                selectWidth="100%"
                                                initialOption="Lease to own"
                                                floatingLabel={
                                                    cluster.lease_to_own_cluster_code &&
                                                    'Lease to own'
                                                }
                                                optionStyleNoCheckbox={{
                                                    color: '#233862',
                                                }}
                                                name="lease_to_own_cluster_code"
                                                contentField="name"
                                                dropdownPositionRelative
                                                withCheckBox
                                                currentSelected={
                                                    cluster.lease_to_own_cluster_code
                                                }
                                                value={
                                                    cluster.lease_to_own_cluster_code
                                                }
                                                values={formatClusterData(
                                                    l2OCreditVariableData?.data
                                                        ?.data,
                                                )}
                                                handleChange={(_, value) => {
                                                    setCluster(prev => ({
                                                        ...prev,
                                                        lease_to_own_cluster_code:
                                                            value,
                                                    }))
                                                }}
                                                disabled={
                                                    !paymentType.length ||
                                                    l2OCreditVariablesFetching
                                                }
                                            />
                                            <p
                                                className={
                                                    styles_pageform.OptionalLabel
                                                }
                                            >
                                                Optional
                                            </p>
                                        </div>
                                    )}
                                {paymentType === 'Subscription' &&
                                    data?.subscription_supported && (
                                        <div>
                                            <SelectField
                                                selectWidth="100%"
                                                initialOption="Subscription"
                                                floatingLabel={
                                                    cluster.subscription_cluster_code &&
                                                    'Subscription'
                                                }
                                                optionStyleNoCheckbox={{
                                                    color: '#233862',
                                                }}
                                                name="subscription_cluster_code"
                                                contentField="name"
                                                dropdownPositionRelative
                                                withCheckBox
                                                currentSelected={
                                                    cluster.subscription_cluster_code
                                                }
                                                value={
                                                    cluster.subscription_cluster_code
                                                }
                                                values={formatClusterData(
                                                    subCreditVariableData?.data
                                                        ?.data,
                                                )}
                                                handleChange={(_, value) => {
                                                    setCluster(prev => ({
                                                        ...prev,
                                                        subscription_cluster_code:
                                                            value,
                                                    }))
                                                }}
                                                disabled={
                                                    !paymentType.length ||
                                                    subCreditVariablesFetching
                                                }
                                            />
                                            <p
                                                className={
                                                    styles_pageform.OptionalLabel
                                                }
                                            >
                                                Optional
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </section>
                    )
                ) : (
                    <section
                        className={styles_pageform.FormSection}
                        style={{ marginTop: 24, width: '100%' }}
                    >
                        <h4>Apply cluster variable to this package</h4>
                        <div className={styles_pageform.FormSectionContentRow}>
                            {data?.lease_to_own_supported && (
                                <div>
                                    <SelectField
                                        selectWidth="100%"
                                        initialOption="Lease to own"
                                        floatingLabel={
                                            cluster.lease_to_own_cluster_code &&
                                            'Lease to own'
                                        }
                                        optionStyleNoCheckbox={{
                                            color: '#233862',
                                        }}
                                        name="lease_to_own_cluster_code"
                                        contentField="name"
                                        dropdownPositionRelative
                                        withCheckBox
                                        currentSelected={
                                            cluster.lease_to_own_cluster_code
                                        }
                                        value={
                                            cluster.lease_to_own_cluster_code
                                        }
                                        values={formatClusterData(
                                            l2OCreditVariableData?.data?.data,
                                        )}
                                        handleChange={(_, value) => {
                                            setCluster(prev => ({
                                                ...prev,
                                                lease_to_own_cluster_code:
                                                    value,
                                            }))
                                        }}
                                        disabled={l2OCreditVariablesFetching}
                                    />
                                    <p
                                        className={
                                            styles_pageform.OptionalLabel
                                        }
                                    >
                                        Optional
                                    </p>
                                </div>
                            )}
                            {data?.subscription_supported && (
                                <div>
                                    <SelectField
                                        selectWidth="100%"
                                        initialOption="Subscription"
                                        floatingLabel={
                                            cluster.subscription_cluster_code &&
                                            'Subscription'
                                        }
                                        optionStyleNoCheckbox={{
                                            color: '#233862',
                                        }}
                                        name="subscription_cluster_code"
                                        contentField="name"
                                        dropdownPositionRelative
                                        withCheckBox
                                        currentSelected={
                                            cluster.subscription_cluster_code
                                        }
                                        value={
                                            cluster.subscription_cluster_code
                                        }
                                        values={formatClusterData(
                                            subCreditVariableData?.data?.data,
                                        )}
                                        handleChange={(_, value) => {
                                            setCluster(prev => ({
                                                ...prev,
                                                subscription_cluster_code:
                                                    value,
                                            }))
                                        }}
                                        disabled={subCreditVariablesFetching}
                                    />
                                    <p
                                        className={
                                            styles_pageform.OptionalLabel
                                        }
                                    >
                                        Optional
                                    </p>
                                </div>
                            )}
                        </div>
                    </section>
                )}

                {!isOnePackage && (
                    <section
                        className={styles_pageform.FormSection}
                        style={{ marginTop: 24, width: '100%' }}
                    >
                        <h4>Select payment type for this package</h4>
                        <MultipleSelectField
                            selectWidth="100%"
                            title={'Payment type'}
                            optionStyleNoCheckbox={{ color: '#233862' }}
                            name="payment_type"
                            contentField="name"
                            selectStyle={{
                                opacity: !paymentTypes.length ? 0.7 : 1,
                            }}
                            dropdownPositionRelative
                            currentSelected={paymentTypes}
                            value={paymentTypes}
                            values={packagePaymentTypes(
                                data?.lease_to_own_supported,
                                data?.subscription_supported,
                                data?.outright_sale_supported,
                                true,
                            )}
                            handleMultipleSelectChange={
                                handlePaymentTypesChange
                            }
                            errorMessage={paymentTypesError}
                            topArrow={paymentTypes?.length > 1}
                        />
                    </section>
                )}

                <div className={styles.PackageListIemButtonWrapper}>
                    <Button
                        btnWidth={'137px'}
                        btnHeight={'40px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        disabled={
                            isPostApprovalPackageChange
                                ? !paymentType.length
                                : !appliances.length ||
                                  (isOnePackage
                                      ? !paymentType.length
                                      : !paymentTypes.length &&
                                        !selectedSolution?.payment_plan_types)
                        }
                        handleClick={() =>
                            handleSelectedSolution({
                                ...data,
                                appliances,
                                ...cluster,
                                ...(isOnePackage
                                    ? {
                                          payment_plan_types:
                                              paymentType?.length > 0
                                                  ? [paymentType]
                                                  : [],
                                      }
                                    : {}),
                            })
                        }
                    >
                        {selectedSolution ? 'Remove package' : 'Select package'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

RecommendedPackage.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object,
    setSelectedSolutions: PropTypes.func,
    selectedSolutions: PropTypes.arrayOf(PropTypes.obj),
    toggleBox: PropTypes.func,
    isOpen: PropTypes.arrayOf(PropTypes.bool),
    profileName: PropTypes.string,
    isOnePackage: PropTypes.bool,
    isPostApprovalPackageChange: PropTypes.bool,
}

export default RecommendedPackage
