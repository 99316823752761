import { useEffect, useRef } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { nudgeProviderPostApproval } from 'src/api/consumer/postApproval'

const usePostApprovalPackageChange = ({
    offer,
    action,
    setAction,
    setActionModalOpen,
    onSuccess,
    onError,
}) => {
    const history = useHistory()

    const hasMounted = useRef(false)

    const modalValues = () => {
        switch (action) {
            case 'post-approval-package-change-request':
                return {
                    actionModalTitle: 'Change your package',
                    actionModalHeaderText:
                        "You're about to change your package",
                    actionModalSubtitle:
                        "Changing your package will trigger a new credit check. If approved, an admin will reassess your eligibilty. If you're found ineligible, your previous selection will remain available.",
                    actionModalActionType: 'warning',
                    actionModalPrimaryBtnText: 'Proceed with request',
                    actionModalFooterLeftContent: 'button',
                    actionModalSecondaryBtnText: 'Cancel request',
                }
            case 'successful-post-approval-package-change-request':
                return {
                    actionModalTitle: 'Change your package',
                    actionModalHeaderText: 'Package request Successful',
                    actionModalSubtitle:
                        'Kindly note that your package change request has been successfully processed, and a notification has been sent to your admin. You will be updated once a new package is suggested',
                    actionModalActionType: 'success',
                    actionModalPrimaryBtnText: 'Okay! Got it',
                    actionModalFooterLeftContent: 'chat',
                    actionModalSecondaryBtnText: '',
                }
            case 'post-approval-package-change-request-in-progress':
                return {
                    actionModalTitle: 'Package request already in progress',
                    actionModalHeaderText:
                        'Package request already in progress',
                    actionModalSubtitle:
                        'Please be aware that the package change request is  already in progress and your admin is already on it. However, your admin can be notified to speed up the process so you can get your application submitted as soon as possible.',
                    actionModalActionType: 'warning',
                    actionModalPrimaryBtnText: 'Got it',
                    actionModalFooterLeftContent: 'button',
                    actionModalSecondaryBtnText: 'Nudge Provider',
                }
            case 'post-approval-package-change-request-in-progress-and-provided-has-been-nudged':
                return {
                    actionModalTitle: 'Package request already in progress',
                    actionModalHeaderText:
                        'Package request already in progress',
                    actionModalSubtitle:
                        'Please be aware that the package change request is  already in progress and your admin is already on it.',
                    actionModalActionType: 'warning',
                    actionModalPrimaryBtnText: 'Got it',
                    actionModalFooterLeftContent: 'chat',
                    actionModalSecondaryBtnText: '',
                }
        }
    }

    const returnPostApprovalPackageChangeAction = () => {
        if (offer?.consumer_status === 'REQUEST_PACKAGE_CHANGE') {
            if (offer?.post_approval?.package_change_request?.nudged_admins) {
                return 'post-approval-package-change-request-in-progress-and-provided-has-been-nudged'
            } else {
                return 'post-approval-package-change-request-in-progress'
            }
        } else {
            return 'post-approval-package-change-request'
        }
    }

    const postApprovalProviderNudgeMutation = useMutation({
        mutationKey: ['nudge_provider'],
        mutationFn: () => nudgeProviderPostApproval(offer?.id),
        onSuccess: () => {
            onSuccess?.()
            setAction(
                'post-approval-package-change-request-in-progress-and-provided-has-been-nudged',
            )
        },
        onError: err => {
            onError?.(err)
        },
    })

    useEffect(() => {
        if (!offer?.id || hasMounted.current) return
        hasMounted.current = true

        const changePackagePostApproval =
            history?.location.state?.action === 'change-package-request'

        const hasSeenModal = JSON.parse(
            localStorage.getItem('shownPackageRequestModal') || 'false',
        )

        if (changePackagePostApproval && !hasSeenModal) {
            localStorage.setItem(
                'shownPackageRequestModal',
                JSON.stringify(true),
            )
            const _action = returnPostApprovalPackageChangeAction()
            setAction(_action)
            setActionModalOpen(true)
        }

        return () => {
            localStorage.removeItem('shownPackageRequestModal')
        }

        // eslint-disable-next-line
    }, [offer?.id])

    return {
        returnPostApprovalPackageChangeAction,
        modalValues,
        postApprovalProviderNudgeMutation,
    }
}

export default usePostApprovalPackageChange
