import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/consumerkyc.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { InputFields, SelectField } from 'src/components/InputFields'
import { sharedFormComponentPropTypes } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import {
    countries,
    sameAddressOptions,
} from '../../../../OutrightSaleInformation/utils'
import { localGovtData, stateData } from 'src/utils/stateData'
import { SelectButtons } from 'src/components/SelectButtons'
import { Switch } from 'antd'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import s from '../styles.module.scss'

const Address = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    handleSameAddressOptionChange,
    isAdminUser,
    isCustomerApproved,
    page,
    useType,
    onInputFocus = () => {},
    onSelectFocus = () => {},
}) => {
    const { isMobile } = useMediaQueries()

    const isOnePageDynamicKyc = page === 'one-page-dynamic-kyc'
    const fieldMarginBottom = isOnePageDynamicKyc ? '16px' : '20px'
    const disableFieldForAdmins = isAdminUser && page !== 'one-page-dynamic-kyc'

    return (
        <>
            {!isOnePageDynamicKyc && (
                <Label
                    title={'Your contact address'}
                    subTitle={
                        'Please provide your contact address. This does not have to be the same as your installation address'
                    }
                />
            )}
            <InputFields
                name="contactAddress"
                title={
                    useType === 'RESIDENTIAL'
                        ? 'Home Address'
                        : 'Business contact address'
                }
                handleChange={handleChange}
                onBlur={handleOnBlur}
                value={inputs?.contactAddress}
                errorMessage={errors?.contactAddress}
                disabled={disableFieldForAdmins || isCustomerApproved}
                marginBottom={fieldMarginBottom}
                onFocus={onInputFocus}
            />

            <div
                className="PersonalInfo_DualSelectField-Wrapper"
                style={{ marginBottom: isOnePageDynamicKyc ? 0 : '' }}
            >
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCountry"
                    floatingLabel="Country"
                    initialOption="Country"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCountry')}
                    values={countries.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactCountry}
                    errorMessage={errors?.contactCountry}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCountry}
                    selectedValue={inputs?.contactCountry}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                    onSelectFocus={onSelectFocus}
                />
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactState"
                    floatingLabel="State"
                    initialOption="State"
                    selectWidth="100%"
                    values={stateData?.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactState}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactState')}
                    errorMessage={errors?.contactState}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactState}
                    selectedValue={inputs?.contactState}
                    disabled={disableFieldForAdmins || isCustomerApproved}
                    marginBottom={fieldMarginBottom}
                    onSelectFocus={onSelectFocus}
                />

                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCity"
                    floatingLabel="L.G.A."
                    initialOption="L.G.A."
                    selectWidth="100%"
                    values={localGovtData[inputs?.contactState || 'Lagos']?.map(
                        option => ({
                            value: option,
                        }),
                    )}
                    disabled={
                        inputs?.contactState?.length < 1 ||
                        disableFieldForAdmins ||
                        isCustomerApproved
                    }
                    value={inputs?.contactCity}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCity')}
                    errorMessage={errors?.contactCity}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCity}
                    selectedValue={inputs?.contactCity}
                    marginBottom={fieldMarginBottom}
                    onSelectFocus={onSelectFocus}
                />
            </div>

            {isOnePageDynamicKyc ? (
                <>
                    <div className={s.sameAddressWrapper}>
                        <p>
                            Is this contact address the same as where you want
                            to install the system?
                        </p>
                        <div className="SwitchWrapper">
                            <span
                                style={{
                                    color: !inputs?.sameAddress?.value
                                        ? '#004AAD'
                                        : '',
                                }}
                            >
                                No
                            </span>
                            <Switch
                                size={isMobile ? 'small' : ''}
                                checked={inputs?.sameAddress?.value}
                                onChange={e => {
                                    handleSameAddressOptionChange(e)
                                }}
                                disabled={isCustomerApproved}
                            />
                            <span
                                style={{
                                    color: inputs?.sameAddress?.value
                                        ? '#004AAD'
                                        : '',
                                }}
                            >
                                Yes
                            </span>
                        </div>
                    </div>
                    {errors?.confirmAddress && (
                        <span className="fieldError">
                            {errors?.confirmAddress}
                        </span>
                    )}
                </>
            ) : (
                <div className="ContactAddress_Confirmation-Wrapper">
                    <p>
                        Is your contact address the same as where you want to
                        install the system?
                    </p>
                    <SelectButtons
                        selectedValue={inputs?.sameAddress?.title}
                        values={sameAddressOptions?.map(o => o.title)}
                        handleChange={value =>
                            handleSameAddressOptionChange(value)
                        }
                        width={163}
                        height={46}
                        disabled={isAdminUser || isCustomerApproved}
                    />
                    {errors?.confirmAddress && (
                        <span className="fieldError">
                            {errors?.confirmAddress}
                        </span>
                    )}
                </div>
            )}

            <VerticalSpacer top={16} />
            {!inputs?.sameAddress?.value && (
                <>
                    {!isOnePageDynamicKyc && (
                        <Label
                            title="Your installation address"
                            subTitle="Please provide us with where you want to have the inverter installed."
                        />
                    )}
                    <InputFields
                        name="installationAddress"
                        title={'Installation Address'}
                        handleChange={handleChange}
                        onBlur={handleOnBlur}
                        value={inputs?.installationAddress}
                        errorMessage={errors?.installationAddress}
                        disabled={disableFieldForAdmins || isCustomerApproved}
                        marginBottom={fieldMarginBottom}
                        onFocus={onInputFocus}
                    />

                    <div
                        className="PersonalInfo_DualSelectField-Wrapper"
                        style={{ marginBottom: isOnePageDynamicKyc ? 0 : '' }}
                    >
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCountry"
                            floatingLabel="Country"
                            initialOption="Country"
                            selectWidth="100%"
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCountry')
                            }
                            values={countries.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationCountry}
                            errorMessage={errors?.installationCountry}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationCountry}
                            selectedValue={inputs?.installationCountry}
                            disabled={
                                disableFieldForAdmins || isCustomerApproved
                            }
                            marginBottom={fieldMarginBottom}
                            onSelectFocus={onSelectFocus}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationState"
                            floatingLabel="State"
                            initialOption="State"
                            selectWidth="100%"
                            values={stateData?.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationState}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationState')
                            }
                            errorMessage={errors?.installationState}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationState}
                            selectedValue={inputs?.installationState}
                            disabled={
                                disableFieldForAdmins || isCustomerApproved
                            }
                            marginBottom={fieldMarginBottom}
                            onSelectFocus={onSelectFocus}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCity"
                            floatingLabel="L.G.A."
                            initialOption="L.G.A."
                            selectWidth="100%"
                            values={localGovtData[
                                inputs?.installationState || 'Lagos'
                            ]?.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationCity}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCity')
                            }
                            errorMessage={errors?.installationCity}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationCity}
                            selectedValue={inputs?.installationCity}
                            disabled={
                                inputs?.installationState?.length < 1 ||
                                disableFieldForAdmins ||
                                isCustomerApproved
                            }
                            marginBottom={fieldMarginBottom}
                            onSelectFocus={onSelectFocus}
                        />
                    </div>
                    <VerticalSpacer top={12} />
                </>
            )}
        </>
    )
}

Address.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default Address
