import { useMutation } from 'react-query'
import { onePageSubmitConsumerInfo } from 'src/api/shoppingExperience/kyc/api'

const useSaveAuditedFinancialReport = ({ onSuccess, onError }) => {
    const saveAuditedFinancialReport = useMutation({
        mutationFn: ({ url, estimationId }) =>
            onePageSubmitConsumerInfo({
                type: 'BUSINESS',
                data: {
                    financial_information: {
                        estimation_id: estimationId,
                        audited_financial_report: url,
                    },
                    submit: false,
                    dynamic_kyc: false,
                },
            }),
        onSuccess: () => {
            onSuccess?.()
        },
        onError: err => {
            onError?.(err)
        },
    })

    return { saveAuditedFinancialReport }
}

export default useSaveAuditedFinancialReport
