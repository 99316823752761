import { useMutation } from 'react-query'
import { notifyEnergyNeedsOverrideApi } from 'src/api/estimations/estimations-step-two'
import { errorHandler } from 'src/utils/errorHandler'

const useNotifyEnergyNeedsOverride = ({
    setModalToast,
    resetToast,
    onSuccess,
}) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ estimationId, data }) =>
            notifyEnergyNeedsOverrideApi(estimationId, data),
        onSuccess: () => {
            onSuccess?.()
        },
        onError: error => {
            setModalToast({
                showToast: true,
                toastMessage:
                    errorHandler(error?.response?.data) ||
                    'An error occurred, please try again later.',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    return {
        notifyEnergyNeedsOverrideMutate: mutate,
        notifyEnergyNeedsOverrideLoading: isLoading,
    }
}

export default useNotifyEnergyNeedsOverride
