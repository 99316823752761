/* eslint-disable react/prop-types */
import { useState } from 'react'
import { Tabs } from 'antd'
import BackNav from '../../../components/BackNav'
import EnergyRecommendation from '../EnergyRecommendation'
import './viewrecommendation.scss'
import PaymentPlansForAltRecommendations from '../PaymentPlanForAltRecommendation'

const ViewRecommendation = ({
    closeModal,
    viewSelectedSolution,
    selectedSolutions,
    estimationId,
    handleTriggerRefetch,
    triggerSetSolution,
    module,
    dropOffId,
    handleUpdateAltPackageSuccess = () => {},
    extensivePackageData,
    setExtensivePackageData = () => {},
}) => {
    const [selected, setSelected] = useState([])

    const { TabPane } = Tabs
    return (
        <div className="ViewRecommendationsWrapper">
            <div className="BackNavWrapper">
                <BackNav title="Back to Recommendations" onClick={closeModal} />
            </div>

            <Tabs defaultActiveKey="1" className="RecommendedSolutionTab">
                <TabPane tab="Energy Recommendation" key="1">
                    <EnergyRecommendation
                        closeModal={closeModal}
                        energySolution={viewSelectedSolution}
                        selected={selected}
                        setSelected={setSelected}
                        selectedSolutions={selectedSolutions}
                        estimationId={estimationId}
                        handleTriggerRefetch={handleTriggerRefetch}
                        triggerSetSolution={triggerSetSolution}
                        module={module}
                        dropOffId={dropOffId}
                        handleUpdateAltPackageSuccess={
                            handleUpdateAltPackageSuccess
                        }
                        setExtensivePackageData={setExtensivePackageData}
                    />
                </TabPane>
                <TabPane
                    tab="Payment Plans"
                    key="2"
                    disabled={
                        module === 'alt-recommendations' &&
                        extensivePackageData?.length === 0
                    }
                >
                    <PaymentPlansForAltRecommendations
                        modalData={extensivePackageData}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ViewRecommendation
