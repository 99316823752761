import { getPermissions } from './formatter'

export const tableHeaders = ['Package name', 'Package amount', 'Action']

export const moreActions = isPublished => {
    return [
        {
            id: 1,
            title: 'Edit consumer profile information',
            action: 'edit-profile',
            disabled: !getPermissions().canUpdateEnergyProfile,
        },
        {
            id: 2,
            title: 'Duplicate consumer profile',
            action: 'duplicate-profile',
            disabled: !getPermissions().canDuplicateEnergyProfile,
        },
        ...(isPublished
            ? [
                  {
                      id: 3,
                      title: 'Unpublish consumer profile',
                      action: 'unpublish-profile',
                      disabled: !getPermissions().canUpdateEnergyProfile,
                  },
              ]
            : [
                  {
                      id: 3,
                      title: 'Publish consumer profile',
                      action: 'publish-profile',
                      disabled: !getPermissions().canUpdateEnergyProfile,
                  },
              ]),
        ...(isPublished
            ? [
                  {
                      id: 4,
                      title: 'Delete consumer profile',
                      action: 'unpublish-profile-before-deleting',
                      disabled: !getPermissions().canDeleteEnergyProfile,
                  },
              ]
            : [
                  {
                      id: 3,
                      title: 'Delete consumer profile',
                      action: 'delete-profile',
                      disabled: !getPermissions().canDeleteEnergyProfile,
                  },
              ]),
    ]
}

export const actionModalValues = action => {
    switch (action) {
        case 'remove-package':
            return {
                actionModalHeaderText: 'Remove package',
                actionModalSubtitle:
                    'You’re about to remove a package from this consumer profile, please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Remove package',
            }
        case 'package-removed':
            return {
                actionModalHeaderText: 'Package removed',
                actionModalSubtitle:
                    'You have removed this package from this consumer profile',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'package-saved':
            return {
                actionModalHeaderText: 'Package saved',
                actionModalSubtitle:
                    'You have saved your changes to this package',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'publish-profile':
            return {
                actionModalHeaderText: 'Publish consumer profile',
                actionModalSubtitle:
                    'You’re about to publish this consumer profile, it’ll be added to the list of available consumer profiles. Please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Publish consumer profile',
            }
        case 'profile-published':
            return {
                actionModalHeaderText: 'Consumer Profile published',
                actionModalSubtitle:
                    'You have successfully published this consumer profile, and its been added to the list of available consumer profiles',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'unpublish-profile':
            return {
                actionModalHeaderText: 'Unpublish consumer profile',
                actionModalSubtitle:
                    'You’re about to remove this consumer profile, it’ll be taken out from the list of available consumer profiles. Please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Remove consumer profile',
            }
        case 'profile-unpublished':
            return {
                actionModalHeaderText: 'Consumer Profile unpublished',
                actionModalSubtitle:
                    'You have successfully unpublished this consumer profile',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'unpublish-profile-before-deleting':
            return {
                actionModalHeaderText:
                    'Unpublish consumer profile before deleting',
                actionModalSubtitle:
                    'You need to unpublish this consumer profile before deleting the consumer profile',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Unpublish now',
            }
        case 'unpublish-profile-before-deleting-confirmation':
            return {
                actionModalHeaderText: 'Unpublish consumer profile',
                actionModalSubtitle:
                    'You’re about to remove this consumer profile, it’ll be taken out from the list of available consumer profiles. Please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Remove consumer profile',
            }
        case 'profile-unpublished-before-deleting':
            return {
                actionModalHeaderText: 'Consumer Profile unpublished',
                actionModalSubtitle:
                    'You have successfully unpublished this consumer profile',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Delete consumer profile',
            }
        case 'delete-profile':
            return {
                actionModalHeaderText: 'Delete consumer profile',
                actionModalSubtitle:
                    'You’re about to delete this consumer profile, it’ll be taken out from the list of available consumer profiles. Please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Delete consumer profile',
            }
        case 'profile-deleted':
            return {
                actionModalHeaderText: 'Consumer Profile deleted',
                actionModalSubtitle:
                    'You have successfully deleted this consumer profile',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'duplicate-profile':
            return {
                actionModalHeaderText: 'Duplicate consumer profile',
                actionModalSubtitle:
                    'You’re about to duplicate this consumer profile, you’ll be required to also edit the details of the profile. Please confirm',
                actionModalActionType: 'warning',
                actionModalShowCancelBtn: true,
                actionModalActionText: 'Duplicate consumer profile',
            }
        case 'profile-duplicated':
            return {
                actionModalHeaderText: 'Consumer Profile duplicated',
                actionModalSubtitle:
                    'You have successfully duplicated this consumer profile, and you can now edit the details of the profile',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Edit consumer profile',
            }
        default:
            return {
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalShowCancelBtn: true,
                actionModalActionText: '',
            }
    }
}
