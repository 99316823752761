import PropTypes from 'prop-types'

export const sharedFormComponentPropTypes = {
    kycModule: PropTypes.object,
    inputs: PropTypes.object,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    handleChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    handleSelectOnBlur: PropTypes.func,
    handleSameAddressOptionChange: PropTypes.func,
    showRemoveFileModal: PropTypes.bool,
    fileToBeRemoved: PropTypes.object,
    closeRemoveFileModal: PropTypes.func,
    closeRemoveFileModalAfterSuccess: PropTypes.func,
    showCalendar: PropTypes.bool,
    setShowCalendar: PropTypes.func,
    setShowUploadModal: PropTypes.func,
    handleRemoveFile: PropTypes.func,
    employmentContract: PropTypes.array,
    setEmploymentContract: PropTypes.func,
    showUploadModal: PropTypes.bool,
    handleCloseUploadModal: PropTypes.func,
    successEmploymentContractUpload: PropTypes.array,
    setSuccessEmploymentContractUpload: PropTypes.func,
    initiateContactVerification: PropTypes.func,
    initiateEmailVerificationLoading: PropTypes.bool,
    initiatePhoneVerificationLoading: PropTypes.bool,
    handleBusinessRegisteredChange: PropTypes.func,
    toggleShowCacVerifyModal: PropTypes.func,
    cacVerificationInProgress: PropTypes.bool,
    isCustomerApproved: PropTypes.bool,
    toggleSelfieImageModal: PropTypes.func,
    showIdOverrideModal: PropTypes.bool,
    toggleIdOverrideModal: PropTypes.func,
    kycStatus: PropTypes.object,
    idVerificationInProgress: PropTypes.bool,
    openIdVerifyModal: PropTypes.func,
    openBVNModal: PropTypes.func,
    bvnVerificationInProgress: PropTypes.bool,
    kycData: PropTypes.object,
    showOnlyMono: PropTypes.bool,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onePageKyc: PropTypes.bool,
    startMonoLoading: PropTypes.func,
    stopMonoLoading: PropTypes.func,
    connectToMonoLoading: PropTypes.bool,
    page: PropTypes.string,
    useType: PropTypes.string,
    onInputFocus: PropTypes.func,
    onSelectFocus: PropTypes.func,
    handleAutoSave: PropTypes.func,
    handlePersistEmploymentContract: PropTypes.func,
    uploadModalBtnLoading: PropTypes.func,
    handlePersistMoa: PropTypes.func,
    providerUpdatedFieldsData: PropTypes.object,
}

export const videoGuide = (module, kycData) => {
    const userType =
        kycData?.consumer?.customer_type === 'RESIDENTIAL'
            ? 'residential'
            : 'business'

    switch (module) {
        case 'identity_information': {
            return {
                showVideoGuide: true,
                videoGuideTitle: 'Next step',
                videoGuideHeader: 'Verify your Identity',
                videoGuideInfo:
                    'Here’s a video to guide you on how you can verify your ID',
                videoGuideUrl: 'https://www.youtube.com/watch?v=AkLVZVvfqoU',
                videoGuideGIF:
                    'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Verify_Your_Identity.gif',
                videoGuideDescription:
                    'We need to verify your identity before we can provide financing, to do this, you’ll have to add an image of your self and also an ID number of a valid government-issued ID number such as: International Passport, Driver’s license or National ID (NIN)',
                videoGuidePageTitle: 'Watch how to verify your identity',
            }
        }

        case 'financial_information': {
            return {
                showVideoGuide: true,
                videoGuideTitle: 'Next step',
                videoGuideHeader: 'Submit your financial information',
                videoGuideInfo:
                    'Here’s a video to guide you on how you submit your financial information',
                videoGuideUrl:
                    userType === 'business'
                        ? 'https://www.youtube.com/watch?v=iiDpoTvw4mk'
                        : 'https://www.youtube.com/watch?v=nmkqKnnyFEo',
                videoGuideGIF:
                    userType === 'business'
                        ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Enter_Your_Financial_Details_as_a_Registered_Business.gif'
                        : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_Individuals_or_Unregistered_Businesses_Can_Submit_Their_.gif',
                videoGuideDescription:
                    userType === 'business'
                        ? 'Please submit your monthly revenue, 12 months of up-to-date primary bank statements, and audited financial report. Ensure bank statements are clear, legible, complete, and display all key details for quick analysis.'
                        : 'Please submit a 6-month up-to-date bank statement and BVN for credit check. Ensure clear, complete, and legible bank statements for quick analysis.',
                videoGuidePageTitle: 'Watch how to submit your financial info',
            }
        }

        default: {
            return {
                showVideoGuide: false,
                videoGuideTitle: '',
                videoGuideHeader: '',
                videoGuideInfo: '',
                videoGuideUrl: '',
                videoGuideGIF: '',
                videoGuideDescription: '',
                videoGuidePageTitle: '',
            }
        }
    }
}

export const buildStatementUploadErrorMessage = missingFields => {
    const fieldKeys = Object.keys(missingFields)
    if (fieldKeys.length === 0) {
        return ''
    }

    const fieldDisplayNames = {
        firstName: 'first name',
        lastName: 'last name',
        businessName: 'business name',
        directorFirstName: "director's first name",
        directorLastName: "director's last name",
    }

    const fieldNames = fieldKeys.map(field => fieldDisplayNames[field] || field)
    let errorMessage = 'Please provide your '
    if (fieldNames.length === 1) {
        errorMessage += `${fieldNames[0]}`
    } else if (fieldNames.length === 2) {
        errorMessage += `${fieldNames[0]} and ${fieldNames[1]}`
    } else {
        const allButLast = fieldNames.slice(0, -1).join(', ')
        const lastField = fieldNames[fieldNames.length - 1]
        errorMessage += `${allButLast} and ${lastField}`
    }
    errorMessage += ' before uploading a bank statement'
    return errorMessage
}
