import { useParams, useHistory } from 'react-router-dom'
import DetailsPage from 'src/components/DetailsPage'
import ProviderListTable from './components/ProviderListTable'
import { useQuery } from 'react-query'
import {
    addGlobalLibraryContributorApi,
    addGlobalLibraryUserApi,
    addSubscriptionProviderApi,
    deleteGlobalLibraryContributorApi,
    deleteGlobalLibraryUserApi,
    deleteSubscriptionUserApi,
    getGlobalLibraryContributorsApi,
    getGlobalLibraryUsersApi,
    getProvidersApi,
    getSubscriptionUsersApi,
} from 'src/api/admin/settings/providerresources'
import { useState, useEffect } from 'react'
import ActionModal from 'src/components/ActionModal'
import AddProviderModal from './components/AddProviderModal'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { permissionsControl } from 'src/utils/permissionsControl'
import { getPermissions } from '../Listings/data'
import { isObjectEmpty } from 'src/utils/removeEmptyValues'

const AdminProviderResources = () => {
    const { viewLibraryUserPermissions } = getPermissions()
    const [providersData, setProvidersData] = useState([])
    const [unassignedProviders, setUnassignedProviders] = useState([])
    const [action, setAction] = useState('')
    const [selectedProvider, setSelectedProvider] = useState({
        id: '',
        status: '',
    })
    const [providerToAdd, setProviderToAdd] = useState({})
    const [providerInput, setProviderInput] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [providerError, setProviderError] = useState('')
    const [pageToast, setPageToast] = useState(false)
    const [pageToastMessage, setPageToastMessage] = useState('')

    //modals
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [addProviderModalOpen, setAddProviderModalOpen] = useState(false)

    const params = useParams()
    const history = useHistory()

    const titleFormatting = {
        'global-library-contributors': 'Global Library Contributors',
        'global-library-users': 'Global Library Users',
        'subscription-model-providers': 'Subscription model providers',
    }

    const warningModalTitleFormatting = {
        'exit-settings-to-provider': 'Exit Settings?',
        'remove-provider': 'Remove Provider',
    }

    const warningModalSubTitleFormatting = {
        'exit-settings-to-provider':
            'You will be navigated to the Provider workspace. Are you sure you want to exit settings?',
        'remove-provider': 'Are you sure you want to remove this provider?',
    }

    const warningModalBtnTextFormatting = {
        'exit-settings-to-provider': 'Continue',
        'remove-provider': 'Remove',
    }

    const successModalTitleFormatting = {
        'remove-provider': 'Provider Removed Successfully',
        'add-provider': 'Provider Added Successfully',
    }

    const successModalSubTitleFormatting = {
        'remove-provider': `You have successfully removed a provider`,
        'add-provider': `You have successfully added a provider`,
    }

    const fetchResourceApiMapping = {
        'global-library-contributors': getGlobalLibraryContributorsApi,
        'global-library-users': getGlobalLibraryUsersApi,
        'subscription-model-providers': getSubscriptionUsersApi,
    }

    const fetchResourceQueryKeyMapping = {
        'global-library-contributors': 'fetch-global-library-contributors',
        'global-library-users': 'fetch-global-library-users',
        'subscription-model-providers': 'fetch-subscription-users',
    }

    const addProviderApiMapping = {
        'global-library-contributors': addGlobalLibraryContributorApi,
        'global-library-users': addGlobalLibraryUserApi,
        'subscription-model-providers': addSubscriptionProviderApi,
    }

    const addProviderQueryKeyMapping = {
        'global-library-contributors': 'add-global-library-contributor',
        'global-library-users': 'add-global-library-user',
        'subscription-model-providers': 'add-subscription-provider',
    }

    const filterResourceMapping = {
        'global-library-contributors': 'is_gl_contributor',
        'global-library-users': 'is_gl_user',
        'subscription-model-providers': 'is_subscription_provider',
    }

    const removeProviderApiMapping = {
        'global-library-contributors': deleteGlobalLibraryContributorApi,
        'global-library-users': deleteGlobalLibraryUserApi,
        'subscription-model-providers': deleteSubscriptionUserApi,
    }

    const removeProviderQueryKeyMapping = {
        'global-library-contributors': 'remove-global-library-contributor',
        'global-library-users': 'remove-global-library-user',
        'subscription-model-providers': 'remove-subscription-provider',
    }

    const checkCreateLibraryUserPermissions = () => {
        const permissions = {
            'global-library-contributors': permissionsControl([
                'can_create_global_library_contributor',
            ]),
            'global-library-users': permissionsControl([
                'can_create_global_library_user',
            ]),
            'subscription-model-providers': permissionsControl([
                'can_create_subscription_model_provider',
            ]),
        }
        return permissions
    }

    const createLibraryUserPermissions = checkCreateLibraryUserPermissions()

    //get providers under a resource
    const { refetch: resourceRefetch, isFetching: resourceLoading } = useQuery(
        fetchResourceQueryKeyMapping[params?.id],
        () => fetchResourceApiMapping[params?.id](),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const res = data?.data?.data
                setProvidersData([])
                for (let i = 0; i < res.length; i++) {
                    setProvidersData(prev => [
                        ...prev,
                        {
                            name: res?.[i]?.provider?.display_name,
                            type: res?.[i]?.provider?.user?.provider?.type
                                ?.identifier,
                            id: res?.[i]?.provider?.id,
                            id_under_resource: res?.[i]?.id,
                            status: res?.[i]?.provider?.status,
                        },
                    ])
                }
            },
            onError: error => {
                setPageToast(true)
                setPageToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //get all providers filtered to get only those not currently using a resource
    const { refetch: allProvidersRefetch, isFetching: allProvidersFetching } =
        useQuery('fetch-all-providers', () => getProvidersApi(), {
            enabled: false,
            retry: false,
            onSuccess: async data => {
                const res = await data?.data?.data
                setUnassignedProviders([])
                for (let i = 0; i < res?.length; i++) {
                    if (
                        !res?.[i]?.user?.provider?.[
                            filterResourceMapping[params.id]
                        ]
                    ) {
                        setUnassignedProviders(prev => [
                            ...prev,
                            { name: res?.[i]?.display_name, id: res?.[i]?.id },
                        ])
                    }
                }
            },
            onError: error => {
                setPageToast(true)
                setPageToastMessage(errorHandler(error?.response?.data))
            },
        })

    useEffect(() => {
        resourceRefetch()
        allProvidersRefetch()
    }, [allProvidersRefetch, resourceRefetch])

    //adding providers
    const { refetch: addProviderRefetch, isLoading: addProviderLoading } =
        useQuery(
            addProviderQueryKeyMapping[params?.id],
            () => addProviderApiMapping[params?.id](providerToAdd[0].id),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    closeAddProviderModal()
                    setSuccessModalOpen(true)
                    resourceRefetch()
                    allProvidersRefetch()
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //removing providers
    const { refetch: removeProviderRefetch, isLoading: removeProviderLoading } =
        useQuery(
            removeProviderQueryKeyMapping[params?.id],
            () => removeProviderApiMapping[params?.id](selectedProvider?.id),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setWarningModalOpen(false)
                    setSuccessModalOpen(true)
                    resourceRefetch()
                    allProvidersRefetch()
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //exiting settings to provider page
    const handleExitWarning = (id, status) => {
        setAction('exit-settings-to-provider')
        setSelectedProvider({ id: id, status: status })
        setWarningModalOpen(true)
    }

    //removing a provider
    const handleRemoveWarning = id => {
        setAction('remove-provider')
        setSelectedProvider({ id: id, status: '' })
        setWarningModalOpen(true)
    }

    //adding a provider
    const handleAddProviderClick = () => {
        setAction('add-provider')
        setAddProviderModalOpen(true)
    }

    const handleAddProviderSubmit = () => {
        if (providerInput.length === 0) {
            setProviderError('Select a provider name to continue')
        } else {
            addProviderRefetch()
        }
    }

    const handleInputChange = (name, value) => {
        setProviderError('')
        setProviderInput(value)
        setProviderToAdd(unassignedProviders.filter(obj => obj.name === value))
    }

    const closeAddProviderModal = () => {
        setAddProviderModalOpen(false)
        setProviderInput('')
        setProviderError('')
    }

    const closeSuccessModal = () => {
        setSuccessModalOpen(false)
    }

    // redirect users without view permission to the listings page
    useEffect(() => {
        if (
            !isObjectEmpty(viewLibraryUserPermissions) &&
            !viewLibraryUserPermissions[params?.id]
        ) {
            history.push('/admin/settings?tab=3')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewLibraryUserPermissions])

    return (
        <>
            <AddProviderModal
                type={params?.id}
                showModal={addProviderModalOpen}
                onCancel={closeAddProviderModal}
                data={unassignedProviders}
                input={providerInput}
                handleChange={handleInputChange}
                isLoading={addProviderLoading}
                handleClick={handleAddProviderSubmit}
                toastError={toastError}
                errorMessage={errorMessage}
                error={providerError}
            />
            {/*warning modal*/}
            <ActionModal
                actionType="warning"
                actionModalOpen={warningModalOpen}
                headerText={warningModalTitleFormatting[action]}
                subTitle={warningModalSubTitleFormatting[action]}
                onCancel={() => setWarningModalOpen(false)}
                closeModal={() => setWarningModalOpen(false)}
                actionText={warningModalBtnTextFormatting[action]}
                actionHandler={() => {
                    if (action === 'exit-settings-to-provider') {
                        history.push(
                            `/admin/providers/${selectedProvider?.status}/${selectedProvider?.id}`,
                        )
                    }
                    if (action === 'remove-provider') {
                        removeProviderRefetch()
                    }
                }}
                noBackLink
                loading={removeProviderLoading}
                toastError={toastError}
                errorMessage={errorMessage}
            />

            {/*success modal*/}
            <ActionModal
                actionType="success"
                actionModalOpen={successModalOpen}
                headerText={successModalTitleFormatting[action]}
                subTitle={successModalSubTitleFormatting[action]}
                actionText="Okay, Got it"
                onCancel={() => closeSuccessModal()}
                actionHandler={() => closeSuccessModal()}
                noCancelBtn
                noBackLink
            />
            {/*error toast*/}
            {pageToast && (
                <Toast messageType="error" message={pageToastMessage} />
            )}

            <DetailsPage
                name={titleFormatting[params?.id]}
                noStatusFlag
                backNavTitle="Back to Settings"
                backNavUrl="/admin/settings?tab=3"
                infoSubhead="DESCRIPTION"
                infoParagraph="Not available"
                useCustomTable
                customTable={
                    <ProviderListTable
                        data={providersData}
                        handleExitClick={handleExitWarning}
                        handleRemoveClick={handleRemoveWarning}
                        isLoading={resourceLoading || allProvidersFetching}
                        pageId={params?.id}
                    />
                }
                rightSectionHeading="Action"
                placeRightSectionHeadingBottom
                rowCount={0}
                actionButton
                buttonOneText="Add Provider"
                buttonOneHandleClick={() => handleAddProviderClick()}
                buttonOneDisabled={!createLibraryUserPermissions[params?.id]}
                buttonOneTooltipText={
                    !createLibraryUserPermissions[params?.id]
                        ? 'You are not authorised to perform this action'
                        : null
                }
                isLoading={resourceLoading || allProvidersFetching}
            />
        </>
    )
}
export default AdminProviderResources
