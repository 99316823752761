import { useCallback } from 'react'

const useValidateOnBlur = () => {
    const validateField = useCallback(
        async ({ name, value, schema, setErrors, context = {}, onSuccess }) => {
            try {
                await schema.validateAt(
                    name,
                    { [name]: value },
                    { context: context },
                )
                setErrors(prevErrors => {
                    const newErrors = { ...prevErrors }
                    delete newErrors[name]
                    return newErrors
                })
                onSuccess?.()
            } catch (error) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: error.message,
                }))
            }
        },
        [],
    )

    return validateField
}

export default useValidateOnBlur
