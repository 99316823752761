import FormBox from 'src/components/FormBox'
import FormTitleBar from 'src/components/FormTitleBar'
import './otp.scss'
import Button from 'src/components/Button'
import SeoComponent from '../../components/Seo'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { decrypt, encrypt } from 'src/utils/cryptography'
import useVerify from 'src/components/GettingStarted/hooks/useVerify'
import { useMutation } from 'react-query'
import { loginApi, verifyLoginOTPApi } from 'src/api/login'
import { otpSchema } from 'src/utils/validationSchema'
import { formatPhoneNumber } from 'src/utils/formatting'
import { useHistory } from 'react-router-dom'
import { appTracking } from 'src/utils/appTracker'
import { encodeUserInfo } from 'src/utils/auth'
import { onboardingStage, stageNumber } from '../Login/stages'
import { removeCountryCode } from 'src/utils/NumberFormatter'
import { maskEmail, maskPhoneNumber } from 'src/utils/maskDetails'
import Toast from 'src/components/Toast'
import useErrorHandler from 'src/utils/Hooks/useErrorHandler'
import { InlineLoader } from 'src/components/Loader'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'

const OTP = ({ showTextHandler, hasUniqueRoute, hideOtpScreen }) => {
    const [contactType, setContactType] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [otp, setOTP] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)

    const [errs, setErrs] = useState(null)
    const history = useHistory()
    const decryptedData =
        localStorage.getItem('sunfiUserLoggingIn') &&
        JSON.parse(decrypt(localStorage.getItem('sunfiUserLoggingIn')))
    const decryptedLoginStageData =
        sessionStorage.getItem('sunfiUserLoginStage') &&
        JSON.parse(decrypt(sessionStorage.getItem('sunfiUserLoginStage')))

    const [nextPage] = useState(
        window?.location?.search?.split('next=')[1] ?? '',
    )

    useEffect(() => {
        showTextHandler(true)
        if (
            decryptedLoginStageData?.stage !== 'OTP_REQUESTED' ||
            (hasUniqueRoute && history.location.state?.from !== 'LOGIN') ||
            !decryptedData ||
            !(decryptedData.phone || decryptedData.email)
        ) {
            if (hasUniqueRoute) {
                history.push('/login')
            } else {
                hideOtpScreen && hideOtpScreen()
            }
            return
        } else {
            setContactType(decryptedData.contactType)
            decryptedData.contactType === 'phone'
                ? setPhoneNumber(decryptedData.phone)
                : setEmail(decryptedData.email)
        }
        // eslint-disable-next-line
    }, [])

    const resendOTPMutation = useMutation({
        mutationFn: () => {
            return loginApi(
                contactType,
                contactType === 'email'
                    ? {
                          email,
                          password: decryptedData.password,
                      }
                    : {
                          phone_number: formatPhoneNumber(phoneNumber, '+234'),
                          password: decryptedData.password,
                      },
            )
        },
    })

    const verifyOTP = useMutation({
        mutationFn: () => {
            return verifyLoginOTPApi({
                code: otp.join(''),
                ...(contactType === 'phone'
                    ? { phone_number: phoneNumber }
                    : { email: email }),
            })
        },
        onSuccess: data => {
            appTracking('', '', '', '', '', '', ['MP'], 'identify', {
                identify: { id: data?.data?.data?.user?.id },
            })
            appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                people_set: {
                    'User Types': data?.data?.data?.user?.user_types,
                    last_completed_stage:
                        data?.data?.data?.last_completed_stage,
                    'Phone Number': data?.data?.data?.user?.phone_number,
                    $email: data?.data?.data?.user?.email,
                    $name: data?.data?.data?.user?.display_name,
                    $avatar: data?.data?.data?.user?.avatar_url,
                },
            })
            encodeUserInfo(data)
            const encodedNextStage = encrypt(
                data?.data?.data?.next_stage === null ||
                    data?.data?.data?.user?.is_system_admin
                    ? 'completed'
                    : 'ongoing',
            )
            localStorage.setItem('sunfiUserStage', encodedNextStage)
            localStorage.setItem('sunfiToken', data?.data?.data?.token?.access)
            localStorage.removeItem('isSSEConsumer')
            localStorage.removeItem('isClusterRep')

            if (
                data?.data?.data?.next_stage === null ||
                data?.data?.data?.user?.is_admin_workspace_user
            ) {
                if (data?.data?.data?.user?.is_admin_workspace_user) {
                    history.push(nextPage !== '' ? nextPage : 'admin/products')
                } else {
                    history.push(
                        nextPage !== ''
                            ? nextPage
                            : process.env.REACT_APP_LOGGEDIN_LANDING_URL
                            ? process.env.REACT_APP_LOGGEDIN_LANDING_URL
                            : 'app/products',
                    )
                }
            } else {
                const hasOnboardingStarted =
                    data?.data?.data?.last_completed_stage ===
                    onboardingStage['COMPLETED_SIGN_UP']
                        ? 'notstarted'
                        : 'started'

                history.push({
                    pathname: `/onboarding`,
                    state: {
                        onboardingState: hasOnboardingStarted,
                        nextOnboardingStage:
                            stageNumber[data?.data?.data?.next_stage],
                    },
                })
            }
            sessionStorage.removeItem('sunfiUserLoginStage')
        },
        onError: error => {
            if (
                error?.response?.status === 403 &&
                error?.response?.data?.message ===
                    'Your workspace account is yet to be verified'
            ) {
                history.push({
                    pathname: `/workspace-status`,
                    state: {
                        workspace: {
                            unapproved: true,
                        },
                    },
                })
            }
            if (
                error?.response?.status === 403 &&
                error?.response?.data?.message ===
                    'Your workspace account is suspended'
            ) {
                history.push({
                    pathname: `/workspace-status`,
                    state: {
                        workspace: {
                            suspended: true,
                        },
                    },
                })
            }
            setErrs(error)
        },
    })

    const {
        requestCode,
        handleInputChange,
        handleKeyDown,
        handleInputPaste,
        activeOtpIndex,
        inputsRef,
        timerRef,
    } = useVerify(() => resendOTPMutation.mutate(), otp, setOTP)

    const errorValue = useErrorHandler(errs)

    const handleSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                verifyOTP.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    useOTPVerify({ otp, handleOtpSubmit: handleSubmit })

    return (
        <>
            {hasUniqueRoute && <SeoComponent title="SunFi - Provide OTP" />}
            <div className="OTPContainer">
                <FormBox width="450px">
                    <FormTitleBar
                        title="Verification Code"
                        subtitle={
                            <>
                                Enter the OTP that was sent to{' '}
                                <span className="PhoneEmail">
                                    {contactType === 'phone'
                                        ? maskPhoneNumber(
                                              removeCountryCode(phoneNumber),
                                          )
                                        : maskEmail(email)}
                                </span>
                            </>
                        }
                    />
                    {errs && (
                        <Toast
                            messageType="error"
                            message={
                                verifyOTP.isLoading
                                    ? ''
                                    : errorValue['msg'][0].otp ??
                                      errorValue['msg']
                            }
                        />
                    )}
                    <div className="OTPGroup">
                        {otp.map((_, idx) => (
                            <input
                                key={idx}
                                ref={idx === activeOtpIndex ? inputsRef : null}
                                type="number"
                                inputMode="numeric"
                                autoComplete="one-time-code"
                                pattern="\d{1}"
                                onChange={e =>
                                    handleInputChange(e.target.value, idx)
                                }
                                onKeyDown={e => handleKeyDown(e, idx)}
                                onPaste={e => handleInputPaste(e, idx)}
                                value={otp[idx]}
                            />
                        ))}
                    </div>
                    {otpError && (
                        <p className="OTPErrorMsg">Please enter a valid OTP</p>
                    )}

                    <div>
                        <CountdownTimer
                            timerRef={timerRef}
                            requestCode={requestCode}
                        />

                        <div className="VerifyGroup">
                            <Button
                                type="primary"
                                btnWidth="194px"
                                btnHeight="56px"
                                disabled={!otp.every(value => value !== '')}
                                handleClick={handleSubmit}
                            >
                                {verifyOTP.isLoading ? (
                                    <InlineLoader />
                                ) : (
                                    <>
                                        Verify{' '}
                                        {contactType === 'phone'
                                            ? 'Phone Number'
                                            : 'Email Address'}
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </FormBox>
            </div>
        </>
    )
}

OTP.propTypes = {
    showTextHandler: PropTypes.func,
    hasUniqueRoute: PropTypes.bool,
    hideOtpScreen: PropTypes.func,
}

export default OTP

const CountdownTimer = props => {
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        if (props.timerRef?.current) {
            props.timerRef.current.setTime = time => setTimer(time)
        }
    }, [props.timerRef])

    return (
        <div className="ResendOTPGroup">
            <p>Didn&apos;t receive the code?</p>
            {timer > 0 ? (
                <p>
                    Request for new code in 00:
                    {timer < 10 ? `0${timer}` : timer}
                </p>
            ) : (
                <p
                    onClick={() => props.requestCode?.()}
                    role={'button'}
                    style={{
                        cursor: 'pointer',
                        color: '',
                    }}
                    data-testid="resend-code"
                >
                    Resend code
                </p>
            )}
        </div>
    )
}

CountdownTimer.propTypes = {
    requestCode: PropTypes.func,
    timerRef: PropTypes.object,
}
