import { useMutation } from 'react-query'
import { checkEnergyNeedsMatchApi } from 'src/api/estimations/estimations-step-two'
import { errorHandler } from 'src/utils/errorHandler'

const useCheckEnergyNeedsMatch = ({ setModalToast, resetToast, onSuccess }) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ estimationId, data }) =>
            checkEnergyNeedsMatchApi(estimationId, data),
        onSuccess: res => {
            onSuccess?.(res)
        },
        onError: error => {
            setModalToast({
                showToast: true,
                toastMessage:
                    errorHandler(error?.response?.data) ||
                    'An error occurred, please try again later.',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    return {
        checkEnergyNeedsMatchMutate: mutate,
        checkEnergyNeedsMatchLoading: isLoading,
    }
}

export default useCheckEnergyNeedsMatch
