import BackNav from 'src/components/BackNav'
import s from './styles.module.scss'
import ButtonComponent from 'src/components/Button'
import { ReactComponent as StackImg } from 'src/assets/images/stack-2-no-stroke.svg'
import PackageCard from './components/PackageCard'
import AddPackageDrawer from '../../Profiles/AddPackage/components/AddPackageDrawer'
import { useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ActionModal from 'src/components/ActionModal'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { getSinglePackageApi } from 'src/api/products/packages'
import SolutionModalContent from '../Details/components/solutionModalContent/SolutionModalContent'
import { actionModalValues, formatPackageData } from './utils'
import {
    changeConsumerPackage,
    notifyConsumerOfPackageChange,
} from 'src/api/post-approval/api'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import ManualDispute from '../Details/components/creditReviewTab/ManualDispute'
import { getCreditDecisionResult } from 'src/api/credit/api'
import { Skeleton } from 'antd'

const PostApprovalRecommendations = () => {
    const [selectedPackage, setSelectedPackage] = useState([])
    const [showAddPackageDrawer, setShowAddPackageDrawer] = useState(false)
    const [showPackageInfoModal, setShowPackageInfoModal] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [showDisputeCreditDecisionModal, setShowDisputeCreditDecisionModal] =
        useState(false)
    const [selectedPackagePaymentPlans, setSelectedPackagePaymentPlans] =
        useState([])
    const [categoryId, setCategoryId] = useState(0)

    const [action, setAction] = useState('')
    const history = useHistory()

    const packageId = history?.location?.state?.packageId
    const paymentType = history?.location?.state?.paymentType
    const estimationId = history?.location?.state?.estimationId
    const consumerType = history?.location?.state?.consumerType
    const profileId = history?.location?.state?.profileId
    const onboardingType = history?.location?.state?.onboardingType
    const consumerStatus = history?.location?.state?.consumer_status

    const {
        cancelText,
        actionModalActionType,
        actionModalSubtitle,
        actionModalHeaderText,
        actionModalPrimaryBtn,
    } = actionModalValues(action)

    const { errorAlert } = useCustomToast()

    const toggleAddPackageDrawer = () => {
        setShowAddPackageDrawer(prev => !prev)
        showAddPackageDrawer && setSelectedPackage([])
    }

    const goBackToPlan = () => {
        history.replace(
            `/admin/plans/${estimationId}`,
            history?.location?.state,
        )
    }

    const { isLoading } = useQuery(
        ['fetch-package', packageId],
        () => getSinglePackageApi(packageId),
        {
            enabled: !!packageId,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setSelectedPackage(data)

                if (consumerStatus !== 'REQUEST_PACKAGE_CHANGE') goBackToPlan
            },
            onError: err => {
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    const handleUpdatePackages = ([data]) => {
        history.replace({
            state: {
                packageId: data?.id,
                paymentType: data?.payment_plan_types[0],
            },
        })
        setSelectedPackage(data)
        setShowAddPackageDrawer(false)
    }

    const handlePackageSelection = useMutation({
        mutationFn: () => {
            const consumer_type = consumerType === 'DTC' ? 'dtc' : 'dep'
            const payload = {
                [consumer_type]: {
                    id: selectedPackage?.id,
                    payment_plan_types: [paymentType],
                    energy_profile_id: profileId,
                    ...(consumer_type === 'dtc' && {
                        flow: onboardingType,
                    }),
                    ...(consumer_type === 'dtc'
                        ? {
                              appliances: [],
                          }
                        : {
                              components: [],
                          }),
                },
            }

            return changeConsumerPackage(estimationId, payload)
        },
        onSuccess: () => {
            setAction('package-change-successful')
        },
        onError: () => {
            setAction('package-change-unsuccessful')
        },
    })

    const notifyConsumerOfChange = useMutation({
        mutationFn: () => {
            return notifyConsumerOfPackageChange(estimationId)
        },
        onSuccess: () => {
            setAction('notified-consumer-of-package-change')
        },
        onError: err => {
            errorAlert(errorHandler(err?.response?.data))
        },
    })

    const actionHandler = () => {
        switch (action) {
            case 'confirm-package-change':
                handlePackageSelection.mutate()
                break
            case 'package-change-successful':
                notifyConsumerOfChange.mutate()
                break
            case 'notified-consumer-of-package-change':
                goBackToPlan()
                break
            case 'package-change-unsuccessful':
                setShowDisputeCreditDecisionModal(true)
                setShowActionModal(false)
                break
        }
    }

    const handleModalClose = () => {
        if (
            action === 'notified-consumer-of-package-change' ||
            action === 'package-change-successful'
        ) {
            goBackToPlan()
        }
        setAction('')
        setShowActionModal(false)
    }

    useQuery(
        'fetch-payment-plans',
        () => getCreditDecisionResult(estimationId),
        {
            enabled: !!estimationId,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setSelectedPackagePaymentPlans(data?.payment_plans)
            },
            onError: err => {
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return (
        <>
            <div className={s.Page}>
                <BackNav
                    title="Back to KYC page"
                    v2
                    onClick={() => history.goBack()}
                />

                <div className={s.mainTitle}>
                    <h2>Send a recommendation</h2>
                    <ButtonComponent
                        btnBgColor="#004aad"
                        btnTextColor="white"
                        handleClick={() => {
                            setAction('confirm-package-change')
                            setShowActionModal(true)
                        }}
                        disabled={isLoading}
                    >
                        Send to consumer
                    </ButtonComponent>
                </div>

                {isLoading ? (
                    <Skeleton className={s.Card} />
                ) : (
                    !isLoading &&
                    selectedPackage && (
                        <>
                            <div className={s.subtitle}>
                                <p>
                                    <span>{paymentType}</span> Recommendations
                                </p>
                                <button onClick={toggleAddPackageDrawer}>
                                    <StackImg />
                                    Choose another package
                                </button>
                            </div>

                            <div className={s.packageCard}>
                                <PackageCard
                                    packageData={selectedPackage}
                                    handleViewPackageInfo={() =>
                                        setShowPackageInfoModal(true)
                                    }
                                    paymentPlans={selectedPackagePaymentPlans}
                                />
                            </div>
                        </>
                    )
                )}
            </div>

            <AddPackageDrawer
                selectedPackages={[selectedPackage]}
                showAddPackageModal={showAddPackageDrawer}
                setShowAddPackageModal={toggleAddPackageDrawer}
                handleAddPackagesClick={handleUpdatePackages}
                isOnePackage
                isPostApprovalPackageChange
            />

            <SecondaryModal
                showModal={showPackageInfoModal}
                modalHeight="600px"
                modalWidth="550px"
                modalClass="adminModal"
                onCancel={() => setShowPackageInfoModal(false)}
                content={
                    <SolutionModalContent
                        closeModal={() => setShowPackageInfoModal(false)}
                        modalData={formatPackageData(
                            selectedPackage,
                            selectedPackagePaymentPlans,
                        )}
                    />
                }
            />

            <ActionModal
                actionModalOpen={showActionModal}
                closeModal={handleModalClose}
                onCancel={handleModalClose}
                closable
                actionText={actionModalPrimaryBtn}
                actionType={actionModalActionType}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                cancelText={cancelText}
                actionHandler={actionHandler}
                noCancelBtn={cancelText === '' ?? !cancelText}
                loading={
                    handlePackageSelection.isLoading ||
                    notifyConsumerOfChange.isLoading
                }
            />

            <SecondaryModal
                showModal={showDisputeCreditDecisionModal}
                modalWidth="624px"
                modalHeight="755px"
                onCancel={() => setShowDisputeCreditDecisionModal(false)}
                closable
                content={
                    <ManualDispute
                        closeModal={() => {
                            setShowDisputeCreditDecisionModal(false)
                        }}
                        paymentPlans={selectedPackagePaymentPlans}
                        estimationId={estimationId}
                        categoryId={categoryId}
                        setCategoryId={setCategoryId}
                        isApproved
                    />
                }
            />
        </>
    )
}

export default PostApprovalRecommendations
