import { permissionsControl } from 'src/utils/permissionsControl'

export const getPermissions = () => {
    const canUpdateEnergyProfile = permissionsControl([
        'can_update_energy_profile',
    ])
    const canDeleteEnergyProfile = permissionsControl([
        'can_delete_energy_profile',
    ])
    const canDuplicateEnergyProfile = permissionsControl([
        'can_duplicate_energy_profile',
    ])
    const canModifyEnergyProfilePackage = permissionsControl([
        'can_modify_energy_profile_package',
    ])

    return {
        canUpdateEnergyProfile,
        canDeleteEnergyProfile,
        canDuplicateEnergyProfile,
        canModifyEnergyProfilePackage,
    }
}
