import axios from '../../../config/axios'
import { useQuery } from 'react-query'
import { useMutation } from 'react-query'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'

export const getSingleAdminPaymentPlanApi = async id => {
    const request = await axios.get(`/admin/estimations/${id}`)
    return request
}

export const getCreditDecision = async estimationId => {
    const request = await axios.get(
        `/credit/decision/estimations/${estimationId}/result`,
    )
    return request
}

export const getConsumerCheckReport = async userId => {
    const request = await axios.get(
        `/admin/users/${userId}/consumer-check-report`,
    )
    return request
}

export const getSingleAdminPaymentPlanSSEApi = async id => {
    const request = await axios.get(`/admin/sse-consumers/estimations/${id}`)
    return request
}

export const getSingleAdminDropOffSSEApi = async id => {
    const request = await axios.get(`/admin/sse-consumers/drop-offs/${id}`)
    return request
}

export const getRecommendedSolutions = async (estimationId, page) => {
    const request = await axios.get(
        `/admin/sse-consumers/recommended-solutions/estimations/${estimationId}?page=${page}`,
    )
    return request
}

export const getPackages = async (
    name = '',
    pageNumber = 1,
    subscriptionSupported = false,
    leaseToOwnSupported = false,
) => {
    const res = await axios.get(
        `/admin/packages/recommendable-packages?name=${name}&page=${pageNumber}&subscription_supported=${subscriptionSupported}&lease_to_own_supported=${leaseToOwnSupported}`,
    )
    return res.data
}

export const useGetRecommendablePackages = ({
    name,
    pageNumber,
    subscriptionSupported,
    leaseToOwnSupported,
    onSuccess,
}) => {
    const { errorAlert } = useCustomToast()

    return useQuery({
        queryKey: [
            'recommendable-packages',
            name,
            pageNumber,
            subscriptionSupported,
            leaseToOwnSupported,
        ],
        queryFn: () =>
            getPackages(
                name,
                pageNumber,
                subscriptionSupported,
                leaseToOwnSupported,
            ),
        enabled: false,
        retry: false,
        onSuccess: onSuccess,
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
}

export const recommendSolutionsForDropoffConsumer = async (dropOffId, body) => {
    const res = await axios.post(
        `/admin/sse-consumers/drop-offs/${dropOffId}/recommend-solutions`,
        { packages: body },
    )
    return res.data
}

export const useRecommendSolutionsForDropoffConsumer = (options = {}) => {
    const { errorAlert } = useCustomToast()
    return useMutation({
        mutationFn: ({ dropOffId, body }) =>
            recommendSolutionsForDropoffConsumer(dropOffId, body),
        mutationKey: ['recommendaton'],
        ...options,
        retry: false,
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
}

export const getAlternativeRecommendationsApi = dropoffId => {
    const request = axios.get(
        `/admin/sse-consumers/drop-offs/${dropoffId}/recommend-solutions`,
    )
    return request
}

export const markAPaymentApi = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('repayment_month', file.repayment_month)
    formData.append('date_repayment_was_made', file.date_repayment_was_made)
    formData.append('repayment_method', file.repayment_method)
    file.repayment_evidence !== null &&
        formData.append('repayment_evidence', file.repayment_evidence)
    formData.append('total_amount_paid', file.total_amount_paid)

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        {
            headers,
        },
    )
    return request
}

export const regenerateAndAssignPaymentScheduleApi = async (
    estimationId,
    file,
) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('new_financing_start_date', file.new_financing_start_date)
    formData.append('first_repayment_date', file.first_repayment_date)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const uploadPaymentPlanUpfrontDepositApi = async (
    estimationId,
    file,
) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('upfront_deposit_paid_at', file.upfront_deposit_paid_at)
    formData.append(
        'upfront_deposit_evidence_file_url',
        file.upfront_deposit_evidence_file_url,
    )
    formData.append('total_amount_paid', file.total_amount_paid)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const uploadPaymentPlanBuyoutApi = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('buyout_paid_at', file.buyout_paid_at)
    formData.append('buyout_evidence_file_url', file.buyout_evidence_file_url)
    formData.append('total_amount_paid', file.total_amount_paid)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const uploadProviderPaymentEvidenceApi = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append(
        'provider_received_payment_at',
        file.provider_received_payment_at,
    )
    formData.append(
        'evidence_of_provider_payment_file_url',
        file.evidence_of_provider_payment_file_url,
    )
    formData.append('total_amount_paid', file.total_amount_paid)

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const uploadSignedSunFiAgreementApi = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('agreement_signed_at', file.agreement_signed_at)
    formData.append('signed_agreement_file_url', file.signed_agreement_file_url)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const uploadSolutionInstalledEvidenceApi = async (
    estimationId,
    file,
) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('solution_installed_at', file.solution_installed_at)
    formData.append(
        'evidence_of_solution_installed_file',
        file.evidence_of_solution_installed_file,
    )
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const repaymentMethodSetupApi = async (estimationId, file) => {
    const mapping = {
        'Bank - Standing Order': 'BANK_TRANSFER:STANDING_ORDER',
        'Paystack - Plan/Subscription': 'PAYSTACK:PLAN_SUBSCRIPTION',
        'Bank Transfer': 'BANK_TRANSFER:ADMIN_MANUAL_ENTRY',
    }
    let formData = new FormData()
    file['repayment_method_setup_type'] =
        mapping[file.repayment_method_setup_type]
    formData.append('action', file.action)
    formData.append(
        'repayment_method_setup_created_at',
        file.repayment_method_setup_created_at,
    )
    formData.append(
        'repayment_method_setup_type',
        file.repayment_method_setup_type,
    )
    if (file.repayment_method_setup_type === 'PAYSTACK:PLAN_SUBSCRIPTION') {
        formData.append(
            'repayment_method_setup_subscription_code',
            file.repayment_method_setup_subscription_code,
        )
    }
    if (file.repayment_method_setup_type === 'BANK_TRANSFER:STANDING_ORDER') {
        formData.append(
            'repayment_method_setup_file_url',
            file.repayment_method_setup_file_url,
        )
    }

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const updatePaymentPlanTenure = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('action_payment_plan', file.action_payment_plan)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/approved/${estimationId}/change`,
        formData,
        { headers },
    )
    return request
}

export const closePaymentPlan = async (estimationId, file) => {
    let formData = new FormData()
    formData.append('action', file.action)
    formData.append('reason', file.reason)

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/estimations/mini/${estimationId}/close`,
        formData,
        { headers },
    )
    return request
}

export const requestCancelRepaymentSetupApi = async (id, reason) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/cancel-repayment-method`,
        reason,
    )
    return request
}

export const requestRepaymentSetupApi = async (id, notes) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/request-repayment-setup`,
        notes !== '' && { notes: notes },
    )
    return request
}

export const requestUpfrontDepositApi = async (id, inputs) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/request-upfront-deposit`,
        inputs,
    )
    return request
}

export const declinePaymentPlanApi = async (id, input) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/decline`,
        input,
    )
    return request
}

export const declineReEstimatePaymentPlanApi = async (id, input) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/decline-re-estimate`,
        input,
    )
    return request
}

export const resendPiiRequestApi = async (id, input) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/resend-consumer-pii`,
        input,
    )
    return request
}

export const approvePaymentPlanApi = async (id, input) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/approve-payment-plan`,
        input,
    )
    return request
}

export const getRepaymentScheduleApi = async id => {
    const request = await axios.get(
        `/admin/estimations/mini/${id}/repayment-schedule?paginate=false`,
    )
    return request
}

export const updateRepaymentSetup = async ({ id, body }) => {
    const formData = new FormData()
    Object.entries(body).forEach(([key, value]) => {
        if (value) formData.append(key, value)
    })
    const res = await axios.post(
        `/admin/estimations/mini/${id}/edit-repayment-setup`,
        formData,
        {
            headers: {
                'Content-type': 'multipart/form-data',
            },
        },
    )
    return res.data
}

export const requestRepaymentUpdateFromConsumer = async ({ id, notes }) => {
    const res = await axios.post(
        `/admin/estimations/mini/${id}/request-repayment-setup`,
        { notes },
    )
    return res.data
}

export const useUpdateRepaymentSetup = () => {
    return useMutation(({ id, body }) => updateRepaymentSetup({ id, body }))
}

export const useRequestRepaymentUpdateFromConsumer = () => {
    return useMutation(({ id, notes }) =>
        requestRepaymentUpdateFromConsumer({ id, notes }),
    )
}

export const requestBankStatementApi = async (id, inputs) => {
    const request = await axios.post(
        `/admin/estimations/mini/${id}/resubmit-bank-statement`,
        inputs,
    )
    return request
}

export const updateFirstRepaymentDateApi = async ({ id, body }) => {
    const result = await axios.post(
        `/admin/estimations/mini/${id}/first-repayment-date/change`,
        body,
    )
    return result.data
}

export const useUpdateFirstRepaymentDateApi = () => {
    return useMutation(({ id, body }) =>
        updateFirstRepaymentDateApi({ id, body }),
    )
}

export const updateTenureApi = async (estimationId, data) => {
    const result = await axios.post(
        `/admin/post-approval/consumers/estimations/${estimationId}/tenure/change`,
        data,
    )
    return result
}
