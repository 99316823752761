//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import './estimations-stepthree.scss'
import LeftArrow from '../../assets/images/left-blue-arrow.svg'
import Tick from '../../assets/images/tick.svg'
import {
    getRepaymentPlansApi,
    createRepaymentPlansApi,
    getEstimationByIdApi,
    paymentPlanFeedbackApi,
} from '../../api/estimations/estimations-step-three'
import Toast from '../../components/Toast'
import EmptyState from '../../components/EmptyState'
import { errorHandler } from '../../utils/errorHandler'
import ActionModal from '../../components/ActionModal'
import { InlineLoader } from '../../components/Loader'
import { convertToSentenceCase } from '../../utils/formatting'
import { useLocation } from 'react-router-dom'
import { Skeleton } from 'antd'

import SeoComponent from '../../components/Seo'
import { eventTrackers } from '../../utils/eventTrackers.js'
import { appTracking } from '../../utils/appTracker.js'
import NPSRatingModal from 'src/components/NPSRatingModal'
import {
    hasShownNPSModalInLast30Days,
    useUpdateNPSValues,
} from 'src/utils/handleNPSValues'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import ActionModalV2 from 'src/components/ActionModalV2'

const EstimationsStepThree = () => {
    const history = useHistory()
    const [paymentPlansData, setPaymentPlansData] = useState([])
    const [paymentPlans, setPaymentPlans] = useState([])
    const [estimation, setEstimation] = useState([])
    const [paymentPlansId, setPaymentPlansId] = useState([])
    let selectedPaymentPlans = [paymentPlans.join(', ')]
    const estimationsId = localStorage.getItem('estimationsId')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')
    const [successModal, setSucessModal] = useState(false)
    const [repaymentPlansLoading, setRepaymentPlansLoading] = useState(true)
    const [optionModal, setOptionModal] = useState(false)
    const [sendTo, setSendTo] = useState('')
    const [showNPSRatingModal, setShowNPSRatingModal] = useState(false)
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [showActionModal, setShowActionModal] = useState(false)

    const location = useLocation()
    const { npsState, updateNPSAndResetValues } = useUpdateNPSValues(
        'paymentPlanCreationNPSDisplayed',
    )
    const canShowNpsModal = !hasShownNPSModalInLast30Days(
        npsState?.paymentPlanCreationNPSDisplayed,
    )
    const { successAlert } = useCustomToast()

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'

    const showPartnerKycAssist =
        process.env.REACT_APP_FLAG_PARTNER_KYC_ASSIST === 'true'

    //stepper route to step one
    const pushRouteOne = () => {
        let currentUrl = location.pathname
        switch (currentUrl) {
            case '/app/payment-plans/create/three':
                history.push('/app/payment-plans/create/one', {
                    params: estimationsId,
                })
                break
            default:
                history.push(`/app/payment-plans/edit/one/${estimationsId}`)
        }
    }

    //steper route to step two
    const pushRouteTwo = () => {
        let currentUrl = location.pathname
        switch (currentUrl) {
            case '/app/payment-plans/create/three':
                history.push('/app/payment-plans/create/two', {
                    isEstimationTwo: true,
                    clusterCode: estimation?.cluster_code,
                })
                break
            default:
                history.push('/app/payment-plans/edit/two', {
                    isEstimationTwo: true,
                })
        }
    }

    function getSelectedCheckboxValues() {
        const checkboxes = document.querySelectorAll(
            `input[name="paymentPlans"]:checked`,
        )
        let values = []
        checkboxes.forEach(checkbox => {
            values.push(checkbox.value)
        })
        setPaymentPlans(values)
        return values
    }

    function getSelectedCheckboxIds() {
        const checkboxes = document.querySelectorAll(
            `input[name="paymentPlans"]:checked`,
        )
        let ids = []
        checkboxes.forEach(checkbox => {
            ids.push(checkbox.id)
        })
        setPaymentPlansId(ids)
        return ids
    }
    //fetch repayment-plans
    const { refetch: fetchRepaymentPlans } = useQuery(
        ['fetchRepaymentPlans', estimationsId],
        () => getRepaymentPlansApi(estimationsId),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setPaymentPlansData(data?.data?.data)
                setRepaymentPlansLoading(false)
            },
            onError: () => {
                setRepaymentPlansLoading(false)
                setToastError(true)
                setErrorType('repaymentPlans')
                setErrorMessage(
                    'Failed to fetch All Payment Plans. Refresh page',
                )
            },
        },
    )

    //fetch estimation
    const { refetch: fetchEstimation } = useQuery(
        ['fetchEstimation', estimationsId],
        () => getEstimationByIdApi(estimationsId),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setEstimation(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                // setErrorType('estimation');
                setErrorMessage('Failed to fetch Payment Plan. Refresh page')
            },
        },
    )

    //POST repayment plans
    const {
        isLoading: createPaymentPlansLoading,
        refetch: createPaymentPlansRefetch,
    } = useQuery(
        ['createPaymentPlans'],
        () =>
            createRepaymentPlansApi(estimationsId, {
                repayment_plan_ids: paymentPlansId,
                send_to: sendTo,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setOptionModal(false)
                if (canShowNpsModal) {
                    setShowNPSRatingModal(true)
                    updateNPSAndResetValues()
                } else {
                    setSucessModal(true)
                    localStorage.removeItem('estimationsId')
                    localStorage.removeItem('reEstimationStatus')
                }

                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    sendTo === 'admin'
                        ? 'PAYMENT_PLAN_SUCCESSFULLY_SENT_TO_ADMIN'
                        : 'PAYMENT_PLAN_SUCCESSFULLY_SENT_TO_CONSUMER',
                    eventTrackers['createPaymentPlan'].label,
                    eventTrackers['createPaymentPlan'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {
                            payment_plan_step_three_info: {
                                repayment_plan_ids: paymentPlansId,
                                send_to: sendTo,
                            },
                        },
                    },
                )
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else {
                    setErrorMessage([
                        'Failed to create payment plan. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const submitPlanCreationRatingMutation = useMutation({
        mutationFn: () =>
            paymentPlanFeedbackApi(estimationsId, additionalPayload),
        onSuccess: () => {
            proceedToSuccessMsg()
        },
        onError: () => {
            proceedToSuccessMsg()
        },
    })

    const handleSavePaymentPlans = value => {
        setSendTo(value)
        setToastError(false)
    }
    const handleModalAction = () => {
        setSucessModal(false)

        if (showPartnerKycAssist) {
            setShowActionModal(true)
        } else {
            history.push('/app/payment-plans')
        }
    }

    const handleActionModalPrimaryClick = () => {
        if (estimation) {
            if (estimation.customer_type === 'RESIDENTIAL') {
                history.push({
                    pathname: `/app/plans/${estimation.id}/kyc/v2/residential`,
                    state: {
                        estimationId: estimation.id,
                    },
                })
            } else {
                history.push({
                    pathname: `/app/plans/${estimation.id}/kyc/v2/business`,
                    state: {
                        estimationId: estimation.id,
                    },
                })
            }
        }
    }

    const handleActionModalSecondaryClick = () => {
        setShowActionModal(false)
        history.push('/app/payment-plans')
    }

    const proceedToSuccessMsg = () => {
        setShowNPSRatingModal(false)
        setSucessModal(true)
        localStorage.removeItem('estimationsId')
        localStorage.removeItem('reEstimationStatus')
    }

    useEffect(() => {
        sendTo.length > 1 && createPaymentPlansRefetch()
    }, [sendTo])

    useEffect(() => {
        fetchRepaymentPlans()
        fetchEstimation()
    }, [fetchRepaymentPlans, fetchEstimation])

    useEffect(() => {
        location.state &&
            location.state?.isEstimationThree &&
            localStorage.setItem('isEstimationThree', 'true')
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (location?.state?.overrodeEnergyMatch) {
            successAlert('System energy limit overridden', 274)
            const { overrodeEnergyMatch, ...rest } = location.state
            history.replace({ ...location, state: rest })
        }
    }, [location, history])

    const subTitleMessage = `If you’ve not entered your account details, kindly enter your details on your profile as your payment will be made into the account if approved`

    return (
        <>
            <SeoComponent
                title="SunFi - Create Payment Plan | Step Three"
                tracker="CreatePaymentplanStepThree"
            />
            <ActionModal
                actionModalOpen={optionModal}
                headerText="Send to?"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionType="success"
                actionText={
                    createPaymentPlansLoading && sendTo === 'admin' ? (
                        <InlineLoader />
                    ) : (
                        'Submit for Approval'
                    )
                }
                loading={''}
                cancelText={
                    createPaymentPlansLoading && sendTo === 'consumer' ? (
                        <InlineLoader />
                    ) : (
                        'Consumer Only'
                    )
                }
                actionHandler={() => handleSavePaymentPlans('admin')}
                closeModal={() => handleSavePaymentPlans('consumer')}
            />
            <ActionModal
                actionModalOpen={successModal}
                headerText="Payment Plan Successfully Created"
                subTitle={subTitleMessage}
                closeModal={handleModalAction}
                actionHandler={handleModalAction}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
            />

            <NPSRatingModal
                showModal={showNPSRatingModal}
                closeModal={() => proceedToSuccessMsg()}
                feature={'created-payment-plan'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={() => submitPlanCreationRatingMutation.mutate()}
                btnLoading={submitPlanCreationRatingMutation.isLoading}
            />
            <ActionModalV2
                title={'Assist customer with KYC'}
                actionModalOpen={showActionModal}
                actionType={'solar-mills'}
                headerText={'Assist with KYC'}
                subTitle={`You can assist the consumer by filling out part of their KYC to make the process faster and more efficient.`}
                // primaryBtnLoading={isAdminSavingOnePageKYC}
                primaryBtnText={'Start KYC'}
                handlePrimaryBtnClick={handleActionModalPrimaryClick}
                v2
                onCancel={handleActionModalSecondaryClick}
                footerLeftContent={'button'}
                secondaryBtnText={'Not now'}
                handleSecondaryBtnClick={handleActionModalSecondaryClick}
                kycSecondaryButtonStopLoading
            />

            <div className="Estimation_stepthree--container">
                <button
                    className="PrevButton"
                    onClick={() => history.push('/app/payment-plans')}
                >
                    <img src={LeftArrow} alt="left-arrow" /> Back to Payment
                    Plans
                </button>
                <div style={{ display: 'flex' }}>
                    <h1 className="EstimationStepOneH1">
                        Create a Single Payment Plan
                    </h1>
                </div>
                <div className="EstimationStepOneContainer">
                    <div className="StepsContainer">
                        <div className="StepsChildContainer StepsChildContainerDiv">
                            <button
                                className="StepsButton"
                                style={{
                                    background: '#61c9a8',
                                    border: 'none',
                                }}
                                onClick={pushRouteOne}
                            >
                                <img
                                    src={Tick}
                                    alt="tick"
                                    style={{ padding: '4px' }}
                                />
                            </button>
                            <p className="StepsText">Payment Plan Info</p>
                        </div>
                        <div
                            className="StepsLine"
                            style={{ borderColor: '#61c9a8' }}
                        ></div>
                        <div className="StepsChildContainer">
                            <button
                                className="StepsButton"
                                style={{
                                    background: '#61c9a8',
                                    border: 'none',
                                }}
                                onClick={pushRouteTwo}
                            >
                                <img
                                    src={Tick}
                                    alt="tick"
                                    style={{ padding: '4px' }}
                                />
                            </button>
                            <p className="StepsText">Energy Solution</p>
                        </div>
                        <div
                            className="StepsLine"
                            style={{ borderColor: '#61c9a8' }}
                        ></div>
                        <div className="StepsChildContainer">
                            <button className="StepsButton StepsButtonStyle">
                                3
                            </button>
                            <p className="StepsText">Payment Plans</p>
                        </div>
                    </div>
                    <div className="SelectedPaymentPlansLayout">
                        <div style={{ width: '100%', display: 'block' }}>
                            <div className="PaymentPlansWrapper">
                                <div>
                                    <p className="EstimationStepOneSubTitle">
                                        Payment Plans
                                    </p>
                                    <h2 className="EstimationStepOneH2">
                                        Available Payment Plans for{' '}
                                        {`${convertToSentenceCase(
                                            estimation?.consumer?.user
                                                ?.first_name,
                                        )} ${convertToSentenceCase(
                                            estimation?.consumer?.user
                                                ?.last_name,
                                        )}`}
                                    </h2>
                                </div>
                                <div>
                                    <button
                                        className="ChangeEnergySolutionBtn"
                                        onClick={pushRouteTwo}
                                    >
                                        Change Energy Solution
                                    </button>
                                </div>
                            </div>
                            <div style={{ marginTop: '24px' }}></div>
                            {toastError &&
                                errorType === 'All Payment Plans' && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                            {paymentPlansData.length > 0 ? (
                                <div className="PaymentPlansSelectContainer">
                                    {paymentPlansData.map((payment, index) => (
                                        <div
                                            key={index}
                                            className="PaymentPlansSelectChildContainer"
                                            onClick={() => {
                                                getSelectedCheckboxValues()
                                                getSelectedCheckboxIds()
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                name="paymentPlans"
                                                id={payment.id}
                                                value={`Plan ${index + 1}`}
                                                style={{ display: 'none' }}
                                            />
                                            <label
                                                htmlFor={payment.id}
                                                className="PaymentPlansSelectLabel"
                                            >
                                                <div className="PlanStyle">
                                                    <div>
                                                        <p>{`Plan ${
                                                            index + 1
                                                        }`}</p>
                                                        <p
                                                            style={{
                                                                marginTop:
                                                                    '12px',
                                                            }}
                                                        >
                                                            <span className="PlanAmount">
                                                                {
                                                                    payment
                                                                        .currency
                                                                        .symbol
                                                                }{' '}
                                                                {
                                                                    payment.monthly_payment
                                                                }
                                                            </span>
                                                            <span className="PlanMonth">
                                                                / Month
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <span
                                                        className="PaymentPlansSelectButton"
                                                        style={{
                                                            opacity:
                                                                paymentPlans.find(
                                                                    plan =>
                                                                        plan ===
                                                                        `Plan ${
                                                                            index +
                                                                            1
                                                                        }`,
                                                                )
                                                                    ? '0.7'
                                                                    : '',
                                                        }}
                                                    >
                                                        {paymentPlans.find(
                                                            plan =>
                                                                plan ===
                                                                `Plan ${
                                                                    index + 1
                                                                }`,
                                                        )
                                                            ? 'Selected'
                                                            : 'Select Plan'}
                                                    </span>
                                                </div>
                                                <div className="RepaymentWrapper">
                                                    <div>
                                                        <p className="PaymentPlansSelectChildRowTwoHeader">
                                                            MONTHS OF REPAYMENT
                                                        </p>
                                                        <p
                                                            className="PaymentPlansSelectChildRowTwoContent"
                                                            style={{
                                                                marginTop:
                                                                    '6px',
                                                            }}
                                                        >
                                                            {payment.tenure}{' '}
                                                            Months
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="PaymentPlansSelectChildRowTwoHeader">
                                                            INITIAL DEPOSIT
                                                        </p>
                                                        <p
                                                            className="PaymentPlansSelectChildRowTwoContent"
                                                            style={{
                                                                marginTop:
                                                                    '6px',
                                                            }}
                                                        >
                                                            {
                                                                payment.currency
                                                                    .symbol
                                                            }{' '}
                                                            {
                                                                payment.initial_deposit
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            ) : repaymentPlansLoading ? (
                                <div className="PaymentPlansSelectContainer">
                                    {[...Array(4)].map(i => (
                                        <div
                                            key={i}
                                            className="PaymentPlansSelectChildContainer"
                                        >
                                            <div className="SkeletonTop">
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                    }}
                                                >
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '38px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '122px',
                                                            height: '37px',
                                                        }}
                                                    />
                                                </div>
                                                <Skeleton.Button
                                                    active
                                                    style={{
                                                        width: '72px',
                                                        height: '37px',
                                                    }}
                                                />
                                            </div>
                                            <div className="SkeletonBottom">
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                    }}
                                                >
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '100px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '72px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                    }}
                                                >
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '100px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                    <Skeleton.Button
                                                        active
                                                        style={{
                                                            width: '72px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <EmptyState
                                    subTitle={
                                        'You do not have any payment plans.'
                                    }
                                />
                            )}
                            <div style={{ marginBottom: '86px' }}></div>
                            <div className="pages">
                                {/* <Pagination
                  defaultCurrent={1}
                  defaultPageSize={6}
                  total='130'
                  showSizeChanger={false}
                  showTitle={false}
                  showLessItems
                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {paymentPlans.length > 0 && (
                <div className="SelectedPaymentWrapper">
                    <div className="SelectedPaymentPlans">
                        <div style={{ display: 'flex', paddingRight: '8px' }}>
                            <p style={{ fontWeight: 500 }}>
                                You selected {paymentPlans.length} payment
                                plan(s)
                            </p>
                            <p style={{ fontWeight: 300 }}>
                                &nbsp;
                                {selectedPaymentPlans.map((plans, index) => (
                                    <span key={index}>({plans})</span>
                                ))}
                            </p>
                        </div>
                        <button
                            className="PaymentPlansBtnPrimary"
                            onClick={() => setOptionModal(true)}
                        >
                            Send
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default EstimationsStepThree
