/* eslint-disable react/prop-types */

import { useState } from 'react'
import ReactQuill from 'react-quill'
import { modules, formats, bulletModules } from './toolBar'
import 'react-quill/dist/quill.snow.css'
import './editor.scss'

const Editor = ({
    onChangeHandler,
    value = '',
    editorWidth,
    errorMessage = '',
    editorRef,
    editorHeight,
    marginBottom,
    TextPrefilled,
    placeholder = '',
    bulletOnly = false,
    disabled = false,
    hideErrorElementIfNoError,
    showCharacterLimit,
    charactersLeft,
    quillFullWidth,
}) => {
    const limitTagStyles = {
        background:
            charactersLeft < 0
                ? '#DA1E28'
                : 'linear-gradient(90deg, #F1F1F1 0%, #F3F3F3 51.7%, #F3F3F3 100%, #F1F1F1 100%)',
        color: charactersLeft < 0 ? '#FFFFFF' : '#233862',
        right: bulletOnly ? 40 : 76,
    }
    const [contentLength, setContentLength] = useState(1)

    return (
        <div>
            <div
                className={`RQEditorWrapper ${
                    quillFullWidth ? 'quillFullWidth' : ''
                }`}
                style={{ width: editorWidth }}
            >
                {contentLength > 1 && (
                    <span className="RQEditorFloatingLabel">{placeholder}</span>
                )}
                <ReactQuill
                    theme="snow"
                    value={value}
                    placeholder={placeholder}
                    onChange={(content, delta, source, editor) => {
                        onChangeHandler(content)
                        setContentLength(editor.getLength())
                    }}
                    style={{
                        width: editorWidth,
                        height: editorHeight,
                        marginBottom: marginBottom,
                        paddingTop: '5px',
                        border: `1px solid ${
                            errorMessage !== ''
                                ? 'red'
                                : TextPrefilled
                                ? 'var(--blue)'
                                : '#e5e5e5'
                        }`,
                    }}
                    modules={bulletOnly ? bulletModules : modules}
                    formats={formats}
                    ref={editorRef}
                    readOnly={disabled}
                />
                {showCharacterLimit && (
                    <div
                        className="RQEditorCharacterLimit"
                        style={limitTagStyles}
                    >
                        {charactersLeft}
                    </div>
                )}
            </div>
            {hideErrorElementIfNoError && !errorMessage ? (
                ''
            ) : (
                <div className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </div>
            )}
        </div>
    )
}

export default Editor
