import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter-blue.svg'
import { ReactComponent as CancelIcon } from 'src/assets/images/cancel-blue.svg'
import { ReactComponent as DownloadIcon } from 'src/assets/images/download.svg'
import s from './styles.module.scss'
import { useState } from 'react'
import { handleFilterDisplay } from './data'
import CustomToolTip from 'src/components/CustomToolTip'
import { isMobile } from 'src/utils/mediaQueries'

const SearchAndFilterPanel = ({
    handleFilterDrawerOpen,
    membersListFiltered,
    resetFilter,
    filterStatus,
    setFilterStatus,
    applyFilter,
    handleExport,
    page,
}) => {
    const [searchQuery, setSearchQuery] = useState('')

    const handleApplyFilter = () => {
        setFilterStatus(prev => ({
            ...prev,
            name: searchQuery,
        }))
        applyFilter()
    }

    return (
        <div className={s.wrapper}>
            <div
                className={`${s.search} ${
                    page === 'overview' ? s.searchOverview : ''
                }`}
            >
                <SearchIcon role="button" />
                <input
                    placeholder={
                        page === 'workspace'
                            ? 'Enter email, location or name to search'
                            : 'Enter cluster name'
                    }
                    onChange={e => setSearchQuery(e.target.value)}
                    onBlur={handleApplyFilter}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            handleApplyFilter()
                            e.target.blur()
                        }
                    }}
                />
            </div>
            <div className={s.filterAndDownload}>
                {membersListFiltered &&
                handleFilterDisplay(filterStatus).length > 0 ? (
                    <div
                        className={`${s.filtered} ${
                            page === 'overview' ? s.overviewFiltered : ''
                        }`}
                    >
                        <CancelIcon
                            role="button"
                            aria-label="reset filter"
                            onClick={resetFilter}
                        />
                        <span
                            className={s.filteredText}
                            role={'button'}
                            aria-label="open filter drawer"
                            onClick={handleFilterDrawerOpen}
                        >
                            Filtered by:{' '}
                            {handleFilterDisplay(filterStatus).join(', ')}
                        </span>
                    </div>
                ) : (
                    <div
                        className={`${s.filter} ${
                            page === 'overview' ? s.filterOverview : ''
                        }`}
                        role={'button'}
                        aria-label="open filter drawer"
                        onClick={handleFilterDrawerOpen}
                    >
                        <FilterIcon role="presentation" />
                        <p>
                            Filter{' '}
                            {page === 'workspace' ? <span>members</span> : 'by'}
                        </p>
                    </div>
                )}
                {page === 'workspace' && (
                    <CustomToolTip
                        text={'Export list as excel file.'}
                        toolTipFontSize="12px"
                        toolTipPadding="9px 12px 9px 8px"
                        toolTipPopupWidth={133}
                        arrowPositionCenter
                        toolTipMarginLeft={isMobile ? -68 : -50}
                    >
                        <span className={s.download} onClick={handleExport}>
                            <DownloadIcon />
                        </span>
                    </CustomToolTip>
                )}
            </div>
        </div>
    )
}

SearchAndFilterPanel.propTypes = {
    handleFilterDrawerOpen: PropTypes.func,
    membersListFiltered: PropTypes.bool,
    applyFilter: PropTypes.func,
    resetFilter: PropTypes.func,
    setFilterStatus: PropTypes.func,
    handleExport: PropTypes.func,
    filterStatus: PropTypes.object,
    page: PropTypes.string.isRequired,
}

export default SearchAndFilterPanel
