/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Widget } from 'react-typeform-embed'
import { Row, Col } from 'antd'
import styles from './getstarted.module.scss'
import ButtonComponent from '../Button'
import GetModal from '../Modal/index'
import { eventTrackers } from '../../utils/eventTrackers.js'
import { appTracking } from '../../utils/appTracker.js'

export const ImageSection = ({ imgUrl, page }) => {
    const { ImgWrapper, ImgDiv, MobileImgWrapper } = styles
    return (
        <Col
            sm={24}
            lg={12}
            style={{
                textAlign: 'center',
            }}
        >
            <div className={ImgDiv}>
                <img
                    src={imgUrl}
                    alt="solarcity"
                    className={`${ImgWrapper} ${
                        page === 'home'
                            ? MobileImgWrapper
                            : page === 'partners'
                            ? MobileImgWrapper
                            : null
                    }`}
                />
            </div>
        </Col>
    )
}

export const TextSection = ({
    title,
    subTitle,
    buttonText,
    page,
    textColor,
    headerTextColor,
    headerTextWidth,
    subTextWidth,
    btnBgColor,
    btnTextColor,
    buttonClick,
    dropdownMenu,
    btnBgColorOutline,
    btnTextColorOutline,
}) => {
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const pageTitle = title
    const pageUrlName = window.location.pathname
    const pageTracker = 'CTA_TRACKER'

    if (showModal) {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers[page].action,
            eventTrackers[page].label,
            eventTrackers[page].category,
        )
    }

    let typeFormUrl = ''
    switch (page) {
        case 'home':
            typeFormUrl = process.env.REACT_APP_GET_OFFER_FORM_URL
            break
        default:
            typeFormUrl = process.env.REACT_APP_GET_OFFER_FORM_URL
    }

    const redirectTo = pageUrl => {
        window.location.href = pageUrl
    }

    const getTypeForm = <Widget id={`${typeFormUrl}`} height={700} />

    const handleModal = () => {
        page === 'home'
            ? history.push('/consumer/get-started')
            : setShowModal(true)
    }

    const handleCancel = () => {
        setShowModal(false)
    }

    const partnersSignUp = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['partnerSignup'].action,
            eventTrackers['partnerSignup'].label,
            eventTrackers['partnerSignup'].category,
        )
        redirectTo('/signup')
    }
    const partnersLogin = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['partnerLogin'].action,
            eventTrackers['partnerLogin'].label,
            eventTrackers['partnerLogin'].category,
        )
        redirectTo('/login')
    }
    const {
        FirstParagraph,
        ThirdParagraph,
        GetStartedBtns,
        GetStartedWrapper,
        HomePage,
        GetStartedSpan,
        SecondParagraphHome,
        PartnerCtaBtns,
        ConsumerSignInBtn,
    } = styles

    return (
        <>
            <Col
                sm={24}
                lg={12}
                md={24}
                className={`${GetStartedWrapper} 
                    ${page === 'home' ? HomePage : ''}
                `}
            >
                <div className={FirstParagraph}>
                    <span
                        className={GetStartedSpan}
                        style={{ color: `${textColor}` }}
                    >
                        {page === 'partners'
                            ? ' YOUR CLEAN ENERGY BUSINESS'
                            : 'SIMPLIFYING CLEAN ENERGY'}
                    </span>
                </div>
                <h1
                    className={SecondParagraphHome}
                    style={{
                        color: `${headerTextColor}`,
                        width: `${headerTextWidth}`,
                    }}
                >
                    {title}
                </h1>
                <p
                    className={ThirdParagraph}
                    style={{ color: `${textColor}`, width: `${subTextWidth}` }}
                >
                    {subTitle}
                </p>
                <div className={GetStartedBtns}>
                    <span style={{ marginRight: '5px' }}>
                        {buttonText === 'Get Your Offer' ? (
                            <>
                                <span style={{ marginRight: '8px' }}>
                                    <ButtonComponent
                                        btnBgColor="#fff"
                                        btnTextColor="#004AAD"
                                        handleClick={handleModal}
                                        //type="default"
                                        marginBottom="10px"
                                        btnWidth="150px"
                                        btnHeight="54px"
                                        fontSize="14px"
                                    >
                                        {buttonText}
                                    </ButtonComponent>
                                </span>

                                <div className={ConsumerSignInBtn}>
                                    <ButtonComponent
                                        btnBgColorOutline="#004AAD"
                                        btnTextColorOutline="#fff"
                                        btnTextColor="#fff"
                                        btnWidth="150px"
                                        btnHeight="54px"
                                        handleClick={() =>
                                            history.push('/consumer/welcome')
                                        }
                                        type="outline"
                                        fontSize="14px"
                                    >
                                        Sign In
                                    </ButtonComponent>
                                </div>
                            </>
                        ) : (
                            <>
                                {dropdownMenu ? (
                                    <div className={PartnerCtaBtns}>
                                        <ButtonComponent
                                            btnBgColor={btnBgColor}
                                            btnTextColor={btnTextColor}
                                            handleClick={partnersSignUp}
                                            type="default"
                                            fontSize="14px"
                                            btnHeight="54px"
                                        >
                                            {buttonText}
                                        </ButtonComponent>
                                        &nbsp; &nbsp;
                                        <ButtonComponent
                                            btnBgColorOutline={
                                                btnBgColorOutline
                                            }
                                            btnTextColorOutline={
                                                btnTextColorOutline
                                            }
                                            type="outline"
                                            fontSize="14px"
                                            btnHeight="54px"
                                            handleClick={partnersLogin}
                                        >
                                            Sign In as a Partner
                                        </ButtonComponent>
                                    </div>
                                ) : (
                                    <ButtonComponent
                                        btnBgColor={btnBgColor}
                                        btnTextColor={btnTextColor}
                                        handleClick={
                                            buttonClick
                                                ? buttonClick
                                                : handleModal
                                        }
                                        type="default"
                                    >
                                        {buttonText}
                                    </ButtonComponent>
                                )}
                            </>
                        )}
                    </span>
                </div>
            </Col>
            <GetModal
                app="adminsolution"
                showModal={showModal}
                onCancel={handleCancel}
                content={getTypeForm}
            />
        </>
    )
}

const GetStarted = ({ sectionOne, sectionTwo, bgColor, page }) => {
    const { GetStartedRowChild, GetStartedRowDiv, HomeGetStartedRowChild } =
        styles

    return (
        <div className={GetStartedRowDiv} style={{ backgroundColor: bgColor }}>
            <Row
                className={`${GetStartedRowChild} ${
                    page === 'home' ? HomeGetStartedRowChild : ''
                }
                }`}
            >
                {sectionOne}
                {sectionTwo}
            </Row>
        </div>
    )
}

export default GetStarted
