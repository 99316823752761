import ChatCTA from 'src/components/ChatCTA'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { footerPropTypes } from '../utils'
import ButtonComponent from 'src/components/Button'

const Footer = ({
    footerLeftContent = 'chat',
    chatCTAText,
    handleSecondaryBtnClick,
    secondaryBtnDisabled,
    secondaryBtnLoading,
    secondaryBtnText,
    handlePrimaryBtnClick,
    primaryBtnDisabled,
    primaryBtnLoading,
    primaryBtnText,
    footerClassName,
    primaryBtnDataTestId,
}) => {
    const { isMobile } = useMediaQueries()
    return (
        <div
            className={
                footerClassName ? footerClassName : 'consumer-modal-base-footer'
            }
        >
            {footerLeftContent === 'chat' ? (
                <ChatCTA
                    width={isMobile ? '100%' : 'fit-content'}
                    text={chatCTAText}
                />
            ) : footerLeftContent === 'button' ? (
                <ButtonComponent
                    v2
                    type="secondary"
                    size={isMobile ? 'small' : 'large'}
                    loading={secondaryBtnLoading}
                    handleClick={handleSecondaryBtnClick}
                    disabled={secondaryBtnDisabled}
                >
                    {secondaryBtnText}
                </ButtonComponent>
            ) : null}
            <ButtonComponent
                v2
                type="primary"
                size={isMobile ? 'small' : 'large'}
                loading={primaryBtnLoading}
                handleClick={handlePrimaryBtnClick}
                disabled={primaryBtnDisabled}
                dataTestId={primaryBtnDataTestId}
                marginLeft={'auto'}
            >
                {primaryBtnText}
            </ButtonComponent>
        </div>
    )
}

Footer.propTypes = {
    ...footerPropTypes,
}

export default Footer
