import PropTypes from 'prop-types'
import AccountSetupNav from '../../AccountSetupOverview/components/Nav'
import { useConsumerKYCContext } from '../context'

const SetupNav = ({ handleSaveProgress, consumerName, estimationId }) => {
    const {
        handleSubmit,
        setShowSimulateCreditDecision,
        showSimulateCreditDecision,
        isSaving,
        isSubmitting,
        submit,
        kycStatus,
        paymentPlanType,
        disablePageButtons,
        inputs,
        useType,
        estimation,
        isCustomerApproved,
        ranCreditDecision,
        kycInfoFormik,
        autoSaveStatus,
        fetchEstimation,
        providerUpdatedFieldsData,
        providerSendForReviewButtonEnabled,
    } = useConsumerKYCContext()

    return (
        <AccountSetupNav
            handleSubmit={handleSubmit}
            canSubmit={true}
            handleSaveProgress={handleSaveProgress}
            estimationId={estimationId}
            paymentPlanType={paymentPlanType}
            consumerName={consumerName}
            showSimulateCreditDecision={showSimulateCreditDecision}
            setShowSimulateCreditDecision={setShowSimulateCreditDecision}
            page="one-page-kyc"
            isSaving={isSaving}
            isSubmitting={submit && isSubmitting}
            completed={kycStatus?.account_setup_completion_status}
            disablePageButtons={disablePageButtons}
            inputs={inputs}
            useType={useType}
            estimation={estimation}
            isCustomerApproved={isCustomerApproved}
            ranCreditDecision={ranCreditDecision}
            kycInfoFormik={kycInfoFormik}
            hasPaidOutrightly={false} // set to false because outright sale consumers do not use one page kyc.
            autoSaveStatus={autoSaveStatus}
            fetchEstimation={fetchEstimation}
            providerSendForReviewButtonEnabled={
                providerSendForReviewButtonEnabled ||
                !!providerUpdatedFieldsData?.allFields?.length
            }
        />
    )
}

SetupNav.propTypes = {
    handleSaveProgress: PropTypes.func,
    estimationId: PropTypes.string,
    consumerName: PropTypes.string,
    paymentPlanType: PropTypes.string,
}

export default SetupNav
