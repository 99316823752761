import axios from 'src/config/axios'

export const requestPackage = (id, payload) => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/request-change`,
        payload,
    )
    return request
}

export const nudgeProviderPostApproval = async id => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/nudge`,
    )
    return request
}

export const changePackageDecision = async (id, payload) => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/decide`,
        payload,
    )
    return request
}

export const fetchUpfrontDepositDetails = async id => {
    const request = axios.get(
        `/post-approval/consumers/estimations/${id}/upfront-deposit/details`,
    )

    return request
}

export const increaseUpfrontDeposit = async (id, payload) => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/upfront-deposit/increase`,
        payload,
    )

    return request
}
