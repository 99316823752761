import { useState } from 'react'
import { useMutation } from 'react-query'
import { instigateSSESignup } from 'src/api/shoppingExperience/get-system'
import { appTracking } from 'src/utils/appTracker'
import { encodeUserInfo, setToken } from 'src/utils/auth'
import { errorHandler } from 'src/utils/errorHandler'
import getConsumerKYCHome from 'src/utils/getConsumerKYCHome'

const useOnePageKYCSignup = ({ onSuccess, isPromotional }) => {
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [signUpModalToast, setSignUpModalToast] = useState(toastData)
    const [consumerKYCHome, setConsumerKYCHome] = useState('')

    const resetToast = () => {
        setTimeout(() => {
            setSignUpModalToast(toastData)
        }, 2000)
    }

    const {
        mutate: onePageKYCSignupMutate,
        isLoading: onePageKYCSignupLoading,
    } = useMutation({
        mutationKey: ['initiateSSEOnePageSignup'],
        mutationFn: payload => instigateSSESignup(payload),
        onSuccess: res => {
            const data = res?.data?.data
            const customerType = data?.customer_type
            res.data.data.used_one_page_kyc = true
            res.data.data.uses_dynamic_kyc = isPromotional

            appTracking('', '', '', '', '', '', ['MP'], 'identify', {
                identify: { id: data?.user?.id },
            })
            appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                people_set: {
                    'User Types': data?.user?.user_types,
                    'Phone Number': data?.user?.phone_number,
                    $email: data?.user?.email,
                    $name: data?.user?.display_name,
                    $avatar: data?.user?.avatar_url,
                    'Payment Plan Type': data?.payment_model,
                    'Consumer Type': data?.is_dep_customer ? 'DEP' : 'DTC ',
                },
            })

            setToken(data?.token?.access)
            encodeUserInfo(res)
            onSuccess?.(data)

            const consumerKYCHome = getConsumerKYCHome(
                true,
                customerType,
                isPromotional,
            )
            setConsumerKYCHome(consumerKYCHome)
        },
        onError: error => {
            const errorMsg = errorHandler(error?.response?.data)

            setSignUpModalToast({
                showToast: true,
                toastMessage: errorMsg,
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    return {
        onePageKYCSignupMutate,
        onePageKYCSignupLoading,
        signUpModalToast,
        consumerKYCHome,
    }
}

export default useOnePageKYCSignup
