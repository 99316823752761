import PropTypes from 'prop-types'
import { ReactComponent as ImageIcon } from 'src/assets/images/image-icon.svg'
import { ReactComponent as ImageIconWhite } from 'src/assets/images/image-icon-white.svg'
import { ReactComponent as UploadImageIcon } from 'src/assets/images/upload-image-icon.svg'
import { ReactComponent as UploadImageIconWhite } from 'src/assets/images/upload-image-icon-white.svg'

const ImageOptions = ({ selected, setSelected }) => {
    return (
        <div className="ImageOptionsWrapper">
            <button
                className={selected === 'selfie' && 'active'}
                type="button"
                onClick={() => setSelected('selfie')}
            >
                <div>
                    {selected === 'selfie' ? <ImageIconWhite /> : <ImageIcon />}
                </div>
                <div>
                    <h2>Take a seflie</h2>
                    <p>Take the selfie using your device camera</p>
                </div>
            </button>
            <button
                className={selected === 'upload' && 'active'}
                type="button"
                onClick={() => setSelected('upload')}
            >
                <div>
                    {selected === 'upload' ? (
                        <UploadImageIconWhite />
                    ) : (
                        <UploadImageIcon />
                    )}
                </div>
                <div>
                    <h2>Upload your picture</h2>
                    <p>Upload your picture from your device</p>
                </div>
            </button>
        </div>
    )
}

ImageOptions.propTypes = {
    selected: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
}

export default ImageOptions
