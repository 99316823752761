import { useMutation } from 'react-query'
import { decideOnTenureUpdateApi } from 'src/api/consumer'
import { errorHandler } from 'src/utils/errorHandler'

const useDecideOnTenureChange = ({ setModalToast, resetToast, onSuccess }) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: ({ estimationId, payload }) =>
            decideOnTenureUpdateApi(estimationId, payload),
        onSuccess: () => {
            onSuccess?.()
        },
        onError: error => {
            setModalToast({
                showToast: true,
                toastMessage:
                    errorHandler(error?.response?.data) ||
                    'An error occurred, please try again later.',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    return {
        decideOnTenureChangeMutate: mutate,
        decideOnTenureChangeLoading: isLoading,
    }
}

export default useDecideOnTenureChange
