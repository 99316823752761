/* eslint-disable react/prop-types */
import { LockOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { useState, useEffect } from 'react'
import './costfield.scss'

export const CostField = ({
    handleChange,
    name,
    value,
    inputValue,
    defaultValue,
    errorMessage = '',
    inputWidth,
    disabled,
    fullWidth,
    selectWidth,
    placeholder,
    noFloat,
    inputMarginLeft,
    marginBottom,
    cssStyle,
    currencyCode,
    title,
    floatTitlePositionLeft,
    prefilled,
    prefixDisabled,
    zIndex,
    pii,
    floatingLabelFontSize,
}) => {
    const { Option } = Select

    const [updateCurrencyCode, setUpdateCurrencyCode] = useState('₦')

    useEffect(() => setUpdateCurrencyCode(currencyCode), [currencyCode])

    return (
        <div>
            <div
                className={!pii ? 'InputWrapper' : undefined}
                style={{
                    border: errorMessage !== '' ? '1px solid #DA1E28' : '',
                    marginBottom: marginBottom
                        ? marginBottom
                        : errorMessage && '0px',
                    width: inputWidth ? inputWidth : '',
                }}
            >
                <Select
                    defaultValue={defaultValue ? defaultValue : '₦'}
                    style={{
                        width: selectWidth || '150px',
                        background: '#fff !important',
                    }}
                    className="SelectCountry"
                    suffixIcon={''}
                    disabled={disabled ? true : prefixDisabled ? true : false}
                    dropdownClassName="countrySelect"
                    showArrow={false}
                >
                    <Option value={defaultValue ? defaultValue : '₦'}>
                        <div className="UnitCodeBox">
                            <span
                                className="UnitCode"
                                style={{ top: -2, position: 'relative' }}
                            >
                                {updateCurrencyCode}
                            </span>
                        </div>
                    </Option>
                </Select>
                {!pii && (
                    <input
                        type="text"
                        name={name}
                        value={value}
                        className={`DropdownInput ${
                            noFloat && 'NoFloatingLabelInput'
                        }`}
                        onChange={handleChange}
                        required
                        style={{
                            width: fullWidth ? '400px' : '',
                            marginLeft: inputMarginLeft || '-35px',
                            marginTop: title ? '12px' : '',
                            zIndex: zIndex ? 0 : 3,
                            ...cssStyle,
                            // padding: '9px 0px',
                            // position: 'relative',
                            // top: '2px'
                        }}
                        disabled={disabled}
                        placeholder={placeholder}
                        defaultValue={inputValue}
                    />
                )}
                {!pii && (
                    <span
                        style={{
                            left: floatTitlePositionLeft || '42px',
                            zIndex: zIndex ? 0 : 5,
                            top: '16px',
                            fontSize: floatingLabelFontSize || '14px',
                        }}
                        className="FloatingInput"
                    >
                        {title}
                    </span>
                )}
                {prefilled && !pii && (
                    <span className="lock-icon">
                        <LockOutlined className="LockFieldPosition" />
                    </span>
                )}
            </div>
            {errorMessage ? <p className="ErrorText">{errorMessage}</p> : ''}
        </div>
    )
}

export const IconInputField = ({
    icon,
    placeholder,
    name,
    value,
    handleChange,
    width,
    height,
    title,
    onBlur,
    onMouseOut,
    errorMessage = '',
    type,
    marginBottom,
    optionalBottom,
    disabled,
    floatingLabelFontSize,
    showUpdateDot,
}) => {
    return (
        <div
            className="nairaInputContainer"
            style={{ marginBottom: marginBottom }}
        >
            <div
                className={`NairaInputWrapper ${
                    errorMessage ? 'errorBorder' : ''
                }`}
            >
                <span className="NairaInputImgWrapper">
                    <img src={icon} />
                </span>
                <input
                    className="ConsumerNairaInput IconInput"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    style={{
                        width: width,
                        height: height,
                        border: errorMessage && 'none',
                    }}
                    onBlur={e => {
                        onBlur && onBlur(e)
                    }}
                    onMouseOut={e => {
                        onMouseOut && onMouseOut(e)
                    }}
                    type={type ? type : 'text'}
                    required
                    disabled={disabled}
                    data-testid={`naira-input-${name}`}
                />
                <span
                    style={{
                        top: '16px',
                        fontSize: floatingLabelFontSize,
                    }}
                    className={`FloatingLabel ${
                        showUpdateDot ? 'FloatingLabelRow' : ''
                    }`}
                >
                    {title}
                    {showUpdateDot && <div className="FieldUpdateDot" />}
                </span>
            </div>
            {optionalBottom && <span className="optionalTag">Optional</span>}
            {errorMessage ? <p className="ErrorText">{errorMessage}</p> : ''}
        </div>
    )
}
