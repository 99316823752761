import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    addClusterMember,
    validateMemberUpload,
    exportMembers,
    getAllClusterRepMember,
    removeClusterMember,
    resendSystemSelectionLink,
    retrieveClusterSummary,
    uploadCreditReport,
    addMultipleClusterMembers,
} from 'src/api/representative/members'
import { decodeUserInfo } from 'src/utils/auth'
import { errorHandler } from 'src/utils/errorHandler'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateClusterRepMembersFilter,
    updatedClusterRepMembersFilters,
} from 'src/redux/reducers/representative/members/listing'
import {
    convertQueryParamsToObject,
    numberWithCommas,
} from 'src/utils/formatting'
import { getProfilePackageCapacity } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { format } from 'date-fns'
import { uploadMultipleToS3 } from 'src/api/utils/file-upload'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { useParams } from 'react-router-dom'
import { getAllClusters, getClusterInfo } from 'src/api/representative/clusters'
import {
    getEnergyProfile,
    getEnergyProfilePackages,
} from 'src/api/shoppingExperience/energy-profile/api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useWorkspace = () => {
    const { successAlert, errorAlert } = useCustomToast()
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)
    const [showAddMembersModal, setShowAddMembersModal] = useState(false)
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [modalAction, setModalAction] = useState('') // notify-member-warning | notify-member-success | make-group-payment-confirmation-request
    const [members, setMembers] = useState([])
    const [memberDetailsPayload, setMemberDetailsPayload] = useState({})
    const [pageAction, setPageAction] = useState('view-dashboard') // view-dashboard | make-payment | upload-multiple-credit-report | upload-multiple-cluster-members
    const [membersWithDuePayment, setMembersWithDuePayment] = useState([])
    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false)
    const [profileDrawerVisible, setProfileDrawerVisible] = useState(false)
    const [membersListFiltered, setMembersListFiltered] = useState(false)
    const [showMemberDetailsModal, setShowMemberDetailsModal] = useState(false)
    const [memberDetails, setMemberDetails] = useState({})
    const [clusterSummary, setClusterSummary] = useState({})
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [totalMemberPages, setMembersTotalPages] = useState(0)
    const defaultQueries = useSelector(updatedClusterRepMembersFilters)
    const [profileSystemSizes, setProfileSystemSizes] = useState([])
    const [bankDetailsModal, setBankDetailsModal] = useState(false)
    const [uploadFilesModal, setUploadFilesModal] = useState(false)
    const [fileSuccessUpload, setFileSuccessUpload] = useState([])
    const [creditReportFiles, setCreditReportFiles] = useState([])
    const [membersWithNoCreditReport, setMembersWithNoCreditReport] = useState(
        [],
    )
    const [energyProfile, setEnergyProfile] = useState({
        id: '',
        name: '',
        description: '',
        packages: [],
    })
    const [clusterProfileEnergyId, setClusterProfileEnergyId] = useState('')
    const [clusterPackages, setClusterPackages] = useState([])
    const clusterId = useParams()?.id
    const [clusterList, setClusterList] = useState([])
    const [searchClusterQuery, setSearchClusterQuery] = useState('')
    const [filterEnergyPackages, setFilterEnergyPackages] = useState({
        name: '',
    })

    const [currentPage, setCurrentPage] = useState(Number(defaultQueries?.page))
    const [isCreditReportUploaded, setIsCreditReportUploaded] = useState(false)
    const [uploadType, setUploadType] = useState('') // upload-multiple-credit-report || upload-multiple-group-members || single-credit-upload
    const dispatch = useDispatch()
    const [newMembersList, setNewMembersList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [clusterDetails, setClusterDetails] = useState({})
    const initialState = {
        member_status:
            defaultQueries?.member_status?.length > 0
                ? [defaultQueries.member_status].map(item => item)
                : [],
        due_payment: defaultQueries?.due_payment ?? false,
        system_size: defaultQueries?.system_size ?? '',
        payment_type: defaultQueries?.payment_type ?? '',
        payment_amount: defaultQueries?.payment_amount ?? '',
        page: Number(defaultQueries?.page) ?? 1,
        name: defaultQueries?.name ?? '',
        has_credit_report: defaultQueries?.has_credit_report ?? '',
    }

    const [filterStatus, setFilterStatus] = useState(initialState)

    const userInfo = decodeUserInfo()
    const history = useHistory()

    const { uploadToS3Mutate, uploadToS3Loading } = useUploadToS3()

    const closeWelcomeModal = () => {
        setShowWelcomeModal(false)
    }

    const openAddMembersModal = () => {
        setShowAddMembersModal(true)
    }

    const closeAddMembersModal = () => {
        setShowAddMembersModal(false)
    }

    const storeActionAndOpenModal = (action, member) => {
        if (member) setMemberDetails(member)
        if (
            action === 'save-multiple-cluster-members-warning' &&
            newMembersList?.length === 0
        ) {
            handleChangePageAction('view-dashboard')
        } else {
            setModalAction(action)
            setActionModalOpen(true)
        }
    }

    const handleHoldNewMember = payload => {
        setMemberDetailsPayload(payload)
        storeActionAndOpenModal('notify-member-warning')
    }

    const handleChangePageAction = action => {
        setPageAction(action)
    }

    const handlePaymentConfirmation = () => {
        setBankDetailsModal(false)
        setModalAction('make-payment-confirmation')
        setActionModalOpen(true)
    }

    const isCreditReportAdded = (files, members) => {
        if (!files || !members) return false
        const fileNames = files.map(file => file.name)
        return members.some(member =>
            fileNames.some(file => file.includes(member?.email_address)),
        )
    }

    useEffect(() => {
        if (userInfo.isFirstTimeLogin === 'true') {
            setShowWelcomeModal(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (pageAction !== 'upload-multiple-credit-report') {
            setFilterStatus(() =>
                pageAction === 'make-payment'
                    ? { due_payment: true }
                    : { ...initialState, due_payment: false },
            )
        }

        // eslint-disable-next-line
    }, [pageAction])

    useEffect(() => {
        if (
            pageAction === 'upload-multiple-credit-report' &&
            creditReportFiles.length > 0
        ) {
            setIsCreditReportUploaded(
                isCreditReportAdded(
                    creditReportFiles,
                    membersWithNoCreditReport,
                ),
            )
        }
    }, [pageAction, creditReportFiles, membersWithNoCreditReport])

    const { refetch: refectClusterInfo } = useQuery(
        'clusterInfo',
        () => getClusterInfo(clusterId),
        {
            enabled: !!clusterId,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setClusterProfileEnergyId(data?.energy_profile)
                setClusterDetails(data)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useQuery(
        'cluster-energy-profile',
        () => getEnergyProfile({ id: clusterProfileEnergyId }),
        {
            enabled: !!clusterProfileEnergyId,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setEnergyProfile({
                    id: data?.id,
                    name: data?.name,
                    description: data?.description,
                    packages: data?.packages,
                })
            },
        },
    )

    const {
        refetch: fetchClusterProfilePackages,
        isLoading: isLoadingClusterProfilePackages,
        isFetching: isFetchingClusterProfilePackages,
    } = useQuery(
        'clusterProfilePackages',
        () =>
            getEnergyProfilePackages({
                id: clusterProfileEnergyId ?? energyProfile?.id,
                name: filterEnergyPackages?.name || '',
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setClusterPackages(data)
            },
        },
    )

    const handleEnergyProfilePackagesSearch = name => {
        setFilterEnergyPackages({ ...filterEnergyPackages, name })
    }

    const getDueAmount = () => {
        if (pageAction === 'make-payment') {
            return membersWithDuePayment?.reduce(
                (total, item) =>
                    numberWithCommas(
                        total +
                            parseFloat(
                                item.monthly_payment.replaceAll(',', ''),
                            ),
                    ),
                0,
            )
        } else {
            return memberDetails?.monthly_payment
        }
    }

    const handleSaveClusterMember = () => {
        if (newMembersList.some(m => !m.valid)) {
            storeActionAndOpenModal('failed-upload-cluster-members')
        } else {
            storeActionAndOpenModal(
                'upload-multiple-cluster-members-confirmation',
            )
        }
    }

    const queryKey = [
        'clusterRepMembers',
        filterStatus?.member_status,
        filterStatus?.payment_type,
        filterStatus?.system_size,
        filterStatus?.payment_amount,
        filterStatus?.due_payment,
        filterStatus?.page,
        filterStatus?.name,
        filterStatus?.has_credit_report,
        currentPage,
    ]

    const {
        refetch: refetchClusterMembers,
        isLoading: clusterMembersFetching,
    } = useQuery(
        queryKey,
        () =>
            getAllClusterRepMember(
                clusterId,
                filterStatus?.member_status?.map(item => item).join(',') || [],
                filterStatus?.payment_type || '',
                filterStatus?.system_size || '',
                filterStatus?.payment_amount || '',
                filterStatus?.due_payment || '',
                currentPage || 1,
                filterStatus?.name || '',
                filterStatus?.has_credit_report, //has_credit_report value
                filterStatus?.has_credit_report, //for pagniation, should pagniate if has_credit_report is true and vice versa
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateClusterRepMembersFilter(
                        convertQueryParamsToObject([...queries]),
                    ),
                )

                const data = res?.data?.data

                if (pageAction === 'make-payment') {
                    setMembersWithDuePayment(data)
                } else {
                    setMembers(data)
                    if (
                        !filterStatus?.has_credit_report &&
                        (modalAction ===
                            'upload-multiple-credit-report-request' ||
                            pageAction === 'upload-multiple-credit-report')
                    ) {
                        setMembersWithNoCreditReport(
                            data.filter(member => !!member?.system_size?.value),
                        )
                        setModalAction('')
                    }
                }

                if (pageAction !== 'upload-multiple-cluster-members') {
                    setMembersTotalPages(res?.data?.total_pages)
                }
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useQuery(
        'profilePackageRatedCapacity',
        () => getProfilePackageCapacity(userInfo?.clusterProfileId),
        {
            enabled: true,
            onSuccess: res => {
                setProfileSystemSizes(res?.data?.data)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    const addNewClusterMember = useMutation({
        mutationFn: () => addClusterMember(memberDetailsPayload, clusterId),
        onSuccess: () => {
            setModalAction('notify-member-success')
            refetchClusterMembers()
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    useQuery('clusterSummary', () => retrieveClusterSummary(clusterId), {
        enabled: true,
        retry: false,
        onSuccess: data => {
            setClusterSummary(data?.data?.data)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const { refetch: exportMembersList } = useQuery(
        'download-csv',
        () =>
            exportMembers(
                clusterId,
                filterStatus?.member_status?.map(item => item).join(',') || '',
                filterStatus?.payment_type || '',
                filterStatus?.system_size || '',
                filterStatus?.payment_amount || '',
                filterStatus?.due_payment || '',
                filterStatus?.page || 1,
                filterStatus?.name || '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const clusterName = userInfo?.clusterName.replaceAll(' ', '_')
                const date = format(new Date(), 'yyyy_MM_dd_HH_mm')
                const outputFilename = `${clusterName}_${date}.xls`
                const url = URL.createObjectURL(new Blob([data.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', outputFilename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(url)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const resendSystemSelectionMutation = useMutation({
        mutationKey: ['resendSystemSelection'],
        mutationFn: () =>
            resendSystemSelectionLink(memberDetails.id, clusterId),
        onSuccess: () => {
            setModalAction('resend-system-selection-link-success')
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const removeClusterMemberMutation = useMutation({
        mutationKey: ['removeClusterMember'],
        mutationFn: () => removeClusterMember(memberDetails.id, clusterId),
        onSuccess: () => {
            setModalAction('remove-member-confirmation-success')
            refetchClusterMembers()
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const uploadCreditReportsMutation = useMutation({
        mutationFn: urls => uploadCreditReport({ url: urls }, clusterId),
        onSuccess: () => {
            if (pageAction === 'view-dashboard') {
                successAlert(
                    'Upload successful, You will get notified once review is complete',
                )
                setUploadFilesModal(false)
                setFileSuccessUpload([])
                refetchClusterMembers()
            } else {
                setModalAction('confirm-multiple-credit-reports-upload-success')
            }
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const handleCreditReportUpload = uploads => {
        const credit_report_files =
            uploadType === 'single-credit-upload' ? uploads : creditReportFiles
        const presignedUrlData = credit_report_files?.map(item => ({
            file_name: item.name,
            category: 'CLUSTER_MEMBERS',
        }))
        uploadMultipleToS3Mutate({
            files: credit_report_files,
            presignedUrlData,
        })
    }

    const {
        mutate: uploadMultipleToS3Mutate,
        isLoading: uploadMultipleFilesToS3Loading,
    } = useMutation({
        mutationFn: ({ files, presignedUrlData }) =>
            uploadMultipleToS3(presignedUrlData, files),
        onSuccess: data => {
            const urls = data?.map(file => {
                const docUrl = file?.url + file?.fields?.key || ''
                return docUrl
            })
            uploadCreditReportsMutation.mutate(urls)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const handleUpload = () => {
        switch (uploadType) {
            case 'upload-multiple-credit-report':
                setFilterStatus(prev => ({
                    ...prev,
                    has_credit_report: false,
                }))
                setCreditReportFiles(fileSuccessUpload)
                setUploadFilesModal(false)
                handleChangePageAction('upload-multiple-credit-report')
                setFileSuccessUpload([])
                break
            case 'single-credit-upload':
                setCreditReportFiles(prev => [...prev, ...fileSuccessUpload])
                if (pageAction === 'upload-multiple-credit-report') {
                    setUploadFilesModal(false)
                    setUploadType('')
                    setFileSuccessUpload([])
                } else {
                    if (
                        fileSuccessUpload[0].name.includes(
                            memberDetails?.email_address,
                        )
                    ) {
                        handleCreditReportUpload(fileSuccessUpload)
                    } else {
                        errorAlert(
                            'Invalid document, ensure the document is uniquely named after the email of member',
                        )
                    }
                }
                break
            case 'upload-multiple-group-members':
                storeActionAndOpenModal('uploading-multiple-cluster-members')
                setUploadFilesModal(false)
                break
        }
    }

    const handleCreditReportRemoval = () => {
        const newMemberList = membersWithNoCreditReport.filter(
            member => member?.id !== memberDetails?.id,
        )
        setMembersWithNoCreditReport(newMemberList)
        setModalAction('remove-credit-report-member-confirmation-success')
    }

    useEffect(() => {
        if (!membersWithNoCreditReport || !creditReportFiles) return

        const filteredCreditReportFiles = creditReportFiles?.filter(file =>
            membersWithNoCreditReport?.some(member =>
                file?.name?.includes(member?.email_address),
            ),
        )

        setCreditReportFiles(filteredCreditReportFiles)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membersWithNoCreditReport])

    const validateClusterMember = useMutation({
        mutationFn: url => validateMemberUpload({ url }, clusterId),
        onSuccess: res => {
            const data = res?.data?.data
            const total_page = Math.ceil(data.length / 10)
            setNewMembersList(data)
            setMembersTotalPages(total_page)
        },
        onError: () => {
            errorAlert('Error uploading file')
        },
    })

    const addMultipleClusterMembersMutation = useMutation({
        mutationFn: members => addMultipleClusterMembers(members, clusterId),
        onSuccess: () => {
            setModalAction('upload-multiple-cluster-members-success')
        },
        onError: () => {
            errorAlert('Error adding members, please try again')
        },
    })

    const handleFileSubmission = () => {
        uploadToS3Mutate(
            {
                category: 'CLUSTER_MEMBERS',
                file: fileSuccessUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    validateClusterMember.mutate(docUrl)
                    setFileSuccessUpload([])
                },
                onError: () => {
                    errorAlert('Error uploading file')
                },
            },
        )
    }

    const handleMultipleMembersSubmission = () => {
        let newMembers = []
        for (const item of newMembersList) {
            newMembers.push(item.row)
        }
        addMultipleClusterMembersMutation.mutate(newMembers)
    }

    const removeGroupMemberFromUpload = () => {
        const updatedMembersList = newMembersList.filter(
            member =>
                Object.values(member.row).toString() !==
                Object.values(memberDetails).toString(),
        )
        setNewMembersList(updatedMembersList)
        setIsLoading(false)
        setModalAction('remove-member-confirmation-success')
    }

    const {
        refetch: refetchClusters,
        isFetching: fetchingClusterList,
        isLoading: loadingClusterList,
    } = useQuery(
        'clusters',
        () => getAllClusters({ name: searchClusterQuery }),
        {
            enabled: true,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                setClusterList(data)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    const handleClusterListSearch = query => {
        setSearchClusterQuery(query)
        refetchClusters()
    }

    useEffect(() => {
        refetchClusterMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filterStatus, clusterId])

    const disableActionBtn = () => {
        switch (pageAction) {
            case 'make-payment':
                return membersWithDuePayment?.length === 0
            case 'upload-multiple-credit-report':
                return !isCreditReportUploaded
            case 'upload-multiple-cluster-members':
                return newMembersList?.length === 0
            default:
                return false
        }
    }

    const actionModalHandler = () => {
        switch (modalAction) {
            case 'notify-member-warning': {
                addNewClusterMember.mutate()
                break
            }
            case 'notify-member-success': {
                setActionModalOpen(false)
                setModalAction('')
                break
            }
            case 'make-group-payment-confirmation-request':
                setActionModalOpen(false)
                setBankDetailsModal(true)
                break
            case 'resend-system-selection-link':
                resendSystemSelectionMutation.mutate()
                break
            case 'resend-system-selection-link-success':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'make-single-payment-confirmation-request':
                setActionModalOpen(false)
                setBankDetailsModal(true)
                break
            case 'make-payment-confirmation':
                pageAction !== 'view-dashboard' &&
                    handleChangePageAction('view-dashboard')
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'remove-member-confirmation-request':
                removeClusterMemberMutation.mutate()
                break
            case 'remove-member-confirmation-success':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'upload-multiple-credit-report-request':
                setActionModalOpen(false)
                setModalAction('')
                setUploadType('upload-multiple-credit-report')
                setUploadFilesModal(true)

                break
            case 'confirm-multiple-credit-reports-upload':
                handleCreditReportUpload()
                break
            case 'confirm-multiple-credit-reports-upload-success':
                setActionModalOpen(false)
                setMembersWithNoCreditReport([])
                setCreditReportFiles([])
                setFileSuccessUpload([])
                setModalAction('')
                handleChangePageAction('view-dashboard')
                break
            case 'remove-credit-report-member-confirmation-request':
                handleCreditReportRemoval()
                break
            case 'remove-credit-report-member-confirmation-success':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'uploading-multiple-cluster-members':
                handleFileSubmission()
                setActionModalOpen(false)
                handleChangePageAction('upload-multiple-cluster-members')
                break
            case 'failed-upload-cluster-members':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'upload-multiple-cluster-members-confirmation':
                handleMultipleMembersSubmission()
                break
            case 'upload-multiple-cluster-members-success':
                setActionModalOpen(false)
                setModalAction('')
                handleChangePageAction('view-dashboard')
                break
            case 'save-multiple-cluster-members-warning':
                handleChangePageAction('view-dashboard')
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'remove-group-member-from-upload-confirmation-request':
                setIsLoading(true)
                removeGroupMemberFromUpload()
                break
        }
    }

    const secondaryActionModalHandler = () => {
        switch (modalAction) {
            case 'remove-member-confirmation-request':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'save-multiple-cluster-members-warning':
                setActionModalOpen(false)
                setModalAction('')
                break
        }
    }

    const closeActionModal = () => {
        setActionModalOpen(false)
    }

    const handleFilterDrawerOpen = () => {
        setFilterDrawerVisible(true)
    }

    const onFilterDrawerClose = () => {
        setFilterDrawerVisible(false)
    }

    const handleProfileDrawerOpen = () => {
        setProfileDrawerVisible(true)
    }

    const onProfileDrawerClose = () => {
        setProfileDrawerVisible(false)
    }

    const applyFilter = () => {
        setMembersListFiltered(true)
        setFilterDrawerVisible(false)
        refetchClusterMembers()
    }

    const resetFilter = () => {
        setMembersListFiltered(false)
        setFilterStatus({
            member_status: [],
            due_payment: false,
            system_size: '',
            payment_type: '',
            page: 1,
            name: '',
            payment_amount: '',
        })
        setFilterDrawerVisible(false)
    }
    const openMemberDetailsModal = member => {
        setMemberDetails(member)
        setShowMemberDetailsModal(true)
    }

    const closeMemberDetailsModal = () => {
        setShowMemberDetailsModal(false)
    }

    const updatePage = page => {
        setCurrentPage(page)
    }

    const storeActionAndOpenUploadModal = (action, member) => {
        if (member) setMemberDetails(member)
        setUploadType(action)
        setUploadFilesModal(true)
    }

    useEffect(() => {
        refectClusterInfo()
        refetchClusters()
        // eslint-disable-next-line
    }, [clusterId])

    useEffect(() => {
        if (clusterProfileEnergyId || energyProfile?.id) {
            fetchClusterProfilePackages()
        }
        // eslint-disable-next-line
    }, [filterEnergyPackages, clusterProfileEnergyId, energyProfile])

    const goBackToSystem = estimation => {
        if (history.location.state?.from) {
            history.push(history.location.state.from, history.location.state)
        } else {
            history.push({
                pathname: `/consumer/view-system/${estimation?.id}`,
                state: {
                    returnToClusterWorkspace:
                        history.location?.pathname?.includes('/rep/workspace'),
                    payment_model: estimation?.payment_model,
                    isAuthenticatedUser: true,
                    from: history?.location?.pathname,
                },
            })
        }
    }

    return {
        showWelcomeModal,
        closeWelcomeModal,
        showAddMembersModal,
        openAddMembersModal,
        closeAddMembersModal,
        actionModalOpen,
        modalAction,
        actionModalHandler,
        members,
        handleHoldNewMember,
        pageAction,
        handleChangePageAction,
        membersWithDuePayment,
        storeActionAndOpenModal,
        closeActionModal,
        filterDrawerVisible,
        handleFilterDrawerOpen,
        onFilterDrawerClose,
        profileDrawerVisible,
        handleProfileDrawerOpen,
        onProfileDrawerClose,
        membersListFiltered,
        applyFilter,
        resetFilter,
        showMemberDetailsModal,
        openMemberDetailsModal,
        closeMemberDetailsModal,
        secondaryActionModalHandler,
        memberDetails,
        userInfo,
        clusterSummary,
        toastError,
        errorMessage,
        actionLoading:
            addNewClusterMember.isLoading ||
            removeClusterMemberMutation.isLoading ||
            resendSystemSelectionMutation.isLoading ||
            uploadMultipleFilesToS3Loading ||
            uploadCreditReportsMutation.isLoading ||
            isLoading,
        totalMemberPages,
        filterStatus,
        setFilterStatus,
        profileSystemSizes,
        currentPage,
        updatePage,
        exportMembers: exportMembersList,
        bankDetailsModal,
        successAlert,
        handlePaymentConfirmation,
        totalDuePaymentAmount: getDueAmount(),
        uploadFilesModal,
        setUploadFilesModal,
        fileSuccessUpload,
        setFileSuccessUpload,
        creditReportFiles,
        membersWithNoCreditReport,
        isCreditReportUploaded,
        uploadType,
        handleSaveClusterMember,
        handleFileSubmission,
        storeActionAndOpenUploadModal,
        handleUpload,
        newMembersList,
        membersLoading:
            clusterMembersFetching ||
            validateClusterMember.isLoading ||
            uploadToS3Loading,
        disableActionBtn,
        clusterDetails,
        handleClusterListSearch,
        clusterList,
        fetchingClusterList,
        loadingClusterList,
        clusterPackages,
        energyProfile,
        handleEnergyProfilePackagesSearch,
        goBackToSystem,
        isFetchingClusterProfilePackages:
            isLoadingClusterProfilePackages || isFetchingClusterProfilePackages,
    }
}

export default useWorkspace
