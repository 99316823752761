import { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { getRequestedUpdatesApi } from 'src/api/shoppingExperience/kyc/api'
import { decodeUserInfo } from 'src/utils/auth'
import { UPDATE_REQUEST_MESSAGE_MAP } from '../utils'
import { getUserType } from 'src/utils/getUserType'

const useUpdateRequests = () => {
    const [requestMsg, setRequestMsg] = useState([])
    const [requestCategories, setRequestCategories] = useState([])
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const { isProviderAndPartnerKycAssistAllowed } = getUserType()
    const location = useLocation()

    const { refetch: updateRequestsRefetch } = useQuery(
        ['update-requests-from-admin', location.pathname],
        () => getRequestedUpdatesApi(),
        {
            enabled: !(isAdminUser || isProviderAndPartnerKycAssistAllowed),
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: res => {
                const data = res?.data?.data
                let tempRequests = {}
                let tempRequestCategories = []
                data?.forEach(item => {
                    item.fields?.forEach(field => {
                        if (field.updated === false) {
                            tempRequests[field.name] = `Please change your ${
                                UPDATE_REQUEST_MESSAGE_MAP[field.name]
                            }`
                        }
                    })
                    if (Array.isArray(item.sse_category)) {
                        tempRequestCategories = [
                            ...tempRequestCategories,
                            ...item.sse_category,
                        ]
                    }
                })
                setRequestMsg(tempRequests)
                setRequestCategories([...new Set(tempRequestCategories)])
            },
        },
    )
    return { requestMsg, requestCategories, updateRequestsRefetch }
}

export default useUpdateRequests
