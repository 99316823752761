import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import s from '../clusterinfo.module.scss'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ButtonComponent from 'src/components/Button'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import Avatar from 'src/components/Avatar/Avatar'
import { ReactComponent as EditIcon } from 'src/assets/images/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/images/trash-icon.svg'
import ActionModal from 'src/components/ActionModal'
import { handleInputChange } from 'src/components/ConsumerLandingPageBody/utils'
import { ProfileClusterRepValidator } from 'src/utils/validationSchema'
import { formatPhoneNumber } from 'src/utils/formatting'
import { usePostClusterOrigination } from 'src/api/cluster_origination/queries'
import { useQueryClient } from 'react-query'
import { InlineLoader } from 'src/components/Loader'
import { removeCountryCode } from 'src/utils/NumberFormatter'

const Representatives = ({ data, setData, addRepInfo, onAfterUpdate }) => {
    const { profile_id } = useParams()
    const queryClient = useQueryClient()

    const [showRepModal, setShowRepModal] = useState({
        open: false,
        id: '',
    })
    const [showDeleteModal, setShowDeleteModal] = useState({
        open: false,
        id: '',
    })

    const [inputs, setInputs] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
    })
    const [errors, setErrors] = useState({})

    const clearInputs = () => {
        setInputs({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
        })
    }

    const closeRepModal = () => {
        setShowRepModal({ open: false, id: '' })
        setErrors({})
        clearInputs()
    }

    const { postClusterOriginationLoading, postClusterOriginationMutate } =
        usePostClusterOrigination({
            id: profile_id,
            onSuccess: () => {
                if (showDeleteModal?.open === true) {
                    setShowDeleteModal({
                        id: '',
                        open: false,
                    })
                } else if (showRepModal?.open === true) {
                    setShowRepModal({
                        id: '',
                        open: false,
                    })
                }
                clearInputs()
                queryClient.invalidateQueries('get-cluster-origination')
            },
        })

    const handleSubmit = () => {
        ProfileClusterRepValidator.validate(inputs, {
            context: { data: data },
            abortEarly: false,
        })
            .then(() => {
                if (profile_id && showRepModal?.id?.length === 0) {
                    postClusterOriginationMutate({
                        add: [
                            {
                                first_name: inputs?.first_name,
                                last_name: inputs?.last_name,
                                email: inputs?.email,
                                phone_number: formatPhoneNumber(inputs?.phone),
                            },
                        ],
                    })
                } else if (showRepModal?.id?.length) {
                    if (profile_id) {
                        postClusterOriginationMutate({
                            modify: [
                                {
                                    first_name: inputs?.first_name,
                                    last_name: inputs?.last_name,
                                    id: showRepModal?.id,
                                },
                            ],
                        })
                    } else {
                        editObjectInArray(showRepModal?.id)
                    }
                } else {
                    setData(prev => [...prev, { ...inputs, id: uuidv4() }])

                    if (onAfterUpdate) {
                        onAfterUpdate(data)
                    }
                }
                !profile_id && closeRepModal()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const removeRepById = () => {
        if (profile_id) {
            postClusterOriginationMutate({
                remove: [showDeleteModal?.id],
            })
        } else {
            const remainingObjs = data.filter(
                item => item.id !== showDeleteModal?.id,
            )
            setData(remainingObjs)
            if (onAfterUpdate) {
                onAfterUpdate(remainingObjs)
            }
            setShowDeleteModal({ id: '', open: false })
        }
    }

    function editObjectInArray(id) {
        const index = data.findIndex(obj => obj.id === id)
        if (index !== -1) {
            data[index] = { ...data[index], ...inputs }
        }
        if (onAfterUpdate) {
            onAfterUpdate(data)
        }
        setInputs({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
        })
        return data
    }

    return (
        <div style={{ marginTop: 8 }}>
            {data?.length > 0 ? (
                <div className={s.representative_card_parent_wrapper}>
                    {data?.map(item => (
                        <div key={item.id} className={s.representative_card}>
                            <div>
                                <Avatar
                                    avatar={null}
                                    background_color="#F9FCFF"
                                    first_name={item?.first_name}
                                    last_name={item?.last_name}
                                    text_color="#3D5075"
                                />
                            </div>
                            <div>
                                <div>
                                    <h3>
                                        {item?.first_name} {item?.last_name}
                                    </h3>
                                    <div>
                                        <EditIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                const editedData = data?.find(
                                                    dt => dt.id === item.id,
                                                )
                                                setInputs({
                                                    ...editedData,
                                                    phone: removeCountryCode(
                                                        item?.phone,
                                                    ),
                                                })
                                                setShowRepModal({
                                                    open: true,
                                                    id: item?.id,
                                                })
                                            }}
                                        />
                                        <DeleteIcon
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                setShowDeleteModal({
                                                    id: item?.id,
                                                    open: true,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span>
                                            {formatPhoneNumber(
                                                removeCountryCode(item?.phone),
                                            )}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="5"
                                            height="5"
                                            viewBox="0 0 5 5"
                                            fill="none"
                                        >
                                            <circle
                                                cx="2.5"
                                                cy="2.5"
                                                r="2.5"
                                                fill="#3D5075"
                                            />
                                        </svg>
                                        <span>{item?.email}</span>
                                    </div>
                                    {/* TODO: this is not needed for now  */}
                                    {/* <p>{item.representative_permission}</p> */}
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={s.representative_card_add_more_btn}>
                        <button
                            onClick={() =>
                                setShowRepModal({
                                    open: true,
                                    id: '',
                                })
                            }
                        >
                            <PlusIcon /> Add a new cluster rep
                        </button>
                    </div>
                </div>
            ) : (
                <div className={s.no_cluster_wrapper}>
                    <p
                        style={{
                            color: !addRepInfo ? '#B0B0B0' : '',
                        }}
                    >
                        Cluster representatives will manage all consumers added
                        to this cluster, they’ll be responsible for adding
                        consumers, bulk payments, etc.
                    </p>
                    <button
                        onClick={() =>
                            setShowRepModal({
                                open: true,
                                id: '',
                            })
                        }
                        style={{
                            cursor: !addRepInfo ? 'not-allowed' : '',
                            opacity: !addRepInfo ? '0.5' : '',
                        }}
                        disabled={!addRepInfo}
                    >
                        <PlusIcon /> Add Representatives
                    </button>
                </div>
            )}

            <SecondaryModal
                closable={true}
                showModal={showRepModal?.open}
                onCancel={closeRepModal}
                modalWidth="624px"
                content={
                    <div className={s.rep_modal_content_wrapper}>
                        <div>
                            <h2>
                                {showRepModal?.id?.length ? 'Edit' : 'Add a'}{' '}
                                cluster representative
                            </h2>
                            <h3>
                                {showRepModal?.id?.length
                                    ? 'Edit the'
                                    : 'Add the following'}{' '}
                                details of the cluster representative
                            </h3>
                        </div>
                        <div>
                            <InputFields
                                name="first_name"
                                title={'Representative First Name'}
                                value={inputs?.first_name}
                                handleChange={e =>
                                    handleInputChange(e, setInputs, setErrors)
                                }
                                errorMessage={errors['first_name']}
                            />
                            <InputFields
                                name="last_name"
                                title={'Representative Last Name'}
                                value={inputs?.last_name}
                                handleChange={e =>
                                    handleInputChange(e, setInputs, setErrors)
                                }
                                errorMessage={errors['last_name']}
                            />
                            <SelectCountryCode
                                title="Representative Phone Number"
                                name="phone"
                                inputValue={inputs?.phone}
                                handleChange={e => {
                                    handleInputChange(e, setInputs, setErrors)
                                }}
                                errorMessage={errors['phone']}
                                disabled={profile_id && showRepModal?.id}
                            />
                            <InputFields
                                name="email"
                                title={'Representative Email Address'}
                                value={inputs?.email}
                                handleChange={e =>
                                    handleInputChange(e, setInputs, setErrors)
                                }
                                errorMessage={errors['email']}
                                disabled={profile_id && showRepModal?.id}
                            />

                            {/* TODO: this is not needed for now  */}
                            {/* <SelectField
                                selectWidth="100%"
                                initialOption="Add Representative Permission"
                                floatingLabel={'Add Representative Permission'}
                                optionStyleNoCheckbox={{ color: '#233862' }}
                                name="representative_permission"
                                contentField="name"
                                dropdownPositionRelative
                                withCheckBox
                            /> */}
                        </div>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'186px'}
                            btnHeight={'56px'}
                            btnTextColor={'#FFFFFF'}
                            marginTop={'24px'}
                            handleClick={handleSubmit}
                        >
                            {postClusterOriginationLoading ? (
                                <InlineLoader />
                            ) : showRepModal?.id?.length ? (
                                'Save'
                            ) : (
                                'Add this representative'
                            )}
                        </ButtonComponent>
                    </div>
                }
            />
            <ActionModal
                actionModalOpen={showDeleteModal?.open}
                headerText={'You’re about to remove a cluster rep'}
                subTitle={
                    'You’re about to remove a cluster rep from the list of added representative. Please confirm'
                }
                actionType={'warning'}
                actionText={'Confirm'}
                noCancelBtn={false}
                noPrimaryBtn={false}
                loading={postClusterOriginationLoading}
                actionHandler={removeRepById}
                closeModal={() => setShowDeleteModal({ id: '', open: false })}
                noBackLink
            />
        </div>
    )
}

Representatives.propTypes = {
    data: PropTypes.array,
    setData: PropTypes.func,
    addRepInfo: PropTypes.boolean,
    onAfterUpdate: PropTypes.func,
}

export default Representatives
