import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import s from '../profileslistings.module.scss'
import '../adminprofileslistings.scss'
import { tableHeaders } from '../data'
import ButtonComponent from 'src/components/Button'
import Card from 'src/components/Card'
import { toTitleCase } from 'src/utils/formatting'
import { format, parseISO } from 'date-fns'
import { getPermissions } from '../formatter'

const ProfilesListingsTable = ({ profiles }) => {
    const history = useHistory()
    const { canViewEnergyProfileDetails } = getPermissions()

    return (
        <>
            <table
                className={s.table}
                data-testid="profiles-listings-desktop-table"
            >
                <tr className={s.tableHeads}>
                    {tableHeaders?.map((header, i) => (
                        <th key={i}>{header}</th>
                    ))}
                </tr>
                {profiles?.map((row, i) => (
                    <tr key={i} className={s.tableRow}>
                        <div>
                            <td>{row?.name} </td>
                            {row?.promotional_type !== null && (
                                <span
                                    className={
                                        row?.promotional_type === 'PRIVATE'
                                            ? 'DetailsPageStatusSecTag'
                                            : 'DetailsPageStatusTag'
                                    }
                                >
                                    <p>{row?.promotional_type}</p>
                                </span>
                            )}
                        </div>
                        <td>{row?.package_added}</td>
                        <td>{toTitleCase(row?.customer_type)}</td>
                        <td>
                            {row?.updated_at !== undefined &&
                                format(
                                    parseISO(row?.updated_at),
                                    'dd MMM yyyy, HH:mm',
                                )}
                        </td>
                        <td>
                            <ButtonComponent
                                btnBgColor={'#004AAD'}
                                btnWidth={'70px'}
                                btnHeight={'37px'}
                                btnTextColor={'#FFFFFF'}
                                handleClick={() =>
                                    history.push(
                                        `/admin/consumer-profiles/${row?.id}`,
                                    )
                                }
                                dataTestId={`view-button-${row?.id}`}
                                disabled={!canViewEnergyProfileDetails}
                            >
                                View
                            </ButtonComponent>
                        </td>
                    </tr>
                ))}
            </table>

            {/* mobile */}
            <div
                className={s.cards}
                data-testid="profiles-listings-mobile-cards"
            >
                {profiles?.map((row, i) => (
                    <Card
                        key={i}
                        topLeftHeading={'CONSUMER PROFILE NAME'}
                        topLeftContent={row?.name}
                        bottomLeftHeading={'PACKAGE ADDED'}
                        bottomLeftContent={row?.package_added}
                        bottomRightHeading={'CONSUMER PROFILE TYPE'}
                        bottomRightContent={toTitleCase(row?.customer_type)}
                        handleClick={() =>
                            history.push(`/admin/consumer-profiles/${row?.id}`)
                        }
                        btnDataTestId={`card-view-button-${row?.id}`}
                        btnDisabled={!canViewEnergyProfileDetails}
                    />
                ))}
            </div>
        </>
    )
}

ProfilesListingsTable.propTypes = {
    profiles: PropTypes.array,
}

export default ProfilesListingsTable
