import 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/consumerkyc.scss'
import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
// import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import ChatCTA from 'src/components/ChatCTA'
import { useHistory, useParams } from 'react-router-dom'
import { useConsumerKYCContext } from '../../../ConsumerKYC/context'
import { getUserType } from 'src/utils/getUserType'

const PageHeading = ({
    goBackToSystem,
    setShowSaveProgressModal,
    kycInfoFormik,
    // handleShowVideo,
}) => {
    // TODO: add prop when video is restored
    // handleShowVideo,
    const { autoSaveStatus } = useConsumerKYCContext()
    const history = useHistory()
    const { id } = useParams()
    const { isMobile } = useMediaQueries()
    const { isProviderAndPartnerKycAssistAllowed, isAdminUser } = getUserType()

    const abTestInBoundRequest = history?.location?.state?.abTestInBoundRequest

    const handleGoBackUrl = () => {
        if (id) {
            history.replace(`/admin/plans/${id}`)
        } else {
            history.goBack()
        }
    }

    const handleBackNavigation = () => {
        if (isAdminUser) {
            if (kycInfoFormik.dirty) {
                if (!autoSaveStatus) {
                    setShowSaveProgressModal(false)
                    handleGoBackUrl()
                } else {
                    setShowSaveProgressModal(true)
                }
            } else {
                handleGoBackUrl()
            }
        } else if (isProviderAndPartnerKycAssistAllowed) {
            history.replace({
                pathname: '/app/payment-plans',
                search: history.location?.state?.query || '',
                state: history.location?.state,
            })
        } else {
            if (
                history?.location?.state?.returnToWorkspace ||
                history?.location?.state?.from === '/consumer/workspace'
            ) {
                history.push(`/consumer/workspace`, history?.location?.state)
            } else goBackToSystem()
        }
    }

    return (
        <div className="OnePageKYC_HeadingSection" style={{ paddingBottom: 0 }}>
            {!abTestInBoundRequest && !isMobile && (
                <BackNav
                    v2
                    title={isAdminUser ? 'Back to admin portal' : 'Go back'}
                    marginBottom="16px"
                    onClick={handleBackNavigation}
                />
            )}
            {isAdminUser ? (
                <h3 className="Heading">View customer&apos;s information</h3>
            ) : (
                <h3 className="Heading">Welcome to the KYC section!</h3>
            )}
            {isAdminUser ? (
                <p className="SubHeading">
                    Consumer submitted data is readily accessible for
                    administrative review, with the ability to examine each
                    section in detail
                </p>
            ) : (
                <p className="SubHeading">
                    We would love to get to know you better! <br /> Kindly
                    provide the information requested below.
                </p>
            )}
            {/* TODO: uncomment when an updated video for one page kyc is inserted here */}
            {/* {!isAdminUser && (
                <div
                    className="KYCPageVideo"
                    onClick={() => handleShowVideo(true)}
                >
                    <PlayIcon />
                    <p>How to complete your KYC</p>
                </div>
            )} */}
            {isMobile && (
                <ChatCTA
                    text="Have questions about this process?"
                    margin="19px 0 0 0"
                />
            )}
        </div>
    )
}

PageHeading.propTypes = {
    handleShowVideo: PropTypes.func,
    goBackToSystem: PropTypes.func,
    showSaveProgressModal: PropTypes.bool,
    setShowSaveProgressModal: PropTypes.func,
    kycInfoFormik: PropTypes.object,
}

export default PageHeading
