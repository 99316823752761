import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { verifyCac } from 'src/api/create-account'
import { appTracking } from 'src/utils/appTracker'
import { errorHandler } from 'src/utils/errorHandler'
import { eventTrackers } from 'src/utils/eventTrackers'
import { getUserType } from 'src/utils/getUserType'
import { onePageVerifyKycBussinessProviderApi } from 'src/api/shoppingExperience/kyc/api'

const useVerifyCac = ({
    closeModal,
    refetch,
    setVerificationFailedModal,
    handleModalInputError,
}) => {
    const { id: estimationID } = useParams()
    const { isProviderAndPartnerKycAssistAllowed } = getUserType()

    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('success-secondary')
    const [modalToastMessage, setModalToastMessage] = useState('')
    const [modalToastType, setModalToastType] = useState('success-secondary')
    const pageTitle =
        'KYC - VERIFY CAC | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'

    const validateCacMutation = useMutation({
        mutationFn: cacVerifyPayload => {
            if (isProviderAndPartnerKycAssistAllowed && estimationID) {
                return onePageVerifyKycBussinessProviderApi({
                    data: cacVerifyPayload,
                    estimationID,
                })
            }
            return verifyCac(cacVerifyPayload)
        },
        onSuccess: response => {
            if (response.data.data.result) {
                closeModal()
                setToastType('success-secondary')
                refetch()

                setToastMessage(
                    'Your CAC number has been verified successfully',
                )
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'VERIFY_CAC',
                    eventTrackers['sseSaveBusinessInfo'].label,
                    eventTrackers['sseSaveBusinessInfo'].category,
                )
            } else if (response.data.data.cac_server_error) {
                setVerificationFailedModal(true)
            } else {
                // check for cac <> business name mismatch
                if (
                    response?.data?.message?.includes(
                        'business name is spelt correctly',
                    )
                ) {
                    setModalToastType('error-secondary')
                    setModalToastMessage(
                        'CAC number does not match business name.',
                    )
                    setTimeout(() => {
                        setModalToastMessage('')
                    }, 5000)
                    handleModalInputError(
                        'add',
                        'businessName',
                        response?.data?.message,
                    )
                } else {
                    setToastType('error-secondary')
                    setVerificationFailedModal(true)
                }
            }
        },
        onError: error => {
            if (error?.response?.status === 429) {
                setToastType('error-secondary')
                setToastMessage(error?.response?.data?.message)
                closeModal()
            }
            errorHandler(error?.response?.data)
        },
    })

    return {
        validateCacMutation,
        setToastMessage,
        toastMessage,
        toastType,
        modalToastMessage,
        modalToastType,
    }
}

export default useVerifyCac
