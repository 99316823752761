import axios from 'src/config/axios'

export const requestPackage = (id, payload) => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/request-change`,
        payload,
    )
    return request
}

export const nudgeProviderPostApproval = async id => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/nudge`,
    )
    return request
}

export const changePackageDecision = async (id, payload) => {
    const request = axios.post(
        `/post-approval/consumers/estimations/${id}/package/decide`,
        payload,
    )
    return request
}

export const changeConsumerPackage = async (id, payload) => {
    const request = axios.post(
        `/admin/post-approval/consumers/estimations/${id}/package/change`,
        payload,
    )
    return request
}

export const notifyConsumerOfPackageChange = async id => {
    const request = axios.post(
        `/admin/post-approval/consumers/estimations/${id}/package/notify`,
    )

    return request
}
