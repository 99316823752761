import '../consumerkyc.scss'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'
import PropTypes from 'prop-types'
import arrow from 'src/assets/images/arrowDown.svg'
import SelectedSolutionCard from '../../AccountSetupOverview/components/SelectedSolutionCard'
import EnergyInfo from './EnergyInfo'
import Button from 'src/components/Button'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useTypeMapping } from '../data'
import { SelectedSolutionLoader } from './Loaders'
import KycAnalysisSummary from '../../CreateAccount/FinancialInformation/FinancialInfoForm/BvnData/KycAnalysisSummary'
import OverrideCheckModal from '../../CreateAccount/_components/OverrideCheckModal'
import AddPackageDrawer from 'src/pages/Admin/Profiles/AddPackage/components/AddPackageDrawer'
import ActionModal from 'src/components/ActionModal'
import ActionModalV2 from 'src/components/ActionModalV2'
import { useChangePackage } from '../../AccountSetupOverview/hooks/useChangePackage'
import { errorHandler } from 'src/utils/errorHandler'
import { Skeleton } from 'antd'
import PageHeading from '../../CreateAccount/_components/PageHeading'
import { useConsumerKYCContext } from '../context'
import { onePageSubmitAdminInfo } from 'src/api/shoppingExperience/kyc/api'
import {
    formatKYCPayload,
    handleMixPanelTracking,
} from '../../ConsumerKYC/utils'
import { adminActionSaveModalInitialData } from '../../CreateAccount/_components/Header/data'
import { useNotifyConsumerOfKycUpdate } from '../../CreateAccount/_components/Header/hooks/useNotifyConsumerOfKycUpdate'
import { formatPayload } from '../../CreateAccount/DynamicInformationV2/utils'
import { getUserType } from 'src/utils/getUserType'

const SelectedSolutionSection = ({
    useType,
    handleShowVideo,
    estimation,
    goBackToSystem,
    handlePackageChange,
    handleUseTypeChange,
    showPageHeading,
    inputs,
    estimationFetching,
    estimationLoadingOrRefetchError,
    disablePageButtons,
    isCustomerApproved,
    upfrontDepositPaid,
    onAdminKycSaveSuccessCallback,
    requiredKycSections,
    page,
    toggleAddPackageDrawer,
    showAddPackageDrawer,
    selectedPackage,
    setSelectedPackage,
}) => {
    const history = useHistory()
    const { id } = useParams()
    const { notifyConsumerOfUpdate } = useNotifyConsumerOfKycUpdate()

    const [isOpen, setIsOpen] = useState(Array(2).fill(false))
    const { isMobile } = useMediaQueries()
    const { isProviderAndPartnerKycAssistAllowed, isAdminUser } = getUserType()
    const [showOverrideModal, setShowOverrideModal] = useState(false)
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        action: '',
    })
    const [isAdminSavingOnePageKYC, setIsAdminSavingOnePageKYC] =
        useState(false)
    const [adminSaveActionModalData, setAdminSaveActionModalData] = useState(
        adminActionSaveModalInitialData,
    )
    const [
        showAdminSaveActionSuccessModal,
        setShowAdminSaveActionSuccessModal,
    ] = useState(false)
    const abTestInBoundRequest =
        history?.location?.state?.abTestInBoundRequest ?? false
    const {
        kycInfoFormik,
        showAdminSaveProgressModal,
        setShowAdminSaveProgressModal,
        allowPostApprovalChanges,
    } = useConsumerKYCContext()

    const isPostApprovalPackageChangeAllowed =
        allowPostApprovalChanges &&
        isCustomerApproved &&
        !(
            estimation?.application_status === 'ACTIVE' ||
            estimation?.application_status === 'SOLUTION_INSTALLED' ||
            estimation?.application_status === 'PLAN_COMPLETED' ||
            estimation?.application_status === 'ARCHIVED'
        )

    const handleOverride = () => {
        setShowOverrideModal(true)
    }

    const isChangePackageBtnDisabled =
        disablePageButtons ||
        (!isPostApprovalPackageChangeAllowed && isCustomerApproved) ||
        (isAdminUser &&
            isCustomerApproved &&
            estimation?.consumer_status !== 'REQUEST_PACKAGE_CHANGE') ||
        isProviderAndPartnerKycAssistAllowed

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            return prevOpen.map((open, i) => {
                index
                if (i === index) {
                    return !open
                }
                return false
            })
        })
    }

    const handleUpdatePackages = data => {
        setSelectedPackage(data)
        if (
            estimation?.consumer_status === 'REQUEST_PACKAGE_CHANGE' &&
            isPostApprovalPackageChangeAllowed
        ) {
            history.push({
                pathname: `/admin/plans/${id}/post-approval/package-change`,
                state: {
                    packageId: data[0]?.id,
                    paymentType: data[0]?.payment_plan_types[0],
                    estimationId: estimation?.id,
                    consumerType:
                        estimation?.survey_type === 'CFS'
                            ? 'DTC'
                            : estimation?.survey_type === 'DFS'
                            ? 'DEP'
                            : '',
                    profileId: estimation?.profileId,
                    onboardingType: estimation?.onboardingType,
                    consumerStatus: estimation?.consumer_status,
                },
            })
        } else {
            setShowActionModal({
                open: true,
                action: 'confirm',
            })
        }
    }

    const updateAdmiSaveActionModalData = update => {
        setAdminSaveActionModalData(prev => ({ ...prev, ...update }))
    }

    const getAdminUpdateSaveSuccessfulData = () => {
        return {
            modalType: 'update-successful',
            title: 'Changes saved successfully',
            actionType: 'success',
            heading: 'Changes saved successfully',
            subHeading: `You have successfully updated the basic information for has been saved successfully`,
            leftContent: 'button',
            leftButtonText: 'Notify Consumer',
            rightButtonText: 'More changes',
        }
    }

    const onKYCSaveSuccess = () => {
        setShowAdminSaveActionSuccessModal(true)
        setIsAdminSavingOnePageKYC(false)
        setShowAdminSaveProgressModal(false)
        updateAdmiSaveActionModalData(getAdminUpdateSaveSuccessfulData())
        handleMixPanelTracking({
            eventActionAndCategory: 'Assisted KYC Data submission',
            estimation,
            inputs,
            isDynamicKYC: page === 'one-page-dynamic-kyc',
        })
        kycInfoFormik?.resetForm()
        onAdminKycSaveSuccessCallback?.()
    }

    const formattedData = () => {
        let data
        if (page === 'one-page-dynamic-kyc') {
            data = formatPayload(inputs, estimation, requiredKycSections, false)
        } else {
            data = {
                ...formatKYCPayload({ ...inputs }, estimation, useType),
                submit: false,
            }
        }

        return data
    }

    const handleAdminSaveOnePageKyc = () => {
        setIsAdminSavingOnePageKYC(true)
        onePageSubmitAdminInfo({
            data: {
                ...formattedData(),
            },
            type: useType,
            estimationID: estimation.id,
        }).then(() => onKYCSaveSuccess())
    }

    const handleSendUpdateNotification = () => {
        const getAdminSendUpdateNotificationSuccessfulData = () => {
            return {
                modalType: 'notification-successful',
                title: 'Notified Consumer',
                actionType: 'success',
                heading: 'Consumer has been notified',
                subHeading: `Great work! we’ve notified ${inputs.firstName} ${inputs.lastName} about the updates that have been made`,
                leftContent: 'chat',
                rightButtonText: 'Great, Thank you',
            }
        }

        notifyConsumerOfUpdate.mutate({
            estimationId: id,
            handleSuccess: () => {
                setShowAdminSaveActionSuccessModal(true)
                updateAdmiSaveActionModalData(
                    getAdminSendUpdateNotificationSuccessfulData(),
                )
            },
        })
    }

    const { changePackage } = useChangePackage({
        onSuccess: () => {
            setShowActionModal(prev => ({
                ...prev,
                action: 'success',
            }))
        },
    })

    const onChangePackageClick = () => {
        if (disablePageButtons) {
            return () => {}
        }
        if (isAdminUser) {
            return toggleAddPackageDrawer()
        } else {
            return handlePackageChange()
        }
    }

    const dropdownOptions = [
        {
            title:
                isAdminUser || isProviderAndPartnerKycAssistAllowed
                    ? 'Package Details'
                    : 'Your Package Details',
            component: (
                <SelectedSolutionCard
                    estimation={estimation}
                    goBackToSystem={goBackToSystem}
                    planType={estimation?.payment_plan}
                    handlePackageChange={handlePackageChange}
                    systemAccepted={
                        estimation?.consumer_status ===
                        'CONSUMER_ACCEPT_PACKAGE'
                    }
                    isCustomerApproved={isCustomerApproved}
                    upfrontDepositPaid={upfrontDepositPaid}
                    hidePackageInfoBtn={isProviderAndPartnerKycAssistAllowed}
                />
            ),
        },
        {
            title:
                isAdminUser || isProviderAndPartnerKycAssistAllowed
                    ? 'Energy Info'
                    : 'Your Energy Info',
            component: (
                <EnergyInfo
                    energyInfo={estimation?.energy_info}
                    paymentPlan={estimation?.payment_plan}
                />
            ),
        },
    ]

    const DetailsDropdown = ({ title, index, children }) => {
        return (
            <div className="DetailsDropdownContainer">
                <div
                    className={`DetailsDropdown ${
                        isOpen[index] ? 'isOpen' : ''
                    }`}
                    onClick={() => toggleBox(index)}
                >
                    <p>{title}</p>
                    <div>
                        <img
                            src={arrow}
                            alt="arrow"
                            className={
                                isOpen[index]
                                    ? 'TopArrowOpen'
                                    : 'TopArrowClosed'
                            }
                        />
                    </div>
                </div>
                {isOpen[index] && <>{children}</>}
            </div>
        )
    }

    DetailsDropdown.propTypes = {
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        children: PropTypes.node,
    }

    return (
        <div className="LeftContainerSection">
            {showPageHeading && (
                <PageHeading
                    handleShowVideo={handleShowVideo}
                    goBackToSystem={goBackToSystem}
                    showSaveProgressModal={showAdminSaveProgressModal}
                    setShowSaveProgressModal={setShowAdminSaveProgressModal}
                    kycInfoFormik={kycInfoFormik}
                    isCustomerApproved={isCustomerApproved}
                />
            )}
            <OverrideCheckModal
                showOverrideModal={showOverrideModal}
                type="BVN"
                onCancel={() => {
                    setShowOverrideModal(false)
                }}
            />
            <div className="Container">
                <div className="SelectedSolutionContainer">
                    <div className="SelectedSolutionNav">
                        {isAdminUser ? (
                            <h5>Selected Solution</h5>
                        ) : (
                            <h5>Your Selected Solution</h5>
                        )}
                        {!abTestInBoundRequest && (
                            <div
                                className={`ASOTextIconBtn ${
                                    isChangePackageBtnDisabled
                                        ? 'ASOTextIconBtn_Disabled'
                                        : ''
                                }`}
                                role={'button'}
                                onClick={() => {
                                    if (!isChangePackageBtnDisabled) {
                                        onChangePackageClick()
                                    }
                                }}
                            >
                                <LightBulb
                                    role={'presentation'}
                                    className={'ASOSSvg'}
                                />
                                <p>Change Package</p>
                            </div>
                        )}
                    </div>

                    {estimationFetching || estimationLoadingOrRefetchError ? (
                        <SelectedSolutionLoader />
                    ) : (
                        <div>
                            {dropdownOptions.map((option, index) => (
                                <DetailsDropdown
                                    title={option.title}
                                    key={index}
                                    index={index}
                                >
                                    {option.component}
                                </DetailsDropdown>
                            ))}
                        </div>
                    )}
                    {!isAdminUser && (
                        <p className="Questions">
                            Have questions? <span>Chat with us</span>
                        </p>
                    )}
                </div>

                {!isAdminUser &&
                    !estimation?.isDynamicKycUser &&
                    !isProviderAndPartnerKycAssistAllowed && (
                        <div className="ChangeUseType">
                            <p>
                                Is this solution for{' '}
                                {useTypeMapping[useType] === 'residential'
                                    ? 'business'
                                    : 'residential'}{' '}
                                purposes?
                            </p>

                            {estimationFetching ? (
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{
                                        height: 29,
                                        width: 40,
                                    }}
                                />
                            ) : (
                                <Button
                                    btnBgColor={'#004AAD'}
                                    btnTextColor={'#fff'}
                                    btnWidth={isMobile ? '186px' : '140px'}
                                    btnHeight={'29px'}
                                    fontFamily={'SF-Pro-Display-Medium'}
                                    handleClick={handleUseTypeChange}
                                    disabled={isCustomerApproved}
                                >
                                    Switch to{' '}
                                    {useTypeMapping[useType] === 'residential'
                                        ? 'business'
                                        : 'residential'}
                                </Button>
                            )}
                        </div>
                    )}

                {isAdminUser && (
                    <KycAnalysisSummary
                        title="KYC ANALYSIS SUMMARY"
                        handleOverride={handleOverride}
                        inputs={inputs}
                    />
                )}
                {isAdminUser && (
                    <AddPackageDrawer
                        selectedPackages={selectedPackage}
                        showAddPackageModal={showAddPackageDrawer}
                        setShowAddPackageModal={toggleAddPackageDrawer}
                        handleAddPackagesClick={handleUpdatePackages}
                        isOnePackage
                        isPostApprovalPackageChange={
                            estimation?.consumer_status ===
                            'REQUEST_PACKAGE_CHANGE'
                        }
                    />
                )}
                {isAdminUser && (
                    <ActionModal
                        actionModalOpen={showActionModal?.open}
                        headerText={
                            showActionModal?.action === 'success'
                                ? 'Consumer package changed'
                                : 'You’re about to change the package'
                        }
                        subTitle={
                            showActionModal?.action === 'success' ? (
                                <>
                                    You have successfully changed the consumer’s
                                    selected package from{' '}
                                    <span
                                        style={{
                                            color: '#233862',
                                            fontFamily: 'SF-Pro-Display-Medium',
                                        }}
                                    >
                                        {estimation?.system_name}
                                    </span>{' '}
                                    to{' '}
                                    <span
                                        style={{
                                            color: '#233862',
                                            fontFamily: 'SF-Pro-Display-Medium',
                                        }}
                                    >
                                        {selectedPackage[0]?.name}
                                    </span>
                                    .
                                </>
                            ) : (
                                <>
                                    Kindly confirm you want to change the
                                    selected package of this consumer from{' '}
                                    <span
                                        style={{
                                            color: '#233862',
                                            fontFamily: 'SF-Pro-Display-Medium',
                                        }}
                                    >
                                        {estimation?.system_name}
                                    </span>{' '}
                                    to{' '}
                                    <span
                                        style={{
                                            color: '#233862',
                                            fontFamily: 'SF-Pro-Display-Medium',
                                        }}
                                    >
                                        {selectedPackage?.[0]?.name}
                                    </span>
                                    .
                                </>
                            )
                        }
                        actionType={
                            showActionModal?.action === 'success'
                                ? 'success'
                                : 'warning'
                        }
                        noCancelBtn={showActionModal?.action === 'success'}
                        actionText={
                            showActionModal?.action === 'success'
                                ? 'Okay, Got it'
                                : 'Confim'
                        }
                        cancelText={'Go Back'}
                        actionHandler={() => {
                            if (showActionModal?.action === 'confirm') {
                                changePackage.mutate({
                                    id: estimation.id,
                                    payload: {
                                        dtc: {
                                            id: selectedPackage[0]?.id,
                                            appliances:
                                                selectedPackage[0]?.appliances,
                                            ...(selectedPackage[0]
                                                ?.payment_plan_types?.length >
                                                0 && {
                                                payment_plan_types:
                                                    selectedPackage[0]
                                                        ?.payment_plan_types,
                                            }),
                                            ...(selectedPackage[0]
                                                ?.subscription_cluster_code !==
                                                '' && {
                                                subscription_cluster_code:
                                                    selectedPackage[0]
                                                        ?.subscription_cluster_code,
                                            }),
                                            ...(selectedPackage[0]
                                                ?.lease_to_own_cluster_code !==
                                                '' && {
                                                lease_to_own_cluster_code:
                                                    selectedPackage[0]
                                                        ?.lease_to_own_cluster_code,
                                            }),
                                        },
                                    },
                                })
                            }

                            if (showActionModal?.action === 'success') {
                                setShowActionModal({
                                    open: false,
                                    action: '',
                                })

                                toggleAddPackageDrawer()
                            }
                        }}
                        closable={false}
                        closeModal={() =>
                            setShowActionModal({
                                open: false,
                                action: 'success',
                            })
                        }
                        toastError={changePackage.isError}
                        errorMessage={errorHandler(
                            changePackage.error?.response?.data ||
                                changePackage.error?.message,
                        )}
                        loading={changePackage.isLoading}
                    />
                )}
                {isAdminUser && (
                    <ActionModalV2
                        title={'Save your progress'}
                        actionModalOpen={showAdminSaveProgressModal}
                        actionType={'warning'}
                        headerText={'Save your progress? Please confirm'}
                        subTitle={`You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue`}
                        primaryBtnLoading={isAdminSavingOnePageKYC}
                        primaryBtnText={'Save changes'}
                        handlePrimaryBtnClick={handleAdminSaveOnePageKyc}
                        v2
                        onCancel={() => setShowAdminSaveProgressModal(false)}
                        footerLeftContent={'button'}
                        secondaryBtnText={"Don't save"}
                        handleSecondaryBtnClick={() => history.goBack()}
                        kycSecondaryButtonStopLoading
                    />
                )}
                {isAdminUser && (
                    <ActionModalV2
                        title={adminSaveActionModalData.title}
                        actionModalOpen={showAdminSaveActionSuccessModal}
                        actionType={adminSaveActionModalData.actionType}
                        headerText={adminSaveActionModalData.heading}
                        subTitle={adminSaveActionModalData.subHeading}
                        primaryBtnText={
                            adminSaveActionModalData.rightButtonText
                        }
                        handlePrimaryBtnClick={() => {
                            setShowAdminSaveActionSuccessModal(false)
                            setAdminSaveActionModalData(
                                adminActionSaveModalInitialData,
                            )
                        }}
                        v2
                        onCancel={() => {
                            setShowAdminSaveActionSuccessModal(false)
                            setAdminSaveActionModalData(
                                adminActionSaveModalInitialData,
                            )
                        }}
                        footerLeftContent={adminSaveActionModalData.leftContent}
                        secondaryBtnText={
                            adminSaveActionModalData.leftButtonText
                        }
                        handleSecondaryBtnClick={() => {
                            handleSendUpdateNotification()
                        }}
                        kycSecondaryButtonStopLoading
                    />
                )}
            </div>
        </div>
    )
}

SelectedSolutionSection.propTypes = {
    useType: PropTypes.string,
    handleShowVideo: PropTypes.func,
    estimation: PropTypes.object,
    goBackToSystem: PropTypes.func,
    handlePackageChange: PropTypes.func,
    handleEditInfo: PropTypes.func,
    handleUseTypeChange: PropTypes.func,
    showPageHeading: PropTypes.bool,
    inputs: PropTypes.object,
    estimationFetching: PropTypes.bool,
    estimationLoadingOrRefetchError: PropTypes.bool,
    disablePageButtons: PropTypes.bool,
    isCustomerApproved: PropTypes.bool,
    upfrontDepositPaid: PropTypes.bool,
    onAdminKycSaveSuccessCallback: PropTypes.func,
    requiredKycSections: PropTypes.array,
    page: PropTypes.string,
    toggleAddPackageDrawer: PropTypes.func,
    showAddPackageDrawer: PropTypes.bool,
    selectedPackage: PropTypes.array,
    setSelectedPackage: PropTypes.func,
}

export default SelectedSolutionSection
