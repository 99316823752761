import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import { logoutApi } from '../../api/logout'
import { getToken, removeToken } from '../../utils/auth.js'
import ActionModal from '../ActionModal'

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

const AppLogout = ({ children }) => {
    let timer
    let countdownTimer

    // this function sets the timer that displays a modal after 3mins
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            // clears any pending timer.
            resetTimer()
            // Listener clean up. Removes the existing event listener from the window
            events.forEach(item => {
                window.removeEventListener(item, resetTimer)
            })

            // show modal
            setShowModal(true)
            startCountDown()
        }, 180000)
    }

    const startCountDown = () => {
        countdownTimer = setTimeout(() => logoutAction(), 30000)
    }

    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) clearTimeout(timer)
        if (countdownTimer) clearTimeout(countdownTimer)
    }

    useEffect(() => {
        events.forEach(item => {
            window.addEventListener(item, () => {
                resetTimer()
                handleLogoutTimer()
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // logs out user by calling the logoutApi, clearing out token in localStorage and redirecting url to /consumer/login.
    const logoutAction = () => {
        refetch()
    }

    const { isFetching, refetch } = useQuery(
        'logout',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                window.location.href = `/consumer/login`
                removeToken()
            },
        },
    )

    const [showModal, setShowModal] = useState(false)

    const handleYes = () => {
        setShowModal(false)
        resetTimer()
    }

    const handleModalLogOut = () => {
        logoutAction()
        setShowModal(false)
    }

    return (
        <>
            <ActionModal
                actionModalOpen={showModal}
                headerText="Are you still there?"
                subTitle="Let’s pick up where you left off!"
                closeModal={handleModalLogOut}
                actionHandler={handleYes}
                onCancel={() => setShowModal(false)}
                actionType="warning"
                actionText="Yes, I’m Here"
                cancelText="Log Me Out"
                loading={isFetching}
                actionHeaderClassName="ActionHeader"
                noBackLink
            />
            {children}
        </>
    )
}

AppLogout.propTypes = {
    children: PropTypes.any,
}

export default AppLogout
