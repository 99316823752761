import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import Confirmation from '../../../components/Confirmation'
import errorImage from '../../../assets/images/deletemodal-icon.svg'
import { logoutApi } from '../../../api/logout'
import { removeToken, getToken } from '../../../utils/auth.js'

const LoginStatus = () => {
    const history = useHistory()
    const [cfsEstimation, setCfsEstimation] = useState(false)
    const [noRepaymentSchedule, setNoRepaymentSchedule] = useState(false)
    useEffect(() => {
        if (history?.location?.state?.consumer) {
            setCfsEstimation(history?.location?.state?.consumer?.no_estimation)
            setNoRepaymentSchedule(
                history?.location?.state?.consumer?.no_repayment_schedule,
            )
            // Clear the state of the history
            const state = {}
            history.replace({ ...history.location, state })
        } else {
            history.push('/consumer/welcome')
        }
    }, [history])

    const { refetch: logoutAction } = useQuery(
        'logout',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                removeToken()
                history.push('/')
            },
        },
    )

    const onButtonClick = () => {
        logoutAction()
    }

    return (
        <>
            {cfsEstimation && (
                <Confirmation
                    imgUrl={errorImage}
                    header="Update Application"
                    subHeader="You currently do no have any offer with us. If this is not meant to be so, please reach out to hello@sunfi.co."
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Go Home"
                />
            )}
            {noRepaymentSchedule && (
                <Confirmation
                    imgUrl={errorImage}
                    header="Repayment Schedule"
                    subHeader="You currently do no have any offer with us. If this is not meant to be so, please reach out to hello@sunfi.co."
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Go Home"
                />
            )}
        </>
    )
}

export default LoginStatus
