import PropTypes from 'prop-types'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import UploadField from 'src/components/InputFields/UploadField'
import { businessIndustry } from 'src/utils/business'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import { decodeUserInfo } from 'src/utils/auth'
import { isMobile } from 'src/utils/mediaQueries'
import { convertArrayOfStringsToObjectOfBooleans } from '../../utils'

const BusinessDetails = ({
    isBusiness,
    isResidential,
    inputs,
    setInputs,
    errors,
    cacVerificationInProgress,
    handleInputChange,
    handleSelectChange,
    handleSelectOnBlur,
    handleBlur,
    handleCacVerifyModal,
    handleRemoveFile,
    moa,
    setMoa,
    handleUploadClick,
    removeFromErrObj,
    isCustomerApproved,
    onInputFocus,
    onSelectFocus,
    providerUpdatedFieldsData,
    isDEPConsumer,
}) => {
    const showBusinessFields =
        (isBusiness && inputs?.businessType !== '') ||
        (isResidential && inputs?.employmentType === 'Self Employed')

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const basicInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.basicInformation || [],
        )
    const businessInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.businessInformation || [],
        )

    if (!showBusinessFields) return null

    return (
        <div className="BusinessDetails">
            {((isBusiness && inputs?.businessType?.length > 0) ||
                (isResidential &&
                    inputs?.employmentType === 'Self Employed')) && (
                <>
                    {isResidential && inputs?.isBusinessRegistered !== null && (
                        <InputFields
                            title="Business name"
                            value={inputs?.businessName}
                            name="businessName"
                            handleChange={handleInputChange}
                            onBlur={handleBlur}
                            disabled={isCustomerApproved}
                            onFocus={onInputFocus}
                            showUpdateDot={
                                isDEPConsumer &&
                                (basicInformationUpdatedFields.businessName ||
                                    businessInformationUpdatedFields.businessName)
                            }
                        />
                    )}

                    {((inputs.employmentType === 'Self Employed' &&
                        inputs?.isBusinessRegistered !== null) ||
                        isBusiness) && (
                        <>
                            <SelectField
                                floatLabelOnSelectedValue
                                name="businessIndustry"
                                floatingLabel="Business Industry"
                                initialOption="Business Industry"
                                selectWidth="100%"
                                handleChange={handleSelectChange}
                                onBlur={() =>
                                    handleSelectOnBlur('businessIndustry')
                                }
                                values={businessIndustry.map(option => ({
                                    value: option,
                                }))}
                                value={inputs?.businessIndustry}
                                showSearch
                                dropdownPositionRelative
                                withCheckBox
                                currentSelected={inputs?.businessIndustry}
                                selectedValue={inputs?.businessIndustry}
                                marginBottom="24px"
                                disabled={isCustomerApproved}
                                onFocus={onSelectFocus}
                                showUpdateDot={
                                    isDEPConsumer &&
                                    (basicInformationUpdatedFields.businessIndustry ||
                                        businessInformationUpdatedFields.businessIndustry)
                                }
                            />
                        </>
                    )}

                    {((isBusiness && inputs?.businessType?.length > 0) ||
                        (!isBusiness &&
                            inputs?.employmentType === 'Self Employed')) && (
                        <>
                            {(isBusiness ||
                                (!isBusiness &&
                                    inputs?.employmentType ===
                                        'Self Employed' &&
                                    inputs?.isBusinessRegistered)) && (
                                <InputFieldWithButton
                                    title="CAC"
                                    btnText="Verify CAC number"
                                    type="text"
                                    name="cacNumber"
                                    mobileBtnText="Verify"
                                    value={inputs?.cacNumber}
                                    handleChange={handleInputChange}
                                    onBlur={handleBlur}
                                    disabled={
                                        inputs?.cacNumberVerified ||
                                        cacVerificationInProgress ||
                                        isAdminUser ||
                                        isCustomerApproved
                                    }
                                    verified={inputs?.cacNumberVerified}
                                    showBtn={!inputs?.cacNumberVerified}
                                    progressText="Verification in Progress"
                                    inprogress={cacVerificationInProgress}
                                    btnDisabled={
                                        inputs?.cacNumberVerified ||
                                        inputs?.businessName === '' ||
                                        inputs?.cacNumber === '' ||
                                        errors?.cacNumber ||
                                        isAdminUser ||
                                        isCustomerApproved
                                    }
                                    onBtnClick={handleCacVerifyModal}
                                    showUpdateDot={
                                        isDEPConsumer &&
                                        (basicInformationUpdatedFields.cacNumber ||
                                            businessInformationUpdatedFields.cacNumber)
                                    }
                                />
                            )}

                            {isBusiness &&
                                inputs?.businessType !==
                                    'Non-governmental Organisation' && (
                                    <UploadField
                                        width="100%"
                                        btnText="Memorandum of association"
                                        handleUploadClick={() => {
                                            handleUploadClick(
                                                'memorandum_of_association',
                                            )
                                            removeFromErrObj('moa')
                                        }}
                                        handleRemoveClick={() => {
                                            if (inputs.moa_url !== undefined) {
                                                handleRemoveFile(
                                                    'MOA',
                                                    inputs.moa_url,
                                                )
                                            } else {
                                                setMoa([])
                                                setInputs(prev => ({
                                                    ...prev,
                                                    moa_url: undefined,
                                                }))
                                            }
                                        }}
                                        fileUploaded={
                                            inputs?.moa_url?.length > 0 ||
                                            moa?.length > 0
                                        }
                                        fileName={extractFileNameFromUrl(
                                            inputs?.moa_url,
                                        )}
                                        fileUrl={inputs?.moa_url}
                                        margin={'0 0 32px 0'}
                                        errorMessage={errors.moa_url}
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                    />
                                )}

                            {((!isBusiness &&
                                inputs?.employmentType === 'Self Employed' &&
                                inputs?.isBusinessRegistered !== null) ||
                                isBusiness) && (
                                <TextArea
                                    title={
                                        inputs.businessDescription
                                            ? 'Description of business'
                                            : ''
                                    }
                                    placeholder="Tell us about your business (example: how many years of operations, if you have a website, etc.)"
                                    name="businessDescription"
                                    value={inputs.businessDescription}
                                    handleChange={handleInputChange}
                                    onBlur={handleBlur}
                                    height="159px"
                                    resize={false}
                                    width={isMobile ? 327 : 385}
                                    disabled={isCustomerApproved}
                                    onFocus={onInputFocus}
                                    showUpdateDot={
                                        isDEPConsumer &&
                                        (basicInformationUpdatedFields.businessDescription ||
                                            businessInformationUpdatedFields.businessDescription)
                                    }
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

BusinessDetails.propTypes = {
    isBusiness: PropTypes.bool.isRequired,
    isResidential: PropTypes.bool.isRequired,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    errors: PropTypes.object,
    businessIndustry: PropTypes.string,
    cacVerificationInProgress: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleSelectOnBlur: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleCacVerifyModal: PropTypes.func.isRequired,
    removeFromErrObj: PropTypes.func.isRequired,
    handleRemoveFile: PropTypes.object,
    moa: PropTypes.array.isRequired,
    setMoa: PropTypes.func.isRequired,
    handleUploadClick: PropTypes.func.isRequired,
    isCustomerApproved: PropTypes.func,
    onInputFocus: PropTypes.func,
    onSelectFocus: PropTypes.func,
    providerUpdatedFieldsData: PropTypes.object,
    isDEPConsumer: PropTypes.bool,
}

export default BusinessDetails
