/* eslint-disable react/prop-types */
import { encrypt, decrypt } from './cryptography'
import { decodeUserInfo } from './auth'

const userInfo = decodeUserInfo()
const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

export const decryptUserPermissions = () => {
    const getUserPermission = JSON.parse(
        localStorage.getItem('userPermissions'),
    )

    return decrypt(getUserPermission)
}

export const applyDynamicPermissionsToProviderUser =
    !isAdminWorkspace &&
    process.env.REACT_APP_FLAG_ENFORCE_PERMISSION === 'true'

export const applyDynamicPermissionsToAdminUser =
    isAdminWorkspace &&
    process.env.REACT_APP_FLAG_ENFORCE_ADMIN_PERMISSION === 'true'

export const permissionsControl = requiredPermissions => {
    // decrypt user permission
    const decryptedUserPermissions = decryptUserPermissions()?.split(',')

    const hasAccess = requiredPermissions?.every(permission =>
        decryptedUserPermissions?.includes(permission),
    )

    return applyDynamicPermissionsToProviderUser ||
        applyDynamicPermissionsToAdminUser
        ? hasAccess
        : true
}

export const encrptUserPermissions = data => {
    const permissions = data?.data?.data?.permission_data?.map(
        permission => permission.permission,
    )
    const encryptedPermissions = encrypt(permissions)
    localStorage.setItem(
        'userPermissions',
        JSON.stringify(encryptedPermissions),
    )
}
