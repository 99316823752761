import { useState } from 'react'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateTenureChangeRequestForm } from 'src/utils/validationSchema'
import useRequestTenureChange from './useRequestTenureChange'
import useDecideOnTenureChange from './useDecideOnTenureUpdate'
import { useQueryClient } from 'react-query'
import { compareTenures } from '../utils'

const useTenureChangeModal = ({
    tenureChangeAction,
    setTenureChangeAction,
    closeTenureChangeModal,
    estimationId,
    planWithUpdatedTenure,
}) => {
    const [inputs, setInputs] = useState({
        reason: '',
        note: '',
    })
    const [errors, setErrors] = useState({})
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [modalToast, setModalToast] = useState(toastData)
    const validateField = useValidateOnBlur()
    const queryClient = useQueryClient()
    const comparedTenures = compareTenures(planWithUpdatedTenure)

    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }
    const { requestTenureChangeMutate, requestTenureChangeLoading } =
        useRequestTenureChange({
            setModalToast,
            resetToast,
            onSuccess: () => {
                setTenureChangeAction('request-sent-successfully')
                queryClient.invalidateQueries('sse-consumer-estimation')
            },
        })

    const { decideOnTenureChangeMutate, decideOnTenureChangeLoading } =
        useDecideOnTenureChange({
            setModalToast,
            resetToast,
            onSuccess: () => {
                if (tenureChangeAction === 'reject-new-tenure') {
                    setTenureChangeAction('tenure-rejected-successfully')
                } else {
                    setTenureChangeAction('tenure-accepted-successfully')
                }
                queryClient.invalidateQueries('sse-consumer-estimation')
            },
        })

    const schemaContext = {
        noteRequired: inputs?.reason === 'Others',
    }

    const handleRequestSubmit = () => {
        validateTenureChangeRequestForm
            .validate(inputs, {
                context: schemaContext,
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                let payload = {
                    reason:
                        inputs?.reason === 'Others'
                            ? inputs.note
                            : inputs.reason,
                }
                if (tenureChangeAction === 'enter-request-reason') {
                    requestTenureChangeMutate({ estimationId, payload })
                } else {
                    payload.decision = 'REJECT'
                    decideOnTenureChangeMutate({
                        estimationId,
                        payload,
                    })
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const acceptPayload = {
        decision: 'ACCEPT',
    }

    const handlePrimaryBtnClick = () => {
        switch (tenureChangeAction) {
            case 'confirm-change-request':
                setTenureChangeAction('enter-request-reason')
                break
            case 'enter-request-reason':
                handleRequestSubmit()
                break
            case 'request-sent-successfully':
                onCancel()
                break
            case 'admin-updated-tenure-notification':
                setTenureChangeAction('compare-tenures')
                break
            case 'compare-tenures':
                decideOnTenureChangeMutate({
                    estimationId,
                    payload: acceptPayload,
                })
                break
            case 'tenure-accepted-successfully':
                onCancel()
                break
            case 'reject-new-tenure':
                handleRequestSubmit()
                break
            case 'tenure-rejected-successfully':
                onCancel()
                break
        }
    }

    const handleSecondaryBtnClick = () => {
        switch (tenureChangeAction) {
            case 'compare-tenures':
                setTenureChangeAction('reject-new-tenure')
                break
        }
    }

    const onCancel = () => {
        closeTenureChangeModal()
        setInputs({
            reason: '',
            note: '',
        })
        setErrors({})
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateTenureChangeRequestForm,
            setErrors,
            context: schemaContext,
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateTenureChangeRequestForm,
            setErrors,
            context: schemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    const primaryBtnLoading =
        requestTenureChangeLoading || decideOnTenureChangeLoading

    return {
        handlePrimaryBtnClick,
        onCancel,
        inputs,
        errors,
        handleOnBlur,
        handleSelectOnBlur,
        handleChange,
        handleSelectChange,
        modalToast,
        primaryBtnLoading,
        handleSecondaryBtnClick,
        comparedTenures,
    }
}

export default useTenureChangeModal
