import s from '../../paymentPlanDetails.module.scss'
import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'
import FormTitleBar from 'src/components/FormTitleBar'
import { SelectField } from 'src/components/InputFields'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const UpdateTenureModal = ({
    showModal,
    closeModal,
    selectedNewTenure,
    setSelectedNewTenure,
    formattedTenures,
    handleUpdateTenure,
}) => {
    const handleSelectChange = (name, value) => {
        setSelectedNewTenure(value)
    }

    const onCancel = () => {
        closeModal()
    }
    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'624px'}
            content={
                <div className={s.UpdateTenureModal_Wrapper}>
                    <VerticalSpacer top={48} />
                    <FormTitleBar
                        title={'Request to update tenure'}
                        subtitle={'Select a new plan tenure to continue'}
                    />
                    <VerticalSpacer top={52} />
                    <SelectField
                        selectWidth={'100%'}
                        floatingLabel={'Select Plan Duration'}
                        name={'tenure'}
                        values={formattedTenures?.map(option => ({
                            value: option,
                        }))}
                        initialOption="Select Plan Duration"
                        value={selectedNewTenure}
                        selectedValue={selectedNewTenure}
                        currentSelected={selectedNewTenure}
                        floatLabelOnSelectedValue
                        withCheckBox
                        dropdownPositionRelative
                        handleChange={handleSelectChange}
                        marginRight={'80px'}
                    />
                    <VerticalSpacer top={136} />
                    <div className={s.UpdateTenureModal_Btn}>
                        <ButtonComponent
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnBgColor={'#004aad'}
                            btnTextColor={'#ffffff'}
                            handleClick={handleUpdateTenure}
                            disabled={selectedNewTenure === ''}
                        >
                            Update Plan
                        </ButtonComponent>
                    </div>
                    <VerticalSpacer top={72} />
                </div>
            }
        />
    )
}

UpdateTenureModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    selectedNewTenure: PropTypes.string,
    setSelectedNewTenure: PropTypes.func,
    formattedTenures: PropTypes.array,
    handleUpdateTenure: PropTypes.bool,
}

export default UpdateTenureModal
