//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import React, { useState, useEffect, Fragment } from 'react'
import { format } from 'date-fns'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Skeleton, Carousel, Pagination } from 'antd'
import FloatingBox from 'src/components/FloatingBox'
import ConsumerHelpCenter from 'src/components/HelpCenter/ConsumerHelpCenter/index'
import QuestionMarkIcon from 'src/assets/images/questionMark.svg'
import checkedStepperImg from 'src/assets/images/checked.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import Invoice from './Invoice'
import EnergySolution from './EnergySolution'
import overviewNoActivity from 'src/assets/images/overviewNoActivity.svg'
import Toast from 'src/components/Toast'
import styles from './repaymentschedule.module.scss'
import { getToken } from 'src/utils/auth'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import {
    getRepaymentScheduleApi,
    getRepaymentScheduleStatsApi,
    getConsumerLatestOffer,
    getRepaymentScheduleByIdApi,
    getRepaymentScheduleStatsByIdApi,
} from 'src/api/consumer/repaymentSchedule'
import SeoComponent from 'src/components/Seo'
import PayStack from 'src/pages/Consumer/SmartShoppingExperience/CreditDecision/components/PayStack'
import { InlineLoader } from 'src/components/Loader'
import AppLogout from 'src/components/AppLogout'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import BackNav from 'src/components/BackNav'
import NairaSymbol from 'src/components/NairaSymbol'
import { ConsumerButton } from 'src/components/Button'
import Buyout from 'src/components/Buyout/Buyout'
import { useCalculateBuyoutAmount } from 'src/api/buyout/queries'
import { removeQueryParamFromUrl } from 'src/utils/urlTracker'
import ActionModalV2 from 'src/components/ActionModalV2'
import { closeSubscriptionPlan } from 'src/api/shoppingExperience/account-overview'

const DAYS_BEFORE_EXPIRED = 2

const RepaymentSchedule = () => {
    const {
        Wrapper,
        Heading,
        SubHeading,
        Dashboard,
        DashboardWrapper,
        DashboardTitle,
        DashboardDateWrapper,
        DashboardDate,
        DashboardSubtitle,
        PaymentBreakdownWrapper,
        PaymentBreakdownTitle,
        StepWrapper,
        ActiveStepper,
        DefaultStepper,
        GreenVerticalLine,
        DefaultVerticalLine,
        checkedStepperWrapper,
        ContentWrapper,
        TableWrapper,
        RowWrapper,
        RowColumnOne,
        TableData,
        TableBtnWrapper,
        TableBtn,
        TableBtnShortPaid,
        TableBtnSecondary,
        TableBtnContainer,
        RowColumnOneFirst,
        RowColumnOneSecond,
        PaidStatus,
        BuyoutStatus,
        DueStatus,
        TableDate,
        NoActivityWrapper,
        EmptyHeading,
        EmptyText,
        TopLeft,
        TopRight,
        SkeletonWrapper,
        TopSkeletonWrapper,
        MiddleSkeletonWrapper,
        MiddleTopColumn,
        MiddleBottomColumn,
        MiddleRow,
        SkeletonMiddle,
        BottomSkeletonWrapper,
        BottomRow,
        BottomSmallColumn,
        BottomColumn,
        BottomColumn1,
        BottomColumn2,
        BottomColumn3,
        BottomColumn4,
        MobileDashboardWrapper,
        CarouselWrapper,
        MobileHide,
        DesktopHide,
        ConsumerFloatBox,
    } = styles

    const [showModal, setShowModal] = useState(false)
    const [showClosePlanModal, setShowClosePlanModal] = useState(false)
    const [showClosePlanSuccessModal, setShowClosePlanSuccessModal] =
        useState(false)
    const [showBuyoutModal, setShowBuyoutModal] = useState(false)
    const [buyout, setBuyout] = useState(false)
    const [showEnergyModal, setShowEnergyModal] = useState(false)
    const [repaymentStat, setRepaymentStat] = useState({})
    const [tableData, setTableData] = useState([])
    const [invoiceData, setInvoiceData] = useState({})
    const [paidLength, setPaidLength] = useState(0)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [repaymentMethod, setRepaymentMethod] = useState('')
    const [energySolutionData, setEnergySolutionData] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [paystackLoading, setPaystackLoading] = useState(false)
    const [showPayStack, setShowPaystack] = useState(false)
    const [estimationId, setEstimationId] = useState('')
    const [consumerEmail, setConsumerEmail] = useState('')
    const [consumerId, setConsumerId] = useState('')
    const [upfrontDepositPaid, setUpfrontDepositPaid] = useState('')
    const [repaymentMonth, setRepaymentMonth] = useState(null)
    const [isPromoCustomer, setIsPromoCustomer] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [preBuyoutModal, setPreBuyoutModal] = useState(false)
    const [callInitializePayment, setCallInitializePayment] = useState(true)
    const [isUpfrontDepositRequired, setIsUpfrontDepositRequired] =
        useState(false)

    let history = useHistory()
    const queryParams = new URLSearchParams(window.location.search)
    const planEstimationId = queryParams.get('id')
    const planName = queryParams.get('name')
    const isBuyout = queryParams.get('buyout')
    const consumerCanPayAnyMonth =
        process.env.REACT_APP_FLAG_ENABLE_PAY_NOW_FOR_ALL_CONSUMER_REPAYMENT ===
        'true'
    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
    }
    const paginationRequest = () => {
        return tableData?.length > 0 ? paginationBlock() : ''
    }
    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={1}
                total={parseInt(totalPages, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePage(value)}
            />
        )
    }

    //  return nth_days back from the date parameter
    //  e.g getNthDaytoDate('11/5/2022', 2) return 2 days back from the passed date
    //  that is '11/3/2022
    const getNthDaytoDate = (date, nthDays = 2) => {
        var result = date?.setDate(date?.getDate() - nthDays)
        return new Date(result)
    }

    const isSubscriptionPlan =
        energySolutionData?.payment_plan === 'Subscription'

    const dashboardData = [
        {
            id: 0,
            name: isSubscriptionPlan ? 'Package' : 'Total Outstanding',
            amount: isSubscriptionPlan
                ? energySolutionData?.recommended_solution?.package_name
                : `${currencyFormatter(repaymentStat.total_outstanding)}`,
            isMoney: true,
        },
        {
            id: 1,
            name: isSubscriptionPlan ? 'NEXT PAYMENT' : 'NEXT REPAYMENT',
            amount: `${
                repaymentStat.next_repayment_amount === null
                    ? '0.00'
                    : repaymentStat?.next_repayment_amount ||
                      currencyFormatter(repaymentStat.next_repayment_amount)
            }`,
            isMoney: true,

            date:
                !isSubscriptionPlan &&
                ![undefined, null].includes(
                    repaymentStat.next_repayment_date,
                ) &&
                format(
                    new Date(repaymentStat?.next_repayment_date),
                    'd, MMM yyyy',
                ),
        },
        {
            id: 2,
            name: isSubscriptionPlan ? 'NEXT PAYMENT DATE' : 'remaining months',
            isMoney: false,
            amount: isSubscriptionPlan
                ? [null, undefined].includes(repaymentStat?.next_repayment_date)
                    ? '-'
                    : format(
                          new Date(
                              repaymentStat?.next_repayment_date ||
                                  '01/01/1990',
                          ),
                          'd, MMM yyyy',
                      )
                : repaymentStat.remaining_months,
        },
    ]

    const handleViewInvoice = data => {
        setInvoiceData(data)
        setShowModal(true)
    }
    const pageTitle = 'SunFi - Consumer Login | Repayment Schedule'
    const pageUrlName = window.location.pathname
    const pageTracker = 'consumer_repayment_schedule'

    const ViewEnergySolution = () => {
        setShowEnergyModal(true)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['viewConsumerSolution'].action,
            eventTrackers['viewConsumerSolution'].label,
            eventTrackers['viewConsumerSolution'].category,
            ['MP', 'GA'],
            'event',
            {
                event: energySolutionData?.recommended_solution,
            },
        )
    }

    let token = getToken()

    const {
        refetch: repaymentScheduleStatsRefetch,
        isFetching: repaymentScheduleStatsLoading,
    } = useQuery(
        'getRepaymentScheduleStatsApi',
        () =>
            planName
                ? getRepaymentScheduleStatsByIdApi(planEstimationId)
                : getRepaymentScheduleStatsApi(token),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setRepaymentStat(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(
                    'Failed to fetch Payment Breakdown. Refresh page',
                )
            },
        },
    )

    const { refetch: closePlan, isFetching: isClosingPlan } = useQuery(
        'closeSubscriptionPlan',
        () => closeSubscriptionPlan(planEstimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setShowClosePlanSuccessModal(true)
                setShowClosePlanModal(false)
                repaymentScheduleRefetch()
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to close repayment plan')
            },
        },
    )

    const {
        data: repaymentScheduleData,
        isFetching: repaymentScheduleLoading,
        refetch: repaymentScheduleRefetch,
    } = useQuery(
        ['getRepaymentScheduleApi', currentPage],
        () =>
            planName
                ? getRepaymentScheduleByIdApi(planEstimationId, currentPage)
                : getRepaymentScheduleApi(token, currentPage),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setTableData(data?.data?.data)
                setTotalPages(data?.data?.total_pages)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(
                    'Failed to fetch Payment Breakdown. Refresh page',
                )
            },
        },
    )

    const { refetch: getConsumerLatestOfferRefetch, data: offerData } =
        useQuery(
            'getConsumerLatestOffer',
            () => getConsumerLatestOffer(token),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    if (data?.data?.data?.estimation === null) {
                        history.push({
                            pathname: `/consumer/login-status`,
                            state: {
                                consumer: {
                                    no_repayment_schedule: true,
                                },
                            },
                        })
                    }
                    setRepaymentMethod(
                        data?.data?.data?.estimation?.repayment_method,
                    )
                    setEstimationId(data?.data?.data?.estimation?.id)
                    setIsUpfrontDepositRequired(
                        data?.data?.data?.estimation?.upfront_deposit_required,
                    )

                    setUpfrontDepositPaid(
                        data?.data?.data?.estimation?.upfront_deposit_paid_at,
                    )
                    setConsumerEmail(data?.data?.data?.consumer?.user?.email)
                    setConsumerId(data?.data?.data?.consumer?.user?.id)
                    setEnergySolutionData(data?.data?.data?.estimation)
                    setIsPromoCustomer(
                        data?.data?.data?.estimation?.is_promo_estimation,
                    )

                    const componentTypeList =
                        data?.data?.data?.estimation?.recommended_solution
                            ?.component_type_list?.[0] || ''

                    appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                        people_set: {
                            'Battery type': componentTypeList.includes('lead')
                                ? 'Lead'
                                : componentTypeList.includes('lithium')
                                ? 'Lithium'
                                : 'N/A',
                        },
                    })
                },
            },
        )

    useEffect(() => {
        getConsumerLatestOfferRefetch()
    }, [])

    useEffect(() => {
        repaymentScheduleStatsRefetch()
        repaymentScheduleRefetch()
    }, [])

    useEffect(() => {
        repaymentScheduleRefetch()
    }, [currentPage])

    useEffect(() => {
        isBuyout && setPreBuyoutModal(true)
    }, [isBuyout])

    useEffect(() => {
        const paid = tableData.filter(t => t.status.toLowerCase() === 'paid')
        setPaidLength(paid.length)
    }, [tableData])

    const emptyState = repaymentScheduleData?.data?.data?.length
    const [width] = React.useState(window.innerWidth)

    const todayFormattedDate = format(new Date(), 'P')

    const handleShowPaystackModal = () => {
        setPaystackLoading(true)
        setShowPaystack(true)
        setCallInitializePayment(!callInitializePayment)
    }

    const repaymentEstimationId = tableData?.find(
        item => item.due_date === repaymentStat?.next_repayment_date,
    )?.id

    const handleReload = () => {
        getConsumerLatestOfferRefetch()
        repaymentScheduleStatsRefetch()
        repaymentScheduleRefetch()
    }

    const currentPaymentId = tableData.filter(item => item.status !== 'PAID')[0]
        ?.id
    const currentPaymentDueDate = tableData.filter(
        item => item.status !== 'PAID',
    )[0]?.due_date

    const differenceInDays =
        (new Date(todayFormattedDate).getTime() -
            new Date(currentPaymentDueDate).getTime()) /
        (1000 * 3600 * 24)

    const allowPayment = data => {
        const compareId = data?.id === currentPaymentId
        const upfrontDepositPayment = upfrontDepositPaid !== null

        if (['cancelled', 'deleted'].includes(data?.status?.toLowerCase())) {
            return true
        } else if (isPromoCustomer) {
            return compareId && differenceInDays > -1 ? false : true
        } else if (consumerCanPayAnyMonth) {
            return false
        } else if (compareId && differenceInDays > -1) {
            return isUpfrontDepositRequired && !upfrontDepositPayment
                ? true
                : false
        } else {
            return true
        }
    }

    const handleInitializePaymentError = error => {
        setToastError(true)
        setErrorMessage(error)
    }

    const {
        calculateBuyoutAmountMutate,
        calculateBuyoutAmountLoading,
        calculateBuyoutAmountData,
    } = useCalculateBuyoutAmount({
        onSuccess: () => {
            if (preBuyoutModal) {
                removeQueryParamFromUrl('buyout')
                setPreBuyoutModal(false)
            }
            setShowBuyoutModal(true)
            setBuyout(true)
        },
        user: 'consumer',
    })

    const handleCalcBuyout = () => {
        calculateBuyoutAmountMutate({
            estimation_id: planEstimationId,
            user_id: consumerId,
        })
    }

    return (
        <AppLogout>
            <SeoComponent
                title="Repayment Schedule | SunFi | Simplifying and Scaling Clean Energy"
                tracker="RepaymentScheduleTracker"
            />
            {showPayStack && (
                <PayStack
                    estimationId={buyout ? planEstimationId : estimationId}
                    reloadPage={handleReload}
                    event={buyout ? 'buyout' : 'monthly_repayment'}
                    email={consumerEmail}
                    handleLoading={() => setPaystackLoading(false)}
                    handleError={handleInitializePaymentError}
                    callInitializePayment={callInitializePayment}
                    setVerifyLoading={setVerifyLoading}
                    openCardDetailsModal={handleShowPaystackModal}
                    v2={buyout}
                    month={repaymentMonth}
                />
            )}
            <SecondaryModal
                modalWidth="410px"
                modalHeight={
                    invoiceData?.status === 'SHORT_PAID' ? '420px' : '350px'
                }
                showModal={showModal}
                onCancel={() => setShowModal(false)}
                content={
                    <Invoice
                        onClose={() => setShowModal(false)}
                        invoiceData={invoiceData}
                        repaymentMethod={repaymentMethod}
                    />
                }
            />
            <SecondaryModal
                modalWidth="624px"
                modalHeight="600px"
                showModal={showEnergyModal}
                onCancel={() => setShowEnergyModal(false)}
                content={
                    <EnergySolution
                        onClose={() => setShowEnergyModal(false)}
                        energySolutionData={energySolutionData}
                        setShowClosePlanModal={setShowClosePlanModal}
                    />
                }
            />

            <ActionModalV2
                title={'Close plan'}
                actionModalOpen={showClosePlanModal}
                actionType={'warning'}
                headerText={'Close your subscription plan'}
                subTitle={`You’re about to close your subscription plan, this will involve removal of the installed system at the location. Please confirm`}
                primaryBtnLoading={isClosingPlan}
                primaryBtnText={'Continue'}
                handlePrimaryBtnClick={closePlan}
                v2
                onCancel={() => setShowClosePlanModal(false)}
                footerLeftContent={'chat'}
            />

            <ActionModalV2
                title={'Plan Closed!'}
                actionModalOpen={showClosePlanSuccessModal}
                actionType={'success'}
                headerText={'Your subscription plan has been closed'}
                subTitle={`We have now closed your subscription plan, someone from our team will reach out to you about the logistics`}
                primaryBtnText={'Continue'}
                handlePrimaryBtnClick={() => {
                    setShowClosePlanSuccessModal(false)
                    history.push('/consumer/workspace?tab=active-plans')
                }}
                v2
                closable={false}
                footerLeftContent={'chat'}
            />

            <section className={Wrapper}>
                <BackNav
                    title="Back to Plans"
                    onClick={() => {
                        history.push('/consumer/workspace?tab=active-plans')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'CONSUMER_CLICKS_BACK_TO_PLANS(CONSUMER_WORKSPACE)',
                            'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                            'KYC_CONSUMER_WORKSPACE',
                        )
                    }}
                    marginBottom={40}
                    v2
                />
                {repaymentScheduleLoading || repaymentScheduleStatsLoading ? (
                    <div className={SkeletonWrapper}>
                        <div className={TopSkeletonWrapper}>
                            <Skeleton.Input className={TopLeft} active />
                            <Skeleton.Input className={TopRight} active />
                        </div>
                        <div
                            className={`${MiddleSkeletonWrapper} ${MobileHide}`}
                        >
                            {[...Array(3)].map(i => (
                                <div className={MiddleRow} key={i + 1}>
                                    <Skeleton.Input
                                        className={MiddleTopColumn}
                                        active
                                    />
                                    <Skeleton.Input
                                        className={MiddleBottomColumn}
                                        active
                                    />
                                </div>
                            ))}
                        </div>

                        <div
                            className={`${MiddleSkeletonWrapper} ${DesktopHide}`}
                        >
                            <div className={MiddleRow}>
                                <Skeleton.Input
                                    className={MiddleTopColumn}
                                    active
                                />
                                <Skeleton.Input
                                    className={MiddleBottomColumn}
                                    active
                                />
                            </div>
                        </div>

                        <Skeleton.Input className={SkeletonMiddle} />
                        {[...Array(3)].map(i => (
                            <div className={BottomSkeletonWrapper} key={i + 1}>
                                <div className={BottomRow}>
                                    <Skeleton.Input
                                        className={BottomSmallColumn}
                                        active
                                    />
                                    <Skeleton.Input
                                        className={BottomColumn}
                                        active
                                    />
                                </div>
                                <Skeleton.Input
                                    className={`${BottomColumn1} ${MobileHide}`}
                                    active
                                />
                                <Skeleton.Input
                                    className={`${BottomColumn2} ${MobileHide}`}
                                    active
                                />
                                <Skeleton.Input
                                    className={BottomColumn3}
                                    active
                                />
                                <Skeleton.Input
                                    className={`${BottomColumn4} ${MobileHide}`}
                                    active
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        {isSubscriptionPlan && !planName ? (
                            <h2 className={Heading}>Your Solar Subscription</h2>
                        ) : planName ? (
                            <h2 className={Heading}>{planName}</h2>
                        ) : (
                            <h2 className={Heading}>Repayment Schedule</h2>
                        )}
                        {energySolutionData?.approval_status === 'APPROVED' && (
                            <p
                                className={SubHeading}
                                onClick={ViewEnergySolution}
                            >
                                View Plan Details
                            </p>
                        )}
                        <div className={DashboardWrapper}>
                            {dashboardData.map((data, i) => {
                                return (
                                    <div className={Dashboard} key={i + 1}>
                                        <div className={DashboardDateWrapper}>
                                            <p className={DashboardTitle}>
                                                {data.name}
                                            </p>
                                            {data.date &&
                                                (data.date ===
                                                'Invalid date' ? (
                                                    ''
                                                ) : (
                                                    <span
                                                        className={
                                                            DashboardDate
                                                        }
                                                    >
                                                        {data.date}
                                                    </span>
                                                ))}
                                        </div>
                                        <p className={DashboardSubtitle}>
                                            {data?.isMoney ? (
                                                <NairaSymbol />
                                            ) : null}{' '}
                                            {data.amount}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={MobileDashboardWrapper}>
                            <Carousel className={CarouselWrapper}>
                                {dashboardData.map((data, i) => {
                                    return (
                                        <div className={Dashboard} key={i + 1}>
                                            <div
                                                className={DashboardDateWrapper}
                                            >
                                                <p className={DashboardTitle}>
                                                    {data.name}
                                                </p>
                                                {data.date &&
                                                    (data.date ===
                                                    'Invalid date' ? (
                                                        ''
                                                    ) : (
                                                        <span
                                                            className={
                                                                DashboardDate
                                                            }
                                                        >
                                                            {data.date}
                                                        </span>
                                                    ))}
                                            </div>
                                            <p
                                                className={DashboardSubtitle}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {data.amount}
                                            </p>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                        {emptyState === 0 ? (
                            <>
                                <div className={NoActivityWrapper}>
                                    <img
                                        src={overviewNoActivity}
                                        alt="no activity"
                                    />
                                    <p className={EmptyHeading}>
                                        No repayment schedule yet
                                    </p>
                                    <p className={EmptyText}>
                                        You are yet to be assigned a repayment
                                        schedule
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div className={PaymentBreakdownWrapper}>
                                <div>
                                    <p className={PaymentBreakdownTitle}>
                                        Payment Breakdown
                                    </p>
                                    {process.env
                                        .REACT_APP_FLAG_PAYMENT_COLLECTION_ENHANCEMENT_BUYOUT ===
                                        'true' &&
                                        offerData?.data?.data?.estimation?.payment_plan?.toLowerCase() ===
                                            'lease to own' &&
                                        repaymentStat.remaining_months !==
                                            0 && (
                                            <ConsumerButton
                                                hover
                                                btnHeight={'44px'}
                                                btnTextColor={'#004aad'}
                                                btnBgColor={'#F9FCFF'}
                                                btnFontSize={'13px'}
                                                border={
                                                    '0.5px solid var(--primary-004-aad, #004AAD)'
                                                }
                                                borderRadius={30}
                                                handleClick={handleCalcBuyout}
                                                loading={
                                                    calculateBuyoutAmountLoading
                                                }
                                            >
                                                Buy Out Your Plan
                                            </ConsumerButton>
                                        )}
                                </div>
                                <div className={ContentWrapper}>
                                    <div className={StepWrapper}>
                                        {toastError && (
                                            <Toast
                                                errorMessage={errorMessage}
                                            />
                                        )}
                                        {tableData.map((table, i) => {
                                            const lastItem =
                                                i === tableData.length - 1
                                            const paid =
                                                table.status.toLowerCase() ===
                                                'paid'
                                            const part_paid =
                                                table.status.toLowerCase() ===
                                                'short_paid'
                                            const buyout_status =
                                                table.status.toLowerCase() ===
                                                'buyout'
                                            return (
                                                <Fragment key={i}>
                                                    <div
                                                        className={`${DefaultStepper} ${
                                                            paid ||
                                                            part_paid ||
                                                            buyout_status
                                                                ? checkedStepperWrapper
                                                                : paidLength ===
                                                                  (currentPage -
                                                                      1) *
                                                                      10 +
                                                                      i
                                                                ? ActiveStepper
                                                                : ''
                                                        }`}
                                                    >
                                                        {paid ||
                                                        buyout_status ||
                                                        part_paid ? (
                                                            <img
                                                                src={
                                                                    checkedStepperImg
                                                                }
                                                                alt="paid icon"
                                                            />
                                                        ) : (
                                                            (currentPage - 1) *
                                                                10 +
                                                            (i + 1)
                                                        )}
                                                    </div>
                                                    {!lastItem && (
                                                        <div
                                                            className={`${DefaultVerticalLine} ${
                                                                paid &&
                                                                GreenVerticalLine
                                                            } ${
                                                                part_paid &&
                                                                GreenVerticalLine
                                                            } `}
                                                        ></div>
                                                    )}
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                    <table className={TableWrapper}>
                                        {tableData.map((data, i) => {
                                            const isExpired =
                                                getNthDaytoDate(
                                                    new Date(data?.due_date),
                                                    DAYS_BEFORE_EXPIRED,
                                                ).toLocaleDateString() ===
                                                new Date().toLocaleDateString()
                                            return (
                                                <>
                                                    <div
                                                        key={data.id}
                                                        className={RowWrapper}
                                                        onClick={
                                                            width < 600
                                                                ? () =>
                                                                      handleViewInvoice(
                                                                          data,
                                                                      )
                                                                : () => {}
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                RowColumnOne
                                                            }
                                                        >
                                                            <td
                                                                className={
                                                                    RowColumnOneFirst
                                                                }
                                                            >
                                                                {data?.due_date !==
                                                                    undefined &&
                                                                    format(
                                                                        new Date(
                                                                            data?.due_date,
                                                                        ),
                                                                        'MMM',
                                                                    )}
                                                            </td>
                                                            <td
                                                                className={
                                                                    RowColumnOneSecond
                                                                }
                                                            >
                                                                {data?.due_date !==
                                                                    undefined &&
                                                                    format(
                                                                        new Date(
                                                                            data?.due_date,
                                                                        ),
                                                                        'd',
                                                                    )}
                                                            </td>
                                                        </div>
                                                        <td
                                                            className={
                                                                TableData
                                                            }
                                                        >
                                                            {![
                                                                'PAID',
                                                                'SHORT_PAID',
                                                            ].includes(
                                                                data?.status,
                                                            ) &&
                                                            (currentPage - 1) *
                                                                10 +
                                                                i >
                                                                paidLength &&
                                                            repaymentStat.remaining_months !==
                                                                0 ? null : (
                                                                <p
                                                                    className={`${
                                                                        data?.status ===
                                                                        ''
                                                                            ? ''
                                                                            : data?.status ===
                                                                              'PAID'
                                                                            ? PaidStatus
                                                                            : data?.status ===
                                                                              'BUYOUT'
                                                                            ? BuyoutStatus
                                                                            : DueStatus
                                                                    } ${MobileHide}`}
                                                                >
                                                                    {isExpired &&
                                                                    ![
                                                                        'cancelled',
                                                                        'deleted',
                                                                    ].includes(
                                                                        data?.status?.toLowerCase(),
                                                                    )
                                                                        ? `Due ${DAYS_BEFORE_EXPIRED} Days`
                                                                        : data?.status?.toLowerCase() ===
                                                                          'short_paid'
                                                                        ? 'PART-PAID'
                                                                        : data?.status}
                                                                </p>
                                                            )}
                                                        </td>
                                                        <td
                                                            className={`${TableData} ${TableDate} ${MobileHide}`}
                                                        >
                                                            {data?.due_date !==
                                                                undefined &&
                                                                format(
                                                                    new Date(
                                                                        data?.due_date,
                                                                    ),
                                                                    'MMM yyyy',
                                                                )}
                                                        </td>
                                                        <td
                                                            className={`${TableData} ${TableDate}`}
                                                        >
                                                            <NairaSymbol />{' '}
                                                            {currencyFormatter(
                                                                data?.amount_due,
                                                            )}
                                                        </td>
                                                        <div
                                                            className={
                                                                TableBtnWrapper
                                                            }
                                                        >
                                                            {[
                                                                'PAID',
                                                                'BUYOUT',
                                                            ].includes(
                                                                data?.status,
                                                            ) ? (
                                                                <button
                                                                    className={`${TableBtn}
                                                                    ${MobileHide}`}
                                                                    onClick={() => {
                                                                        handleViewInvoice(
                                                                            data,
                                                                        )
                                                                        appTracking(
                                                                            pageUrlName,
                                                                            pageTracker,
                                                                            pageTitle,
                                                                            'CONSUMER_CLICKS_VIEW_INVOICE',
                                                                            'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                                                                            'KYC_CONSUMER_WORKSPACE',
                                                                        )
                                                                    }}
                                                                >
                                                                    View Invoice
                                                                </button>
                                                            ) : data?.status ===
                                                              'SHORT_PAID' ? (
                                                                <div
                                                                    className={
                                                                        TableBtnContainer
                                                                    }
                                                                >
                                                                    <button
                                                                        className={`${TableBtn}
                                                                    ${MobileHide}`}
                                                                        onClick={() => {
                                                                            handleViewInvoice(
                                                                                data,
                                                                            )
                                                                            appTracking(
                                                                                pageUrlName,
                                                                                pageTracker,
                                                                                pageTitle,
                                                                                'CONSUMER_CLICKS_VIEW_INVOICE',
                                                                                'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                                                                                'KYC_CONSUMER_WORKSPACE',
                                                                            )
                                                                        }}
                                                                    >
                                                                        View
                                                                        Invoice
                                                                    </button>
                                                                    <button
                                                                        className={`${TableBtnShortPaid}
                                                                    ${MobileHide}`}
                                                                        disabled={allowPayment(
                                                                            data,
                                                                        )}
                                                                        onClick={() => {
                                                                            consumerCanPayAnyMonth &&
                                                                                setRepaymentMonth(
                                                                                    data?.month,
                                                                                )
                                                                            setBuyout(
                                                                                false,
                                                                            )
                                                                            setTimeout(
                                                                                () => {
                                                                                    handleShowPaystackModal()
                                                                                },
                                                                                500,
                                                                            )
                                                                            appTracking(
                                                                                pageUrlName,
                                                                                pageTracker,
                                                                                pageTitle,
                                                                                'CONSUMER_CLICKS_PAY_NOW',
                                                                                'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                                                                                'KYC_CONSUMER_WORKSPACE',
                                                                            )
                                                                        }}
                                                                    >
                                                                        {!buyout &&
                                                                        (paystackLoading ||
                                                                            verifyLoading) &&
                                                                        (consumerCanPayAnyMonth
                                                                            ? repaymentMonth ===
                                                                              data?.month
                                                                            : repaymentEstimationId) ? (
                                                                            <InlineLoader />
                                                                        ) : [
                                                                              'PAID',
                                                                              'BUYOUT',
                                                                          ].includes(
                                                                              data?.status,
                                                                          ) ? (
                                                                            'View Invoice'
                                                                        ) : (
                                                                            'Pay Now'
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            ) : data?.status !==
                                                              'PAID' ? (
                                                                <button
                                                                    className={`${TableBtnSecondary}
                                                                    ${MobileHide}`}
                                                                    disabled={allowPayment(
                                                                        data,
                                                                    )}
                                                                    onClick={() => {
                                                                        consumerCanPayAnyMonth &&
                                                                            setRepaymentMonth(
                                                                                data?.month,
                                                                            )
                                                                        setBuyout(
                                                                            false,
                                                                        )
                                                                        setTimeout(
                                                                            () => {
                                                                                handleShowPaystackModal()
                                                                            },
                                                                            500,
                                                                        )
                                                                        appTracking(
                                                                            pageUrlName,
                                                                            pageTracker,
                                                                            pageTitle,
                                                                            'CONSUMER_CLICKS_PAY_NOW',
                                                                            'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                                                                            'KYC_CONSUMER_WORKSPACE',
                                                                        )
                                                                    }}
                                                                >
                                                                    {!buyout &&
                                                                    (paystackLoading ||
                                                                        verifyLoading) &&
                                                                    (consumerCanPayAnyMonth
                                                                        ? repaymentMonth ===
                                                                          data?.month
                                                                        : repaymentEstimationId) ? (
                                                                        <InlineLoader />
                                                                    ) : [
                                                                          'PAID',
                                                                          'BUYOUT',
                                                                      ].includes(
                                                                          data?.status,
                                                                      ) ? (
                                                                        'View Invoice'
                                                                    ) : (
                                                                        'Pay Now'
                                                                    )}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className={`${TableBtn}
                                                                    ${MobileHide}`}
                                                                    onClick={() => {
                                                                        handleViewInvoice(
                                                                            data,
                                                                        )
                                                                        appTracking(
                                                                            pageUrlName,
                                                                            pageTracker,
                                                                            pageTitle,
                                                                            'CONSUMER_CLICKS_VIEW_INVOICE',
                                                                            'KYC_CONSUMER_REPAYMENT_SCHEDULE',
                                                                            'KYC_CONSUMER_WORKSPACE',
                                                                        )
                                                                    }}
                                                                >
                                                                    View Invoice
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </table>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className="pages" style={{ marginTop: '0px' }}>
                    {paginationRequest()}
                </div>
            </section>

            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass={ConsumerFloatBox}
                    renderComponent={props => <ConsumerHelpCenter {...props} />}
                />
            </div>
            <Buyout
                details={calculateBuyoutAmountData}
                showBuyoutModal={showBuyoutModal}
                setShowBuyoutModal={setShowBuyoutModal}
                user={'consumer'}
                handlePrimaryBtn={handleShowPaystackModal}
                preBuyoutModal={preBuyoutModal}
                setPreBuyoutModal={setPreBuyoutModal}
                handleCalcBuyout={handleCalcBuyout}
                calculateBuyoutAmountLoading={calculateBuyoutAmountLoading}
            />
        </AppLogout>
    )
}

export default RepaymentSchedule
