export const pageTitle =
    'Consumer Welcome | SunFi | Simplifying and Scaling Clean Energy'
export const pageUrlName = window.location.pathname
export const pageTracker = 'KYC_LOGIN'

export const signInOptions = [
    {
        title: 'Sign In as a Consumer',
        description: 'You purchased a system for personal/business use',
        link: '/consumer/login',
    },
    {
        title: 'Sign In as a Provider',
        description:
            'You own a business that sells and/or installs solar systems',
        link: '/login',
    },
    {
        title: 'Sign in as an Agent or Business Rep',
        description:
            'Manage your account as a SunFi Agent or Business Representative',
        link: '/rep/login',
    },
]
