import 'src/components/GettingStarted/gettingstarted.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { SelectField, TextArea } from 'src/components/InputFields'
import { changePackageOptions, disputeReasonsOptions } from '../data'
import { disputePackageSchema } from 'src/utils/validationSchema'
import { useMutation } from 'react-query'
import { disputePackageApi } from 'src/api/shoppingExperience/account-overview'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import {
    changePackageDecision,
    requestPackage,
} from 'src/api/consumer/postApproval'

const DisputePackageModal = ({
    showModal,
    onCancel,
    onClick,
    estimation_id,
    showDEPConsumerChangePackageOption,
    title,
    postApprovalRequest,
    type = 'dispute-package',
}) => {
    const [inputs, setInputs] = useState({
        category: '',
        reason: '',
    })
    const [errors, setErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [showToast, setShowToast] = useState(false)
    const dispusteApi =
        type === 'dispute-package'
            ? disputePackageApi
            : postApprovalRequest &&
              (type === 'reject-new-post-approval-package'
                  ? changePackageDecision
                  : type === 'request-new-package-post-approval'
                  ? requestPackage
                  : disputePackageApi)

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
        clearError(name)
    }

    const handleInputChange = event => {
        const { value } = event.target
        const { name } = event.target
        setInputs(prev => ({ ...prev, [name]: value }))
        clearError(name)
    }

    const clearError = name => {
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
    }

    const handleDisputePackage = () => {
        disputePackageSchema(showDEPConsumerChangePackageOption)
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                submitDisputeMutation.mutate()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const submitDisputeMutation = useMutation({
        mutationKey: ['dispute_package'],
        mutationFn: () =>
            dispusteApi(
                estimation_id,
                postApprovalRequest
                    ? {
                          reason: `${inputs.category} ${inputs.reason}`,
                          ...(type === 'reject-new-post-approval-package' && {
                              decision: 'REJECT',
                          }),
                      }
                    : {
                          category: inputs.category,
                          ...(inputs.reason.length !== 0 && {
                              reason: inputs.reason,
                          }),
                      },
            ),
        onSuccess: () => {
            onClick()
        },
        onError: err => {
            setShowToast(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const handleModalClose = () => {
        setInputs({ category: '', reason: '' })
        setErrors({})
        onCancel()
    }

    const reasonOptions = showDEPConsumerChangePackageOption
        ? changePackageOptions
        : disputeReasonsOptions

    return (
        <SecondaryModal
            v2
            showModal={showModal}
            modalWidth={475}
            title={title || 'Reason for disputing package'}
            onCancel={handleModalClose}
            content={
                <div
                    className={`GettingStartedWrapper ${
                        showDEPConsumerChangePackageOption
                            ? 'DisputePackageModal'
                            : ''
                    }`}
                >
                    {showToast && (
                        <Toast
                            messageType="error-secondary"
                            message={errorMessage}
                            closable={true}
                        />
                    )}
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 8 }}
                    >
                        <h3></h3>
                        <h3>
                            {showDEPConsumerChangePackageOption
                                ? 'Select your reason for this request'
                                : 'Select a category for your reason'}
                        </h3>
                        <p>
                            {showDEPConsumerChangePackageOption
                                ? 'Please tell us why you want to change your package'
                                : 'Please tell us why you want to dispute your package'}
                        </p>

                        <SelectField
                            values={reasonOptions.map(option => ({
                                value: option,
                                label: option,
                            }))}
                            selectWidth="100%"
                            name="category"
                            initialOption="Select a reason"
                            handleChange={handleSelectChange}
                            marginBottom="20px"
                            dropdownPositionRelative
                            errorMessage={errors['category']}
                            floatingLabel={
                                inputs.category === '' ? '' : 'Select a reason'
                            }
                            withCheckBox
                            selectedValue={inputs.category}
                            currentSelected={inputs.category}
                        />
                    </div>
                    <div
                        className={'GettingStartedHeadingAndText'}
                        style={{ marginBottom: 8 }}
                    >
                        <h3>
                            {showDEPConsumerChangePackageOption
                                ? 'Your reason was not included in the options?'
                                : 'Add a more explanatory note'}
                        </h3>
                        <p>
                            {showDEPConsumerChangePackageOption
                                ? 'Please go ahead and type in your reason for wanting to alter'
                                : 'Please tell us why you want to dispute your package'}
                        </p>
                        <TextArea
                            title={
                                showDEPConsumerChangePackageOption
                                    ? 'Type in your reason'
                                    : 'Leave a more explanatory note'
                            }
                            height="140px"
                            resize
                            name="reason"
                            inputValue={inputs.reason}
                            value={inputs.reason}
                            rows={10}
                            handleChange={handleInputChange}
                            errorMessage={errors['reason']}
                        />
                    </div>
                </div>
            }
            showFooter
            footerLeftContent={null}
            primaryBtnText={'Confirm'}
            handlePrimaryBtnClick={() =>
                postApprovalRequest
                    ? submitDisputeMutation.mutate()
                    : handleDisputePackage()
            }
            primaryBtnLoading={submitDisputeMutation.isLoading}
            primaryBtnDisabled={
                postApprovalRequest &&
                inputs.reason === '' &&
                inputs.category === ''
            }
        />
    )
}

DisputePackageModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    onClick: PropTypes.func,
    estimation_id: PropTypes.string,
    showDEPConsumerChangePackageOption: PropTypes.bool,
    title: PropTypes.string,
    postApprovalRequest: PropTypes.bool,
    type: PropTypes.string,
}

export default DisputePackageModal
