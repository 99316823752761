export const formatPackageData = (data, payment_plans) => {
    let formattedData = {
        name: data?.name,
        package_description: data?.description,
        components: data?.components?.map(components => {
            return {
                name: components?.component?.name,
                type: components?.component?.type?.name,
                cost: components?.component?.cost,
                total_cost: components?.cost,
                quantity: components?.quantity,
            }
        }),
        cost: data?.subtotal,
        total_cost: data?.total,
        margin: data?.margin,
        payment_plans,
    }

    return formattedData
}

export const actionModalValues = action => {
    switch (action) {
        case 'confirm-package-change':
            return {
                actionModalHeaderText:
                    'Confirm you want to send this recommendation to the consumer',
                actionModalSubtitle:
                    'The consumer will recieve an email of this new recommendation',
                actionModalActionType: 'warning',
                actionModalPrimaryBtn: 'Yes, Confirm',
                cancelText: 'Cancel',
            }
        case 'notified-consumer-of-package-change':
            return {
                actionModalHeaderText: 'New recommendation sent',
                actionModalSubtitle:
                    'You have successfully sent new recommendation and this consumer will be notified',
                actionModalActionType: 'success',
                actionModalPrimaryBtn: 'Okay, Got it',
                cancelText: '',
            }
        case 'package-change-successful':
            return {
                actionModalHeaderText: 'Credit run successful',
                actionModalSubtitle:
                    'This package will now be sent to this consumer and they will be notified.',
                actionModalActionType: 'success',
                actionModalPrimaryBtn: 'Send ',
                cancelText: 'Cancel',
            }
        case 'package-change-unsuccessful':
            return {
                actionModalHeaderText: 'Credit run unsuccessful',
                actionModalSubtitle:
                    "The credit run didn't go through and this package cannot be sent to this consumer.",
                actionModalActionType: 'delete',
                actionModalPrimaryBtn: 'Have a manual review ',
                cancelText: 'Send another package',
            }
        default:
            return {
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtn: '',
                cancelText: '',
            }
    }
}
