import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styles from './promolistings.module.scss'
import { Pagination } from 'antd'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import CSSEPromoListingsHeader from '../Components/CSSEPromoListingsHeader'
import CSSECTA from 'src/components/CSSECTA'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import ConsumerFooter from 'src/components/ConsumerFooter'
import FilterRecommendations from '../../SolarSystemsListings/FilterRecommendations'
import SeoComponent from 'src/components/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { removeToken } from 'src/utils/auth'
import CookieNotification from 'src/components/CookieNotificiation'
import { useGetEnergyProfiles } from 'src/api/shoppingExperience/energy-profile/queries'
import { SkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import { setOnboardingInfo } from 'src/utils/userOnboardingInfoManager'

const CSSEPromoListings = () => {
    const {
        cssePromoListingsWrapper,
        csseSearchInputWrapper,
        cssepromoListingsImg,
        cssePromoListingsStyle,
        PaginationWrapper,
    } = styles

    const [searchText, setSearchText] = useState('')
    const [searchVal, setSearchVal] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [addFilter, setAddFilter] = useState(false)
    const [, setFiltersPayload] = useState({})

    const handleSearch = () => {
        setSearchVal(searchText)
    }

    const handleSearchInputChange = e => {
        setSearchText(e.target.value)
        if (e.target.value?.length === 0) {
            setSearchVal('')
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleFilter = filterVariables => {
        setFiltersPayload(filterVariables)
        setAddFilter(false)
    }

    const history = useHistory()
    const isAuthenticatedUser =
        history.location?.state?.isAuthenticatedUser ?? false

    useEffect(() => {
        if (!isAuthenticatedUser) {
            removeToken()
        }
    }, [isAuthenticatedUser])

    const { getEnergyProfilesData, getEnergyProfilesFetching } =
        useGetEnergyProfiles({
            enabled: true,
            params: {
                is_promotional: true,
                promotional_type: 'public',
                name: searchVal,
                page: currentPage,
            },
        })

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={1}
                current={currentPage}
                total={parseInt(getEnergyProfilesData?.data?.total_pages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={e => setCurrentPage(e)}
            />
        )
    }

    return (
        <>
            <SeoComponent
                title="Promos | SunFi | Simplifying and Scaling Clean Energy"
                tracker="PromoTracker"
            />
            {addFilter && (
                <FilterRecommendations
                    addFilter={addFilter}
                    setAddFilter={setAddFilter}
                    handleFilter={handleFilter}
                    isLoading={false}
                />
            )}
            <ConsumerNavigation />
            <section>
                <CSSEPromoListingsHeader
                    title="Browse some available promos for you"
                    subTitle="Get access to some already available product, all you just need to do is choose the product that can best power what you want to power and choose a plan"
                />
                <section className={cssePromoListingsWrapper}>
                    <div className={csseSearchInputWrapper}>
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            border="0px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#FFFFFF"
                            iconType="promo-search"
                            prefilled
                            inputPlaceholder="Search for a particular promo"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                    </div>
                    {getEnergyProfilesFetching ? (
                        <div style={{ width: '100%', maxWidth: '1244px' }}>
                            <SkeletalCards />
                            <SkeletalCards />
                        </div>
                    ) : !getEnergyProfilesFetching &&
                      getEnergyProfilesData?.data?.count === 0 ? (
                        <EmptyState
                            title={'No available promo'}
                            subTitle={'Your search result cannot be found'}
                            buttonVisible
                            buttonText={'Clear Search'}
                            buttonClick={() => {
                                setSearchText('')
                                setSearchVal('')
                            }}
                        />
                    ) : (
                        <div className={cssePromoListingsStyle}>
                            {getEnergyProfilesData?.data?.data?.map(
                                (promo, i) => (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            setOnboardingInfo({
                                                type: 'DTC',
                                                context: {},
                                            })

                                            history.push(
                                                `/consumer/promo-detail/${promo?.id}`,
                                            )
                                        }}
                                    >
                                        <LazyLoadImage
                                            src={promo?.image_url}
                                            alt={promo.name}
                                            className={cssepromoListingsImg}
                                        />
                                    </div>
                                ),
                            )}
                        </div>
                    )}

                    {getEnergyProfilesData?.data?.count === 0 &&
                        !getEnergyProfilesFetching && (
                            <div className={PaginationWrapper}>
                                {paginationBlock()}
                            </div>
                        )}
                </section>
            </section>
            <CSSECTA />
            <CookieNotification />
            <ConsumerFooter />
        </>
    )
}

export default CSSEPromoListings
