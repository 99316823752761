import { InputFields, SelectField } from 'src/components/InputFields'
import '../../consumerkyc.scss'
import { Switch } from 'antd'
import { localGovtData, stateData } from 'src/utils/stateData'
import DetailsSection from './DetailsSection'
import PersonalInfo from './PersonalInfo'
import { useConsumerKYCContext } from '../../context'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { countries } from '../../data'
import { convertArrayOfStringsToObjectOfBooleans } from '../../utils'

const BasicInformationSection = () => {
    const {
        inputs,
        useType,
        handleBlur,
        handleInputChange,
        handleSelectChange,
        handleSelectOnBlur,
        setInputs,
        isCustomerApproved,
        onInputFocus,
        onSelectFocus,
        handleSameAddressOptionChangeCallback,
        providerUpdatedFieldsData,
        isDEPConsumer,
    } = useConsumerKYCContext()

    const { isMobile } = useMediaQueries()
    const allowAutoSave = process.env.REACT_APP_FLAG_KYC_AUTOSAVE === 'true'

    const basicInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.basicInformation || [],
        )
    const contactInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.contactInformation || [],
        )
    const businessInformationUpdatedFields =
        convertArrayOfStringsToObjectOfBooleans(
            providerUpdatedFieldsData?.businessInformation || [],
        )

    const handleSameAddressOptionChange = isSameAddress => {
        setInputs(prev => ({
            ...prev,
            confirmAddress: `${isSameAddress}`,
            ...(isSameAddress && {
                installationCountry: inputs?.contactCountry ?? '',
                installationState: inputs?.installationState ?? '',
                installationCity: inputs?.installationCity ?? '',
            }),
            ...(!isSameAddress && {
                installationCountry: '',
                installationState: '',
                installationCity: '',
                installationAddress: '',
            }),
        }))

        if (allowAutoSave) {
            handleSameAddressOptionChangeCallback(isSameAddress)
        }
    }

    return (
        <div>
            {useType === 'residential' && (
                <>
                    <PersonalInfo />
                    <InputFields
                        title="Home Address"
                        name="contactAddress"
                        value={inputs?.contactAddress}
                        marginBottom={isMobile ? '16px' : ''}
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        disabled={isCustomerApproved}
                        showUpdateDot={
                            isDEPConsumer &&
                            (basicInformationUpdatedFields.contactAddress ||
                                contactInformationUpdatedFields.contactAddress)
                        }
                        onFocus={onInputFocus}
                    />
                </>
            )}

            {useType === 'business' && (
                <div>
                    <InputFields
                        title="Registered Business Name"
                        value={inputs?.businessName}
                        name="businessName"
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        marginBottom={isMobile ? '16px' : ''}
                        disabled={isCustomerApproved}
                        onFocus={onInputFocus}
                        showUpdateDot={
                            isDEPConsumer &&
                            businessInformationUpdatedFields.businessName
                        }
                    />
                    <InputFields
                        title="Business contact address"
                        value={inputs?.contactAddress}
                        name="contactAddress"
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        marginBottom={isMobile ? '16px' : ''}
                        disabled={isCustomerApproved}
                        onFocus={onInputFocus}
                        showUpdateDot={
                            isDEPConsumer &&
                            businessInformationUpdatedFields.contactAddress
                        }
                    />
                </div>
            )}

            <div className="GroupItems AddressDetails">
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCountry"
                    selectWidth="100%"
                    initialOption={
                        useType === 'residential' ? 'Country' : 'Nationality'
                    }
                    floatingLabel={
                        useType === 'residential' ? 'Country' : 'Nationality'
                    }
                    values={countries.map(option => ({
                        value: option,
                    }))}
                    handleChange={handleSelectChange}
                    value={inputs?.contactCountry}
                    marginBottom={isMobile ? '16px' : ''}
                    onBlur={() => handleSelectOnBlur('contactCountry')}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCountry}
                    selectedValue={inputs?.contactCountry}
                    disabled={isCustomerApproved}
                    onFocus={onSelectFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.contactCountry ||
                            businessInformationUpdatedFields.contactCountry)
                    }
                />
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactState"
                    floatingLabel="State"
                    initialOption="State"
                    selectWidth="100%"
                    values={stateData?.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactState}
                    marginBottom={isMobile ? '16px' : ''}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactState')}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactState}
                    selectedValue={inputs?.contactState}
                    disabled={isCustomerApproved}
                    onFocus={onSelectFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.contactState ||
                            businessInformationUpdatedFields.contactState)
                    }
                />
                <SelectField
                    initialOption="LGA"
                    values={localGovtData[inputs?.contactState]?.map(
                        option => ({
                            value: option,
                        }),
                    )}
                    value={inputs?.contactCity}
                    marginBottom={isMobile ? '16px' : ''}
                    floatLabelOnSelectedValue
                    name="contactCity"
                    floatingLabel="LGA"
                    selectWidth="100%"
                    disabled={
                        inputs?.contactState?.length < 1 || isCustomerApproved
                    }
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCity')}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCity}
                    selectedValue={inputs?.contactCity}
                    onFocus={onSelectFocus}
                    showUpdateDot={
                        isDEPConsumer &&
                        (basicInformationUpdatedFields.contactCity ||
                            businessInformationUpdatedFields.contactCity)
                    }
                />
            </div>
            <div className="GroupItems">
                <p className="InstallationText">
                    Is this contact address the same as where you want to
                    install the system?
                </p>

                <div className="SwitchWrapper">
                    <span
                        style={{
                            color: !inputs?.confirmAddress ? '#004AAD' : '',
                        }}
                    >
                        No
                    </span>
                    <Switch
                        size={isMobile ? 'small' : ''}
                        checked={
                            inputs?.confirmAddress === 'true' ? true : false
                        }
                        onChange={e => handleSameAddressOptionChange(e)}
                        disabled={isCustomerApproved}
                    />
                    <span
                        style={{
                            color: inputs?.confirmAddress ? '#004AAD' : '',
                        }}
                    >
                        Yes
                    </span>
                </div>
            </div>
            <div>
                {inputs?.confirmAddress !== 'true' && (
                    <>
                        <InputFields
                            title="Installation Address"
                            name="installationAddress"
                            value={inputs?.installationAddress}
                            marginBottom={isMobile ? '16px' : ''}
                            handleChange={handleInputChange}
                            onBlur={handleBlur}
                            disabled={isCustomerApproved}
                            onFocus={onInputFocus}
                            showUpdateDot={
                                isDEPConsumer &&
                                (basicInformationUpdatedFields.installationAddress ||
                                    businessInformationUpdatedFields.installationAddress)
                            }
                        />
                        <div
                            className="GroupItems AddressDetails"
                            style={{ marginBottom: '16px' }}
                        >
                            <SelectField
                                floatLabelOnSelectedValue
                                name="installationCountry"
                                selectWidth="100%"
                                initialOption={'Country'}
                                floatingLabel={'Country'}
                                values={countries.map(option => ({
                                    value: option,
                                }))}
                                handleChange={handleSelectChange}
                                value={inputs?.installationCountry}
                                marginBottom={isMobile ? '16px' : ''}
                                onBlur={() =>
                                    handleSelectOnBlur('installationCountry')
                                }
                                showSearch
                                dropdownPositionRelative
                                withCheckBox
                                currentSelected={inputs?.installationCountry}
                                selectedValue={inputs?.installationCountry}
                                disabled={isCustomerApproved}
                                onFocus={onSelectFocus}
                                showUpdateDot={
                                    isDEPConsumer &&
                                    (basicInformationUpdatedFields.installationCountry ||
                                        businessInformationUpdatedFields.installationCountry)
                                }
                            />
                            <SelectField
                                floatLabelOnSelectedValue
                                name="installationState"
                                floatingLabel="State"
                                initialOption="State"
                                selectWidth="100%"
                                values={stateData?.map(option => ({
                                    value: option,
                                }))}
                                value={inputs?.installationState}
                                marginBottom={isMobile ? '16px' : ''}
                                handleChange={handleSelectChange}
                                onBlur={() =>
                                    handleSelectOnBlur('installationState')
                                }
                                showSearch
                                dropdownPositionRelative
                                withCheckBox
                                currentSelected={inputs?.installationState}
                                selectedValue={inputs?.installationState}
                                disabled={isCustomerApproved}
                                onFocus={onSelectFocus}
                                showUpdateDot={
                                    isDEPConsumer &&
                                    (basicInformationUpdatedFields.installationState ||
                                        businessInformationUpdatedFields.installationState)
                                }
                            />
                            <SelectField
                                initialOption="LGA"
                                values={localGovtData[
                                    inputs?.installationState
                                ]?.map(option => ({
                                    value: option,
                                }))}
                                value={inputs?.installationCity}
                                marginBottom={isMobile ? '16px' : ''}
                                floatLabelOnSelectedValue
                                name="installationCity"
                                floatingLabel="LGA"
                                selectWidth="100%"
                                disabled={
                                    inputs?.installationState?.length < 1 ||
                                    isCustomerApproved
                                }
                                handleChange={handleSelectChange}
                                onBlur={() =>
                                    handleSelectOnBlur('installationCity')
                                }
                                showSearch
                                dropdownPositionRelative
                                withCheckBox
                                currentSelected={inputs?.installationCity}
                                selectedValue={inputs?.installationCity}
                                onFocus={onSelectFocus}
                                showUpdateDot={
                                    isDEPConsumer &&
                                    (basicInformationUpdatedFields.installationCity ||
                                        businessInformationUpdatedFields.installationCity)
                                }
                            />
                        </div>
                    </>
                )}
            </div>
            <DetailsSection />
        </div>
    )
}

export default BasicInformationSection
