import axios from 'src/config/axios'

export const getCreditScoreCard = async customer_type => {
    const request = axios.get(
        `/credit/scorecards?customer_type=${customer_type}`,
    )
    return request
}

export const getCreditDecisionResult = id => {
    const request = axios.get(`/credit/decision/estimations/${id}/result`)
    return request
}
