import { ReactComponent as Logo } from 'src/assets/images/lettermark.svg'
import s from '../styles.module.scss'
const LoadingState = () => {
    return (
        <div className={s.LoadingState}>
            <Logo />
            <h6>Checking Available Packages</h6>
            <p>
                Checking available packages for you. Sit back and relax, this
                will only take a moment.
            </p>
        </div>
    )
}

export default LoadingState
