import axios from '../../config/axios'

export const getEnergySolutionsApi = async (
    pageNumber,
    searchText = '',
    clusterCode = '',
) => {
    const request = await axios.get(
        `/products/packages?page=${pageNumber}&name=${searchText}&cluster_code=${clusterCode}`,
    )
    return request
}

export const getComponentsApi = async () => {
    const request = await axios.get(`/products/components?paginate=false`)
    return request
}

export const createEnergySolutionsApi = async (
    id,
    margin,
    energySolutions,
    clusterCode = '',
) => {
    const request = await axios.post(
        `/estimations/mini/${id}/package-snapshot`,
        margin,
        energySolutions,
        clusterCode,
    )
    return request
}

export const getEstimationByIdApi = async id => {
    const request = await axios.get(`/estimations/${id}`)
    return request
}

export const checkEnergyNeedsMatchApi = async (estimationId, data) => {
    const request = await axios.post(
        `/estimations/mini/${estimationId}/energy-needs/match`,
        data,
    )
    return request
}

export const notifyEnergyNeedsOverrideApi = async (estimationId, data) => {
    const request = await axios.post(
        `/estimations/mini/${estimationId}/energy-needs/notify`,
        data,
    )
    return request
}
