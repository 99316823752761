import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Menu } from 'antd'
import DetailsPage from 'src/components/DetailsPage'
import ComponentTable from './components/componentTable'
import MoreActionsModal from './components/MoreActionsModal'
import ActionModal from 'src/components/ActionModal'
import AddAttribute from './components/AddAttribute'

import { getPermissions, moreActions } from './data'
import {
    deleteComponentTypeApi,
    getSingleComponentTypeApi,
    removeComponentTypeAttributeApi,
} from 'src/api/admin/settings/componenttypes'
import { useQuery } from 'react-query'
import { format, parseISO } from 'date-fns'
import EditAttribute from './components/EditAttribute'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'

const AdminComponentTypes = () => {
    const { canDeleteComponentType, canUpdateComponentType } = getPermissions()
    const [action, setAction] = useState('')
    const [showMoreActionsModal, setShowMoreActionsModal] = useState(false)
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [attrToRemove, setAttrToRemove] = useState('')
    const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
    const [showEditAttributeModal, setShowEditAttributeModal] = useState(false)
    const [editAttributeData, setEditAttributeData] = useState()
    const [component, setComponent] = useState([])
    const [attributes, setAttributes] = useState([])
    const [errorToast, setErrorToast] = useState(false)
    const [errorToastMessage, setErrorToastMessage] = useState('')
    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState('')
    const params = useParams()

    const {
        refetch: singleComponentRefetch,
        isFetching: singleComponentFetching,
    } = useQuery(
        'fetch-single-component-type',
        () => getSingleComponentTypeApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setComponent(data?.data?.data)
                setAttributes(data?.data?.data?.attributes)
            },
            onError: error => {
                setErrorToast(true)
                setErrorToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: deleteComponentRefetch,
        isLoading: deleteComponentLoading,
    } = useQuery(
        'delete-component-type',
        () => deleteComponentTypeApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setWarningModalOpen(false)
                setSuccessModalOpen(true)
                window.location.href = '/admin/settings?tab=4'
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: removeAttributeRefetch,
        isLoading: removeAttributeLoading,
    } = useQuery(
        'remove-attribute',
        () => removeComponentTypeAttributeApi(params?.id, attrToRemove),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setWarningModalOpen(false)
                setSuccessModalOpen(true)
                singleComponentRefetch()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        singleComponentRefetch()
    }, [singleComponentRefetch])

    const handleMoreActions = action => {
        setAction(action)
        if (action === 'delete-component-type') {
            setWarningModalOpen(true)
        }
        if (action === 'rename-component-type') {
            setShowMoreActionsModal(true)
        }
    }

    const handleMoreActionsSubmit = () => {
        if (action === 'rename-component-type') {
            setShowMoreActionsModal(false)
            singleComponentRefetch()
            setSuccessModalOpen(true)
        }
    }

    const handleShowAddAttribute = () => {
        // setAction('add-attribute')
        setShowAddAttributeModal(true)
    }

    const warningTitleMapping = {
        'remove-attribute': 'Remove Attribute?',
        'delete-component-type': 'Delete Component Type?',
        'view-user': 'Exit Settings?',
    }

    const warningBtnMapping = {
        'remove-attribute': 'Remove',
        'delete-component-type': 'Delete',
        'view-user': 'Continue',
    }

    const successTitleMapping = {
        'change-permissions': 'Permissions Changed Successfully',
        'remove-attribute': 'Attribute Removed Successfully',
        'delete-component-type': 'Component Type Deleted',
        'rename-component-type': 'Component Type Renamed Successfully',
        'add-attribute': 'Attribute Added Successfully',
        'edit-attribute': 'Changes Saved',
    }

    const handleWarningModalAction = () => {
        if (action === 'remove-attribute') {
            removeAttributeRefetch()
        }
        if (action === 'delete-component-type') {
            deleteComponentRefetch()
        }
    }

    const handleAddAttribute = () => {
        setAction('add-attribute')
        setShowAddAttributeModal(false)
        setSuccessModalOpen(true)
        singleComponentRefetch()
    }

    const handleEditAttribute = atr => {
        setAction('edit-attribute')
        setEditAttributeData(atr)
        setShowEditAttributeModal(true)
    }

    //func to remove attribute
    const handleRemoveAttribute = id => {
        setAction('remove-attribute')
        setAttrToRemove(id)
        setWarningModalOpen(true)
    }

    const closeMoreActionsModal = () => {
        setShowMoreActionsModal(false)
    }

    const menu = (
        <Menu style={{ marginTop: '0' }}>
            {moreActions.map((each, i) => (
                <Menu.Item
                    key={i}
                    disabled={
                        (each?.action === 'rename-component-type' &&
                            !canUpdateComponentType) ||
                        (each?.action === 'delete-component-type' &&
                            !canDeleteComponentType)
                    }
                >
                    <span
                        className="AdminProvidersActiveDropdownLinks ComponentTypes"
                        onClick={() => handleMoreActions(each?.action)}
                    >
                        {each.label}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <div>
            {/*warning modal*/}
            <ActionModal
                actionType="warning"
                actionModalOpen={warningModalOpen}
                headerText={warningTitleMapping[action]}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText={warningBtnMapping[action]}
                closable
                onCancel={() => setWarningModalOpen(false)}
                closeModal={() => setWarningModalOpen(false)}
                actionHandler={() => handleWarningModalAction()}
                noBackLink
                loading={deleteComponentLoading || removeAttributeLoading}
                toastError={modalError}
                errorMessage={modalErrorMessage}
            />

            {/*success modal*/}
            <ActionModal
                actionType="success"
                actionModalOpen={successModalOpen}
                headerText={successTitleMapping[action]}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                closable
                onCancel={() => setSuccessModalOpen(false)}
                actionHandler={() => setSuccessModalOpen(false)}
                noBackLink
                noCancelBtn
            />
            <MoreActionsModal
                showModal={showMoreActionsModal}
                onCancel={closeMoreActionsModal}
                action={action}
                handleSubmit={handleMoreActionsSubmit}
                name={component?.name}
                id={params?.id}
            />
            <AddAttribute
                showModal={showAddAttributeModal}
                onCancel={() => {
                    setShowAddAttributeModal(false)
                }}
                handleAddAttribute={handleAddAttribute}
                id={params?.id}
            />
            <EditAttribute
                showModal={showEditAttributeModal}
                onCancel={() => setShowEditAttributeModal(false)}
                editAttributeData={editAttributeData}
                id={params?.id}
                handleSuccess={() => {
                    setShowEditAttributeModal(false)
                    setSuccessModalOpen(true)
                    singleComponentRefetch()
                }}
            />
            {/* page */}
            {errorToast && (
                <Toast messageType="error" message={errorToastMessage} />
            )}
            <DetailsPage
                backNavTitle="Back to Settings"
                backNavUrl="/admin/settings?tab=4"
                name={component?.name}
                useCustomTable
                noStatusFlag
                customTable={
                    <ComponentTable
                        componentAttributes={attributes}
                        handleEditAttribute={handleEditAttribute}
                        removeAttribute={handleRemoveAttribute}
                        isFetching={singleComponentFetching}
                    />
                }
                infoSubheadFontSize={'16px'}
                rightSectionHeading="Provider Details"
                rowCount={2}
                gridItemOneHeading="CREATION DATE"
                gridItemOneContent={
                    component?.created_at !== undefined &&
                    format(
                        parseISO(component?.created_at),
                        'dd MMM, yyyy, HH:mm',
                    )
                }
                gridItemTwoHeading="CREATED BY"
                gridItemTwoContent={component?.created_by ?? 'N/A'}
                gridItemThreeHeading="NO. OF ATTRIBUTES"
                gridItemThreeContent={attributes?.length}
                actionButton
                buttonOneText="Add Attribute"
                buttonOneHandleClick={handleShowAddAttribute}
                buttonOneDisabled={!canUpdateComponentType}
                buttonOneTooltipText={
                    !canUpdateComponentType
                        ? 'You are not authorised to perform this action'
                        : null
                }
                secondButton
                secondButtonType="moreActions"
                buttonTwoText="More Actions"
                buttonOneWidth="147px"
                dropdownMenu={menu}
                isLoading={singleComponentFetching}
                noTopInfo
            />
        </div>
    )
}

export default AdminComponentTypes
