import { permissionsControl } from 'src/utils/permissionsControl'

export const providerStatus = [
    {
        id: 'APPROVED',
        label: 'Approved',
    },
    {
        id: 'UNAPPROVED',
        label: 'Unapproved',
    },
    {
        id: 'SUSPENDED',
        label: 'Suspended',
    },
]

export const getPermissions = () => {
    const canViewProviderDetails = permissionsControl([
        'can_view_provider_detail',
    ])

    return {
        canViewProviderDetails,
    }
}
