import PropTypes from 'prop-types'
import RequiredInfo from 'src/components/GettingStarted/components/RequiredInfo'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const WelcomeModal = ({
    showModal,
    useType,
    firstName,
    onCancel,
    onClick,
    secondaryLoading,
}) => {
    const { isMobile } = useMediaQueries()

    return (
        <SecondaryModal
            v2
            showModal={showModal}
            closable={false}
            title={'Welcome to your KYC'}
            content={
                <RequiredInfo
                    heading={`Hi ${firstName}, Welcome to Your KYC`}
                    inputs={{ useType: useType }}
                />
            }
            showFooter
            footerLeftContent={'button'}
            primaryBtnText={'Yes, Continue'}
            handlePrimaryBtnClick={() => onClick()}
            secondaryBtnText={`I don't have these`}
            handleSecondaryBtnClick={() => onCancel()}
            secondaryBtnLoading={secondaryLoading}
            primaryBtnWidth={isMobile ? '128px' : '160px'}
            secondaryBtnWidth={isMobile ? '150px' : '160px'}
        />
    )
}

WelcomeModal.propTypes = {
    showModal: PropTypes.bool,
    useType: PropTypes.string,
    firstName: PropTypes.string,
    onCancel: PropTypes.func,
    onClick: PropTypes.func,
    secondaryLoading: PropTypes.bool,
}

export default WelcomeModal
