import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
import SeoComponent from 'src/components/Seo'
import { useParams, useHistory } from 'react-router-dom'
import useGetPlanInfo from './hooks/useGetPlanInfo'
import styles from './paymentPlanDetails.module.scss'
import MainDetails from './components/mainDetails/MainDetails'
import AsideDetails from './components/asideDetails/AsideDetails'
import AdminApprovedProvider from './hooks/planProvider'

const PaymentPlanDetails = ({ recommendationType = 'auto' }) => {
    const params = useParams()
    const history = useHistory()
    const {
        paymentPlanData,
        isPaymentPlanLoading,
        paymentPlanDataRefetch,
        isRecommendedSolutionsLoading,
    } = useGetPlanInfo(params?.id, recommendationType)

    const pageTitle =
        recommendationType === 'alternative'
            ? 'SunFi - Admin Alternative Recommendations Details'
            : 'SunFi - Admin Payment Plans | Plan Details'

    return (
        <AdminApprovedProvider>
            <>
                <SeoComponent
                    title={pageTitle}
                    tracker="AdminPaymentPlansTracker"
                />
                <main className={styles.paymentPlanContainer}>
                    <BackNav
                        title={
                            recommendationType === 'alternative'
                                ? 'Back to alternative recommendations'
                                : 'Back to payment plans'
                        }
                        top="-40px"
                        onClick={() => {
                            if (recommendationType !== 'alternative') {
                                history.replace('/admin/plans')
                            }
                        }}
                        url={
                            recommendationType === 'alternative' &&
                            '/admin/alternative-recommendations'
                        }
                        v2
                    />

                    <div className={styles.paymentPlanContainer__content}>
                        <MainDetails
                            paymentPlanData={paymentPlanData}
                            isPaymentPlanLoading={isPaymentPlanLoading}
                            recommendationType={recommendationType}
                        />
                        <div
                            className={
                                styles.paymentPlanContainer__content__divider
                            }
                        />
                        <AsideDetails
                            paymentPlanData={paymentPlanData}
                            isPaymentPlanLoading={isPaymentPlanLoading}
                            paymentPlanDataRefetch={paymentPlanDataRefetch}
                            isRecommendedSolutionsLoading={
                                isRecommendedSolutionsLoading
                            }
                            recommendationType={recommendationType}
                        />
                    </div>
                </main>
            </>
        </AdminApprovedProvider>
    )
}

PaymentPlanDetails.propTypes = {
    recommendationType: PropTypes.oneOf(['auto', 'alternative']),
}

export default PaymentPlanDetails
