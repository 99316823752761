//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useContext, useEffect, useState, useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { getAllComponentsApi } from '../../../api/products/library'
import { AppButton } from '../../../components/Button'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import {
    numberWithCommas,
    replaceHtmlTags,
    subStringText,
} from '../../../utils/formatting'
import Toast from '../../../components/Toast'
import UpArrow from '../../../assets/images/blackUpArrow.svg'
import DownArrow from '../../../assets/images/blackDownArrow.svg'
import {
    updateAltRecommendationSnapshotApi,
    updateRecommendationContextApi,
    updateSolutionComponents,
} from '../../../api/requests/recommendations'
import { InlineLoader } from '../../../components/Loader'
import { formatPackageComponents } from '../../../components/ProductPackage/EditPackage/formatter'
import {
    getSingleAlternativePackageApi,
    getSinglePackageApi,
} from '../../../api/products/packages'

import {
    MultipleSelectField,
    SelectField,
} from '../../../components/InputFields'

import CloseIcon from '../../../assets/images/close-icon.svg'

import styles from './energyrecommendation.module.scss'
import { isMobile } from 'src/utils/mediaQueries'
import {
    remarkOptions,
    remarkMapping,
    remarkMappingReverse,
} from 'src/utils/recommendations'
import { errorHandler } from '../../../utils/errorHandler'
import { recommendationContextValidationSchema } from '../../../utils/validationSchema'
import Editor from 'src/components/Editor'

const EnergyRecommendation = ({
    closeModal,
    energySolution,
    saveNewPaymentPlans,
    selectedSolutions,
    selected,
    setSelected = () => {},
    estimationId,
    handleTriggerRefetch,
    triggerSetSolution,
    module = 'energy',
    dropOffId,
    handleUpdateAltPackageSuccess,
    setExtensivePackageData,
}) => {
    const {
        label,
        Title,
        SubTitle,
        Wrapper,
        ComponentTitle,
        ButtonWrapper,
        ComponentType,
        ComponentAmount,
        ComponentQty,
        ComponentDetails,
        // SelectedComponents,
        InputTitle,
        EnergyCapacity,
    } = styles

    const [allComponents, setAllComponents] = useState([])
    const [componentList, setComponentList] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [components, setComponents] = useState([])
    const [count, setCount] = useState([])
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [margin, setMargin] = useState(0)
    const [updatedSolution, setUpdatedSolution] = useState({})
    const [messageType, setMessageType] = useState('error')
    const [, setPackageComponent] = useState([])
    const [inputs, setInputs] = useState({ description: '', remark: '' })
    const [errors, setErrors] = useState({})
    const [additionalEnergySolutionInfo, setAdditionalEnergySolutionInfo] =
        useState({})

    const persistedComponents =
        module === 'alt-recommendations'
            ? additionalEnergySolutionInfo?.components
            : energySolution?.components || []
    const persistedMargin =
        module === 'alt-recommendations'
            ? additionalEnergySolutionInfo?.margin
            : energySolution?.margin || 0
    const disableRecommendationContextContinue =
        (inputs.description === energySolution?.recommendation_context?.note ||
            inputs.description ===
                energySolution?.recommendation_context?.recommendation_note) &&
        inputs.remark ===
            remarkMapping[energySolution?.recommendation_context?.remark]
    //fetch all components
    const { isFetching: fetchComponentsLoading, refetch: fetchComponents } =
        useQuery(['fetchAllComponents'], () => getAllComponentsApi(), {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setComponentList(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch components. Refresh page')
            },
        })
    const { refetch: packageRefetch, isFetching: packageFetching } = useQuery(
        ['fetchSinglePackage'],
        () => {
            if (module === 'alt-recommendations') {
                return getSingleAlternativePackageApi(
                    dropOffId,
                    energySolution?.id,
                )
            } else return getSinglePackageApi(energySolution?.id)
        },
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPackageComponent(
                    formatPackageComponents(data?.data?.data?.components),
                )
                if (module === 'alt-recommendations') {
                    setExtensivePackageData(data?.data?.data)
                    setAdditionalEnergySolutionInfo({
                        components: data?.data?.data?.components,
                        margin: data?.data?.data?.margin,
                    })
                }
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch package. Refresh page`)
            },
        },
    )

    useEffect(
        function () {
            const allComponentsList = componentList

            //compare and update recommended_solutions component with name and type
            let formatPreselectedComponents = persistedComponents?.map(
                function (x) {
                    var result = componentList?.filter(a1 => a1.id === x.id)
                    if (result.length > 0) {
                        x.name = result[0].name
                        x.type = result[0].type.name
                        x['count'] = x['quantity']
                        x['cost'] = x['total_cost']
                        x['realCost'] = x['unit_cost']
                    }
                    return x
                },
            )

            let formatPackageComponentList = formatPreselectedComponents?.map(
                item => {
                    return {
                        ...item,
                        value: `${
                            item?.name || item?.component_name
                        } (₦${currencyFormatter(
                            item?.realCost || item?.unit_cost,
                        )} | ${item?.type || 'battery'})`,
                        disabled: true,
                    }
                },
            )

            // const packageComponentsList = formatPreselectedComponents
            let formatAllComponentList = allComponentsList?.map(item => {
                return {
                    ...item,
                    value: `${item?.name} (₦${currencyFormatter(
                        item?.cost,
                    )} | ${item?.type?.name})`,
                }
            })

            let countList = []
            let checked = []

            formatPackageComponentList?.forEach(item => {
                checked.push(item.value)
                countList.push({
                    id: item.id,
                    cost: item.cost,
                    count: item?.count,
                    realCost: parseFloat(item?.realCost, 10).toFixed(2),
                })
            })
            setCount(countList)
            setAllComponents(formatAllComponentList)
            setSelected(formatPackageComponentList)
        },
        [componentList, energySolution, additionalEnergySolutionInfo],
    )
    useEffect(() => {
        fetchComponents()
        packageRefetch()
    }, [])

    useEffect(() => {
        packageRefetch()
    }, [energySolution])

    useEffect(() => {
        setInputs({
            description:
                energySolution?.recommendation_context?.recommendation_note ||
                energySolution?.recommendation_context?.note,
            remark: remarkMapping[
                energySolution?.recommendation_context?.remark
            ],
        })
    }, [triggerSetSolution])

    const handleSelectChange = useCallback(
        value => {
            setComponents([...components, value])
            let filteredComponent = allComponents.find(item => {
                const formattedItem = `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`
                if (formattedItem == value) return item
            })

            let preselectedfilterComponent = [...selected, filteredComponent]
            const updatedSelected = preselectedfilterComponent?.map(
                component => {
                    return {
                        ...component,
                        estimatedValue: `${
                            component?.name
                        } (₦${numberWithCommas(component?.cost)} | ${
                            component?.type
                        })`,
                        count: component?.count || 1,
                        realCost:
                            component?.realCost === undefined
                                ? component?.cost
                                : component?.realCost,
                        disabled: false,
                    }
                },
            )
            const countList = updatedSelected.map(item => ({
                id: item.id,
                cost: item.cost,
                count: item?.count,
                realCost: item?.realCost,
            }))
            setCount(countList)
            setSelected(updatedSelected)
        },
        [allComponents, selected],
    )
    const getCount = componentId => {
        return count?.filter(value => value.id === componentId)[0]?.count
    }
    const updateCount = (currentCount, action, componentId, cost, realCost) => {
        let newCount = {
            id: componentId,
            cost: cost,
            count: currentCount,
            realCost,
        }
        if (action === 'add') {
            newCount.count = currentCount + 1
            newCount.cost = realCost * (currentCount + 1)
        }
        if (action === 'subtract' && currentCount > 1) {
            newCount.count = currentCount - 1
            newCount.cost = realCost * (currentCount - 1)
        }

        let countCopy = [...count]
        let selectedCopy = [...selected]

        let found = countCopy?.findIndex(q => q.id === componentId)
        let foundSelected = selectedCopy.findIndex(q => q.id === componentId)

        if (found > -1) {
            countCopy[found] = newCount
        }

        if (foundSelected > -1) {
            selectedCopy[foundSelected] = {
                ...selectedCopy[foundSelected],
                cost: newCount.cost,
                count: newCount.count,
            }
        }

        const updatedSelectedComponents = selectedCopy.map(component => {
            return {
                ...component,
                disabled: false,
            }
        })

        setCount(countCopy)
        setSelected(updatedSelectedComponents)
    }

    const handleCancel = id => {
        if (selected.length > 1) {
            let newComponent = selected?.filter(data => data.id !== id)
            const checked = newComponent?.map(data => data.value)

            let countList = []

            newComponent?.forEach(item => {
                countList.push({
                    id: item?.id,
                    cost: item?.cost,
                    count: item?.count,
                    realCost: item?.realCost,
                })
            })

            const filteredComponents = newComponent.map(component => {
                return {
                    ...component,
                    disabled: false,
                }
            })

            setCount(countList)
            setComponents(checked)
            setSelected(filteredComponents)
        } else {
            setToastError(true)
            setErrorMessage('One or more components must be provided')
        }
    }

    useEffect(() => {
        let calculateSubtotal = count?.reduce(
            (total, item) => total + item?.realCost * item?.count,
            0,
        )
        const marginValue = Math.abs(
            (persistedMargin / 100) * calculateSubtotal,
        )
        let total = calculateSubtotal + marginValue
        setMargin(marginValue)
        setSubtotal(calculateSubtotal)
        setTotal(total)
        // eslint-disable-next-line
    }, [count, selected])

    let componentIds = []
    selected?.forEach(item => {
        componentIds.push({
            id: item?.id,
            quantity: item.count,
        })
    })
    //update the components for the selected solution

    const { isLoading: componentLoading, refetch: updateComponentRefetch } =
        useQuery(
            'update-selected-solution-components',
            () =>
                updateSolutionComponents({
                    estimationId: actionType?.requestDetails?.estimation?.id,
                    packageId: energySolution?.id,
                    components: componentIds,
                }),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setToastError(true)
                    setMessageType('success')
                    setErrorMessage('Changes have been saved')
                    setUpdatedSolution(data?.data?.data)
                    saveNewPaymentPlans(data?.data?.data)
                },
                onError: () => {
                    setToastError(true)
                    setErrorMessage('Failed to save changes')
                },
            },
        )

    //update the description and remark for a recommendation
    const {
        refetch: updateRecommendationContextRefetch,
        isLoading: updateRecommendationContextLoading,
    } = useQuery(
        'update-recommendation-context',
        () =>
            updateRecommendationContextApi(
                estimationId,
                inputs?.description,
                remarkMappingReverse[inputs?.remark],
                energySolution?.id,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setToastError(true)
                setMessageType('success')
                setErrorMessage('Changes have been saved')
                setUpdatedSolution(data?.data?.data)
                handleTriggerRefetch()
            },
            onError: error => {
                const errObj = error?.response?.data
                setToastError(true)
                setErrorMessage(errorHandler(errObj))
            },
        },
    )
    const updateAltPackageSnapshotMutation = useMutation({
        mutationFn: () => {
            updateAltRecommendationSnapshotApi(dropOffId, energySolution?.id, {
                margin: persistedMargin?.margin,
                remark: remarkMappingReverse[inputs?.remark],
                recommendation_note: replaceHtmlTags(inputs?.description),
                components: componentIds,
            })
        },
        onSuccess: () => {
            setToastError(true)
            setMessageType('success')
            setErrorMessage('Changes have been saved')
            handleUpdateAltPackageSuccess()
        },
        onError: error => {
            const errObj = error?.response?.data
            setToastError(true)
            setErrorMessage(errorHandler(errObj))
        },
    })
    const handleSave = () => {
        if (
            !disableRecommendationContextContinue ||
            module === 'alt-recommendations'
        ) {
            recommendationContextValidationSchema
                .validate(inputs, { abortEarly: false })
                .then(() => {
                    setErrors({})
                    if (module === 'alt-recommendations') {
                        updateAltPackageSnapshotMutation.mutate()
                    } else updateRecommendationContextRefetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })
                    setErrors(errList)
                })
        }

        if (
            selected?.length > 0 &&
            !selected?.[0]?.disabled &&
            module !== 'alt-recommendations'
        ) {
            updateComponentRefetch()
        }
    }

    const handleSelectpackage = () => {
        // send updated package if changes was made

        let selectedPackage =
            Object.keys(updatedSolution).length === 0
                ? energySolution
                : updatedSolution
        closeModal(true, selectedPackage)
    }

    const checkSelected = data => {
        return selectedSolutions?.find(sol => sol.id === data.id)
    }
    const altRecommendationsActionTypeObj = {
        type: 'review',
        requestDetails: {
            estimation: {
                id: estimationId,
            },
        },
    }
    const actionType = altRecommendationsActionTypeObj

    const handleChange = (name, value) => {
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleTextAreaChange = value => {
        setInputs(preState => ({
            ...preState,
            description: value,
        }))
    }
    const isFetching = fetchComponentsLoading || packageFetching
    return (
        <div className={Wrapper}>
            {toastError && (
                <Toast
                    messageType={messageType}
                    message={fetchComponentsLoading ? '' : errorMessage}
                    position="fixed"
                    top={10}
                    left={0}
                />
            )}
            <p className={label}>BEST MATCH</p>
            <h1 className={Title}>
                {energySolution?.package_name || energySolution?.name}
                <span className={EnergyCapacity}>
                    (
                    {energySolution?.package_energy_capacity?.value ||
                        energySolution?.energy_capacity?.value}
                    {energySolution?.package_energy_capacity
                        ?.unit_of_measurement ||
                        energySolution?.energy_capacity?.unit_of_measurement}
                    )
                </span>
            </h1>
            <div>
                <p className={SubTitle}>DESCRIPTION</p>
                <Editor
                    editorHeight={'137px'}
                    editorWidth={'100% !important'}
                    placeholder={
                        inputs.description === '' || inputs.description === null
                            ? 'Description'
                            : ''
                    }
                    name={'description'}
                    value={inputs.description}
                    onChangeHandler={value => handleTextAreaChange(value)}
                    errorMessage={errors.description}
                    disabled={actionType?.type === 'preview'}
                    bulletOnly
                    quillFullWidth
                />
            </div>

            <div>
                <p className={SubTitle}>REMARK</p>
                <SelectField
                    selectWidth="100%"
                    name={'remark'}
                    type={'approvedModal'}
                    initialOption={
                        inputs.remark === '' ? 'Select Remark' : inputs.remark
                    }
                    value={inputs.remark}
                    values={remarkOptions.map(option => ({
                        value: option,
                    }))}
                    withCheckBox={true}
                    optionStyle={{
                        width: '100%',
                        marginTop: '4px',
                        marginBottom: '4px',
                    }}
                    handleChange={handleChange}
                    currentSelected={inputs.remark}
                    selectedBlue={true}
                    errorMessage={errors.remark}
                    disabled={actionType?.type === 'preview'}
                />
            </div>
            <div>
                <p className={ComponentTitle}>Components Information</p>
                <p className={InputTitle}>Select Component</p>
                <MultipleSelectField
                    values={allComponents}
                    value={selected?.map(item => item.value)}
                    onSelect
                    handleMultipleSelectChange2={handleSelectChange}
                    selectWidth="99%"
                    dropDownWidth="390px"
                    title="Select Component"
                    inputValue={selected?.map(item => item.value)}
                    maxTagCount="responsive"
                    maxTagPlaceholder="Select Component"
                    mobileWidth
                    width={'100%'}
                    disabled={
                        isFetching
                            ? true
                            : actionType?.type === 'preview'
                            ? true
                            : false
                    }
                />
                <div style={{ height: '150px', overflowY: 'auto' }}>
                    <div>
                        <table style={{ marginTop: isMobile ? 20 : 0 }}>
                            {isFetching
                                ? [...Array(4)].map(i => (
                                      <Skeleton.Input
                                          key={i}
                                          active
                                          className="EstimationSkeletalField"
                                      />
                                  ))
                                : selected?.length > 0 &&
                                  selected?.map(item => {
                                      return (
                                          <tr
                                              key={item?.id}
                                              className="StepOneSelectedComponents"
                                          >
                                              <td
                                                  className="ERComponentsFirstTD"
                                                  style={{
                                                      width: isMobile
                                                          ? '88%'
                                                          : '50%',
                                                  }}
                                              >
                                                  <div className="ERComponentsFirstTDDiv">
                                                      <span className="ERComponentsFirstTDSelectedName">
                                                          {subStringText(
                                                              item?.name,
                                                              30,
                                                          )}{' '}
                                                      </span>
                                                  </div>
                                                  <span className="ERComponentAmountMobile">
                                                      NGN{' '}
                                                      {currencyFormatter(
                                                          item?.realCost,
                                                      )}
                                                  </span>
                                              </td>
                                              <td
                                                  className={ComponentType}
                                                  style={{ width: '30%' }}
                                              >
                                                  <span className="">
                                                      {subStringText(
                                                          item?.type?.name ||
                                                              item?.type,
                                                          9,
                                                      )}
                                                  </span>
                                              </td>
                                              {!isMobile && (
                                                  <td
                                                      className={
                                                          ComponentAmount
                                                      }
                                                      style={{ width: '25%' }}
                                                  >
                                                      <span className="SelectedCapacity">
                                                          &#8358;
                                                          {currencyFormatter(
                                                              item?.realCost,
                                                          )}
                                                      </span>
                                                  </td>
                                              )}
                                              <td
                                                  className={ComponentQty}
                                                  style={{
                                                      width: '7%',
                                                      fontWeight: '600',
                                                  }}
                                              >
                                                  <div className="Scroll">
                                                      <span
                                                          className="QuantityImageWrapper"
                                                          onClick={() =>
                                                              actionType.type !==
                                                                  'preview' &&
                                                              updateCount(
                                                                  getCount(
                                                                      item.id,
                                                                  ),
                                                                  'add',
                                                                  item?.id,
                                                                  item?.cost,
                                                                  item?.realCost,
                                                              )
                                                          }
                                                          disabled={
                                                              count >= 10
                                                                  ? true
                                                                  : false
                                                          }
                                                          style={{
                                                              cursor:
                                                                  count >= 10
                                                                      ? 'not-allowed'
                                                                      : actionType?.type ===
                                                                            'preview' &&
                                                                        'not-allowed',
                                                          }}
                                                      >
                                                          <img
                                                              src={UpArrow}
                                                              className="Arrow"
                                                              alt="up-arrow-icon"
                                                          />
                                                      </span>

                                                      <span className="Quantity">
                                                          {getCount(item.id)}
                                                      </span>
                                                      <span
                                                          className="QuantityImageWrapper"
                                                          onClick={() =>
                                                              actionType.type !==
                                                                  'preview' &&
                                                              updateCount(
                                                                  getCount(
                                                                      item.id,
                                                                  ),
                                                                  'subtract',
                                                                  item?.id,
                                                                  item?.cost,
                                                                  item?.realCost,
                                                              )
                                                          }
                                                          disabled={
                                                              count <= 1
                                                                  ? true
                                                                  : false
                                                          }
                                                          style={{
                                                              cursor:
                                                                  count <= 1
                                                                      ? 'not-allowed'
                                                                      : actionType?.type ===
                                                                            'preview' &&
                                                                        'not-allowed',
                                                          }}
                                                      >
                                                          <img
                                                              src={DownArrow}
                                                              className="Arrow"
                                                              alt="down-arrow-icon"
                                                          />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <span
                                                      onClick={() =>
                                                          actionType.type !==
                                                              'preview' &&
                                                          handleCancel(item.id)
                                                      }
                                                      style={{
                                                          cursor:
                                                              actionType.type ===
                                                                  'preview' &&
                                                              'not-allowed',
                                                      }}
                                                  >
                                                      <img
                                                          src={CloseIcon}
                                                          alt="close-icon"
                                                      />
                                                  </span>
                                              </td>
                                          </tr>
                                      )
                                  })}
                        </table>
                    </div>
                </div>
                {subtotal > 0 && (
                    <div className={`${ComponentDetails}`}>
                        <p
                            style={{
                                fontWeight: persistedMargin === 0 && '600',
                            }}
                        >
                            {persistedMargin === 0 ? 'Total' : 'Subtotal'}
                        </p>
                        <p className="Subtotal">
                            &#8358;
                            {currencyFormatter(subtotal)}
                        </p>
                    </div>
                )}

                {persistedMargin !== 0 && (
                    <div className={ComponentDetails}>
                        <p>Margin</p>
                        <p
                            style={{
                                color: persistedMargin > 0 ? '#32CC79' : 'red',
                                fontWeight: '600',
                            }}
                        >{`${
                            persistedMargin > 0 ? '+' : ''
                        }${persistedMargin}% (₦${currencyFormatter(
                            margin,
                        )})`}</p>
                    </div>
                )}

                {persistedMargin !== 0 && (
                    <div className={ComponentDetails}>
                        <p style={{ fontWeight: '600' }}>Total</p>

                        <p style={{ fontWeight: '600' }}>
                            &#8358;{currencyFormatter(total)}
                        </p>
                    </div>
                )}
                <div className={ButtonWrapper}>
                    {actionType?.type !== 'preview' && (
                        <AppButton
                            type={
                                module === 'alt-recommendations'
                                    ? ''
                                    : 'buttonTwo'
                            }
                            btnTextColor={
                                module === 'alt-recommendations'
                                    ? 'var(--white)'
                                    : 'var(--blue)'
                            }
                            btnBgColor={
                                module === 'alt-recommendations'
                                    ? 'var(--blue)'
                                    : '#E2EEFF'
                            }
                            btnWidth="160px"
                            btnHeight="56px"
                            disabled={
                                selected?.length !== 0 &&
                                selected?.[0]?.disabled &&
                                disableRecommendationContextContinue &&
                                !packageFetching
                            }
                            handleClick={handleSave}
                        >
                            {componentLoading ||
                            updateRecommendationContextLoading ||
                            updateAltPackageSnapshotMutation.isLoading ? (
                                <InlineLoader />
                            ) : (
                                'Save Changes'
                            )}
                        </AppButton>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {module !== 'alt-recommendations' && (
                        <AppButton
                            btnTextColor="var(--white)"
                            btnBgColor="var(--blue)"
                            btnWidth="160px"
                            btnHeight="56px"
                            disabled={
                                !checkSelected(energySolution) &&
                                selectedSolutions?.length === 3
                                    ? true
                                    : (selected?.length !== 0 &&
                                          selected?.[0]?.disabled) === false
                                    ? true
                                    : false
                            }
                            handleClick={
                                actionType?.type === 'preview'
                                    ? closeModal
                                    : handleSelectpackage
                            }
                        >
                            {actionType?.type === 'preview'
                                ? 'Okay, Got it'
                                : checkSelected(energySolution)
                                ? 'Unselect'
                                : 'Select Package'}
                        </AppButton>
                    )}
                </div>
            </div>
        </div>
    )
}

EnergyRecommendation.propTypes = {
    closeModal: PropTypes.any,
    energySolution: PropTypes.any,
    saveNewPaymentPlans: PropTypes.any,
    selectedSolutions: PropTypes.any,
    selected: PropTypes.any,
    setSelected: PropTypes.func,
    module: PropTypes.string,
}

export default EnergyRecommendation
