import { useMutation } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { onePageSubmitKycForReviewProviderApi } from 'src/api/shoppingExperience/kyc/api'

export const useProviderSendKycForReview = ({ onSuccess }) => {
    const { errorAlert } = useCustomToast()

    const { isLoading, mutate } = useMutation({
        mutationFn: ({ id, payload }) =>
            onePageSubmitKycForReviewProviderApi({
                data: payload,
                estimationID: id,
            }),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
    return {
        providerSendKycForReviewLoading: isLoading,
        providerSendKycForReview: mutate,
    }
}
