import styles from './styles.module.scss'
import SearchAndFilterPanel from '../Workspace/components/SearchAndFilterPanel'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { convertQueryParamsToObject } from 'src/utils/formatting'
import { useQuery } from 'react-query'
import {
    getAllClusters,
    getClusterSummary,
} from 'src/api/representative/clusters'
import { useEffect, useState } from 'react'
import FilterDrawer from '../Workspace/components/FilterDrawer'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateClusterFilter,
    updatedClusterFilters,
} from 'src/redux/reducers/representative/clusters/listing'
import { decodeUserInfo } from 'src/utils/auth'
import { getGreetingTime } from 'src/utils/formatting'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import WelcomeModal from '../Workspace/components/WelcomeModal'
import OverviewMetrics from './components/OverviewMetrics'
import ClusterListing from './components/ClusterListing'
import EmptyState from '../Workspace/components/EmptyState'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'

const RepresentativeOverview = () => {
    const [clusterInfo, setClusterInfo] = useState([])
    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false)
    const defaultQueries = useSelector(updatedClusterFilters)
    const [showFiltered, setShowFiltered] = useState(false)
    const [clustersSummary, setClustersSummary] = useState()
    const [currentPage, setCurrentPage] = useState(Number(defaultQueries?.page))
    const [totalPages, setTotalPages] = useState(0)
    const dispatch = useDispatch()
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)

    const initialState = {
        profile_type: defaultQueries?.profile_type ?? '',
        max_cluster_members: defaultQueries?.max_cluster_members ?? '',
        recently_updated: defaultQueries?.recently_updated ?? '',
        recently_created: defaultQueries?.recently_created ?? '',
        name: defaultQueries?.name ?? '',
    }
    const [filterStatus, setFilterStatus] = useState(initialState)
    const userInfo = decodeUserInfo()

    const handleFilterDrawerOpen = () => {
        setFilterDrawerVisible(true)
    }

    const onFilterDrawerClose = () => {
        setFilterDrawerVisible(false)
    }

    const updatePage = page => {
        setCurrentPage(page)
    }

    const closeWelcomeModal = () => {
        setShowWelcomeModal(false)
    }

    const queryKey = [
        'clusterListing',
        filterStatus?.profile_type,
        filterStatus?.max_cluster_members,
        filterStatus?.recently_updated,
        filterStatus?.recently_created,
        filterStatus?.name,
        filterStatus?.page,
        currentPage,
    ]

    const { isLoading, refetch: refetchClusters } = useQuery(
        queryKey,
        () =>
            getAllClusters({
                profileType: filterStatus?.profile_type || '',
                maxClusterMembers: filterStatus?.max_cluster_members || '',
                recentlyUpdated: filterStatus?.recently_updated || '',
                recentlyCreated: filterStatus?.recently_created || '',
                name: filterStatus?.name || '',
                page: currentPage || 1,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateClusterFilter(
                        convertQueryParamsToObject([...queries]),
                    ),
                )
                const data = res?.data?.data
                setClusterInfo(data)
                setTotalPages(res?.data?.total_pages)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    useEffect(() => {
        refetchClusters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filterStatus])

    const { isLoading: fetchingClusterSummary } = useQuery(
        'clusterOverview',
        () => getClusterSummary(),
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                setClustersSummary(data)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    const applyFilter = () => {
        setShowFiltered(true)
        setFilterDrawerVisible(false)
    }

    const resetFilter = () => {
        setShowFiltered(true)
        setFilterStatus({
            profile_type: '',
            max_cluster_members: '',
            recently_updated: '',
            recently_created: '',
            name: '',
            page: 1,
        })
        setFilterDrawerVisible(false)
    }

    useEffect(() => {
        if (userInfo.isFirstTimeLogin === 'true') {
            setShowWelcomeModal(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className={styles.page}>
                <div className={styles.header}>
                    <h5>Cluster overview</h5>
                    <h4>
                        Good {getGreetingTime()}, {userInfo?.firstName ?? ''}
                    </h4>
                </div>

                <OverviewMetrics
                    clustersSummary={clustersSummary}
                    noOfClusters={clusterInfo?.length}
                    fetchingClusterSummary={fetchingClusterSummary}
                />

                <div className={styles.mainContent}>
                    {isLoading && filterStatus.name === '' ? null : (
                        <SearchAndFilterPanel
                            page="overview"
                            handleFilterDrawerOpen={handleFilterDrawerOpen}
                            applyFilter={applyFilter}
                            resetFilter={resetFilter}
                            setFilterStatus={setFilterStatus}
                            filterStatus={filterStatus}
                            membersListFiltered={showFiltered}
                        />
                    )}
                    <VerticalSpacer top={16} />
                    {isLoading ? (
                        <ListingsSkeletalCards total={3} columnCount={5} />
                    ) : (
                        <>
                            {!isLoading &&
                                (clusterInfo.length === 0 &&
                                filterStatus?.name !== '' ? (
                                    <EmptyState
                                        v2
                                        heading="You do not cluster member with that name"
                                        description="Click on the search bar to find another cluster or search by a different name"
                                    />
                                ) : (
                                    <ClusterListing
                                        clusterInfo={clusterInfo}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        updatePage={updatePage}
                                    />
                                ))}
                        </>
                    )}
                </div>
            </div>

            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}

            <FilterDrawer
                visible={filterDrawerVisible}
                onDrawerClose={onFilterDrawerClose}
                page="overview"
                applyFilter={applyFilter}
                resetFilter={resetFilter}
                setFilterStatus={setFilterStatus}
                filterStatus={filterStatus}
            />

            <WelcomeModal
                showModal={showWelcomeModal}
                onCancel={closeWelcomeModal}
                firstName={userInfo?.firstName ?? ''}
            />
        </>
    )
}

export default RepresentativeOverview
