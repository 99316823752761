import { useMutation } from 'react-query'
import {
    sseIdentityVerification,
    sseIdentityVerificationCompare,
    sseAdminIdentityVerificationCompare,
    sseSelfieUpload,
} from './api'
import { onePageIndentityVerificationKycProviderApi } from '../kyc/api'

export const useSSESelfieUpload = ({
    onSuccess,
    onError,
    isProvider,
    estimationID,
}) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => {
            if (isProvider && estimationID) {
                return onePageIndentityVerificationKycProviderApi({
                    data: payload,
                    estimationID,
                })
            }
            return sseSelfieUpload(payload)
        },
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitKycSelfie: mutate,
        kycSelfieLoading: isLoading,
        kycSelfieData: data,
        kycSelfieError: error,
        kycSelfieSuccess: isSuccess,
    }
}

export const useSSEConsumerIdentityUpload = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: payload => sseIdentityVerification(payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitKycResidentialIdentity: mutate,
        kycResidentialIdentityLoading: isLoading,
        kycResidentialIdentityData: data,
        kycResidentialIdentityError: error,
        kycResidentialIdentitySuccess: isSuccess,
    }
}

export const useSSEConsumerIdentityCompare = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: id => sseIdentityVerificationCompare(id),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitKycResidentialIdentityCompare: mutate,
        kycResidentialIdentityCompareLoading: isLoading,
        kycResidentialIdentityCompareData: data,
        kycResidentialIdentityCompareError: error,
        kycResidentialIdentityCompareSuccess: isSuccess,
    }
}

export const useSSEAdminIdentityCompare = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: id => sseAdminIdentityVerificationCompare(id),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitKycResidentialIdentityCompare: mutate,
        kycResidentialIdentityCompareLoading: isLoading,
        kycResidentialIdentityCompareData: data,
        kycResidentialIdentityCompareError: error,
        kycResidentialIdentityCompareSuccess: isSuccess,
    }
}
