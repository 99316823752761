import { ReactComponent as MoneyIcon } from 'src/assets/images/money.svg'
import { ReactComponent as GiftIcon } from 'src/assets/images/gift.svg'
import { numberWithCommas } from 'src/utils/formatting'

export const dashboardMetricsData = data => [
    {
        icon: <MoneyIcon />,
        title: 'total outstanding',
        value: data?.total_outstanding,
        symbol: 'naira',
    },
    {
        icon: <GiftIcon />,
        title: 'monthly repayment',
        value: data?.monthly_repayment,
        symbol: 'naira',
    },
    {
        icon: <GiftIcon />,
        title: 'active members',
        value: data?.active_members,
    },
]

export const actionModalValues = (
    action,
    memberDetails,
    membersWithDuePayment,
    creditReportFiles,
) => {
    const totalDue = membersWithDuePayment?.reduce(
        (total, item) =>
            numberWithCommas(
                total + parseFloat(item.monthly_payment.replaceAll(',', '')),
            ),
        0,
    )
    switch (action) {
        case 'notify-member-warning':
            return {
                actionModalTitle: 'Group members will be notified',
                actionModalHeaderText:
                    'Added members will be notified to select a system',
                actionModalSubtitle:
                    'All added group members will be sent an email, containing a link where they can choose their preferred system size',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'notify-member-success':
            return {
                actionModalTitle: 'Group members will be notified',
                actionModalHeaderText: 'Email sent successfully',
                actionModalSubtitle:
                    'An email has been sent to the group members, containing a link where they can choose their preferred system size',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'make-group-payment-confirmation-request':
            return {
                actionModalTitle: 'Group members payment',
                actionModalHeaderText: `You’re about to make payment for ${membersWithDuePayment?.length} due member(s)`,
                actionModalSubtitle: `Payment for ${membersWithDuePayment?.length} members will be a total of ₦${totalDue}. Please confirm to go ahead.`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'make-payment-confirmation':
            return {
                actionModalTitle: 'Payment confirmation',
                actionModalHeaderText: 'Payment Under Review',
                actionModalSubtitle:
                    'We’re currently reviewing your payment. You’ll receive an email notification once it’s confirmed. Thank you for your patience.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'make-single-payment-confirmation-request':
            return {
                actionModalTitle: 'Group member payment',
                actionModalHeaderText:
                    'You’re about to make payment for a due member',
                actionModalSubtitle: `Payment for ${memberDetails?.name} will be a total of ₦${memberDetails?.monthly_payment}. Please confirm to go ahead.`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'resend-system-selection-link':
            return {
                actionModalTitle: 'Group member will be notified',
                actionModalHeaderText:
                    'Group member will be notified to select a system',
                actionModalSubtitle:
                    'This group member will be sent an email, containing a link where he/she can choose their preferred system size.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'resend-system-selection-link-success':
            return {
                actionModalTitle: 'Group member will be notified',
                actionModalHeaderText: 'Email sent successfully',
                actionModalSubtitle:
                    'An email has been sent to the added group member, containing a link where they can choose their preferred system size.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'remove-member-confirmation-request':
            return {
                actionModalTitle: 'Remove group member',
                actionModalHeaderText: 'You are about to remove a group member',
                actionModalSubtitle: `Removing ${memberDetails?.name} will prevent them from having access to the solutions we have available for them.`,
                actionModalActionType: 'delete',
                actionModalPrimaryBtnText: 'Remove member',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Cancel',
            }
        case 'remove-member-confirmation-success':
            return {
                actionModalTitle: 'Remove group member',
                actionModalHeaderText: 'Group member successfully removed',
                actionModalSubtitle: `${
                    memberDetails?.name ?? 'Member'
                } has been removed from your group.`,
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'upload-multiple-credit-report-request':
            return {
                actionModalTitle: 'Upload multiple credit report',
                actionModalHeaderText:
                    'You are about to upload multiple credit report.',
                actionModalSubtitle: (
                    <>
                        Ensure each document is uniquely named after{' '}
                        <b>the email of each member</b> of your group.
                    </>
                ),
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Continue',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }

        case 'confirm-multiple-credit-reports-upload':
            return {
                actionModalTitle: 'Confirm upload',
                actionModalHeaderText: 'Submit credit report',
                actionModalSubtitle: `You’re about to submit the credit report for the ${creditReportFiles?.length} cluster members, please confirm.`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Continue',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-multiple-credit-reports-upload-success':
            return {
                actionModalTitle: 'Upload successful',
                actionModalHeaderText: 'Credit reports uploaded',
                actionModalSubtitle: `Your uploaded credit reports will be reviewed and you will get notified once review is complete`,
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'remove-credit-report-member-confirmation-request':
            return {
                actionModalTitle: `Remove member's credit report`,
                actionModalHeaderText:
                    'You are about to remove a member from credit report upload',
                actionModalSubtitle: `Removing member means their credit report will not be uploaded`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'remove-credit-report-member-confirmation-success':
            return {
                actionModalTitle: `Remove member's credit report`,
                actionModalHeaderText: 'Member successfully removed',
                actionModalSubtitle: `Member has been removed`,
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'uploading-multiple-cluster-members':
            return {
                actionModalTitle: 'Add your group members',
                actionModalHeaderText: 'Might take some time to upload',
                actionModalSubtitle:
                    'Your upload is currently being processed and may take some time. Please check back later to see if it has been completed.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Okay',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'save-multiple-cluster-members-warning':
            return {
                actionModalTitle: 'Go back',
                actionModalHeaderText:
                    'All your newly added members are not yet saved ',
                actionModalSubtitle:
                    'You’ll need to start this process again if you go back without saving your newly added consumers',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Continue',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Go back',
            }
        case 'failed-upload-cluster-members':
            return {
                actionModalTitle: 'Add your group members',
                actionModalHeaderText: 'You have some failed uploads',
                actionModalSubtitle:
                    'You still have some pending issues from the list of members you uploaded, update those members details before you can continue',
                actionModalActionType: 'delete',
                actionModalPrimaryBtnText: 'Got it',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'upload-multiple-cluster-members-confirmation':
            return {
                actionModalTitle: 'Group members will be notified',
                actionModalHeaderText:
                    'Added members will be notified to select a system',
                actionModalSubtitle:
                    'All added group members will be sent an email, containing a link where they can choose their preferred system size',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'upload-multiple-cluster-members-success':
            return {
                actionModalTitle: 'Group members will be notified',
                actionModalHeaderText: 'Email sent successfully',
                actionModalSubtitle:
                    'An email has been sent to all added group members, containing a link where they can choose their preferred system size',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'remove-group-member-from-upload-confirmation-request':
            return {
                actionModalTitle: `Remove member`,
                actionModalHeaderText:
                    'You are about to remove a member from this upload',
                actionModalSubtitle: `Removing member means their details will not be uploaded`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const uploadModalValues = action => {
    switch (action) {
        case 'upload-multiple-credit-report':
            return {
                uploadModalTitle: 'Upload multiple credit report',
                acceptableFileTypes: { 'application/pdf': 'PDF' },
                showUploadList: false,
                maxCount: 10,
                acceptsMultiple: true,
            }
        case 'upload-multiple-group-members':
            return {
                uploadModalTitle: 'Add your group members',
                acceptableFileTypes: {
                    'text/csv': '.csv',
                },
                showUploadList: true,
                maxCount: 1,
                acceptsMultiple: false,
            }
        case 'single-credit-upload':
            return {
                uploadModalTitle: 'Upload credit report',
                acceptableFileTypes: { 'application/pdf': 'PDF' },
                showUploadList: true,
                maxCount: 1,
                acceptsMultiple: false,
            }
        default:
            return {
                uploadModalTitle: '',
                acceptableFileTypes: { 'application/pdf': 'PDF' },
                showUploadList: true,
                maxCount: 1,
            }
    }
}

export const formatMemberData = data => {
    return {
        basicInfo: [
            { label: 'Name', value: data?.name },
            { label: 'Location', value: data?.location },
            { label: 'Phone Number', value: data?.phone_number },
            { label: 'Email Address', value: data?.email_address },
        ],
        identityVerification: [
            { label: 'ID Card Type', value: data?.identity_verification?.type },
            {
                label: 'ID Card Number',
                value: data?.identity_verification?.number,
            },
        ],
    }
}

export const formatPackageInformation = data => {
    let values = {
        system_name: data?.name ?? 'N/A',
        capacity:
            data?.energy_capacity?.value +
                data?.energy_capacity?.unit_of_measurement ?? 'N/A',
        total_price: data?.total_cost?.toLocaleString('US') ?? 0,
        price_per_month: data?.min_monthly_repayment?.toLocaleString('US') ?? 0,
        backupHours: data?.backup_hours,
        averageLoadSize: data?.average_load_size,
        upfront_deposit_amount: data?.upfront_deposit,
        appliances: data?.appliances,
        components: data?.components,
        payment_model: data?.payment_model,
        energy_capacity: data?.energy_capacity,
    }

    return values
}
