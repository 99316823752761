import {
    canSetupRepaymentMethod,
    canStartRepaymentSchedule,
    canUploadInstallationEvidence,
    canUploadProviderPaymentEvidence,
    canUploadSignedAgreement,
    canUploadUpfrontDeposit,
} from '../../utils/data'

const formatToDoData = data => {
    const signedAgreement = data?.signed_agreement || {}
    const repaymentMethodSetup = data?.repayment_method_setup || {}
    const evidenceOfProviderPayment = data?.evidence_of_provider_payment || {}
    const isEmptyRepaymentMethodSetup =
        Object.entries(repaymentMethodSetup).length === 0

    const planDuration = data?.repayment_plans?.filter(
        plan => plan.admin_approved === true,
    )[0]?.tenure

    const toDoData = [
        {
            keyID: 'SUNFI_AGREEMENT',
            label: 'SunFi Agreement',
            statusBox:
                Object.entries(signedAgreement).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(signedAgreement).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(signedAgreement).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.agreement_signed_at,
            btnText:
                Object.entries(signedAgreement).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle:
                Object.entries(signedAgreement).length === 0
                    ? 'Upload Updated Signed Agreement'
                    : 'Signed Agreement',
            modalSubTitle: 'Enter the details of the signed agreement',
            uploadText: 'Signed Agreement',
            actionEvidence: data?.signed_agreement?.secure_url,
            permission: {
                ...(Object.entries(signedAgreement).length === 0 && {
                    permission: canUploadSignedAgreement,
                }),
                permissionKey:
                    Object.entries(signedAgreement).length === 0
                        ? 'signed_agreement:can_upload'
                        : 'signed_agreement:can_view',
            },
        },
        {
            keyID: 'UPFRONT_DEPOSIT',
            label: 'Upfront Deposit',
            statusBox:
                data?.upfront_deposit_paid_at === null ? '#fffaea' : '#daf9ea',
            statusText:
                data?.upfront_deposit_paid_at === null ? '#ffc222' : '#32cc79',
            statusMsg:
                data?.upfront_deposit_paid_at === null
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.upfront_deposit_paid_at,
            btnText: data?.upfront_deposit_paid_at === null ? 'Upload' : 'View',

            modalTitle:
                data?.upfront_deposit_paid_at === null
                    ? 'Record Deposit'
                    : 'View Deposit',
            modalSubTitle:
                'Enter the details of the payment received by the provider',
            uploadText: 'Evidence of Payment',
            actionEvidence: data?.upfront_deposit_evidence?.secure_url,
            permission: {
                ...(data?.upfront_deposit_paid_at === null && {
                    permission: canUploadUpfrontDeposit,
                }),
                permissionKey:
                    data?.upfront_deposit_paid_at === null
                        ? 'upfront_deposit:can_upload'
                        : 'upfront_deposit:can_view',
            },
        },
        {
            keyID: 'REPAYMENT_METHOD_SETUP',
            label: 'Repayment Method Setup',
            statusBox: isEmptyRepaymentMethodSetup ? '#fffaea' : '#daf9ea',
            statusText: isEmptyRepaymentMethodSetup ? '#ffc222' : '#32cc79',
            statusMsg: isEmptyRepaymentMethodSetup ? 'PENDING' : 'COMPLETED',
            activeStatusBox:
                repaymentMethodSetup?.is_active === false
                    ? '#FFF1F1'
                    : '#daf9ea',
            activeStateText:
                repaymentMethodSetup?.is_active === false
                    ? '#DA1E28'
                    : '#32cc79',
            activeStateMsg:
                repaymentMethodSetup?.is_active === false
                    ? 'INACTIVE'
                    : 'ACTIVE',
            updatedTime: isEmptyRepaymentMethodSetup
                ? null
                : data?.repayment_method_setup?.created_at,
            btnText: isEmptyRepaymentMethodSetup ? 'Upload' : 'View',

            modalTitle: isEmptyRepaymentMethodSetup
                ? 'Record Repayment Method Setup'
                : 'View Repayment Method Setup',
            modalSubTitle: 'Enter the details of the repayment method setup',
            uploadText: 'Evidence of Repayment Method Setup (Standing Order)',
            methodType: data?.repayment_method_setup?.type,
            methodSubscriptionCode:
                data?.repayment_method_setup?.type ===
                'PAYSTACK:PLAN_SUBSCRIPTION'
                    ? data?.repayment_method_setup?.subscription_code
                    : null,
            actionEvidence: isEmptyRepaymentMethodSetup
                ? null
                : data?.repayment_method_setup?.secure_url,
            permission: {
                ...(isEmptyRepaymentMethodSetup && {
                    permission: canSetupRepaymentMethod,
                }),
                permissionKey: isEmptyRepaymentMethodSetup
                    ? 'repayment_method_setup:can_upload'
                    : 'repayment_method_setup:can_view',
            },
        },
        {
            keyID: 'REPAYMENT_SCHEDULE',
            label: 'Repayment Schedule',
            statusBox:
                data?.financing_start_date === null ? '#fffaea' : '#daf9ea',
            statusText:
                data?.financing_start_date === null ? '#ffc222' : '#32cc79',
            statusMsg:
                data?.financing_start_date === null ? 'PENDING' : 'COMPLETED',
            updatedTime: data?.financing_start_date,
            btnText: data?.financing_start_date === null ? 'Start' : 'View',
            planDuration: planDuration,
            firstRepayment: data?.repayment_schedule?.[0]?.due_date,
            modalTitle:
                data?.financing_start_date === null
                    ? 'Start Repayment Schedule'
                    : 'View Repayment Schedule',
            modalSubTitle: 'Enter the details of repayment made',
            permission: {
                ...(data?.financing_start_date === null && {
                    permission: canStartRepaymentSchedule,
                }),
                permissionKey:
                    data?.financing_start_date === null
                        ? 'repayment_schedule:can_upload'
                        : 'repayment_schedule:can_view',
            },
        },
        {
            keyID: 'PROVIDER_PAYMENT',
            label: 'Provider Payment',
            statusBox:
                Object.entries(evidenceOfProviderPayment).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(evidenceOfProviderPayment).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(evidenceOfProviderPayment).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.provider_received_payment_at,
            btnText:
                Object.entries(evidenceOfProviderPayment).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle:
                Object.entries(evidenceOfProviderPayment).length === 0
                    ? 'Upload Payment Evidence'
                    : 'View Payment Evidence',
            modalSubTitle:
                'Enter the details of the payment received by the provider',
            uploadText: 'Evidence of Payment',
            actionEvidence: data?.evidence_of_provider_payment?.secure_url,
            permission: {
                ...(Object.entries(evidenceOfProviderPayment).length === 0 && {
                    permission: canUploadProviderPaymentEvidence,
                }),
                permissionKey:
                    Object.entries(evidenceOfProviderPayment).length === 0
                        ? 'provider_payment:can_upload'
                        : 'provider_payment:can_view',
            },
        },
        {
            keyID: 'SOLUTION_INSTALLED',
            label: 'Solution Installed',
            statusBox:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.solution_installed_at,
            btnText:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'Upload Installation Evidence'
                    : 'View Installation Evidence',
            modalSubTitle: 'Enter the details of the installed solution',
            uploadText: 'Evidence Drive Link',
            actionEvidence:
                data?.evidence_of_solution_installed?.length > 0
                    ? data?.evidence_of_solution_installed[0]
                    : null,
            permission: {
                ...(data?.evidence_of_solution_installed?.length === 0 && {
                    permission: canUploadInstallationEvidence,
                }),
                permissionKey:
                    data?.evidence_of_solution_installed?.length === 0
                        ? 'solution_installed:can_upload'
                        : 'solution_installed:can_view',
            },
        },
    ]

    process.env.REACT_APP_FLAG_PAYMENT_COLLECTION_ENHANCEMENT_BUYOUT ===
        'true' &&
        data?.payment_plan === 'Lease to Own' &&
        toDoData.push({
            keyID: 'BUYOUT',
            label: 'Buy Out',
            statusBox:
                data?.meta_data?.buyout_evidence?.secure_url === undefined
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data?.meta_data?.buyout_evidence?.secure_url === undefined
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data?.meta_data?.buyout_evidence?.secure_url === undefined
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.meta_data?.buyout_evidence?.paid_at ?? '',
            btnText:
                data?.meta_data?.buyout_evidence?.secure_url === undefined
                    ? 'Upload'
                    : 'View',
            modalTitle:
                data?.meta_data?.buyout_evidence?.secure_url === undefined
                    ? 'Record Buy Out'
                    : 'View Buy Out',
            modalSubTitle: 'Enter the details of lease-to-own buy out',
            uploadText: 'Upload evidence',
            actionEvidence:
                data?.meta_data?.buyout_evidence?.secure_url?.length > 0
                    ? data?.meta_data?.buyout_evidence?.secure_url
                    : null,
            permission: {
                ...([null, undefined].includes(
                    data?.meta_data?.buyout_evidence?.paid_at,
                ) && {
                    permission: canUploadUpfrontDeposit,
                }),
                permissionKey: [null, undefined].includes(
                    data?.meta_data?.buyout_evidence?.paid_at,
                )
                    ? 'upfront_deposit:can_upload'
                    : 'upfront_deposit:can_view',
            },
        })

    return toDoData
}

export default formatToDoData

export const formatOutrightSaleToDoData = data => {
    const signedAgreement = data?.signed_agreement || {}

    return [
        {
            keyID: 'SUNFI_AGREEMENT',
            label: 'SunFi Agreement',
            disabled:
                Object.entries(signedAgreement).length === 0 ? false : true,
            statusBox:
                Object.entries(signedAgreement).length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                Object.entries(signedAgreement).length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                Object.entries(signedAgreement).length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.agreement_signed_at,
            btnText:
                Object.entries(signedAgreement).length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle:
                Object.entries(signedAgreement).length === 0
                    ? 'Signed Agreement'
                    : 'View Signed Agreement',
            modalSubTitle: 'Enter the details of the signed agreement',
            uploadText: 'Signed Agreement',
            actionEvidence: data?.signed_agreement?.secure_url,
            permission: {
                permissionKey:
                    Object.entries(signedAgreement).length === 0
                        ? 'signed_agreement:can_upload'
                        : 'signed_agreement:can_view',
            },
        },
        {
            keyID: 'SOLUTION_INSTALLED',
            label: 'Solution Installed',
            statusBox:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data?.evidence_of_solution_installed?.length === 0
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.solution_installed_at,
            btnText:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'Upload'
                    : 'View',
            modalTitle:
                data?.evidence_of_solution_installed?.length === 0
                    ? 'Upload Installation Evidence'
                    : 'View Installation Evidence',
            modalSubTitle: 'Enter the details of the installed solution',
            uploadText: 'Evidence Drive Link',
            actionEvidence:
                data?.evidence_of_solution_installed?.length > 0
                    ? data?.evidence_of_solution_installed[0]
                    : null,
            permission: {
                permissionKey:
                    data?.evidence_of_solution_installed.length === 0
                        ? 'solution_installed:can_upload'
                        : 'solution_installed:can_view',
            },
        },
        {
            keyID: 'MARK_A_PAYMENT',
            label: 'Record Payment',
            moreActionsLabel: 'Payment Evidence',
            disabled:
                data.application_status === 'AWAITING_PAYMENT' ? false : true,
            statusBox:
                data.application_status === 'AWAITING_PAYMENT'
                    ? '#fffaea'
                    : '#daf9ea',
            statusText:
                data.application_status === 'AWAITING_PAYMENT'
                    ? '#ffc222'
                    : '#32cc79',
            statusMsg:
                data.application_status === 'AWAITING_PAYMENT'
                    ? 'PENDING'
                    : 'COMPLETED',
            updatedTime: data?.outright_payment_evidence?.paid_at
                ? data?.outright_payment_evidence?.paid_at
                : null,
            btnText:
                data.application_status === 'AWAITING_PAYMENT'
                    ? 'Upload'
                    : 'View',
            methodOfPayment: data?.outright_payment_evidence?.payment_method,
            modalTitle: 'Record Payment',
            modalSubTitle:
                "Enter the details of the consumer's outright payment",
            uploadText: 'Evidence of Payment',
            actionEvidence:
                data?.outright_payment_evidence?.repayment_evidence_url,
            permission: {
                permissionKey:
                    data.application_status === 'AWAITING_PAYMENT'
                        ? 'record_payment:can_upload'
                        : 'record_payment:can_view',
            },
        },
    ]
}
