import PropTypes from 'prop-types'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'
import { ReactComponent as Edit } from 'src/assets/images/edit-icon-no-stroke.svg'
import '../accountsetupoverview.scss'
import ChatCTA from 'src/components/ChatCTA'
import { isMobile } from 'src/utils/mediaQueries'
import SelectedSolutionCard from './SelectedSolutionCard'
import { useAppContext } from 'src/appContext/AppContext'
import NairaSymbol from 'src/components/NairaSymbol'

const SelectedSolution = ({
    estimation,
    handleEditInfo,
    handleChangePackage,
    goBackToSystem,
    isDEPConsumer,
    AppliancesFromProvider,
    systemAccepted,
    systemDisputed,
    isAdminUser,
    isPromo,
    upfrontDepositPaid,
    hasPaidOutrightly,
    abTestInBoundRequest = false,
}) => {
    const { isCustomerApproved } = useAppContext()
    const ownerPrefix = isAdminUser ? "CUSTOMER'S" : 'YOUR'

    const isAdminDepConsumer = isAdminUser && estimation?.survey_type === 'DFS'
    const showDEPConsumerChangePackageOption =
        process.env.REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
            'true' && !abTestInBoundRequest
    return (
        <section data-testid="account-setup-overview-selected-solution">
            <div className={'ASOSelectedSolutionWrapper'}>
                {isDEPConsumer ? (
                    systemDisputed && !showDEPConsumerChangePackageOption ? (
                        <div className={'ASOSelectedSolution_DisputeFlag'}>
                            <p>
                                Your request to reject the selected package by
                                your provider has been duly noted and your
                                provider has been notified for re-estimation
                            </p>
                        </div>
                    ) : (
                        <div className="ASOSelectedSolutionContainer_DEP">
                            <h3 className={'ASOSelectedSolutionHeading_DEP'}>
                                Here&apos;s the package selected for you by your
                                provider
                            </h3>
                            {showDEPConsumerChangePackageOption &&
                                estimation.application_status !==
                                    'AWAITING_APPROVAL' && (
                                    <div
                                        className={'ASOTextIconBtn'}
                                        role={'button'}
                                        onClick={() => handleChangePackage()}
                                        hidden={isCustomerApproved}
                                    >
                                        <LightBulb
                                            role={'presentation'}
                                            className={'ASOSSvg'}
                                        />
                                        <p>Change Package</p>
                                    </div>
                                )}
                        </div>
                    )
                ) : (
                    <div className={'ASOSelectedSolutionHeading'}>
                        <h3>{ownerPrefix} SELECTED SOLUTION</h3>
                        {isAdminUser &&
                        !isAdminDepConsumer &&
                        !hasPaidOutrightly &&
                        !abTestInBoundRequest ? (
                            <div
                                className={'ASOTextIconBtn'}
                                role={'button'}
                                onClick={() => handleChangePackage()}
                                hidden={isCustomerApproved}
                            >
                                <LightBulb
                                    role={'presentation'}
                                    className={'ASOSSvg'}
                                />
                                <p>Change Package</p>
                            </div>
                        ) : null}
                    </div>
                )}
                <SelectedSolutionCard
                    estimation={estimation}
                    planType={estimation?.payment_plan}
                    goBackToSystem={goBackToSystem}
                    systemAccepted={systemAccepted}
                    hidePackageInfoBtn={isAdminUser || abTestInBoundRequest}
                    isPromo={isPromo}
                    upfrontDepositPaid={upfrontDepositPaid}
                    hasPaidOutrightly={hasPaidOutrightly}
                />
            </div>
            {isDEPConsumer || estimation?.survey_type === 'DFS' ? (
                <div className={'ASOApplianceInfoWrapper'}>
                    <h4>{ownerPrefix} ADDED APPLIANCES</h4>
                    <AppliancesFromProvider />
                </div>
            ) : hasPaidOutrightly ? null : (
                <div className={'ASOEnergyInfoWrapper'}>
                    <div className={'ASOEnergyInfoHeading'}>
                        <h4>{ownerPrefix} ENERGY INFO</h4>
                        {!isAdminUser && !abTestInBoundRequest && (
                            <div
                                className={'ASOTextIconBtn'}
                                onClick={handleEditInfo}
                                role={'button'}
                                hidden={isCustomerApproved}
                            >
                                <Edit role={'presentation'} />
                                <p>Edit your info</p>
                            </div>
                        )}
                    </div>
                    <div className={'ASOEnergyInfoList'}>
                        {estimation?.energy_info?.map((each, i) => (
                            <div key={i}>
                                <h5>{each.title}</h5>
                                <p>
                                    {each?.isAmount ? <NairaSymbol /> : ''}{' '}
                                    {each.value}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!isAdminUser && !hasPaidOutrightly && (
                <ChatCTA
                    margin={isMobile ? '51px auto 0 auto' : '21px 0 0 0'}
                    justifyContent={isMobile ? 'center' : ''}
                />
            )}
        </section>
    )
}

SelectedSolution.propTypes = {
    estimation: PropTypes.object,
    handleEditInfo: PropTypes.func,
    handleChangePackage: PropTypes.func,
    goBackToSystem: PropTypes.func,
    isDEPConsumer: PropTypes.bool,
    AppliancesFromProvider: PropTypes.node,
    systemAccepted: PropTypes.bool,
    systemDisputed: PropTypes.bool,
    isAdminUser: PropTypes.bool,
    isPromo: PropTypes.bool,
    upfrontDepositPaid: PropTypes.bool,
    hasPaidOutrightly: PropTypes.bool,
    abTestInBoundRequest: PropTypes.bool,
}

export default SelectedSolution
